import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import config from '../../config';
import {
	KernelSellerStatement,
	SearchSellerStatementBySellerParams,
} from './sellerStatementService.model';

export const getSellerStatementsByUser = async ({
	sellerId,
	status,
}: SearchSellerStatementBySellerParams): Promise<KernelSellerStatement[]> => {
	const body = {
		company_id: config.organization.company_id,
		query_type: 'user',
		seller_id: sellerId,
	};
	const bodyWithFilters =
		status === 'All' ? body : { ...body, filters: { seller_statement_status: status } };

	const response = await postAuthed<{ body: KernelSellerStatement[] }>(
		`${apiEndpoints.seller_statements}`,
		bodyWithFilters
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { createStore, combineReducers } from 'redux';
import reducers from '.';

const configureStore = () => {
	return createStore(
		combineReducers({
			...reducers,
		}),
		{}
	);
};

export default configureStore;

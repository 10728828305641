import { isEqual, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import ConfirmationButtons from '../../../../components/ConfirmationButtons';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { Input } from '../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../queries/queryKeys';
import {
	AuctionTechnicalDetails as AuctionTechnicalDetailsType,
	AuctionType,
	updateAuctionTechnicalDetails,
} from '../../../../services/auctionsService';
import AuctionTypeSelector from '../AuctionTypeSelector';

const AuctionTechnicalDetails = ({
	commission,
	extendedBiddingDuration,
	buyerPremium,
	type,
}: {
	commission?: number;
	extendedBiddingDuration?: number;
	buyerPremium?: number;
	type?: AuctionType;
}) => {
	const { auctionId } = useParams<{ auctionId: string }>();
	const incomingValues: AuctionTechnicalDetailsType = {
		commission,
		extended_bidding_duration: extendedBiddingDuration,
		buyer_premium: buyerPremium,
		auction_type: type,
	};
	const [draftFields, setDraftFields] = useState(incomingValues);
	const updateDraftFields = (updates: AuctionTechnicalDetailsType) =>
		setDraftFields({ ...draftFields, ...updates });

	const changedKeys = Object.keys(draftFields).filter(
		(key) =>
			draftFields[key as keyof AuctionTechnicalDetailsType] !==
			incomingValues[key as keyof AuctionTechnicalDetailsType]
	);

	useEffect(() => {
		if (!isEqual(incomingValues, draftFields)) {
			setDraftFields(incomingValues);
		}
	}, [commission, extendedBiddingDuration, buyerPremium]);

	const hasChanges = !isEqual(incomingValues, draftFields);

	const { mutate: doUpdateTechnicalDetails, status: updateStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateAuctionTechnicalDetails, draftFields],
		mutationFn: () =>
			updateAuctionTechnicalDetails({
				auctionId,
				deltas: pick(draftFields, changedKeys),
			}),
	});

	const queryClient = useQueryClient();
	const handleSave = () =>
		doUpdateTechnicalDetails(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully updated auction technical details.');
				queryClient.invalidateQueries([QueryKeys.GetAuctions]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating auction technical details. Please notify the #mvp-cs-channel if the issue persists.'
				);
			},
		});

	return (
		<Card>
			<h2 className="text-display5">Technical Details</h2>
			<div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
				<AuctionTypeSelector
					selectedAuctionType={draftFields.auction_type}
					setSelectedAuctionType={(selectedAuctionType: AuctionType) =>
						updateDraftFields({ auction_type: selectedAuctionType })
					}
				/>
				<Input
					id="buyers-premium"
					value={draftFields.buyer_premium}
					label="Buyer's Premium"
					type="number"
					onChange={({ target: { value } }) =>
						updateDraftFields({
							buyer_premium: typeof value === 'undefined' ? value : Number(value),
						})
					}
				/>
				<Input
					id="commission"
					value={draftFields.commission}
					label="Commission"
					type="number"
					onChange={({ target: { value } }) =>
						updateDraftFields({
							commission: typeof value === 'undefined' ? value : Number(value),
						})
					}
				/>
				<Input
					id="extended-bidding-duration"
					value={draftFields.extended_bidding_duration}
					label="Extended Bidding Duration"
					type="number"
					onChange={({ target: { value } }) =>
						updateDraftFields({
							extended_bidding_duration: typeof value === 'undefined' ? value : Number(value),
						})
					}
				/>
			</div>
			<div className="mt-4">
				<ConfirmationButtons
					confirmButtonLabel="Save Changes"
					onCancel={() => setDraftFields(incomingValues)}
					onConfirm={handleSave}
					disabled={!hasChanges}
					loading={updateStatus === 'loading'}
				/>
			</div>
		</Card>
	);
};

export default AuctionTechnicalDetails;

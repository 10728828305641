import React, { useRef } from 'react';
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip';
import './Tooltip.css';

export type TooltipProps = {
	content: React.ReactNode;
	id: string;
	placement?: 'top' | 'bottom' | 'left' | 'right';
	trigger?: TriggerType | TriggerType[] | null;
	children: React.ReactNode;
};

export const Tooltip = ({
	children,
	content,
	id,
	placement = 'top',
	trigger = ['hover', 'focus'],
}: TooltipProps) => {
	const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
		usePopperTooltip({
			placement: placement,
			trigger: trigger,
		});

	const childrenRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<button
				ref={setTriggerRef}
				type="button"
				aria-describedby={id}
				className="inline-block cursor-pointer transition-colors text-neutral3 hover:text-primary-500 focus:text-primary-500 focus:outline-none text-left"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				{children}
			</button>
			<div
				ref={setTooltipRef}
				id={id}
				role="tooltip"
				{...getTooltipProps({
					className: [
						'tooltip-container',
						...((childrenRef.current?.textContent?.length || 0) > 40
							? ['py-2.5', 'px-3']
							: ['py-2', 'px-2.5']),
						...(visible ? [] : ['sr-only', '!m-0']),
					].join(' '),
				})}
			>
				<div {...getArrowProps({ className: 'tooltip-arrow' })} />
				<div ref={childrenRef}>{content}</div>
			</div>
		</>
	);
};

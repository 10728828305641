import { noop } from 'lodash';
import React from 'react';
import { Icon } from '../Icon';
import { mergeRefs } from '../utils';

export const SearchContext = React.createContext<{
	count: number;
	cursor: number;
	id: string;
	handleResultKeydown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
	registerResult: () => void;
	removeResult: () => void;
	resultRefs: React.RefObject<HTMLDivElement>[];
}>({
	count: 0,
	cursor: -1,
	id: '',
	handleResultKeydown: noop,
	registerResult: noop,
	removeResult: noop,
	resultRefs: [],
});

type SearchProps = {
	errorText?: string;
	helpText?: string;
	id: string;
	onClear?: () => void;
	onSearch?: (value: string) => void;
	placeholder: string;
	showError?: boolean;
	withResults?: boolean;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'id' | 'placeholder'>;

export const Search = React.forwardRef(
	(
		{
			children,
			errorText,
			helpText,
			id,
			onClear,
			onSearch,
			placeholder,
			showError,
			value,
			withResults,
			...props
		}: SearchProps,
		ref?: React.ForwardedRef<HTMLInputElement>
	) => {
		const inputRef = React.useRef<HTMLInputElement>(null);
		const [resultCount, setResultCount] = React.useState(0);
		const [cursor, setCursor] = React.useState(-1);

		const registerResult = React.useCallback(() => {
			setResultCount((rc) => (rc += 1));
			setCursor(-1);
		}, []);

		const removeResult = React.useCallback(() => {
			setResultCount((rc) => (rc -= 1));
			setCursor(-1);
		}, []);

		const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (resultCount <= 0) return;

			if (e.key === 'ArrowUp') {
				e.preventDefault();
				setCursor(resultCount - 1);
				resultRefs[resultCount - 1].current?.focus();
			}

			if (e.key === 'ArrowDown') {
				e.preventDefault();
				setCursor(0);
				resultRefs[0].current?.focus();
			}
		};

		const handleResultKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter') {
				resultRefs[cursor].current?.click();
			}

			if (e.key === 'ArrowUp') {
				e.preventDefault();
				if (cursor === 0) {
					inputRef.current?.focus();
					setCursor(-1);
				} else {
					resultRefs[cursor - 1].current?.focus();
					setCursor((c) => c - 1);
				}
			}

			if (e.key === 'ArrowDown') {
				e.preventDefault();
				if (cursor === resultCount - 1) {
					inputRef.current?.focus();
					setCursor(-1);
				} else {
					resultRefs[cursor + 1].current?.focus();
					setCursor((c) => c + 1);
				}
			}
		};

		const handleSearchClick = () => {
			const inputValue = value || inputRef.current?.value;

			if (cursor > -1) {
				resultRefs[cursor].current?.click();
			} else if (inputValue) {
				onSearch?.(inputValue.toString());
			}

			inputRef.current?.focus();
		};

		const handleClearClick = () => {
			onClear?.();

			if (value == null && inputRef.current) {
				inputRef.current.value = '';
			}

			inputRef.current?.focus();
		};

		const inputProps = withResults
			? ({
					role: 'combobox',
					'aria-expanded': true,
					'aria-owns': `${id}-results`,
					'aria-autocomplete': 'list',
			  } as const)
			: {};

		const resultRefs = React.useMemo(() => {
			return Array.from({ length: resultCount || 0 }, () => React.createRef<HTMLDivElement>());
		}, [resultCount]);

		const SEARCH_HEIGHT_CLASS = 'h-10';

		return (
			<SearchContext.Provider
				value={{
					count: resultCount,
					cursor,
					handleResultKeydown,
					id,
					registerResult,
					removeResult,
					resultRefs,
				}}
			>
				<div className="relative group" role="search">
					<label htmlFor={id} className="sr-only">
						{placeholder}
					</label>
					<input
						ref={ref ? mergeRefs([ref, inputRef]) : inputRef}
						id={id}
						placeholder={placeholder}
						value={value}
						autoComplete="off"
						onKeyDown={handleKeydown}
						className={[
							'peer',
							'w-full',
							SEARCH_HEIGHT_CLASS,
							'px-11',
							'bg-neutralbkg2',
							'border',
							'border-neutralstroke1',
							'rounded-full',
							'text-body2',
							'placeholder:text-body2',
							'placeholder:text-neutral3',
							'focus-within:bg-inverted',
							'focus:outline-none',
							...(resultCount > 0
								? [
										'relative',
										'z-[2]',
										'group-focus-within:rounded-b-none',
										'group-focus-within:bg-inverted',
										'focus:ring-0',
										'group-focus-within:border-transparent',
										'group-focus-within:border-b-neutralstroke2',
								  ]
								: [
										'focus-within:ring-1',
										...(showError && errorText
											? ['ring-error-500', 'border-error-500']
											: ['focus-within:ring-primary-500', 'focus-within:border-primary-500']),
								  ]),
						].join(' ')}
						{...props}
						{...inputProps}
					/>
					{children}
					<button
						type="button"
						aria-label="Start Search"
						tabIndex={-1}
						onClick={handleSearchClick}
						className={[
							'absolute',
							'z-[3]',
							'top-0',
							'left-0',
							'flex',
							'items-center',
							SEARCH_HEIGHT_CLASS,
							'pl-4',
							'pr-2',
							'text-neutral3',
							'transition',
							'focus:outline-none',
							...(showError && errorText
								? ['text-error-500']
								: [
										'hover:text-primary-500',
										'focus:text-primary-500',
										'peer-focus:text-primary-500',
								  ]),
						].join(' ')}
					>
						<Icon name="Search" size="md" aria-hidden />
					</button>
					<button
						type="button"
						aria-label="Clear Search Field"
						onClick={handleClearClick}
						className={[
							'absolute',
							'z-[3]',
							'top-0',
							'right-0',
							'flex',
							'items-center',
							SEARCH_HEIGHT_CLASS,
							'pr-4',
							'pl-2',
							'text-neutral3',
							'transition',
							'hover:text-primary-500',
							'focus:text-primary-500',
							'focus:outline-none',
							'peer-placeholder-shown:hidden',
						].join(' ')}
					>
						<Icon name="DismissCircleFilled" size="md" aria-hidden />
					</button>
					{errorText && (
						<div
							id={`${id}-error`}
							role="alert"
							className={[
								...(showError ? ['mt-2', 'text-body2', 'text-error-500'] : ['sr-only']),
							].join(' ')}
						>
							{errorText}
						</div>
					)}
					{helpText && (
						<div id={`${id}-hint`} className="sr-only">
							{helpText}
						</div>
					)}
				</div>
			</SearchContext.Provider>
		);
	}
);

Search.displayName = 'Search';

import React from 'react';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';

type TagVariant = 'gray' | 'primary' | 'success' | 'warning' | 'error';
type TagProps = {
	children: React.ReactNode;
	filled?: boolean;
	icon?: IconName | JSX.Element;
	variant?: TagVariant;
};

export const Tag = ({ children, filled, icon, variant = 'gray' }: TagProps) => {
	const combination = `${variant}.${filled ? 'filled' : 'regular'}` as const;
	const borderColors: Record<TagVariant, string> = {
		error: 'border-error-500',
		gray: 'border-neutral3',
		primary: 'border-primary-500',
		success: 'border-success-500',
		warning: 'border-warning-500',
	};
	const textColors: Record<typeof combination, string> = {
		'error.filled': 'text-inverted',
		'error.regular': 'text-error-500',
		'gray.filled': 'text-inverted',
		'gray.regular': 'text-neutral2',
		'primary.filled': 'text-inverted',
		'primary.regular': 'text-primary-500',
		'success.filled': 'text-inverted',
		'success.regular': 'text-success-500',
		'warning.filled': 'text-inverted',
		'warning.regular': 'text-warning-500',
	};
	const backgroundColors: Record<typeof combination, string> = {
		'error.filled': 'bg-error-500',
		'error.regular': 'bg-error-25',
		'gray.filled': 'bg-neutral2',
		'gray.regular': 'bg-neutralbkg2',
		'primary.filled': 'bg-primary-500',
		'primary.regular': 'bg-primary-25',
		'success.filled': 'bg-success-500',
		'success.regular': 'bg-success-25',
		'warning.filled': 'bg-warning-500',
		'warning.regular': 'bg-warning-25',
	};
	return (
		<div
			className={[
				'flex',
				'items-center',
				'gap-1',
				'px-1',
				'h-4',
				'uppercase',
				'text-caption3',
				textColors[combination],
				'border',
				borderColors[variant],
				'rounded',
				backgroundColors[combination],
			].join(' ')}
		>
			{typeof icon === 'string' && iconList.includes(icon) ? (
				<Icon name={icon} size={3} aria-hidden />
			) : (
				React.isValidElement(icon) && icon
			)}
			<div className="whitespace-nowrap">{children}</div>
		</div>
	);
};

import RequireAuth from '../../../routing/RequireAuth';
import BulkUploadsPage from './BulkUploadsPage';
import ProtectedRoute from '../../../routing/ProtectedRoute/ProtectedRoute';

const BulkUploadsRoot = ({ match }: { match: { url: string } }) => {
	return (
		<RequireAuth>
			<ProtectedRoute exact path={`${match.url}/bulk-uploads`} component={BulkUploadsPage} />
		</RequireAuth>
	);
};

export default BulkUploadsRoot;

import React from 'react';
import useIndividualLot from '../../../../../../../../../queries/useIndividualLot';
import { KernelLot } from '../../../../../../../../../services/lotsService/lotsService.model';

const SplittableLot = ({
	lotId,
	isStagedForSplitting,
	onClick,
}: {
	lotId: string;
	isStagedForSplitting: boolean;
	onClick: () => void;
}) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);

	return (
		<>
			{lotStatus === 'success' && (
				<button className="flex text-left" onClick={onClick}>
					<input type="checkbox" checked={isStagedForSplitting} className="mr-2 mt-1" />
					<span>{lot.title}</span>
				</button>
			)}
		</>
	);
};

export default SplittableLot;

import { compareDesc } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';
import { SimpleDropdownMenuItem } from '../Dropdown/Dropdown.model';
import Label from '../Label';
import useAuctions from '../../queries/useAuctions';
import { AuctionStatus } from '../../services/auctionsService/auctionsService.model';
import Checkbox from '../Checkbox';

const DEFAULT_FILTERS: { [key in AuctionStatus]: boolean } = {
	Active: true,
	Completed: true,
	Not_Started: true,
	Preview: true,
	Pause: false,
	Withdrawn: false,
};

/** @deprecated use NewAuctionPicker. If you need the modal functionality but without the trigger button
 *  use AuctionPickerModal instead.
 */
const AuctionPicker = ({
	selectedAuctionId,
	setSelectedAuctionId,
	shouldShowUnassignedLots = true,
	allowedAuctionIds = [],
	allowedAuctionStatuses = [],
	isDisabled = false,
}: {
	allowedAuctionIds?: string[];
	/** Filters by status if provided. All statuses allowed by default. */
	allowedAuctionStatuses?: AuctionStatus[];
	selectedAuctionId?: string;
	setSelectedAuctionId: (newId: string) => void;
	shouldShowUnassignedLots?: boolean;
	isDisabled?: boolean;
}) => {
	const [filters, setFilters] = useState(DEFAULT_FILTERS);
	const filteredStatuses = Object.keys(filters).filter(
		(auctionStatus) => !!filters[auctionStatus as AuctionStatus]
	);

	const { data: auctions = [] } = useAuctions();

	const baseAuctionMenuItems: SimpleDropdownMenuItem[] = auctions
		.filter(({ id }) => allowedAuctionIds.length === 0 || allowedAuctionIds.includes(id))
		.filter(
			({ status }) => allowedAuctionStatuses.length === 0 || allowedAuctionStatuses.includes(status)
		)
		.filter(({ status }) => filteredStatuses.includes(status))
		.sort((a, b) =>
			compareDesc(
				new Date(a.previewTimestamp || new Date()),
				new Date(b.previewTimestamp || new Date())
			)
		)
		.map(({ title = '', id = '' }) => ({
			label: title,
			value: id,
		}));

	const auctionMenuItems: SimpleDropdownMenuItem[] = shouldShowUnassignedLots
		? [{ label: 'Unassigned Lots', value: 'Unassigned' }, ...baseAuctionMenuItems]
		: baseAuctionMenuItems;

	useEffect(() => {
		if (!selectedAuctionId && baseAuctionMenuItems.length > 0 && !shouldShowUnassignedLots) {
			setSelectedAuctionId(baseAuctionMenuItems[0].value);
		}
	}, [selectedAuctionId, baseAuctionMenuItems.length]);

	return (
		<div className="col-span-2">
			<div className="flex max-w-full items-center justify-between">
				<div className="w-96">
					<Label htmlFor="auctions">Auction</Label>
					<Dropdown
						name="auctions"
						options={auctionMenuItems}
						onChange={(selectedOption) =>
							setSelectedAuctionId((selectedOption as SimpleDropdownMenuItem)?.value || '')
						}
						value={auctionMenuItems.find(({ value }) => value === selectedAuctionId)}
						isDisabled={isDisabled}
					/>
				</div>
			</div>
			{allowedAuctionStatuses.length === 0 && (
				<div className="mt-2 flex justify-between md:justify-start">
					<div className="flex flex-col md:flex-row">
						<div className="mr-2">
							<Checkbox
								name="Active"
								checked={filters.Active}
								onChange={(newState: boolean) => setFilters({ ...filters, Active: newState })}
								label="Active"
							/>
						</div>
						<div className="mr-2">
							<Checkbox
								name="Completed"
								checked={filters.Completed}
								onChange={(newState: boolean) => setFilters({ ...filters, Completed: newState })}
								label="Completed"
							/>
						</div>
					</div>
					<div className="flex flex-col md:flex-row">
						<div className="mr-2">
							<Checkbox
								name="Not_Started"
								checked={filters.Not_Started}
								onChange={(newState: boolean) => setFilters({ ...filters, Not_Started: newState })}
								label="Not Started"
							/>
						</div>
						<div>
							<Checkbox
								name="Preview"
								checked={filters.Preview}
								onChange={(newState: boolean) => setFilters({ ...filters, Preview: newState })}
								label="Preview"
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AuctionPicker;

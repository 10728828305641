import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';

export const sentryInit = () => {
	if (!process.env.SENTRY_DSN) return;

	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: process.env.SENTRY_ENV,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
};

export const SentryRoute = Sentry.withSentryRouting(Route);

import React, { useState } from 'react';
import useImageUrl from '../../../../../../../queries/useImageUrl';
import {
	CheckoutOrder,
	LotAdjustment,
} from '../../../../../../../services/ordersService/ordersService.model';
import blankLotImage from '../../../../../../../assets/images/placeholder-card-xs.png';
import { Link } from 'react-router-dom';
import { KernelLot } from '../../../../../../../services/lotsService/lotsService.model';
import { DeletableLineItem, VanillaLineItem } from '../LineItem';
import Button from '../../../../../../../components/Button';
import SplitLotsToNewOrderModal from './SplitLotsToNewOrderModal';
import ChangeBuyerModal from './ChangeBuyerModal';
import ChangeAuctionModal from './ChangeAuctionModal';

const OrderSummaryLot = ({
	lot,
	order,
	adjustments,
	adjustmentIdsStagedForDeletion,
	toggleAdjustmentDeletion,
}: {
	lot: KernelLot;
	order: CheckoutOrder;
	adjustments: LotAdjustment[];
	adjustmentIdsStagedForDeletion: string[];
	toggleAdjustmentDeletion: (adjustmentId: string) => void;
}) => {
	const { data: auctionImageUrl = '' } = useImageUrl({
		storageProvider: 'cloudfront',
		itemId: lot.primary_image_name || '',
		rootPath: 'Lots',
		parentPath: lot.lot_id,
		imageSize: '@1x',
	});

	const imageUrl =
		lot.auction_id === 'fixed_price_marketplace' ? lot.primary_image_name : auctionImageUrl;

	const lots = order.costMetadata?.lots || [];
	const costMetadata = lots.find(({ lotId }) => lotId === lot.lot_id);

	const [shouldShowSplitLotsToNewOrderModal, setShouldShowSplitLotsToNewOrderModal] =
		useState(false);
	const [shouldShowChangeBuyerModal, setShouldShowChangeBuyerModal] = useState(false);
	const [shouldShowChangeAuctionModal, setShouldShowChangeAuctionModal] = useState(false);

	return (
		<div className="mb-4 flex w-full items-start">
			<div className="flex pt-1">
				{imageUrl ? (
					<img className="w-8 rounded-md object-contain" src={imageUrl} />
				) : (
					<img className="w-8 object-contain" src={blankLotImage} />
				)}
			</div>
			<div className="w-full">
				<VanillaLineItem kind="primary" amount={costMetadata?.price}>
					<div className="mr-8">
						<Link
							className="link"
							to={
								lot.auction_id === 'fixed_price_marketplace'
									? `/fixed-price/listings/${lot.lot_id}`
									: `/inventory/listings/${lot.lot_id}`
							}
						>
							{lot.title} (Serial Number {lot.serial_number} - Lot {lot.lot_number})
						</Link>
					</div>
				</VanillaLineItem>
				{order.auctionId !== 'fixed_price_marketplace' && (
					<div className="mb-1 ml-4">
						<Button
							kind="tertiary"
							isInline={true}
							className="link mr-2 text-caption2"
							onClick={() => setShouldShowSplitLotsToNewOrderModal(true)}
						>
							Split to New Order
						</Button>
						{shouldShowSplitLotsToNewOrderModal && (
							<SplitLotsToNewOrderModal
								onClose={() => setShouldShowSplitLotsToNewOrderModal(false)}
								selectedLotId={lot.lot_id}
							/>
						)}
						<Button
							kind="tertiary"
							isInline={true}
							className="link mr-2 text-caption2"
							onClick={() => setShouldShowChangeBuyerModal(true)}
						>
							Change Buyer
						</Button>
						{shouldShowChangeBuyerModal && (
							<ChangeBuyerModal
								onClose={() => setShouldShowChangeBuyerModal(false)}
								lotId={lot.lot_id}
							/>
						)}
						{order.invoiceStatus !== 'Paid' && (
							<Button
								kind="tertiary"
								isInline={true}
								className="link text-caption2"
								onClick={() => setShouldShowChangeAuctionModal(true)}
							>
								Assign to Auction
							</Button>
						)}
						{shouldShowChangeAuctionModal && (
							<ChangeAuctionModal
								currentAuctionId={order.auctionId}
								lot={lot}
								onClose={() => setShouldShowChangeAuctionModal(false)}
							/>
						)}
					</div>
				)}
				<VanillaLineItem
					amount={costMetadata?.buyerPremium}
					kind="secondary"
				>{`Buyer's Premium`}</VanillaLineItem>
				{adjustments.map(({ description, amount, id = '' }) => (
					<DeletableLineItem
						kind="secondary"
						amount={Number(amount)}
						key={description + amount}
						isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
						toggleAdjustmentDeletion={toggleAdjustmentDeletion}
						adjustmentId={id}
					>
						{description}
					</DeletableLineItem>
				))}
			</div>
		</div>
	);
};

export default OrderSummaryLot;

import { Link, useHistory, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import Card from '../../../../../components/Card';
import DownloadCSVButton from '../../../../../components/DownloadCSVButton';
import SortableTable from '../../../../../components/SortableTable';
import { Tooltip } from '../../../../../prizm-ui/Tooltip';
import { Button } from '../../../../../prizm-ui/Button';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import { SortableTableParams } from '../../../../../components/SortableTable/SortableTable.model';
import { ActionableAuctionStatus, Auction } from '../../../../../services/auctionsService';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';
import useAuctionById from '../../../../../queries/useAuctionById';
import useLotsForAuction from '../../../../../queries/useLotsForAuction';

const AuctionDashboardTable = () => {
	const history = useHistory();

	const { auctionId = '' } = useParams<{ auctionId?: string }>();

	const { data: auction = {} as Auction, status: auctionStatus } = useAuctionById(auctionId);

	const { data: lots = [] as KernelLot[], status: lotsStatus } = useLotsForAuction({
		auctionId,
		include: ['commission', 'consignment_manager_email', 'reserve_amount'],
		list: true,
	});

	const handleClick = (actionableAuctionStatus: ActionableAuctionStatus) =>
		history.push(`/auctions/auctionpublisher/${auctionId}/${actionableAuctionStatus}`);

	const columns: ColumnDef<KernelLot>[] = [
		{
			accessorKey: 'lot_number',
			enableGlobalFilter: false,
			header: 'Lot No.',
		},
		{
			accessorKey: 'serial_number',
			header: 'Serial No.',
			cell: ({
				getValue,
				row: {
					original: { lot_id, lot_number },
				},
			}) => (
				<Tooltip
					content="View this lot"
					id={`auction-dashboard-lot-${lot_number}`}
					placement="top"
					trigger="hover"
				>
					<Link className="block link" to={`/auctions/${auction.id}/inventory-manager/${lot_id}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: 'title',
			header: 'Title',
		},
		{
			accessorKey: 'min_bid_price',
			enableGlobalFilter: false,
			header: 'Min. Bid Price',
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			accessorKey: 'reserve_amount',
			enableGlobalFilter: false,
			header: 'Reserve',
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			accessorKey: 'commission',
			enableGlobalFilter: false,
			header: 'Commission',
			cell: ({ getValue }) => <div>{getValue() ? `${getValue()}%` : ''}</div>,
		},
		{
			accessorKey: 'consignment_manager_email',
			header: 'Consignment Manager',
		},
		{
			accessorKey: 'seller_email',
			header: 'Seller Email',
		},
		{
			accessorKey: 'category',
			header: 'Category',
		},
		{
			accessorKey: 'sub_category',
			header: 'Sub-Category',
		},
		{
			accessorKey: 'room',
			header: 'Room',
		},
		{
			accessorKey: 'bin',
			header: 'Bin',
		},
	];

	const sortableTableParams: SortableTableParams<KernelLot> = {
		columns,
		data: lots,
		getRowId: ({ lot_id }) => lot_id,
	};

	const AUCTION_DASHBOARD_CSV_COLUMN_NAMES = [
		'lot_number',
		'serial_number',
		'title',
		'min_bid_price',
		'reserve_amount',
		'commission',
		'consignment_manager_email',
		'seller_email',
		'category',
		'sub_category',
		'room',
		'bin',
	];

	return (
		<Card>
			<div className="flex flex-row items-end justify-between mb-4">
				<h2 className="text-subtitle1">Lots</h2>
				<div className="flex flex-row">
					{['Active', 'Preview'].includes(auction.status) && (
						<>
							<Button
								className="mr-2"
								size="md"
								variant="secondary"
								onClick={() => handleClick('pause')}
							>
								Pause
							</Button>
							<Button
								className="mr-2"
								destructive
								size="md"
								variant="secondary"
								onClick={() => handleClick('complete')}
							>
								Complete
							</Button>
						</>
					)}
					<DownloadCSVButton
						columnNames={AUCTION_DASHBOARD_CSV_COLUMN_NAMES}
						documentName={`commission report for ${auction.title ?? 'untitled'}`}
						items={lots}
					/>
				</div>
			</div>
			<SortableTable
				isLoading={lotsStatus === 'loading'}
				noResultsMessage="No lots to show"
				tableParams={sortableTableParams}
			/>
		</Card>
	);
};

export default AuctionDashboardTable;

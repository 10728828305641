import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import SortableTable from '../../../zenauction-ui-shared/Components/Elements/SortableTable';
import { v4 as uuidv4 } from 'uuid';
import { StripHtml } from '../../../zenauction-ui-shared/Utilities';
import Editor from '../../../zenauction-ui-shared/Components/Elements/Editor';
import config from '../../../config';
import {
	getInvoiceDetails,
	updateInvoiceComments,
	updateInvoiceShippingStatus,
	updateInvoiceTrackingId,
} from '../../../services/invoicesService';

const useStyles = makeStyles({
	bold: {
		fontWeight: 'bold',
	},
	details: {
		marginBottom: '8px',
		'& .title': {
			color: 'gray',
		},
	},
});

const PickSheet = (props) => {
	const classes = useStyles();
	const [invoiceDetails, setInvoiceValue] = useState({});
	const [openTrackingId, setOpenTrackingIdValue] = useState(false);
	const [shipments, setShipmentsValue] = useState([{ tracking_id: '', Vendor: '' }]);
	const [shippingStatus, setShippingStatusValue] = useState('');
	const [comments, setComments] = useState(0);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [invoiceAmount, setInvoiceAmount] = useState(0);
	const [totalLotsValue, setTotalLotsValue] = useState(0);

	useEffect(() => {
		getInvoiceDetails({
			auction_id: props.auction_id,
			invoice_id: props.invoice_id,
		}).then((invoiceObj) => {
			let totalAmount = 0;
			invoiceObj.lots.forEach((lot) => {
				lot.location =
					lot.room || lot.bin ? `${lot.room || ''} ${lot.bin || ''}`.trim() : 'Unknown';
				if (lot.buyer_premium) {
					totalAmount += lot.current_price * (lot.buyer_premium / 100);
				}
				totalAmount += lot.current_price;
			});
			invoiceObj.tracking_id && setShipmentsValue(JSON.parse(invoiceObj.tracking_id));
			setInvoiceValue(invoiceObj);
			setShippingStatusValue(invoiceObj.shipping_status);
			setInvoiceAmount(totalAmount);
			setComments(invoiceObj.comments);
			setIsDataLoaded(true);

			const {
				cost_metadata: { lots_total = 0 },
			} = invoiceObj;

			setTotalLotsValue(lots_total);
		});
	}, []);

	const addShipment = () => {
		setShipmentsValue([...shipments, { Vendor: '', tracking_id: '' }]);
	};

	const updateShipment = debounce((index, val, key) => {
		let shipping = [...shipments];
		shipping[index][key] = val;
		setShipmentsValue(shipping);
	}, 1 * 1000);

	const deleteShipment = (index) => {
		let shipping = [...shipments];
		shipping.splice(index, 1);
		setShipmentsValue(shipping);
	};

	const markInvoiceAsShipped = () => {
		updateInvoiceShippingStatus({
			auctionId: props.auction_id,
			invoiceId: props.invoice_id,
			newShippingStatus: 'Shipped',
		}).then((res) => {
			if (res) setShippingStatusValue('Shipped');
			props.reload_rows_callback(props.auction_id);
		});
	};

	const handleSubmitShipments = () => {
		setOpenTrackingIdValue(false);
		let shipments_string = JSON.stringify(shipments);
		updateInvoiceTrackingId({
			auction_id: props.auction_id,
			invoice_id: props.invoice_id,
			tracking: shipments_string,
		});
	};

	const handleCommentUpdates = (data) => {
		if (!data || data === comments) return;
		updateInvoiceComments({
			auction_id: props.auction_id,
			invoice_id: props.invoice_id,
			comments: data,
		}).then((result) => {
			console.log('Comments updated: ' + result);
			setComments(data);
		});
	};

	const {
		invoice_status,
		stripe_customer_email,
		stripe_customer_name,
		stripe_customer_shipping,
		lots,
	} = invoiceDetails;
	const details = [
		{ title: 'Invoice Number', content: [props.invoice_number || 'N/A'] },
		{
			title: 'Account details',
			content: [stripe_customer_name || 'N/A', stripe_customer_email || 'N/A'],
		},
		{
			title: 'Shipping Details',
			content: [
				stripe_customer_shipping?.name || 'N/A',
				stripe_customer_shipping?.address?.line1 + ' ' + stripe_customer_shipping?.address?.line2 ||
					'N/A',
				stripe_customer_shipping?.address?.city || 'N/A',
				stripe_customer_shipping?.address?.state || 'N/A',
				stripe_customer_shipping?.address?.country || 'N/A',
				stripe_customer_shipping?.address?.postal_code || 'N/A',
			],
		},
	];
	const columns = [
		{ id: 'lot_number', label: 'Lot #', align: 'left' },
		{ id: 'serial_number', label: 'Serial #', align: 'left' },
		{ id: 'title', label: 'Title', align: 'left', minWidth: 300 },
		{ id: 'location', label: 'Location', align: 'left' },
		{
			id: 'final_price',
			label: 'Amount',
			align: 'left',
			format: (value) => {
				return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
					value.toFixed(2)
				);
			},
		},
	];

	return !isDataLoaded ? (
		<div style={{ display: 'flex', padding: '30px', height: '800px' }}>
			<div style={{ position: 'absolute', left: '50%', top: '50%' }}>
				<CircularProgress />
			</div>
		</div>
	) : (
		<div style={{ display: 'flex', padding: '30px', height: 'fit-content' }}>
			<div>
				<div style={{ width: '200px', marginRight: '16px' }}>
					<h3 className={`legacy-h3 ${classes.bold}`} style={{ marginBottom: '60px' }}>
						{stripe_customer_name}
					</h3>
					<h6 className={`legacy-h6 ${classes.bold}`}>Details</h6>
					{details?.map((detail, i) => {
						return (
							<div key={i} className={classes.details}>
								<span className="title">{detail.title}</span>
								<br />
								{detail.content?.map((line, j) => {
									return (
										<>
											<span key={i + j}>{line}</span>
											<br />
										</>
									);
								})}
							</div>
						);
					})}
				</div>
				<div style={{ display: 'flex', width: '200px', marginRight: '16px', marginTop: '20px' }}>
					<h6 className={`legacy-h6 ${classes.bold}`}>Shipping Details</h6>
					<Button
						onClick={() => {
							setOpenTrackingIdValue(true);
						}}
						style={{ marginRight: '20px' }}
						color="primary"
					>
						Edit/Add
					</Button>
				</div>
				{shipments.map((shipment) => {
					return (
						<div
							key={shipment.tracking_id}
							style={{ display: 'flex', width: '200px', marginRight: '16px', marginTop: '20px' }}
						>
							<div style={{ marginRight: '4px' }}>{shipment.Vendor}</div>
							<div>{shipment.tracking_id}</div>
						</div>
					);
				})}
			</div>
			<div style={{ width: '1000px' }}>
				<>
					<div style={{ display: 'flex', margin: '30px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Status:
						</span>
						{invoice_status || 'N/A'}
					</div>
					<div style={{ display: 'flex', margin: '10px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Payment Date:
						</span>
						{props.paid_date}
					</div>
					<div style={{ display: 'flex', margin: '30px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Shipping Status:
						</span>
						{shippingStatus || 'N/A'}
					</div>
					<div style={{ marginBottom: '30px' }}>
						<p className={classes.bold}>Payments:</p>
						<Button
							onClick={() => markInvoiceAsShipped()}
							disabled={shippingStatus == 'Shipped'}
							style={{ textTransform: 'none' }}
							color="primary"
							variant="outlined"
						>
							Mark as Shipped
						</Button>
					</div>
					<div style={{ display: 'flex', margin: '10px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Auction:
						</span>
						{props.auction_title}
					</div>
					<div style={{ display: 'flex', margin: '10px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Total Lots Value:
						</span>
						{totalLotsValue}
					</div>
					<div style={{ display: 'flex', margin: '10px 0' }}>
						<span className={classes.bold} style={{ marginRight: '4px' }}>
							Total Invoice Amount:
						</span>
						{invoiceAmount}
					</div>
					<div style={{ marginBottom: '30px' }}>
						<p className={classes.bold}>Lots Won:</p>
						<SortableTable
							key={uuidv4()}
							dataToSort={lots}
							columns={columns}
							style={{ width: '100%' }}
						/>
					</div>
					<div>
						<p className={classes.bold}>Private Comments:</p>
						<Editor
							key={uuidv4()}
							placeHolder="Enter private comments here (optional)"
							initialValue={comments}
							updateContentCallback={(value) => {
								if (window.commentEventCallback) clearTimeout(window.commentEventCallback);
								window.commentEventCallback = setTimeout(
									() => handleCommentUpdates(StripHtml(value)),
									1000
								);
							}}
							className="m-1"
						/>
						<br />
					</div>
				</>
			</div>
			<Dialog
				open={openTrackingId}
				onClose={() => setOpenTrackingIdValue(false)}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle>Add Shipping</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={4}>
							<div>Vendor</div>
						</Grid>
						<Grid item xs={8}>
							<div>Tracking ID</div>
						</Grid>
					</Grid>
					{shipments.map((shipment, index) => {
						return (
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								key={`${shipment.tracking_id}-${index}`}
								alignItems="center"
								spacing={1}
							>
								<Grid item xs={4}>
									<Select
										fullWidth
										defaultValue={shipment.Vendor}
										id={shipment.tracking_id + '-select'}
										onChange={(event) => updateShipment(index, event.target.value, 'Vendor')}
									>
										{config.shipping.vendors.map((v) => {
											return <MenuItem value={v}>{v}</MenuItem>;
										})}
									</Select>
								</Grid>
								<Grid item xs={7}>
									<TextField
										fullWidth
										defaultValue={shipment.tracking_id}
										onChange={({ target: { value } }) =>
											updateShipment(index, value, 'tracking_id')
										}
									></TextField>
								</Grid>
								<Grid item xs={1}>
									<i
										className="pe-7s-trash"
										onClick={() => {
											deleteShipment(index);
										}}
									></i>
								</Grid>
							</Grid>
						);
					})}
					<Grid container direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item xs={4}>
							<Button onClick={addShipment} color="primary" spacing={8}>
								Add another shipment
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button onClick={handleSubmitShipments} color="primary">
								Submit
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
};
export default PickSheet;

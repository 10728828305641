import { formatDate } from '../../../../utils/formatters';
import {
	KernelSellerStatementAdjustment,
	KernelSellerStatementDetails,
} from '../../../../services/sellerStatementService';

export const FIXED_PRICE_SELLER_STATEMENT_CSV_COLUMNS = [
	{
		label: 'Statement Date',
		value: (row: KernelSellerStatementDetails) => formatDate(row.created_at),
	},
	{
		label: 'Statement Number',
		value: 'seller_statement_number',
	},
	{
		label: 'Seller Name',
		value: (row: KernelSellerStatementDetails) =>
			`${row.user_info.given_name} ${row.user_info.family_name}`,
	},
	{
		label: 'Email',
		value: 'user_info.email',
	},
	{
		label: 'Total Lot Value',
		value: 'total_lot_value',
	},
	{
		label: 'Commission',
		value: 'commission',
	},
	{
		label: 'Total Statement Amount',
		value: 'total_statement_amount',
	},
	{
		label: 'Net Adjustments',
		value: (row: KernelSellerStatementDetails): number =>
			row.adjustments
				? row.adjustments.reduce(
						(accumulator: number, thisAdjustment: KernelSellerStatementAdjustment) =>
							accumulator + Number(thisAdjustment.amount),
						0
				  )
				: 0,
	},
	{
		label: 'Total Ready for Payout',
		value: 'total_ready_for_payout',
	},
	{
		label: 'Status',
		value: 'seller_statement_status',
	},
];

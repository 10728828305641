import Button from '../Button';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';
import { QueryKeys } from '../../queries/queryKeys';
import { KernelLot } from '../../services/lotsService/lotsService.model';
import useIndividualLot from '../../queries/useIndividualLot';
import { showErrorToast, showSuccessToast } from '../Toast';
import { updateLot } from '../../services/lotsService';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import { truncate } from 'lodash';

const BidderLotURL = ({ auctionId, lotId }: { auctionId: string; lotId: string }) => {
	const {
		data: currentLot = {} as KernelLot,
		status: lotStatus,
		updateLot: updateLotInCache,
	} = useIndividualLot(lotId);

	const [slug, setSlug] = useState(currentLot.meta_slug);

	useEffect(() => {
		if (!slug && currentLot.meta_slug) {
			setSlug(currentLot.meta_slug);
		}
		if (slug && currentLot.meta_slug !== slug) {
			setSlug(currentLot.meta_slug);
		}
	}, [currentLot.meta_slug]);

	const [randomString] = uuidv4().split('-');
	const { title = '' } = currentLot;
	const clientGeneratedMetaSlug =
		slugify(title.slice(0, 70), { lower: true }) + '-' + randomString.slice(0, 5);

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, 'meta_slug', clientGeneratedMetaSlug],
		mutationFn: () => updateLot({ lot_id: lotId, meta_slug: clientGeneratedMetaSlug }),
	});

	const handleGenerateBidderSiteURL = () =>
		doUpdateLot(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully generated Bidder URL');
				updateLotInCache({ meta_slug: clientGeneratedMetaSlug });
				setSlug(clientGeneratedMetaSlug);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong generating Bidder URL. If the issue persists, please notify the #mvp-cs-channel.'
				);
			},
		});

	const url = `${process.env.BIDDER_SITE_URL}/${
		auctionId === 'fixed_price_marketplace' ? 'fixed-price/item' : 'item'
	}/${slug}`;

	if (lotStatus === 'loading') {
		return <></>;
	}

	return slug ? (
		<a href={url} target="_blank" rel="noreferrer" className="link">
			{truncate(url, { length: 60 })}
		</a>
	) : (
		<Button
			kind="tertiary"
			isInline={true}
			onClick={handleGenerateBidderSiteURL}
			isLoading={updateLotStatus === 'loading'}
		>
			Generate Bidder URL
		</Button>
	);
};

export default BidderLotURL;

import { useQuery } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { QueryKeys } from '../queryKeys';
import getBiddersByKeyword from '../../services/bidderService/getBiddersByKeyword';

const useBiddersByKeyword = (keyword: string) =>
	useQuery([QueryKeys.GetBiddersByKeyword, keyword], () => getBiddersByKeyword(keyword), {
		enabled: !!keyword,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving bidders. Please refresh and try again.'
			),
	});

export default useBiddersByKeyword;

import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import Card from '../../../components/Card';
import DownloadCSVButton from '../../../components/DownloadCSVButton';
import Label from '../../../components/Label';
import SortableTable from '../../../components/SortableTable';
import FixedPriceOrderSearchForm from './FixedPriceOrderSearchForm';
import { isEmail } from '../../../utils/validators';
import formatCurrency from '../../../utils/formatters/formatCurrency';
import { formatDate, getQueryParamsObject, getQueryString } from '../../../utils/formatters';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import {
	FixedPriceOrder,
	FixedPriceOrderSearchParams,
} from '../../../services/ordersService/ordersService.model';
import { BLANK_FIXED_PRICE_QUERY_PARAMS } from '../../../services/sellerStatementService';
import useFixedPriceOrders from '../../../queries/useFixedPriceOrders';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import { FIXED_PRICE_ORDER_CSV_COLUMNS } from '../../orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderSummary/OrderSummary.model';
import { FieldInfo } from 'json2csv';

const FixedPriceOrderSummaryPage = () => {
	const history = useHistory();
	const { search } = useLocation();

	const paramsFromUrl = getQueryParamsObject<FixedPriceOrderSearchParams>(search);

	const initialSearchParams: FixedPriceOrderSearchParams = {
		...BLANK_FIXED_PRICE_QUERY_PARAMS,
		...paramsFromUrl,
	};

	const [searchParams, setSearchParams] = useState(initialSearchParams);

	const updateSearchParams = (newParams: FixedPriceOrderSearchParams) => {
		setSearchParams(newParams);

		const paramsForQueryString: Partial<FixedPriceOrderSearchParams> =
			newParams.searchType === 'query'
				? { searchType: 'query', searchQuery: newParams.searchQuery }
				: {
						searchType: 'date-range',
						fromDate: newParams.fromDate,
						toDate: newParams.toDate,
						status: newParams.status,
				  };

		const { fromDate = '', toDate = '' } = newParams;

		if (isEmail(newParams.searchQuery) && fromDate.length > 0) {
			paramsForQueryString.fromDate = newParams.fromDate;
		}

		if (isEmail(newParams.searchQuery) && toDate.length > 0) {
			paramsForQueryString.toDate = newParams.toDate;
		}

		if (newParams.searchType === 'date-range' && !newParams.fromDate) {
			delete paramsForQueryString.fromDate;
		}

		if (newParams.searchType === 'date-range' && !newParams.toDate) {
			delete paramsForQueryString.toDate;
		}

		const queryString = getQueryString(paramsForQueryString);

		history.push(`/fixed-price/order-summary?${queryString}`);
	};

	const { data: fixedPriceOrders = [] as FixedPriceOrder[], status: fixedPriceOrdersStatus } =
		useFixedPriceOrders(searchParams);

	const columns: ColumnDef<FixedPriceOrder>[] = [
		{
			accessorKey: 'invoiceNumber',
			header: 'Order No.',
			cell: ({ getValue, row: { id } }) => (
				<Tooltip id={`view-order-${id}`} content="View Order">
					<Link className="link" to={`/orders/${id}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: 'name',
			header: 'Customer',
			cell: ({
				getValue,
				row: {
					original: { winnerId },
				},
			}) => (
				<div className="w-36">
					<Tooltip id={`view-${winnerId}`} content={`View ${getValue()}'s Profile`}>
						<Link className="link" to={`/usermanagement/customer/${winnerId}`}>
							{getValue() as string}
						</Link>
					</Tooltip>
				</div>
			),
		},
		{
			enableGlobalFilter: false,
			header: 'Total Amount',
			accessorFn: ({ costMetadata: { lotsTotal } }) => lotsTotal,
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			enableGlobalFilter: false,
			header: 'Net Adjustments',
			accessorFn: ({ costMetadata: { adjustmentsTotal } }) => adjustmentsTotal,
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			accessorKey: 'totalAmount',
			enableGlobalFilter: false,
			header: 'Total Amount Due',
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			enableGlobalFilter: false,
			header: "Buyer's Premium",
			accessorFn: ({ costMetadata: { bpTotal } }) => bpTotal,
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number)}</div>,
		},
		{
			accessorKey: 'invoiceStatus',
			header: 'Order Status',
		},
		{
			accessorKey: 'createdAt',
			header: 'Order Date',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		{
			accessorKey: 'paymentDate',
			header: 'Payment Date',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		{
			accessorKey: 'shippingStatus',
			header: 'Shipping Status',
		},
		{
			accessorKey: 'shippingDate',
			header: 'Shipped Date',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		{
			header: 'Comments',
			accessorFn: ({ comments }) =>
				comments && Array.isArray(comments) && comments.length > 0 ? comments?.pop()?.text : '',
			cell: ({ getValue }) => <div>{getValue() as string}</div>,
		},
	];

	const sortableTableParams: SortableTableParams<FixedPriceOrder> = {
		data: fixedPriceOrders as FixedPriceOrder[],
		columns,
		getRowId: ({ invoiceNumber }) => invoiceNumber,
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 50,
			},
		},
	};

	const canClick = fixedPriceOrdersStatus !== 'loading' && fixedPriceOrders.length > 0;

	return (
		<Card>
			<h1 className="text-display4 mb-4">Search Fixed Price Orders</h1>
			<FixedPriceOrderSearchForm
				onSubmit={(searchParams: Partial<FixedPriceOrderSearchParams>) =>
					// @ts-expect-error
					updateSearchParams(searchParams)
				}
			/>
			<div className="mt-6">
				<div className="flex flex-row items-end justify-between mb-2">
					<Label>Fixed Price Orders</Label>
					<DownloadCSVButton
						columnNames={FIXED_PRICE_ORDER_CSV_COLUMNS as FieldInfo<unknown>[]}
						disabled={!canClick}
						documentName="fixed-price-orders-summary.csv"
						items={fixedPriceOrders}
						kind="secondary"
					/>
				</div>
				<SortableTable
					globalFilterPlaceholder="Filter results ..."
					isLoading={fixedPriceOrdersStatus === 'loading'}
					key="fixed-price-order-sortable-table"
					tableParams={sortableTableParams}
				/>
			</div>
		</Card>
	);
};

export default FixedPriceOrderSummaryPage;

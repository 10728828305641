import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import ActionModal from '../../../../components/ActionModal';
import UploadCSV from '../../../../components/UploadCSV/UploadCSV';
import useCurrentUser from '../../../../queries/useCurrentUser';
import { CurrentUser } from '../../../../services/userService/userService.model';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { UploadCSVProps } from '../../../../components/UploadCSV/UploadCSV';
import { BulkAdjustmentsCSV } from '../../../../services/invoicesService/invoicesService.model';
import { bulkUploadInvoiceAdjustments } from '../../../../services/invoicesService';
import formatCurrency from '../../../../utils/formatters/formatCurrency';

const BulkUploadAdjustmentsModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const [bulkUploadAdjustmentsFile, setBulkUploadAdjustmentsFile] = useState<File | null>(null);
	const [bulkUploadAdjustmentsData, setBulkUploadAdjustmentsData] = useState<BulkAdjustmentsCSV[]>(
		[]
	);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { mutate: bulkUploadAdjustmentsMutation, status: bulkUploadAdjustmentsStatus } =
		useMutation({
			mutationKey: [QueryKeys.BulkUploadAdjustments, bulkUploadAdjustmentsFile],
			mutationFn: (bulkUploadAdjustmentsFile: File) =>
				bulkUploadInvoiceAdjustments({
					bulkUploadAdjustmentsFile,
					employeeId: currentUser.currentUserId,
				}),
		});

	const bulkUploadAdjustmentsHandler = () => {
		if (bulkUploadAdjustmentsFile) {
			bulkUploadAdjustmentsMutation(bulkUploadAdjustmentsFile, {
				onSuccess: () => {
					showSuccessToast(
						"Invoice adjustments are processing. You'll receive an email when complete."
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting invoice adjustments. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkAdjustmentsCSV> = {
		resultsColumns: [
			{ accessorKey: 'invoice_number', header: 'Invoice #' },
			{ accessorKey: 'buyer_email', header: 'Buyer Email' },
			{ accessorKey: 'category', header: 'Adjustment Type' },
			{ accessorKey: 'description', header: 'Adjustment Description' },
			{
				accessorKey: 'amount',
				header: 'Adjustment Amount',
				cell: ({ getValue }) => (
					<div className="text-right">{formatCurrency(getValue() as number)}</div>
				),
			},
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadAdjustmentsFile(uploadedFile);
			setBulkUploadAdjustmentsData(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadAdjustmentsFile &&
		bulkUploadAdjustmentsData.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadAdjustmentsStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={bulkUploadAdjustmentsHandler}
			isLoading={bulkUploadAdjustmentsStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Invoice Adjustments"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadAdjustmentsModal;

import React, { ClipboardEventHandler, FocusEventHandler } from 'react';
import { TableCell } from '@material-ui/core';
import { noop } from 'lodash';
import moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { SortableTableColumn } from '../../../TypesafeSortableTable/TypesafeSortableTable.model';
import DescriptionCell from './DescriptionCell';
import EditableCell from './EditableCell';
import IconButtonCell from './IconButtonCell';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import { Link } from 'react-router-dom';

const SortableTableRow = <T extends unknown>({
	rowNumber,
	columns,
	rowItem,
	contentEditable,
	handlePaste = noop,
	handleUpdateEditableContent,
	onFocus,
}: {
	rowNumber: number;
	columns: SortableTableColumn<T>[];
	rowItem: T;
	contentEditable: boolean;
	handlePaste?: ClipboardEventHandler;
	handleUpdateEditableContent: FocusEventHandler;
	onFocus: FocusEventHandler;
}) => {
	const rowHtml = [];
	if (rowItem && columns) {
		for (const [_, column] of columns.entries()) {
			// @ts-ignore
			const cellValue = rowItem[column.id];
			if (column.hidden) continue;
			if (column.type == 'description') {
				rowHtml.push(
					<DescriptionCell
						key={column.id}
						column={column}
						value={cellValue}
						contentEditable={contentEditable}
						rowNumber={rowNumber}
						handlePaste={handlePaste}
						handleUpdateEditableContent={handleUpdateEditableContent}
						onFocus={onFocus}
					/>
				);
			} else if (column.type == 'icon_button') {
				rowHtml.push(
					<IconButtonCell key={column.id} column={column} rowNumber={rowNumber} rowItem={rowItem} />
				);
			} else if (column.type == 'timestamp') {
				// todo:allow this to handle contentEditable
				if (cellValue) {
					rowHtml.push(
						<TableCell key={column.id + rowNumber} className="align-middle">
							{moment.utc(cellValue).local().format('M/D/YYYY h:mm a')}
						</TableCell>
					);
				}
			} else if (column.type == 'countdown_timer') {
				if (cellValue) {
					rowHtml.push(
						<TableCell key={column.id + rowNumber} className="align-middle">
							<CountdownTimer
								timeTillDate={moment(cellValue).format('MM DD YYYY hh:mm a')}
								timeFormat="MM DD YYYY, h:mm a"
							/>
						</TableCell>
					);
				}
			} else if (column.type == 'component') {
				const Component = column.component;
				rowHtml.push(
					<TableCell key={column.id + rowNumber} className="align-middle">
						{/** @ts-ignore */}
						<Component {...cellValue}></Component>
					</TableCell>
				);
			} else if (column.type === 'currency') {
				rowHtml.push(
					<TableCell key={column.id + rowNumber} className="text-right align-middle">
						{formatCurrency(cellValue)}
					</TableCell>
				);
			} else if (column.type === 'external-link') {
				rowHtml.push(
					<TableCell key={column.id + rowNumber} className="align-middle">
						<a
							href={cellValue.url}
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-500 link"
						>
							{cellValue.label}
						</a>
					</TableCell>
				);
			} else if (column.type === 'internal-link') {
				rowHtml.push(
					<TableCell key={column.id + rowNumber} className="align-middle">
						<Link to={cellValue.url} className="text-blue-500 link">
							{cellValue.label}
						</Link>
					</TableCell>
				);
			} else {
				rowHtml.push(
					<EditableCell
						// @ts-ignore
						key={column.id}
						column={column}
						cellValue={cellValue}
						rowObject={rowItem}
						rowNumber={rowNumber}
						contentEditable={contentEditable}
						onFocus={onFocus}
						onBlur={handleUpdateEditableContent}
						onPaste={handlePaste}
					/>
				);
			}
		}
	}
	return <tr>{rowHtml}</tr>;
};

export default SortableTableRow;

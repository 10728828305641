import React, { ChangeEvent, useState } from 'react';
import { noop } from 'lodash';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import Dropdown from '../../../../../components/Dropdown';
import Input from '../../../../../components/Input';
import Label from '../../../../../components/Label';
import { Textarea } from '../../../../../prizm-ui/Textarea';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { CATEGORY_DROPDOWN_OPTIONS } from './StripePayoutModal.model';
import { DropdownMenuItem } from '../../../../../components/Dropdown/Dropdown.model';
import { QueryKeys } from '../../../../../queries/queryKeys';
import { postStripePayout } from '../../../../../services/cashAccountsService';

const StripePayoutModal = ({
	currentBalance,
	onClose,
	refreshCurrentBalance,
	refreshHistoryTable,
}: {
	currentBalance: number;
	onClose: () => void;
	refreshCurrentBalance: () => void;
	refreshHistoryTable: () => void;
}) => {
	const { userId } = useParams<{ userId: string }>();

	const [amount, setAmount] = useState(0);

	const [category, setCategory] = useState<DropdownMenuItem>({
		label: 'Payout',
		value: 'withdrawal',
	});

	const [description, setDescription] = useState('');

	const { mutate: doWithdrawFromCashAccount, status: withdrawFromCashAccountStatus } = useMutation({
		mutationFn: () =>
			postStripePayout({
				amount,
				description,
				user: userId,
			}),
		mutationKey: [QueryKeys.WithdrawFromCashAccount, userId],
	});

	const handleSave = () => {
		if (amount > currentBalance) {
			showErrorToast('Amount cannot exceed balance.');

			return;
		}

		doWithdrawFromCashAccount(undefined, {
			onError: ({
				response: {
					data: { status },
				},
			}) => {
				if (status === 'account_incomplete') {
					showErrorToast('The user has not setup their Stripe payout account.');

					return;
				}

				if (status === 'insufficient_balance') {
					showErrorToast('The user has insufficient funds for this withdrawal.');

					return;
				}

				if (status === 'payout_failed') {
					showErrorToast('Stripe returned an error. Please try again.');

					return;
				}

				if (status === 'unknown_error') {
					showErrorToast('Something went wrong. Please try again later.');
				}

				if (status === 'unpaid_orders') {
					showErrorToast('The user cannot withdraw due to unpaid orders.');
				}
			},
			onSuccess: () => {
				refreshCurrentBalance();
				refreshHistoryTable();

				onClose();

				showSuccessToast('Stripe payout saved successfully.');
			},
		});
	};

	const amountIsValid = amount !== 0;
	const operationTypeIsValid =
		CATEGORY_DROPDOWN_OPTIONS.find((categoryOption) => categoryOption.value === category.value) !==
		undefined;

	return (
		<ActionModal
			cancelButtonLabel="Cancel"
			canConfirm={amountIsValid && operationTypeIsValid}
			className="my-4"
			confirmButtonLabel="Save"
			isLoading={withdrawFromCashAccountStatus === 'loading'}
			title="Stripe Payout"
			onClose={onClose}
			onConfirm={handleSave}
		>
			<div className="mb-4">
				<Label className="mb-2">Category</Label>
				<Dropdown
					isDisabled={true}
					key="category"
					name="category"
					onChange={noop}
					options={[...CATEGORY_DROPDOWN_OPTIONS]}
					value={category}
				/>
			</div>
			<div className="mb-4">
				<Label className="mb-2">Amount</Label>
				<Input
					className="w-full"
					name="amount"
					required
					type="number"
					value={amount}
					onChange={({ target: { value } }) => setAmount(Number(value))}
				/>
			</div>
			<div className="mb-4">
				<Label className="mb-2">Description</Label>
				<Textarea
					defaultValue={description}
					id="description"
					label=""
					name="description"
					onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
						setDescription(value)
					}
				/>
			</div>
		</ActionModal>
	);
};

export default StripePayoutModal;

import { useQuery, useQueryClient } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { getUser } from '../../services/userService';
import { User } from '../../services/userService/userService.model';
import { QueryKeys } from '../queryKeys';

const useUserById = (userId = '') => {
	const queryKey = [QueryKeys.GetUserById, userId];
	const baseQuery = useQuery(queryKey, () => getUser(userId), {
		enabled: !!userId,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the user. Please refresh and try again.'
			),
	});

	const queryClient = useQueryClient();
	const updateUser = (updatedFields: Partial<User>) =>
		queryClient.setQueryData(queryKey, (originalOrder: User = {} as User) => ({
			...originalOrder,
			...updatedFields,
		}));

	return { ...baseQuery, updateUser };
};

export default useUserById;

import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import Card from '../../Card';
import DeleteLotModal from './DeleteLotModal';
import EndLotModal from './EndLotModal';
import LoadingWrapper from '../../LoadingWrapper';
import PauseLotModal from './PauseLotModal';
import PlaceBidModal from './PlaceBidModal';
import RelistLotModal from './RelistLotModal';
import RestrictedArea from '../../RestrictedArea';
import SetToLiveModal from './SetToLiveModal';
import WithdrawLotModal from './WithdrawLotModal';
import { Button } from '../../../prizm-ui/Button';
import { showErrorToast, showSuccessToast } from '../../Toast';
import { QueryKeys } from '../../../queries/queryKeys';
import { Auction } from '../../../services/auctionsService/auctionsService.model';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { regenerateSellerStatementByLot } from '../../../services/sellerStatementService';
import { EDITOR_ROLES, UserRoleName } from '../../../services/userService/userService.model';
import useAuctions from '../../../queries/useAuctions';
import useIndividualLot from '../../../queries/useIndividualLot';
import useUserRole from '../../../queries/useUserRole';

const AdministrativeActions = () => {
	const { lotId, auctionId } = useParams<{ lotId: string; auctionId: string }>();

	const { data: currentLot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);

	const {
		data: auctions = [] as Auction[],
		refetch: refetchCurrentLot,
		status: currentAuctionStatus,
	} = useAuctions();

	const { data: userRole = '' as UserRoleName, status: userRoleStatus } = useUserRole();

	const [shouldShowDeleteLotModal, setShouldShowDeleteLotModal] = useState(false);
	const [shouldShowEndLotModal, setShouldShowEndLotModal] = useState(false);
	const [shouldShowPauseLotModal, setShouldShowPauseLotModal] = useState(false);
	const [shouldShowPlaceBidModal, setShouldShowPlaceBidModal] = useState(false);
	const [shouldShowRelistLotModal, setShouldShowRelistLotModal] = useState(false);
	const [shouldShowSetToLiveModal, setShouldShowSetToLiveModal] = useState(false);
	const [shouldShowWithdrawLotModal, setShouldShowWithdrawLotModal] = useState(false);

	const isAdmin = userRole === 'Admin';

	const canPlaceBid =
		isAdmin || ['AuctionManager', 'Consignment', 'CustomerSupport', 'Finance'].includes(userRole);

	const canTakeAdministrativeActions: UserRoleName[] = [...EDITOR_ROLES, 'AuctionManager'];

	const canTriggerModals = isAdmin || userRole === 'AuctionManager';

	const canRegenerateSellerStatement =
		['Completed_Sold', 'Completed_Unsold'].includes(currentLot.status) &&
		canTriggerModals &&
		auctionId !== 'Unassigned';

	const canShowEndLotModal =
		['Live', 'Extended_Bidding'].includes(currentLot.status) &&
		canTriggerModals &&
		auctionId !== 'Unassigned';

	const canShowPauseLotModal =
		['Live', 'Extended_Bidding'].includes(currentLot.status) &&
		canTriggerModals &&
		auctionId !== 'Unassigned';

	const canShowPlaceBidModal =
		['Live', 'Extended_Bidding'].includes(currentLot.status) &&
		canPlaceBid &&
		auctionId !== 'Unassigned';

	const currentAuction = auctions.find(({ id }) => id === auctionId);

	const canShowSetToLiveModal =
		currentAuction?.status === 'Active' &&
		['Not_Started', 'Pause', 'Inactive', undefined].includes(currentLot.status) &&
		canTriggerModals &&
		auctionId !== 'Unassigned';

	const isVaultedCollectorsItem =
		!!currentLot.collectible_id &&
		(currentLot.location === 'vault vault' ||
			`${currentLot.bin} ${currentLot.room}` === 'vault vault');

	const canShowRelistModal = isVaultedCollectorsItem && currentLot.status === 'Withdrawn';

	const canShowWithdrawModal =
		isVaultedCollectorsItem && !['Completed_Sold', 'Withdrawn'].includes(currentLot.status);

	const { mutate: doRegenerateSellerStatement, status: regenerateSellerStatementStatus } =
		useMutation({
			mutationKey: [QueryKeys.RegenerateSellerStatementForLot, new Date().toISOString()],
			mutationFn: (lotId: string) => regenerateSellerStatementByLot(lotId),
		});

	const handleRegenerateSellerStatement = () =>
		doRegenerateSellerStatement(currentLot.lot_id, {
			onSuccess: () => {
				refetchCurrentLot();

				showSuccessToast('Successfully regenerated seller statement.');
			},
			onError: () => {
				showErrorToast(
					'Something went wrong regenerating seller statement. Please try again later.'
				);
			},
		});

	return (
		<LoadingWrapper queryStatuses={[lotStatus, currentAuctionStatus, userRoleStatus]}>
			<Card className="p-4">
				<div className="mb-4">
					<h2 className="text-subtitle1">Administrative Actions</h2>
					<p className={`text-body1 ${currentLot.title ? '' : 'italic'}`}>
						{currentLot.title ? `"${currentLot.title}"` : 'Unnamed Lot'}
					</p>
				</div>
				<div className="flex flex-col xl:flex-row">
					{canShowPlaceBidModal && (
						<Button
							className="mr-0 xl:mr-2 mb-4 xl:mb-0"
							size="md"
							variant="brand"
							onClick={() => setShouldShowPlaceBidModal(true)}
						>
							Place Bid
						</Button>
					)}
					{shouldShowPlaceBidModal && (
						<PlaceBidModal
							auctionId={currentLot.auction_id}
							currentPrice={currentLot.current_price}
							lotId={currentLot.lot_id}
							lotNumber={currentLot.lot_number as string}
							minBid={currentLot.min_bid_price}
							numberOfBids={currentLot.number_of_bids}
							onClose={() => setShouldShowPlaceBidModal(false)}
						/>
					)}

					{canShowPauseLotModal && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								className="mr-0 xl:mr-2 mb-4 xl:mb-0"
								size="md"
								variant="brand"
								onClick={() => setShouldShowPauseLotModal(true)}
							>
								Pause Lot
							</Button>
						</RestrictedArea>
					)}
					{shouldShowPauseLotModal && (
						<PauseLotModal lot={currentLot} onClose={() => setShouldShowPauseLotModal(false)} />
					)}
					{canShowSetToLiveModal && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button size="md" variant="brand" onClick={() => setShouldShowSetToLiveModal(true)}>
								Set to Live
							</Button>
						</RestrictedArea>
					)}
					{shouldShowSetToLiveModal && (
						<SetToLiveModal lot={currentLot} onClose={() => setShouldShowSetToLiveModal(false)} />
					)}
					{canShowEndLotModal && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								className="mr-0 xl:mr-2 mb-4 xl:mb-0"
								size="md"
								variant="brand"
								onClick={() => setShouldShowEndLotModal(true)}
							>
								End Lot
							</Button>
						</RestrictedArea>
					)}
					{shouldShowEndLotModal && (
						<EndLotModal lot={currentLot} onClose={() => setShouldShowEndLotModal(false)} />
					)}

					{canRegenerateSellerStatement && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								className="mr-0 xl:mr-2 mb-4 xl:mb-0"
								disabled={regenerateSellerStatementStatus === 'loading'}
								loading={regenerateSellerStatementStatus === 'loading'}
								size="md"
								variant="brand"
								onClick={handleRegenerateSellerStatement}
							>
								Regenerate Seller Statement
							</Button>
						</RestrictedArea>
					)}
					{!isVaultedCollectorsItem && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								destructive
								key="lot-manager-delete-lot"
								size="md"
								onClick={() => setShouldShowDeleteLotModal(true)}
							>
								Delete lot
							</Button>
						</RestrictedArea>
					)}
					{shouldShowDeleteLotModal && (
						<DeleteLotModal lot={currentLot} onClose={() => setShouldShowDeleteLotModal(false)} />
					)}
					{canShowWithdrawModal && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								destructive
								key="lot-manager-withdraw-lot"
								size="md"
								onClick={() => setShouldShowWithdrawLotModal(true)}
							>
								Withdraw lot
							</Button>
						</RestrictedArea>
					)}
					{shouldShowWithdrawLotModal && (
						<WithdrawLotModal
							lot={currentLot}
							onClose={() => setShouldShowWithdrawLotModal(false)}
						/>
					)}
					{canShowRelistModal && (
						<RestrictedArea allowedRoles={canTakeAdministrativeActions}>
							<Button
								destructive
								key="lot-manager-relist-lot"
								size="md"
								onClick={() => setShouldShowRelistLotModal(true)}
							>
								Relist lot
							</Button>
						</RestrictedArea>
					)}
					{shouldShowRelistLotModal && (
						<RelistLotModal lot={currentLot} onClose={() => setShouldShowRelistLotModal(false)} />
					)}
				</div>
			</Card>
		</LoadingWrapper>
	);
};

export default AdministrativeActions;

import { RemovedLot } from '../lotsService/lotsService.model';
import { AdjustmentTypeEnum } from '../../Pages/orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderSummary/AddAdjustmentModal/AddAdjustmentModal';

export interface LotCostMetadata {
	buyerPremium: number;
	lotId: string;
	price: number;
	title: string;
}

export type BasePaymentMethod = 'ach' | 'creditCard' | 'crypto' | 'wire';

export type PaymentMethod =
	| BasePaymentMethod
	| 'advance'
	| 'balance'
	| 'cash'
	| 'check'
	| 'credits'
	| 'offset'
	| 'other';

export type TaxablePaymentMethod = PaymentMethod;

export type TaxesByTaxablePaymentMethod = { [key in TaxablePaymentMethod]: number };

export const PAYMENT_METHODS_WITH_SURCHARGES: PaymentMethod[] = ['creditCard', 'crypto', 'ach'];

export type PaymentMethodFees = { [key in PaymentMethod]: number };

export type StructuredFee = 'handling' | 'insurance' | 'shipping' | 'tax';
export const STRUCTURED_FEES = ['handling', 'insurance', 'shipping', 'tax'];

export type StructuredFees = { [key in StructuredFee]: number };

interface BaseAdjustment {
	amount: number | string;
	createdAt?: string;
	createdBy?: string;
	description?: string;
	id?: string;
	isNew?: boolean;
	lotId?: string;
	taxable: boolean;
}

export interface BalanceAdjustment extends BaseAdjustment {
	category: 'balance';
}

export interface CouponAdjustment extends BaseAdjustment {
	category: 'coupon';
	metadata: {
		couponCode: string;
	};
}

export interface FreeformAdjustment extends BaseAdjustment {
	category:
		| 'other'
		| 'offset'
		| 'advance'
		| AdjustmentTypeEnum.Credits
		| AdjustmentTypeEnum.PartialPayment;
	description: string;
}

export interface LatePaymentAdjustment extends BaseAdjustment {
	category: 'late_payment';
	description: string;
}

export interface LotAdjustment extends BaseAdjustment {
	category: 'lot';
	description: string;
	lotId: string;
}

export interface StructuredFeeAdjustment extends BaseAdjustment {
	category: StructuredFee | string;
}

export type Adjustment =
	| BalanceAdjustment
	| CouponAdjustment
	| FreeformAdjustment
	| LatePaymentAdjustment
	| LotAdjustment
	| StructuredFeeAdjustment;

export type CheckoutOrderCollectionsStatus = 'active' | 'defaulted' | 'en_route' | 'vip';

export type CheckoutOrderCommentKind =
	| 'unknown'
	| 'order-comment'
	| 'order-paid'
	| 'order-refund'
	| 'order-shipping-comment'
	| 'order-shipping-details'
	| 'order-shipping-status'
	| 'order-status'
	| 'order-adjustment';

export interface CheckoutOrderComment {
	author?: {
		authorDisplayName: string | 'unknown';
		authorId: string;
	};
	kind: CheckoutOrderCommentKind;
	text: string;
	timestamp: string;
}

export type InvoiceStatus =
	| 'Awaiting_Payment'
	| 'Blocked'
	| 'Defaulted'
	| 'Draft'
	| 'Paid'
	| 'Payment_Failed'
	| 'Payment_Processing'
	| 'Pending_Approval'
	| 'Refunded'
	| 'User_Submitted'
	| 'Void';

export interface BaseCostMetadata {
	accountBalance: number;
	adjustments: (
		| BalanceAdjustment
		| CouponAdjustment
		| FreeformAdjustment
		| LatePaymentAdjustment
		| LotAdjustment
		| StructuredFeeAdjustment
	)[];
	address: {
		city: string;
		country: string;
		line1: string;
		line2: string;
		postalCode: string;
		state: string;
	};
	bpTotal: number;
	calculationId: string;
	fees: StructuredFees;
	lots: LotCostMetadata[];
	lotsTotal: number;
	paymentOptionFees: PaymentMethodFees;
	priceTotal: number;
	subtotal: number;
	taxes?: TaxesByTaxablePaymentMethod;
}

export interface BaseCheckoutOrder {
	auctionId: string;
	costMetadata: BaseCostMetadata;
	createdAt: string;
	description: string;
	invoiceId: string;
	invoiceNumber: string;
	invoiceStatus: InvoiceStatus;
	lotIds: string[];
	usesCheckout: true;
	winnerId: string;
}

export interface KernelCheckoutOrder extends BaseCheckoutOrder {
	comments: CheckoutOrderComment[] | string;
}

export interface KernelFixedPriceOrder extends BaseCheckoutOrder {
	comments: CheckoutOrderComment[] | string;
}

export interface CheckoutOrder extends BaseCheckoutOrder {
	approvedDate: string;
	disablePaymentReminders: boolean;
	dueDate: string;
	collectionsStatus: CheckoutOrderCollectionsStatus;
	comments: CheckoutOrderComment[];
	paymentDate: string;
	paymentMethod: PaymentMethod;
	printedAt: string;
	shippingStatus: string;
	totalAmount: number;
	trackingId: string;
	removedLots?: RemovedLot[];
}

interface FixedPriceCostMetadata extends BaseCostMetadata {
	adjustmentsTotal: number;
}

export interface FixedPriceOrder extends BaseCheckoutOrder {
	approvedDate: string;
	comments: CheckoutOrderComment[];
	costMetadata: FixedPriceCostMetadata;
	disablePaymentReminders: boolean;
	dueDate: string;
	invoiceNumber: string;
	invoiceStatus: InvoiceStatus;
	name: string;
	paymentDate: string;
	paymentMethod: PaymentMethod;
	shippingDate: string;
	shippingStatus: string;
	totalAmount: number;
	trackingId: string;
}

interface BaseFixedPriceOrderSearchParams {
	searchType: 'date-range' | 'query' | 'winnerEmail';
	status?: FixedPriceOrderStatusType;
	fromDate?: string;
	toDate?: string;
	searchQuery?: string;
}

interface FixedPriceOrderSearchByDateRangeParams extends BaseFixedPriceOrderSearchParams {
	fromDate: string;
	toDate: string;
	status: FixedPriceOrderStatusType;
	searchType: 'date-range';
}
interface FixedPriceOrderSearchByQueryParams extends BaseFixedPriceOrderSearchParams {
	searchQuery: string;
	searchType: 'query';
}

export type FixedPriceOrderSearchParams =
	| FixedPriceOrderSearchByDateRangeParams
	| FixedPriceOrderSearchByQueryParams;

export type FixedPriceOrderStatusType = 'Paid' | 'Unpaid' | 'Refunded';

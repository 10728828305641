import React from 'react';
import { SearchContext } from './Search';

type SearchResultProps = {
	children?: React.ReactNode;
	index: number;
	onClick?: (value: string) => void;
	value: string;
};

export const SearchResult = ({ children, index, onClick, value }: SearchResultProps) => {
	const { cursor, handleResultKeydown, registerResult, removeResult, resultRefs } =
		React.useContext(SearchContext);
	React.useEffect(() => {
		registerResult();

		return () => removeResult();
	}, [registerResult, removeResult]);

	return (
		<div
			role="option"
			aria-selected={cursor === index}
			tabIndex={-1}
			ref={resultRefs[index]}
			onClick={() => onClick?.(value)}
			onKeyDown={handleResultKeydown}
			className={(onClick
				? [
						'cursor-pointer',
						'transition',
						'hover:bg-neutralstroke2',
						'focus-visible:bg-neutralstroke2',
						'focus:outline-none',
				  ]
				: []
			).join(' ')}
		>
			{children}
		</div>
	);
};

import React from 'react';
import ManageBanners from './ManageBanners';
import CarouselPreview from './CarouselPreview';
import AddBanner from './AddBanner';
import { Banner } from '../../../../services/layoutService/layoutService.model';
import {
	ManageBannersContextProvider,
	useManageBannersState,
} from './ManageBanners/useManageBannersState';

const BannerEditorView = ({ banners }: { banners: Banner[] }) => {
	const [manageBannersState, dispatch] = useManageBannersState(banners);

	return (
		<div>
			<ManageBannersContextProvider value={{ manageBannersState, dispatch }}>
				<div className="mb-16">
					<CarouselPreview />
				</div>
				<div className="my-8">
					<AddBanner />
				</div>
				<div className="mb-8">
					<ManageBanners />
				</div>
			</ManageBannersContextProvider>
		</div>
	);
};

export default BannerEditorView;

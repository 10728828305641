import React, { Fragment, Component } from 'react';
import classnames from 'classnames';
import { compareAsc, isAfter } from 'date-fns';
import { Nav, NavItem, NavLink, Col, CardHeader, Card, CardBody, Button } from 'reactstrap';
import { Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { invoiceStatus, shippingStatus, getInvoices } from '../../../../AdminUtils/invoiceUtils';
import SortableTable from '../../../../zenauction-ui-shared/Components/Elements/SortableTable';
import InvoiceDetailsCard from '../InvoiceDetailsCard';
import moment from 'moment';
import { sendDraftInvoices } from '../../../../services/invoicesService';

export default class WinnerSummaryDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: 'All',
			activeColumns: [],
			auctionCardsKey: 1,
			auction: props.auction,
			invoices: [],
			auction_map: props.auction_map,
			auction_title: props.auction_title,
			invoiceStatus: null,
			page: 0,
			rowsPerPage: 5,
			shippingStatus: null,
			selectedInvoiceId: null,
			selectedInvoiceAuctionId: null,
			selectedInvoiceNumber: null,
			tableKey: 1,
		};

		this.tabs = [
			'All',
			'Blocked',
			'Pending Approval',
			'Awaiting Payment',
			'Payment Failed',
			'Payment Received',
			'Past Due',
			'Shipped',
		];

		this.columns = {
			invoice_number: {
				id: 'invoice_number',
				label: 'Invoice No.',
				align: 'left',
				minWidth: 100,
				type: 'component',
				component: this.invoiceDetailsLink,
			},
			customer_name: { id: 'customer_name', label: 'Winner Name', align: 'left', minWidth: 175 },
			customer_email: { id: 'customer_email', label: 'Email', align: 'left' },
			due_date: {
				id: 'due_date',
				label: 'Due Date',
				align: 'left',
			},
			amount_due: {
				id: 'amount_due',
				label: 'Total Amount Due',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
						value.toFixed(2)
					);
				},
			},
			buyer_premium: {
				id: 'buyer_premium',
				label: "Buyer's Premium",
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
						value.toFixed(2)
					);
				},
			},
			amount_remaining: {
				id: 'amount_remaining',
				label: 'Amount Owed',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
						value.toFixed(2)
					);
				},
			},
			amount_paid: {
				id: 'amount_paid',
				label: 'Amount Received',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
						value.toFixed(2)
					);
				},
			},
			// { id: 'shipping_cost', label: 'Shipping Cost', align: 'left', format: (value) => { return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value.toFixed(2)); } },
			// { id: 'tax', label: 'Sales Tax', align: 'left', format: (value) => { return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value.toFixed(2)); } },
			reason: { id: 'reason', label: 'Blocked Reason', align: 'left' },
			invoice_status: { id: 'invoice_status', label: 'Status', align: 'left' },
			auction_title: { id: 'auction_title', label: 'Auction', align: 'left', minWidth: 100 },
			invoice_sent_date: {
				id: 'invoice_sent_date',
				label: 'Invoice Sent Date',
				align: 'left',
			},
			//Payment columns
			paid_date: { id: 'paid_date', label: 'Payment Date', align: 'left' },
			payment_type: { id: 'payment_type', label: 'Payment Type', align: 'left' },
			//Post payment columns
			date_shipped: { id: 'date_shipped', label: 'Date Shipped', align: 'left' },
			tracking_id: {
				id: 'tracking_id',
				label: 'Tracking Number',
				align: 'left',
				type: 'component',
				component: this.readableTrackingId,
			},
			comments: {
				align: 'left',
				component: this.getComment,
				id: 'comments',
				label: 'Comments',
				minWidth: 100,
				type: 'component',
			},
			collections_status: {
				align: 'left',
				id: 'collections_status',
				label: 'Collections Status',
			},
		};

		this.tabColumns = {
			All: [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'amount_remaining',
				'amount_paid',
				'invoice_status',
				'date_shipped',
				'comments',
				'collections_status',
			],
			Blocked: [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'reason',
				'comments',
				'collections_status',
			],
			'Pending Approval': [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'comments',
				'collections_status',
			],
			'Awaiting Payment': [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'amount_remaining',
				'amount_paid',
				'invoice_sent_date',
				'comments',
				'collections_status',
			],
			'Payment Failed': [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'amount_remaining',
				'amount_paid',
				'invoice_sent_date',
				'comments',
				'collections_status',
			],
			'Payment Received': [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'amount_remaining',
				'amount_paid',
				'paid_date',
				'payment_type',
				'comments',
				'collections_status',
			],
			'Past Due': [
				'invoice_number',
				'customer_name',
				'customer_email',
				'invoice_status',
				'due_date',
				'amount_due',
				'buyer_premium',
				'amount_remaining',
				'amount_paid',
				'paid_date',
				'payment_type',
				'comments',
				'collections_status',
			],
			Shipped: [
				'invoice_number',
				'customer_name',
				'customer_email',
				'amount_due',
				'buyer_premium',
				'date_shipped',
				'tracking_id',
				'comments',
				'collections_status',
			],
		};
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			let invoice_status = null;
			let shipping_status = null;

			switch (tab) {
				case 'Blocked':
					invoice_status = invoiceStatus.BLOCKED;
					break;
				case 'Pending Approval':
					invoice_status = invoiceStatus.PENDING_APPROVAL;
					break;
				case 'Awaiting Payment':
					invoice_status = invoiceStatus.AWAITING_PAYMENT;
					break;
				case 'Payment Failed':
					invoice_status = invoiceStatus.PAYMENT_FAILED;
					break;
				case 'Payment Received':
					invoice_status = invoiceStatus.PAID;
					shipping_status = shippingStatus.UNPICKED;
					break;
				case 'Shipped':
					invoice_status = invoiceStatus.PAID;
					shipping_status = shippingStatus.SHIPPED;
					break;
			}

			this.setState(
				{
					activeTab: tab,
					invoices: [],
					invoiceStatus: invoice_status,
					shippingStatus: shipping_status,
				},
				() => {
					this.generateActiveColumns(this.state.activeTab);
					this.prepareInvoiceRows();
				}
			);
		}
	};

	generateActiveColumns(tab) {
		if (this.props.getInvoices) {
			for (let tab in this.tabColumns) {
				if (!this.tabColumns[tab].includes('auction_title')) {
					this.tabColumns[tab].splice(1, 0, 'auction_title');
				}
			}
		}
		let column_names = this.tabColumns[tab];
		let columns = column_names.map((col) => this.columns[col]);
		this.setState({
			activeColumns: columns,
		});
	}

	prepareInvoiceRows = async () => {
		let invoices;
		if (this.props.getInvoices) {
			invoices = await this.props.getInvoices({
				invoice_status: this.state.invoiceStatus,
				shipping_status: this.state.shippingStatus,
			});
		} else {
			//	TODO: swap for getInvoices service; correct auction_id
			invoices = await getInvoices(this.state.auction, this.state.invoiceStatus, {
				shipping_status: this.state.shippingStatus,
			});
		}

		invoices.forEach((val, index) => {
			invoices[index]['invoice_number'] = {
				invoice_number: val['invoice_number'],
				invoice_id: val['invoice_id'],
				auction_id: val['auction_id'],
				paid_date: val['paid_date'],
				uses_checkout: val['uses_checkout'] ? val['uses_checkout'] : false,
			};
			invoices[index]['comments'] = {
				comments: val['comments'],
				uses_checkout: val['uses_checkout'] ? val['uses_checkout'] : false,
			};

			if (this.props.getInvoices) {
				invoices[index]['auction_title'] =
					this.state.auction_map[val['auction_id']] !== undefined
						? this.state.auction_map[val['auction_id']]
						: '';
			}

			invoices[index]['auction_title'] =
				val['auction_id'] === 'fixed_price_marketplace'
					? 'Fixed Price Marketplace'
					: val['auction_title'];

			invoices[index]['amount_due'] = val['amount_due'] / 100;
			invoices[index]['amount_paid'] = val['amount_paid'] / 100;
			invoices[index]['amount_remaining'] = val['amount_remaining'] / 100;
			invoices[index]['tracking_id'] = invoices[index]['tracking_id']
				? JSON.parse(invoices[index]['tracking_id'])
				: '';
			invoices[index]['due_date'] = val['due_date']
				? moment(val['due_date']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['invoice_sent_date'] = val['invoice_sent_date']
				? moment(val['invoice_sent_date']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['paid_date'] = val['paid_date']
				? moment(val['paid_date']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['date_shipped'] = val['date_shipped']
				? moment(val['date_shipped']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['collections_status'] = val['collections_status']
				? val['collections_status']
				: '';
		});

		if (this.state.activeTab === 'Past Due') {
			invoices = invoices.filter(
				({ due_date, invoice_status }) =>
					isAfter(new Date(), new Date(due_date)) && invoice_status !== 'Paid'
			);
		}

		this.setState({
			invoices: invoices,
			tableKey: this.state.tableKey + 1,
		});
	};

	openInvoiceModal = async (props) => {
		let auction_title = '';
		if (this.state.auction_map) {
			auction_title = this.state.auction_map[props.auction_id];
		} else if (this.state.auction_title) {
			auction_title = this.state.auction_title;
		}
		let paidDate = '';
		if (props.paid_date) {
			paidDate = moment.utc(props.paid_date).local().format('M/D/YYYY h:mm a');
		}
		this.setState({
			openInvoiceDetails: true,
			selectedInvoiceId: props.invoice_id,
			selectedInvoiceAuctionId: props.auction_id,
			auction_title: auction_title,
			paid_date: paidDate,
			selectedInvoiceNumber: props.invoice_number,
		});
		return;
	};

	handleCloseInvoiceDetails = (shouldRefresh) => {
		this.setState({
			openInvoiceDetails: false,
		});
		if (shouldRefresh) {
			this.componentDidMount();
		}
	};

	invoiceDetailsLink = (props) => {
		const foundInvoice = this.state.invoices.find(
			(invoice) => invoice.invoice_id == props.invoice_id
		);

		if (foundInvoice?.uses_checkout) {
			return (
				<Link className="link" to={`/orders/${props.invoice_number}`}>
					{props.invoice_number}
				</Link>
			);
		}

		return (
			<Link className="link" onClick={() => this.openInvoiceModal(props)}>
				{props.invoice_number}
			</Link>
		);
	};

	readableTrackingId = (props) => {
		return (
			<div>
				{Object.values(props || {}).map((tracking) => {
					return (
						<div key={`${tracking.tracking_id}`}>
							{'Vendor: ' + tracking.Vendor + ' Tracking Id: ' + tracking.tracking_id}
						</div>
					);
				})}
			</div>
		);
	};

	handleClickSendInvoices = async () => {
		let auction_id = this.state.auction;
		let res = await sendDraftInvoices(auction_id);
		this.setState({
			invoices: [],
		});
		await new Promise((r) => setTimeout(r, 5000));
		if (res) this.prepareInvoiceRows();
	};

	getComment = (props) => {
		if (props.uses_checkout) {
			if (props.comments == undefined || props.comments.length == 0) {
				return '';
			}

			const sortedComments = props.comments.sort((a, b) =>
				compareAsc(new Date(a.timestamp), new Date(b.timestamp))
			);

			return sortedComments[sortedComments.length - 1].text;
		}

		return props.comments ? props.comments : '';
	};

	componentDidMount() {
		this.generateActiveColumns(this.state.activeTab);
		this.prepareInvoiceRows();
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;
		if (prevProps.auction && prevProps.auction !== newProps.auction) {
			this.setState(
				{
					auction: this.props.auction,
					auction_title: this.props.auction_title,
					invoices: [],
				},
				() => {
					this.generateActiveColumns(this.state.activeTab);
					this.prepareInvoiceRows();
				}
			);
		}
	}

	render() {
		return (
			<Fragment>
				<Col md="12" className="display-grid">
					<Card tabs="true" className="mb-3">
						<CardHeader className="card-header-tab">
							<Nav>
								{this.tabs.map((value) => {
									return (
										<NavItem key={value}>
											<NavLink
												href="javascript:void(0);"
												className={classnames({ active: this.state.activeTab === value })}
												onClick={() => {
													this.toggle(value);
												}}
											>
												{value}
											</NavLink>
										</NavItem>
									);
								})}
							</Nav>
						</CardHeader>
						<CardBody>
							{this.state.activeTab != 'Pending Approval' || this.props.getInvoices || (
								<Button onClick={this.handleClickSendInvoices} color="primary">
									Send Draft Invoices
								</Button>
							)}
							<SortableTable
								key={this.state.tableKey}
								dataToSort={this.state.invoices}
								columns={this.state.activeColumns}
							/>
							<Dialog
								open={this.state.openInvoiceDetails}
								onClose={this.handleCloseInvoiceDetails}
								fullWidth
								maxWidth="lg"
							>
								<InvoiceDetailsCard
									auction_id={this.state.selectedInvoiceAuctionId}
									auction_title={this.state.auction_title}
									paid_date={this.state.paid_date}
									invoice_id={this.state.selectedInvoiceId}
									invoice_number={this.state.selectedInvoiceNumber}
									closeClickback={this.handleCloseInvoiceDetails}
								/>
							</Dialog>
						</CardBody>
					</Card>
				</Col>
			</Fragment>
		);
	}
}

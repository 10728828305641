import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelWeeklyAuctionSummary } from './auctionsService.model';

const getNextWeeklyAuction = async (): Promise<KernelWeeklyAuctionSummary> => {
	const response = await axiosAuth.get(`${apiEndpoints.auctions}/weekly/next`);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

export default getNextWeeklyAuction;

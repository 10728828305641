import { formatDistanceToNowStrict, isValid } from 'date-fns';
import { addDays, isAfter } from 'date-fns/esm';

const TimeRemainingCell = ({ createdAt }: { createdAt: string }) => {
	if (!isValid(new Date(createdAt))) {
		return <></>;
	}

	const expirationDate = addDays(new Date(createdAt), 1);
	const isExpired = isAfter(new Date(), expirationDate);

	if (isExpired) {
		return <>Expired</>;
	}

	return <>Expires in {formatDistanceToNowStrict(new Date(createdAt))}</>;
};

export default TimeRemainingCell;

import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import { GetInvoicesFilter } from './invoicesService.model';

/** @deprecated use getInvoicesByUserV2 */
export const getInvoicesByUser = async (
	winnerId: string,
	filters?: GetInvoicesFilter | undefined
) => {
	const response = await postAuthed(`${config.api.auction_invoices}`, {
		company_id: config.organization.company_id,
		filters,
		query_type: 'user',
		winner_id: winnerId,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

export default getInvoicesByUser;

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv';
import BalanceHistoryTable from './BalanceHistoryTabView/BalanceHistoryTable';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import MakeAdjustmentModal from './MakeAdjustmentModal';
import StripePayoutModal from './StripePayoutModal';
import {
	CashAccountEntriesResponse,
	CashAccountEntry,
	CashAccountSummary,
} from '../../../../services/cashAccountsService/cashAccountsService.model';
import { User } from '../../../../services/userService/userService.model';
import useCashAccountEntries from '../../../../queries/useCashAccountEntries';
import useLatestCashAccountEntry from '../../../../queries/useLatestCashAccountEntry';
import useUserById from '../../../../queries/useUserById';
import useCashAccountSummary from '../../../../queries/useCashAccountSummary';
import Card from '../../../../components/Card';
import { Select, Option } from '../../../../prizm-ui/Select';
import usePendingCashAccountEntries from '../../../../queries/usePendingCashAccountEntries';
import RestrictedArea from '../../../../components/RestrictedArea';
import { Button } from '../../../../prizm-ui/Button';
import { PaginationState } from '@tanstack/react-table';

const BalanceHistoryTab = () => {
	const { userId } = useParams<{ userId: string }>();

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 100,
	});

	const {
		data: cashAccountEntriesResponse = {} as CashAccountEntriesResponse,
		refetch: refetchCashAccountEntries,
		status: cashAccountEntriesStatus,
	} = useCashAccountEntries({
		userId,
		page: pagination.pageIndex,
		pageSize: pagination.pageSize,
	});

	// Export CSV still needs all items and it's ok if they take a bit longer to load
	const {
		data: allCashAccountEntriesResponse = {} as CashAccountEntriesResponse,
		status: allCashAccountEntriesStatus,
	} = useCashAccountEntries({ userId });
	const { entries: allCashAccountEntries = [] as CashAccountEntry[] } =
		allCashAccountEntriesResponse;

	const {
		entries: cashAccountEntries = [] as CashAccountEntry[],
		totalPages = 0,
		total = 0,
	} = cashAccountEntriesResponse;

	const {
		data: pendingCashAccountEntries = [] as CashAccountEntry[],
		status: pendingCashAccountEntriesStatus,
	} = usePendingCashAccountEntries(userId);

	const {
		data: latestCashAccountEntry = {} as CashAccountEntry,
		refetch: refetchLatestCashAccountEntry,
	} = useLatestCashAccountEntry(userId);

	const { data: cashAccountSummary = {} as CashAccountSummary } = useCashAccountSummary(userId);

	const { data: winner = {} as User } = useUserById(userId);

	const [showMakeAdjustmentModal, setShowMakeAdjustmentModal] = useState(false);
	const [showStripePayoutModal, setShowStripePayoutModal] = useState(false);

	const handleExportCSV = () => {
		const jsonParser = new Parser({ eol: '\n' });

		const csv = jsonParser.parse(allCashAccountEntriesResponse.entries);

		fileDownload(csv, `Balance History for ${winner.givenName} ${winner.familyName}.csv`);
	};

	const [transactionStatus, setTransactionStatus] = useState<'completed' | 'pending'>('completed');

	return (
		<Card className="px-8">
			<div className="mb-6 mt-4 flex w-full flex-col lg:flex-row items-center lg:items-end justify-between">
				<div className="flex justify-between w-full lg:w-fit">
					<div className="mr-8">
						<p className="mb-2 text-caption2">Balance</p>
						<p className="text-xl font-bold">{formatCurrency(cashAccountSummary.currentBalance)}</p>
					</div>
					<>
						<div className="mr-8">
							<p className="mb-2 text-caption2">Pending Balance</p>
							<p className="text-xl font-bold">{formatCurrency(cashAccountSummary.pendingTotal)}</p>
						</div>
						<div>
							<p className="mb-2 text-caption2">Available Balance</p>
							<p className="text-xl font-bold">{formatCurrency(cashAccountSummary.available)}</p>
						</div>
					</>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-2 mt-8 lg:mt-0 w-full lg:w-fit">
					<RestrictedArea allowedRoles={['Admin', 'Finance']}>
						<Button
							variant="secondary"
							size="md"
							onClick={() => setShowStripePayoutModal(true)}
							trailingIcon="Send"
						>
							Stripe Payout
						</Button>
						<Button
							variant="secondary"
							size="md"
							onClick={() => setShowMakeAdjustmentModal(true)}
							trailingIcon="AddCircle"
						>
							Make Adjustment
						</Button>
					</RestrictedArea>
					<Button
						disabled={
							allCashAccountEntries.length === 0 || allCashAccountEntriesStatus === 'loading'
						}
						variant="primary"
						size="md"
						onClick={handleExportCSV}
						trailingIcon="ArrowCircleDown"
					>
						Export to CSV
					</Button>
				</div>
			</div>
			<div className="my-8 w-full lg:w-1/3">
				<Select
					id="transaction-status"
					value={transactionStatus}
					onChange={({ target: { value } }) =>
						setTransactionStatus(value as 'completed' | 'pending')
					}
					label="Transaction Status"
				>
					<Option index={0} value="completed">
						Completed
					</Option>
					<Option index={1} value="pending">
						Pending
					</Option>
				</Select>
			</div>
			<BalanceHistoryTable
				cashAccountEntries={
					transactionStatus === 'completed' ? cashAccountEntries : pendingCashAccountEntries
				}
				userId={userId}
				isLoading={
					transactionStatus === 'completed'
						? cashAccountEntriesStatus === 'loading'
						: pendingCashAccountEntriesStatus === 'loading'
				}
				pagination={pagination}
				setPagination={setPagination}
				status={transactionStatus}
				totalPages={totalPages}
				totalItems={total}
			/>
			{showStripePayoutModal && (
				<StripePayoutModal
					currentBalance={latestCashAccountEntry.currentBalance}
					onClose={() => setShowStripePayoutModal(false)}
					refreshCurrentBalance={refetchLatestCashAccountEntry}
					refreshHistoryTable={refetchCashAccountEntries}
				/>
			)}
			{showMakeAdjustmentModal && (
				<MakeAdjustmentModal
					onClose={() => setShowMakeAdjustmentModal(false)}
					refreshCurrentBalance={refetchLatestCashAccountEntry}
					refreshHistoryTable={refetchCashAccountEntries}
				/>
			)}
		</Card>
	);
};

export default BalanceHistoryTab;

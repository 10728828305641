import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const deactivateUser = async (userId: string) => {
	const body = {
		disable_user_id: userId,
	};

	const response = await postAuthed(`${apiEndpoints.disable_user}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

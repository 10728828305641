import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import {
	FacetSearchQuery,
	KernelLot,
	KernelPaginatedLotsResponse,
	LotsPageSize,
	LotsQueryType,
	PaginatedLotsResponse,
} from './lotsService.model';

export const getPaginatedLots = async ({
	auctionId,
	from,
	pageSize = '24',
	queryType = 'Featured',
	facetSearchQuery = {},
}: {
	auctionId: string;
	from?: number;
	pageSize?: LotsPageSize;
	queryType?: LotsQueryType;
	facetSearchQuery: FacetSearchQuery;
}): Promise<PaginatedLotsResponse> => {
	const response = await axiosAuth.post<KernelPaginatedLotsResponse>(apiEndpoints.lots, {
		queryType,
		auction_id: [auctionId],
		from,
		size: pageSize,
		...facetSearchQuery,
	});

	if (response.status >= 400) {
		throw response;
	}

	return {
		lots: response.data.lots.map((kernelLot: KernelLot) => ({
			auctionId: kernelLot.auction_id,
			buyerPremium: kernelLot.buyer_premium,
			commission: kernelLot.commission,
			companyId: kernelLot.company_id,
			currentPrice: kernelLot.current_price,
			description: kernelLot.description,
			endTimestamp: kernelLot.end_timestamp,
			hasEndTimestampChanged: kernelLot.has_end_timestamp_changed,
			latestVersion: kernelLot.latest_version,
			id: kernelLot.lot_id,
			images: kernelLot.images,
			location: kernelLot.location,
			lotNumber: kernelLot.lot_number,
			metaDescription: kernelLot.meta_description,
			metaSlug: kernelLot.meta_slug,
			metaTitle: kernelLot.meta_title,
			minBidPrice: kernelLot.min_bid_price,
			numberOfBids: kernelLot.number_of_bids,
			numberOfImages: kernelLot.number_of_images,
			primaryImageName: kernelLot.primary_image_name,
			reserveAmount: kernelLot.reserve_amount,
			room: kernelLot.room,
			serialNumber: kernelLot.serial_number,
			startTimestamp: kernelLot.start_timestamp,
			status: kernelLot.status,
			title: kernelLot.title,
			version: kernelLot.version,
		})),
		total: response.data.total,
	};
};

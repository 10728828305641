import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ShipmentDashboard from '../../Auctions/ShipmentManager/Dashboard';
import {
	getSellerStatementsByUser,
	updateUserNotes,
	updateUserPassword,
} from '../../../AdminUtils/userUtils';
import { Button } from '@material-ui/core';
import CustomerHeader from './CustomerHeader';
import { Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { searchBidder } from '../../../AwsUtils/ElasticSearchApiUtils';
import Editor from '../../../zenauction-ui-shared/Components/Elements/Editor';
import { StripHtml } from '../../../zenauction-ui-shared/Utilities';
import { checkAccessToPage } from '../../../AdminUtils/permissionUtils';
import { NAV_ITEMS } from '../../../AdminUtils/permissionUtils';
import { fetchAuctions } from '../../../AdminUtils/auctionUtils';
import { getUserRole } from '../../../AdminUtils/userRoles';
import CustomerManagerTabs from './CustomerManagerTabs';
import {
	ALL_CUSTOMER_MANAGEMENT_TAB_PATHS,
	BASE_CUSTOMER_MANAGEMENT_TABS,
} from './CustomerManagerTabs/CustomerManagerTabs.model';
import ActivityTab from './ActivityTab';
import BalanceHistoryTab from './BalanceHistoryTab';
import BidsTab from './BidsTab';
import MergeAccountsTab from './MergeAccountsTab';
import WatchlistTab from './WatchlistTab';
import { getInvoicesByUser } from '../../../services/invoicesService';
import ConsignedLotsTab from './ConsignedLotsTab';
import SellerStatementsTab from './SellerStatementsTab';
import CustomerOverview from './CustomerOverview';
import InvoicesTab from './InvoicesTab';
import useDecision from '../../../optimizely/useDecision';
import { FeatureFlags } from '../../../optimizely/useDecision/useDecision.model';

function CustomerManager() {
	const { tabId, userId } = useParams();
	const history = useHistory();

	const possibleTabPaths = Object.values(ALL_CUSTOMER_MANAGEMENT_TAB_PATHS);
	const activeTabPath = possibleTabPaths.includes(tabId)
		? tabId
		: ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview;
	const thisTab = BASE_CUSTOMER_MANAGEMENT_TABS.find(({ path }) => path === activeTabPath);
	const thisTabLabel = thisTab?.label || 'Customer Overview';
	const disableVaultListingFF = useDecision(FeatureFlags.DisableVaultListing);

	const [state, setState] = useState({
		id: uuidv4(),
		activeTab: {
			label: thisTabLabel,
			path: activeTabPath,
		},
		users: [],
		userEditorKey: 1,
		lastSearchTerm: null,
		selectedUser: null,
		auction_map: {},
		userRole: null,
		selectedTabFromParams: {},
	});

	useEffect(() => {
		checkAccessToPage(NAV_ITEMS.ManageCustomers).then((result) => {
			if (!result) {
				window.location.href = window.location.origin;
			}
			getUserRole().then(
				(userRole) => {
					setState((prevState) => ({ ...prevState, userRole }));
				},
				(error) => {
					console.log(error);
					return false;
				}
			);
		});

		searchBidder('').then((users) => {
			setState((prevState) => ({
				...prevState,
				users: users,
				userEditorKey: uuidv4(),
			}));
		});

		fetchAuctions().then(
			(auctions) => {
				let auction_map = {};
				auctions.forEach((auction) => {
					auction_map[auction['auction_id']] = auction['title'];
				});
				setState((prevState) => ({
					...prevState,
					auction_map: auction_map,
				}));
			},
			(error) => {
				setState((prevState) => ({
					...prevState,
					errorText: error,
				}));
			}
		);

		updateUserParam();
		updateTabParam();
	}, []);

	useEffect(() => {
		if (userId) {
			updateUserParam();
		}
	}, [userId]);

	useEffect(() => {
		if (tabId) {
			if (disableVaultListingFF && tabId === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.vault) {
				setState((prevState) => ({
					...prevState,
					activeTab: {
						label: BASE_CUSTOMER_MANAGEMENT_TABS.find(
							({ path }) => path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview
						),
						path: ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview,
					},
					selectedTabFromParams: ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview,
				}));
				history.push(
					`/usermanagement/customer/${userId}/${ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview}`
				);
			}
			updateTabParam();
		}
	}, [tabId]);

	const toggle = (tab) => {
		if (state.activeTab.path !== tab.path) {
			setState((prevState) => ({
				...prevState,
				activeTab: tab,
			}));
			if (state.selectedUser) {
				history.push(`/usermanagement/customer/${state.selectedUser.user_id}/${tab.path}`);
			}
		}
	};

	const getInvoices = async (filters) => {
		const { invoices } = await getInvoicesByUser(state.selectedUser.user_id, filters);
		return invoices;
	};

	const getSellerStatements = async (filters) => {
		let statements = await getSellerStatementsByUser(state.selectedUser.user_id, filters);
		return statements;
	};

	const updateUserParam = () => {
		if (!userId) return;

		searchBidder(userId).then((results) => {
			const selectedUser = results.length > 0 ? results[0] : null;
			selectedUser['new_credit_limit'] = selectedUser['credit_limit'];
			selectedUser.payment_address_line1 = selectedUser.payment_address?.line1;
			selectedUser.payment_address_line2 = selectedUser.payment_address?.line2;
			selectedUser.payment_address_city = selectedUser.payment_address?.city;
			selectedUser.payment_address_state = selectedUser.payment_address?.state;
			selectedUser.payment_address_postal_code = selectedUser.payment_address?.postal_code;
			selectedUser.payment_address_country = selectedUser.payment_address?.country;
			setState((prevState) => ({
				...prevState,
				selectedUser: selectedUser,
				users: results,
				userEditorKey: uuidv4(),
			}));
		});
	};

	const updateTabParam = () => {
		if (tabId) {
			setState((prevState) => ({ ...prevState, selectedTabFromParams: tabId }));
		}
	};

	const handleSubmitNewPassword = async (event) => {
		event.preventDefault();
		let password = state.selectedUser.new_password;
		if (password) {
			let res = await updateUserPassword(state.selectedUser);
			if (!res) {
				window.alert(`Failed to set password!\n
                Password MUST include more than 8 characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 number.
                `);
			}
		} else {
			console.log('no new password');
			return null;
		}
	};

	const handleCommentUpdates = async (value) => {
		const user = state.selectedUser;
		user['notes'] = value;
		setState((prevState) => ({
			...prevState,
			selectedUser: user,
		}));
	};

	const handleUpdateNotes = async () => {
		let res = await updateUserNotes(state.selectedUser);
		if (!res) {
			window.alert(`Save was not successful \n`);
		} else {
			window.alert(`Save successful. \n`);
		}
		console.log('success');
	};

	return (
		<>
			<ReactCSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<div>
					{state.selectedUser && (
						<>
							<CustomerHeader />
							<Col md="12" className="display-grid">
								<Card tabs="true" className="mb-3">
									<CardHeader className="card-header-tab">
										<CustomerManagerTabs
											userRole={state.userRole}
											activeTab={state.activeTab}
											onSelect={(selectedTab) => toggle(selectedTab)}
											selectedTabFromParams={state.selectedTabFromParams}
										/>
									</CardHeader>
									<CardBody>
										<>
											{state.activeTab.path ===
												ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.customerOverview && <CustomerOverview />}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.invoices && (
												<InvoicesTab
													legacyAuctionMap={state.auction_map}
													legacyGetInvoices={getInvoices.bind(this)}
												/>
											)}
											{state.activeTab.path ===
												ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.sellerStatements && (
												<SellerStatementsTab />
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.balance && (
												<Row>
													<BalanceHistoryTab />
												</Row>
											)}
											{state.activeTab.path ===
												ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.shipmentManager && (
												<Row>
													<ShipmentDashboard
														key="Admin-Shipping-Statement"
														auction={{ title: 'Shipments' }}
														auction_map={state.auction_map}
														getInvoices={getInvoices}
													></ShipmentDashboard>
												</Row>
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.activity && (
												<ActivityTab
													userId={state.selectedUser.user_id}
													onClickTabbedLink={({ label, path }) =>
														setState((prevState) => ({
															...prevState,
															activeTab: { label, path },
														}))
													}
												/>
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.bids && (
												<BidsTab />
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.watchList && (
												<WatchlistTab userId={state.selectedUser.user_id} />
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.consignedLots && (
												<ConsignedLotsTab />
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.notes && (
												<div>
													<Editor
														placeHolder="Enter customer notes here"
														initialValue={state.selectedUser.notes || ''}
														updateContentCallback={(value) => {
															if (window.commentEventCallback)
																clearTimeout(window.commentEventCallback);
															window.commentEventCallback = setTimeout(
																() => handleCommentUpdates(StripHtml(value)),
																1000
															);
														}}
														className="m-1"
													/>
													<Button
														className="d-inline-block btn-wide btn-shadow floating-footer-button m-1 p-2 text-center"
														variant="contained"
														color="primary"
														onClick={handleUpdateNotes}
													>
														Update
													</Button>
												</div>
											)}
											{state.activeTab.path === ALL_CUSTOMER_MANAGEMENT_TAB_PATHS.mergeAccounts && (
												<MergeAccountsTab selectedFromUserId={state.selectedUser.user_id} />
											)}
										</>
									</CardBody>
								</Card>
							</Col>
						</>
					)}
				</div>
			</ReactCSSTransitionGroup>
		</>
	);
}

export default withRouter(CustomerManager);

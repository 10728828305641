import { KernelLotKey, LotsQueryType } from './lotsService.model';

/** @deprecated use LotsQueryType from lotsService.model */
export type QueryType = 'All' | 'Featured';
export type KindOfParams = 'assigned' | 'consigned' | 'search-term' | 'unassigned' | 'winner';

interface BaseLotsBySubsetParams {
	from?: number;
	include?: KernelLotKey[];
	list?: boolean;
	queryType?: LotsQueryType;
	size?: number;
}
interface LotsBySearchTermParams extends BaseLotsBySubsetParams {
	auctionId?: string;
	kind: 'search-term';
	searchTerm: string;
}
interface ConsignedLotsParams extends BaseLotsBySubsetParams {
	kind: 'consigned';
	sellerId: string;
}
interface UnassignedLotsParams extends BaseLotsBySubsetParams {
	kind: 'unassigned';
}
interface AssignedLotsParams extends BaseLotsBySubsetParams {
	auctionId: string;
	kind: 'assigned';
}
interface LotsByWinnerParams extends BaseLotsBySubsetParams {
	kind: 'winner';
	winnerId: string;
}

export type LotsBySubsetParams =
	| AssignedLotsParams
	| ConsignedLotsParams
	| LotsBySearchTermParams
	| LotsByWinnerParams
	| UnassignedLotsParams;

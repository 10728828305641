import { ArrowSortDown20Regular, ArrowSortUp20Regular } from '@fluentui/react-icons';
import { flexRender, Header } from '@tanstack/react-table';
import { useTable } from '../../../SortableTable/useTableContext';

const LotSelectorHeaders = () => {
	const { getHeaderGroups } = useTable();

	const [{ headers = [] as Header<unknown, unknown>[] }] = getHeaderGroups();
	const [lotNumberHeader, lotTitleHeader, minBidHeader] = headers;

	return (
		<>
			<div
				className="cursor-pointer col-span-3 lg:col-span-2 xl:col-span-1 py-4 text-center lg:text-left xl:text-center"
				onClick={lotNumberHeader.column.getToggleSortingHandler()}
			>
				{flexRender(lotNumberHeader.column.columnDef.header, lotNumberHeader.getContext())}
				{{
					asc: <ArrowSortUp20Regular />,
					desc: <ArrowSortDown20Regular />,
				}[lotNumberHeader.column.getIsSorted() as string] ?? null}
			</div>
			<div
				className="cursor-pointer col-span-7 lg:col-span-8 xl:col-span-9 pl-4 py-4"
				onClick={lotTitleHeader.column.getToggleSortingHandler()}
			>
				{flexRender(lotTitleHeader.column.columnDef.header, lotTitleHeader.getContext())}
				{{
					asc: <ArrowSortUp20Regular />,
					desc: <ArrowSortDown20Regular />,
				}[lotTitleHeader.column.getIsSorted() as string] ?? null}
			</div>
			<div
				className="cursor-pointer col-span-2 pr-8 py-4 text-right"
				onClick={minBidHeader.column.getToggleSortingHandler()}
			>
				{flexRender(minBidHeader.column.columnDef.header, minBidHeader.getContext())}
				{{
					asc: <ArrowSortUp20Regular />,
					desc: <ArrowSortDown20Regular />,
				}[minBidHeader.column.getIsSorted() as string] ?? null}
			</div>
		</>
	);
};

export default LotSelectorHeaders;

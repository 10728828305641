import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Tooltip } from '../../../../../prizm-ui/Tooltip';
import SortableTable from '../../../../SortableTable/SortableTable';
import { SortableTableParams } from '../../../../SortableTable/SortableTable.model';
import { KernelLotHistorySellerStatementEntry } from '../../../../../services/lotsService/lotsService.model';
import { formatDate } from '../../../../../utils/formatters';
import formatCurrency from '../../../../../utils/formatters/formatCurrency/formatCurrency';

const LotSellerStatementHistoryContent = ({
	sellerStatementHistoryEntries,
}: {
	sellerStatementHistoryEntries: KernelLotHistorySellerStatementEntry[];
}) => {
	const columns: ColumnDef<KernelLotHistorySellerStatementEntry>[] = [
		{
			header: 'Date',
			id: 'date',
			accessorFn: ({ date }) => formatDate(date),
		},
		{
			header: 'Seller',
			id: 'seller',
			cell: ({
				row: {
					original: { seller, sellerId },
				},
			}) =>
				seller ? (
					<Tooltip content={`View ${seller.fullName}'s profile`} id="seller-link-tooltip">
						<Link
							className="block link"
							to={`/usermanagement/customer/${sellerId}/customer-overview`}
						>
							{seller.fullName}
						</Link>
					</Tooltip>
				) : (
					''
				),
		},
		{
			header: 'Statement #',
			id: 'statement-number',
			accessorKey: 'sellerStatementNumber',
			cell: ({
				getValue,
				row: {
					original: { auctionId, sellerStatementNumber },
				},
			}) => (
				<Tooltip content={`View statement number ${getValue()}`} id="statement-number-link-tooltip">
					<Link
						className="block link"
						to={`/auctions/${auctionId}/statements/${sellerStatementNumber}`}
					>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			header: 'Final Price',
			id: 'final-price',
			accessorFn: ({ finalPrice }) => formatCurrency(finalPrice),
		},
		{
			header: 'Commission',
			id: 'commission',
			accessorFn: ({ commission }) => (commission ? `${commission}%` : '-'),
		},
		{
			header: 'Admin',
			id: 'admin',
			cell: ({
				row: {
					original: { modified },
				},
			}) => (modified ? modified.fullName : ''),
		},
	];

	const sortableTableParams: SortableTableParams<KernelLotHistorySellerStatementEntry> = {
		columns,
		data: sellerStatementHistoryEntries,
		enableColumnFilters: false,
		getRowId: ({ sellerStatementId }) => sellerStatementId,
	};

	return (
		<div className="my-8">
			<div className="text-subtitle2">Statements</div>
			<div className="mt-4">
				<SortableTable
					key="lot-statements-history-content"
					tableParams={sortableTableParams}
					withGlobalFilter={false}
					withPagination={false}
				/>
			</div>
		</div>
	);
};

export default LotSellerStatementHistoryContent;

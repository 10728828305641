import { useState } from 'react';
import { Button } from '../../../prizm-ui/Button';
import useIndividualLot from '../../../queries/useIndividualLot';
import { KernelLot } from '../../../services/lotsService';
import { EDITOR_ROLES, UserRoles } from '../../../services/userService/userService.model';
import Card from '../../Card';
import DefinitionList from '../../DefinitionList';
import LoadingWrapper from '../../LoadingWrapper';
import RestrictedArea from '../../RestrictedArea';
import LotAuthenticationStatusChip from '../../statusChips/LotAuthenticationStatusChip';
import LotProcessingStatusChip from '../../statusChips/LotProcessingStatusChip';
import EditLotPhysicalItemDetailsModal from './EditLotPhysicalItemDetailsModal';

const LotPhysicalItemDetails = ({ lotId }: { lotId: string }) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);

	const [shouldShowEditPhysicalItemDetailsModal, setShouldShowEditPhysicalItemDetailsModal] =
		useState(false);

	return (
		<Card>
			<LoadingWrapper queryStatuses={[lotStatus]}>
				<div className="flex justify-between">
					<h2 className="text-display5">Physical Item Details</h2>
					<RestrictedArea
						allowedRoles={[...EDITOR_ROLES, UserRoles.AuctionManager, UserRoles.CustomerSupport]}
					>
						<Button
							variant="text"
							size="md"
							icon="Edit"
							onClick={() => setShouldShowEditPhysicalItemDetailsModal(true)}
						/>
						{shouldShowEditPhysicalItemDetailsModal && (
							<EditLotPhysicalItemDetailsModal
								lot={lot}
								onClose={() => setShouldShowEditPhysicalItemDetailsModal(false)}
							/>
						)}
					</RestrictedArea>
				</div>
				<DefinitionList
					rows={[
						{
							title: 'Authentication Status',
							content: lot.authentication_status ? (
								<LotAuthenticationStatusChip status={lot.authentication_status} />
							) : (
								'-'
							),
						},
						{ title: 'Authentication Notes', content: lot.authentication_notes || '-' },
						{
							title: 'Processing Status',
							content: lot.processing_status ? (
								<LotProcessingStatusChip status={lot.processing_status} />
							) : (
								'-'
							),
						},
						{ title: 'Room', content: lot.room || '-' },
						{ title: 'Bin', content: lot.bin || '-' },
						{ title: 'Shipping Type', content: lot.enable_custom_shipping ? 'Custom' : 'Default' },
						{ title: 'Vaultable', content: lot.vaultable ? 'Yes' : 'No' },
					]}
				/>
			</LoadingWrapper>
		</Card>
	);
};

export default LotPhysicalItemDetails;

import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Button } from '../../../../../prizm-ui/Button';
import { QueryKeys } from '../../../../../queries/queryKeys';
import {
	deleteSellerStatementAdjustment,
	KernelSellerStatementAdjustment,
} from '../../../../../services/sellerStatementService';

const DeleteStatementAdjustmentButton = ({
	adjustment,
	allAdjustments,
	auctionId,
	statementId,
}: {
	adjustment: KernelSellerStatementAdjustment;
	allAdjustments: KernelSellerStatementAdjustment[];
	auctionId: string;
	statementId: string;
}) => {
	const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);
	const adjustmentsWithoutThisAdjustment = allAdjustments.filter(
		({ adjustment_id }) => adjustment_id !== adjustment.adjustment_id
	);

	const { mutate: doDeleteAdjustment, status: deleteAdjustmentStatus } = useMutation({
		mutationKey: [
			QueryKeys.DeleteSellerStatementAdjustment,
			auctionId,
			statementId,
			adjustment.adjustment_id,
		],
		mutationFn: () =>
			deleteSellerStatementAdjustment({
				auctionId,
				statementId,
				updatedAdjustments: adjustmentsWithoutThisAdjustment,
			}),
	});

	const queryClient = useQueryClient();
	const handleDeleteAdjustment = () =>
		doDeleteAdjustment(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully deleted statement adjustment.');
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementDetails]);
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementPDF]);
				setShouldShowConfirmationModal(false);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong deleting statement adjustment. If the issue persists after refreshing your browser, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<div className="w-full flex justify-center">
			<Button
				icon="Delete"
				variant="text"
				destructive={true}
				size="md"
				onClick={() => setShouldShowConfirmationModal(true)}
			/>
			{shouldShowConfirmationModal && (
				<ActionModal
					onConfirm={handleDeleteAdjustment}
					onClose={() => setShouldShowConfirmationModal(false)}
					isDangerous={true}
					isLoading={deleteAdjustmentStatus === 'loading'}
					title="Delete Adjustment"
				>
					{`Are you sure you want to delete "${adjustment.description}"?`}
				</ActionModal>
			)}
		</div>
	);
};

export default DeleteStatementAdjustmentButton;

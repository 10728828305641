import React, { useState } from 'react';
import { showErrorToast } from '../../../../components/Toast';
import { Button } from '../../../../prizm-ui/Button';
import { getPickOrderSheets } from '../../../../services/ordersService/getPickOrderSheets';

const PrintPickSheetsButton = () => {
	const [getPickSheetsStatus, setGetPickSheetsStatus] = useState('idle');

	const openTab = (pickSheetsHtmlBlob: Blob) => {
		const pickSheetsUrl = URL.createObjectURL(pickSheetsHtmlBlob);

		window.open(pickSheetsUrl, '_blank');
	};

	const getPickSheets = () => {
		setGetPickSheetsStatus('loading');
		getPickOrderSheets()
			.then((pickSheetsHtmlBlob) => {
				setGetPickSheetsStatus('success');
				openTab(pickSheetsHtmlBlob);
			})
			.catch((err) => {
				setGetPickSheetsStatus('error');
				showErrorToast(`Error loading pick sheet: ${JSON.stringify(err)}`);
			});
	};

	return (
		<div>
			<Button
				variant="secondary"
				className="ml-2"
				onClick={getPickSheets}
				loading={getPickSheetsStatus === 'loading'}
				size="md"
				trailingIcon="Print"
			>
				Print Pick Sheets (Limit 50)
			</Button>
		</div>
	);
};

export default PrintPickSheetsButton;

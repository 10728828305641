import { axiosAuth } from '../../AdminUtils/httpUtils';
import { isEmail } from '../../utils/validators';
import apiEndpoints from '../../queries/apiEndpoints';
import { FixedPriceOrder, KernelFixedPriceOrder } from './ordersService.model';
import { FixedPriceOrderStatusType } from './ordersService.model';
import { SearchByQueryOrDateRangeParams } from '../../Pages/fixed-price/seller-summary/SearchByQueryOrDateRange/SearchByQueryOrDateRange.model';

export const getFixedPriceOrders = async ({
	fromDate,
	searchQuery,
	searchType,
	status,
	toDate,
}: SearchByQueryOrDateRangeParams<FixedPriceOrderStatusType>) => {
	const rawParamsForQuerySearch: Record<string, string> = isEmail(searchQuery)
		? { winner_email: searchQuery }
		: { invoice_number: searchQuery };

	if (isEmail(searchQuery) && fromDate.length > 0) {
		rawParamsForQuerySearch.from_date = fromDate;
	}

	if (isEmail(searchQuery) && toDate.length > 0) {
		rawParamsForQuerySearch.to_date = toDate;
	}

	const rawParamsForDateRangeSearch: Record<string, string> = {
		invoice_status: status,
		from_date: fromDate,
		to_date: toDate,
	};

	const params = searchType === 'query' ? rawParamsForQuerySearch : rawParamsForDateRangeSearch;

	const response = await axiosAuth.get<KernelFixedPriceOrder[]>(
		`${apiEndpoints.get_fixed_price_orders}`,
		{ params }
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.map((fixedPriceOrder) => ({
		...(fixedPriceOrder as FixedPriceOrder),
		comments:
			typeof fixedPriceOrder.comments === 'string'
				? [
						{
							kind: 'unknown',
							text: fixedPriceOrder.comments,
							timestamp: '',
						},
				  ]
				: fixedPriceOrder.comments,
	}));
};

import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import Card from '../../../../components/Card';
import SortableTable from '../../../../components/SortableTable';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import { Button } from '../../../../prizm-ui/Button';
import { Input } from '../../../../prizm-ui/Input';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import useLotsBySubset from '../../../../queries/useLotsBySubset';
import { getChipSentimentForFixedPriceItemStatus } from '../../../../services/lotsService';
import {
	FixedPriceItemStatus,
	KernelLot,
} from '../../../../services/lotsService/lotsService.model';
import { formatDate, stripUnderscores } from '../../../../utils/formatters';
import { Tag } from '../../../../prizm-ui/Tag';

const SearchFixedPriceListings = () => {
	const { query = '' } = useParams<{ query?: string }>();
	const decodedQuery = decodeURIComponent(query);
	const history = useHistory();

	const [draftSearchQuery, setDraftSearchQuery] = useState(decodedQuery);
	const [submittedSearchQuery, setSubmittedSearchQuery] = useState(draftSearchQuery);
	const handleSubmitSearchQuery = () => {
		setSubmittedSearchQuery(draftSearchQuery);
		history.push(`/fixed-price/search/${encodeURIComponent(draftSearchQuery)}`);
	};

	const { data: lots = [] as KernelLot[], status: lotsStatus } = useLotsBySubset({
		kind: 'search-term',
		searchTerm: submittedSearchQuery,
		auctionId: 'fixed_price_marketplace',
	});

	const columns: ColumnDef<KernelLot>[] = [
		{
			accessorKey: 'serial_number',
			header: 'Serial',
			cell: ({ getValue, row: { id } }) => (
				<Tooltip id="view-listing" content="View Listing">
					<Link to={`/fixed-price/listings/${id}`} className="link">
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{ accessorKey: 'cert_number', header: 'Cert No.' },
		{
			accessorKey: 'title',
			header: 'Title',
		},
		{
			/** Keeping 'Completed_Sold' as the actual cell value breaks column sorting */
			accessorFn: ({ status = '' }) => (status === 'Completed_Sold' ? 'Sold' : status),
			header: 'Status',
			enableGlobalFilter: false,
			cell: ({
				getValue,
				row: {
					original: { status },
				},
			}) => {
				return (
					<div className="w-fit">
						<Tag variant={getChipSentimentForFixedPriceItemStatus(status as FixedPriceItemStatus)}>
							{stripUnderscores(getValue() as FixedPriceItemStatus)}
						</Tag>
					</div>
				);
			},
		},
		{
			accessorKey: 'current_price',
			header: 'Price',
			cell: ({ getValue }) => <span>{formatCurrency(getValue() as number)}</span>,
			enableGlobalFilter: false,
		},
		{
			accessorFn: (row) => formatDate(row.created_at),
			header: 'Created',
			enableGlobalFilter: false,
		},
		{ accessorFn: (row) => row.number_of_bids, header: 'Offers', enableGlobalFilter: false },
		{
			accessorFn: (row) => row.seller_email,
			header: 'Seller',
			cell: ({ getValue, row }) => {
				const {
					original: { seller_id: sellerId },
				} = row;
				const sellerEmail = getValue<string>();

				if (sellerEmail && sellerId) {
					return (
						<Tooltip content="View Seller Profile" id="view-seller-profile">
							<Link className="link" to={`/usermanagement/customer/${sellerId}/customer-overview`}>
								{sellerEmail}
							</Link>
						</Tooltip>
					);
				}

				return 'Not Found';
			},
		},
	];

	const sortableTableParams: SortableTableParams<KernelLot> = {
		data: lots,
		columns,
		getRowId: ({ lot_id }) => lot_id,
	};

	const noResultsMessage = submittedSearchQuery
		? 'No results to show.'
		: 'Please enter search term to begin.';

	return (
		<Card>
			<h1 className="text-display4">Search Fixed Price Listings</h1>
			<div className="flex flex-col lg:flex-row lg:items-center mt-2">
				<Input
					id="search-customer-input"
					className="flex-grow mb-2 lg:mb-0 lg:mr-2"
					label="Search by title, serial, or cert number ..."
					type="text"
					value={draftSearchQuery}
					onChange={({ target: { value } }) => setDraftSearchQuery(value)}
					onKeyDown={({ key }) => key === 'Enter' && handleSubmitSearchQuery()}
				/>
				<Button
					variant="primary"
					trailingIcon="Search"
					disabled={submittedSearchQuery === draftSearchQuery}
					onClick={handleSubmitSearchQuery}
				>
					Search
				</Button>
			</div>
			<div className="mt-4">
				<SortableTable
					globalFilterPlaceholder="Filter ..."
					tableParams={sortableTableParams}
					isLoading={lotsStatus === 'loading'}
					noResultsMessage={noResultsMessage}
				/>
			</div>
		</Card>
	);
};

export default SearchFixedPriceListings;

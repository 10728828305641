import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { CreateAuctionParams } from './auctionsService.model';

export const createAuction = async (body: CreateAuctionParams) => {
	const response = await axiosAuth.post(`${config.api.auctions}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../reducers/configureStore';
import { ToastContainer } from 'react-toastify';
import OptimizelyProvider from '../optimizely/OptimizelyProvider';

const store = configureStore();
const queryClient = new QueryClient();

const App: FC = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<OptimizelyProvider>
					<HashRouter>
						{children}
						<ToastContainer
							className="toaster-container"
							position="bottom-center"
							autoClose={1500}
							hideProgressBar={true}
							newestOnTop={false}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</HashRouter>
				</OptimizelyProvider>
			</Provider>
		</QueryClientProvider>
	);
};

export default App;

import { noop } from 'lodash';
import React from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import type { DropdownMenuResponse } from './types';

export const MenuContext = React.createContext<{
	menu?: DropdownMenuResponse<HTMLButtonElement>;
	onSelect?: (value?: string) => void;
	registerItem: () => void;
	removeItem: () => void;
}>({
	menu: undefined,
	onSelect: noop,
	registerItem: noop,
	removeItem: noop,
});

export type MenuProps = {
	onSelect?: (value?: string) => void;
};

export const Menu: React.FC<MenuProps> = ({ children, onSelect }) => {
	const [itemCount, setItemCount] = React.useState(0);
	const menu = useDropdownMenu(itemCount);
	const registerItem = () => setItemCount((ic) => (ic += 1));
	const removeItem = () => setItemCount((ic) => (ic -= 1));
	return (
		<MenuContext.Provider value={{ menu, onSelect, registerItem, removeItem }}>
			<div className="relative">{children}</div>
		</MenuContext.Provider>
	);
};

import apiEndpoints from '../../queries/apiEndpoints';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const updateCashAccount = async ({
	amount,
	auctionId,
	category,
	description,
	internalNotes,
	transactionId,
	userId,
}: {
	amount: number;
	auctionId?: string;
	category: string;
	description: string;
	internalNotes?: string;
	transactionId?: string;
	userId: string;
}) => {
	const body = {
		amount,
		category,
		description,
		...(auctionId && { auctionId }),
		...(internalNotes && { internalNotes }),
		...(transactionId && { transactionId }),
	};

	const response = await postAuthed(`${apiEndpoints.cash_accounts}/${userId}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

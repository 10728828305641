import moment from 'moment';
import { useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import useAuctionById from '../../../../queries/useAuctionById';
import useInvoiceById from '../../../../queries/useInvoiceById';
import { Auction } from '../../../../services/auctionsService';
import { KernelInvoice } from '../../../../services/invoicesService/invoicesService.model';
import CheckoutOrderPickSheet from '../Dashboard/CheckoutOrderPickSheet';
import PickSheet from '../PickSheet';

const PickSheetPage = () => {
	const { auctionId = '', invoiceId = '' } = useParams<{ auctionId: string; invoiceId: string }>();

	const { data: invoice = {} as KernelInvoice, status: invoiceStatus } = useInvoiceById({
		auctionId,
		invoiceId,
	});

	const { data: auction = {} as Auction, status: auctionStatus } = useAuctionById(auctionId);

	return (
		<LoadingWrapper queryStatuses={[invoiceStatus, auctionStatus]}>
			{invoice.uses_checkout ? (
				<Card>
					<CheckoutOrderPickSheet orderNumber={invoice.invoice_number} />
				</Card>
			) : (
				<PickSheet
					auction_id={auctionId}
					auction_title={auction.title}
					paid_date={moment.utc(invoice.paid_date).local().format('M/D/YYYY h:mm a')}
					invoice_id={invoiceId}
					invoice_number={invoice.invoice_number}
				/>
			)}
		</LoadingWrapper>
	);
};

export default PickSheetPage;

import sideBarImage from '../assets/utils/images/sidebar/abstract-blue.jpg';

export const SET_AUCTIONS = 'THEME_OPTIONS/SET_AUCTIONS';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';
export const SET_CHALLENGE_NAME = 'THEME_OPTIONS/SET_CHALLENGE_NAME';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_COMPANY_ADDRESS = 'THEME_OPTIONS/SET_COMPANY_ADDRESS';
export const SET_COMPANY_EMAIL = 'THEME_OPTIONS/SET_COMPANY_EMAIL';
export const SET_COMPANY_ID = 'THEME_OPTIONS/SET_COMPANY_ID';
export const SET_COMPANY_NAME = 'THEME_OPTIONS/SET_COMPANY_NAME';
export const SET_COMPANY_SOCIAL = 'THEME_OPTIONS/SET_COMPANY_SOCIAL';
export const SET_COMPANY_TELEPHONE = 'THEME_OPTIONS/SET_COMPANY_TELEPHONE';
export const SET_COMPANY_WEBSITE = 'THEME_OPTIONS/SET_COMPANY_WEBSITE';
export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';
export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';
export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';
export const SET_IS_AUTHENTICATED = 'THEME_OPTIONS/SET_IS_AUTHENTICATED';
export const SET_IS_AUTHENTICATING = 'THEME_OPTIONS/SET_IS_AUTHENTICATING';
export const SET_USER = 'THEME_OPTIONS/SET_USER';

export const setEnableBackgroundImage = (enableBackgroundImage) => ({
	type: SET_ENABLE_BACKGROUND_IMAGE,
	enableBackgroundImage,
});

export const setEnableFixedHeader = (enableFixedHeader) => ({
	type: SET_ENABLE_FIXED_HEADER,
	enableFixedHeader,
});

export const setEnableHeaderShadow = (enableHeaderShadow) => ({
	type: SET_ENABLE_HEADER_SHADOW,
	enableHeaderShadow,
});

export const setEnableSidebarShadow = (enableSidebarShadow) => ({
	type: SET_ENABLE_SIDEBAR_SHADOW,
	enableSidebarShadow,
});

export const setEnablePageTitleIcon = (enablePageTitleIcon) => ({
	type: SET_ENABLE_PAGETITLE_ICON,
	enablePageTitleIcon,
});

export const setEnablePageTitleSubheading = (enablePageTitleSubheading) => ({
	type: SET_ENABLE_PAGETITLE_SUBHEADING,
	enablePageTitleSubheading,
});

export const setEnablePageTabsAlt = (enablePageTabsAlt) => ({
	type: SET_ENABLE_PAGE_TABS_ALT,
	enablePageTabsAlt,
});

export const setEnableFixedSidebar = (enableFixedSidebar) => ({
	type: SET_ENABLE_FIXED_SIDEBAR,
	enableFixedSidebar,
});

export const setEnableClosedSidebar = (enableClosedSidebar) => ({
	type: SET_ENABLE_CLOSED_SIDEBAR,
	enableClosedSidebar,
});

export const setEnableMobileMenu = (enableMobileMenu) => ({
	type: SET_ENABLE_MOBILE_MENU,
	enableMobileMenu,
});

export const setEnableMobileMenuSmall = (enableMobileMenuSmall) => ({
	type: SET_ENABLE_MOBILE_MENU_SMALL,
	enableMobileMenuSmall,
});

export const setEnableFixedFooter = (enableFixedFooter) => ({
	type: SET_ENABLE_FIXED_FOOTER,
	enableFixedFooter,
});

export const setBackgroundColor = (backgroundColor) => ({
	type: SET_BACKGROUND_COLOR,
	backgroundColor,
});

export const setHeaderBackgroundColor = (headerBackgroundColor) => ({
	type: SET_HEADER_BACKGROUND_COLOR,
	headerBackgroundColor,
});

export const setColorScheme = (colorScheme) => ({
	type: SET_COLOR_SCHEME,
	colorScheme,
});

export const setBackgroundImageOpacity = (backgroundImageOpacity) => ({
	type: SET_BACKGROUND_IMAGE_OPACITY,
	backgroundImageOpacity,
});

export const setBackgroundImage = (backgroundImage) => ({
	type: SET_BACKGROUND_IMAGE,
	backgroundImage,
});

export const setCompanyName = (companyName) => ({
	type: SET_COMPANY_NAME,
	companyName,
});

export const setCompanyWebsite = (companyWebsite) => ({
	type: SET_COMPANY_WEBSITE,
	companyWebsite,
});

export const setCompanyAddress = (companyAddress) => ({
	type: SET_COMPANY_ADDRESS,
	companyAddress,
});

export const setCompanyTelephone = (companyTelephone) => ({
	type: SET_COMPANY_TELEPHONE,
	companyTelephone,
});

export const setCompanyEmail = (companyEmail) => ({
	type: SET_COMPANY_EMAIL,
	companyEmail,
});

export const setCompanySocial = (companySocial) => ({
	type: SET_COMPANY_SOCIAL,
	companySocial,
});

export const setCompanyId = (companyId) => ({
	type: SET_COMPANY_ID,
	companyId,
});

export const setIsAuthenticated = (isAuthenticated) => ({
	type: SET_IS_AUTHENTICATED,
	isAuthenticated,
});

export const setIsAuthenticating = (isAuthenticating) => ({
	type: SET_IS_AUTHENTICATING,
	isAuthenticating,
});

export const setUser = (user) => ({
	type: SET_USER,
	user,
});

export const setChallengeName = (challengeName) => ({
	type: SET_CHALLENGE_NAME,
	challengeName,
});

export const setAuctions = (auctions) => ({
	type: SET_AUCTIONS,
	auctions,
});

export default function reducer(
	state = {
		backgroundColor: 'bg-royal sidebar-text-light',
		headerBackgroundColor: 'bg-strong-bliss header-text-light',
		enableMobileMenuSmall: '',
		enableBackgroundImage: true,
		enableClosedSidebar: false,
		enableFixedHeader: true,
		enableHeaderShadow: true,
		enableSidebarShadow: true,
		enableFixedFooter: true,
		enableFixedSidebar: true,
		colorScheme: 'white',
		backgroundImage: sideBarImage,
		backgroundImageOpacity: 'opacity-1',
		enablePageTitleIcon: true,
		enablePageTitleSubheading: true,
		enablePageTabsAlt: false,
		companyName: 'Goldin Auctions',
		companyWebsite: 'https://goldinauctions.com',
		companyAddress: '160 E. Ninth Avenue, Suite A, Runnemede, NJ 08078',
		companyTelephone: '856.767.8550',
		companyEmail: 'info@goldinauctions.com',
		companySocial: {
			facebook: 'https://www.facebook.com/GoldinAuctions',
			twitter: 'https://twitter.com/GoldinAuctions',
		},
		companyId: 'f607a2e3-81a9-4e61-91f9-d7d0b8d01e34',
		isAuthenticated: false,
		isAuthenticating: true,
		user: null,
		challengeName: null,
		auctions: [],
	},
	action
) {
	switch (action.type) {
		case SET_COMPANY_NAME:
			return {
				...state,
				companyName: action.companyName,
			};

		case SET_COMPANY_WEBSITE:
			return {
				...state,
				companyWebsite: action.companyWebsite,
			};

		case SET_COMPANY_ADDRESS:
			return {
				...state,
				companyAddress: action.companyAddress,
			};

		case SET_COMPANY_TELEPHONE:
			return {
				...state,
				companyTelephone: action.companyTelephone,
			};

		case SET_COMPANY_EMAIL:
			return {
				...state,
				companyEmail: action.companyEmail,
			};

		case SET_COMPANY_SOCIAL:
			return {
				...state,
				companySocial: action.companySocial,
			};

		case SET_COMPANY_ID:
			return {
				...state,
				companyId: action.companyId,
			};

		case SET_ENABLE_BACKGROUND_IMAGE:
			return {
				...state,
				enableBackgroundImage: action.enableBackgroundImage,
			};

		case SET_ENABLE_FIXED_HEADER:
			return {
				...state,
				enableFixedHeader: action.enableFixedHeader,
			};

		case SET_ENABLE_HEADER_SHADOW:
			return {
				...state,
				enableHeaderShadow: action.enableHeaderShadow,
			};

		case SET_ENABLE_SIDEBAR_SHADOW:
			return {
				...state,
				enableSidebarShadow: action.enableSidebarShadow,
			};

		case SET_ENABLE_PAGETITLE_ICON:
			return {
				...state,
				enablePageTitleIcon: action.enablePageTitleIcon,
			};

		case SET_ENABLE_PAGETITLE_SUBHEADING:
			return {
				...state,
				enablePageTitleSubheading: action.enablePageTitleSubheading,
			};

		case SET_ENABLE_PAGE_TABS_ALT:
			return {
				...state,
				enablePageTabsAlt: action.enablePageTabsAlt,
			};

		case SET_ENABLE_FIXED_SIDEBAR:
			return {
				...state,
				enableFixedSidebar: action.enableFixedSidebar,
			};

		case SET_ENABLE_MOBILE_MENU:
			return {
				...state,
				enableMobileMenu: action.enableMobileMenu,
			};

		case SET_ENABLE_MOBILE_MENU_SMALL:
			return {
				...state,
				enableMobileMenuSmall: action.enableMobileMenuSmall,
			};

		case SET_ENABLE_CLOSED_SIDEBAR:
			return {
				...state,
				enableClosedSidebar: action.enableClosedSidebar,
			};

		case SET_ENABLE_FIXED_FOOTER:
			return {
				...state,
				enableFixedFooter: action.enableFixedFooter,
			};

		case SET_BACKGROUND_COLOR:
			return {
				...state,
				backgroundColor: action.backgroundColor,
			};

		case SET_HEADER_BACKGROUND_COLOR:
			return {
				...state,
				headerBackgroundColor: action.headerBackgroundColor,
			};

		case SET_COLOR_SCHEME:
			return {
				...state,
				colorScheme: action.colorScheme,
			};

		case SET_BACKGROUND_IMAGE:
			return {
				...state,
				backgroundImage: action.backgroundImage,
			};

		case SET_BACKGROUND_IMAGE_OPACITY:
			return {
				...state,
				backgroundImageOpacity: action.backgroundImageOpacity,
			};
		case SET_IS_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: action.isAuthenticated,
			};
		case SET_IS_AUTHENTICATING:
			return {
				...state,
				isAuthenticating: action.isAuthenticating,
			};
		case SET_USER:
			return {
				...state,
				user: action.user,
			};
		case SET_CHALLENGE_NAME:
			return {
				...state,
				challengeName: action.challengeName,
			};
		case SET_AUCTIONS:
			return {
				...state,
				auctions: action.auctions,
			};
	}
	return state;
}

import React from 'react';
import { MenuContext } from './Menu';

export const MenuList: React.FC = ({ children }) => {
	const { menu } = React.useContext(MenuContext);
	const isOpen = !!menu?.isOpen;
	return (
		<div
			role="menu"
			className={[
				'flex',
				'flex-col',
				'absolute',
				'z-10',
				'top-full',
				'right-0',
				'p-2',
				'mt-2',
				'bg-neutralbkg1',
				'border',
				'border-neutralstroke2',
				'rounded-xl',
				'shadow-elevation2',
				'whitespace-nowrap',
				...(isOpen ? ['visible'] : ['invisible']),
			].join(' ')}
		>
			{children}
		</div>
	);
};

import { isEqual } from 'lodash';
import React, { ChangeEventHandler } from 'react';
import { useMutation } from 'react-query';
import { Slide, toast } from 'react-toastify';
import { getFilenameForLegacyImage } from '../../../../../AdminUtils/imageUtils.helpers';
import Button from '../../../../../components/Button';
import ImageUploadDropzone from '../../../../../components/ImageUploadDropzone';
import Input from '../../../../../components/Input';
import Label from '../../../../../components/Label';
import Toast from '../../../../../components/Toast';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useImageUrl from '../../../../../queries/useImageUrl';
import { updateBanners } from '../../../../../services/layoutService';
import { Banner } from '../../../../../services/layoutService/layoutService.model';
import { getStoredItemPath } from '../../../../../services/storageService/storageService.utils';
import { useManageBannersContext } from '../ManageBanners/useManageBannersState';
import {
	emptyBanner,
	ManageBannersActionType,
} from '../ManageBanners/useManageBannersState/useManageBannersState.model';
import { BannerContentProps } from './BannerContent.model';

const BannerContent = ({ bannerId, kind }: BannerContentProps) => {
	const { dispatch, manageBannersState } = useManageBannersContext();

	/** A banner in motion will pass down the correct id, but the rest of the object will contain drag-and-drop
	 * internals. Passing down the banner as a prop would have been nice, but due to this discrepancy you'd see
	 * banner content (title, url) go blank when the item is being dragged. Grabbing the item out of state will
	 * ensure that the full banner item is in scope.
	 */
	const getBannerFromState = (bannerId: string): Banner =>
		manageBannersState.sortedBanners.find(({ id }) => id === bannerId) || emptyBanner;
	const thisBanner: Banner =
		kind === 'new' ? manageBannersState.newBanner : getBannerFromState(bannerId);

	const UPDATE_BANNER_ACTION: ManageBannersActionType =
		kind === 'new' ? 'UPDATE_NEW_BANNER' : 'UPDATE_BANNER_CONTENT';

	const bannerImageFilename = getFilenameForLegacyImage({ imageIdentifier: thisBanner.id });

	const handleBannerTitleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		dispatch({
			type: UPDATE_BANNER_ACTION,
			payload: { ...thisBanner, title: value },
		});
	};
	const handleBannerUrlChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		dispatch({
			type: UPDATE_BANNER_ACTION,
			payload: { ...thisBanner, url: value },
		});
	};
	const handleBannerIdChange = (value: string) => {
		dispatch({
			type: UPDATE_BANNER_ACTION,
			payload: { ...thisBanner, id: value },
		});
	};

	const hasChanges =
		kind === 'new'
			? !isEqual(thisBanner, emptyBanner)
			: !isEqual(
					thisBanner,
					manageBannersState.originalBanners.find(({ id }) => id === thisBanner.id)
			  );

	const { data: bannerImageUrl = '' } = useImageUrl({
		storageProvider: 's3',
		rootPath: 'CarouselImages',
		parentPath: 'home',
		itemId: bannerImageFilename,
		isEnabled: kind === 'existing' || !!thisBanner.id,
	});

	const { mutate: doUpdateBanners, status: updateBannersStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateBanners, ...Object.entries(manageBannersState.sortedBanners)],
		mutationFn: (sortedBanners: Banner[]) => updateBanners(sortedBanners),
	});

	const saveChanges = () => {
		const updatedBanners =
			kind === 'new'
				? [...manageBannersState.sortedBanners, thisBanner]
				: manageBannersState.sortedBanners;

		doUpdateBanners(updatedBanners, {
			onSuccess: () => {
				toast(<Toast message="Successfully updated banners." sentiment="success" />, {
					transition: Slide,
				});
				if (kind === 'new') {
					dispatch({
						type: 'COMMIT_NEW_BANNER',
						payload: thisBanner,
					});
				} else {
					dispatch({
						type: 'COMMIT_BANNER_CHANGES',
						payload: updatedBanners,
					});
				}
			},
			onError: () => {
				toast(
					<Toast message="Error updating banners. Please try again later." sentiment="warning" />,
					{
						transition: Slide,
					}
				);
			},
		});
	};

	const cancelChanges = () => dispatch({ type: 'DISCARD_NEW_BANNER_CHANGES' });

	return (
		<>
			{kind === 'new' && !thisBanner.id ? (
				<ImageUploadDropzone
					bucketPath={getStoredItemPath({ rootPath: 'CarouselImages', parentPath: 'home' })}
					onSuccess={handleBannerIdChange}
					className="bg-neutral1"
				/>
			) : (
				<img src={bannerImageUrl} />
			)}
			<div className="mt-4 w-full">
				<div>
					<Label>Title</Label>
					<Input
						className="w-full"
						value={thisBanner.title}
						type="text"
						onChange={handleBannerTitleChange}
					/>
				</div>
				<div className="mt-2">
					<Label>Click-Through URL</Label>
					<Input
						className="w-full"
						value={thisBanner.url}
						type="text"
						onChange={handleBannerUrlChange}
					/>
				</div>
				{kind === 'new' && (
					<div className="mt-4 flex">
						<Button
							kind="primary"
							size="medium"
							disabled={!hasChanges || !thisBanner.id || updateBannersStatus === 'loading'}
							className="mr-2 flex items-center"
							type="submit"
							onClick={saveChanges}
							isLoading={updateBannersStatus === 'loading'}
						>
							Save Changes
						</Button>
						<Button
							kind="tertiary"
							size="medium"
							disabled={!hasChanges || updateBannersStatus === 'loading'}
							onClick={cancelChanges}
						>
							Cancel
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default BannerContent;

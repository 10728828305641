import React from 'react';
import useUserRole from '../../../queries/useUserRole';
import { UserRoleName } from '../../../services/userService/userService.model';
import NavSection from './NavSection';
import { getNavSectionsForRole } from './SideBar.model';

const Sidebar = () => {
	const { data: role = 'Unknown' as UserRoleName } = useUserRole();
	const navSections = getNavSectionsForRole(role);

	return (
		<div className="sticky w-full overflow-y-auto bg-neutral1 pt-6 shadow-elevation1 md:w-72">
			{navSections.map(({ label, items }) => (
				<NavSection label={label} items={items} key={label} />
			))}
		</div>
	);
};

export default Sidebar;

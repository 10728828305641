import React from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';

const EndLotModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const diffs: Partial<KernelLot> = {
		lot_id: lot.lot_id,
		has_end_timestamp_changed: true,
		status: 'End',
		lot_status_changed: 'End',
		end_timestamp: new Date().toISOString(),
	};
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id],
		mutationFn: (updates: Partial<KernelLot>) => updateLot(updates),
	});

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const handleEndLot = () =>
		doUpdateLot(diffs, {
			onSuccess: (updatedLot: KernelLot) => {
				updateLotInCache(updatedLot);
				showSuccessToast('Successfully ended lot.');
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong ending lot. Please try again later.');
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			title="End Lot"
			onConfirm={handleEndLot}
			isLoading={updateLotStatus === 'loading'}
			className="max-w-xl"
		>
			{`"${lot.title}" will end now. Are you sure?`}
		</ActionModal>
	);
};

export default EndLotModal;

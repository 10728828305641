import { ColumnDef } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Card from '../../../components/Card';
import DownloadCSVButton from '../../../components/DownloadCSVButton';
import Label from '../../../components/Label';
import SortableTable from '../../../components/SortableTable';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import useFixedPriceSellerStatements from '../../../queries/useFixedPriceSellerStatements';
import {
	BLANK_FIXED_PRICE_QUERY_PARAMS,
	KernelFixedPriceSellerStatement,
	SellerStatementStatusType,
} from '../../../services/sellerStatementService';
import { formatDate, getQueryParamsObject, getQueryString } from '../../../utils/formatters';
import FixedPriceSellerStatementSearchForm from './FixedPriceSellerStatementSearchForm';
import { BaseFixedPriceSellerStatementSearchParams } from './FixedPriceSellerStatementSearchForm/FixedPriceSellerStatementSearchForm.model';
import StatementNumberCell from '../../../components/SortableTable/cells/StatementNumberCell';
import { FIXED_PRICE_SELLER_STATEMENT_SUMMARY_CSV_COLUMNS } from './FixedPriceSellerSummaryPage.model';
import { currencyColumn } from '../../../components/SortableTable/columns';
import SellerStatementStatusChip from '../../../components/statusChips/SellerStatementStatusChip';
import { Button } from '../../../prizm-ui/Button';
import BulkUploadSellerStatementAdjustmentsModal from '../../../components/BulkUploadSellerStatementAdjustmentsModal';
import { FieldInfo } from 'json2csv';

const FixedPriceSellerSummaryPage = () => {
	const history = useHistory();

	const { search } = useLocation();
	const paramsFromUrl = getQueryParamsObject<BaseFixedPriceSellerStatementSearchParams>(search);

	const initialSearchParams: BaseFixedPriceSellerStatementSearchParams = {
		...BLANK_FIXED_PRICE_QUERY_PARAMS,
		...paramsFromUrl,
	};

	const [searchParams, setSearchParams] = useState(initialSearchParams);

	useEffect(() => {
		if (!isEqual(initialSearchParams, searchParams)) {
			setSearchParams(initialSearchParams);
		}
	}, [initialSearchParams]);

	const updateSearchParams = (newParams: BaseFixedPriceSellerStatementSearchParams) => {
		setSearchParams(newParams);

		const paramsForQueryString: Partial<BaseFixedPriceSellerStatementSearchParams> =
			newParams.searchType === 'search-query'
				? { searchType: 'search-query', searchQuery: newParams.searchQuery }
				: {
						searchType: 'date-range',
						fromDate: newParams.fromDate,
						toDate: newParams.toDate,
						status: newParams.status,
				  };
		if (newParams.searchType === 'date-range' && !newParams.fromDate) {
			delete paramsForQueryString.fromDate;
		}
		if (newParams.searchType === 'date-range' && !newParams.toDate) {
			delete paramsForQueryString.toDate;
		}
		const queryString = getQueryString(paramsForQueryString);

		history.push(`/fixed-price/seller-summary?${queryString}`);
	};

	const {
		data: fixedPriceSellerStatements = [] as KernelFixedPriceSellerStatement[],
		status: fixedPriceSellerStatementsStatus,
	} = useFixedPriceSellerStatements(searchParams);

	const columns: ColumnDef<KernelFixedPriceSellerStatement>[] = [
		{
			accessorKey: 'sellerStatementNumber',
			header: 'Statement Number',
			cell: ({ row: { original: sellerStatement } }) => (
				<StatementNumberCell
					urlSchema="fixed-price"
					statementId={sellerStatement.sellerStatementId}
					statementNumber={sellerStatement.sellerStatementNumber}
					stripePayoutAccountId={sellerStatement.sellerStripePayoutAccountId}
					auctionId="fixed_price_marketplace"
				/>
			),
		},
		{
			accessorKey: 'sellerStatementStatus',
			header: () => <div className="text-right">Status</div>,
			cell: ({ getValue }) => (
				<div className="text-right">
					<SellerStatementStatusChip status={getValue() as SellerStatementStatusType} />
				</div>
			),
		},
		{
			accessorKey: 'sellerName',
			header: 'Seller Name',
			cell: ({
				getValue,
				row: {
					original: { sellerId },
				},
			}) => (
				<Tooltip id={`view-seller-${sellerId}`} content={`View ${getValue()}'s Profile`}>
					<Link className="link" to={`/usermanagement/customer/${sellerId}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: 'sellerEmail',
			header: 'Seller Email',
		},
		{
			accessorKey: 'createdAt',
			header: 'Statement Date',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		currencyColumn<KernelFixedPriceSellerStatement>({
			header: 'Total Lot Value',
			accessorKey: 'totalLotValue',
		}),
		currencyColumn<KernelFixedPriceSellerStatement>({
			header: 'Commission',
			accessorKey: 'commission',
		}),
		currencyColumn<KernelFixedPriceSellerStatement>({
			header: 'Net Adjustments',
			accessorKey: 'netAdjustments',
		}),
		currencyColumn<KernelFixedPriceSellerStatement>({
			header: 'Total Statement Amount',
			accessorKey: 'totalStatementAmount',
		}),
		currencyColumn<KernelFixedPriceSellerStatement>({
			header: 'Total Ready for Payout',
			accessorKey: 'totalReadyForPayout',
		}),
		{
			accessorKey: 'payoutDate',
			enableGlobalFilter: false,
			header: 'Payout Date',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		{
			accessorKey: 'comments',
			enableGlobalFilter: false,
			header: 'Comments',
		},
	];

	const tableParams: SortableTableParams<KernelFixedPriceSellerStatement> = {
		columns,
		data: fixedPriceSellerStatements,
	};

	const canDownloadCSV =
		fixedPriceSellerStatementsStatus !== 'loading' && fixedPriceSellerStatements.length > 0;

	const [shouldShowUploadAdjustmentsModal, setShouldShowUploadAdjustmentsModal] = useState(false);

	return (
		<>
			<Card>
				<h1 className="mb-4 text-display4">Search Fixed Price Seller Statements</h1>
				<FixedPriceSellerStatementSearchForm onSubmit={updateSearchParams} />
				<div className="mt-4">
					<div className="flex flex-row items-end justify-between mb-2">
						<Label>Fixed Price Seller Statements</Label>
						<div className="flex flex-col items-end">
							<Button
								className="mb-2"
								variant="primary"
								size="md"
								onClick={() => setShouldShowUploadAdjustmentsModal(true)}
							>
								Upload Statement Adjustments
							</Button>
							{shouldShowUploadAdjustmentsModal && (
								<BulkUploadSellerStatementAdjustmentsModal
									onClose={() => setShouldShowUploadAdjustmentsModal(false)}
								/>
							)}
							<DownloadCSVButton
								columnNames={
									FIXED_PRICE_SELLER_STATEMENT_SUMMARY_CSV_COLUMNS as FieldInfo<unknown>[]
								}
								disabled={!canDownloadCSV}
								documentName="fixed-price-seller-statements-summary.csv"
								items={fixedPriceSellerStatements}
								kind="secondary"
							/>
						</div>
					</div>
					<SortableTable
						isLoading={fixedPriceSellerStatementsStatus === 'loading'}
						tableParams={tableParams}
					/>
				</div>
			</Card>
		</>
	);
};

export default FixedPriceSellerSummaryPage;

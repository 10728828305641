import { Chip } from '../../../../../prizm-ui/Chip';
import { Tag } from '../../../../../prizm-ui/Tag';

const VerifiedTinChip = ({ status, as }: { status: boolean; as: 'Chip' | 'Tag' }) => {
	const Component = as === 'Chip' ? Chip : Tag;
	const label = status ? 'TIN Verified' : 'TIN Unverified';
	const variant = status ? 'success' : 'error';

	return <Component variant={variant}>{label}</Component>;
};

export default VerifiedTinChip;

import { useQuery } from 'react-query';
import useDecision from '../../optimizely/useDecision';
import { getRequestBodyForKindOfParams, LotsBySubsetParams } from '../../services/lotsService';
import { getListOfLots } from '../../services/lotsService/getListOfLots';
import {
	FacetSearchQuery,
	KernelLot,
	KernelSummaryLot,
	LotFilters,
} from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';

const useListOfLots = <T extends KernelSummaryLot>({
	params,
	filters,
	include = [],
	facetSearchQuery = {},
}: {
	params: LotsBySubsetParams;
	filters?: LotFilters;
	include?: Partial<keyof KernelLot>[];
	facetSearchQuery?: FacetSearchQuery;
}) => {
	const requestBodyForKindOfParams = getRequestBodyForKindOfParams(params);

	// Feature flag in use pending backend work to send all lots
	const shouldLimitResponseSize = useDecision('limit-lots-list-response-size');

	const baseRequestParams = { params, filters, include, facetSearchQuery };
	const requestParams = shouldLimitResponseSize
		? { ...baseRequestParams, size: 3500 }
		: baseRequestParams;

	const queryKey = [
		QueryKeys.GetListOfLots,
		facetSearchQuery,
		...Object.entries(params),
		requestBodyForKindOfParams,
		filters,
		include,
	];

	return useQuery(queryKey, () => getListOfLots<T>(requestParams));
};

export default useListOfLots;

import { useState } from 'react';
import { Button } from '../../../../prizm-ui/Button';
import AddNewCustomerModal from './AddNewCustomerModal';

const AddNewCustomerButton = () => {
	const [shouldShowAddNewCustomerModal, setShouldShowAddNewCustomerModal] = useState(false);

	return (
		<>
			<Button
				size="md"
				trailingIcon="AddCircle"
				onClick={() => setShouldShowAddNewCustomerModal(true)}
				variant="secondary"
			>
				Add New Customer
			</Button>
			{shouldShowAddNewCustomerModal && (
				<AddNewCustomerModal onClose={() => setShouldShowAddNewCustomerModal(false)} />
			)}
		</>
	);
};

export default AddNewCustomerButton;

import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import { FixedPriceItem } from '../../../../../services/lotsService/lotsService.model';
import useFixedPriceItem from '../../../../../queries/useFixedPriceItem';

const ListingDetails = () => {
	const { itemId = '' } = useParams<{ itemId: string }>();
	const { data: item = {} as FixedPriceItem } = useFixedPriceItem(itemId);

	return (
		<Card className="self-start">
			<h2 className="text-display5 pb-2">Item Details</h2>
			<div className="text-body1">
				<div className="py-4 flex justify-between">
					<span className="mr-8">Category</span>
					<span className="text-right">{item.category ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Item Type</span>
					<span className="text-right">{item.itemType ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Sub-Category</span>
					<span className="text-right">{item.subCategory ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Player/Subject</span>
					<span className="text-right">{item.playerSubject ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Sport</span>
					<span className="text-right">{item?.sport ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Year</span>
					<span className="text-right">{item.year ?? '-'}</span>
				</div>
				<div className="py-4 border-t-light flex justify-between">
					<span className="mr-8">Autograph Type</span>
					<span className="text-right">{item?.autographType ?? '-'}</span>
				</div>
			</div>
		</Card>
	);
};

export default ListingDetails;

import React, { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import Dropdown from '../../../../../components/Dropdown';
import Input from '../../../../../components/Input';
import Label from '../../../../../components/Label';
import { Textarea } from '../../../../../prizm-ui/Textarea';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import {
	CategoryAmountIsValidReturn,
	CategoryAuctionIsValidReturn,
	CATEGORY_DROPDOWN_OPTIONS,
} from './MakeAdjustmentModal.model';
import { DropdownMenuItem } from '../../../../../components/Dropdown/Dropdown.model';
import { Auction } from '../../../../../services/auctionsService/auctionsService.model';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useAuctions from '../../../../../queries/useAuctions';
import { updateCashAccount } from '../../../../../services/cashAccountsService/updateCashAccount';

const MakeAdjustmentModal = ({
	onClose,
	refreshCurrentBalance,
	refreshHistoryTable,
}: {
	onClose: () => void;
	refreshCurrentBalance: () => void;
	refreshHistoryTable: () => void;
}) => {
	const { userId } = useParams<{ userId: string }>();

	const { data: auctions = [] as Auction[] } = useAuctions();

	const [amount, setAmount] = useState(0);

	const defaultAuction: DropdownMenuItem = { label: 'Select ...', value: '' };
	const [auction, setAuction] = useState<DropdownMenuItem>(defaultAuction);

	const defaultCategory: DropdownMenuItem = { label: 'Select ...', value: '' };
	const [category, setCategory] = useState<DropdownMenuItem>(defaultCategory);

	const [description, setDescription] = useState('');
	const [internalNotes, setInternalNotes] = useState('');
	const [transactionId, setTransactionId] = useState('');

	const auctionDropdownOptions: DropdownMenuItem[] = auctions.map(({ title = '', id = '' }) => ({
		label: title,
		value: id,
	}));

	const { mutate: doUpdateCashAccount, status: updateCashAccountStatus } = useMutation({
		mutationFn: ({
			amount,
			auctionId,
			category,
			description,
			internalNotes,
			transactionId,
			userId,
		}: {
			amount: number;
			auctionId?: string;
			category: string;
			description: string;
			internalNotes?: string;
			transactionId?: string;
			userId: string;
		}) =>
			updateCashAccount({
				amount,
				category,
				description,
				userId,
				...(auctionId && { auctionId }),
				...(internalNotes && { internalNotes }),
				...(transactionId && { transactionId }),
			}),
		mutationKey: [QueryKeys.UpdateCashAccount, userId],
	});

	const handleSave = () => {
		const { categoryAmountErrorMessage, categoryAmountIsValid } = isCategoryAmountValid();

		if (!categoryAmountIsValid) {
			showErrorToast(categoryAmountErrorMessage);

			return;
		}

		const { categoryAuctionErrorMessage, categoryAuctionIsValid } = isCategoryAuctionValid();

		if (!categoryAuctionIsValid) {
			showErrorToast(categoryAuctionErrorMessage);

			return;
		}

		doUpdateCashAccount(
			{
				amount: Number(amount),
				auctionId: auction.value as string,
				category: category.value as string,
				description,
				internalNotes,
				transactionId,
				userId,
			},
			{
				onError: () => showErrorToast('Something went wrong. Please try again later.'),
				onSuccess: () => {
					refreshCurrentBalance();
					refreshHistoryTable();

					onClose();

					showSuccessToast('Adjustment saved successfully.');
				},
			}
		);
	};

	const isCategoryAmountValid = (): CategoryAmountIsValidReturn => {
		if (category.value === 'deposit' && amount <= 0) {
			return {
				categoryAmountErrorMessage: 'Proceed amounts must be greater than zero.',
				categoryAmountIsValid: false,
			};
		} else if (category.value === 'purchase' && amount >= 0) {
			return {
				categoryAmountErrorMessage: 'Payment amounts must be less than zero.',
				categoryAmountIsValid: false,
			};
		} else if (category.value === 'withdrawal' && amount >= 0) {
			return {
				categoryAmountErrorMessage: 'Payout amounts must be less than zero.',
				categoryAmountIsValid: false,
			};
		} else {
			return {
				categoryAmountErrorMessage: '',
				categoryAmountIsValid: true,
			};
		}
	};

	const isCategoryAuctionValid = (): CategoryAuctionIsValidReturn => {
		if (category.value === 'deposit' && auction.value.length === 0) {
			return {
				categoryAuctionErrorMessage: 'Please select the appropriate auction for these proceeds.',
				categoryAuctionIsValid: false,
			};
		} else if (category.value === 'purchase' && auction.value.length === 0) {
			return {
				categoryAuctionErrorMessage: 'Please select the appropriate auction for this payout.',
				categoryAuctionIsValid: false,
			};
		} else {
			return {
				categoryAuctionErrorMessage: '',
				categoryAuctionIsValid: true,
			};
		}
	};

	const amountIsValid = amount !== 0;
	const categoryIsValid =
		CATEGORY_DROPDOWN_OPTIONS.find((categoryOption) => categoryOption.value === category.value) !==
		undefined;
	const descriptionIsValid = !!description;

	return (
		<ActionModal
			cancelButtonLabel="Cancel"
			canConfirm={amountIsValid && descriptionIsValid && categoryIsValid}
			className="my-4"
			confirmButtonLabel="Save"
			isLoading={updateCashAccountStatus === 'loading'}
			title="Make Adjustment"
			onClose={onClose}
			onConfirm={handleSave}
		>
			<div className="mb-4">
				<Label className="mb-2">Description</Label>
				<Textarea
					defaultValue={description}
					id="description"
					label=""
					name="description"
					required
					onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
						setDescription(value)
					}
				/>
			</div>
			<div className="mb-4">
				<Label className="mb-2">Auction</Label>
				<Dropdown
					key="auction"
					name="auction"
					onChange={({ label, value }) => setAuction({ label, value })}
					options={[defaultAuction, ...auctionDropdownOptions]}
					value={auction}
				/>
			</div>
			<div className="mb-4">
				<Label className="mb-2">Category</Label>
				<Dropdown
					key="category"
					name="category"
					onChange={({ label, value }) => setCategory({ label, value })}
					options={[defaultCategory, ...CATEGORY_DROPDOWN_OPTIONS]}
					value={category}
				/>
			</div>
			<div className="mb-4">
				<Label className="mb-2">Amount</Label>
				<Input
					className="w-full"
					name="amount"
					required
					type="number"
					value={amount}
					onChange={({ target: { value } }) => setAmount(value)}
				/>
			</div>
			{(category.value === 'deposit' || category.value === 'purchase') && (
				<div className="mb-4">
					<Label className="mb-2">Transaction ID</Label>
					<Input
						className="w-full"
						name="transaction_id"
						required
						type="text"
						value={transactionId}
						onChange={({ target: { value } }) => setTransactionId(value)}
					/>
				</div>
			)}
			<div className="mb-4">
				<Label className="mb-2">Notes</Label>
				<Textarea
					defaultValue={internalNotes}
					id="notes"
					label=""
					name="notes"
					onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
						setInternalNotes(value)
					}
				/>
			</div>
		</ActionModal>
	);
};

export default MakeAdjustmentModal;

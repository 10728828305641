import { useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Dropdown from '../../../../../../components/Dropdown';
import Label from '../../../../../../components/Label';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { DropdownMenuItem } from '../../../../../../components/Dropdown/Dropdown.model';
import { BASE_COLLECTIONS_STATUS_OPTIONS } from './OrderCollectionsStatus.model';
import {
	CheckoutOrder,
	CheckoutOrderCollectionsStatus,
} from '../../../../../../services/ordersService/ordersService.model';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import { updateOrder } from '../../../../../../services/ordersService';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';

const OrderCollectionsStatus = ({ orderNumber }: { orderNumber: string }) => {
	const { data: order = {} as CheckoutOrder, updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber,
		enabled: !!orderNumber,
	});

	const defaultCollectionsStatusOption = { label: 'Select ...', value: '' };
	const foundCollectionsStatusOption = BASE_COLLECTIONS_STATUS_OPTIONS.find(
		(option) => option.value === order.collectionsStatus
	);
	const [newCollectionsStatus, setNewCollectionsStatus] = useState<DropdownMenuItem>(
		foundCollectionsStatusOption || defaultCollectionsStatusOption
	);

	const { mutate: doUpdateOrder, status: updateOrderStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderCollectionsStatus, orderNumber],
		mutationFn: () =>
			updateOrder({
				fieldsToUpdate: {
					collectionsStatus: newCollectionsStatus.value as CheckoutOrderCollectionsStatus,
				},
				orderNumber,
			}),
	});

	const handleSave = () => {
		doUpdateOrder(undefined, {
			onError: () =>
				showErrorToast(
					'Something went wrong while updating corrections status. Please try again later.'
				),
			onSuccess: (updatedOrder: CheckoutOrder) => {
				updateOrderInCache(updatedOrder);

				showSuccessToast('Collections status successfully updated.');
			},
		});
	};

	return (
		<Card>
			<h2 className="text-subtitle1">Update Collections Status To</h2>
			<div className="mt-4">
				<Label>Collections Statuses</Label>
				<Dropdown
					defaultOption={foundCollectionsStatusOption || defaultCollectionsStatusOption}
					key="collections-status-dropdown"
					name="collections-status-dropdown"
					options={[defaultCollectionsStatusOption, ...BASE_COLLECTIONS_STATUS_OPTIONS]}
					value={newCollectionsStatus}
					onChange={(value) => setNewCollectionsStatus(value)}
				/>
			</div>
			<div className="flex flex-row mt-4">
				<Button
					disabled={!newCollectionsStatus.value}
					isLoading={updateOrderStatus === 'loading'}
					kind="primary"
					onClick={handleSave}
				>
					Save Changes
				</Button>
				<Button
					className="ml-2"
					kind="secondary"
					onClick={() =>
						setNewCollectionsStatus(foundCollectionsStatusOption || defaultCollectionsStatusOption)
					}
				>
					Cancel
				</Button>
			</div>
		</Card>
	);
};

export default OrderCollectionsStatus;

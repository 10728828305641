import { Select, Option } from '../../../../prizm-ui/Select';
import { AuctionType } from '../../../../services/auctionsService';

const AuctionTypeSelector = ({
	selectedAuctionType,
	setSelectedAuctionType,
}: {
	selectedAuctionType?: AuctionType;
	setSelectedAuctionType: (selectedAuctionType: AuctionType) => void;
}) => {
	return (
		<Select
			id="auction-type"
			label="Auction Type"
			value={selectedAuctionType}
			onChange={({ target: { value } }) => {
				setSelectedAuctionType(value as AuctionType);
			}}
		>
			<Option index={0} value="Elite">
				Elite
			</Option>
			<Option index={1} value="Thematic">
				Thematic
			</Option>
			<Option index={2} value="Weekly">
				Weekly
			</Option>
		</Select>
	);
};

export default AuctionTypeSelector;

import React, { ChangeEventHandler } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';

const DatePicker = ({
	allowedRoles = [],
	className = '',
	defaultValue,
	isDisabled = false,
	onChange,
}: {
	allowedRoles?: UserRoleName[];
	className?: string;
	defaultValue?: string;
	isDisabled?: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	return (
		<input
			className={`border-base focus:border-blue-500 focus:ring-blue-500 block rounded-lg p-2 ${className}`}
			defaultValue={defaultValue}
			name="edit-due-date-picker"
			type="date"
			onChange={onChange}
			disabled={isDisabled || isForbidden}
		/>
	);
};

export default DatePicker;

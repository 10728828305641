import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { FreeformAdjustment, LotAdjustment, StructuredFeeAdjustment } from './ordersService.model';

export const updateOrderAdjustments = async ({
	orderNumber,
	updatedAdjustments,
	roundToNearestInteger = false,
}: {
	orderNumber: string;
	updatedAdjustments: (StructuredFeeAdjustment | FreeformAdjustment | LotAdjustment)[];
	roundToNearestInteger?: boolean;
}) => {
	const body = {
		orderNumber,
		adjustments: updatedAdjustments.map((adjustment) => ({
			...adjustment,
			amount: roundToNearestInteger
				? parseInt(adjustment.amount.toString(), 10)
				: parseFloat(adjustment.amount.toString()),
		})),
	};
	const response = await axiosAuth.put(`${apiEndpoints.checkout}/orders`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { headerPaginationToPagination } from '../../utils/formatters';
import {
	CashAccountEntriesResponse,
	GetCashAccountEntriesProps,
	KernelCashAccountEntry,
} from './cashAccountsService.model';

export const getCashAccountEntries = async ({
	category,
	userId,
	page,
	pageSize,
}: GetCashAccountEntriesProps): Promise<CashAccountEntriesResponse> => {
	// requesting without page and pageSize will give you all the entries
	const paginator =
		typeof page !== 'undefined' && pageSize ? `?page=${page}&pageSize=${pageSize}` : '';
	const response = await axiosAuth.get<KernelCashAccountEntry[]>(
		`${apiEndpoints.cash_accounts}/${userId}/all${paginator}`,
		{ params: { category } }
	);

	if (response.status >= 400) {
		throw response;
	}

	return {
		entries: response.data,
		...headerPaginationToPagination(response.headers),
	};
};

import { difference, without } from 'lodash';
import { useReducer } from 'react';
import { ImagesAction, LotImagesState } from './useLotImagesReducer.model';

const saveChanges = (lotImagesState: LotImagesState): LotImagesState => {
	const basePersistedImageNames = [
		...difference(lotImagesState.orderedImageNames, lotImagesState.deletedImageNames),
	];
	const persistedImageUrlsWithCorrectPrimaryImageName =
		lotImagesState.currentPrimaryImageName &&
		lotImagesState.currentPrimaryImageName === basePersistedImageNames[0]
			? basePersistedImageNames
			: [
					lotImagesState.currentPrimaryImageName,
					...without(basePersistedImageNames, lotImagesState.currentPrimaryImageName),
			  ];
	const filteredPersistedImageNames = persistedImageUrlsWithCorrectPrimaryImageName.filter(
		(imageName) => !!imageName
	);

	const newLotImagesState: LotImagesState = {
		...lotImagesState,
		deletedImageNames: [],
		newImageNames: [],
		persistedImageNames: filteredPersistedImageNames,
		orderedImageNames: filteredPersistedImageNames,
		currentPrimaryImageName:
			filteredPersistedImageNames.length === 1
				? filteredPersistedImageNames[0]
				: lotImagesState.currentPrimaryImageName,
		hasUnsavedChanges: false,
		hasUserChangedImageOrder: false,
	};

	return { ...newLotImagesState, previousState: newLotImagesState };
};

const getHasUnsavedChanges = (lotImagesState: LotImagesState): boolean => {
	const hasPrimaryImageChanged =
		lotImagesState.currentPrimaryImageName !== lotImagesState.originalPrimaryImageName;
	const haveNewImagesBeenAdded = lotImagesState.newImageNames.length > 0;
	const haveImagesBeenDeleted = lotImagesState.deletedImageNames.length > 0;

	return hasPrimaryImageChanged || haveNewImagesBeenAdded || haveImagesBeenDeleted;
};

const getOrderedNewImages = ({
	existingNewImagesArray,
	newImageName,
	hasUserChangedImageOrder,
}: {
	existingNewImagesArray: string[];
	newImageName: string;
	hasUserChangedImageOrder: boolean;
}): string[] => {
	const newImageArray = hasUserChangedImageOrder
		? [...existingNewImagesArray, newImageName]
		: [...existingNewImagesArray, newImageName].sort();

	return newImageArray;
};

const lotImagesReducer = (lotImagesState: LotImagesState, action: ImagesAction): LotImagesState => {
	switch (action.type) {
		case 'ADD_IMAGE':
			return {
				...lotImagesState,
				hasUnsavedChanges: getHasUnsavedChanges({
					...lotImagesState,
					newImageNames: [...lotImagesState.newImageNames, action.payload],
				}),
				newImageNames: getOrderedNewImages({
					existingNewImagesArray: lotImagesState.newImageNames,
					newImageName: action.payload,
					hasUserChangedImageOrder: lotImagesState.hasUserChangedImageOrder,
				}),
				orderedImageNames: [
					...difference(
						lotImagesState.orderedImageNames,
						getOrderedNewImages({
							existingNewImagesArray: lotImagesState.newImageNames,
							newImageName: action.payload,
							hasUserChangedImageOrder: lotImagesState.hasUserChangedImageOrder,
						})
					),
					...getOrderedNewImages({
						existingNewImagesArray: lotImagesState.newImageNames,
						newImageName: action.payload,
						hasUserChangedImageOrder: lotImagesState.hasUserChangedImageOrder,
					}),
				],
				currentPrimaryImageName:
					(lotImagesState.originalPrimaryImageName && lotImagesState.currentPrimaryImageName) ||
					getOrderedNewImages({
						existingNewImagesArray: lotImagesState.newImageNames,
						newImageName: action.payload,
						hasUserChangedImageOrder: lotImagesState.hasUserChangedImageOrder,
					}).filter((imageName) => !imageName.includes('LOA_'))[0],
				previousState: lotImagesState,
			};
		case 'DELETE_IMAGE':
			return {
				...lotImagesState,
				hasUnsavedChanges: getHasUnsavedChanges({
					...lotImagesState,
					deletedImageNames: [...lotImagesState.deletedImageNames, action.payload],
				}),
				deletedImageNames: [...lotImagesState.deletedImageNames, action.payload],
				currentPrimaryImageName:
					action.payload === lotImagesState.currentPrimaryImageName
						? ''
						: lotImagesState.currentPrimaryImageName,
				previousState: lotImagesState,
			};
		case 'UNDELETE_IMAGE':
			return {
				...lotImagesState,
				hasUnsavedChanges: getHasUnsavedChanges({
					...lotImagesState,
					deletedImageNames: without(lotImagesState.deletedImageNames, action.payload),
				}),
				deletedImageNames: without(lotImagesState.deletedImageNames, action.payload),
				previousState: lotImagesState,
			};
		case 'SAVE_CHANGES':
			return saveChanges(lotImagesState);
		case 'DISCARD_CHANGES':
			return {
				...lotImagesState.previousState,
				newImageNames: [],
				orderedImageNames: difference(
					lotImagesState.orderedImageNames,
					lotImagesState.newImageNames
				),
			} as LotImagesState;
		case 'CHANGE_IMAGE_ORDER':
			return {
				...lotImagesState,
				hasUserChangedImageOrder: true,
				hasUnsavedChanges: true,
				orderedImageNames: action.payload,
				currentPrimaryImageName: action.payload[0],
			};
		default:
			return lotImagesState;
	}
};

const useLotImagesReducer = ({
	primaryImageName,
	persistedImageNames,
}: {
	primaryImageName: string;
	persistedImageNames: string[];
}) => {
	const baseInitialState: Omit<LotImagesState, 'previousState'> = {
		originalPrimaryImageName: primaryImageName,
		currentPrimaryImageName: primaryImageName,
		persistedImageNames,
		orderedImageNames: persistedImageNames,
		newImageNames: [],
		deletedImageNames: [],
		hasUnsavedChanges: false,
		hasUserChangedImageOrder: false,
	};
	const initialState: LotImagesState = {
		...baseInitialState,
		previousState: baseInitialState,
	};

	return useReducer(lotImagesReducer, initialState);
};

export default useLotImagesReducer;

import { isNaN } from 'lodash';

/** Format currency as USD string, ie '$37.00' */
const formatCurrency = (rawCurrencyValue?: number, fallback = '-'): string => {
	if (isNaN(rawCurrencyValue)) {
		return 'Invalid Number Received';
	}

	const formatter = new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' });
	const baseCurrencyValue =
		rawCurrencyValue !== undefined ? formatter.format(rawCurrencyValue) : fallback;

	return baseCurrencyValue === '0' ? '-' : baseCurrencyValue;
};

export default formatCurrency;

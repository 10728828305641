import { useMutation, useQueryClient } from 'react-query';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Button } from '../../../../../prizm-ui/Button';
import { QueryKeys } from '../../../../../queries/queryKeys';
import {
	regenerateSellerStatement,
	RegenerateSellerStatementParams,
} from '../../../../../services/sellerStatementService';

const RegenerateStatementButton = ({
	auctionId,
	sellerId,
	statementId,
}: RegenerateSellerStatementParams) => {
	const { mutate: doRegenerateStatement, status: regenerateStatementStatus } = useMutation({
		mutationKey: [QueryKeys.RegenerateSellerStatement],
		mutationFn: () => regenerateSellerStatement({ auctionId, sellerId, statementId }),
	});

	const queryClient = useQueryClient();
	const handleRegenerateStatement = () =>
		doRegenerateStatement(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully regenerated seller statement');
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementDetails]);
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementPDF]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong regenerating seller statement. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	return (
		<Button
			variant="primary"
			size="md"
			className="w-full"
			loading={regenerateStatementStatus === 'loading'}
			onClick={handleRegenerateStatement}
		>
			Regenerate Statement
		</Button>
	);
};

export default RegenerateStatementButton;

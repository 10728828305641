import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import { KernelInvoice } from './invoicesService.model';

export const getInvoiceDetails = async ({
	auction_id,
	invoice_id,
}: {
	auction_id: string;
	invoice_id: string;
}): Promise<KernelInvoice> => {
	try {
		const response = await postAuthed(`${config.api.invoice_details}`, {
			company_id: config.organization.company_id,
			auction_id,
			invoice_id,
		});

		if (response.status >= 400) {
			throw response;
		}

		return JSON.parse(response.data.body);
	} catch (err) {
		console.log(`Unable to get invoice details: ${err}`);

		throw err;
	}
};

export default getInvoiceDetails;

import React, { useEffect, useState } from 'react';
import {
	Grid,
	Button,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { AUCTION_ID_FIELD, UNASSIGNED_LOT_ID } from '../../../AdminUtils';
import { fetchAuctions } from '../../../AdminUtils/auctionUtils';
import { StripHtml } from '../../../zenauction-ui-shared/Utilities';
import { reassignLotToAuction } from '../../../services/lotsService/reassignLotToAuction';

const LotReassignmentCard = (props) => {
	const [lotData] = useState(props.lot);
	const [newAuctionId, setNewAuctionId] = useState(null);
	const [auctionList, setAuctionList] = useState([]);

	useEffect(() => {
		fetchAuctions().then((responses) => {
			setAuctionList(responses);
		});
	}, []);

	return (
		<Dialog
			key={props.key || 'confirmation-dialog-key'}
			open={true}
			aria-labelledby="confirmation-dialog-title"
			aria-describedby="confirmation-dialog-description"
			maxWidth="md"
		>
			<DialogTitle id="confirmation-dialog-title">Allocate lot to new auction</DialogTitle>
			<DialogContent>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab">
						<div>
							<div key={uuidv4()} className="card-subtitle">
								Lot Details
							</div>
							<div key={uuidv4()} className="card-header-title">
								{lotData.title}
							</div>
						</div>
					</CardHeader>

					<CardBody className="auction-card">
						<Grid
							container
							direction="column"
							alignItems="flex-start"
							justifyContent="flex-start"
							spacing={2}
						>
							<div style={{ width: '100%' }}>
								<Grid item xs={12}>
									<FormControl fullWidth className="mt-2 mb-2" variant="outlined">
										<InputLabel id="auction-assignment-label">Assign lot to auction:</InputLabel>
										<Select
											id="auction-assignment"
											onChange={(event) => {
												if (event && event.target) {
													const val = event.target.value;
													setNewAuctionId(val);
												}
											}}
											required={false}
											defaultValue={lotData[AUCTION_ID_FIELD]}
											labelWidth={150}
										>
											{auctionList &&
												auctionList.length > 0 &&
												auctionList.map((auction) => (
													<MenuItem
														value={auction[AUCTION_ID_FIELD] || UNASSIGNED_LOT_ID}
														key={JSON.stringify(auction)}
													>
														<div>{StripHtml(auction.title ? auction.title : 'Unassigned')}</div>
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							</div>
						</Grid>
					</CardBody>
				</Card>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() => {
						reassignLotToAuction({
							lotId: lotData.lot_id,
							destinationAuctionId: newAuctionId,
						}).then(() => {
							props.onClose();
						});
					}}
					color="primary"
					variant="contained"
					disabled={!newAuctionId}
				>
					Reassign Lot
				</Button>

				<Button
					onClick={() => {
						props.onClose();
					}}
					autoFocus
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LotReassignmentCard;

import { useState } from 'react';
import {
	CheckoutOrder,
	PaymentMethod,
} from '../../../../../services/ordersService/ordersService.model';
import OrderCollectionsStatus from './OrderCollectionsStatus';
import OrderComments from './OrderComments';
import OrderCustomerInfo from './OrderCustomerInfo';
import OrderInvoicePaymentDetails from './OrderPaymentDetails';
import OrderSummary from './OrderSummary';
import OrderStatus from './OrderStatus';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';
import { useParams } from 'react-router-dom';
import OrderRemovedLots from './OrderRemovedLots';

const OrderDashboardView = ({
	order,
	lotItems,
}: {
	order: CheckoutOrder;
	lotItems: KernelLot[];
}) => {
	const { orderNumber } = useParams<{ orderNumber: string }>();

	const originalPaymentMethod: PaymentMethod = order.paymentMethod ?? 'creditCard';
	const [selectedPaymentMethod, setSelectedPaymentMethod] =
		useState<PaymentMethod>(originalPaymentMethod);

	const { costMetadata } = order;

	return (
		<>
			{costMetadata ? (
				<div className="flex w-full flex-col md:flex-row">
					<div className="mb-4 md:mr-4 md:mb-0 md:w-1/3">
						<div className="flex flex-col">
							<OrderCustomerInfo />
							<div className="mt-4">
								<OrderInvoicePaymentDetails
									order={order}
									selectedPaymentMethod={selectedPaymentMethod}
									setSelectedPaymentMethod={setSelectedPaymentMethod}
								/>
							</div>
							<div className="mt-4">
								<OrderStatus paymentMethod={selectedPaymentMethod} />
							</div>
							<div className="mt-4">
								<OrderComments orderNumber={orderNumber} />
							</div>
							{order.auctionId !== 'fixed_price_marketplace' && (
								<div className="mt-4">
									<OrderCollectionsStatus orderNumber={orderNumber} />
								</div>
							)}
						</div>
					</div>
					<div className="md:w-2/3">
						<OrderSummary
							removedLots={order.removedLots ?? []}
							order={order}
							lotItems={lotItems}
							paymentMethod={selectedPaymentMethod}
						/>
						{order.removedLots && order.removedLots.length > 0 && (
							<OrderRemovedLots removedLots={order.removedLots} />
						)}
					</div>
				</div>
			) : (
				<h2 className="mb-4 text-subtitle1">
					There was an error retrieving the order. Cost metadata does not exist. Please alert
					#mvp-cs-channel.
				</h2>
			)}
		</>
	);
};

export default OrderDashboardView;

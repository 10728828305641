import { TestIdentifierProps } from './getTestIdentifier.model';

export const getTestIdentifier = ({
	componentName,
	descriptor,
	pagePath,
	sectionPath,
	tabPath,
}: TestIdentifierProps) => {
	const testIdentifierPrefix = 'zen-admin';

	const testIdentifierTokens = [testIdentifierPrefix];

	testIdentifierTokens.push(sectionPath);

	if (pagePath) {
		testIdentifierTokens.push(pagePath);
	}

	if (tabPath) {
		testIdentifierTokens.push(`${tabPath}-tab`);
	}

	testIdentifierTokens.push(componentName);
	testIdentifierTokens.push(descriptor);

	return testIdentifierTokens.join('.');
};

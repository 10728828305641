import React from 'react';
import { ErrorText, getInputClasses, HelpText, Label } from '../Input/Input.shared';

type TextareaProps = {
	errorText?: string;
	helpText?: string;
	id: string;
	invalid?: boolean;
	label: string;
} & Omit<React.ComponentPropsWithRef<'textarea'>, 'id'>;

export const Textarea = React.forwardRef(
	(
		{ className, errorText, helpText, id, invalid, label, rows = 3, ...rest }: TextareaProps,
		ref?: React.ForwardedRef<HTMLTextAreaElement>
	) => {
		const props = {
			ref,
			id,
			rows,
			...rest,
		};
		return (
			<div className={className}>
				<div className="relative h-32">
					<textarea
						{...props}
						className={[getInputClasses({ invalid }), 'resize-none', 'pt-7'].join(' ')}
						placeholder=" "
					/>
					<Label id={id} invalid={invalid} variant="textarea">
						{label}
					</Label>
				</div>
				<ErrorText errorText={errorText} id={id} invalid={invalid} />
				<HelpText errorText={errorText} helpText={helpText} id={id} invalid={invalid} />
			</div>
		);
	}
);

Textarea.displayName = 'Textarea';

import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import { OrderDashboardHeaderProps } from './OrderDashboardHeader.model';

const OrderDashboardHeader = ({ order }: OrderDashboardHeaderProps) => {
	const { orderNumber } = useParams<{ orderNumber: string }>();
	return (
		<Card>
			<h1 className="text-display4">Invoice Details for Order {orderNumber}</h1>
			{order.description && <div className="mt-2 text-display5">{order.description}</div>}
		</Card>
	);
};

export default OrderDashboardHeader;

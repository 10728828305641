import { axiosAuth, axiosUnauthed } from '../../AdminUtils/httpUtils';
import config from '../../config';

const getLotBulkUploadS3Url = async (auctionId = '') => {
	const response = await axiosAuth.post(`${config.api.lots_bulk_upload_url}`, {
		auction_id: auctionId,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

/** @description Uploads bulk imported lots to the backend */
export const bulkUploadLots = async ({
	bulkImportFile,
	employeeId,
}: {
	bulkImportFile: File;
	employeeId: string;
}) => {
	const signedUrl = await getLotBulkUploadS3Url();

	const baseHeaders = {
		'Content-Type': bulkImportFile.type,
		// Do not include authorization here
	};

	const headers = {
		...baseHeaders,
		'x-amz-meta-user-id': employeeId,
	};

	const response = await axiosUnauthed.put(signedUrl, bulkImportFile, { headers });

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

import { useState } from 'react';
import { Button } from '../../../../prizm-ui/Button';
import Card from '../../../../components/Card';
import BulkUploadCSVModal from './BulkImportLotsModal';
import BulkUploadLotAdjustmentsModal from './BulkUploadLotAdjustmentsModal';
import { EDITOR_ROLES } from '../../../../services/userService/userService.model';
import AddNewLotModal from './AddNewLotModal/AddNewLotModal';
import { getTestIdentifier } from '../../../../utils/test-identifiers/getTestIdentifier';

const GeneralLotOptions = () => {
	const [shouldShowCreateLotModal, setShouldShowCreateLotModal] = useState(false);
	const [shouldShowImportLotsModal, setShouldShowBulkImportLotsModal] = useState(false);
	const [shouldShowBulkUploadLotAdjustmentsModal, setShouldShowBulkUploadLotAdjustmentsModal] =
		useState(false);

	return (
		<>
			<Card className="mb-8" allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
				<div className="mb-4">
					<h2 className="text-subtitle1">General Lot Options</h2>
				</div>
				<div className="flex flex-col lg:flex-row">
					<Button
						className="mr-0 lg:mr-4 mb-4 lg:mb-0"
						size="md"
						variant="brand"
						onClick={() => setShouldShowCreateLotModal(true)}
						data-testid={getTestIdentifier({
							componentName: 'GeneralLotOptions',
							descriptor: 'add-new-lot-button',
							pagePath: 'inventory-manager',
							sectionPath: 'auctions',
						})}
					>
						Add a new lot
					</Button>
					<Button
						className="mr-0 lg:mr-4 mb-4 lg:mb-0"
						key="lot-manager-bulk-import-auction"
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBulkImportLotsModal(true)}
						data-testid={getTestIdentifier({
							componentName: 'GeneralLotOptions',
							descriptor: 'bulk-upload-lots-button',
							pagePath: 'inventory-manager',
							sectionPath: 'auctions',
						})}
					>
						Bulk Upload Lots
					</Button>
					<Button
						className="mr-0 lg:mr-4 mb-4 lg:mb-0"
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBulkUploadLotAdjustmentsModal(true)}
					>
						Bulk Upload Lot Adjustments
					</Button>
					{shouldShowCreateLotModal && (
						<AddNewLotModal onClose={() => setShouldShowCreateLotModal(false)} />
					)}
					{shouldShowImportLotsModal && (
						<BulkUploadCSVModal onClose={() => setShouldShowBulkImportLotsModal(false)} />
					)}
					{shouldShowBulkUploadLotAdjustmentsModal && (
						<BulkUploadLotAdjustmentsModal
							onClose={() => setShouldShowBulkUploadLotAdjustmentsModal(false)}
						/>
					)}
				</div>
			</Card>
		</>
	);
};

export default GeneralLotOptions;

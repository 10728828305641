import React from 'react';
import Checkbox from '../../../../components/Checkbox';
import { FacetTitle, KernelFacet } from '../../../../services/facetsService/getFacets.model';
import { FacetSearchQuery } from '../../../../services/lotsService/lotsService.model';
import { getIsFacetValueInCurrentState } from './useFacetSelection';

const AuctionPreviewFacets = ({
	facets,
	toggleFacetValue,
	facetSearchQuery,
}: {
	facets: KernelFacet[];
	toggleFacetValue: ({
		facetTitle,
		facetValue,
	}: {
		facetTitle: FacetTitle;
		facetValue: string;
	}) => void;
	facetSearchQuery: FacetSearchQuery;
}) => {
	return (
		<div>
			{facets.map((facet) => (
				<div className="mb-4" key={facet.title}>
					<h3 className="mb-2 text-display5">{facet.title}</h3>
					{facet.buckets.map((bucket) => (
						<Checkbox
							className="mt-1"
							checked={getIsFacetValueInCurrentState({
								facetTitle: facet.title,
								facetValue: bucket.key,
								currentState: facetSearchQuery,
							})}
							name={bucket.key}
							label={`${bucket.key} (${bucket.doc_count})`}
							key={bucket.key}
							onChange={() => toggleFacetValue({ facetTitle: facet.title, facetValue: bucket.key })}
						/>
					))}
				</div>
			))}
		</div>
	);
};

export default AuctionPreviewFacets;

import React from 'react';
import ColorHash from 'color-hash';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Person24Regular } from '@fluentui/react-icons';

const AvatarMenu = ({ name = '--', email }: { name?: string; email: string }) => {
	const history = useHistory();
	const [backgroundColor] = new ColorHash({ saturation: 60, lightness: 60 })
		.hex(name || 'User')
		.split('-');

	const { buttonProps, itemProps, isOpen } = useDropdownMenu(1);

	const getInitials = () => {
		try {
			return name.split(' ')[0][0] + name.split(' ')[1][0];
		} catch (e) {
			return '--';
		}
	};

	const initials = getInitials();

	return (
		<div>
			<button
				className="flex h-10 w-10 items-center justify-center rounded-full font-semibold bg-neutral1dark"
				style={{ backgroundColor, color: 'white' }}
				{...buttonProps}
			>
				{initials || <Person24Regular />}
			</button>
			<div
				className={`${
					isOpen ? 'border-base visible absolute top-16 right-4 rounded-lg bg-neutral1' : 'hidden'
				} `}
				role="menu"
			>
				<div className="flex flex-col">
					<div className="border-b-base p-4">
						<div className="font-semibold">{name}</div>
						{email}
					</div>
					<div className="flex flex-grow">
						<a
							className="link w-full flex-grow cursor-pointer rounded-b-lg p-4 hover:bg-blue2"
							{...itemProps[0]}
							onClick={async () => {
								await Auth.signOut();
								history.push('/login');
							}}
						>
							Log Out
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AvatarMenu;

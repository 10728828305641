import { FC, ReactNode } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';

/**
 * @description - For use when you want to restrict a subsection of content. To restrict an entire
 * section, provide an `allowedRoles` prop to the Card component
 */
const RestrictedArea: FC<{ allowedRoles: UserRoleName[]; fallback?: ReactNode }> = ({
	allowedRoles,
	children,
	fallback,
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	return isForbidden ? <>{fallback}</> : <>{children}</>;
};

export default RestrictedArea;

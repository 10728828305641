import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelConsignedLotsResponse } from './lotsService.model';
import { getLotLocation } from './lotsService.utils';

export const getConsignedLots = async ({
	sellerId,
	pageSize,
	page,
}: {
	sellerId: string;
	pageSize?: number;
	page?: number;
}): Promise<KernelConsignedLotsResponse> => {
	// requesting without page and pageSize will give you all the entries
	const hasPaginationParams = typeof page !== 'undefined' && pageSize;
	const paginationParams = hasPaginationParams ? { page, pageSize } : { size: 10000 };
	const query = {
		queryType: 'All',
		seller_id: [sellerId],
		...paginationParams,
	};

	const response = await axiosAuth.post<KernelConsignedLotsResponse>(`${config.api.lots}`, query);

	if (response.status >= 400) {
		throw response;
	}

	return {
		...response.data,
		lots: response.data.lots.map((lot) => ({
			...lot,
			location: getLotLocation(lot),
		})),
	};
};

import { useState } from 'react';
import { Auction } from '../../../../services/auctionsService';
import Button from '../../../Button';
import AuctionItem from './AuctionItem';
import { sortAuctionsByStatus } from './AuctionPickerResultsSection.utils';

const AuctionPickerSection = ({
	auctions,
	selectedAuctionId,
	selectAuction,
	title,
}: {
	auctions: Auction[];
	selectedAuctionId: string;
	selectAuction: (selectedAuctionId: string) => void;
	title: string;
}) => {
	const [shouldShowAll, setShouldShowAll] = useState(false);

	const sortedAuctions = sortAuctionsByStatus(auctions);
	const auctionsToShow = shouldShowAll ? sortedAuctions : sortedAuctions.slice(0, 3);

	return auctions.length > 0 ? (
		<div className="mt-8">
			<div className="pb-4 pl-4 border-b-base">
				<h2 className="text-caption1">{title}</h2>
			</div>
			<div className="mt-4">
				{auctionsToShow.map((auction) => (
					<AuctionItem
						auction={auction}
						key={auction.id}
						selectedAuctionId={selectedAuctionId}
						selectAuction={selectAuction}
					/>
				))}
			</div>
			{auctions.length > 3 && (
				<div className="mt-4 border-t-base">
					<Button
						kind="tertiary"
						isInline={true}
						className="mt-2 ml-4"
						onClick={() => setShouldShowAll(!shouldShowAll)}
					>
						{shouldShowAll ? 'Show fewer' : 'Show more'}
					</Button>
				</div>
			)}
		</div>
	) : (
		<></>
	);
};

export default AuctionPickerSection;

import { useState } from 'react';
import DefinitionList from '../../../../../components/DefinitionList';
import RestrictedArea from '../../../../../components/RestrictedArea';
import { DefinitionListRow } from '../../../../../components/DefinitionList/DefinitionList.model';
import { Banner } from '../../../../../prizm-ui/Banner';
import { Button } from '../../../../../prizm-ui/Button';
import {
	KernelRateCard,
	RateCardVariant,
	TeeShirtRateCard,
} from '../../../../../services/rateCardService/rateCardService.model';
import EditRateCardModal from './EditRateCardModal';
import { kernelRateCardToTeeShirtRateCard } from './RateCard.utils';
import RateCardRate from './RateCardRate';

const RateCard = ({
	legacyCommissionRate,
	kernelRateCard,
	variant,
}: {
	legacyCommissionRate?: number;
	kernelRateCard?: KernelRateCard;
	variant: RateCardVariant;
}) => {
	const teeShirtRateCard = kernelRateCardToTeeShirtRateCard({ kernelRateCard, variant });
	const legacyRateCard: TeeShirtRateCard = {
		variant,
		rates: {
			xs: legacyCommissionRate,
			sm: legacyCommissionRate,
			md: legacyCommissionRate,
			lg: legacyCommissionRate,
			xl: legacyCommissionRate,
		},
	};

	/** If there is no V2 rate card yet and the seller has a legacy commission value set, this will honor the legacy
	 *  commission rate for auction items only. Fixed-price marketplace is never eligible for the legacy commission.
	 *
	 *  In the intermediate state where a seller has legacy commission but no values set for the ranges, we defer to
	 *  the legacy commission rate. This is because the front-end creates rate entries for each of the price tiers at
	 *  the first time we customize a seller's rates.
	 *
	 *  If the seller has a V2 rate card, we defer to it. To revert the seller back to default rates, we keep the rate
	 *  card in place and set the commission values to null.
	 */
	const rateCard =
		variant === 'auction' &&
		teeShirtRateCard.isEmpty && // The rate card has not been created yet
		!teeShirtRateCard.isNull && // The rate card has been created, but now the seller has been set back to default rates
		typeof legacyCommissionRate !== 'undefined'
			? legacyRateCard
			: teeShirtRateCard;

	const shouldShowDefaultRatesBanner =
		teeShirtRateCard.isNull || // Rates have been intentionally set back to default
		(typeof legacyCommissionRate === 'undefined' && teeShirtRateCard.isEmpty); // Rates have not been set in the first place

	const rateRows: DefinitionListRow[] = [
		{
			title: 'Up to $2,499',
			content: <RateCardRate id="rate-xs" rate={rateCard.rates.xs} variant={variant} />,
		},
		{
			title: '$2,500 - $4,999',
			content: <RateCardRate id="rate-sm" rate={rateCard.rates.sm} variant={variant} />,
		},
		{
			title: '$5,000 - $9,999',
			content: <RateCardRate id="rate-md" rate={rateCard.rates.md} variant={variant} />,
		},
		{
			title: '$10,000 - $249,999',
			content: <RateCardRate id="rate-lg" rate={rateCard.rates.lg} variant={variant} />,
		},
		{
			title: '$250,000 +',
			content: <RateCardRate id="rate-xl" rate={rateCard.rates.xl} variant={variant} />,
		},
	];

	const [shouldShowEditRateCardModal, setShouldShowEditRateCardModal] = useState(false);

	return (
		<>
			<div className="flex justify-between mb-4">
				<h2 className="text-display5 mr-4">
					{variant === 'auction' ? 'Auction Commission Rates' : 'Marketplace Commission Rates'}
				</h2>
				<RestrictedArea allowedRoles={['Admin', 'Finance']}>
					<Button
						size="md"
						trailingIcon="Edit"
						onClick={() => setShouldShowEditRateCardModal(true)}
					>
						Edit
					</Button>
				</RestrictedArea>
			</div>
			{shouldShowEditRateCardModal && (
				<EditRateCardModal
					onClose={() => setShouldShowEditRateCardModal(false)}
					variant={variant}
					rates={rateCard.rates}
				/>
			)}
			{shouldShowDefaultRatesBanner ? (
				<Banner
					variant="gray"
					icon="Info"
					title={variant === 'auction' ? 'Default Auction Rates' : 'Default Marketplace Rates'}
					description={`Click the "Edit" button to assign custom rates.`}
				/>
			) : (
				<DefinitionList rows={rateRows} />
			)}
		</>
	);
};

export default RateCard;

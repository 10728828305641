import { axiosAuth as axios } from './httpUtils';
import config from '../config';

export const invoiceStatus = {
	PENDING_APPROVAL: 'Pending_Approval',
	BLOCKED: 'Blocked',
	AWAITING_PAYMENT: 'Awaiting_Payment',
	PAYMENT_FAILED: 'Payment_Failed',
	PAID: 'Paid',
	VOID: 'Void',
};

export const shippingStatus = {
	UNPICKED: 'Unpicked',
	READY: 'Ready',
	SHIPPED: 'Shipped',
};

/** @deprecated - moving to src/services/.. */
export async function getInvoices(auction_id, invoice_status, filters) {
	try {
		console.log(auction_id);
		const response = await axios.post(`${config.api.auction_invoices}`, {
			company_id: config.organization.company_id,
			auction_id: auction_id,
			invoice_status: invoice_status,
			query_type: 'auction',
			filters: filters,
		});
		let invoices = response.data.body.invoices;
		return invoices;
	} catch (err) {
		console.log(`Unable to add auction: ${err}`);
		throw err;
	}
}

import { isArray, omit, without } from 'lodash';
import { useState } from 'react';
import { FacetTitle } from '../../../../../services/facetsService/getFacets.model';
import {
	FacetSearchQuery,
	FacetSearchQueryKey,
	FACET_SEARCH_QUERY_KEYS,
} from '../../../../../services/lotsService/lotsService.model';

const addFacet = ({
	facetTitle,
	facetValue,
	currentState,
}: {
	facetTitle: FacetTitle;
	facetValue: string;
	currentState: FacetSearchQuery;
}): FacetSearchQuery => {
	const queryKey: FacetSearchQueryKey = FACET_SEARCH_QUERY_KEYS[facetTitle];

	const currentStateForQueryKey = currentState[queryKey] || [];
	const updatedStateForQueryKey = [...currentStateForQueryKey, facetValue];

	return {
		...currentState,
		[queryKey]: updatedStateForQueryKey,
	};
};

const removeFacet = ({
	facetTitle,
	facetValue,
	currentState,
}: {
	facetTitle: FacetTitle;
	facetValue: string;
	currentState: FacetSearchQuery;
}): FacetSearchQuery => {
	const queryKey: FacetSearchQueryKey = FACET_SEARCH_QUERY_KEYS[facetTitle];

	const currentStateForQueryKey = currentState[queryKey] || [];
	const updatedStateForQueryKey = without(currentStateForQueryKey, facetValue);

	if (updatedStateForQueryKey.length === 0) {
		return omit(currentState, queryKey);
	}

	return {
		...currentState,
		[queryKey]: updatedStateForQueryKey,
	};
};

export const getIsFacetValueInCurrentState = ({
	facetTitle,
	facetValue,
	currentState,
}: {
	facetTitle: FacetTitle;
	facetValue: string;
	currentState: FacetSearchQuery;
}): boolean => {
	const queryKey: FacetSearchQueryKey = FACET_SEARCH_QUERY_KEYS[facetTitle];

	return (
		!!currentState[queryKey] &&
		isArray(currentState[queryKey]) &&
		!!currentState[queryKey]?.includes(facetValue)
	);
};

const _toggleFacetValue = ({
	facetTitle,
	facetValue,
	currentState,
}: {
	facetTitle: FacetTitle;
	facetValue: string;
	currentState: FacetSearchQuery;
}): FacetSearchQuery => {
	const isFacetValueInCurrentState = getIsFacetValueInCurrentState({
		facetTitle,
		facetValue,
		currentState,
	});

	if (isFacetValueInCurrentState) {
		return removeFacet({
			facetTitle,
			facetValue,
			currentState,
		});
	}

	return addFacet({
		facetTitle,
		facetValue,
		currentState,
	});
};

export const useFacetSelection = () => {
	const [facetSearchQuery, setFacetSearchQuery] = useState({} as FacetSearchQuery);

	const toggleFacetValue = ({
		facetTitle,
		facetValue,
	}: {
		facetTitle: FacetTitle;
		facetValue: string;
	}) => {
		const updatedFacetSearchQuery: FacetSearchQuery = _toggleFacetValue({
			facetTitle,
			facetValue,
			currentState: facetSearchQuery,
		});

		return setFacetSearchQuery(updatedFacetSearchQuery);
	};

	const resetFacetSearchQuery = () => setFacetSearchQuery({} as FacetSearchQuery);

	return { facetSearchQuery, resetFacetSearchQuery, toggleFacetValue };
};

import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';
import { SortableTableParams } from '../SortableTable.model';

const useTable = <T>(params: SortableTableParams<T>) =>
	useReactTable({
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 100,
			},
		},
		...params,
		// Pipeline
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

export default useTable;

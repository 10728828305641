import React, { useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {
	BASE_CUSTOMER_MANAGEMENT_TABS,
	CustomerManagementTab,
	CustomerManagerTabProps,
} from './CustomerManagerTabs.model';
import useDecision from '../../../../optimizely/useDecision';
import { FeatureFlags } from '../../../../optimizely/useDecision/useDecision.model';

const CustomerManagerTabs = ({
	activeTab,
	userRole,
	onSelect,
	selectedTabFromParams,
}: CustomerManagerTabProps) => {
	const isVaultCollectionEnabled = useDecision('vault-collection');
	const disableVaultListingFF = useDecision(FeatureFlags.DisableVaultListing);

	const baseTabs =
		isVaultCollectionEnabled && !disableVaultListingFF
			? BASE_CUSTOMER_MANAGEMENT_TABS
			: BASE_CUSTOMER_MANAGEMENT_TABS.filter((tab) => tab.path !== 'vault');

	const tabsToShow: CustomerManagementTab[] = baseTabs.filter((tab) =>
		tab.allowedRoles ? userRole && tab.allowedRoles.includes(userRole) : tab
	);

	useEffect(() => {
		if (
			selectedTabFromParams &&
			selectedTabFromParams.label !== activeTab.label &&
			tabsToShow.filter((tab) => tab.label == selectedTabFromParams.label).length > 0
		) {
			onSelect(selectedTabFromParams);
		}
	}, [selectedTabFromParams]);

	return (
		<Nav className="mx-auto flex-nowrap">
			{tabsToShow.map(({ label, path }) => (
				<NavItem key={path}>
					<NavLink
						className={classnames({ active: activeTab.label === label })}
						onClick={() => onSelect({ label, path })}
					>
						{label}
					</NavLink>
				</NavItem>
			))}
		</Nav>
	);
};

export default CustomerManagerTabs;

import { useEffect, useState } from 'react';
import { getFilenameForLegacyImage } from '../../../../../AdminUtils/imageUtils.helpers';
import useImageUrl from '../../../../../queries/useImageUrl';
import { Auction } from '../../../../../services/auctionsService';
import LoadingWrapper from '../../../../LoadingWrapper';
import auctionFallbackImage from '../../../../../assets/images/placeholder-card-xs.png';
import { Chip } from '../../../../../prizm-ui/Chip';
import AuctionStatusChip from '../../../../AuctionStatusChip';

const AuctionItem = ({
	auction,
	selectedAuctionId,
	selectAuction,
}: {
	auction: Auction;
	selectedAuctionId: string;
	selectAuction: (selectedAuctionId: string) => void;
}) => {
	const auctionImageFilename = getFilenameForLegacyImage({
		imageIdentifier: auction.primaryImageName ?? '',
	});

	const { data: imageUrl, status: imageUrlStatus } = useImageUrl({
		storageProvider: 'cloudfront',
		rootPath: 'Auctions',
		itemId: auctionImageFilename,
		parentPath: auction.id,
		imageSize: '@1x',
	});

	const [auctionImage, setAuctionImage] = useState(imageUrl);
	useEffect(() => {
		if (imageUrl) {
			setAuctionImage(imageUrl);
		}
	}, [imageUrl]);

	return (
		<button
			className="p-2 w-full flex items-start cursor-pointer text-body1 hover:bg-neutral2 rounded-xl text-left"
			onClick={() => selectAuction(auction.id)}
		>
			<div className="flex">
				<div className="mr-4 h-12 w-16 flex items-center justify-center">
					<LoadingWrapper queryStatuses={[imageUrlStatus]}>
						<img
							src={auctionImage}
							className="max-h-full max-w-full object-contain"
							onError={() => setAuctionImage(auctionFallbackImage)}
						/>
					</LoadingWrapper>
				</div>
				<div className="flex h-full">
					<div className="max-w-[16rem] leading-[1.35rem]">{auction.title}</div>
					<div className="ml-4 flex items-start flex-grow">
						{auction.id !== 'Unassigned' && <AuctionStatusChip status={auction.status} />}
						{auction.id === selectedAuctionId && (
							<div className="ml-2">
								<Chip variant="gray">Selected</Chip>
							</div>
						)}
					</div>
				</div>
			</div>
		</button>
	);
};

export default AuctionItem;

import React from 'react';

export const INPUT_HEIGHT_CLASS = 'h-16';

/**
 * Shared Classes
 */

export const getInputClasses = ({
	hasIcon,
	invalid,
	hasLabel = true,
}: {
	hasIcon?: boolean;
	invalid?: boolean;
	hasLabel?: boolean;
} = {}) => {
	return [
		'w-full',
		'h-full',
		'px-4',
		'border',
		'border-neutralstroke1',
		'transition',
		'focus:outline-none',
		'focus:ring-1',
		...(hasLabel ? ['pt-5', 'placeholder-transparent'] : []),
		...(invalid
			? ['border-error-500', 'ring-error-500', 'focus:border-error-500', 'focus:ring-error-500']
			: [
					'focus:border-primary-500',
					'focus:ring-primary-500',
					'group-focus-within:border-primary-500',
					'group-focus-within:ring-primary-500',
					'invalid:pr-14',
					'invalid:border-error-500',
					'invalid:ring-error-500',
					'focus:invalid:border-error-500',
					'focus:invalid:ring-error-500',
			  ]),
		'rounded-lg',
		'bg-inverted',
		'text-body1',
		'peer',
		'disabled:bg-neutralbkg2',
		'disabled:border-neutralstroke2',
		...(hasIcon || invalid ? ['pr-14'] : []),
	].join(' ');
};

/**
 * Shared Components
 */

export const Label = ({
	children,
	hidden,
	id,
	invalid,
	disabled,
	hasValue,
	variant = 'input',
}: {
	children: React.ReactNode;
	hidden?: boolean;
	id: string;
	invalid?: boolean;
	disabled?: boolean;
	hasValue?: boolean;
	variant?: 'input' | 'select' | 'textarea';
}) => {
	return (
		<label
			htmlFor={id}
			data-disabled={disabled}
			data-invalid={invalid}
			className={
				hidden
					? undefined
					: [
							'flex',
							'items-center',
							'absolute',
							'top-px',
							'left-4',
							'py-2.5',
							'pr-4',
							'text-body1',
							'whitespace-nowrap',
							'pointer-events-none',
							'transition',
							'duration-75',
							'origin-top-left',
							'peer-disabled:text-neutral3',
							'peer-focus:scale-75',
							'peer-focus:bottom-auto',
							'group-focus-within:text-primary-500',
							'group-focus-within:scale-75',
							'group-focus-within:bottom-auto',
							...(invalid
								? [
										'text-error-500',
										'peer-focus:text-error-500',
										'group-focus-within:text-error-500',
								  ]
								: [
										'text-neutral3',
										'peer-focus:text-primary-500',
										'peer-invalid:text-error-500',
										'peer-invalid:peer-focus:text-error-500',
								  ]),
							...(variant === 'textarea'
								? [
										'bg-inverted',
										'peer-disabled:bg-neutralbkg2',
										'peer-focus:h-auto',
										'group-focus-within:h-auto',
										`peer-placeholder-shown:${INPUT_HEIGHT_CLASS}`,
								  ]
								: variant === 'input'
								? ['peer-placeholder-shown:bottom-px']
								: []),
							...(variant === 'select'
								? hasValue
									? ['scale-75']
									: ['scale-100', 'bottom-px']
								: ['scale-75', 'bottom-auto', 'peer-placeholder-shown:scale-100']),
					  ].join(' ')
			}
		>
			{children}
		</label>
	);
};

export const ErrorText = ({
	errorText,
	id,
	invalid,
}: {
	errorText?: string;
	id: string;
	invalid?: boolean;
}) => (
	<>
		{errorText && (
			<div
				id={`${id}-error`}
				role="alert"
				className={[
					'mt-2',
					'text-body2',
					'text-error-500',
					'peer-invalid:block',
					...(invalid ? ['block'] : ['hidden']),
				].join(' ')}
			>
				{errorText}
			</div>
		)}
	</>
);

export const HelpText = ({
	errorText,
	helpText,
	id,
	invalid,
}: {
	errorText?: string;
	helpText?: string;
	id: string;
	invalid?: boolean;
}) => (
	<>
		{!(invalid && errorText) && helpText && (
			<div id={`${id}-hint`} className="mt-2 text-body2 text-neutral3">
				{helpText}
			</div>
		)}
	</>
);

import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import useDecision from '../../../optimizely/useDecision';
import useAuctionById from '../../../queries/useAuctionById';
import { InvoicesQueryType } from '../../../services/invoicesService/invoicesService.model';
import InvoicesTable from '../../../components/InvoicesTable';
import WinnerSummaryDashboard from './Dashboard';
import { FeatureFlags } from '../../../optimizely/useDecision/useDecision.model';

const WinnerSummary = () => {
	const history = useHistory();
	const { auctionId = '', queryType = 'All' } = useParams<{
		auctionId?: string;
		queryType?: InvoicesQueryType;
	}>();
	const { data: auction, status: auctionStatus } = useAuctionById(auctionId);

	const onChangeQueryType = (queryType: InvoicesQueryType) =>
		history.push(`/auctions/winnersummary/${auctionId}/${queryType}`);

	const shouldShowNewInvoicesTable = useDecision(FeatureFlags.NewInvoicesTable);

	return (
		<LoadingWrapper queryStatuses={[auctionStatus]}>
			<Card className="mb-4">
				<h1 className="text-display4">Winner Summary</h1>
				<h2 className="text-display5 mb-4">{auction?.title ?? 'No Auction Selected'}</h2>
				<NewAuctionPicker
					selectedAuctionId={auctionId}
					setSelectedAuctionId={(selectedAuctionId) =>
						history.push(`/auctions/winnersummary/${selectedAuctionId}/All`)
					}
					allowedAuctionStatuses={['Completed', 'Active']}
				/>
				{auction && shouldShowNewInvoicesTable && (
					<InvoicesTable
						auctionId={auctionId}
						mode="auction"
						defaultQueryType={queryType}
						onChangeQueryType={onChangeQueryType}
					/>
				)}
			</Card>
			{auction && !shouldShowNewInvoicesTable && (
				<WinnerSummaryDashboard auction={auctionId} auction_title={auction.title} />
			)}
		</LoadingWrapper>
	);
};

export default WinnerSummary;

/* eslint-disable no-console */
import React, { useState } from 'react';
import UserPicker from './UserPicker';
import { useMutation } from 'react-query';
import { mergeUserInfo } from '../../../../services/userService/mergeUserInfo';
import { MergeAccountsDropdownMenuItem } from './MergeAccounts.model';
import Button from '../../../../components/Button';
import ProcessedResults from './ProcessedResults';
import ErrorMessage from './ErrorMessage';
import { MergeUserInfoResponseData } from '../../../../services/userService/mergeUserInfo.model';

const MergeAccountsTab = ({ selectedFromUserId }: { selectedFromUserId: string }) => {
	const [destinationUserAccount, setDestinationUserAccount] =
		useState<MergeAccountsDropdownMenuItem | null>();
	const [mergeUserFunctionResults, setMergeUserFunctionResults] = useState<
		MergeUserInfoResponseData | undefined
	>();
	const [errorMessage, setErrorMessage] = useState('');

	const handleClick = async () => {
		const WARNING_MESSAGE =
			'WARNING: This process will permanently transfer all data to the selected account and disable this account! Do you wish to continue?';

		if (confirm(WARNING_MESSAGE)) {
			mergeUserInfoAction({ selectedMergeToUser: destinationUserAccount });
		} else {
			console.log('mergeAccounts', 'Cancel clicked');
		}
	};

	const { mutate: mergeUserInfoAction, status: mergeUserRequestStatus } = useMutation({
		mutationFn: ({
			selectedMergeToUser,
		}: {
			selectedMergeToUser: MergeAccountsDropdownMenuItem | null | undefined;
		}) =>
			mergeUserInfo({
				userIdFrom: selectedFromUserId,
				userIdTo: selectedMergeToUser?.value.userId || '',
			}),
		// @TODO build more comprehensive type for an error response
		onError: (error: { errorMessage: string }) => {
			setErrorMessage(error.errorMessage);
		},
		onSuccess: (data) => {
			setMergeUserFunctionResults(data as MergeUserInfoResponseData);
		},
	});

	return (
		<div className="ml-2">
			Merge To: <br />
			<div className="mt-2">
				<UserPicker setSelectedValue={setDestinationUserAccount} />
			</div>
			<div>
				<Button
					disabled={destinationUserAccount == null || mergeUserRequestStatus === 'loading'}
					color="secondary"
					onClick={() => handleClick()}
					kind={'primary'}
					className="mt-2"
				>
					Merge Accounts
				</Button>
				{mergeUserRequestStatus === 'error' && <ErrorMessage>{errorMessage}</ErrorMessage>}
				{mergeUserRequestStatus === 'success' && mergeUserFunctionResults && (
					<ProcessedResults results={mergeUserFunctionResults} />
				)}
			</div>
		</div>
	);
};

export default MergeAccountsTab;

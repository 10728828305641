import React from 'react';
import { IconName, ICONS } from './icons.shared';
import { Icon as Iconify } from '@iconify/react';

type GlobalIconProps = {
	children: React.ReactNode;
	icons: IconName[];
};

export const GlobalIconsContext = React.createContext<{
	icons: IconName[];
}>({
	icons: [],
});

export const GlobalIconsProvider = ({ children, icons }: GlobalIconProps) => {
	return (
		<>
			<svg
				xmlns="https://www.w3.org/2000/svg"
				xmlnsXlink="https://www.w3.org/1999/xlink"
				className="hidden"
				aria-hidden="true"
			>
				{icons?.map((icon) => (
					<symbol key={icon} id={`svg-${icon}`}>
						<Iconify icon={ICONS[icon]} height="100%" width="100%" />
					</symbol>
				))}
			</svg>
			<GlobalIconsContext.Provider value={{ icons }}>{children}</GlobalIconsContext.Provider>
		</>
	);
};

import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import SellerStatementTable from '../../../components/SellerStatementTable';
import useAuctionById from '../../../queries/useAuctionById';
import { getTestIdentifier } from '../../../utils/test-identifiers/getTestIdentifier';

const SellerSummary = () => {
	const history = useHistory();
	const { auctionId = '' } = useParams<{ auctionId?: string }>();
	const { data: auction, status: auctionStatus } = useAuctionById(auctionId);

	return (
		<LoadingWrapper queryStatuses={[auctionStatus]}>
			<Card className="mb-4">
				<h1 className="text-display4">Seller Summary</h1>
				<h2 className="text-display5 mb-4">{auction?.title ?? 'No Auction Selected'}</h2>
				<NewAuctionPicker
					allowedAuctionStatuses={['Completed', 'Active']}
					selectedAuctionId={auctionId}
					setSelectedAuctionId={(selectedAuctionId) =>
						history.push(`/auctions/sellersummary/${selectedAuctionId}`)
					}
					testIdentifier={getTestIdentifier({
						componentName: 'SellerSummary',
						descriptor: 'new-auction-picker-button',
						pagePath: 'sellersummary',
						sectionPath: 'auctions',
					})}
				/>
				{auction && (
					<div className="mt-4">
						<SellerStatementTable queryBy="auctionId" auctionId={auctionId} status="All" />
					</div>
				)}
			</Card>
		</LoadingWrapper>
	);
};

export default SellerSummary;

import { Button } from '../../prizm-ui/Button';

const ConfirmationButtons = ({
	cancelButtonLabel = 'Cancel',
	className = '',
	canCancel = true,
	canConfirm = true,
	confirmButtonLabel = 'Confirm',
	destructive = false,
	disabled = false,
	loading = false,
	onCancel,
	onConfirm,
	size = 'md',
}: {
	cancelButtonLabel?: string;
	canConfirm?: boolean;
	canCancel?: boolean;
	className?: string;
	confirmButtonLabel?: string;
	destructive?: boolean;
	disabled?: boolean;
	loading?: boolean;
	onCancel: () => void;
	onConfirm: () => void;
	size?: 'sm' | 'md' | 'lg';
}) => {
	return (
		<div className={`flex ${className}`}>
			<div className="mr-2 min-w-37">
				<Button
					variant="brand"
					className="w-full"
					size={size}
					destructive={destructive}
					onClick={onConfirm}
					disabled={!canConfirm || disabled || loading}
					loading={loading}
				>
					{confirmButtonLabel}
				</Button>
			</div>
			<div>
				<Button
					variant="secondary"
					size={size}
					onClick={onCancel}
					disabled={!canCancel || disabled || loading}
				>
					{cancelButtonLabel}
				</Button>
			</div>
		</div>
	);
};

export default ConfirmationButtons;

import { ColumnDef } from '@tanstack/react-table';
import SortableTable from '../../../../SortableTable/SortableTable';
import { SortableTableParams } from '../../../../SortableTable/SortableTable.model';
import { KernelLotHistoryAuctionEntry } from '../../../../../services/lotsService/lotsService.model';
import { formatDate } from '../../../../../utils/formatters';

const LotAuctionHistoryContent = ({
	auctionHistoryEntries,
}: {
	auctionHistoryEntries: KernelLotHistoryAuctionEntry[];
}) => {
	const columns: ColumnDef<KernelLotHistoryAuctionEntry>[] = [
		{
			header: 'Date',
			id: 'date',
			accessorFn: ({ date }) => formatDate(date),
		},
		{
			accessorKey: 'auctionName',
			header: 'Auction',
			id: 'auction',
		},
		{
			header: 'Admin',
			id: 'admin',
			cell: ({
				row: {
					original: { modified },
				},
			}) => (modified ? modified.fullName : ''),
		},
	];

	const sortableTableParams: SortableTableParams<KernelLotHistoryAuctionEntry> = {
		columns,
		data: auctionHistoryEntries,
		enableColumnFilters: false,
		getRowId: ({ auctionId }) => auctionId,
	};

	return (
		<div>
			<div className="text-subtitle2">Auctions</div>
			<div className="mt-4">
				<SortableTable
					key="lot-auction-history-content"
					tableParams={sortableTableParams}
					withGlobalFilter={false}
					withPagination={false}
				/>
			</div>
		</div>
	);
};

export default LotAuctionHistoryContent;

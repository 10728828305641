import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { Auth } from 'aws-amplify';
import { Archive, Pageview } from '@material-ui/icons';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControl,
	Grid,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@material-ui/core';
import { TabContent, TabPane, Row, Col, CardBody, CardTitle } from 'reactstrap';
import { isAccountReportType, sendReportRequest } from '../../../AdminUtils/reportUtils';
import { LightBlueButton } from '../../../zenauction-ui-shared/Utilities';
import { AUCTION_ID_FIELD } from '../../../AdminUtils';
import { fetchAuctions } from '../../../AdminUtils/auctionUtils';
import AuctionPicker from '../../../components/AuctionPicker';
import Card from '../../../components/Card';
import { getTestIdentifier } from '../../../utils/test-identifiers/getTestIdentifier';

const ReportsPage = () => {
	const [state, setState] = useState({
		allAuctions: [],
		auctionSelectorListKey: 1,
		buttonText: 'Generate Report',
		cashAccountCategory: null,
		cashAccountDateFrom: null,
		cashAccountDateTo: null,
		chargeDateFrom: null,
		chargeDateTo: null,
		currentAuction: {},
		dueDateFrom: null,
		dueDateTo: null,
		email: '',
		enableActive: true,
		enableCompleted: true,
		enableNotStarted: false,
		filteredAuctions: [],
		id: uuidv4(),
		isDataFetched: false,
		isDialogOpen: false,
		isMobile: false,
		reportType: 'buyerReport',
		titleKey: 1,
	});

	useEffect(() => {
		fetchAuctions().then((auctions) => {
			const allAuctions = auctions;
			const filteredAuctions =
				allAuctions && allAuctions.length
					? allAuctions.filter((auction) => auction.status !== 'Not_Started')
					: [];
			setState((prevState) => ({
				...prevState,
				allAuctions,
				filteredAuctions,
				isDataFetched: true,
			}));
		});

		Auth.currentAuthenticatedUser().then((user) => {
			setState((prevState) => ({
				...prevState,
				email: user.attributes.email,
			}));
		});
	}, []);

	const generateReport = () => {
		if (isEqual(state.currentAuction, {}) && !isAccountReportType(state.reportType)) {
			setState((prevState) => ({
				...prevState,
				buttonText: 'Error - Please select an auction',
			}));
			return;
		}
		setState((prevState) => ({
			...prevState,
			buttonText: 'Please wait...',
		}));

		let extras = {};
		if (isAccountReportType(state.reportType)) {
			if (state.cashAccountCategory && state.cashAccountCategory !== '_default')
				extras.operationType = state.cashAccountCategory;
			if (state.cashAccountDateFrom) extras.dateFrom = state.cashAccountDateFrom;
			if (state.cashAccountDateTo) extras.dateTo = state.cashAccountDateTo;
			if (state.dueDateFrom) extras.dueDateFrom = state.dueDateFrom;
			if (state.dueDateTo) extras.dueDateTo = state.dueDateTo;
			if (state.chargeDateFrom) extras.chargeDateFrom = state.chargeDateFrom;
			if (state.chargeDateTo) extras.chargeDateTo = state.chargeDateTo;
		}

		sendReportRequest(state.reportType, state.currentAuction[AUCTION_ID_FIELD], state.email, extras)
			.then((response) => {
				if (!response.length) {
					setState((prevState) => ({
						...prevState,
						buttonText: 'Error - Please try again',
					}));
					return;
				}

				setState((prevState) => ({
					...prevState,
					buttonText: 'Generate Report',
					isDialogOpen: true,
				}));
			})
			.catch((err) => {
				console.error(`Report request failed: ${err}`);
				setState((prevState) => ({
					...prevState,
					buttonText: 'Error - Please try again',
				}));
			});
	};

	const getFilteredAuctions = (listOfAuctions, enableActive, enableNotStarted, enableCompleted) => {
		let filteredAuctions = listOfAuctions;

		if (!enableActive)
			filteredAuctions = filteredAuctions.filter(function (auction) {
				return auction.status !== 'Active' && auction.status !== 'Preview';
			});
		if (!enableNotStarted)
			filteredAuctions = filteredAuctions.filter(function (auction) {
				return auction.status !== 'Not_Started';
			});
		if (!enableCompleted)
			filteredAuctions = filteredAuctions.filter(function (auction) {
				return auction.status !== 'Completed';
			});

		return filteredAuctions;
	};

	const updateActiveAuction = (selectedAuction) => {
		let selection =
			typeof selectedAuction == 'number' &&
			state.filteredAuctions &&
			selectedAuction < state.filteredAuctions.length &&
			state.filteredAuctions[selectedAuction]
				? state.filteredAuctions[selectedAuction]
				: null;

		const updatedFilteredAuctions = selection
			? updateFilteredAuction(selection)
			: state.filteredAuctions;

		setState((prevState) => ({
			...prevState,
			currentAuction: selection ? cloneDeep(selection) : {},
			filteredAuctions: updatedFilteredAuctions,
			auctionSelectorListKey: prevState.auctionSelectorListKey + 1,
			titleKey: prevState.titleKey + 1,
			buttonText: 'Generate Report',
		}));
	};

	const filterAuctions = (type, value) => {
		let enableActive = type === 'Active' ? value : state.enableActive;
		let enableNotStarted = type === 'Not_Started' ? value : state.enableNotStarted;
		let enableCompleted = type === 'Completed' ? value : state.enableCompleted;

		let auctionSelectorChoices = getFilteredAuctions(
			state.allAuctions,
			enableActive,
			enableNotStarted,
			enableCompleted
		);

		let currentAuction = state.currentAuction;
		if (currentAuction) {
			if (
				(currentAuction.status === 'Active' || currentAuction.status === 'Preview') &&
				!enableActive
			)
				currentAuction = {};
			else if (currentAuction.status === 'Inactive' && !enableNotStarted) currentAuction = {};
			else if (currentAuction.status === 'Completed' && !enableCompleted) currentAuction = {};
		}

		setState((prevState) => ({
			...prevState,
			enableActive: enableActive,
			enableNotStarted: enableNotStarted,
			enableCompleted: enableCompleted,
			filteredAuctions: auctionSelectorChoices,
			currentAuction: currentAuction,
			auctionSelectorListKey: prevState.auctionSelectorListKey + 1,
			titleKey: prevState.titleKey + 1,
		}));
	};

	const updateFilteredAuction = (updatedAuction) => {
		if (!updatedAuction) return state.filteredAuctions;

		let newFilteredAuctions = cloneDeep(state.filteredAuctions);

		for (const [index, auction] of newFilteredAuctions.entries()) {
			if (auction[AUCTION_ID_FIELD] === updatedAuction[AUCTION_ID_FIELD]) {
				newFilteredAuctions[index] = updatedAuction;
				break;
			}
		}

		return newFilteredAuctions;
	};

	if (!state.isDataFetched) return null;

	return (
		<Fragment>
			<ReactCSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<Card>
					<div className="w-full flex flex-row justify-between">
						<h1
							className="text-display4 mb-4"
							data-testid={getTestIdentifier({
								componentName: 'ReportPage',
								descriptor: 'header',
								sectionPath: 'reports',
							})}
						>
							Reports
						</h1>
					</div>
					<Row>
						<Col md="12" className="display-grid">
							<TabContent>
								<TabPane>
									<AuctionPicker
										selectedAuctionId={state.currentAuction.auction_id}
										setSelectedAuctionId={(selectedAuctionId) =>
											setState((prevState) => ({
												...prevState,
												currentAuction: state.allAuctions.find(
													({ auction_id }) => auction_id === selectedAuctionId
												),
											}))
										}
									/>
									<Card key="report-selector-card" className="main-card mb-3">
										<CardBody className="auction-card">
											<CardTitle>
												<Pageview /> Select a Report Type
											</CardTitle>
											<Grid
												container
												spacing={2}
												alignItems="center"
												justifyContent="flex-start"
												className="auction-controls-bar"
												direction={isMobile ? 'column' : 'row'}
											>
												<Grid item>
													<FormControl component="fieldset">
														<RadioGroup
															aria-label="report-type"
															defaultValue="buyerReport"
															name="report-buttons-group"
															color="primary"
															onChange={(event) => {
																setState((prevState) => ({
																	...prevState,
																	reportType: event.target.value,
																	showDateFilters: isAccountReportType(event.target.value),
																}));

																if (isAccountReportType(event.target.value)) {
																	setState((prevState) => ({
																		...prevState,
																		chargeDate: null,
																		dueDate: null,
																	}));
																}
															}}
														>
															<FormControlLabel
																value="buyerReport"
																control={<Radio />}
																label="Buyer Report"
															/>
															<FormControlLabel
																value="sellerReport"
																control={<Radio />}
																label="Seller Report"
															/>
															<FormControlLabel
																value="bidReport"
																control={<Radio />}
																label="Bid Report"
															/>
															<FormControlLabel
																value="statementReport"
																control={<Radio />}
																label="Statement Report"
															/>
															<FormControlLabel
																value="accountReceivableReport"
																control={<Radio />}
																label="Account Receivable Report"
															/>
															<FormControlLabel
																value="accountPaymentsReport"
																control={<Radio />}
																label="Account Payments Report"
															/>
															<FormControlLabel
																value="cashAccountReport"
																control={<Radio />}
																label="All User Balances Report"
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</CardBody>
									</Card>
									{state.showDateFilters && (
										<Card key="report-datefilters-card" className="main-card mb-3">
											<CardBody className="auction-card">
												<CardTitle>
													<Pageview />
													Additional Filters
												</CardTitle>
												{state.reportType !== 'cashAccountReport' && (
													<Grid container direction={isMobile ? 'column' : 'row'}>
														Charge Date
														<Grid
															container
															direction={isMobile ? 'column' : 'row'}
															alignItems="center"
														>
															<TextField
																helperText="From"
																id="charge-date-from"
																type="date"
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		chargeDateFrom: e.target.value,
																	}))
																}
																defaultValue={state.chargeDateFrom}
															/>
															<TextField
																helperText="To"
																id="charge-date-to"
																type="date"
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		chargeDateTo: e.target.value,
																	}))
																}
																defaultValue={state.chargeDateTo}
															/>
														</Grid>
														Due Date
														<Grid container direction="row">
															<TextField
																id="due-date-from"
																helperText="From"
																type="date"
																defaultValue={state.dueDate}
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		dueDateFrom: e.target.value,
																	}))
																}
															/>
															<TextField
																id="due-date-to"
																type="date"
																helperText="To"
																defaultValue={state.dueDateTo}
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		dueDateTo: e.target.value,
																	}))
																}
															/>
														</Grid>
													</Grid>
												)}
												{state.reportType === 'cashAccountReport' && (
													<Grid container direction={isMobile ? 'column' : 'row'}>
														Date Range
														<Grid
															className="mb-4"
															container
															direction={isMobile ? 'column' : 'row'}
															alignItems="center"
														>
															<TextField
																helperText="From"
																id="cash-account-date-from"
																type="date"
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		cashAccountDateFrom: e.target.value,
																	}))
																}
																defaultValue={state.cashAccountDateFrom}
															/>
															<TextField
																helperText="To"
																id="cash-account-date-to"
																type="date"
																onChange={(e) =>
																	setState((prevState) => ({
																		...prevState,
																		cashAccountDateTo: e.target.value,
																	}))
																}
																defaultValue={state.cashAccountDateTo}
															/>
														</Grid>
														Category
														<Grid container direction={isMobile ? 'column' : 'row'}>
															<Select
																defaultValue={'_default'}
																id="cash-account-category"
																onChange={({ target: { value } }) =>
																	setState((prevState) => ({
																		...prevState,
																		cashAccountCategory: value,
																	}))
																}
															>
																<MenuItem value="_default">Select ...</MenuItem>
																<MenuItem value="adjustment">Adjustments</MenuItem>
																<MenuItem value="purchase">Payments</MenuItem>
																<MenuItem value="withdrawal">Payouts</MenuItem>
																<MenuItem value="deposit">Proceeds</MenuItem>
															</Select>
														</Grid>
													</Grid>
												)}
											</CardBody>
										</Card>
									)}
									<Card key="report-create-card" className="main-card mb-3">
										<CardBody className="auction-card">
											<CardTitle>
												<Pageview />
												Request Report
											</CardTitle>
											<Grid
												container
												spacing={2}
												alignItems="center"
												justifyContent="flex-start"
												className="auction-controls-bar"
												direction={isMobile ? 'column' : 'row'}
											>
												<Grid item>
													<LightBlueButton
														variant="contained"
														color="primary"
														startIcon={<Archive />}
														onClick={generateReport}
														className="generate-report-button"
													>
														{state.buttonText}
													</LightBlueButton>
												</Grid>
											</Grid>
										</CardBody>
									</Card>
									<Dialog open={Boolean(state.isDialogOpen)}>
										<DialogContent>
											<DialogContentText>
												{`The report is being generated. You'll receive an email at ${state.email} when it's ready.`}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={() =>
													setState((prevState) => ({
														...prevState,
														isDialogOpen: false,
													}))
												}
												color="primary"
											>
												OK
											</Button>
										</DialogActions>
									</Dialog>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Card>
			</ReactCSSTransitionGroup>
		</Fragment>
	);
};

export default withRouter(ReportsPage);

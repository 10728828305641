import { useQuery, useQueryClient } from 'react-query';
import { getEmployees } from '../../services/userService';
import { QueryKeys } from '../queryKeys';
import { Employee } from '../../services/userService/userService.model';

const useEmployees = () => {
	const queryKey = QueryKeys.GetEmployees;
	const baseQuery = useQuery([queryKey], () => getEmployees());

	const queryClient = useQueryClient();

	const addEmployee = (employee: Employee) =>
		queryClient.setQueryData(queryKey, (existingEmployees: Employee[] = [] as Employee[]) => [
			...existingEmployees,
			employee,
		]);

	return { ...baseQuery, addEmployee };
};

export default useEmployees;

import React, { useState } from 'react';
import {
	DndContext,
	DragOverlay,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
	DragStartEvent,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import MoveableBannerItem from './MoveableBannerItem';
import { Banner } from '../../../../../services/layoutService/layoutService.model';
import { useManageBannersContext } from './useManageBannersState';
import Button from '../../../../../components/Button';
import { isEqual } from 'lodash';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../../queries/queryKeys';
import { updateBanners } from '../../../../../services/layoutService';
import { Slide, toast } from 'react-toastify';
import Toast from '../../../../../components/Toast';

const ManageBanners = () => {
	const {
		manageBannersState: { sortedBanners, originalBanners, bannersToDelete },
		dispatch,
	} = useManageBannersContext();
	const setSortedBanners = (updatedBanners: Banner[]) =>
		dispatch({
			type: 'CHANGE_BANNER_ORDER',
			payload: updatedBanners,
		});

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	// This indicates the thumbnail that the user is actively dragging to reorder images
	const [activeBanner, setActiveBanner] = useState<Banner | null>(null);
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;

		if (active) {
			setActiveBanner(active as unknown as Banner);
		}
	};
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!!over && active.id !== over.id) {
			const bannerIds = sortedBanners.map(({ id }) => id);
			const oldIndex = bannerIds.indexOf(active.id);
			const newIndex = bannerIds.indexOf(over.id);

			const updatedOrderedBanners = arrayMove(sortedBanners, oldIndex, newIndex);
			setSortedBanners(updatedOrderedBanners);
		}
		setActiveBanner(null);
	};

	const haveBannersChanged = !isEqual(sortedBanners, originalBanners) || bannersToDelete.length > 0;

	const { mutate: doUpdateBanners, status: updateBannersStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateBanners, ...Object.entries(sortedBanners)],
		mutationFn: (updatedBanners: Banner[]) => updateBanners(updatedBanners),
	});

	const saveChanges = () => {
		const bannerIdsToDelete = bannersToDelete.map(({ id }) => id);
		const updatedBanners = sortedBanners.filter(({ id }) => !bannerIdsToDelete.includes(id));

		doUpdateBanners(updatedBanners, {
			onSuccess: () => {
				toast(<Toast message="Successfully updated banners." sentiment="success" />, {
					transition: Slide,
				});
				dispatch({
					type: 'COMMIT_BANNER_CHANGES',
					payload: updatedBanners,
				});
			},
			onError: () => {
				toast(
					<Toast message="Error updating banners. Please try again later." sentiment="warning" />,
					{
						transition: Slide,
					}
				);
			},
		});
	};

	const cancelChanges = () => {
		dispatch({
			type: 'DISCARD_BANNER_CHANGES',
		});
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<div className="mb-4 flex items-center justify-between">
				<h2 className="text-display4">Manage Banner Images</h2>
			</div>
			<SortableContext items={sortedBanners}>
				<ul className="grid grid-cols-2 gap-4">
					{sortedBanners.map((banner) => (
						<MoveableBannerItem key={banner.id} banner={banner} activeBanner={activeBanner} />
					))}
				</ul>
			</SortableContext>
			<DragOverlay>
				{activeBanner?.id ? (
					<MoveableBannerItem key={activeBanner.id} banner={activeBanner} />
				) : null}
			</DragOverlay>
			<div className="mt-4 flex">
				<Button
					kind="primary"
					size="medium"
					className="mr-4"
					isLoading={updateBannersStatus === 'loading'}
					disabled={!haveBannersChanged}
					onClick={saveChanges}
				>
					Save Changes
				</Button>
				<Button
					kind="tertiary"
					size="medium"
					disabled={!haveBannersChanged}
					onClick={cancelChanges}
				>
					Cancel
				</Button>
			</div>
		</DndContext>
	);
};

export default ManageBanners;

import apiEndpoints from '../../queries/apiEndpoints';
import { putAuthed } from '../../AdminUtils/httpUtils';
import { ShippableAddress } from '../../Pages/orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderCustomerInfo/EditShippingAddressModal/EditShippingAddressModal.model';

/** @deprecated - use updateOrder */
export const updateOrderShippingAddress = async ({
	orderNumber,
	shippingAddress,
}: {
	orderNumber: string;
	shippingAddress: ShippableAddress;
}) => {
	const body = { addressType: 'home', orderNumber, shippingAddress };

	try {
		const response = await putAuthed(`${apiEndpoints.checkout}/orders`, body);

		if (response.status >= 400) {
			throw response;
		}

		return response.data;
	} catch (err) {
		console.log(`Unable to update order status: ${err}`);

		throw err;
	}
};

export default updateOrderShippingAddress;

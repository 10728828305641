import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getIncrementAmount } from '../../../../../../AdminUtils/bidUtils';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import ActionModal from '../../../../../../components/ActionModal';
import Input from '../../../../../../components/Input';
import Label from '../../../../../../components/Label';
import LoadingWrapper from '../../../../../../components/LoadingWrapper';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import useIndividualLot from '../../../../../../queries/useIndividualLot';
import useUserById from '../../../../../../queries/useUserById';
import { placeBid } from '../../../../../../services/bidderService';
import { KernelLot } from '../../../../../../services/lotsService/lotsService.model';
import { User, UserRoles } from '../../../../../../services/userService/userService.model';
import useUserRole from '../../../../../../queries/useUserRole';

const PlaceBidForUserModal = ({
	lotId,
	onClose,
	isCurrentWinner = false,
}: {
	lotId: string;
	onClose: () => void;
	isCurrentWinner?: boolean;
}) => {
	const { data: userRole = UserRoles.Unknown } = useUserRole();
	const canSetMaxBidToCurrentPrice = [
		UserRoles.Admin,
		UserRoles.Finance,
		UserRoles.CustomerSupport,
	].includes(userRole as UserRoles);
	const { userId, auctionId } = useParams<{ userId: string; auctionId: string }>();
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);
	const { data: user = {} as User } = useUserById(userId);

	const basisForMinimumViableBid = Number(lot.current_price) || Number(lot.min_bid_price) || 0;
	const minimumViableBid = () => {
		if (isCurrentWinner && canSetMaxBidToCurrentPrice) {
			return basisForMinimumViableBid;
		} else {
			return lot.current_price && lot.number_of_bids > 0
				? basisForMinimumViableBid + getIncrementAmount(basisForMinimumViableBid)
				: basisForMinimumViableBid;
		}
	};

	const [newMaxBid, setNewMaxBid] = useState<number>();

	/** This provides an artificial loading state to prevent a race condition with ElasticSearch
	 *  in which the current_price does not immediately update correctly with new bids
	 */
	const [isPendingTimeout, setIsPendingTimeout] = useState(false);

	const { mutate: placeMaximumBid, status: placeMaximumBidStatus } = useMutation({
		mutationFn: ({ maxBid = 0 }: { maxBid?: number }) =>
			placeBid({
				auctionId,
				lotId,
				lotNumber: lot.lot_number.toString ? lot.lot_number.toString() : '',
				userId,
				maxBid: maxBid,
				minBid: lot.min_bid_price,
				currentPrice: lot.current_price,
			}),
	});

	const queryClient = useQueryClient();

	const handleSubmit = () =>
		placeMaximumBid(
			{ maxBid: newMaxBid },
			{
				onError: () => {
					showErrorToast('Something went wrong placing maximum bid. Please try again later.');
				},
				onSuccess: () => {
					setIsPendingTimeout(true);
					setTimeout(() => {
						queryClient.refetchQueries([QueryKeys.GetBidsByUserForAuction, userId, auctionId]);
						queryClient.refetchQueries([QueryKeys.GetIndividualLot, lotId]);
						queryClient.refetchQueries([QueryKeys.GetLotsForAuction, auctionId]);
						queryClient.refetchQueries([QueryKeys.GetWatchlistByUser, userId]);
						showSuccessToast('Successfully placed new maximum bid.');
						onClose();
					}, 2000);
				},
			}
		);

	return (
		<ActionModal
			title={`Edit maximum bid on behalf of ${user.givenName} ${user.familyName}`}
			onClose={onClose}
			onConfirm={handleSubmit}
			isLoading={placeMaximumBidStatus === 'loading' || isPendingTimeout}
			canConfirm={!!newMaxBid && newMaxBid >= minimumViableBid() && newMaxBid <= user.biddingPower}
			confirmButtonLabel="Place Bid"
		>
			<LoadingWrapper queryStatuses={[lotStatus]}>
				<div className="mb-4">
					<h3 className="mb-4 text-body1">
						<span className="font-semibold">Title: </span>
						{lot.title}
					</h3>
					<div>
						<span className="font-semibold">Current Price: </span>
						<span>{formatCurrency(lot.current_price)}</span>
					</div>
					<div>
						<span className="font-semibold">Bidding Power: </span>
						<span>{formatCurrency(user.biddingPower)}</span>
					</div>
				</div>
				<div>
					<Label htmlFor="maxBid">Maximum Bid</Label>
					<Input
						onChange={({ target: { value } }) => setNewMaxBid(+value)}
						name="maxBid"
						type="number"
						value={newMaxBid}
						key={minimumViableBid()}
					/>
					<div className="mt-1 text-sm italic">
						Minimum Bid: {formatCurrency(minimumViableBid())}
					</div>
				</div>
			</LoadingWrapper>
		</ActionModal>
	);
};

export default PlaceBidForUserModal;

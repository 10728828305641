import React from 'react';
import { MergeUserInfoResponseData } from '../../../../../services/userService/mergeUserInfo.model';

const ProcessedResults = ({ results }: { results: MergeUserInfoResponseData }) => {
	return (
		<div>
			<div>Processed Time: {results.processedTime}</div>
			<div className="underline">Processed Counts</div>
			<div className="ml-4">
				<div>Updated Auction Lots: {results.processedAuctionLots}</div>
				<div>Updated Bid Transactions: {results.processedBidTransactions}</div>
				<div>Updated Bidder Invoices: {results.processedBidderInvoices}</div>
				<div>Updated Seller Statements: {results.processedSellerStatements}</div>
				<div>Copied Watchlist: {results.processedWatchlist}</div>
				<div className="underline">User Lot Activities</div>
				<div className="ml-2">
					<div>Outbid: {results.processedUserActivityOutbid}</div>
					<div>Watchlist: {results.processedUserActivityWatchlist}</div>
					<div>Winning: {results.processedUserActivityWinner}</div>
				</div>
			</div>
		</div>
	);
};

export default ProcessedResults;

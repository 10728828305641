import React, { FC } from 'react';
import { Dialog } from '@material-ui/core';
import { ModalProps } from './Modal.model';
import { Icon } from '../../prizm-ui/Icon';

const PaperComponent: FC = ({ children }) => (
	<div className="m-8 rounded-lg bg-neutral1">{children}</div>
);

const Modal: FC<ModalProps> = ({ children, title, onClose, className }) => {
	return (
		<Dialog open={true} onClose={onClose} PaperComponent={PaperComponent}>
			<div className={`lg:min-w-160 max-w-full p-4 ${className}`}>
				<div className={`flex items-start p-4 ${title ? 'justify-between' : 'justify-end'}`}>
					{title && <div className="text-subtitle1 flex-grow text-center">{title}</div>}
					<button onClick={onClose}>
						<Icon name="Dismiss" size="md" aria-hidden={false} aria-label="Close" />
					</button>
				</div>
				<div className="max-h-8/10 mt-4 h-full px-4 pb-4">{children}</div>
			</div>
		</Dialog>
	);
};

export default Modal;

import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import config from '../config';

export async function configureAWS() {
	AWS.config.region = config.aws_sdk.REGION;

	let creds = await Auth.currentCredentials();
	if (creds) {
		AWS.config.credentials = creds;
		return;
	}
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
	});
}

export async function addAWSCredentials() {
	try {
		let { session_token } = await Auth.currentCredentials();
		updateAuthenticatedUser('www.amazon.com', session_token);
	} catch (e) {
		return false;
	}
}

export function updateAuthenticatedUser(providerName, token) {
	AWS.config.credentials.params.Logins = AWS.config.credentials.params.Logins || {};
	AWS.config.credentials.params.Logins[providerName] = token;
	AWS.config.credentials.expired = true;
}

import React, { ClipboardEventHandler, FocusEventHandler } from 'react';
import { noop } from 'lodash';
import { SortableTableColumn } from '../../TypesafeSortableTable/TypesafeSortableTable.model';
import SortableTableRow from './SortableTableRow';

const SortableTableBody = <T extends unknown>({
	columns,
	contentEditable,
	handlePaste = noop,
	handleUpdateEditableContent,
	onFocus,
	tableData,
}: {
	columns: SortableTableColumn<T>[];
	contentEditable: boolean;
	handlePaste?: ClipboardEventHandler;
	handleUpdateEditableContent: FocusEventHandler;
	onFocus: FocusEventHandler;
	tableData: { [key: string]: unknown }[];
}) => {
	const dataHtml = [];
	if (tableData) {
		for (const [index, dataObj] of tableData.entries()) {
			// Generate the HTML for the Auctions Overview section
			// What's up the with key prop you ask? Good question! It's because of this shizz -- https://fb.me/react-warning-keys
			dataHtml.push(
				<SortableTableRow
					key={index}
					rowNumber={index}
					columns={columns}
					rowItem={dataObj as T}
					contentEditable={contentEditable}
					handlePaste={handlePaste}
					handleUpdateEditableContent={handleUpdateEditableContent}
					onFocus={onFocus}
				/>
			);
		}
	}
	if (dataHtml.length == 0)
		dataHtml.push(
			<tr key="loading">
				<td colSpan={columns.length}>
					<div className="widget-content font-italic mb-3 p-0">No data</div>
					<div />
				</td>
			</tr>
		);
	return dataHtml;
};

export default SortableTableBody;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const reallocateLotsToAuction = async ({
	newAuctionId,
	orderNumber,
}: {
	newAuctionId: string;
	orderNumber: string;
}) => {
	const response = await axiosAuth.post(
		`${config.api.reallocate_lots_from_invoice}/${orderNumber}/reallocate_lots`,
		{
			newAuctionId,
		}
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { noop } from 'lodash';
import LotImageThumbnail from '../LotImageThumbnail';
import Lightbox from 'react-image-lightbox';
import { useState } from 'react';
import useLotImagesReducer from '../useLotImagesReducer';
import { getFilenameForLegacyImage } from '../../../../AdminUtils/imageUtils.helpers';
import { getCloudFrontImageUrl } from '../../../../services/storageService';

const ReadOnlyImages = ({
	hasPersistedImageArray,
	imageIdentifiers = [],
	legacyPrimaryImageName = '',
	lotId,
	title,
}: {
	lotId: string;
	/** In legacy code, the \_\_PRIMARY\_\_ image was saved as a separate key. The refactored code relies on position
	 * in the imageIdentifiers array to determine which image is primary (ie, first image).
	 */
	legacyPrimaryImageName?: string;
	/** Legacy "identifiers" will be an array of URLs, whereas non-legacy will be an array of simple filenames.
	 * This is not to be mistaken with any kind of proper "id" and hence the non-abbreviated spelling.
	 */
	imageIdentifiers?: string[];
	title: string;
	hasPersistedImageArray?: boolean;
}) => {
	const incomingPersistedImageIdentifiers = hasPersistedImageArray
		? imageIdentifiers
		: [legacyPrimaryImageName, ...imageIdentifiers];
	const backwardCompatiblePersistedImageNames = incomingPersistedImageIdentifiers
		.filter((imageIdentifier) => !!imageIdentifier)
		.map((imageIdentifier: string) => getFilenameForLegacyImage({ imageIdentifier }));

	const [{ orderedImageNames }] = useLotImagesReducer({
		primaryImageName: backwardCompatiblePersistedImageNames.filter(
			(imageName) => !imageName.includes('LOA_')
		)[0],
		persistedImageNames: backwardCompatiblePersistedImageNames,
	});

	const lightboxImages = orderedImageNames.map((imageName: string) =>
		getCloudFrontImageUrl({
			parentPath: lotId,
			itemId: imageName,
			imageSize: '@4x',
			rootPath: 'Lots',
		})
	);

	const [shouldShowLightbox, setShouldShowLightbox] = useState(false);
	const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
	const openLightBox = (imageIndex: number) => {
		setCurrentLightboxIndex(imageIndex);
		setShouldShowLightbox(true);
	};

	return (
		<div>
			<h3 className="display-3 mb-4">{title}</h3>
			{orderedImageNames.length === 0 ? (
				<div className="italic text-body2 mt-4">No Images Found</div>
			) : (
				<ul className="grid list-none grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
					{orderedImageNames.map((imageName, index) => (
						<li key={`${lotId}-${imageName}-${index}}`}>
							<LotImageThumbnail
								lotId={lotId}
								id={imageName}
								imageName={imageName}
								handleDelete={noop}
								isStagedForDeletion={false}
								onClickImage={() => openLightBox(orderedImageNames.indexOf(imageName))}
								activeImageName={undefined}
							/>
						</li>
					))}
				</ul>
			)}
			{shouldShowLightbox && (
				/** @ts-ignore */
				<Lightbox
					mainSrc={lightboxImages[currentLightboxIndex]}
					nextSrc={lightboxImages[(currentLightboxIndex + 1) % lightboxImages.length]}
					prevSrc={
						lightboxImages[
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						]
					}
					onCloseRequest={() => setShouldShowLightbox(false)}
					onMovePrevRequest={() =>
						setCurrentLightboxIndex(
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						)
					}
					onMoveNextRequest={() =>
						setCurrentLightboxIndex((currentLightboxIndex + 1) % lightboxImages.length)
					}
				/>
			)}
		</div>
	);
};

export default ReadOnlyImages;

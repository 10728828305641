import apiEndpoints from '../../queries/apiEndpoints';
import { putAuthed } from '../../AdminUtils/httpUtils';
import { CheckoutOrder } from './ordersService.model';

export const updateOrder = async ({
	fieldsToUpdate,
	orderNumber,
}: {
	fieldsToUpdate: Partial<CheckoutOrder>;
	orderNumber: string;
}) => {
	const body = { orderNumber, ...fieldsToUpdate };

	const response = await putAuthed(`${apiEndpoints.checkout}/orders`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import React from 'react';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import useAuctions from '../../../../queries/useAuctions';
import BidsTabView from './BidsTabView';

const BidsTab = () => {
	const { status: auctionsStatus } = useAuctions();

	return (
		<div className="ml-4">
			<LoadingWrapper queryStatuses={[auctionsStatus]}>
				<BidsTabView />
			</LoadingWrapper>
		</div>
	);
};

export default BidsTab;

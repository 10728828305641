import { maxBy } from 'lodash';
import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import useFixedPriceItem from '../../../../../queries/useFixedPriceItem';
import useOffersForItem from '../../../../../queries/useOffersForItem';
import { FixedPriceItem } from '../../../../../services/lotsService/lotsService.model';
import { FixedPriceOffer } from '../../../../../services/offersService';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';

const ListingFinancials = () => {
	const { itemId = '' } = useParams<{ itemId: string }>();
	const { data: item = {} as FixedPriceItem } = useFixedPriceItem(itemId);

	const { data: offers = [] as FixedPriceOffer[] } = useOffersForItem(itemId);
	const highestOffer =
		maxBy(offers, ({ offerPrice }) => offerPrice || 0) || ({} as FixedPriceOffer);

	return (
		<Card className="self-start">
			<h2 className="text-display5 pb-2">Financial Details</h2>
			{item.status === 'Completed_Sold' && (
				<div className="py-4 border-b-light flex justify-between">
					<span>Final Price</span>
					<span className="text-right">
						{item?.finalPrice ? formatCurrency(item.finalPrice) : '-'}
					</span>
				</div>
			)}
			<div className="py-4 border-b-light flex justify-between">
				<span>Asking Price</span>
				<span className="text-right">
					{item?.currentPrice ? formatCurrency(Number(item.currentPrice)) : '-'}
				</span>
			</div>
			<div className="py-4 border-b-light flex justify-between">
				<span>Highest Offer</span>
				<span className="text-right">
					{highestOffer.offerPrice ? formatCurrency(highestOffer.offerPrice) : '-'}
				</span>
			</div>
			<div className="py-4 flex justify-between">
				<span>Estimated Value</span>
				<span className="text-right">
					{item?.estimatedValue ? formatCurrency(item.estimatedValue) : '-'}
				</span>
			</div>
		</Card>
	);
};

export default ListingFinancials;

import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const updateInvoiceTrackingId = async ({
	auction_id,
	invoice_id,
	tracking,
}: {
	auction_id: string;
	invoice_id: string;
	tracking: string;
}) => {
	try {
		const body = {
			auction_id,
			company_id: config.organization.company_id,
			invoice_id,
			updates: {
				tracking_id: tracking,
			},
		};

		const response = await postAuthed(`${config.api.update_bidder_invoice}`, body);

		if (response.status >= 400) {
			throw response;
		}

		return response.data.body;
	} catch (err) {
		console.log(`Unable to update invoice tracking id: ${err}`);

		throw err;
	}
};

export default updateInvoiceTrackingId;

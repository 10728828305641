import { isEqual, omit } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../components/ActionModal';
import InterestedPartyInput from '../../../../../../components/InterestedPartyInput';
import LoadingWrapper from '../../../../../../components/LoadingWrapper';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { Input } from '../../../../../../prizm-ui/Input';
import { Toggle } from '../../../../../../prizm-ui/Toggle';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import useEmployeeById from '../../../../../../queries/useEmployeeById';
import useUserById from '../../../../../../queries/useUserById';
import { updateCreditLimit, updateUser } from '../../../../../../services/userService';
import { BaseUser, User } from '../../../../../../services/userService/userService.model';
import { snakeObject } from '../../../../../../utils/formatters';
import { getDeltas } from '../../../../../../utils/formatters/getDeltas';

const EditGeneralInfoModal = ({ onClose }: { onClose: () => void }) => {
	const { userId } = useParams<{ userId: string }>();
	const {
		data: user = {} as User,
		status: userStatus,
		updateUser: updateUserInCache,
	} = useUserById(userId);

	const { data: consignmentManager = {} as User, status: consignmentManagerStatus } =
		useEmployeeById(user.consignmentManagerId);

	const [draftUser, setDraftUser] = useState(user);
	const updateDraftUser = (deltas: Partial<User>) => setDraftUser({ ...draftUser, ...deltas });

	const [selectedConsignmentManager, setSelectedConsignmentManager] = useState<
		BaseUser | undefined
	>(consignmentManager);
	const updateSelectedConsignmentManager = (newConsignmentManager?: BaseUser) => {
		setSelectedConsignmentManager(newConsignmentManager);
		updateDraftUser({ consignmentManagerId: newConsignmentManager?.id ?? '' });
	};

	const deltas = getDeltas<User>({ olderVersion: user, newerVersion: draftUser });
	const canConfirm = !isEqual(user, draftUser);

	const { mutate: doUpdateCreditLimit, status: updateCreditLimitStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateCreditLimit, userId, draftUser.creditLimit],
		mutationFn: () =>
			updateCreditLimit({
				userId,
				newCreditLimit: draftUser.creditLimit,
				oldCreditLimit: user.creditLimit,
				notes: user.notes,
				oldBiddingPower: user.biddingPower,
			}),
	});
	const { mutate: doUpdateUser, status: updateUserStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateUser, userId, draftUser],
		mutationFn: () => {
			const safeDeltas = omit(deltas, 'creditLimit');
			return updateUser({ userId, fieldsToUpdate: snakeObject(safeDeltas) });
		},
	});

	const handleConfirm = () => {
		/** Something on the user has changed, but the credit limit remains the same */
		if (isEqual(draftUser.creditLimit, user.creditLimit)) {
			return doUpdateUser(undefined, {
				onSuccess: () => {
					updateUserInCache(deltas);
					showSuccessToast('Successfully updated user.');
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong updating user. If the issue persists after refresh, please notify the #mvp-cs-channel'
					);
				},
			});
		}

		/** The credit limit has changed, but the rest of the user remains the same */
		if (
			!isEqual(draftUser.creditLimit, user.creditLimit) &&
			isEqual(omit(draftUser, 'creditLimit'), omit(user, 'creditLimit'))
		) {
			return doUpdateCreditLimit(undefined, {
				onSuccess: ({ bidding_power }) => {
					updateUserInCache({ ...deltas, biddingPower: bidding_power });
					showSuccessToast('Successfully updated credit limit.');
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong updating credit limit. If the issue persists after refresh, please notify the #mvp-cs-channel'
					);
				},
			});
		}

		/** The credit limit has changed and also something else on the user has changed */
		return doUpdateCreditLimit(undefined, {
			onSuccess: ({ bidding_power }) => {
				showSuccessToast('Successfully updated credit limit.');
				doUpdateUser(undefined, {
					onSuccess: () => {
						updateUserInCache({ ...deltas, biddingPower: bidding_power });
						showSuccessToast('Successfully updated other fields on the user.');
						onClose();
					},
					onError: () => {
						showErrorToast(
							'Something went wrong updating user. If the issue persists after refresh, please notify the #mvp-cs-channel'
						);
					},
				});
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating credit limit. If the issue persists after refresh, please notify the #mvp-cs-channel'
				);
			},
		});
	};

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleConfirm}
			canConfirm={canConfirm}
			title="Edit General Info"
			isLoading={updateCreditLimitStatus === 'loading' || updateUserStatus === 'loading'}
		>
			<LoadingWrapper queryStatuses={[userStatus, consignmentManagerStatus]}>
				<>
					<Input
						label="Credit Limit"
						value={draftUser.creditLimit}
						type="number"
						onChange={({ target: { value } }) => updateDraftUser({ creditLimit: Number(value) })}
						id="credit-limit"
					/>
					<div className="mt-8 mb-10">
						<InterestedPartyInput
							kind="employee"
							onChange={updateSelectedConsignmentManager}
							onClear={() => {
								updateSelectedConsignmentManager(undefined);
							}}
							selectedParty={selectedConsignmentManager as BaseUser}
						/>
					</div>
					<div className="pb-8">
						<div className="flex items-center justify-between text-body1">
							<span>Verified TIN</span>
							<Toggle
								checked={draftUser.verifiedTin}
								onChange={(newValue: boolean) => updateDraftUser({ verifiedTin: newValue })}
								aria-label={draftUser.verifiedTin ? 'Toggle False' : 'Toggle True'}
							/>
						</div>
						<div className="mt-8 flex items-center justify-between text-body1">
							<span>Send Payment Reminder Emails</span>
							<Toggle
								checked={draftUser.sendPaymentReminder}
								onChange={(newValue: boolean) => updateDraftUser({ sendPaymentReminder: newValue })}
								aria-label={draftUser.sendPaymentReminder ? 'Toggle Off' : 'Toggle On'}
							/>
						</div>
					</div>
				</>
			</LoadingWrapper>
		</ActionModal>
	);
};

export default EditGeneralInfoModal;

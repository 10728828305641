import React, { useState } from 'react';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import { invoiceDetailsColumnWidthClasses } from '../InvoiceDetailsTable.model';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { Menu, MenuItem } from '@material-ui/core';
import LotReassignmentCard from '../../LotReassignmentCard';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';

const InvoiceDetailsTableRow = ({
	lot,
	index,
	isLastRow,
}: {
	lot: Partial<KernelLot>;
	index: number;
	isLastRow: boolean;
}) => {
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const openMoreOptionsMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const closeMoreOptionsMenu = () => {
		setAnchorEl(null);
	};

	const shouldShowMoreOptionsMenu = Boolean(anchorEl);
	const [shouldShowLotReassignmentCard, setShouldShowLotReassignmentCard] = useState(false);

	const openLotReassignmentCard = () => {
		setAnchorEl(null);
		setShouldShowLotReassignmentCard(true);
	};

	const backgroundClass = index % 2 === 0 ? 'bg-gray-100' : 'bg-neutral1';
	const roundingClass = isLastRow ? 'rounded-b-md' : '';

	return (
		<>
			<div
				className={`grid grid-cols-12 gap-4 px-2 py-4 ${backgroundClass} hover:bg-blue-100 ${roundingClass}`}
			>
				<div className={`${invoiceDetailsColumnWidthClasses.lot_number} text-center`}>
					{lot.lot_number}
				</div>
				<div className={`${invoiceDetailsColumnWidthClasses.serial_number}`}>
					{lot.serial_number}
				</div>
				<div className={`${invoiceDetailsColumnWidthClasses.title}`}>{lot.title}</div>
				<div className={`${invoiceDetailsColumnWidthClasses.location}`}>{lot.location}</div>
				<div className={`${invoiceDetailsColumnWidthClasses.current_price} text-right`}>
					{formatCurrency(lot.current_price)}
				</div>
				<button className="col-span-1" onClick={openMoreOptionsMenu}>
					<MoreHorizontal20Regular />
				</button>
				{shouldShowMoreOptionsMenu && (
					<Menu
						id={`${lot.lot_number}-${lot.serial_number}-more-options`}
						open={shouldShowMoreOptionsMenu}
						onClose={closeMoreOptionsMenu}
						anchorEl={anchorEl}
					>
						<MenuItem onClick={openLotReassignmentCard}>Reassign to another auction</MenuItem>
					</Menu>
				)}
			</div>
			{shouldShowLotReassignmentCard && (
				<LotReassignmentCard
					lot={lot}
					open={shouldShowLotReassignmentCard}
					onClose={() => setShouldShowLotReassignmentCard(false)}
				/>
			)}
		</>
	);
};

export default InvoiceDetailsTableRow;

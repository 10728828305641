import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const sendDraftInvoices = async (auction_id: string) => {
	try {
		const body = {
			auction_id,
		};

		const response = await postAuthed(`${config.api.send_draft_invoices}`, body);

		if (response.status >= 400) {
			throw response;
		}

		return response.data.body;
	} catch (err) {
		console.log(`Unable to send out draft invoices: ${err}`);

		throw err;
	}
};

export default sendDraftInvoices;

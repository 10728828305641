import { formatDate } from '../../../../../../utils/formatters';
import { KernelLot } from '../../../../../../services/lotsService/lotsService.model';
import {
	CheckoutOrder,
	FixedPriceOrder,
	PaymentMethod,
} from '../../../../../../services/ordersService/ordersService.model';

export interface FeeStructure {
	shipping: number;
	handling: number;
	insurance: number;
}

export const FIXED_PRICE_ORDER_CSV_COLUMNS = [
	{
		label: 'Invoice Date',
		value: (row: FixedPriceOrder) => formatDate(row.createdAt),
	},
	{
		label: 'Invoice Number',
		value: 'invoiceNumber',
	},
	{
		label: 'Winner Name',
		value: 'name',
	},
	{
		label: 'Email',
		value: 'email',
	},
	{
		label: 'Total Amount',
		value: 'costMetadata.lotsTotal',
	},
	{
		label: 'Net Adjustments',
		value: 'costMetadata.adjustmentsTotal',
	},
	{
		label: 'Total Amount Due',
		value: 'totalAmount',
	},
	{
		label: 'Status',
		value: 'invoiceStatus',
	},
];

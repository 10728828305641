import { useQuery } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { QueryKeys } from '../queryKeys';
import { getLotBidHistory } from '../../services/lotsService';

const useLotBidHistory = ({ auctionId, lotId }: { auctionId: string; lotId: string }) =>
	useQuery(
		[QueryKeys.GetLotBidHistory, auctionId, lotId],
		() => getLotBidHistory({ auctionId, lotId }),
		{
			enabled: !!auctionId && !!lotId,
			onError: () =>
				showErrorToast(
					'Something went wrong while retrieving the bid history. Please refresh and try again.'
				),
		}
	);

export default useLotBidHistory;

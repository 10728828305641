import React from 'react';

export type LoadingSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface LoadingProps {
	size: LoadingSize;
}

type SVGProps = React.ComponentPropsWithoutRef<'svg'>;

const LoadingXS = (props: SVGProps): JSX.Element => (
	<svg viewBox="0 0 8 8" className="w-2 h-2 animate-spin" {...props}>
		<path d="M4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7C4.27614 7 4.5 7.22386 4.5 7.5C4.5 7.77614 4.27614 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4C8 4.27614 7.77614 4.5 7.5 4.5C7.22386 4.5 7 4.27614 7 4C7 2.34315 5.65685 1 4 1Z" />
	</svg>
);
const LoadingSM = (props: SVGProps): JSX.Element => (
	<svg viewBox="0 0 16 16" className="w-4 h-4 animate-spin" {...props}>
		<path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 8.55228 15.5523 9 15 9C14.4477 9 14 8.55228 14 8C14 4.68629 11.3137 2 8 2Z" />
	</svg>
);
const LoadingMD = (props: SVGProps): JSX.Element => (
	<svg viewBox="0 0 20 20" className="w-5 h-5 animate-spin" {...props}>
		<path d="M10 2C5.58172 2 2 5.58172 2 10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C9.44771 20 9 19.5523 9 19C9 18.4477 9.44771 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2Z" />
	</svg>
);
const LoadingLG = (props: SVGProps): JSX.Element => (
	<svg viewBox="0 0 48 48" className="w-12 h-12 animate-spin" {...props}>
		<path d="M24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46C24.5523 46 25 46.4477 25 47C25 47.5523 24.5523 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 24.5523 47.5523 25 47 25C46.4477 25 46 24.5523 46 24C46 11.8497 36.1503 2 24 2Z" />
	</svg>
);
const LoadingXL = (props: SVGProps): JSX.Element => (
	<svg viewBox="0 0 64 64" className="w-16 h-16 animate-spin" {...props}>
		<path d="M32 3.5C16.2599 3.5 3.5 16.2599 3.5 32C3.5 47.7401 16.2599 60.5 32 60.5C32.8284 60.5 33.5 61.1716 33.5 62C33.5 62.8284 32.8284 63.5 32 63.5C14.603 63.5 0.5 49.397 0.5 32C0.5 14.603 14.603 0.5 32 0.5C49.397 0.5 63.5 14.603 63.5 32C63.5 32.8284 62.8284 33.5 62 33.5C61.1716 33.5 60.5 32.8284 60.5 32C60.5 16.2599 47.7401 3.5 32 3.5Z" />
	</svg>
);

export const Loading = ({ size }: LoadingProps) => {
	const loadingSvgs: Record<LoadingSize, (props: SVGProps) => JSX.Element> = {
		xs: LoadingXS,
		sm: LoadingSM,
		md: LoadingMD,
		lg: LoadingLG,
		xl: LoadingXL,
	};
	const LoadingSvg = loadingSvgs[size];

	return <LoadingSvg role="img" aria-label="loading" fill="currentColor" />;
};

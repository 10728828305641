import React from 'react';
import formatCurrency from '../../../../../../../../utils/formatters/formatCurrency';

const RecordRefundModalLineItem = ({
	amount,
	bold = false,
	label,
}: {
	amount: number;
	bold?: boolean;
	label: string;
}) => {
	return (
		<div className={`mt-2 flex flex-row justify-between text-body1 ${bold ? 'font-bold' : ''}`}>
			<p className="capitalize">{label}</p>
			<p className={`${amount < 0 ? 'text-error2' : ''}`}>{`${formatCurrency(amount)}`}</p>
		</div>
	);
};

export default RecordRefundModalLineItem;

import { createContext, ReactNode } from 'react';
import { Icon, IconName } from '../Icon';

export type AlertVariant = 'primary' | 'neutral' | 'success' | 'warning' | 'error';

type CloseProps =
	| {
			onClose: () => void;
			closeLabel?: string;
	  }
	| {
			onClose?: undefined;
			closeLabel?: never;
	  };

export type AlertProps = {
	description?: string;
	footer?: ReactNode;
	title?: string;
	variant?: AlertVariant;
} & CloseProps;

export const AlertContext = createContext<{
	variant: AlertVariant;
}>({
	variant: 'neutral',
});

export const Alert = ({
	closeLabel = 'Close',
	description,
	footer,
	onClose,
	title,
	variant = 'neutral',
}: AlertProps) => {
	const alertColors: Record<AlertVariant, string[]> = {
		primary: ['border-primary-100', 'bg-primary-25', 'text-primary-500'],
		neutral: ['border-neutralstroke2', 'bg-neutralbkg2', 'text-neutral2'],
		success: ['border-success-100', 'bg-success-25', 'text-success-700'],
		warning: ['border-warning-100', 'bg-warning-25', 'text-warning-700'],
		error: ['border-error-100', 'bg-error-25', 'text-error-500'],
	};
	const titleColors: Record<AlertVariant, string> = {
		primary: 'text-primary-700',
		neutral: 'text-neutral1',
		success: 'text-success-700',
		warning: 'text-warning-700',
		error: 'text-error-700',
	};
	const iconNames: Record<AlertVariant, IconName> = {
		primary: 'Sparkle',
		neutral: 'Info',
		success: 'CheckmarkCircle',
		warning: 'Warning',
		error: 'ErrorCircle',
	};
	const buttonColor: Record<AlertVariant, string[]> = {
		primary: ['hover:text-primary-900', 'focus-visible:text-primary-900'],
		neutral: ['hover:text-neutral1', 'focus-visible:text-neutral1'],
		success: ['hover:text-success-900', 'focus-visible:text-success-900'],
		warning: ['hover:text-warning-900', 'focus-visible:text-warning-900'],
		error: ['hover:text-error-900', 'focus-visible:text-error-900'],
	};
	return (
		<AlertContext.Provider value={{ variant }}>
			<div
				role="alert"
				className={[
					'flex',
					'items-start',
					'gap-3',
					'px-4',
					'py-3',
					'border',
					'rounded-lg',
					...alertColors[variant],
				].join(' ')}
			>
				<div className="shrink-0 flex w-5 h-5">
					<Icon name={iconNames[variant]} size="md" aria-hidden />
				</div>
				<div className="grow flex flex-col items-start gap-0.5">
					{title && (
						<span className={[titleColors[variant], 'text-body2', 'font-semibold'].join(' ')}>
							{title}
						</span>
					)}
					{description && <span className="text-body2">{description}</span>}
					{footer}
				</div>
				{onClose && (
					<button
						type="button"
						className={[
							'shrink-0',
							'flex',
							'w-5',
							'h-5',
							'transition-colors',
							...buttonColor[variant],
						].join(' ')}
						onClick={onClose}
						aria-label={closeLabel}
					>
						<Icon name="Dismiss" size="md" aria-hidden />
					</button>
				)}
			</div>
		</AlertContext.Provider>
	);
};

import {
	DraftRateCardRates,
	KernelRateCard,
	KernelRateCardRate,
	RateCardRates,
	RateCardVariant,
	TeeShirtRateCard,
} from '../../../../../services/rateCardService/rateCardService.model';

export const kernelRateCardToTeeShirtRateCard = ({
	kernelRateCard,
	variant,
}: {
	kernelRateCard?: KernelRateCard;
	variant: RateCardVariant;
}): TeeShirtRateCard => {
	const hasRateCardRates =
		kernelRateCard && !!kernelRateCard.rates && kernelRateCard.rates.length > 0;

	if (!hasRateCardRates) {
		return {
			variant,
			rates: {
				xs: undefined,
				sm: undefined,
				md: undefined,
				lg: undefined,
				xl: undefined,
			},
			isEmpty: true,
		};
	}

	const hasNullRates = kernelRateCard.rates
		.map(({ commission }) => commission)
		.every((commission) => !commission && commission !== 0);

	if (hasNullRates) {
		return {
			variant,
			rates: {
				xs: undefined,
				sm: undefined,
				md: undefined,
				lg: undefined,
				xl: undefined,
			},
			isNull: true,
		};
	}

	const rawXs = kernelRateCard.rates.find(({ priceMin }) => priceMin === 0)?.commission;
	const rawSm = kernelRateCard.rates.find(({ priceMin }) => priceMin === 2500)?.commission;
	const rawMd = kernelRateCard.rates.find(({ priceMin }) => priceMin === 5000)?.commission;
	const rawLg = kernelRateCard.rates.find(({ priceMin }) => priceMin === 10000)?.commission;
	const rawXl = kernelRateCard.rates.find(({ priceMin }) => priceMin === 250000)?.commission;

	return {
		variant,
		rates: {
			xs: rawXs,
			sm: typeof rawSm === 'undefined' ? rawXs : rawSm,
			md: typeof rawMd === 'undefined' ? rawSm : rawMd,
			lg: typeof rawLg === 'undefined' ? rawMd : rawLg,
			xl: typeof rawXl === 'undefined' ? rawLg : rawXl,
		},
	};
};

export const teeShirtRatesToKernelRates = (teeShirtRates: RateCardRates): KernelRateCardRate[] => [
	{
		priceMin: 0,
		priceMax: 2499,
		commission: teeShirtRates.xs ?? undefined,
		type: 'Default',
	},
	{
		priceMin: 2500,
		priceMax: 4999,
		commission: teeShirtRates.sm ?? undefined,
		type: 'Default',
	},
	{
		priceMin: 5000,
		priceMax: 9999,
		commission: teeShirtRates.md ?? undefined,
		type: 'Default',
	},
	{
		priceMin: 10000,
		priceMax: 249999,
		commission: teeShirtRates.lg ?? undefined,
		type: 'Default',
	},
	{ priceMin: 250000, commission: teeShirtRates.xl ?? undefined, type: 'Default' },
];

export const teeShirtRateCardToKernelRateCard = (
	teeShirtRateCard: TeeShirtRateCard
): KernelRateCard => ({
	auctionType: teeShirtRateCard.variant === 'auction' ? 'auction' : 'fixed_price_marketplace',
	itemType: 'all',
	rates: teeShirtRatesToKernelRates(teeShirtRateCard.rates),
});

export const rateCardRatesToDraftRateCardRates = ({
	rates = {
		xs: undefined,
		sm: undefined,
		md: undefined,
		lg: undefined,
		xl: undefined,
	},
}: {
	rates?: RateCardRates;
}): DraftRateCardRates => ({
	xs: typeof rates.xs === 'undefined' ? undefined : rates.xs.toString(),
	sm: typeof rates.sm === 'undefined' ? undefined : rates.sm.toString(),
	md: typeof rates.md === 'undefined' ? undefined : rates.md.toString(),
	lg: typeof rates.lg === 'undefined' ? undefined : rates.lg.toString(),
	xl: typeof rates.xl === 'undefined' ? undefined : rates.xl.toString(),
});
export const draftRateCardRatesToRateCardRates = (
	draftRates: DraftRateCardRates
): RateCardRates => ({
	xs: typeof draftRates.xs === 'undefined' ? undefined : parseFloat(draftRates.xs),
	sm: typeof draftRates.sm === 'undefined' ? undefined : parseFloat(draftRates.sm),
	md: typeof draftRates.md === 'undefined' ? undefined : parseFloat(draftRates.md),
	lg: typeof draftRates.lg === 'undefined' ? undefined : parseFloat(draftRates.lg),
	xl: typeof draftRates.xl === 'undefined' ? undefined : parseFloat(draftRates.xl),
});

import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const updateInvoicePaymentReminderStatus = async ({
	auction_id,
	invoice_id,
	disable_payment_reminders,
}: {
	auction_id: string;
	invoice_id: string;
	disable_payment_reminders: boolean;
}) => {
	try {
		const body = {
			auction_id,
			company_id: config.organization.company_id,
			invoice_id,
			updates: {
				disable_payment_reminders,
			},
		};

		const response = await postAuthed(`${config.api.update_bidder_invoice}`, body);

		if (response.status >= 400) {
			throw response;
		}

		return response.data.body;
	} catch (err) {
		console.log(`Unable to update invoice payment reminder status: ${err}`);

		throw err;
	}
};

import { snakeCase } from 'lodash';

/** For those moments when you just need that snake_cased kernelly goodness */
export const snakeObject = <
	T_IN extends { [key: string]: unknown },
	T_OUT extends { [key: string]: unknown }
>(
	incomingObject: T_IN
): T_OUT => {
	const outgoingObject = Object.keys(incomingObject).reduce((accumulator, currentKey) => {
		let currentValue: unknown = incomingObject[currentKey];
		if (typeof currentValue === 'object') {
			currentValue = snakeObject(currentValue as { [key: string]: unknown });
		}

		if (typeof currentValue === 'undefined' || currentValue === null) {
			return accumulator;
		}

		const updatedAccumulator = {
			...accumulator,
			[snakeCase(currentKey)]: currentValue,
		};
		return updatedAccumulator;
	}, {});

	return outgoingObject as T_OUT;
};

import { axiosUnauthed } from '../../AdminUtils/httpUtils';
import { getUsersBulkUploadUrl } from './getUsersBulkUploadUrl';

export const bulkUploadUsers = async ({ employeeId, file }: { employeeId: string; file: File }) => {
	const usersBulkUploadUrl = await getUsersBulkUploadUrl();

	const baseHeaders = {
		'Content-Type': file.type,
	};

	const headers = {
		...baseHeaders,
		'x-amz-meta-user-id': employeeId,
	};

	const response = await axiosUnauthed.put(usersBulkUploadUrl, file, { headers });

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

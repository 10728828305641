import React, { FC } from 'react';
import CurrencyValue from '../CurrencyValue';
import LineItemLayout from '../LineItemLayout';

const VanillaLineItem: FC<{
	kind?: 'primary' | 'secondary';
	amount?: number;
}> = ({ kind = 'secondary', children, amount = 0 }) => {
	return (
		<LineItemLayout kind={kind} label={children}>
			<CurrencyValue amount={amount} className="mr-8" />
		</LineItemLayout>
	);
};

export default VanillaLineItem;

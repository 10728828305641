/**
 * getInvoiceColumnsByQueryType should filter the list of all possible columns down to only the columns
 * specified by the query type in INVOICE_COLUMNS_BY_QUERY_TYPE. Since the table can work for either
 * invoices across an auction or for a single user, we specify the mode as a parameter.
 *
 * If the mode is 'auction', we will exclude the auction_id column. If the mode is 'user', we will
 * exclude the customer_name and customer_email columns.
 */

import { ColumnDef } from '@tanstack/react-table';
import {
	InvoicesQueryType,
	KernelInvoice,
} from '../../services/invoicesService/invoicesService.model';
import { InvoiceColumn, INVOICE_COLUMNS_BY_QUERY_TYPE } from './InvoicesTable.model';

export const getInvoiceColumnsByQueryType = ({
	mode,
	queryType,
	columns,
}: {
	mode: 'auction' | 'user';
	queryType: InvoicesQueryType;
	columns: ColumnDef<KernelInvoice>[];
}): ColumnDef<KernelInvoice>[] => {
	const modeSpecificColumns = columns.filter((column) => {
		if (mode === 'auction' && column.id === 'auction_id') {
			return false;
		}

		if (mode === 'user' && (column.id === 'customer_name' || column.id === 'customer_email')) {
			return false;
		}
		return true;
	});

	/**
	 * INVOICE_COLUMNS_BY_QUERY_TYPE has a list of allowed columns for each query type. We will filter
	 * the mode-specific columns down to only the columns that are allowed for the query type.
	 */
	const columnsToInclude = modeSpecificColumns.filter((column) => {
		const allowedColumns = INVOICE_COLUMNS_BY_QUERY_TYPE[queryType];
		return allowedColumns.includes(column.id as InvoiceColumn);
	});

	return columnsToInclude;
};

import React, { Component } from 'react';

import {
	Carousel as ReactstrapCarousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption,
} from 'reactstrap';

export default class Carousel extends Component {
	constructor(props) {
		super(props);

		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
		this.state = {
			activeIndex: 0,
			items: props.items || [],
			interval: props.interval || 5000,
		};
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;

		const slides = this.state.items.map((item) => {
			return (
				<CarouselItem
					className="custom-carousel-item"
					tag="div"
					key={`${item.img}-${item.title}`}
					onExiting={this.onExiting}
					onExited={this.onExited}
				>
					{item.img ? (
						<div className="w-inherit h-inherit">
							<span className="center-image"></span>
							<img src={item.img} alt={item.title} className="w-inherit" />
						</div>
					) : (
						''
					)}
					{item.description && item.title ? (
						<CarouselCaption
							className={
								(this.props.captionStyle
									? this.props.captionStyle
									: 'text-drop-shadow text-white') +
								(this.props.captionAlignment === 'top' ? ' carousel-caption-top' : '')
							}
							captionText={item.description}
							captionHeader={item.title}
						/>
					) : (
						''
					)}
				</CarouselItem>
			);
		});

		return (
			<div>
				<ReactstrapCarousel
					activeIndex={activeIndex}
					next={this.next}
					previous={this.previous}
					interval={this.state.interval}
					autoPlay={true}
					ride="carousel"
				>
					<CarouselIndicators
						items={this.state.items}
						activeIndex={activeIndex}
						onClickHandler={this.goToIndex}
					/>
					{slides}
					<CarouselControl
						className="carousel-control"
						direction="prev"
						directionText="Previous"
						onClickHandler={this.previous}
					/>
					<CarouselControl
						className="carousel-control"
						direction="next"
						directionText="Next"
						onClickHandler={this.next}
					/>
				</ReactstrapCarousel>
			</div>
		);
	}
}

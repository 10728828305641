import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card';
import { Button } from '../../../prizm-ui/Button';
import BulkUploadPayoutsModal from './BulkUploadsModals/BulkUploadPayoutsModal';
import BulkUploadInvoicePaymentsStatusModal from './BulkUploadsModals/BulkUploadInvoicePaymentsStatusModal';
import BulkUploadAdjustmentsModal from './BulkUploadsModals/BulkUploadAdjustmentsModal';
import BulkUploadStatementStatusModal from './BulkUploadsModals/BulkUploadStatementStatusModal';
import { UserRoleName, UserRoles } from '../../../services/userService/userService.model';
import useUserRole from '../../../queries/useUserRole';
import { useHistory } from 'react-router-dom';
import {
	AdminPageEnum,
	PERMITTED_ROLES_PER_PAGE,
} from '../../../components/Layout/SideBar/SideBar.model';

const BulkUploadsPage = () => {
	const [shouldShowBulkPayoutsModal, setShouldShowBulkPayoutsModal] = useState(false);
	const [shouldShowBulkInvoicePaymentsStatusModal, setShouldShowBulkInvoicePaymentsStatusModal] =
		useState(false);
	const [shouldShowBulkInvoiceAdjustmentsModal, setShouldShowBulkInvoiceAdjustmentsModal] =
		useState(false);
	const [shouldShowBulkStatementStatusModal, setShouldShowBulkStatementStatusModal] =
		useState(false);
	const { data: userRoleName = 'Unknown' as UserRoleName } = useUserRole();

	const history = useHistory();

	useEffect(() => {
		const bulkUploadsPermissions = [
			...PERMITTED_ROLES_PER_PAGE[AdminPageEnum.BulkUploads],
			UserRoles.Admin,
		];

		if (!bulkUploadsPermissions.includes(userRoleName)) {
			history.push('/');
		}
	}, [userRoleName]);

	return (
		<>
			<Card className="mb-4">
				<h1 className="text-display4">Bulk Uploads</h1>
				<div className="my-12 flex flex-col lg:flex-row gap-4">
					<Button
						size="md"
						variant="secondary"
						trailingIcon="Send"
						onClick={() => setShouldShowBulkPayoutsModal(true)}
					>
						Stripe Payouts
					</Button>

					<Button
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBulkInvoicePaymentsStatusModal(true)}
					>
						Invoice Payments / Status
					</Button>

					<Button
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBulkInvoiceAdjustmentsModal(true)}
					>
						Invoice Adjustments
					</Button>
					<Button
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBulkStatementStatusModal(true)}
					>
						Statement Status
					</Button>
				</div>
			</Card>
			{shouldShowBulkPayoutsModal && (
				<BulkUploadPayoutsModal onClose={() => setShouldShowBulkPayoutsModal(false)} />
			)}
			{shouldShowBulkInvoicePaymentsStatusModal && (
				<BulkUploadInvoicePaymentsStatusModal
					onClose={() => setShouldShowBulkInvoicePaymentsStatusModal(false)}
				/>
			)}
			{shouldShowBulkInvoiceAdjustmentsModal && (
				<BulkUploadAdjustmentsModal
					onClose={() => setShouldShowBulkInvoiceAdjustmentsModal(false)}
				/>
			)}
			{shouldShowBulkStatementStatusModal && (
				<BulkUploadStatementStatusModal
					onClose={() => setShouldShowBulkStatementStatusModal(false)}
				/>
			)}
		</>
	);
};

export default BulkUploadsPage;

import { Edit20Regular } from '@fluentui/react-icons';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import { KernelSummaryLot } from '../../../../../services/lotsService/lotsService.model';
import { getPrimaryImageUrlForImageSize, getSrcSetDeclarations } from './LotPreviewCard.utils';
import { Link } from 'react-router-dom';
import ErrorBoundary from '../../../../../components/ErrorBoundary';

const LotPreviewCard = ({ lot }: { lot: KernelSummaryLot }) => {
	return (
		<div className="border-base shadow-sm rounded-lg bg-neutral1 flex flex-col max-w-xs">
			<div className="border-b-base flex w-full justify-end rounded-t-lg p-2">
				<Link
					className="block link"
					to={`/inventory/listings/${lot.lot_id}/manage`}
					target="_blank"
					rel="noreferrer"
				>
					<Edit20Regular />
				</Link>
			</div>
			<div className="bg-neutral2 px-2 py-4 h-48 xl:h-72">
				<ErrorBoundary>
					<a
						className="cursor-pointer hover:text-current hover:no-underline w-full h-full flex flex-grow items-center justify-center"
						href={`${process.env.BIDDER_SITE_URL!}/item/${lot.meta_slug}`}
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="max-h-full"
							srcSet={getSrcSetDeclarations({
								imageName: lot.primary_image_name ?? '',
								lotId: lot.lot_id,
							})}
							src={getPrimaryImageUrlForImageSize({
								primaryImageName: lot.primary_image_name ?? '',
								lotId: lot.lot_id,
								imageSize: '@3x',
							})}
						/>
					</a>
				</ErrorBoundary>
			</div>
			<div className="border-t-base p-4 flex flex-grow">
				<div className="flex flex-col justify-between flex-grow">
					<div className="mb-4">
						<div className="metadata">Lot {lot.lot_number}</div>
						<div className="mt-2">{lot.title}</div>
					</div>
					<div>
						{lot.min_bid_price && !lot.current_price && (
							<div className="flex justify-between">
								<div>Minimum Bid: </div>
								<div className="font-semibold">{formatCurrency(lot.min_bid_price, 'Unknown')}</div>
							</div>
						)}
						{lot.current_price ? (
							<div className="flex justify-between">
								<div>Current Price: </div>
								<div className="font-semibold">{formatCurrency(lot.current_price, 'Unknown')}</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LotPreviewCard;

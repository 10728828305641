import LoadingWrapper from '../../../LoadingWrapper/LoadingWrapper';
import LotAuctionHistoryContent from './LotAuctionHistoryContent/LotAuctionHistoryContent';
import { Modal, ModalContent, ModalHeader } from '../../../../prizm-ui/Modal';
import LotInvoiceHistoryContent from './LotInvoiceHistoryContent/LotInvoiceHistoryContent';
import LotSellerStatementHistoryContent from './LotSellerStatementHistoryContent/LotSellerStatementHistoryContent';
import {
	KernelLot,
	KernelLotHistory,
	KernelLotHistoryAuctionEntry,
	KernelLotHistoryInvoiceEntry,
	KernelLotHistorySellerStatementEntry,
} from '../../../../services/lotsService/lotsService.model';
import useIndividualLot from '../../../../queries/useIndividualLot/useIndividualLot';
import useLotHistory from '../../../../queries/useLotHistory/useLotHistory';

const LotHistoryModal = ({ lotId, onClose }: { lotId: string; onClose: () => void }) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);
	const { data: lotHistory = [] as KernelLotHistory } = useLotHistory(lot.lot_id);

	const auctionHistoryEntries = lotHistory.filter(
		({ entity }) => entity === 'Auction'
	) as KernelLotHistoryAuctionEntry[];

	const invoiceHistoryEntries = lotHistory.filter(
		({ entity }) => entity === 'Invoice'
	) as KernelLotHistoryInvoiceEntry[];

	const sellerStatementHistoryEntries = lotHistory.filter(
		({ entity }) => entity === 'SellerStatement'
	) as KernelLotHistorySellerStatementEntry[];

	return (
		<Modal isOpen={true}>
			<ModalHeader onClose={onClose}>{`Item history for "${lot.title}"`}</ModalHeader>
			<ModalContent>
				<LoadingWrapper queryStatuses={[lotStatus]}>
					<LotAuctionHistoryContent auctionHistoryEntries={auctionHistoryEntries} />
					<LotInvoiceHistoryContent invoiceHistoryEntries={invoiceHistoryEntries} />
					<LotSellerStatementHistoryContent
						sellerStatementHistoryEntries={sellerStatementHistoryEntries}
					/>
				</LoadingWrapper>
			</ModalContent>
		</Modal>
	);
};

export default LotHistoryModal;

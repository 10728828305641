import { getAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { Auction, KernelAuction } from './auctionsService.model';

const getAuctions = async (): Promise<Auction[]> => {
	const response = await getAuthed(apiEndpoints.auctions);

	if (response.status >= 400) {
		throw response;
	}

	const parsedResults = JSON.parse(response.data) as KernelAuction[];

	const mappedResults: Auction[] = parsedResults
		.filter((kernelAuction) => kernelAuction.auction_id && kernelAuction.title)
		.map((kernelAuction) => ({
			auctionType: kernelAuction.auction_type,
			averageBidValue: kernelAuction.average_bid_value,
			bidsPerLot: kernelAuction.bids_per_lot,
			buyerPremium: kernelAuction.buyer_premium,
			commission: kernelAuction.commission,
			companyId: kernelAuction.company_id,
			createdAt: kernelAuction.created_at,
			createdBy: kernelAuction.crated_by,
			description: kernelAuction.description ?? '',
			endTimestamp: kernelAuction.end_timestamp,
			extendedBiddingDuration: kernelAuction.extended_bidding_duration,
			gmv: kernelAuction.gmv,
			hashtags: kernelAuction.tags ?? [],
			id: kernelAuction.auction_id,
			increment: kernelAuction.increment,
			internalNotes: kernelAuction.internal_notes,
			modifiedAt: kernelAuction.modified_at,
			modifiedBy: kernelAuction.modified_by,
			numberOfImages: kernelAuction.number_of_images,
			previewTimestamp: kernelAuction.preview_timestamp,
			primaryImageName: kernelAuction.primary_image_name,
			sellthroughRate: kernelAuction.sellthrough_rate,
			startTimestamp: kernelAuction.start_timestamp,
			status: kernelAuction.status ?? 'Not_Started',
			title: kernelAuction.title,
			totalBidValue: kernelAuction.total_bid_value,
			totalBids: kernelAuction.total_bids,
			totalBuyerPremium: kernelAuction.total_buyer_premium,
			totalCommission: kernelAuction.total_commission,
			totalLots: kernelAuction.total_lots,
			totalViews: kernelAuction.total_views,
			type: kernelAuction.auction_type ?? 'Unset',
			uniqueBidders: kernelAuction.unique_bidders,
			uniqueBiddersPerLot: kernelAuction.unique_bidders_per_lot,
			uniqueSellers: kernelAuction.unique_sellers,
			unsoldLots: kernelAuction.unsold_lots,
		}));
	return mappedResults;
};

export default getAuctions;

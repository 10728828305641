import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { QueryStatus } from 'react-query';

const LoadingWrapper: FC<{ queryStatuses: QueryStatus[] }> = ({ queryStatuses, children }) => {
	if (queryStatuses.includes('loading')) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<CircularProgress color="inherit" />
			</div>
		);
	}

	if (queryStatuses.every((status) => ['success', 'idle'].includes(status))) {
		return <>{children}</>;
	}

	return <></>;
};

export default LoadingWrapper;

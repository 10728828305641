import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const reassignLotToAuction = async ({
	lotId,
	destinationAuctionId,
}: {
	lotId: string;
	destinationAuctionId: string;
}) => {
	const response = await axiosAuth.patch(`${config.api.reallocate_lot}`, {
		lot_id: lotId,
		new_auction_id: destinationAuctionId,
		operation_type: 'NEW_AUCTION',
	});

	if (response.status >= 400 || response.data.statusCode !== 200) {
		throw response;
	}

	return response;
};

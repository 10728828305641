import { isEqual, snakeCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ConfirmationButtons from '../../../../components/ConfirmationButtons';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { Input } from '../../../../prizm-ui/Input';
import { Textarea } from '../../../../prizm-ui/Textarea';
import { QueryKeys } from '../../../../queries/queryKeys';
import { Auction, KernelAuction, updateAuction } from '../../../../services/auctionsService';

const EditAuctionFields = ({ auction }: { auction: Auction }) => {
	const [draftAuction, setDraftAuction] = useState(auction);
	const updateDraftAuction = (updates: Partial<Auction>) =>
		setDraftAuction({ ...draftAuction, ...updates });

	useEffect(() => {
		if (!isEqual(auction, draftAuction)) {
			setDraftAuction(auction);
		}
	}, [auction]);

	const onCancelChanges = () => setDraftAuction(auction);

	const changedFields: Partial<KernelAuction> = Object.keys(draftAuction).reduce<
		Partial<KernelAuction>
	>((accumulator: Partial<KernelAuction>, currentKey: string) => {
		const typesafeCurrentKey = currentKey as keyof Auction;
		if (!isEqual(draftAuction[typesafeCurrentKey], auction[typesafeCurrentKey])) {
			const kernelAuctionKey = currentKey === 'type' ? 'auction_type' : snakeCase(currentKey);
			return {
				...accumulator,
				[kernelAuctionKey]: draftAuction[typesafeCurrentKey],
			};
		}
		return accumulator;
	}, {} as Partial<KernelAuction>);

	const queryClient = useQueryClient();

	const { mutate: doUpdateAuction, status: updateAuctionStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateAuction, draftAuction],
		mutationFn: () => updateAuction(auction.id, changedFields),
	});

	const onSaveChanges = () =>
		doUpdateAuction(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully updated auction fields');
				queryClient.invalidateQueries([QueryKeys.GetAuctions]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating auction fields. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	const hasChanges = !isEqual(auction, draftAuction);

	return (
		<div>
			<div className="my-4">
				<Input
					id="title"
					label="Title"
					value={draftAuction.title}
					onChange={({ target: { value } }) => updateDraftAuction({ title: value })}
				/>
			</div>
			<Textarea
				value={draftAuction.description}
				id="description"
				onChange={({ target: { value } }) => updateDraftAuction({ description: value })}
				label="Description"
			/>
			<div className="mt-4">
				<Textarea
					value={draftAuction.internalNotes}
					id="internal-notes"
					onChange={({ target: { value } }) => updateDraftAuction({ internalNotes: value })}
					label="Internal Notes"
				/>
			</div>
			<div className="mt-4">
				<ConfirmationButtons
					onCancel={onCancelChanges}
					onConfirm={onSaveChanges}
					confirmButtonLabel="Save Changes"
					loading={updateAuctionStatus === 'loading'}
					disabled={!hasChanges}
				/>
			</div>
		</div>
	);
};

export default EditAuctionFields;

import { noop } from 'lodash';
import { Dispatch } from 'react';
import { Banner } from '../../../../../../services/layoutService/layoutService.model';

export interface ManageBannersState {
	newBanner: Banner;
	sortedBanners: Banner[];
	originalBanners: Banner[];
	bannersToDelete: Banner[];
}

export const emptyBanner: Banner = {
	id: '',
	title: '',
	url: '',
};

export const manageBannersDefaultState: ManageBannersState = {
	newBanner: emptyBanner,
	sortedBanners: [],
	originalBanners: [],
	bannersToDelete: [],
};

export type ManageBannersActionType =
	| 'CHANGE_BANNER_ORDER'
	| 'UPDATE_BANNER_CONTENT'
	| 'DELETE_BANNER'
	| 'UNDELETE_BANNER'
	| 'UPDATE_NEW_BANNER'
	| 'COMMIT_NEW_BANNER'
	| 'COMMIT_BANNER_CHANGES'
	| 'DISCARD_BANNER_CHANGES'
	| 'DISCARD_NEW_BANNER_CHANGES';

export interface BaseManageBannersAction {
	type: ManageBannersActionType;
}
export interface ChangeBannerOrderAction extends BaseManageBannersAction {
	type: 'CHANGE_BANNER_ORDER';
	payload: Banner[];
}
export interface UpdateBannerContentAction extends BaseManageBannersAction {
	type: 'UPDATE_BANNER_CONTENT';
	payload: Banner;
}
export interface DeleteBannerAction extends BaseManageBannersAction {
	type: 'DELETE_BANNER';
	payload: Banner;
}
export interface UndeleteBannerAction extends BaseManageBannersAction {
	type: 'UNDELETE_BANNER';
	payload: Banner;
}
export interface UpdateNewBannerAction extends BaseManageBannersAction {
	type: 'UPDATE_NEW_BANNER';
	payload: Banner;
}
export interface CommitNewBannerAction extends BaseManageBannersAction {
	type: 'COMMIT_NEW_BANNER';
	payload: Banner;
}
export interface CommitBannerChangesAction extends BaseManageBannersAction {
	type: 'COMMIT_BANNER_CHANGES';
	payload: Banner[];
}
export interface DiscardBannerChangesAction extends BaseManageBannersAction {
	type: 'DISCARD_BANNER_CHANGES';
}
export interface DiscardNewBannerChangesAction extends BaseManageBannersAction {
	type: 'DISCARD_NEW_BANNER_CHANGES';
}

export type ManageBannersAction =
	| ChangeBannerOrderAction
	| UpdateBannerContentAction
	| DeleteBannerAction
	| UndeleteBannerAction
	| UpdateNewBannerAction
	| CommitNewBannerAction
	| CommitBannerChangesAction
	| DiscardBannerChangesAction
	| DiscardNewBannerChangesAction;

export interface ManageBannersContextValue {
	manageBannersState: ManageBannersState;
	dispatch: Dispatch<ManageBannersAction>;
}

export const manageBannersContextDefaultValue: ManageBannersContextValue = {
	manageBannersState: manageBannersDefaultState,
	dispatch: noop,
};

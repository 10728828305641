import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const deleteLotAdjustment = async ({
	lotId,
	adjustmentId,
}: {
	lotId: string;
	adjustmentId: string;
}) => {
	const response = await axiosAuth.delete(
		`${apiEndpoints.lot_adjustments}/lots/${lotId}/adjustments/${adjustmentId}`
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { FixedPriceItem, KernelFixedPriceItem } from './lotsService.model';
import { kernelFixedPriceItemToDomainFixedPriceItem } from './lotsService.utils';

export const getFixedPriceItem = async (lotId = ''): Promise<FixedPriceItem> => {
	const query = {
		auction_id: ['fixed_price_marketplace'],
		from: 0,
		id: [lotId],
		queryType: 'Featured',
		size: 1,
	};

	const response = await axiosAuth.post<{ lots: KernelFixedPriceItem[] }>(
		`${config.api.lots}`,
		query
	);

	if (response.status >= 400) {
		throw response;
	}

	const {
		data: { lots: fixedPriceItems = [] },
	} = response;
	const [thisItem = {} as KernelFixedPriceItem] = fixedPriceItems;

	return kernelFixedPriceItemToDomainFixedPriceItem(thisItem);
};

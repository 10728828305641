import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import formatCurrency from '../../utils/formatters/formatCurrency';
import BulkAssignLotsToAuctionModal from '../../Pages/Auctions/LotManager/LotSelector/BulkAssignLotsToAuctionModal';
import BulkDeleteLotsModal from '../../Pages/Auctions/LotManager/LotSelector/BulkDeleteLotsModal';
import BulkSetLotsToLiveModal from '../../Pages/Auctions/LotManager/LotSelector/BulkSetLotsToLiveModal';
import useAuctionById from '../../queries/useAuctionById';
import { Auction } from '../../services/auctionsService';
import { KernelSummaryLot } from '../../services/lotsService/lotsService.model';
import { EDITOR_ROLES } from '../../services/userService/userService.model';
import AuctionPicker from '../AuctionPicker';
import { Button } from '../../prizm-ui/Button';
import Card from '../Card';
import NewAuctionPicker from '../NewAuctionPicker';
import RestrictedArea from '../RestrictedArea';
import SortableTable from '../SortableTable';
import { SortableTableParams } from '../SortableTable/SortableTable.model';
import LotSelectorLots from './LotSelectorLots';
import { BulkEditContextProvider } from './useBulkEditMode';
import { getTestIdentifier } from '../../utils/test-identifiers/getTestIdentifier';
import useDecision from '../../optimizely/useDecision';
import { FeatureFlags } from '../../optimizely/useDecision/useDecision.model';

const LotSelector = ({ lots, isLoading }: { lots: KernelSummaryLot[]; isLoading: boolean }) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { auctionId } = useParams<{ auctionId: string; lotId: string }>();

	const handleSelectAuction = (selectedAuctionId: string) =>
		history.push(`/auctions/${selectedAuctionId}/inventory-manager`);

	const [isBulkEditMode, setIsBulkEditMode] = useState(false);
	const [bulkSelectedLotIds, setBulkSelectedLotIds] = useState<string[]>([]);

	const columns: ColumnDef<KernelSummaryLot>[] = [
		{
			accessorFn: ({ lot_number }) => (lot_number ?? '').toString(),
			header: 'Lot #',
			id: 'lot_number',
		},
		{
			accessorKey: 'title',
			header: 'Title',
			cell: ({ getValue }) =>
				getValue() || <span className="italic text-neutral3">Untitled Lot</span>,
		},
		{
			accessorKey: 'serial_number',
		},
		{
			accessorKey: 'min_bid_price',
			header: 'Min Bid',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatCurrency(getValue() as number),
		},
	];

	const tableParams: SortableTableParams<KernelSummaryLot> = {
		columns,
		data: lots ?? [],
		getRowId: ({ lot_id }) => lot_id,
		initialState: {
			pagination: {
				pageSize: 1000,
			},
		},
		state: {
			columnVisibility: {
				lot_number: true,
				title: true,
				serial_number: false,
				min_bid_price: true,
			},
		},
	};

	const [shouldShowBulkAssignLotsToAuctionModal, setShouldShowBulkAssignLotsToAuctionModal] =
		useState(false);
	const [shouldShowBulkDeleteLotsModal, setShouldShowBulkDeleteLotsModal] = useState(false);
	const [shouldShowBulkSetLotsToLiveModal, setShouldShowBulkSetLotsToLiveModal] = useState(false);

	const shouldShowNewAuctionPicker = useDecision(FeatureFlags.NewAuctionPicker);
	const { data: thisAuction = {} as Auction } = useAuctionById(auctionId);

	return (
		<div
			className="mb-8"
			data-testid={getTestIdentifier({
				componentName: 'LotSelector',
				descriptor: 'main-container',
				pagePath: 'inventory-manager',
				sectionPath: 'auctions',
			})}
		>
			<Card>
				<div className="mb-4 flex flex-col lg:flex-row justify-between lg:items-end">
					{shouldShowNewAuctionPicker ? (
						<div>
							<div className="text-display5 mb-4">
								{auctionId === 'Unassigned' ? 'Unassigned Lots' : thisAuction.title}
							</div>
							<NewAuctionPicker
								selectedAuctionId={auctionId}
								setSelectedAuctionId={handleSelectAuction}
								isDisabled={!pathname.includes('inventory-manager')}
								shouldShowUnassignedLots={true}
							/>
						</div>
					) : (
						<AuctionPicker
							selectedAuctionId={auctionId}
							setSelectedAuctionId={handleSelectAuction}
							isDisabled={!pathname.includes('inventory-manager')}
						/>
					)}
					<RestrictedArea allowedRoles={EDITOR_ROLES}>
						<Button
							className={shouldShowNewAuctionPicker ? '' : 'mt-4 lg:mt-0 lg:mb-9'}
							size="md"
							variant="brand"
							onClick={() => setIsBulkEditMode(!isBulkEditMode)}
						>
							{isBulkEditMode ? 'Exit Bulk Manage Mode' : 'Bulk Manage Lots'}
						</Button>
					</RestrictedArea>
				</div>
				{isBulkEditMode && (
					<div className="my-4 flex flex-col lg:flex-row justify-between items-end lg:items-center">
						<div className="flex w-full">
							<Button
								className="mr-2 flex-grow lg:flex-grow-0"
								disabled={bulkSelectedLotIds.length === 0}
								size="md"
								variant="secondary"
								onClick={() => setShouldShowBulkAssignLotsToAuctionModal(true)}
							>
								Reassign Lots
							</Button>
							{shouldShowBulkAssignLotsToAuctionModal && (
								<BulkAssignLotsToAuctionModal
									onClose={() => setShouldShowBulkAssignLotsToAuctionModal(false)}
									bulkSelectedLotIds={bulkSelectedLotIds}
									setIsBulkEditMode={setIsBulkEditMode}
									setBulkSelectedLotIds={setBulkSelectedLotIds}
								/>
							)}
							<Button
								className="mr-2 flex-grow lg:flex-grow-0"
								disabled={bulkSelectedLotIds.length === 0}
								size="md"
								variant="secondary"
								onClick={() => setShouldShowBulkSetLotsToLiveModal(true)}
							>
								Set to Live
							</Button>
							{shouldShowBulkSetLotsToLiveModal && (
								<BulkSetLotsToLiveModal
									onClose={() => setShouldShowBulkSetLotsToLiveModal(false)}
									bulkSelectedLotIds={bulkSelectedLotIds}
									setIsBulkEditMode={setIsBulkEditMode}
									setBulkSelectedLotIds={setBulkSelectedLotIds}
								/>
							)}
							<Button
								className="flex-grow lg:flex-grow-0"
								destructive
								disabled={bulkSelectedLotIds.length === 0}
								size="md"
								onClick={() => setShouldShowBulkDeleteLotsModal(true)}
							>
								Delete Lots
							</Button>
							{shouldShowBulkDeleteLotsModal && (
								<BulkDeleteLotsModal
									onClose={() => setShouldShowBulkDeleteLotsModal(false)}
									bulkSelectedLotIds={bulkSelectedLotIds}
									setIsBulkEditMode={setIsBulkEditMode}
									setShouldShowBulkDeleteLotsModal={setShouldShowBulkDeleteLotsModal}
									setBulkSelectedLotIds={setBulkSelectedLotIds}
								/>
							)}
						</div>
						<div className="text-body2 mt-4 lg:mt-0 w-full text-right">
							{bulkSelectedLotIds.length} {bulkSelectedLotIds.length === 1 ? 'lot' : 'lots'}{' '}
							selected
						</div>
					</div>
				)}
				<div className="mt-4">
					<BulkEditContextProvider
						value={{ isBulkEditMode, setIsBulkEditMode, bulkSelectedLotIds, setBulkSelectedLotIds }}
					>
						<SortableTable
							tableParams={tableParams}
							isLoading={isLoading}
							customTableComponent={LotSelectorLots}
						/>
					</BulkEditContextProvider>
				</div>
			</Card>
		</div>
	);
};

export default LotSelector;

import { SimpleDropdownMenuItem } from '../../../../../../components/Dropdown/Dropdown.model';
import { PaymentMethod } from '../../../../../../services/ordersService/ordersService.model';

export interface PaymentMethodDropdownOption extends SimpleDropdownMenuItem {
	value: PaymentMethod;
	label: string;
}
export const PAYMENT_OPTIONS: PaymentMethodDropdownOption[] = [
	{ value: 'ach', label: 'ACH' },
	{ value: 'advance', label: 'Advance' },
	{ value: 'balance', label: 'Balance' },
	{ value: 'cash', label: 'Cash' },
	{ value: 'check', label: 'Check' },
	{ value: 'creditCard', label: 'Credit Card' },
	{ value: 'credits', label: 'Credits' },
	{ value: 'crypto', label: 'Cryptocurrency' },
	{ value: 'offset', label: 'Offset' },
	{ value: 'other', label: 'Other' },
	{ value: 'wire', label: 'Wire' },
];

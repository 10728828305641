import { useState } from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Button } from '../../../../../prizm-ui/Button';
import { Input } from '../../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../../queries/queryKeys';
import {
	emailSellerStatementPDF,
	SellerStatementStatusType,
} from '../../../../../services/sellerStatementService';
import { isEmail } from '../../../../../utils/validators';

const EmailStatementPDFButton = ({
	auctionId,
	defaultEmail,
	statementId,
	statementStatus,
}: {
	auctionId: string;
	defaultEmail: string;
	statementId: string;
	statementStatus: SellerStatementStatusType;
}) => {
	const [shouldShowEmailStatementModal, setShouldShowEmailStatementModal] = useState(false);
	const [emailTo, setEmailTo] = useState(defaultEmail);

	const { mutate: doSendEmail, status: sendEmailStatus } = useMutation({
		mutationFn: () => emailSellerStatementPDF({ auctionId, statementId, emailTo }),
		mutationKey: [QueryKeys.EmailSellerStatementPDF, auctionId, statementId, emailTo],
	});

	const handleSendEmail = () =>
		doSendEmail(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully sent statement PDF.');
				setShouldShowEmailStatementModal(false);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong sending statement PDF. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	return (
		<>
			<Button
				variant={statementStatus === 'Paid' ? 'primary' : 'secondary'}
				size="md"
				trailingIcon="Send"
				className="mb-2 w-full"
				onClick={() => setShouldShowEmailStatementModal(true)}
			>
				Email Statement PDF
			</Button>
			{shouldShowEmailStatementModal && (
				<ActionModal
					title="Email Statement PDF"
					onClose={() => setShouldShowEmailStatementModal(false)}
					onConfirm={handleSendEmail}
					isLoading={sendEmailStatus === 'loading'}
					canConfirm={isEmail(emailTo)}
				>
					<Input
						value={emailTo}
						onChange={({ target: { value } }) => setEmailTo(value)}
						id="email-to"
						label="Recipient Email Address"
					/>
				</ActionModal>
			)}
		</>
	);
};

export default EmailStatementPDFButton;

import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useLotsForAuction from '../../../../../queries/useLotsForAuction';
import usePluralLots from '../../../../../queries/usePluralLots';
import { updateLot } from '../../../../../services/lotsService';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';

const BulkSetLotsToLiveModal = ({
	onClose,
	bulkSelectedLotIds,
	setIsBulkEditMode,
	setBulkSelectedLotIds,
}: {
	onClose: () => void;
	bulkSelectedLotIds: string[];
	setIsBulkEditMode: (nextState: boolean) => void;
	setBulkSelectedLotIds: (nextState: string[]) => void;
}) => {
	const { auctionId } = useParams<{ auctionId: string }>();

	const lotWord = bulkSelectedLotIds.length === 1 ? 'lot' : 'lots';

	const {
		data: bulkSelectedLotItems = [] as KernelLot[],
		status: lotsStatus,
		updateCacheForIndividualLots,
	} = usePluralLots(bulkSelectedLotIds);

	useEffect(() => {
		updateCacheForIndividualLots(bulkSelectedLotItems);
	}, [bulkSelectedLotItems.length]);

	const { status: lotsForAuctionStatus, updatePluralLots: updateLotsForAuction } =
		useLotsForAuction({ auctionId });

	const updatedLotDiffs = bulkSelectedLotItems.map(({ lot_id }) => ({
		lot_id,
		status: 'Live',
		lot_status_changed: 'Live',
	})) as Partial<KernelLot>[];

	const bulkUpdateLots = async () =>
		Promise.all(updatedLotDiffs.map(async (lotToUpdate) => await updateLot(lotToUpdate)));
	const { mutate: doBulkUpdateLots, status: bulkUpdateLotsStatus } = useMutation({
		mutationKey: [QueryKeys.BulkUpdateLots, Object.entries(updatedLotDiffs).flat()],
		mutationFn: () => bulkUpdateLots(),
	});

	const handleConfirm = () =>
		doBulkUpdateLots(undefined, {
			onSuccess: () => {
				showSuccessToast(`Successfully set ${bulkSelectedLotIds.length} ${lotWord} to live.`);

				updateLotsForAuction(updatedLotDiffs);
				setIsBulkEditMode(false);
				setBulkSelectedLotIds([]);

				onClose();
			},
			onError: () => {
				showErrorToast(`Something went wrong adding ${lotWord} to live. Please try again later.`);
			},
		});

	const canSetLotsToLive = ![bulkUpdateLotsStatus, lotsForAuctionStatus, lotsStatus].includes(
		'loading'
	);

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleConfirm}
			title="Set lots to live"
			canConfirm={canSetLotsToLive}
			isLoading={bulkUpdateLotsStatus === 'loading'}
		>
			{`The selected ${lotWord} will be live and set to active. Are you sure?`}
		</ActionModal>
	);
};

export default BulkSetLotsToLiveModal;

import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useAuctions from '../../../queries/useAuctions';
import useIndividualLot from '../../../queries/useIndividualLot';
import useListOfLots from '../../../queries/useListOfLots';
import LotManagerView from './LotManagerView';

const LotManager = () => {
	const { auctionId = 'Unassigned', lotId = '' } = useParams<{
		auctionId?: string;
		lotId?: string;
	}>();

	const { status: auctionsStatus } = useAuctions();
	const { status: lotsForThisAuctionStatus } = useListOfLots({
		params: { auctionId, kind: 'assigned' },
		filters: {
			missing: [],
			unsold: false,
		},
	});

	const { status: lotFromParamsStatus } = useIndividualLot(lotId);

	const requiredQueryStatuses = lotId
		? [auctionsStatus, lotsForThisAuctionStatus, lotFromParamsStatus]
		: [auctionsStatus, lotsForThisAuctionStatus];

	return (
		<LoadingWrapper queryStatuses={requiredQueryStatuses}>
			<LotManagerView />
		</LoadingWrapper>
	);
};

export default LotManager;

import { flexRender, Row } from '@tanstack/react-table';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { KernelSummaryLot } from '../../../../services/lotsService/lotsService.model';
import LotImage from '../../../LotImage';
import { useBulkEditContext } from '../../useBulkEditMode';

const LotSelectorRow = ({ row, index }: { row: Row<KernelSummaryLot>; index: number }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const { isBulkEditMode, bulkSelectedLotIds, setBulkSelectedLotIds } = useBulkEditContext();

	const {
		original: { auction_id, lot_id, primary_image_name, serial_number },
	} = row;

	const { lotId: lotIdFromPath } = useParams<{ lotId: string }>();
	const isSelected = isBulkEditMode
		? bulkSelectedLotIds.includes(lot_id)
		: lot_id === lotIdFromPath;

	const pushToNextLocation = () => {
		const nextLocation = pathname.includes('inventory-manager')
			? `/auctions/${auction_id || 'Unassigned'}/inventory-manager/${lot_id}`
			: `/auctions/auctionmanager/${auction_id}/${lot_id}`;
		history.push(nextLocation);
	};

	const addOrRemoveFromBulkSelectedLotIds = () => {
		if (bulkSelectedLotIds.includes(lot_id)) {
			return setBulkSelectedLotIds(bulkSelectedLotIds.filter((thisLotId) => thisLotId !== lot_id));
		}
		return setBulkSelectedLotIds([...bulkSelectedLotIds, lot_id]);
	};

	const onClick = isBulkEditMode ? addOrRemoveFromBulkSelectedLotIds : pushToNextLocation;

	const hoverClass = isBulkEditMode && !isSelected ? 'hover:bg-blue3 hover:text-neutral1' : '';

	const [lotNumberCell, titleCell, minBidCell] = row.getVisibleCells();

	return (
		<button
			className={`grid grid-cols-12 gap-4 w-full ${
				index % 2 === 0 ? 'bg-neutral2' : 'bg-neutral1'
			} ${isSelected ? 'bg-blue3' : ''} ${hoverClass}`}
			onClick={onClick}
		>
			<div className="col-span-3 lg:col-span-2 xl:col-span-1 p-4 pr-8 mt-2">
				{flexRender(lotNumberCell.column.columnDef.cell, lotNumberCell.getContext())}
			</div>
			<div className="col-span-7 lg:col-span-8 xl:col-span-9 p-4 flex items-start">
				<div className="w-8 flex-shrink-0 mr-4">
					<LotImage imageName={primary_image_name ?? ''} lotId={lot_id} className="max-h-full" />
				</div>
				<div className="flex-grow text-left">
					<div className="text-body1">
						{flexRender(titleCell.column.columnDef.cell, titleCell.getContext())}
					</div>
					<div
						className={`text-caption1 uppercase ${serial_number ? 'text-neutral3' : 'text-error1'}`}
					>
						{serial_number ? `Serial: ${serial_number}` : 'Missing Serial Number'}
					</div>
				</div>
			</div>
			<div className="col-span-2 p-4 text-right">
				{flexRender(minBidCell.column.columnDef.cell, minBidCell.getContext())}
			</div>
		</button>
	);
};

export default LotSelectorRow;

import React from 'react';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';
import './Input.css';
import { ErrorText, getInputClasses, INPUT_HEIGHT_CLASS, HelpText, Label } from './Input.shared';

export type InputProps = {
	errorText?: string;
	helpText?: string;
	icon?: IconName | JSX.Element;
	id: string;
	invalid?: boolean;
	label: string;
	showLabel?: string;
	hideLabel?: string;
	hiddenMessage?: string;
	visibleMessage?: string;
	onIconClick?: () => void;
} & Omit<React.ComponentPropsWithRef<'input'>, 'id' | 'label'>;

const PasswordToggle = ({
	id,
	onClick,
	type,
	showLabel,
	hideLabel,
	hiddenMessage,
	visibleMessage,
}: {
	id: string;
	onClick: () => void;
	type: React.HTMLInputTypeAttribute;
	showLabel: string;
	hideLabel: string;
	hiddenMessage: string;
	visibleMessage: string;
}) => (
	<>
		<button
			type="button"
			className={[
				'flex',
				'items-center',
				'absolute',
				'inset-y-0',
				'right-0',
				'h-full',
				'px-4',
				'rounded-full',
				'text-neutral3',
				'transition',
				'duration-75',
				'hover:text-primary-500',
				'focus-visible:text-primary-500',
				'focus:outline-none',
				'peer-disabled:hidden',
			].join(' ')}
			onClick={onClick}
			aria-controls={id}
			aria-pressed={type === 'password' ? false : true}
			aria-label={type === 'password' ? showLabel : hideLabel}
		>
			<Icon name={type === 'password' ? 'Eye' : 'EyeOff'} aria-hidden />
		</button>
		<span className="sr-only" aria-live="polite">
			{type === 'password' ? hiddenMessage : visibleMessage}
		</span>
	</>
);

const ErrorIcon = ({ invalid }: { invalid?: boolean }) => (
	<span
		className={[
			'flex',
			'items-center',
			'absolute',
			'inset-y-0',
			'right-4',
			'text-error-500',
			'peer-disabled:hidden',
			...(invalid ? [] : ['hidden', 'peer-invalid:flex']),
		].join(' ')}
		aria-hidden
	>
		<Icon name="ErrorCircleFilled" aria-hidden />
	</span>
);

export const Input = React.forwardRef(
	(
		{
			className,
			disabled,
			errorText,
			helpText,
			icon,
			id,
			invalid,
			label,
			showLabel = 'Show password',
			hideLabel = 'Hide password',
			hiddenMessage = 'Password is hidden',
			visibleMessage = 'Password is visible',
			onIconClick,
			required,
			type = 'text',
			...props
		}: InputProps,
		ref?: React.ForwardedRef<HTMLInputElement>
	) => {
		const [_type, setType] = React.useState(type);

		React.useEffect(() => {
			setType(type);
		}, [type]);

		const toggleType = () => {
			if (_type === 'text') {
				setType('password');
			} else {
				setType('text');
			}
		};

		const inputProps = {
			ref,
			disabled,
			id,
			required,
			type: _type,
			...props,
		};

		const TrailingSlot = () => {
			const IconWrapper = onIconClick ? 'button' : 'span';

			return (
				<>
					{icon ? (
						<IconWrapper
							className={[
								'flex',
								'items-center',
								'absolute',
								'inset-y-0',
								'right-0',
								'h-full',
								'px-4',
								'text-neutral3',
								...(onIconClick
									? [
											'hover:text-primary-500',
											'focus-visible:text-primary-500',
											'focus:outline-none',
									  ]
									: ['pointer-events-none']),
							].join(' ')}
							onClick={onIconClick}
							aria-hidden
							{...(onIconClick && {
								type: 'button',
							})}
						>
							{typeof icon === 'string' && iconList.includes(icon) ? (
								<Icon name={icon} aria-hidden />
							) : (
								React.isValidElement(icon) && icon
							)}
						</IconWrapper>
					) : type === 'password' ? (
						<PasswordToggle
							id={id}
							onClick={toggleType}
							type={_type}
							showLabel={showLabel}
							hideLabel={hideLabel}
							hiddenMessage={hiddenMessage}
							visibleMessage={visibleMessage}
						/>
					) : (
						<ErrorIcon invalid={invalid} />
					)}
				</>
			);
		};

		return (
			<div className={className}>
				<div className={['relative', INPUT_HEIGHT_CLASS].join(' ')}>
					<input
						placeholder=" "
						{...inputProps}
						className={[
							getInputClasses({
								hasIcon: !!icon || type === 'password',
								invalid,
							}),
							'caret-neutral2',
						].join(' ')}
						aria-describedby={helpText ? `${id}-hint` : undefined}
						aria-errormessage={invalid && errorText ? `${id}-error` : undefined}
						aria-invalid={invalid}
						aria-required={required}
					/>
					<Label disabled={disabled} id={id} invalid={invalid}>
						{label}
					</Label>
					<TrailingSlot />
				</div>
				<ErrorText id={id} errorText={errorText} invalid={invalid} />
				<HelpText id={id} helpText={helpText} />
			</div>
		);
	}
);

Input.displayName = 'Input';

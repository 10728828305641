import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import { KernelInvoice } from './invoicesService.model';
import { CheckoutOrder } from '../ordersService/ordersService.model';

export const getInvoices = async ({
	auction_id,
	invoice_status,
	filters,
}: {
	auction_id: string;
	invoice_status: string;
	filters?: Record<string, string>;
}): Promise<CheckoutOrder[] | KernelInvoice[]> => {
	const body = {
		auction_id,
		company_id: config.organization.company_id,
		filters,
		invoice_status,
		query_type: 'auction',
	};

	const response = await postAuthed(`${config.api.auction_invoices}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import React, { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../../../../../../../components/Button';
import Label from '../../../../../../../components/Label';
import Modal from '../../../../../../../components/Modal';
import RecordRefundModalLineItem from './RecordRefundModalLineItem';
import RecordRefundModalLot from './RecordRefundModalLot';
import { Textarea } from '../../../../../../../prizm-ui/Textarea';
import { showErrorToast, showSuccessToast } from '../../../../../../../components/Toast';
import { RecordRefundModalProps } from './RecordRefundModal.model';
import { QueryKeys } from '../../../../../../../queries/queryKeys';
import {
	CheckoutOrder,
	CheckoutOrderComment,
	FreeformAdjustment,
	InvoiceStatus,
	LotAdjustment,
	PaymentMethod,
	PaymentMethodFees,
	PAYMENT_METHODS_WITH_SURCHARGES,
	StructuredFee,
	StructuredFees,
	TaxesByTaxablePaymentMethod,
} from '../../../../../../../services/ordersService/ordersService.model';
import { updateOrder } from '../../../../../../../services/ordersService/updateOrder';
import { CurrentUser } from '../../../../../../../services/userService/userService.model';
import useCurrentUser from '../../../../../../../queries/useCurrentUser';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber';

const RecordRefundModal = ({ order, paymentMethod, onClose }: RecordRefundModalProps) => {
	const [refundComment, setRefundComment] = useState('');

	const { updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber: order.invoiceNumber,
		enabled: true,
	});

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const { mutate: doIssueOrderRefund, status: issueOrderRefundStatus } = useMutation({
		mutationFn: ({ orderNumber, orderStatus }: { orderNumber: string; orderStatus: string }) =>
			updateOrder({
				orderNumber,
				fieldsToUpdate: {
					comments: getUpdatedComments(),
					invoiceStatus: orderStatus as InvoiceStatus,
					paymentMethod: paymentMethod as PaymentMethod,
				},
			}),
		mutationKey: [QueryKeys.IssueOrderRefund, order.invoiceNumber],
	});

	const getUpdatedComments = () => {
		const newRefundComment: CheckoutOrderComment = {
			author: {
				authorDisplayName: currentUser.currentUserFullName || 'unknown',
				authorId: currentUser.currentUserId,
			},
			kind: 'order-refund',
			text: refundComment,
			timestamp: new Date().toISOString(),
		};

		return Array.isArray(order.comments)
			? [...order.comments, newRefundComment]
			: [newRefundComment];
	};

	const handleIssueRefund = () => {
		if (!refundCommentIsValid()) {
			showErrorToast(
				'Comments are required when issuing a refund. Please enter a comment and try again.'
			);

			return;
		}

		doIssueOrderRefund(
			{ orderNumber: order.invoiceNumber, orderStatus: 'Refunded' },
			{
				onError: () => showErrorToast('Something went wrong. Please try again later.'),
				onSuccess: (updatedOrder: CheckoutOrder) => {
					updateOrderInCache({
						...updatedOrder,
						comments: getUpdatedComments(),
					});

					showSuccessToast('Order refund recorded successfully.');

					onClose();
				},
			}
		);
	};

	const refundCommentIsValid = () => refundComment.replace(/\s/g, '').length > 0;

	const {
		costMetadata: {
			adjustments = [],
			fees = {} as StructuredFees,
			lots = [],
			paymentOptionFees = {} as PaymentMethodFees,
			subtotal,
			taxes = {} as TaxesByTaxablePaymentMethod,
		} = {},
	} = order;
	const advances = adjustments.filter(
		({ category }) => category === 'advance'
	) as FreeformAdjustment[];
	const freeformAdjustments = adjustments.filter(
		({ category }) => category === 'other'
	) as FreeformAdjustment[];
	const offsets = adjustments.filter(
		({ category }) => category === 'offset'
	) as FreeformAdjustment[];

	const hasPaymentMethodSurcharge = PAYMENT_METHODS_WITH_SURCHARGES.includes(paymentMethod);
	const paymentMethodSurcharge = paymentOptionFees[paymentMethod] || 0;
	const taxesByPaymentMethod = taxes[paymentMethod] || 0;
	const totalRefundAmount = hasPaymentMethodSurcharge
		? (subtotal ?? 0) + paymentMethodSurcharge + taxesByPaymentMethod
		: (subtotal ?? 0) + taxesByPaymentMethod;

	return (
		<Modal title="Issue Refund" onClose={onClose}>
			<div className="mt-6 max-h-64 overflow-y-auto pr-4">
				{lots.length > 0 &&
					lots.map((lot) => (
						<RecordRefundModalLot
							key={lot.lotId}
							lot={lot}
							lotAdjustments={
								adjustments.filter(
									({ category, lotId }) => category === 'lot' && lotId === lot.lotId
								) as LotAdjustment[]
							}
						/>
					))}
				<hr className="my-4" />
				{freeformAdjustments.length > 0 &&
					freeformAdjustments.map(({ amount, description = '', id }) => (
						<RecordRefundModalLineItem amount={Number(amount)} key={id} label={description} />
					))}
				{freeformAdjustments.length > 0 && <hr className="my-4" />}
				{Object.keys(fees).map((feeType, index) => (
					<RecordRefundModalLineItem
						amount={fees[feeType as StructuredFee]}
						key={`${feeType}-${index}`}
						label={feeType}
					/>
				))}
				<RecordRefundModalLineItem amount={taxesByPaymentMethod} label="Tax" />
				{paymentMethodSurcharge > 0 && (
					<RecordRefundModalLineItem
						amount={paymentMethodSurcharge}
						label="Payment Method Surcharge"
					/>
				)}
				<hr className="my-4" />
				{offsets.length > 0 &&
					offsets.map(({ amount, description = '', id }) => (
						<RecordRefundModalLineItem
							amount={Number(amount)}
							key={id}
							label={`Offset: ${description}`}
						/>
					))}
				{advances.length > 0 &&
					advances.map(({ amount, description = '', id }) => (
						<RecordRefundModalLineItem
							amount={Number(amount)}
							key={id}
							label={`Advance: ${description}`}
						/>
					))}
				{(offsets.length > 0 || advances.length > 0) && <hr className="my-4" />}
				{typeof subtotal === 'number' ? (
					<RecordRefundModalLineItem
						amount={totalRefundAmount}
						bold
						label={'Total Refund Amount'}
					/>
				) : (
					<div className={`mt-2 flex flex-row justify-between text-body1 font-bold`}>
						<p className="capitalize">Total Refund Amount</p>
						<p className="text-error2">Error: Subtotal Missing</p>
					</div>
				)}
			</div>
			<div className="mt-6">
				<Label htmlFor="comment">Comments</Label>
				<Textarea
					autoFocus
					defaultValue={refundComment}
					id="new-comment"
					label=""
					name="new-comment"
					onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
						setRefundComment(value)
					}
				/>
			</div>
			<div className="mt-6">
				<Button
					className="mr-4"
					disabled={!refundComment}
					isLoading={issueOrderRefundStatus === 'loading'}
					kind="primary"
					onClick={handleIssueRefund}
				>
					Issue Refund
				</Button>
				<Button kind="secondary" onClick={() => onClose()}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};

export default RecordRefundModal;

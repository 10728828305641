import React, { LabelHTMLAttributes } from 'react';

const Label = (props: LabelHTMLAttributes<HTMLLabelElement> & { isErrored?: boolean }) => {
	return (
		<label
			{...props}
			className={`label mb-1 block ${props.isErrored ? 'text-error1' : 'text-neutral1'} ${
				props.className
			}`}
		/>
	);
};

export default Label;

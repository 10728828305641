import React, { useState } from 'react';
import Card from '../../../../components/Card';
import ImageUploadDropzone from '../../../../components/ImageUploadDropzone';
import { getFilenameForLegacyImage } from '../../../../AdminUtils/imageUtils.helpers';
import useImageUrl from '../../../../queries/useImageUrl';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import { getStoredItemPath } from '../../../../services/storageService/storageService.utils';
import { useMutation, useQueryClient } from 'react-query';
import { updateAuction } from '../../../../services/auctionsService';
import { QueryKeys } from '../../../../queries/queryKeys';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import ConfirmationButtons from '../../../../components/ConfirmationButtons';

const AuctionImageEditor = ({
	existingImage = '',
	auctionId,
}: {
	existingImage?: string;
	auctionId: string;
}) => {
	const existingImageFilename = getFilenameForLegacyImage({ imageIdentifier: existingImage });
	const [currentImage, setCurrentImage] = useState(existingImageFilename);

	const { data: imageUrl, status: imageUrlStatus } = useImageUrl({
		storageProvider: 's3',
		rootPath: 'Auctions',
		itemId: currentImage,
		parentPath: auctionId,
		imageSize: '@1x',
	});

	const queryClient = useQueryClient();

	const { mutate: doUpdateAuctionImage, status: updateAuctionImageStatus } = useMutation({
		mutationFn: (newImageName: string) =>
			updateAuction(auctionId, { primary_image_name: newImageName }),
		mutationKey: [QueryKeys.UpdateAuctionImage, auctionId, currentImage],
	});
	const handleUpdateAuctionImage = (newImageName: string) => {
		doUpdateAuctionImage(newImageName, {
			onSuccess: () => {
				showSuccessToast('Successfully updated auction image.');
				queryClient.invalidateQueries([QueryKeys.GetAuctions]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating the auction image. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});
	};

	const canTakeAction =
		currentImage !== existingImageFilename && updateAuctionImageStatus !== 'loading';

	return (
		<Card>
			<div className="mb-4 text-display5">Auction Image</div>
			<div className="flex items-center">
				<div className="mr-8 w-72">
					<ImageUploadDropzone
						bucketPath={getStoredItemPath({ rootPath: 'Auctions', parentPath: auctionId })}
						onSuccess={setCurrentImage}
					/>
				</div>
				<div className="w-48">
					{currentImage && (
						<LoadingWrapper queryStatuses={[imageUrlStatus]}>
							<img className="object-fit" src={imageUrl} />
						</LoadingWrapper>
					)}
				</div>
			</div>
			<div className="my-4">
				<ConfirmationButtons
					disabled={!canTakeAction}
					loading={updateAuctionImageStatus === 'loading'}
					onCancel={() => setCurrentImage(existingImageFilename)}
					onConfirm={() => handleUpdateAuctionImage(currentImage)}
					confirmButtonLabel="Save Image"
				/>
			</div>
		</Card>
	);
};

export default AuctionImageEditor;

import { has } from 'lodash';
import { useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import useRateCardsForUser from '../../../../queries/useRateCardsForUser';
import useUserById from '../../../../queries/useUserById';
import { KernelRateCard } from '../../../../services/rateCardService/rateCardService.model';
import { User } from '../../../../services/userService/userService.model';
import CustomerOverviewGeneralInfo from './CustomerOverviewGeneralInfo';
import RateCard from './RateCard';

const CustomerOverview = () => {
	const { userId } = useParams<{ userId: string }>();
	const { data: user = {} as User, status: userStatus } = useUserById(userId);
	const { data: auctionRateCard = {} as KernelRateCard, status: auctionRateCardsStatus } =
		useRateCardsForUser({ userId, variant: 'auction' });

	return (
		<LoadingWrapper queryStatuses={[userStatus, auctionRateCardsStatus]}>
			<div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8 max-w-6xl">
				<Card>
					<CustomerOverviewGeneralInfo />
				</Card>
				<Card className="self-start">
					<RateCard
						variant="auction"
						legacyCommissionRate={
							has(user, 'commission') &&
							typeof user.commission !== 'undefined' &&
							user.commission !== ''
								? Number(user.commission)
								: undefined
						}
						kernelRateCard={auctionRateCard}
					/>
				</Card>
			</div>
		</LoadingWrapper>
	);
};

export default CustomerOverview;

import { Menu, MenuButton, MenuItem, MenuList } from '../../../../prizm-ui/Menu';
import { ALL_AUCTION_STATUSES, AuctionStatus } from '../../../../services/auctionsService';
import { stripUnderscores } from '../../../../utils/formatters';

const AuctionStatusMenu = ({
	allowedAuctionStatuses,
	setSelectedAuctionStatus,
}: {
	allowedAuctionStatuses: AuctionStatus[];
	setSelectedAuctionStatus: (newAuctionStatus: AuctionStatus) => void;
}) => {
	const auctionStatuses =
		allowedAuctionStatuses.length > 0
			? ALL_AUCTION_STATUSES.filter((auctionStatus) =>
					allowedAuctionStatuses.includes(auctionStatus)
			  )
			: ALL_AUCTION_STATUSES;

	return (
		<Menu onSelect={(newValue?: string) => setSelectedAuctionStatus(newValue as AuctionStatus)}>
			<MenuButton size="md" variant="secondary" withChevron>
				Auction Status
			</MenuButton>
			<MenuList>
				{auctionStatuses.length === 0 && (
					<MenuItem index={0} value={undefined}>
						All
					</MenuItem>
				)}
				{auctionStatuses.map((auctionStatus, index) => (
					<MenuItem
						key={auctionStatus}
						index={auctionStatuses.length === 0 ? index + 1 : index}
						value={auctionStatus}
					>
						{stripUnderscores(auctionStatus)}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default AuctionStatusMenu;

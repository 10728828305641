import React, { ClipboardEventHandler, FocusEventHandler } from 'react';
import { TableCell } from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
import { noop } from 'lodash';
import { SortableTableColumn } from '../../../../TypesafeSortableTable/TypesafeSortableTable.model';

const EditableCell = <T extends unknown>({
	column,
	cellValue,
	rowObject,
	rowNumber,
	contentEditable,
	onPaste = noop,
	onBlur,
	onFocus,
}: {
	column: SortableTableColumn<T>;
	cellValue: unknown;
	rowObject: unknown;
	rowNumber: number;
	contentEditable: boolean;
	onPaste?: ClipboardEventHandler;
	onBlur: FocusEventHandler;
	onFocus: FocusEventHandler;
}) => {
	const formatValue = column.format || noop;
	const formatFullValue = column.formatFull || noop;

	let mutableValue =
		'format' in column && typeof cellValue === 'number' ? formatValue(cellValue) : cellValue;
	if ('formatFull' in column) mutableValue = formatFullValue(rowObject);
	if (typeof mutableValue === 'boolean') mutableValue = mutableValue.toString();

	return (
		<TableCell key={column.id + rowNumber} className="align-middle">
			{contentEditable && !column.readOnly ? (
				<ContentEditable
					html={mutableValue as string}
					data-column={column.id}
					data-row={rowNumber}
					className="content-editable"
					onBlur={onBlur}
					onPaste={onPaste}
					onFocus={onFocus}
					onChange={noop}
				/>
			) : (
				(mutableValue as string)
			)}
		</TableCell>
	);
};

export default EditableCell;

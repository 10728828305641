import { noop } from 'lodash';
import { createContext, useContext } from 'react';

const sidebarContext = createContext<{
	closeSidebarIfNarrowViewport: () => void;
}>({ closeSidebarIfNarrowViewport: noop });

export const SidebarContextProvider = sidebarContext.Provider;

export const useSidebarContext = () => useContext(sidebarContext);

import { getStripeStatementUrl } from '../../../../../utils/formatters';
import stripeLogo from './stripe-logo.png';

const StripePayoutLink = ({ payoutAccountId }: { payoutAccountId: string }) => {
	return (
		<a
			href={getStripeStatementUrl(payoutAccountId)}
			target="_blank"
			rel="noreferrer"
			className="py-1 flex-shrink-0"
		>
			<img src={stripeLogo} className="w-8" />
		</a>
	);
};

export default StripePayoutLink;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../prizm-ui/Button';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import Card from '../../Card';
import BidHistoryCard from '../../BidHistoryCard';
import DefinitionList from '../../DefinitionList';
import { DefinitionListRow } from '../../DefinitionList/DefinitionList.model';
import LoadingWrapper from '../../LoadingWrapper';
import LotHistoryModal from './LotHistoryModal/LotHistoryModal';
import Modal from '../../Modal';
import RestrictedArea from '../../RestrictedArea';
import EditLotFinancialDetailsModal from './EditLotFinancialDetailsModal';
import formatCurrency from '../../../utils/formatters/formatCurrency';
import {
	KernelLot,
	KernelLotHistory,
	KernelLotHistoryInvoiceEntry,
	KernelLotHistorySellerStatementEntry,
} from '../../../services/lotsService/lotsService.model';
import { EDITOR_ROLES, User } from '../../../services/userService/userService.model';
import useIndividualLot from '../../../queries/useIndividualLot';
import useLotHistory from '../../../queries/useLotHistory/useLotHistory';
import useUserById from '../../../queries/useUserById/useUserById';

const LotFinancialDetails = ({ lotId }: { lotId: string }) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);
	const { data: lotHistory = [] as KernelLotHistory } = useLotHistory(lotId);
	const { data: winner } = useUserById(lot.winner_id);

	const [shouldShowBiddingHistoryModal, setShouldShowBiddingHistoryModal] = useState(false);
	const [shouldShowItemHistoryModal, setShouldShowItemHistoryModal] = useState(false);

	const invoiceHistoryEntries = lotHistory.filter(
		({ entity }) => entity === 'Invoice'
	) as KernelLotHistoryInvoiceEntry[];

	const [currentInvoiceHistoryEntry] = invoiceHistoryEntries;

	const sellerStatementHistoryEntries = lotHistory.filter(
		({ entity }) => entity === 'SellerStatement'
	) as KernelLotHistorySellerStatementEntry[];

	const [currentSellerStatementHistoryEntry] = sellerStatementHistoryEntries;

	const buyersPremiumAsPercent = lot.buyer_premium / 100;

	const currentPriceRow = lot.current_price
		? { title: 'Current Price', content: formatCurrency(lot.current_price) }
		: { title: 'Minimum Bid Price', content: formatCurrency(lot.min_bid_price) };

	const currentPriceBuyersPremium =
		(lot.current_price ?? lot.min_bid_price) * buyersPremiumAsPercent;

	const finalPriceRow = { title: 'Final Price', content: formatCurrency(lot.final_price) };
	const finalPriceBuyersPremium = lot.final_price * buyersPremiumAsPercent;

	const commissionAsPercent = (lot.commission / 100) * -1; // Negative commission means seller gets more

	const currentCommissionDollarAmount =
		(lot.current_price ?? lot.min_bid_price) * commissionAsPercent;
	const currentPriceWithCommission =
		(lot.current_price ?? lot.min_bid_price) + currentCommissionDollarAmount;
	const finalCommissionDollarAmount = lot.final_price * commissionAsPercent;
	const finalPriceWithCommission = lot.final_price + finalCommissionDollarAmount;
	const commissionDollarAmount =
		lot.status === 'Completed_Sold' ? finalCommissionDollarAmount : currentCommissionDollarAmount;
	const priceWithCommission =
		lot.status === 'Completed_Sold' ? finalPriceWithCommission : currentPriceWithCommission;

	const buyersPremiumPrice =
		lot.status === 'Completed_Sold' ? finalPriceBuyersPremium : currentPriceBuyersPremium;

	const priceRow = lot.status === 'Completed_Sold' ? finalPriceRow : currentPriceRow;

	const baseRows: DefinitionListRow[] = [
		priceRow,
		{ title: 'Estimated Value', content: formatCurrency(lot.estimated_value) },
		{
			title: 'Consignment Commission',
			content: (
				<Tooltip
					id="consignment-commission"
					content={`${formatCurrency(commissionDollarAmount)} - Seller nets ${formatCurrency(
						priceWithCommission
					)}`}
				>
					{lot.commission}%
				</Tooltip>
			),
		},
		{ title: 'Reserve Amount', content: formatCurrency(lot.reserve_amount) },
		{ title: 'Internal Reserve Amount', content: formatCurrency(lot.internal_reserve_amount) },
		{
			title: `Buyer's Premium`,
			content: (
				<Tooltip
					id="buyer-premium-explainer"
					content={`${formatCurrency(buyersPremiumPrice)} - Inherited from auction`}
				>
					<div className="flex">
						<span className=" text-neutral2">{`${lot.buyer_premium}%`}</span>
					</div>
				</Tooltip>
			),
		},
	];

	const optionalRows: DefinitionListRow[] = [
		{
			title: "Winner's Name",
			content: winner ? (
				<Tooltip
					id="winners-name"
					content={`View ${winner.givenName} ${winner.familyName}'s profile`}
				>
					<Link
						className="block link"
						to={`/usermanagement/customer/${winner.id}/customer-overview`}
					>
						{`${winner.givenName} ${winner.familyName}`}
					</Link>
				</Tooltip>
			) : (
				''
			),
		},
		{
			title: 'Invoice Number',
			content: currentInvoiceHistoryEntry ? (
				<Tooltip
					id="invoice-number"
					content={`View invoice number ${currentInvoiceHistoryEntry.invoiceNumber}`}
				>
					<Link className="block link" to={`/orders/${currentInvoiceHistoryEntry.invoiceNumber}`}>
						{currentInvoiceHistoryEntry.invoiceNumber}
					</Link>
				</Tooltip>
			) : (
				''
			),
		},
		{
			title: 'Seller Statement Number',
			content: currentSellerStatementHistoryEntry ? (
				<Tooltip
					id="invoice-number"
					content={`View seller statement number ${currentSellerStatementHistoryEntry.sellerStatementNumber}`}
				>
					<Link
						className="block link"
						to={`/auctions/${currentSellerStatementHistoryEntry.auctionId}/statements/${currentSellerStatementHistoryEntry.sellerStatementNumber}`}
					>
						{currentSellerStatementHistoryEntry.sellerStatementNumber}
					</Link>
				</Tooltip>
			) : (
				''
			),
		},
	];

	const rows = [...baseRows, ...optionalRows.filter(({ content }) => content !== '')];

	const [shouldShowEditLotFinancialDetailsModal, setShouldShowEditLotFinancialDetailsModal] =
		useState(false);

	return (
		<Card>
			<LoadingWrapper queryStatuses={[lotStatus]}>
				<div className="flex justify-between">
					<h2 className="text-display5">Financial Details</h2>
					<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
						<Button
							variant="text"
							size="md"
							icon="Edit"
							onClick={() => setShouldShowEditLotFinancialDetailsModal(true)}
						/>
						{shouldShowEditLotFinancialDetailsModal && (
							<EditLotFinancialDetailsModal
								onClose={() => setShouldShowEditLotFinancialDetailsModal(false)}
								lot={lot}
							/>
						)}
					</RestrictedArea>
				</div>
				<DefinitionList rows={rows} />
				<div className="flex flex-row justify-between mt-4">
					<Button
						className="mr-4"
						fullWidth
						size="md"
						variant="secondary"
						onClick={() => setShouldShowItemHistoryModal(true)}
					>
						View item history
					</Button>
					<Button
						fullWidth
						size="md"
						variant="secondary"
						onClick={() => setShouldShowBiddingHistoryModal(true)}
					>
						View bidding history
					</Button>
				</div>
			</LoadingWrapper>
			{shouldShowBiddingHistoryModal && (
				<Modal
					className="mb-8 overflow-y-auto"
					title={`Bidding history for "${lot.title}"`}
					onClose={() => setShouldShowBiddingHistoryModal(false)}
				>
					<BidHistoryCard auctionId={lot.auction_id} />
				</Modal>
			)}
			{shouldShowItemHistoryModal && (
				<LotHistoryModal lotId={lotId} onClose={() => setShouldShowItemHistoryModal(false)} />
			)}
		</Card>
	);
};

export default LotFinancialDetails;

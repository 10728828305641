import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Input } from '../../../../prizm-ui/Input';
import { Select, Option } from '../../../../prizm-ui/Select';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import {
	CATEGORIES,
	GRADING_COMPANIES,
	ItemType,
	ITEM_TYPES,
	KernelLot,
	LotCategory,
	LotSubCategory,
	SUB_CATEGORIES,
} from '../../../../services/lotsService/lotsService.model';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotDetailsModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const [draftLot, setDraftLot] = useState(lot);
	const updateDraftLot = (deltas: Partial<KernelLot>) => setDraftLot({ ...draftLot, ...deltas });

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () =>
		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated lot details');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating lot details. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			title="Edit Lot Details"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={!isEqual(lot, draftLot)}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Select
					className="mr-0 mb-4 lg:mr-4 lg:mb-0 w-full"
					id="category"
					onChange={({ target: { value } }) => {
						updateDraftLot({
							category: value as LotCategory,
							sub_category: undefined,
						});
					}}
					key="lot-editor-category"
					label="Category"
					value={draftLot.category}
				>
					{CATEGORIES.map((category, index) => (
						<Option index={index} value={category} key={category}>
							{category}
						</Option>
					))}
				</Select>
				<Select
					className="mr-0 mb-4 lg:mr-4 lg:mb-0 w-full"
					id="item-type"
					key="lot-editor-item-type"
					label="Item Type"
					onChange={({ target: { value } }) => updateDraftLot({ item_type: value as ItemType })}
					value={draftLot.item_type}
				>
					{ITEM_TYPES.map((itemType, index) => (
						<Option index={index} value={itemType} key={itemType}>
							{itemType}
						</Option>
					))}
				</Select>
				<Select
					className="w-full"
					id="subCategory"
					key="lot-editor-sub-category"
					onChange={({ target: { value } }) =>
						updateDraftLot({ sub_category: value as LotSubCategory })
					}
					label="Sub-Category"
					value={draftLot.sub_category}
				>
					{SUB_CATEGORIES.map((subCategory, index) => (
						<Option index={index} value={subCategory} key={subCategory}>
							{subCategory}
						</Option>
					))}
				</Select>
				<div className="grid grid-cols-2 gap-4">
					<Select
						id="grading_company"
						value={draftLot.grading_company}
						onChange={({ target: { value } }) => updateDraftLot({ grading_company: value })}
						label="Grading Company"
					>
						{GRADING_COMPANIES.map((gradingCompanyName, index) => (
							<Option index={index} value={gradingCompanyName} key={gradingCompanyName}>
								{gradingCompanyName}
							</Option>
						))}
					</Select>
					<Input
						id="grade"
						value={draftLot.grade}
						label="Grade"
						type="number"
						onChange={({ target: { value } }) => updateDraftLot({ grade: value })}
					/>
					<Input
						id="year"
						label="Year"
						value={draftLot.year}
						type="number"
						onChange={({ target: { value } }) => updateDraftLot({ year: value })}
					/>
					<Input
						id="memorabilia_type"
						label="Memorabilia Type"
						value={draftLot.memorabilia_type}
						onChange={({ target: { value } }) => updateDraftLot({ memorabilia_type: value })}
					/>
					<Input
						id="sport_memorabilia_type"
						label="Sport Memorabilia Type"
						value={draftLot.sport_memorabilia_type}
						onChange={({ target: { value } }) => updateDraftLot({ sport_memorabilia_type: value })}
					/>
					<Input
						id="player_subject"
						label="Player/Subject"
						value={draftLot.player_subject}
						onChange={({ target: { value } }) => updateDraftLot({ player_subject: value })}
					/>
					<Input
						id="sport"
						label="Sport"
						value={draftLot.sport}
						onChange={({ target: { value } }) => updateDraftLot({ sport: value })}
					/>

					<Input
						id="era"
						label="Era"
						value={draftLot.era}
						onChange={({ target: { value } }) => updateDraftLot({ era: value })}
					/>
					<Input
						id="decade"
						label="Decade"
						value={draftLot.decade}
						onChange={({ target: { value } }) => updateDraftLot({ decade: value })}
					/>
					<Input
						id="season"
						label="Season"
						value={draftLot.season}
						onChange={({ target: { value } }) => updateDraftLot({ season: value })}
					/>
					<Input
						id="event"
						label="Event"
						value={draftLot.event}
						onChange={({ target: { value } }) => updateDraftLot({ event: value })}
					/>
					<Input
						id="autograph_type"
						label="Autograph Type"
						value={draftLot.autograph_type}
						onChange={({ target: { value } }) => updateDraftLot({ autograph_type: value })}
					/>
					<Input
						id="autograph_grade"
						value={lot.autograph_grade}
						label="Autograph Grade"
						type="number"
						onChange={({ target: { value } }) => updateDraftLot({ autograph_grade: value })}
					/>
					<Input
						id="team"
						label="Team"
						value={draftLot.team}
						onChange={({ target: { value } }) => updateDraftLot({ team: value })}
					/>
					<Input
						id="manufacturer"
						label="Manufacturer"
						value={draftLot.manufacturer}
						onChange={({ target: { value } }) => updateDraftLot({ manufacturer: value })}
					/>
					<Input
						id="collection"
						label="Collection"
						value={draftLot.collection}
						onChange={({ target: { value } }) => updateDraftLot({ collection: value })}
					/>
					<Input
						id="set"
						label="Set"
						value={draftLot.set}
						onChange={({ target: { value } }) => updateDraftLot({ set: value })}
					/>
					<Input
						id="auto_issuer"
						label="Autograph Issuer"
						value={draftLot.auto_issuer}
						onChange={({ target: { value } }) => updateDraftLot({ auto_issuer: value })}
					/>
					<Input
						id="spec_serial_number"
						label="Spec Serial Number"
						value={draftLot.spec_serial_number}
						onChange={({ target: { value } }) => updateDraftLot({ spec_serial_number: value })}
					/>
				</div>
			</div>
		</ActionModal>
	);
};

export default EditLotDetailsModal;

import { useField } from 'formik';
import React, { useState } from 'react';
import { omit } from 'lodash';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import { searchBidder } from '../../../../../../AwsUtils/ElasticSearchApiUtils';
import Dropdown from '../../../../../Dropdown';
import { PlaceBidFormInputProps } from '../PlaceBidForm.model';
import { BidderDropdownMenuItem } from '../../PlaceBidModal.model';

const BidderSelector = ({
	minimumViableBid,
	setSelectedBidder,
	...formikProps
}: PlaceBidFormInputProps & {
	setSelectedBidder: (selectedBidder: BidderDropdownMenuItem) => void;
}) => {
	const [userSearchQuery, setUserSearchQuery] = useState<string>();
	const [field, _, helpers] = useField({
		...omit(formikProps, 'form'),
		name: 'selectedUserOption',
	});

	const { value: selectedUserOption } = field;

	const { setValue } = helpers;

	const loadOptions = async (): Promise<BidderDropdownMenuItem[]> => {
		const searchResults = await searchBidder(userSearchQuery);

		return searchResults.map(
			({
				user_id,
				given_name,
				family_name,
				email,
				bidding_power,
			}: {
				user_id: string;
				given_name: string;
				family_name: string;
				email: string;
				bidding_power: number;
			}) => ({
				value: { userId: user_id, biddingPower: bidding_power },
				label: `${given_name} ${family_name} (${email})`,
			})
		);
	};

	return (
		<>
			<label htmlFor="selectedUserOption">Bidder Name</label>
			<Dropdown
				kind="async"
				onChange={(selectedOption) => {
					setValue(selectedOption); // This sets the value for Formik
					setSelectedBidder(selectedOption as BidderDropdownMenuItem); // Tracking outside of Formik too in order to validate maxBid against biddingPower
				}}
				loadOptions={loadOptions}
				inputValue={userSearchQuery}
				onInputChange={(newValue) => {
					setUserSearchQuery(newValue);
				}}
				placeholder="Search for bidder..."
				name="selectedUserOption"
			/>
			{selectedUserOption && (
				<div className="mt-1">
					<span
						className={`text-xs italic ${
							selectedUserOption.value.biddingPower < minimumViableBid ? 'text-red-600' : ''
						}`}
					>
						{selectedUserOption.value.biddingPower < minimumViableBid ? (
							<span>
								Bidding Power Too Low: {formatCurrency(selectedUserOption.value.biddingPower)}
							</span>
						) : (
							<span>Bidding Power: {formatCurrency(selectedUserOption.value.biddingPower)}</span>
						)}
					</span>
				</div>
			)}
		</>
	);
};

export default BidderSelector;

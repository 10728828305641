import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { uniqBy } from 'lodash';
import config from '../../config';
import { CognitoUser } from './getEmployees.model';
import { BaseUser } from './userService.model';

export const getEmployeesBySearchQuery = async ({
	searchQuery,
	limit = 10,
}: {
	searchQuery: string;
	limit?: number;
}): Promise<BaseUser[]> => {
	const credentials = await Auth.currentCredentials();
	const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(credentials);

	const allUsersByAttribute = await Promise.all(
		['given_name', 'family_name', 'email', 'sub'].map((attribute) =>
			cognitoIdentityServiceProvider
				.listUsers({
					UserPoolId: config.cognito.USER_POOL_ID || '',
					Filter: `${attribute} ^= "${searchQuery}"`,
					Limit: limit,
				})
				.promise()
		)
	);

	const allUsers = allUsersByAttribute
		.map(({ Users: users }) => users as unknown as CognitoUser[])
		.concat()
		.flat()
		.map((cognitoUser) => ({
			id: cognitoUser.Username,
			givenName: cognitoUser.Attributes.find(({ Name }) => Name === 'given_name')?.Value || '',
			familyName: cognitoUser.Attributes.find(({ Name }) => Name === 'family_name')?.Value || '',
			email: cognitoUser.Attributes.find(({ Name }) => Name === 'email')?.Value || '',
		}))
		.map((user) => ({
			...user,
			givenName:
				!user.givenName || user.givenName.toLowerCase() === 'undefined' ? '' : user.givenName,
			familyName:
				!user.familyName || user.familyName.toLowerCase() === 'undefined' ? '' : user.familyName,
		}));

	return uniqBy(allUsers, ({ id }) => id);
};

import { useState } from 'react';
import {
	GRADING_COMPANIES,
	KernelLot,
	LotCategory,
	LotSubCategory,
} from '../../../../services/lotsService/lotsService.model';
import LotCategories from '../LotCategories';
import SaveFooter from './SaveFooter';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import { updateLot } from '../../../../services/lotsService';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import { getChangedFields, getTextContentForMissingRequiredFields } from './LotEditorView.utils';
import {
	AUTHENTICATION_STATUS_OPTIONS,
	BLANK_AUTHENTICATION_STATUS_OPTION,
} from './LotEditorView.model';
import { useParams } from 'react-router-dom';
import Label from '../../../Label';
import Card from '../../../Card';
import LotEditorField from './LotEditorField';
import Dropdown from '../../../Dropdown';
import { Textarea } from '../../../../prizm-ui/Textarea';
import { EDITOR_ROLES } from '../../../../services/userService/userService.model';
import { formatDate } from '../../../../utils/formatters';
import RestrictedArea from '../../../RestrictedArea';
import Checkbox from '../../../Checkbox';
import { SimpleDropdownMenuItem } from '../../../Dropdown/Dropdown.model';
import { UserRoleName } from '../../../../services/userService/userService.model';
import useUserRole from '../../../../queries/useUserRole';

const LotEditorView = ({
	lotDisplayStatus = '',
}: {
	lotDisplayStatus?: 'Active' | 'Inactive' | '';
}) => {
	const { lotId } = useParams<{ lotId: string }>();
	const { data: currentLot = {} as KernelLot, updateLot: updateLotInCache } =
		useIndividualLot(lotId);

	const [draftLot, setDraftLot] = useState<Partial<KernelLot>>({ lot_id: lotId });

	const updateLotField = (fieldsToUpdate: Partial<KernelLot>) =>
		setDraftLot({ ...draftLot, ...fieldsToUpdate });

	const textContentForMissingRequiredFields = getTextContentForMissingRequiredFields(currentLot);

	const changedFields = getChangedFields({ draftLot, currentLot });
	const hasChanges = Object.keys(changedFields).length > 0;

	const cancelChanges = () => {
		setDraftLot(currentLot);
	};

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, currentLot.lot_id],
		mutationFn: (fieldsToUpdate: Partial<KernelLot>) => updateLot(fieldsToUpdate),
	});

	const updateExistingLot = () => {
		doUpdateLot(
			{ lot_id: currentLot.lot_id, ...changedFields },
			{
				onSuccess: (updatedLot: KernelLot) => {
					showSuccessToast('Successfully updated lot.');
					updateLotInCache(updatedLot);
					setDraftLot({ lot_id: lotId });
				},
				onError: () => {
					showErrorToast('Something went wrong creating the lot. Please try again later.');
				},
			}
		);
	};

	const isLoading = updateLotStatus === 'loading';

	const { data: currentUserRole = 'Unknown' as UserRoleName } = useUserRole();

	const canAdminFinanceOverride =
		currentLot.status === 'Completed_Sold' && ['Admin', 'Finance'].includes(currentUserRole);

	const canEdit = currentLot.status !== 'Completed_Sold' || canAdminFinanceOverride;

	// intentionally defined within the component so we don't have to include currentLot/draftLot as params or create a class
	const getFieldValue = (fieldName: keyof KernelLot): undefined | string | number => {
		// allow for falsy values in updates
		if (Object.keys(draftLot).includes(fieldName)) {
			return draftLot[fieldName];
		}

		return currentLot[fieldName];
	};

	const selectedAuthenticationStatusOption: SimpleDropdownMenuItem | undefined =
		AUTHENTICATION_STATUS_OPTIONS.find(({ value }) => {
			const authenticationStatus =
				draftLot.authentication_status ?? currentLot.authentication_status;
			return value === authenticationStatus;
		});
	const defaultAuthenticationStatusOption: SimpleDropdownMenuItem =
		selectedAuthenticationStatusOption ?? BLANK_AUTHENTICATION_STATUS_OPTION;

	return (
		<Card>
			<div className="flex justify-between px-4">
				<div>
					<h2 className="text-display4">Lot Details</h2>
					<div className="text-subtitle1">{currentLot.title}</div>
				</div>
			</div>

			{/* Missing fields warning */}
			{textContentForMissingRequiredFields && textContentForMissingRequiredFields.length > 0 && (
				<div className="mt-2 mb-8 px-2 text-body2">
					<div className="text-error1">Warning: Some required fields are missing</div>
					<div>Please choose valid values for: {textContentForMissingRequiredFields}</div>
				</div>
			)}
			{/* Lot details editor */}
			<div className="my-4 px-2">
				<div className="mt-4 flex flex-col lg:flex-row">
					<div className="mb-4 lg:mb-0 mr-4 w-24">
						<Label>Status</Label>
						<div className="lg:mt-1 text-body1">{lotDisplayStatus}</div>
					</div>
					<div className="mb-4 lg:mb-0">
						<Label>End Date</Label>
						<div className="lg:mt-1 text-body1">
							{formatDate(getFieldValue('end_timestamp') as string)}
						</div>
					</div>
				</div>
				<div className="mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
					<LotCategories
						currentCategory={(getFieldValue('category') ?? '') as LotCategory}
						currentItemType={(getFieldValue('item_type') ?? '') as string}
						currentSubcategory={getFieldValue('sub_category' ?? '') as LotSubCategory}
						updateLotField={updateLotField}
					/>
				</div>
				<div className="mt-4 grid grid-cols-2 lg:grid-cols-3 gap-4">
					<LotEditorField
						className="mb-4 lg:mb-0"
						name="lot-number"
						label="Lot Number"
						isErrored={hasChanges && !getFieldValue('lot_number')}
						value={getFieldValue('lot_number')}
						onChange={({ target: { value } }) =>
							updateLotField({ lot_number: parseInt(value, 10) || undefined })
						}
						type="number"
					/>
					<LotEditorField
						className="mb-4 lg:mb-0"
						name="serial-number"
						label="Serial Number"
						isErrored={hasChanges && !getFieldValue('serial_number')}
						value={getFieldValue('serial_number')}
						onChange={({ target: { value } }) =>
							updateLotField({ serial_number: value || undefined })
						}
					/>
					<LotEditorField
						className="mb-4 lg:mb-0"
						name="contract-id"
						label="Contract ID"
						isErrored={hasChanges && !getFieldValue('contract_id')}
						value={getFieldValue('contract_id')}
						onChange={({ target: { value } }) =>
							updateLotField({ contract_id: value || undefined })
						}
					/>
					<LotEditorField
						name="session"
						value={getFieldValue('session')}
						onChange={({ target: { value } }) => updateLotField({ session: value })}
					/>
					<LotEditorField
						name="room"
						value={getFieldValue('room')}
						onChange={({ target: { value } }) => updateLotField({ room: value })}
					/>
					<LotEditorField
						name="bin"
						value={getFieldValue('bin')}
						onChange={({ target: { value } }) => updateLotField({ bin: value })}
					/>
					<div className="mb-4 lg:mb-0 lg:mr-4">
						<Label htmlFor="authentication-status">Authentication Status</Label>
						<Dropdown
							allowedRoles={EDITOR_ROLES}
							defaultOption={defaultAuthenticationStatusOption}
							name="authentication_status"
							value={selectedAuthenticationStatusOption}
							options={AUTHENTICATION_STATUS_OPTIONS}
							onChange={({ value }) => updateLotField({ authentication_status: value as string })}
						/>
					</div>
				</div>
				<div className="mt-4 flex flex-col lg:flex-row lg:w-1/2 w-full">
					<div className="flex-grow">
						<Label htmlFor="authentication-notes">Authentication Notes</Label>
						<Textarea
							className="w-full"
							defaultValue={getFieldValue('authentication_notes')}
							id="authentication-notes"
							label=""
							rows={3}
							onChange={({ currentTarget: { value } }) =>
								updateLotField({ authentication_notes: value })
							}
						/>
					</div>
				</div>
				<div className="my-8">
					<h3 className="text-subtitle2">Structured fields for this lot</h3>
					<div className="mt-4 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
						<LotEditorField
							name="player_subject"
							value={getFieldValue('player_subject')}
							label="Player/Subject"
							onChange={({ target: { value } }) => updateLotField({ player_subject: value })}
						/>
						<LotEditorField
							name="sport"
							value={getFieldValue('sport')}
							onChange={({ target: { value } }) => updateLotField({ sport: value })}
						/>
						<LotEditorField
							name="team"
							value={getFieldValue('team')}
							onChange={({ target: { value } }) => updateLotField({ team: value })}
						/>
						<LotEditorField
							name="collection"
							value={getFieldValue('collection')}
							onChange={({ target: { value } }) => updateLotField({ collection: value })}
						/>
						<LotEditorField
							name="manufacturer"
							value={getFieldValue('manufacturer')}
							onChange={({ target: { value } }) => updateLotField({ manufacturer: value })}
						/>
						<LotEditorField
							name="set"
							value={getFieldValue('set')}
							onChange={({ target: { value } }) => updateLotField({ set: value })}
						/>
						<LotEditorField
							name="year"
							value={getFieldValue('year')}
							onChange={({ target: { value } }) => updateLotField({ year: value })}
						/>
						<div className="mr-4">
							<Label htmlFor="grading-company" className="mr-4">
								Grading Company
							</Label>
							<Dropdown
								allowedRoles={EDITOR_ROLES}
								options={GRADING_COMPANIES.map((itemType: string) => ({
									label: itemType,
									value: itemType ?? 'Unset',
								}))}
								name="grading-company"
								onChange={({ value }) => updateLotField({ grading_company: value as string })}
								defaultOption={{
									label: (getFieldValue('grading_company') ?? 'Unset') as string,
									value: (getFieldValue('grading_company') ?? '') as string,
								}}
							/>
						</div>
						<LotEditorField
							name="lot_grade"
							value={getFieldValue('lot_grade')}
							onChange={({ target: { value } }) => updateLotField({ lot_grade: value })}
							label="Grade"
						/>
						<LotEditorField
							name="authentication"
							value={getFieldValue('authentication')}
							onChange={({ target: { value } }) => updateLotField({ authentication: value })}
						/>
						<LotEditorField
							name="item_serial_number"
							value={getFieldValue('item_serial_number')}
							onChange={({ target: { value } }) => updateLotField({ item_serial_number: value })}
						/>
						<LotEditorField
							name="event"
							value={getFieldValue('event')}
							onChange={({ target: { value } }) => updateLotField({ event: value })}
						/>
						<LotEditorField
							name="autograph_type"
							value={getFieldValue('autograph_type')}
							onChange={({ target: { value } }) => updateLotField({ autograph_type: value })}
						/>
						<LotEditorField
							name="autograph_grade"
							value={getFieldValue('autograph_grade')}
							onChange={({ target: { value } }) => updateLotField({ autograph_grade: value })}
						/>
						<LotEditorField
							name="memorabilia_type"
							value={getFieldValue('memorabilia_type')}
							onChange={({ target: { value } }) => updateLotField({ memorabilia_type: value })}
						/>
						<LotEditorField
							name="parallel_type"
							value={getFieldValue('parallel_type')}
							onChange={({ target: { value } }) => updateLotField({ parallel_type: value })}
						/>
						<LotEditorField
							name="era"
							value={getFieldValue('era')}
							onChange={({ target: { value } }) => updateLotField({ era: value })}
						/>
						<LotEditorField
							name="decade"
							value={getFieldValue('decade')}
							onChange={({ target: { value } }) => updateLotField({ decade: value })}
						/>
						<LotEditorField
							name="season"
							value={getFieldValue('season')}
							onChange={({ target: { value } }) => updateLotField({ season: value })}
						/>
						<LotEditorField
							name="sport_memorabilia_type"
							value={getFieldValue('sport_memorabilia_type')}
							onChange={({ target: { value } }) =>
								updateLotField({ sport_memorabilia_type: value })
							}
						/>
						<LotEditorField
							name="card_lot_type"
							value={getFieldValue('card_lot_type')}
							onChange={({ target: { value } }) => updateLotField({ card_lot_type: value })}
						/>
						<LotEditorField
							name="cert_number"
							value={getFieldValue('cert_number')}
							onChange={({ target: { value } }) => updateLotField({ cert_number: value })}
						/>
					</div>
				</div>
				<div className="mt-8">
					<h3 className="text-subtitle2">Financial Information</h3>
					<div className="mt-4">
						<Label>{`Buyer's Premium (Inherited From Auction)`}</Label>
						<div className="lg:mt-1 text-body1">{getFieldValue('buyer_premium')}</div>
					</div>
					<div className="mt-4 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
						<LotEditorField
							className="mt-4 lg:mt-0"
							name="estimated-value"
							label="Estimated Value"
							value={getFieldValue('estimated_value')}
							onChange={({ target: { value } }) =>
								updateLotField({ estimated_value: Number(value) ?? undefined })
							}
							type="number"
						/>
						<LotEditorField
							className="mt-4 lg:mt-0"
							name="min_bid_price"
							label="Minimum Bid Price"
							value={getFieldValue('min_bid_price')}
							onChange={({ target: { value } }) =>
								updateLotField({ min_bid_price: parseInt(value, 10) || undefined })
							}
							isErrored={typeof getFieldValue('min_bid_price') === 'undefined'}
							type="number"
						/>
						<LotEditorField
							className="mt-4 lg:mt-0"
							name="reserve_amount"
							value={getFieldValue('reserve_amount')}
							onChange={({ target: { value } }) =>
								updateLotField({ reserve_amount: parseInt(value, 10) || undefined })
							}
							type="number"
						/>
						<LotEditorField
							className="mt-4 lg:mt-0"
							disabled={!canEdit}
							name="commission"
							label="Consignment Commission"
							value={getFieldValue('commission')}
							onChange={({ target: { value } }) =>
								updateLotField({ commission: Number(value) ?? undefined })
							}
							type="number"
						/>
					</div>
					<div className="mt-4">
						<Checkbox
							allowedRoles={EDITOR_ROLES}
							name="requires-custom-shipping"
							label="Requires Custom Shipping"
							checked={getFieldValue('invoice_status') === 'Blocked'}
							onChange={(checked: boolean) =>
								updateLotField({ invoice_status: checked ? 'Blocked' : 'Pending_Approval' })
							}
						/>
					</div>
				</div>
				<div className="my-8">
					<h3 className="text-subtitle2">Internal Notes</h3>
					<div>
						<small>This will only be visible to internal employees</small>
					</div>
					<div className="mt-4">
						<Textarea
							allowedRoles={EDITOR_ROLES}
							name="internal_notes"
							value={getFieldValue('internal_notes')}
							onChange={({ target: { value } }) => updateLotField({ internal_notes: value })}
						/>
					</div>
				</div>
			</div>
			<RestrictedArea allowedRoles={EDITOR_ROLES}>
				<SaveFooter
					handleSave={updateExistingLot}
					handleCancel={cancelChanges}
					isLoading={isLoading}
					isDisabled={!hasChanges || isLoading}
				/>
			</RestrictedArea>
		</Card>
	);
};

export default LotEditorView;

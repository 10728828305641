import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AddCommentModal from '../OrderComments/AddCommentModal/AddCommentModal';
import Card from '../../../../../../components/Card';
import Button from '../../../../../../components/Button';
import EditShippingAddressModal from './EditShippingAddressModal';
import Label from '../../../../../../components/Label';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';
import useUserById from '../../../../../../queries/useUserById';
import { CheckoutOrder } from '../../../../../../services/ordersService/ordersService.model';
import { User } from '../../../../../../services/userService/userService.model';
import config from '../../../../../../config';
import { Tooltip } from '../../../../../../prizm-ui/Tooltip';

const OrderCustomerInfo = () => {
	const { orderNumber } = useParams<{ orderNumber: string }>();
	const { data: order = {} as CheckoutOrder } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});
	const { data: winner = {} as User } = useUserById(order.winnerId);

	const address = order?.costMetadata?.address || {};
	const [shouldShowAddCommentModal, setShouldShowAddCommentModal] = useState(false);
	const [shouldShowEditShippingAddressModal, setShouldShowEditShippingAddressModal] =
		useState(false);

	const getStripeAccountURL = () => {
		const BASE_URL = 'https://dashboard.stripe.com';
		const PATH_CUSTOMERS = 'customers';
		const PATH_TEST = 'test';

		const HEAD = `${BASE_URL}`;
		const TAIL =
			config.environment === 'production'
				? `${PATH_CUSTOMERS}/${winner.stripeCustomerId}`
				: `${PATH_TEST}/${PATH_CUSTOMERS}/${winner.stripeCustomerId}`;

		return `${HEAD}/${TAIL}`;
	};

	const canEditShippingAddress =
		order?.invoiceStatus !== 'Paid' && order?.invoiceStatus !== 'User_Submitted';

	return (
		<Card>
			<div className="flex items-baseline justify-between">
				<h2 className="mb-4 text-subtitle1">Customer Info</h2>
			</div>
			<div>
				<div>
					<Tooltip
						content={`View ${winner.givenName}'s Profile`}
						id={`view-${winner.givenName}'s-profile`}
					>
						<Link className="link" to={`/usermanagement/customer/${winner.id}/customer-overview`}>
							<div className="text-body1 font-bold">{`${winner.givenName} ${winner.familyName}`}</div>
						</Link>
					</Tooltip>
				</div>

				<div className="text-body1">
					<Tooltip content={`Email ${winner.givenName}`} id={`email-${winner.givenName}`}>
						<a className="link" href={`mailto:${winner.email}`}>
							{winner.email}
						</a>
					</Tooltip>
				</div>
				<div className="text-body2">
					<a className="link" href={getStripeAccountURL()} target="_blank" rel="noreferrer">
						View Stripe Account
					</a>
				</div>
			</div>
			<div className="mt-4">
				<div>
					<Label>Shipping To</Label>
				</div>
				<div className="text-body2">{address.line1}</div>
				<div className="text-body2">{address.line2}</div>
				<div className="text-body2">{`${address.city}, ${address.state} ${address.postalCode} `}</div>
				<div className="text-body2">{`${address.country}`}</div>
				<div className="flex flex-column items-start mt-4">
					<Button
						className="link pl-0 pr-0 text-caption2"
						disabled={!canEditShippingAddress}
						kind="tertiary"
						onClick={() => setShouldShowEditShippingAddressModal(true)}
						isInline={true}
						title={
							canEditShippingAddress
								? 'Edit Shipping Address'
								: `Cannot edit shipping address with "${order?.invoiceStatus}" invoice status`
						}
					>
						Edit Shipping Address
					</Button>
					<Button
						className="link pl-0 pr-0 text-caption2"
						kind="tertiary"
						onClick={() => setShouldShowAddCommentModal(true)}
						isInline={true}
					>
						Add Shipping Comment
					</Button>
				</div>
			</div>
			{shouldShowEditShippingAddressModal && (
				<EditShippingAddressModal
					currentAddress={address}
					orderNumber={orderNumber}
					onClose={() => setShouldShowEditShippingAddressModal(false)}
				/>
			)}
			{shouldShowAddCommentModal && (
				<AddCommentModal
					newCommentKind="order-shipping-comment"
					orderNumber={order.invoiceNumber}
					title="Add Shipping Comment"
					onClose={() => setShouldShowAddCommentModal(false)}
				/>
			)}
		</Card>
	);
};

export default OrderCustomerInfo;

import { UserRoleName, UserRoles } from '../../../services/userService/userService.model';

export enum AdminPageEnum {
	AuctionDashboard = 'AuctionDashboard',
	AuctionManager = 'AuctionManager',
	AuctionPreview = 'AuctionPreview',
	AuctionPublisher = 'AuctionPublisher',
	CarouselEditor = 'CarouselEditor',
	FixedPriceListings = 'FixedPriceListings',
	FixedPriceOrderSummary = 'FixedPriceOrderSummary',
	FixedPriceSellerSummary = 'FixedPriceSellerSummary',
	InventoryManager = 'InventoryManager',
	Listings = 'Listings',
	LiveAuctionDashboard = 'LiveAuctionDashboard',
	ManageCustomers = 'ManageCustomers',
	ManageEmployees = 'ManageEmployees',
	PerformanceDashboard = 'PerformanceDashboard',
	Reports = 'Reports',
	BulkUploads = 'BulkUploads',
	SellerSummary = 'SellerSummary',
	ShipmentManager = 'ShipmentManager',
	UserSiteViewer = 'UserSiteViewer',
	WinnerSummary = 'WinnerSummary',
}

export type AdminPage = `${AdminPageEnum}`;

export const PERMITTED_ROLES_PER_PAGE: { [key in AdminPage]: UserRoleName[] } = {
	AuctionDashboard: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
	],
	AuctionManager: ['AuctionManager', 'Editorial', 'Inventory', 'Finance'],
	AuctionPreview: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
		'Photography',
		'Shipping',
	],
	AuctionPublisher: ['AuctionManager', 'Inventory'],
	CarouselEditor: ['AuctionManager', 'Editorial'],
	FixedPriceListings: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
		'Photography',
		'Shipping',
	],
	FixedPriceOrderSummary: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Finance',
		'Inventory',
	],
	FixedPriceSellerSummary: ['Consignment', 'CustomerSupport', 'Finance', 'Inventory'],

	InventoryManager: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
		'Photography',
		'Shipping',
	],
	Listings: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
		'Photography',
		'Shipping',
	],
	LiveAuctionDashboard: [],
	ManageCustomers: ['AuctionManager', 'Consignment', 'CustomerSupport', 'Finance', 'Inventory'],
	ManageEmployees: ['CustomerSupport'],
	PerformanceDashboard: [
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Editorial',
		'Finance',
		'Inventory',
		'Photography',
		'Shipping',
	],
	Reports: [UserRoles.CustomerSupport, UserRoles.Finance],
	BulkUploads: [UserRoles.Finance],
	SellerSummary: ['AuctionManager', 'Consignment', 'CustomerSupport', 'Finance', 'Inventory'],
	ShipmentManager: ['AuctionManager', 'CustomerSupport', 'Inventory', 'Shipping'],
	UserSiteViewer: ['AuctionManager', 'CustomerSupport'],
	WinnerSummary: ['AuctionManager', 'Consignment', 'CustomerSupport', 'Finance', 'Inventory'],
};

export const PAGE_BASE_PATHS: { [key in AdminPage]: string } = {
	AuctionDashboard: '/auctions',
	AuctionManager: '/auctions',
	AuctionPreview: '/auctions',
	AuctionPublisher: '/auctions',
	CarouselEditor: '/customize',
	FixedPriceListings: '/fixed-price',
	FixedPriceOrderSummary: '/fixed-price',
	FixedPriceSellerSummary: '/fixed-price',
	InventoryManager: '/auctions',
	Listings: '/inventory',
	LiveAuctionDashboard: '/Performance',
	ManageCustomers: '/usermanagement',
	ManageEmployees: '/usermanagement',
	PerformanceDashboard: '/Performance',
	Reports: '/finance',
	BulkUploads: '/finance',
	SellerSummary: '/auctions',
	ShipmentManager: '/auctions',
	UserSiteViewer: '/customize',
	WinnerSummary: '/auctions',
};

enum NavSectionEnum {
	PerformanceDashboard = 'Performance Dashboard',
	CustomizeHomepage = 'Customize Homepage',
	Inventory = 'Inventory',
	ManageFixedPrice = 'Manage Fixed Price',
	ManageAuctions = 'Manage Auctions',
	Finance = 'Finance',
	UserManagement = 'User Management',
}

export type NavSection = `${NavSectionEnum}`;

export interface AdminNavItem {
	key: AdminPage;
	label: string;
	getPath: (auctionId?: string) => string;
	getIsOpen: (pathname: string) => boolean;
}

export const ALL_NAV_ITEMS: { [key in NavSection]: AdminNavItem[] } = {
	'Performance Dashboard': [
		{
			key: 'PerformanceDashboard',
			label: 'Performance Dashboard',
			getPath: () => '/Performance/Summary',
			getIsOpen: (pathname: string) => pathname.includes('/Performance/Summary'),
		},
		{
			key: 'LiveAuctionDashboard',
			label: 'Live Auction Dashboard',
			getPath: () => '/Performance/LiveAuction',
			getIsOpen: (pathname: string) => pathname.includes('/Performance/LiveAuction'),
		},
	],
	'Customize Homepage': [
		{
			key: 'CarouselEditor',
			label: 'Carousel Editor',
			getPath: () => '/customize/carousel',
			getIsOpen: (pathname: string) => pathname.includes('/customize/carousel'),
		},
	],
	Inventory: [
		{
			key: 'Listings',
			label: 'Search Auction Listings',
			getPath: () => '/inventory/search',
			getIsOpen: (pathname: string) => pathname.includes('/inventory'),
		},
		{
			key: 'FixedPriceListings',
			label: 'Search Fixed Price Listings',
			getPath: () => '/fixed-price/search',
			getIsOpen: (pathname: string) =>
				pathname.includes('/fixed-price/search') || pathname.includes('/fixed-price/listings'),
		},
	],
	'Manage Auctions': [
		{
			key: 'AuctionDashboard',
			label: 'Auction Dashboard',
			getPath: () => '/auctions/auction-dashboard',
			getIsOpen: (pathname: string) => pathname.includes('/auctions/auction-dashboard'),
		},
		{
			key: 'AuctionPreview',
			label: 'Auction Preview',
			getPath: (firstAuctionId = '') => `/auctions/${firstAuctionId}/preview`,
			getIsOpen: (pathname: string) =>
				pathname.includes('/auctions') && pathname.includes('/preview'),
		},
		{
			key: 'WinnerSummary',
			label: 'Winner Summary',
			getPath: () => '/auctions/winnersummary',
			getIsOpen: (pathname: string) => pathname.includes('/auctions/winnersummary'),
		},
		{
			key: 'SellerSummary',
			label: 'Seller Summary',
			getPath: () => '/auctions/sellersummary',
			getIsOpen: (pathname: string) =>
				pathname.includes('/auctions/sellersummary') || pathname.includes('statements'),
		},
		{
			key: 'AuctionManager',
			label: 'Create and Edit Auctions',
			getPath: (firstAuctionId = '') => `/auctions/${firstAuctionId}/auction-manager`,
			getIsOpen: (pathname: string) => pathname.includes('auction-manager'),
		},
		{
			key: 'InventoryManager',
			label: 'Inventory Manager',
			getPath: (auctionId = 'Unassigned') => `/auctions/${auctionId}/inventory-manager`,
			getIsOpen: (pathname: string) =>
				pathname.includes('/auctions') && pathname.includes('/inventory-manager'),
		},
		{
			key: 'AuctionPublisher',
			label: 'Auction Publisher',
			getPath: () => '/auctions/auctionpublisher',
			getIsOpen: (pathname: string) =>
				pathname.toLowerCase().includes('/auctions/auctionpublisher'),
		},
		{
			key: 'ShipmentManager',
			label: 'Shipment Manager',
			getPath: () => '/auctions/shipmentmanager',
			getIsOpen: (pathname: string) =>
				pathname.includes('/auctions/shipmentmanager') || pathname.includes('shipment-manager'),
		},
	],
	'Manage Fixed Price': [
		{
			key: 'FixedPriceOrderSummary',
			label: 'Search Orders',
			getPath: () => '/fixed-price/order-summary',
			getIsOpen: (pathname: string) => pathname.includes('/fixed-price/order-summary'),
		},
		{
			key: 'FixedPriceSellerSummary',
			label: 'Search Seller Statements',
			getPath: () => '/fixed-price/seller-summary',
			getIsOpen: (pathname: string) => pathname.includes('/fixed-price/seller-summary'),
		},
	],
	[NavSectionEnum.Finance]: [
		{
			key: AdminPageEnum.Reports,
			label: AdminPageEnum.Reports,
			getPath: () => '/finance/reports',
			getIsOpen: (pathname: string) => pathname.includes('/finance/reports'),
		},
		{
			key: AdminPageEnum.BulkUploads,
			label: 'Bulk Uploads',
			getPath: () => '/finance/bulk-uploads',
			getIsOpen: (pathname: string) => pathname.includes('/finance/bulk-uploads'),
		},
	],
	'User Management': [
		{
			key: 'ManageCustomers',
			label: 'Manage Customers',
			getPath: () => '/usermanagement/search/customers',
			getIsOpen: (pathname: string) =>
				pathname.includes('/usermanagement/customer') ||
				pathname.includes('/usermanagement/search/customers'),
		},
		{
			key: 'ManageEmployees',
			label: 'Manage Employees',
			getPath: () => '/usermanagement/employee',
			getIsOpen: (pathname: string) => pathname.includes('/usermanagement/employee'),
		},
	],
};

export const ORDERED_NAV_SECTIONS: NavSection[] = [
	'Performance Dashboard',
	'Customize Homepage',
	'Inventory',
	'Manage Auctions',
	'Manage Fixed Price',
	'Finance',
	'User Management',
];

const getPermittedPages = (userRole: UserRoleName, navItems: AdminNavItem[]) => {
	if (userRole === 'Admin') {
		return navItems;
	}

	return navItems.filter((navItem) => PERMITTED_ROLES_PER_PAGE[navItem.key].includes(userRole));
};

export const getNavItemsForRole = (
	userRole: UserRoleName | ''
): Partial<{ [key in NavSection]: AdminNavItem[] }> => {
	if (!userRole) {
		return {};
	}

	if (userRole === 'Admin') {
		return ALL_NAV_ITEMS;
	}

	const navItems: Partial<{ [key in NavSection]: AdminNavItem[] }> = {};

	ORDERED_NAV_SECTIONS.forEach((navSection: NavSection) => {
		const permittedPages = getPermittedPages(userRole, ALL_NAV_ITEMS[navSection]);
		if (permittedPages.length > 0) {
			navItems[navSection] = permittedPages;
		}
	});

	return navItems;
};

export const getNavSectionsForRole = (userRole: UserRoleName) => {
	const navItems = getNavItemsForRole(userRole);

	return ORDERED_NAV_SECTIONS.map((navSection: NavSection) => ({
		label: navSection,
		items: navItems[navSection],
	})).filter(({ items }) => !!items);
};

import React, { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../../../../../../../components/Button';
import Modal from '../../../../../../../components/Modal';
import { showErrorToast, showSuccessToast } from '../../../../../../../components/Toast';
import {
	CheckoutOrder,
	CheckoutOrderComment,
	CheckoutOrderCommentKind,
} from '../../../../../../../services/ordersService/ordersService.model';
import { CurrentUser } from '../../../../../../../services/userService/userService.model';
import { QueryKeys } from '../../../../../../../queries/queryKeys';
import { updateInvoiceComments } from '../../../../../../../services/invoicesService';
import useCurrentUser from '../../../../../../../queries/useCurrentUser';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber';
import { Textarea } from '../../../../../../../prizm-ui/Textarea';

const AddCommentModal = ({
	newCommentKind,
	orderNumber,
	title = 'Add Comment',
	onClose,
}: {
	newCommentKind: CheckoutOrderCommentKind;
	orderNumber: string;
	title?: string;
	onClose: () => void;
}) => {
	const { data: order = {} as CheckoutOrder, updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const [newComment, setNewComment] = useState('');

	const { mutate: doAddComment, status: addCommentStatus } = useMutation({
		mutationFn: ({ comments }: { comments: CheckoutOrderComment[] }) =>
			updateInvoiceComments({ auction_id: order.auctionId, comments, invoice_id: order.invoiceId }),
		mutationKey: [QueryKeys.UpdateInvoiceComments, order.invoiceNumber],
	});

	const handleSaveCommentClick = () => {
		const { comments: previousComments = [] } = order;

		const comments: CheckoutOrderComment[] = [
			...previousComments,
			{
				author: {
					authorDisplayName: currentUser.currentUserFullName || 'unknown',
					authorId: currentUser.currentUserId,
				},
				kind: newCommentKind,
				text: newComment,
				timestamp: new Date().toISOString(),
			},
		];

		doAddComment(
			{ comments },
			{
				onError: () => showErrorToast('Something went wrong. Please try again later.'),
				onSuccess: () => {
					updateOrderInCache({ ...order.comments, comments });
					showSuccessToast('Comment added successfully.');
					onClose();
				},
			}
		);
	};

	return (
		<Modal title={title} onClose={onClose}>
			<div>
				<Textarea
					autoFocus
					defaultValue={newComment}
					id="new-comment"
					label=""
					name="new-comment"
					onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
						setNewComment(value)
					}
				/>
			</div>
			<div className="mt-8 flex flex-row items-center space-x-4">
				<Button
					disabled={newComment.length === 0}
					isLoading={addCommentStatus === 'loading'}
					kind="primary"
					onClick={handleSaveCommentClick}
				>
					Save Comment
				</Button>
				<Button kind="secondary" onClick={onClose}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};

export default AddCommentModal;

import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from '../../config';
import { CognitoUser } from './getEmployees.model';
import { Employee, UserRoleName } from './userService.model';

export const getEmployees = async () => {
	const credentials = await Auth.currentCredentials();
	const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(credentials);

	const { Users = [] as CognitoUser[], PaginationToken } = await cognitoIdentityServiceProvider
		.listUsers({
			UserPoolId: config.cognito.USER_POOL_ID || '',
		})
		.promise();

	/** I'm sure AWS has a good security reason for not just letting you dump all users, or be able to page through
	 *  them by hand. But that doesn't mean I have to be happy about how it had to work to get a full list of employees.
	 */
	let mutablePaginationToken = PaginationToken;
	let mutableUsers: CognitoUser[] = Users as CognitoUser[];

	while (mutablePaginationToken) {
		const { Users: additionalUsers = [] as CognitoUser[], PaginationToken: nextPaginationToken } =
			await cognitoIdentityServiceProvider
				.listUsers({
					UserPoolId: config.cognito.USER_POOL_ID || '',
					PaginationToken: mutablePaginationToken,
				})
				.promise();

		mutableUsers = [...mutableUsers, ...(additionalUsers as CognitoUser[])];
		mutablePaginationToken = nextPaginationToken;
	}

	const employees: Employee[] = mutableUsers.map((cognitoUser) => ({
		email: cognitoUser?.Attributes?.find(({ Name }) => Name === 'email')?.Value || '',
		familyName: cognitoUser?.Attributes?.find(({ Name }) => Name === 'family_name')?.Value || '',
		givenName: cognitoUser?.Attributes?.find(({ Name }) => Name === 'given_name')?.Value || '',
		id: cognitoUser.Username || '',
		role:
			/** @ts-ignore because AWS typing sucks */
			(cognitoUser?.Attributes?.find(({ Name }) => Name === 'custom:role')
				?.Value as UserRoleName) || 'Unknown',
		status: cognitoUser?.Enabled,
	}));

	return employees;
};

import { useQuery, useQueryClient } from 'react-query';
import { getIndividualLot } from '../../services/lotsService/getIndividualLot';
import { KernelLot } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';
import useLotsForAuction from '../useLotsForAuction';

const useIndividualLot = (lotId: string) => {
	const queryKey = [QueryKeys.GetIndividualLot, lotId];
	const baseQuery = useQuery(queryKey, () => getIndividualLot(lotId), {
		enabled: !!lotId,
	});

	const queryClient = useQueryClient();
	const thisLot = queryClient.getQueryData(queryKey) as KernelLot;
	const { updateIndividualLot: updateLotInAuctionCache } = useLotsForAuction({
		auctionId: thisLot?.auction_id || 'Unassigned',
	});

	const updateLot = (updatedFields: Partial<KernelLot>) => {
		queryClient.setQueryData(queryKey, (originalLot: KernelLot = {} as KernelLot) => {
			const updatedLot = {
				...originalLot,
				...updatedFields,
			};
			updateLotInAuctionCache(updatedLot);
			return updatedLot;
		});
	};

	const enhancedQuery = { ...baseQuery, updateLot };

	return enhancedQuery;
};

export default useIndividualLot;

import React from 'react';

type ModalContentProps = {
	children?: React.ReactNode;
};

export const ModalContent = ({ children }: ModalContentProps) => (
	<div data-testid="modalcontent" className="p-4 xs:p-10 overflow-auto grow">
		{children}
	</div>
);

import { Link, useParams } from 'react-router-dom';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import Label from '../../../../components/Label';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import SortableTable from '../../../../components/SortableTable';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import { Auction } from '../../../../services/auctionsService';
import {
	KernelConsignedLotsResponse,
	KernelLot,
	LotStatus,
} from '../../../../services/lotsService/lotsService.model';
import {
	KernelSellerStatement,
	SellerStatementStatusType,
} from '../../../../services/sellerStatementService';
import { User } from '../../../../services/userService/userService.model';
import useAuctions from '../../../../queries/useAuctions';
import useUserById from '../../../../queries/useUserById';
import { CONSIGNED_LOTS_TAB_CSV_COLUMN_NAMES } from './ConsignedLots.model';
import useSellerStatements from '../../../../queries/useSellerStatements/useSellerStatements';
import LotStatusChip from '../../../../components/statusChips/LotStatusChip';
import SellerStatementStatusChip from '../../../../components/statusChips/SellerStatementStatusChip';
import StatementNumberCell from '../../../../components/SortableTable/cells/StatementNumberCell';
import { currencyColumn } from '../../../../components/SortableTable/columns';
import useConsignedLots from '../../../../queries/useConsignedLots/useConsignedLots';
import { useMemo, useState } from 'react';

const ConsignedLotsTab = () => {
	const { userId = '' } = useParams<{ userId: string }>();

	const { data: seller = {} as User, status: userStatus } = useUserById(userId);

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 100,
	});

	const {
		data: consignedLotsResponse = {} as KernelConsignedLotsResponse,
		status: consignedLotsStatus,
	} = useConsignedLots({
		sellerId: seller.id,
		pageSize,
		page: pageIndex,
	});

	const {
		data: allConsignedLotsResponse = {} as KernelConsignedLotsResponse,
		status: allConsignedLotsStatus,
	} = useConsignedLots({ sellerId: seller.id });
	const { lots: allConsignedLots = [] as KernelLot[] } = allConsignedLotsResponse;

	const {
		lots: consignedLots = [] as KernelLot[],
		totalPages = 0,
		total: totalItems = 0,
	} = consignedLotsResponse;

	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();

	const { data: sellerStatements = [] as KernelSellerStatement[] } = useSellerStatements({
		queryBy: 'sellerId',
		sellerId: seller.id,
		status: 'All',
	});

	const columns: ColumnDef<KernelLot>[] = [
		{
			accessorKey: 'serial_number',
			cell: ({
				getValue,
				row: {
					original: { auction_id, lot_id },
				},
			}) =>
				auction_id === 'fixed_price_marketplace' ? (
					<Tooltip content="View Fixed Price Listing" id={lot_id} placement="top" trigger="hover">
						<Link className="block link" to={`/fixed-price/listings/${lot_id}`}>
							{getValue() as string}
						</Link>
					</Tooltip>
				) : (
					<Tooltip content="View Listing" id={lot_id} placement="top" trigger="hover">
						<Link className="block link" to={`/inventory/listings/${lot_id}/manage`}>
							{getValue() as string}
						</Link>
					</Tooltip>
				),
			header: 'Serial No.',
		},
		{
			accessorFn: ({ auction_id }) => {
				if (auction_id === 'fixed_price_marketplace') {
					return 'Fixed Price Marketplace';
				}
				return auctions.find(({ id }) => id === auction_id)?.title ?? '';
			},
			cell: ({ getValue }) => <div className="w-36">{getValue()}</div>,
			header: 'Auction',
		},
		{
			accessorKey: 'lot_number',
			enableGlobalFilter: false,
			header: 'Lot No.',
		},
		{
			accessorKey: 'title',
			header: 'Title',
			cell: ({ getValue }) => <div className="w-72">{getValue()}</div>,
		},
		currencyColumn<KernelLot>({ accessorKey: 'current_price', header: 'Price' }),
		{
			accessorKey: 'status',
			header: () => <div className="text-right">Lot Status</div>,
			cell: ({ getValue }) => (
				<div className="text-right">
					<LotStatusChip status={getValue() as LotStatus} />
				</div>
			),
		},
		currencyColumn<KernelLot>({ accessorKey: 'buyer_premium', header: 'Buyer Premium' }),
		{
			accessorKey: 'commission',
			cell: ({ getValue }) => getValue() && <div className="text-right">{getValue()}%</div>,
			enableGlobalFilter: false,
			header: () => <div className="text-right">Commission</div>,
		},
		{
			accessorFn: ({ seller_statement_id }) => {
				const [sellerStatement = {} as KernelSellerStatement] = sellerStatements.filter(
					({ seller_statement_id: thisSellerStatementId }) =>
						thisSellerStatementId === seller_statement_id
				);

				return sellerStatement.seller_statement_number ?? '';
			},
			id: 'seller_statement_number',
			header: () => 'Seller Statement No.',
			cell: ({
				row: {
					original: { auction_id, seller_statement_id },
				},
			}) => {
				const [sellerStatement = {} as KernelSellerStatement] = sellerStatements.filter(
					({ seller_statement_id: thisSellerStatementId }) =>
						thisSellerStatementId === seller_statement_id
				);

				return (
					<StatementNumberCell
						auctionId={auction_id}
						stripePayoutAccountId={sellerStatement.seller_stripe_payout_account_id}
						statementId={sellerStatement.seller_statement_id}
						statementNumber={sellerStatement.seller_statement_number}
						urlSchema={auction_id === 'fixed_price_marketplace' ? 'fixed-price' : 'auction'}
					/>
				);
			},
		},
		{
			accessorFn: ({ seller_statement_id }) => {
				const [sellerStatement = {} as KernelSellerStatement] = sellerStatements.filter(
					({ seller_statement_id: thisSellerStatementId }) =>
						thisSellerStatementId === seller_statement_id
				);

				return sellerStatement.seller_statement_status ?? '';
			},
			id: 'seller_statement_status',
			cell: ({ getValue }) =>
				getValue() ? (
					<div className="text-right">
						<SellerStatementStatusChip status={getValue() as SellerStatementStatusType} />
					</div>
				) : (
					''
				),
			header: () => <div className="text-right">Seller Statement Status</div>,
		},
	];

	const sortableTableParams: SortableTableParams<KernelLot> = {
		columns,
		data: consignedLots,
		getRowId: ({ lot_id }) => lot_id,
		manualPagination: true,
		pageCount: totalPages,
		state: {
			pagination: useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]),
		},
		onPaginationChange: setPagination,
	};

	const downloadCSVData = allConsignedLots.map((lot) => ({
		...lot,
		auction_title:
			lot.auction_id === 'fixed_price_marketplace'
				? 'Fixed Price Marketplace'
				: auctions.find(({ id }) => id === lot.auction_id)?.title ?? lot.auction_id,
		seller_statement_number:
			sellerStatements.find(({ auction_id }) => auction_id === lot.auction_id)
				?.seller_statement_number ?? '',
		seller_statement_status:
			sellerStatements.find(({ auction_id }) => auction_id === lot.auction_id)
				?.seller_statement_status ?? '',
	}));

	return (
		<div className="px-4">
			<div className="flex flex-row items-end justify-between">
				<Label>Consigned Lots</Label>
				<DownloadCSVButton
					className="mb-2"
					columnNames={CONSIGNED_LOTS_TAB_CSV_COLUMN_NAMES}
					disabled={allConsignedLots.length === 0 || allConsignedLotsStatus === 'loading'}
					documentName={`consigned-lots-${seller.givenName}-${seller.familyName}`}
					items={downloadCSVData}
					kind="secondary"
				/>
			</div>
			<div className="mt-2">
				<LoadingWrapper queryStatuses={[userStatus, consignedLotsStatus, auctionsStatus]}>
					<SortableTable
						globalFilterPlaceholder="Filter ..."
						key={userId}
						noResultsMessage="No consigned lots"
						tableParams={sortableTableParams}
						totalItems={totalItems}
					/>
				</LoadingWrapper>
			</div>
		</div>
	);
};

export default ConsignedLotsTab;

import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getLotsBySubset } from '../../services/lotsService';
import { KernelLot, KernelLotKey } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';

const useLotsForAuction = ({
	auctionId = 'Unassigned',
	from = 0,
	include = [],
	list = false,
	size = 3500,
}: {
	auctionId?: string;
	from?: number;
	include?: KernelLotKey[];
	list?: boolean;
	size?: number;
}) => {
	const queryKey = [QueryKeys.GetLotsForAuction, auctionId, from, size];
	const query = useQuery(queryKey, () =>
		getLotsBySubset({
			auctionId,
			from,
			kind: 'assigned',
			list,
			size,
			...(include && { include }),
		})
	);

	const queryClient = useQueryClient();

	// seed individual lot queries when loading lots for auction
	const { data: lotsForAuction = [] as KernelLot[] } = query;
	useEffect(() => {
		if (lotsForAuction.length > 0) {
			lotsForAuction.forEach((lot) =>
				queryClient.setQueryData([QueryKeys.GetIndividualLot, lot.lot_id], () => lot)
			);
		}
	}, [lotsForAuction.length]);

	const addLotsToCache = (additionalLots: KernelLot[]) =>
		queryClient.setQueryData(queryKey, (oldData: KernelLot[] = []) => [
			...oldData,
			...additionalLots,
		]);
	const removeLotsFromCache = (lotIdsToRemove: string[]) =>
		queryClient.setQueryData(queryKey, (oldData: KernelLot[] = []) =>
			oldData.filter(({ lot_id }) => !lotIdsToRemove.includes(lot_id))
		);
	const updateIndividualLot = (updatedLot: Partial<KernelLot>) => {
		queryClient.setQueryData(queryKey, (oldData: KernelLot[] = []) => {
			const originalLot = oldData.find(({ lot_id }) => lot_id === updatedLot.lot_id);

			if (originalLot) {
				const myIndex = oldData.findIndex(({ lot_id }) => lot_id === updatedLot.lot_id);
				const updatedLotsArray = [...oldData];
				updatedLotsArray[myIndex] = { ...originalLot, ...updatedLot };
				return updatedLotsArray;
			}
			return oldData;
		});

		queryClient.setQueryData(
			[QueryKeys.GetIndividualLot, updatedLot.lot_id],
			(oldData: KernelLot = {} as KernelLot) => ({ ...oldData, ...updatedLot })
		);
	};

	const updatePluralLots = (lotsToUpdate: Partial<KernelLot>[]) =>
		lotsToUpdate.forEach((lot) => updateIndividualLot(lot));

	const enhancedQuery = {
		...query,
		addLotsToCache,
		removeLotsFromCache,
		updateIndividualLot,
		updatePluralLots,
	};

	return enhancedQuery;
};

export default useLotsForAuction;

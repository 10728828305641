import React from 'react';
import Layout from '../../../components/Layout';
import OrderDashboard from './OrderDashboard';

const OrdersRoot = () => {
	return (
		<Layout>
			<OrderDashboard />
		</Layout>
	);
};

export default OrdersRoot;

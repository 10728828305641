import formatCurrency from '../../../../utils/formatters/formatCurrency';
import { Chip } from '../../../../prizm-ui/Chip';
import { Icon } from '../../../../prizm-ui/Icon';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import useAuctionById from '../../../../queries/useAuctionById';
import { Auction } from '../../../../services/auctionsService';
import { KernelSellerStatementDetails } from '../../../../services/sellerStatementService';
import { formatDate } from '../../../../utils/formatters';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import MarkAsPaidButton from './MarkAsPaidButton';
import RegenerateStatementButton from './RegenerateStatementButton';
import { FIXED_PRICE_SELLER_STATEMENT_CSV_COLUMNS } from './SellerStatementGeneralInfo.model';

const SellerStatementGeneralInfo = ({
	auctionId,
	statementDetails,
}: {
	auctionId: string;
	statementDetails: KernelSellerStatementDetails;
}) => {
	const { data: auction = {} as Auction } = useAuctionById(auctionId);
	const auctionTitle =
		auctionId === 'fixed_price_marketplace' ? 'Fixed Price Marketplace' : auction.title;

	const sellerName = `${statementDetails?.user_info?.given_name ?? ''} ${
		statementDetails?.user_info?.family_name ?? ''
	}`.trim();

	return (
		<div className="flex flex-col lg:flex-row lg:justify-between">
			<div>
				<div className="flex items-center">
					<h1 className="text-display4">
						Seller Statement {statementDetails.seller_statement_number}
					</h1>
				</div>

				<div>
					<div className="text-subtitle1">{sellerName}</div>
					<div className="text-subtitle2">{auctionTitle}</div>
					<div className="mt-4">
						<div className="text-body1">
							<div className="flex">
								<div>
									<span className="mr-1">Total Statement Amount: </span>
									<span className="font-bold">
										{formatCurrency(statementDetails.total_statement_amount)}
									</span>
								</div>
								<div className="ml-2">
									<Chip
										variant={
											statementDetails.seller_statement_status === 'Paid' ? 'success' : 'error'
										}
									>
										{statementDetails.seller_statement_status}
									</Chip>
								</div>
							</div>
							{statementDetails.seller_statement_status === 'Unpaid' && (
								<div className="flex items-center">
									<span className="mr-1">Total Ready for Payout: </span>
									<span className="font-bold mr-1">
										{formatCurrency(statementDetails.total_ready_for_payout)}
									</span>
									<Tooltip id="total-ready-for-payout-warning" content="Updates every 15 minutes">
										<Icon aria-label="Help" name="QuestionCircle" size="md" />
									</Tooltip>
								</div>
							)}
						</div>
						{statementDetails.seller_statement_status === 'Paid' && (
							<div className="text-body1">{`Paid on ${formatDate(
								statementDetails.payout_date
							)}`}</div>
						)}
					</div>
				</div>
			</div>
			<div className="mt-8 lg:mt-2">
				<RegenerateStatementButton
					auctionId={auctionId}
					sellerId={statementDetails?.user_info?.user_id ?? ''}
					statementId={statementDetails.seller_statement_id}
				/>
				<MarkAsPaidButton
					auctionId={auctionId}
					statementId={statementDetails.seller_statement_id}
					currentStatus={statementDetails.seller_statement_status}
				/>
				<DownloadCSVButton
					className="mt-2 w-full"
					columnNames={FIXED_PRICE_SELLER_STATEMENT_CSV_COLUMNS}
					documentName={`seller-statement-${statementDetails.seller_statement_number}-${statementDetails.user_info.given_name}-${statementDetails.user_info.family_name}`}
					items={statementDetails}
					kind="secondary"
				/>
			</div>
		</div>
	);
};

export default SellerStatementGeneralInfo;

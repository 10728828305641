import { without } from 'lodash';
import {
	KernelLot,
	LotFilters,
	MissingFieldOption,
} from '../../../../services/lotsService/lotsService.model';
import { LotManagerFiltersType } from './LotManagerFilters.model';

export const filterLots = ({
	lots = [],
	filters,
}: {
	lots?: KernelLot[];
	filters: LotManagerFiltersType;
}): KernelLot[] => {
	const activeFilters = Object.keys(filters).filter(
		(filterKey) => !!filters[filterKey as keyof LotManagerFiltersType]
	);

	if (activeFilters.length === 0) {
		return lots;
	}
	const filteredLots = lots.filter((lot) => {
		const isMissingValues = activeFilters.some((filter) => !lot[filter as keyof KernelLot]);

		const isCompletedUnsoldLot =
			activeFilters.includes('status') && lot.status === 'Completed_Unsold';

		return isMissingValues || isCompletedUnsoldLot;
	});

	return filteredLots;
};

export const getSummaryLotsFilters = (filters: LotManagerFiltersType): LotFilters => {
	const activeFilters: MissingFieldOption[] = Object.keys(filters).filter(
		(filterName) => !!filters[filterName]
	);

	const unsold = activeFilters.includes('status');
	const missingFields: MissingFieldOption[] = without(activeFilters, 'status');

	return { unsold, missing: missingFields };
};

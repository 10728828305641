import React, { useState } from 'react';
import useUserRole from '../../queries/useUserRole';
import { searchBidder } from '../../services/bidderService/searchBidder';
import { getEmployeesBySearchQuery } from '../../services/userService';
import { BaseUser, EDITOR_ROLES } from '../../services/userService/userService.model';
import Dropdown from '../Dropdown';
import { ComplexDropdownMenuItem } from '../Dropdown/Dropdown.model';
import Label from '../Label';
import { getInterestedPartyLabel } from './InterestedPartiesInput.utils';

const InterestedPartyInput = ({
	isDisabled = false,
	kind,
	label,
	selectedParty,
	onChange,
	onClear,
}: {
	isDisabled?: boolean;
	kind: 'customer' | 'employee';
	label?: string;
	selectedParty: BaseUser;
	onChange: (value: BaseUser) => void;
	onClear: () => void;
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isEditor = [...EDITOR_ROLES, 'AuctionManager', 'Consignment', 'Finance'].includes(userRole);

	const selectedPartyLabel = getInterestedPartyLabel({
		givenName: selectedParty?.givenName,
		familyName: selectedParty?.familyName,
		email: selectedParty?.email,
	});

	const [searchQuery, setSearchQuery] = useState(selectedPartyLabel);
	const [hasClearedInput, setHasClearedInput] = useState(false);

	const defaultOption = selectedPartyLabel
		? {
				label: selectedPartyLabel,
				value: { id: selectedParty.id, email: selectedParty.email },
		  }
		: undefined;

	const loadOptions = async (searchQuery: string): Promise<ComplexDropdownMenuItem[]> => {
		const searchResults =
			kind === 'employee'
				? await getEmployeesBySearchQuery({ searchQuery })
				: await searchBidder(searchQuery);

		return searchResults.map(
			({
				id,
				givenName,
				familyName,
				email,
			}: {
				id: string;
				givenName: string;
				familyName: string;
				email: string;
			}) => ({
				value: { id, email, familyName, givenName },
				label: `${givenName} ${familyName} (${email})`,
			})
		);
	};

	const clearSelectedParty = () => {
		setHasClearedInput(true);
		setSearchQuery('');
		onClear();
	};

	const fallbackLabel = kind === 'employee' ? 'Consignment Manager' : 'Seller';

	return (
		<>
			<Label htmlFor={kind}>{label || fallbackLabel}</Label>
			<div className="flex items-center">
				{isEditor && !isDisabled ? (
					<>
						<div className="flex-grow">
							<Dropdown
								allowedRoles={[...EDITOR_ROLES, 'AuctionManager', 'Consignment']}
								defaultOption={defaultOption}
								inputValue={searchQuery}
								kind="async"
								loadOptions={loadOptions}
								name={kind}
								onChange={({ value }) => onChange(value as BaseUser)}
								onInputChange={setSearchQuery}
								placeholder={hasClearedInput ? 'Search for person...' : selectedPartyLabel}
							/>
						</div>
						<button className="ml-2" onClick={clearSelectedParty}>
							Clear
						</button>
					</>
				) : (
					<div className="text-body1">{selectedPartyLabel}</div>
				)}
			</div>
		</>
	);
};

export default InterestedPartyInput;

import { Link } from 'react-router-dom';
import { LotWatcher } from '../../../../../services/lotsService/getLotWatchers.model';
import { formatDate } from '../../../../../utils/formatters';

const LotWatchListRows = ({ lotWatchers }: { lotWatchers: LotWatcher[] }) => {
	return (
		<div className="w-full rounded-b-md">
			{lotWatchers.length === 0 && <div className="p-4">No Lot Watchers at this time</div>}
			{lotWatchers.length > 0 &&
				lotWatchers.map((lotWatcher, index) => (
					<div
						className={`grid grid-cols-3 px-4 py-2 ${
							index === lotWatchers.length - 1 ? 'rounded-b-md' : ''
						} ${index % 2 === 0 ? 'bg-neutral1' : 'bg-neutral2'}`}
						key={lotWatcher.userId}
					>
						<div className="col-span-1">{formatDate(lotWatcher.timestamp)}</div>
						<div className="col-span-2">
							<Link
								to={`/usermanagement/customer/${lotWatcher.userId}/Customer%20Overview`}
								className="link"
							>
								{lotWatcher.name}
							</Link>
						</div>
					</div>
				))}
		</div>
	);
};

export default LotWatchListRows;

import { useQueries, useQuery } from 'react-query';
import { getImageUrl } from '../../services/storageService';
import {
	ImageSize,
	RootPathForStorageBucket,
	StorageProvider,
} from '../../services/storageService/storageService.model';
import { QueryKeys } from '../queryKeys';

const useImageUrl = ({
	storageProvider,
	rootPath,
	itemId,
	imageSize,
	parentPath,
	isEnabled = true,
}: {
	storageProvider: StorageProvider;
	rootPath: RootPathForStorageBucket;
	itemId: string;
	imageSize?: ImageSize;
	parentPath?: string;
	isEnabled?: boolean;
}) =>
	useQuery(
		[QueryKeys.GetImageUrl, rootPath, itemId, storageProvider, imageSize],
		() =>
			getImageUrl({
				imageSize,
				storageProvider,
				rootPath,
				itemId,
				parentPath,
			}),
		{
			enabled: isEnabled,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			staleTime: Infinity,
		}
	);

export default useImageUrl;

export const useImageUrls = ({
	storageProvider,
	rootPath,
	itemIds,
	imageSize,
	parentPath,
	isEnabled = true,
}: {
	storageProvider: StorageProvider;
	rootPath: RootPathForStorageBucket;
	itemIds: string[];
	imageSize?: ImageSize;
	parentPath?: string;
	isEnabled?: boolean;
}) =>
	useQueries(
		itemIds.map((itemId) => ({
			queryKey: [QueryKeys.GetImageUrl, rootPath, itemId, storageProvider, imageSize],
			queryFn: () =>
				getImageUrl({
					imageSize,
					storageProvider,
					rootPath,
					itemId,
					parentPath,
				}),
			enabled: isEnabled,
			retry: 3,
			retryDelay: 1000,
		}))
	);

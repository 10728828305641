import { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/DatePicker';
import Dropdown from '../../../../components/Dropdown';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import { isEmail } from '../../../../utils/validators';
import {
	FIXED_PRICE_ORDER_STATUS,
	FixedPriceOrderSearchType,
} from '../FixedPriceOrderSummaryPage.model';
import {
	FixedPriceOrderSearchParams,
	FixedPriceOrderStatusType,
} from '../../../../services/ordersService/ordersService.model';
import { getQueryParamsObject } from '../../../../utils/formatters';
import { getDefaultQueryType } from './FixedPriceOrderSearchForm.util';

const FixedPriceOrderSearchForm = ({
	onSubmit,
}: {
	onSubmit: (searchParams: Partial<FixedPriceOrderSearchParams>) => void;
}) => {
	const { search } = useLocation();
	const paramsFromURL = getQueryParamsObject<FixedPriceOrderSearchParams>(search);

	const [newFromDate, setNewFromDate] = useState('');
	const [newStatus, setNewStatus] = useState<FixedPriceOrderStatusType>('Paid');
	const [newToDate, setNewToDate] = useState('');

	const [queryType, setQueryType] = useState<FixedPriceOrderSearchType>(
		getDefaultQueryType(paramsFromURL)
	);
	const [searchQuery, setSearchQuery] = useState('');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
		setQueryType(e.target.id as FixedPriceOrderSearchType);

	const handleClick = () => {
		const searchParams: FixedPriceOrderSearchParams =
			queryType === 'dateRange'
				? {
						fromDate: newFromDate,
						status: newStatus,
						searchType: 'date-range',
						toDate: newToDate,
				  }
				: { searchQuery, searchType: 'query' };

		if (queryType === 'winnerEmail') {
			searchParams.fromDate = newFromDate;
			searchParams.toDate = newToDate;
		}

		onSubmit(searchParams);
	};

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
		if (searchQuery.length > 0 && key === 'Enter') {
			handleClick();
		}
	};

	const dateRangeIsValid = queryType === 'dateRange' && newStatus && (newFromDate || newToDate);
	const orderNumberIsValid =
		queryType === 'orderNumber' && searchQuery && !isNaN(Number(searchQuery));
	const winnerEmailIsValid = queryType === 'winnerEmail' && isEmail(searchQuery);
	const canClick = dateRangeIsValid || orderNumberIsValid || winnerEmailIsValid;

	return (
		<>
			<div>
				<Label>Search by:</Label>
				<div className="flex">
					<div className="flex items-center">
						<input
							checked={queryType === 'orderNumber'}
							id="orderNumber"
							name="query-type"
							type="radio"
							onChange={(e) => handleChange(e)}
						/>
						<span className="ml-1">Order Number</span>
					</div>
					<div className="flex mx-4">
						<input
							checked={queryType === 'winnerEmail'}
							id="winnerEmail"
							name="query-type"
							type="radio"
							onChange={(e) => handleChange(e)}
						/>
						<span className="ml-1">Winner Email</span>
					</div>
					<div className="flex">
						<input
							checked={queryType === 'dateRange'}
							id="dateRange"
							name="query-type"
							type="radio"
							onChange={(e) => handleChange(e)}
						/>
						<span className="ml-1">Date Range</span>
					</div>
				</div>
			</div>
			<div className="flex items-end justify-between">
				{queryType === 'orderNumber' && (
					<div className="mt-4 w-full">
						<div>
							<Label>Order Number</Label>
							<Input
								autoFocus
								className="w-full"
								value={
									queryType === 'orderNumber' && !isNaN(Number(paramsFromURL.searchQuery))
										? paramsFromURL.searchQuery
										: ''
								}
								onChange={({ target: { value } }) => setSearchQuery(value)}
								onKeyDown={handleKeyDown}
							/>
						</div>
					</div>
				)}
				{queryType === 'winnerEmail' && (
					<div className="mt-4 w-full">
						<div className="w-full">
							<Label>Winner Email</Label>
							<Input
								autoFocus
								className="h-10 w-full"
								value={
									queryType === 'winnerEmail' && isEmail(paramsFromURL.searchQuery)
										? paramsFromURL.searchQuery
										: ''
								}
								onChange={({ target: { value } }) => setSearchQuery(value)}
								onKeyDown={handleKeyDown}
							/>
						</div>
						<div className="flex mt-4 w-full">
							<div className="w-full">
								<Label>From</Label>
								<DatePicker
									className="w-full"
									defaultValue={paramsFromURL.searchType === 'query' ? paramsFromURL.fromDate : ''}
									onChange={({ target: { value } }) => setNewFromDate(value)}
								/>
							</div>
							<div className="ml-4 w-full">
								<Label>To</Label>
								<DatePicker
									className="w-full"
									defaultValue={paramsFromURL.searchType === 'query' ? paramsFromURL.toDate : ''}
									onChange={({ target: { value } }) => setNewToDate(value)}
								/>
							</div>
						</div>
					</div>
				)}
				{queryType === 'dateRange' && (
					<div className="mt-4 w-full">
						<div className="w-full">
							<Label>Order Status</Label>
							<Dropdown
								key="fixed-price-order-status"
								name="order-status"
								options={FIXED_PRICE_ORDER_STATUS}
								onChange={({ value }) => setNewStatus(value as FixedPriceOrderStatusType)}
							/>
						</div>
						<div className="flex mt-4 w-full">
							<div className="w-full">
								<Label>From</Label>
								<DatePicker
									className="w-full"
									defaultValue={
										paramsFromURL.searchType === 'date-range' ? paramsFromURL.fromDate : ''
									}
									onChange={({ target: { value } }) => setNewFromDate(value)}
								/>
							</div>
							<div className="ml-4 w-full">
								<Label>To</Label>
								<DatePicker
									className="w-full"
									defaultValue={
										paramsFromURL.searchType === 'date-range' ? paramsFromURL.toDate : ''
									}
									onChange={({ target: { value } }) => setNewToDate(value)}
								/>
							</div>
						</div>
					</div>
				)}
				<Button className="ml-4" disabled={!canClick} kind="primary" onClick={handleClick}>
					Search
				</Button>
			</div>
		</>
	);
};

export default FixedPriceOrderSearchForm;

import React, { Component } from 'react';
import Layout from '../../components/Layout';

class ChangePasswordConfirmation extends Component {
	render() {
		return (
			<Layout isAuthenticated={false}>
				<div className="login-form">
					<h1 className="legacy-h1">Change Password</h1>
					<p>Your password has been successfully updated!</p>
				</div>
			</Layout>
		);
	}
}

export default ChangePasswordConfirmation;

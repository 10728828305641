import React, { FC } from 'react';
import {
	FluentIconsProps,
	Star24Filled,
	ThumbLike24Filled,
	Warning24Filled,
	Dismiss24Regular,
} from '@fluentui/react-icons';
import { noop } from 'lodash';
import { Sentiment } from '../../types';

const TOAST_ICONS: { [sentiment: string]: FC<FluentIconsProps> } = {
	neutral: Star24Filled,
	success: ThumbLike24Filled,
	warning: Warning24Filled,
};
const BACKGROUND_CLASSES: { [sentiment: string]: string } = {
	neutral: 'bg-blue3',
	success: 'bg-success3',
	warning: 'bg-warning3',
};
const TEXT_CLASSES: { [sentiment: string]: string } = {
	neutral: 'text-blue1',
	success: 'text-success2',
	warning: 'text-warning2',
};
const FILL_CLASSES: { [sentiment: string]: string } = {
	neutral: 'fill-blue1',
	success: 'fill-success2',
	warning: 'fill-warning2',
};

const Toast = ({
	message,
	sentiment = 'neutral',
	closeToast = noop,
}: {
	message: string;
	sentiment?: Sentiment;
	closeToast?: () => void;
}) => {
	const Icon = TOAST_ICONS[sentiment];
	const bgClass = BACKGROUND_CLASSES[sentiment];
	const textClass = TEXT_CLASSES[sentiment];
	const fillClass = FILL_CLASSES[sentiment];

	return (
		<div className={`w-72 rounded-lg p-4 shadow-elevation1 ${bgClass}`}>
			<div className={`flex items-center justify-between ${textClass}`}>
				<div className="flex items-center">
					<Icon className={fillClass} />
				</div>
				<span className="mx-4">{message}</span>
				<button onClick={closeToast}>
					<Dismiss24Regular className={fillClass} />
				</button>
			</div>
		</div>
	);
};

export default Toast;

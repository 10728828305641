import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Banner } from '../../../prizm-ui/Banner';
import { Button } from '../../../prizm-ui/Button';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import useAuctionById from '../../../queries/useAuctionById';
import useEmployeeById from '../../../queries/useEmployeeById';
import useIndividualLot from '../../../queries/useIndividualLot';
import useUserById from '../../../queries/useUserById';
import useUserRole from '../../../queries/useUserRole';
import { Auction } from '../../../services/auctionsService';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { EDITOR_ROLES, User, UserRoleName } from '../../../services/userService/userService.model';
import { formatDate, formatName } from '../../../utils/formatters';
import Card from '../../Card';
import LoadingWrapper from '../../LoadingWrapper';
import RestrictedArea from '../../RestrictedArea';
import LotStatusChip from '../../statusChips/LotStatusChip';
import EditLotHeaderModal from './EditLotHeaderModal';

const LotHeader = ({ lotId }: { lotId: string }) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);
	const { data: auction = {} as Auction, status: auctionStatus } = useAuctionById(lot.auction_id);

	const {
		data: currentConsigmentManagerUser = {} as User,
		status: currentConsigmentManagerUserStatus,
	} = useEmployeeById(lot.consignment_manager_id);
	const consigmentManagerName = formatName(
		currentConsigmentManagerUser.givenName,
		currentConsigmentManagerUser.familyName
	);

	const { data: currentSellerUser = {} as User, status: currentSellerUserStatus } = useUserById(
		lot.seller_id
	);
	const sellerName = formatName(currentSellerUser.givenName, currentSellerUser.familyName);

	const [shouldShowEditLotHeaderModal, setShouldShowEditLotHeaderModal] = useState(false);

	const { data: userRole = 'Unknown' as UserRoleName } = useUserRole();

	return (
		<Card>
			<LoadingWrapper
				queryStatuses={[
					lotStatus,
					auctionStatus,
					currentConsigmentManagerUserStatus,
					currentSellerUserStatus,
				]}
			>
				<div className="flex justify-between">
					<div>
						<div className="flex">
							<div className="w-fit mb-1">
								<LotStatusChip as="Tag" status={lot.status} />
							</div>
						</div>
						<div className="text-subtitle2 uppercase">
							<span className={lot.lot_number ? '' : 'text-error2'}>
								Lot {lot.lot_number || 'Number not set'}
							</span>
							<span> - {auction.title}</span>
						</div>
						<a
							className="text-display4 hover:link"
							href={`${process.env.BIDDER_SITE_URL!}/item/${lot.meta_slug}`}
							target="_blank"
							rel="noreferrer"
						>
							{lot.title}
						</a>
						<div className="text-caption1 mt-1">
							<span className="uppercase">
								<span>Ends {formatDate(lot.end_timestamp)}, Managed by </span>
								<span className={consigmentManagerName ? '' : 'text-error2'}>
									{consigmentManagerName || 'Unknown'}
								</span>
								<span> on behalf of </span>
							</span>
							{sellerName ? (
								<Tooltip
									content={`View ${sellerName}'s Consigned Lots`}
									id={`View ${sellerName}'s consigned lots`}
								>
									<Link
										className="block uppercase link"
										to={`/usermanagement/customer/${currentSellerUser.id}/consigned-lots`}
									>
										{sellerName}
									</Link>
								</Tooltip>
							) : (
								<span className="text-error2 uppercase">Unknown</span>
							)}
						</div>
					</div>
					<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager', 'Consignment']}>
						{lot.status !== 'Completed_Sold' && (
							<div>
								<Button
									variant="text"
									size="md"
									icon="Edit"
									onClick={() => setShouldShowEditLotHeaderModal(true)}
								/>
							</div>
						)}
						{shouldShowEditLotHeaderModal && (
							<EditLotHeaderModal
								lot={lot}
								onClose={() => setShouldShowEditLotHeaderModal(false)}
							/>
						)}
					</RestrictedArea>
				</div>
				{lot.description ? (
					<div className="my-4" dangerouslySetInnerHTML={{ __html: lot.description }} />
				) : (
					<div className="my-8">
						<Banner
							variant="warning"
							icon="Info"
							title="This lot does not have a description yet"
							description="Lot description is a required field"
							buttonText={EDITOR_ROLES.includes(userRole) ? 'Add Description' : ''}
							onClick={() => setShouldShowEditLotHeaderModal(true)}
						/>
					</div>
				)}

				<div className="uppercase text-caption2 flex justify-between">
					<span>Serial {lot.serial_number || 'Unset'}</span>
					<span>
						Located at {lot.room || 'Room Unset'}, {lot.bin || 'Bin Unset'}
					</span>
				</div>
			</LoadingWrapper>
		</Card>
	);
};

export default LotHeader;

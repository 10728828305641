import { AuthenticationStatus } from '../../../../services/lotsService/lotsService.model';
import { SimpleDropdownMenuItem, TypedDropdownMenuItem } from '../../../Dropdown/Dropdown.model';

export const INTERESTED_PARTIES_FIELDS = [
	'seller_email',
	'seller_id',
	'consignment_manager_email',
	'consignment_manager_id',
];

export const AUTHENTICATION_STATUS_OPTIONS: TypedDropdownMenuItem<AuthenticationStatus>[] = [
	{ value: 'NeedsAuthentication', label: 'Needs Authentication' },
	{ value: 'NeedsReholder', label: 'Needs Reholder' },
	{ value: 'CurrentlyBeingAuthenticated', label: 'Currently Being Authenticated' },
	{ value: 'Completed', label: 'Completed' },
];
export const BLANK_AUTHENTICATION_STATUS_OPTION: SimpleDropdownMenuItem = { label: '', value: '' };

import { ChangeEvent, KeyboardEventHandler, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/DatePicker';
import Dropdown from '../../../../components/Dropdown';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import { getQueryParamsObject } from '../../../../utils/formatters';
import {
	BaseFixedPriceSellerStatementSearchParams,
	BLANK_SELLER_STATEMENT_SEARCH_BY_DATE_RANGE_PARAMS,
	BLANK_SELLER_STATEMENT_SEARCH_BY_QUERY_PARAMS,
	FixedPriceSellerStatementSearchOrderStatusOption,
	FixedPriceSellerStatementSearchParams,
	FixedPriceSellerStatementSearchType,
	FIXED_PRICE_SELLER_STATEMENT_SEARCH_ORDER_STATUS_OPTIONS,
} from './FixedPriceSellerStatementSearchForm.model';

const FixedPriceSellerStatementSearchForm = ({
	onSubmit,
}: {
	onSubmit: (searchParams: FixedPriceSellerStatementSearchParams) => void;
}) => {
	const { search } = useLocation();
	const paramsFromURL = getQueryParamsObject<BaseFixedPriceSellerStatementSearchParams>(search);
	const {
		searchType = 'search-query',
		searchQuery: searchQueryFromParams = '',
		fromDate = '',
		toDate = '',
		status = 'Paid',
	} = paramsFromURL;

	const [newStatus, setNewStatus] =
		useState<FixedPriceSellerStatementSearchOrderStatusOption>(status);
	const [newFromDate, setNewFromDate] = useState(fromDate);
	const [newToDate, setNewToDate] = useState(toDate);

	const [queryType, setQueryType] = useState<FixedPriceSellerStatementSearchType>(searchType);
	const [searchQuery, setSearchQuery] = useState(searchQueryFromParams);

	useEffect(() => {
		if (searchType !== queryType) {
			setQueryType(searchType);
		}
		if (searchQuery !== searchQueryFromParams) {
			setSearchQuery(searchQueryFromParams);
		}
		if (fromDate !== newFromDate) {
			setNewFromDate(fromDate);
		}
		if (toDate !== newToDate) {
			setNewToDate(toDate);
		}
		if (status !== newStatus) {
			setNewStatus(status);
		}
	}, [searchType, searchQueryFromParams, fromDate, toDate, status]);

	const handleChange = ({ target: { id } }: ChangeEvent<HTMLInputElement>) => {
		const selectedQueryType = id as FixedPriceSellerStatementSearchType;

		const blankParamsForSearchType =
			selectedQueryType === 'search-query'
				? BLANK_SELLER_STATEMENT_SEARCH_BY_QUERY_PARAMS
				: BLANK_SELLER_STATEMENT_SEARCH_BY_DATE_RANGE_PARAMS;

		setQueryType(selectedQueryType);
		onSubmit(blankParamsForSearchType);
	};

	const handleClick = () => {
		const searchParams: FixedPriceSellerStatementSearchParams =
			queryType === 'date-range'
				? {
						fromDate: newFromDate,
						status: newStatus,
						searchType: 'date-range',
						toDate: newToDate,
				  }
				: { searchQuery, searchType: 'search-query' };

		onSubmit(searchParams);
	};

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
		if (searchQuery.length > 0 && key === 'Enter') {
			handleClick();
		}
	};

	const dateRangeIsValid = queryType === 'date-range' && newStatus && (newFromDate || newToDate);
	const canSearch =
		dateRangeIsValid ||
		(queryType === 'search-query' && !!searchQuery && searchQuery !== searchQueryFromParams);

	return (
		<>
			<div>
				<Label>Search by:</Label>
				<div className="flex">
					<div className="flex items-center mr-4">
						<input
							checked={queryType === 'search-query'}
							id="search-query"
							name="query-type"
							type="radio"
							onChange={(e) => handleChange(e)}
						/>
						<span className="ml-1">Search Query</span>
					</div>
					<div className="flex">
						<input
							checked={queryType === 'date-range'}
							id="date-range"
							name="query-type"
							type="radio"
							onChange={(e) => handleChange(e)}
						/>
						<span className="ml-1">Date Range</span>
					</div>
				</div>
			</div>
			<div className="flex items-end justify-between">
				{queryType === 'search-query' && (
					<div className="mt-4 w-full">
						<div>
							<Label>Search Query</Label>
							<Input
								autoFocus
								className="w-full"
								value={queryType === 'search-query' ? searchQuery : ''}
								onChange={({ target: { value } }) => setSearchQuery(value)}
								onKeyDown={handleKeyDown}
								placeholder="Search by email or statement number ..."
							/>
						</div>
					</div>
				)}
				{queryType === 'date-range' && (
					<div className="mt-4 w-full">
						<div className="w-full">
							<Label>Order Status</Label>
							<Dropdown
								key="fixed-price-order-status"
								name="order-status"
								options={FIXED_PRICE_SELLER_STATEMENT_SEARCH_ORDER_STATUS_OPTIONS}
								onChange={({ value }) =>
									setNewStatus(value as FixedPriceSellerStatementSearchOrderStatusOption)
								}
							/>
						</div>
						<div className="flex mt-4 w-full">
							<div className="w-full">
								<Label>From</Label>
								<DatePicker
									className="w-full"
									defaultValue={searchType === 'date-range' ? fromDate : ''}
									onChange={({ target: { value } }) => setNewFromDate(value)}
								/>
							</div>
							<div className="ml-4 w-full">
								<Label>To</Label>
								<DatePicker
									className="w-full"
									defaultValue={searchType === 'date-range' ? toDate : ''}
									onChange={({ target: { value } }) => setNewToDate(value)}
								/>
							</div>
						</div>
					</div>
				)}
				<Button className="ml-4" disabled={!canSearch} kind="primary" onClick={handleClick}>
					Search
				</Button>
			</div>
		</>
	);
};

export default FixedPriceSellerStatementSearchForm;

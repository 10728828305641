import { useParams } from 'react-router-dom';
import useDecision from '../../../../optimizely/useDecision';
import { Auction } from '../../../../services/auctionsService';
import WinnerSummaryDashboard from '../../../Auctions/WinnerSummary/Dashboard';
import InvoicesTable from '../../../../components/InvoicesTable';
import { FeatureFlags } from '../../../../optimizely/useDecision/useDecision.model';

const InvoicesTab = ({
	legacyAuctionMap,
	legacyGetInvoices,
}: {
	legacyAuctionMap: Auction[];
	legacyGetInvoices: () => void;
}) => {
	const { userId = '' } = useParams<{ userId: string }>();

	const shouldShowNewInvoicesTable = useDecision(FeatureFlags.NewInvoicesTable);

	return shouldShowNewInvoicesTable ? (
		<InvoicesTable mode="user" userId={userId} />
	) : (
		<WinnerSummaryDashboard
			key="Admin-Invoice-02"
			auction={{ title: 'Invoices' }}
			auction_map={legacyAuctionMap}
			getInvoices={legacyGetInvoices}
		/>
	);
};

export default InvoicesTab;

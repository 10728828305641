import { useMutation, useQueryClient } from 'react-query';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Button } from '../../../../../prizm-ui/Button';
import { QueryKeys } from '../../../../../queries/queryKeys';
import {
	KernelSellerStatementDetails,
	SellerStatementStatusType,
	updateSellerStatement,
} from '../../../../../services/sellerStatementService';

const MarkAsPaidButton = ({
	auctionId,
	statementId,
	currentStatus,
}: {
	auctionId: string;
	statementId: string;
	currentStatus: SellerStatementStatusType;
}) => {
	const deltas: Partial<KernelSellerStatementDetails> = {
		seller_statement_status: currentStatus === 'Unpaid' ? 'Paid' : 'Unpaid',
	};
	const { mutate: doMarkAsPaid, status: markAsPaidStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateSellerStatement, auctionId, statementId, deltas],
		mutationFn: () => updateSellerStatement({ auctionId, statementId, deltas }),
	});

	const queryClient = useQueryClient();
	const handleMarkStatementAsPaid = () =>
		doMarkAsPaid(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully marked statement as paid');
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementDetails]);
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementPDF]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong marking statement as paid. If the issue persists after refreshing your browser, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<Button
			variant="secondary"
			size="md"
			className="mt-2 w-full"
			loading={markAsPaidStatus === 'loading'}
			onClick={handleMarkStatementAsPaid}
		>
			{currentStatus === 'Paid' ? 'Mark as Unpaid' : 'Mark as Paid'}
		</Button>
	);
};

export default MarkAsPaidButton;

import { useQuery } from 'react-query';
import { getInvoices } from '../../services/invoicesService';
import { QueryKeys } from '../queryKeys';

const useInvoices = ({
	auctionId,
	filters = {},
	invoiceStatus = '',
}: {
	auctionId: string;
	filters?: Record<string, string>;
	invoiceStatus?: string;
}) =>
	useQuery([QueryKeys.GetInvoiceDetails, auctionId, filters], () =>
		getInvoices({ auction_id: auctionId, filters, invoice_status: invoiceStatus })
	);

export default useInvoices;

export const CONSIGNED_LOTS_TAB_CSV_COLUMN_NAMES = [
	{
		label: 'Serial Number',
		value: 'serial_number',
	},
	{
		label: 'Auction ID',
		value: 'auction_id',
	},
	{
		label: 'Auction Title',
		value: 'auction_title',
	},
	{
		label: 'Lot Number',
		value: 'lot_number',
	},
	{
		label: 'Title',
		value: 'title',
	},
	{
		label: 'Price',
		value: 'current_price',
	},
	{
		label: 'Lot Status',
		value: 'status',
	},
	{
		label: 'Buyer Premium',
		value: 'buyer_premium',
	},
	{
		label: 'Commission',
		value: 'commission',
	},
	{
		label: 'Invoice Status',
		value: 'invoice_status',
	},
	{
		label: 'Seller Statement Number',
		value: 'seller_statement_number',
	},
	{
		label: 'Seller Statement Status',
		value: 'seller_statement_status',
	},
];

import { postAuthed } from '../../AdminUtils/httpUtils';
import {
	BidActivityForUser,
	GetBidActivityForUserParams,
	KernelBidActivityForUser,
} from './getBidActivityForUser.model';
import { AxiosResponse } from 'axios';
import apiEndpoints from '../apiEndpoints';

const getBidActivityForUser = async (userId: string) => {
	const response = await postAuthed<
		GetBidActivityForUserParams,
		AxiosResponse<KernelBidActivityForUser[]>
	>(`${apiEndpoints.bidding_actions}/get_bid_activity`, {
		user_id: userId,
	});

	if (response.status >= 400) {
		throw response;
	}

	// Filter out items that would appear as 0/0/0 for bids/won lots/total value
	const filteredBidActivity: KernelBidActivityForUser[] = response.data.filter(
		(kernelBidActivityItem) =>
			kernelBidActivityItem.number_of_bids ||
			kernelBidActivityItem.number_won_lots ||
			kernelBidActivityItem.total_value_won_lots
	);

	const mappedBidActivity: BidActivityForUser[] = filteredBidActivity.map(
		(kernelBidActivityItem) => ({
			title: kernelBidActivityItem.title,
			startTimestamp: kernelBidActivityItem.start_timestamp,
			endTimestamp: kernelBidActivityItem.end_timestamp,
			status: kernelBidActivityItem.status,
			numberOfBids: kernelBidActivityItem.number_of_bids,
			numberOfWonLots: kernelBidActivityItem.number_won_lots || 0,
			totalValueOfWonLots: kernelBidActivityItem.total_value_won_lots,
			auctionId: kernelBidActivityItem.auction_id,
			lotId: kernelBidActivityItem.lot_id,
		})
	);

	return mappedBidActivity;
};

export default getBidActivityForUser;

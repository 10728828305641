import React from 'react';
import type { WithRequired } from '../types';

export type ToggleProps = {
	onChange?: (value: boolean) => void;
} & WithRequired<Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'>, 'aria-label'>;

export const Toggle = ({
	'aria-label': ariaLabel,
	onChange,
	checked,
	disabled,
	...props
}: ToggleProps) => {
	const [_checked, setChecked] = React.useState(checked);
	const isControlled = checked != null;

	React.useEffect(() => {
		setChecked(checked);
	}, [checked, setChecked]);

	const labelKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.code === 'Enter' || e.code === 'Space') {
			toggleCheckbox();
		}
	};

	const labelClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		toggleCheckbox();
	};

	const toggleCheckbox = () => {
		if (disabled) return;

		if (!isControlled) {
			setChecked((c) => !c);
		}
		onChange?.(!_checked);
	};

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		if (disabled) return;

		if (!isControlled) {
			setChecked(e.currentTarget.checked);
		}
		onChange?.(e.currentTarget.checked);
	};

	return (
		<label
			className="relative block w-12 h-8 group focus:outline-none"
			tabIndex={0}
			onClick={labelClick}
			onKeyDown={labelKeyDown}
		>
			<input
				type="checkbox"
				className="peer opacity-0 w-0 h-0"
				onChange={handleChange}
				role="switch"
				checked={_checked}
				disabled={disabled}
				aria-label={ariaLabel}
				{...props}
			/>

			<div
				className="absolute inset-0 rounded-full bg-neutralstroke1
        hover:brightness-90 peer-disabled:hover:brightness-100
        group-focus:outline-none group-focus-visible:ring-2 group-focus-visible:ring-offset-2 group-focus-visible:ring-primary-500
        peer-checked:bg-primary-500 peer-disabled:bg-disabled
        cursor-pointer peer-disabled:cursor-auto
        transition-all"
			></div>

			<div
				className="absolute top-0.5 left-0.5 peer-checked:left-auto peer-checked:right-0.5
        w-7 h-7 rounded-full pointer-events-none
        bg-inverted peer-disabled:bg-neutralstroke2
        transition-all shadow-elevation1"
			></div>
		</label>
	);
};

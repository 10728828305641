import { useMutation } from 'react-query';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { updateLot } from '../../../../services/lotsService';
import useIndividualLot from '../../../../queries/useIndividualLot';

const WithdrawLotModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const diffs: Partial<KernelLot> = {
		lot_id: lot.lot_id,
		status: 'Withdrawn',
		has_end_timestamp_changed: true,
		lot_status_changed: true,
	};
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id],
		mutationFn: (updates: Partial<KernelLot>) => updateLot(updates),
	});

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const handleSetToWithdrawn = () =>
		doUpdateLot(diffs, {
			onSuccess: (updatedLot: KernelLot) => {
				updateLotInCache(updatedLot);
				showSuccessToast('Successfully withdrew lot');
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong while withdrawing the lot. Please try again later.');
			},
		});

	return (
		<ActionModal
			title="Withdraw Lot"
			onClose={onClose}
			onConfirm={handleSetToWithdrawn}
			className="max-w-xl"
			isLoading={updateLotStatus === 'loading'}
		>
			{`"${lot.title}" will be withdrawn from the current auction. Are you sure?`}
		</ActionModal>
	);
};

export default WithdrawLotModal;

import React, { useState } from 'react';
import ActionModal from '../../../ActionModal';
import Dropdown from '../../../Dropdown';
import Label from '../../../Label';
import { TypedDropdownMenuItem } from '../../../Dropdown/Dropdown.model';
import {
	KernelLot,
	KernelLotAdjustment,
	LotAdjustment,
	LotAdjustmentCategory,
} from '../../../../services/lotsService/lotsService.model';
import Input from '../../../Input';
import { useMutation, useQueryClient } from 'react-query';
import { createLotAdjustment } from '../../../../services/lotsService';
import { QueryKeys } from '../../../../queries/queryKeys';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { CurrentUser } from '../../../../services/userService/userService.model';
import useCurrentUser from '../../../../queries/useCurrentUser';

const AddLotAdjustmentModal = ({ lotId, onClose }: { onClose: () => void; lotId: string }) => {
	const BASE_ADJUSTMENT: LotAdjustment = {
		amount: 0,
		category: 'Payments',
		description: '',
		type: 'Credit',
	};

	const [draftAdjustment, setDraftAdjustment] = useState(BASE_ADJUSTMENT);
	const updateDraftAdjustment = (updatedFields: Partial<LotAdjustment>) =>
		setDraftAdjustment({ ...draftAdjustment, ...updatedFields });

	const categoryOptions: TypedDropdownMenuItem<LotAdjustmentCategory>[] = [
		{
			value: 'Payments',
			label: 'Payments',
		},
		{
			value: 'Offsets',
			label: 'Offsets',
		},
		{
			value: 'Adjustments',
			label: 'Adjustments',
		},
		{
			value: 'Advances',
			label: 'Advances',
		},
	];

	const typeOptions: TypedDropdownMenuItem<'Credit' | 'Debit'>[] = [
		{ value: 'Credit', label: 'Credit' },
		{ value: 'Debit', label: 'Debit' },
	];

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const { data: lot = {} as KernelLot, updateLot: updateLotInCache } = useIndividualLot(lotId);
	const { mutate: doCreateAdjustment, status: createAdjustmentStatus } = useMutation({
		mutationFn: () => createLotAdjustment({ ...draftAdjustment, lotId }),
		mutationKey: [QueryKeys.CreateLotAdjustment, draftAdjustment],
	});

	const queryClient = useQueryClient();

	const handleCreateAdjustment = () =>
		doCreateAdjustment(undefined, {
			onSuccess: ({ id }) => {
				const { adjustments: currentLotAdjustments = [] as KernelLotAdjustment[] } = lot;
				updateLotInCache({
					adjustments: [
						...currentLotAdjustments,
						{
							...draftAdjustment,
							id,
							createdAt: new Date().toISOString(),
							createdBy: {
								id: currentUser.currentUserId,
								email: currentUser.currentUserEmail,
								fullName: currentUser.currentUserFullName,
							},
						},
					],
				});

				queryClient.invalidateQueries([QueryKeys.GetIndividualLot, lotId]);

				showSuccessToast('Successfully issued adjustment');
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong issuing adjustment. Please try again later.');
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleCreateAdjustment}
			title="Add Lot-Level Adjustment"
			isLoading={createAdjustmentStatus === 'loading'}
			canConfirm={
				createAdjustmentStatus !== 'loading' &&
				!!draftAdjustment.amount &&
				!!draftAdjustment.description
			}
		>
			<div className="mb-4">
				<Label htmlFor="category">Category</Label>
				<Dropdown
					options={categoryOptions}
					name="category"
					onChange={({ value }) =>
						updateDraftAdjustment({ category: value as LotAdjustmentCategory })
					}
				/>
			</div>
			<div className="mb-4 flex">
				<div className="mr-4 w-1/3">
					<Label htmlFor="type">Type</Label>
					<Dropdown
						name="type"
						options={typeOptions}
						onChange={({ value }) => updateDraftAdjustment({ type: value as 'Credit' | 'Debit' })}
					/>
				</div>
				<div className="w-1/3">
					<Label htmlFor="amount">Amount</Label>
					<Input
						type="number"
						name="amount"
						className="w-full"
						onChange={({ target: { value } }) =>
							updateDraftAdjustment({ amount: !isNaN(Number(value)) ? Number(value) : undefined })
						}
						value={draftAdjustment.amount}
					/>
				</div>
			</div>
			<div>
				<Label htmlFor="description">Description</Label>
				<Input
					name="description"
					className="w-full"
					onChange={({ target: { value } }) => updateDraftAdjustment({ description: value })}
					value={draftAdjustment.description}
				/>
			</div>
		</ActionModal>
	);
};

export default AddLotAdjustmentModal;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormErrors } from '../FormErrors';
import { FormValidation } from '../FormValidation';
import { Auth } from 'aws-amplify';
import { setChallengeName, setIsAuthenticated, setUser } from '../../reducers/ThemeOptions';
import { connect } from 'react-redux';
import * as awsUtils from '../../AwsUtils';
import UnauthedLayout from '../../components/Layout/UnauthedLayout';
import Input from '../../components/Input';
import Label from '../../components/Label';
import Button from '../../components/Button';
import { QueryKeys } from '../../queries/queryKeys';

class LogIn extends Component {
	constructor(props) {
		super(props);

		this.onLoad = this.onLoad.bind(this);
		this.onLoad();

		this.state = {
			username: '',
			password: '',
			errors: {
				cognito: null,
				blankfield: false,
			},
			isAuthenticating: true,
			isAuthenticated: false,
		};
	}

	// Based on a pattern from https://serverless-stack.com/chapters/load-the-state-from-the-session.html
	async onLoad() {
		try {
			await Auth.currentSession();

			this.setState({
				isAuthenticated: true,
				isAuthenticating: false,
			});
		} catch (e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}

		this.setState({
			isAuthenticating: false,
		});
	}

	clearErrorState = () => {
		this.setState({
			errors: {
				cognito: null,
				blankfield: false,
			},
		});
	};

	handleSubmit = async (event) => {
		this.setState({ isSubmitting: true });

		// Form validation
		this.clearErrorState();
		const error = FormValidation(event, this.state);
		if (error) {
			this.setState({
				errors: { ...this.state.errors, ...error },
			});
		}

		// AWS Cognito integration here
		try {
			const user = await Auth.signIn(this.state.username, this.state.password);
			this.props.setUser(user);
			this.props.queryClient.setQueryData([QueryKeys.GetCurrentUser], () => ({
				currentUserEmail: user.email,
				currentUserFullName: `${user.given_name} ${user.family_name}`,
				currentUserId: user.sub,
			}));
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				this.props.setChallengeName(user.challengeName);
				this.props.history.push('/changepassword');
			} else {
				awsUtils.configureAWS();
				this.props.setIsAuthenticated(true);

				const redirectTo =
					this.props?.history?.location?.state?.from?.pathname ?? '/Performance/Summary';

				this.props.history.push(redirectTo);

				this.setState({
					isAuthenticating: false,
					isAuthenticated: true,
				});
			}
		} catch (error) {
			let err = null;
			this.setState({ isSubmitting: false });
			!error.message ? (err = { message: error }) : (err = error);
			console.log(error);
			this.setState({
				errors: {
					...this.state.errors,
					cognito: err,
				},
			});
		}
	};

	onInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
		document.getElementById(event.target.id).classList.remove('is-danger');
	};

	render() {
		return (
			<UnauthedLayout>
				<h1 className="legacy-h1 mb-8 text-center">Log In</h1>
				{/* todo:turn this into a pretty alert */}
				<FormErrors formerrors={this.state.errors} />

				<div className="field">
					<Label>Email</Label>
					<Input
						className="form-control"
						type="text"
						name="username"
						aria-describedby="usernameHelp"
						value={this.state.username}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="field mt-2">
					<Label>Password</Label>
					<Input
						className="form-control"
						type="password"
						name="password"
						value={this.state.password}
						onChange={this.onInputChange}
					/>
				</div>

				<div className="mt-8 w-full">
					<Button
						className="button is-success w-full"
						kind="primary"
						onClick={this.handleSubmit}
						isLoading={this.state.isSubmitting}
					>
						Login
					</Button>
					<Link to="/forgotpassword" className="mt-4 block link">
						Forgot password?
					</Link>
				</div>
			</UnauthedLayout>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	setIsAuthenticated: (enable) => dispatch(setIsAuthenticated(enable)),
	setUser: (user) => dispatch(setUser(user)),
	setChallengeName: (challengeName) => dispatch(setChallengeName(challengeName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);

import React, { useState } from 'react';
import Dropdown from '../../../../../components/Dropdown';
import { BidderDropdownMenuItem } from '../../../../../components/ManageIndividualLot/AdministrativeActions/PlaceBidModal/PlaceBidModal.model';
import { searchBidder } from '../../../../../services/bidderService/searchBidder';
import { UserSearchResults } from '../../../../../services/bidderService/searchBidder.model';

const UserPicker = ({
	setSelectedValue,
}: {
	setSelectedValue: (newValue: BidderDropdownMenuItem | null) => void;
}) => {
	const [userSearchQuery, setUserSearchQuery] = useState<string>();

	const loadOptions = async (searchQuery: string): Promise<BidderDropdownMenuItem[]> => {
		const searchResults: UserSearchResults[] = (await searchBidder(
			searchQuery
		)) as unknown as UserSearchResults[];

		return searchResults.map(
			(user) =>
				({
					value: { userId: user.id ?? '', biddingPower: user.biddingPower ?? 0 },
					label: `${user.givenName} ${user.familyName} (${user.email})`,
				} as BidderDropdownMenuItem)
		);
	};

	return (
		<div>
			<Dropdown
				name="users"
				kind="async"
				onChange={(selectedOption) => {
					setSelectedValue(selectedOption as BidderDropdownMenuItem);
				}}
				loadOptions={async () => {
					return await loadOptions(userSearchQuery || '');
				}}
				onInputChange={(newValue) => {
					setUserSearchQuery(newValue);
				}}
				placeholder="Search for user email"
			/>
		</div>
	);
};

export default UserPicker;

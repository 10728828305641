import { Chip } from '../../../prizm-ui/Chip';
import { Sentiment } from '../../../prizm-ui/types';

const getSentimentForUserStatusChip = (status: boolean): Sentiment => {
	switch (status) {
		case false:
			return 'error';
		case true:
			return 'success';
		default:
			return 'gray';
	}
};

const UserStatusChip = ({ className = '', status }: { className?: string; status: boolean }) => {
	const statusLabel = status ? 'Active' : 'Disabled';

	return (
		<Chip className={className} variant={getSentimentForUserStatusChip(status)}>
			{statusLabel}
		</Chip>
	);
};

export default UserStatusChip;

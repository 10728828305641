import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';
import Editor from '../../zenauction-ui-shared/Components/Elements/Editor';

/**
 * @deprecated - End users should only be allowed to edit HTML under use cases of proven
 * requirement. The Editor fires a callback on mount that formats whatever you feed it into
 * HTML, which will throw off diffing whether something has changed. Use Textarea instead
 * unless you absolutely have to.
 */
const HtmlTextarea = ({
	allowedRoles = [],
	initialValue,
	onChange,
	placeholder,
}: {
	allowedRoles?: UserRoleName[];
	initialValue: string;
	onChange: (newValue: string) => void;
	placeholder?: string;
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	return isForbidden ? (
		<>
			{initialValue ? (
				<div dangerouslySetInnerHTML={{ __html: initialValue }} />
			) : (
				<div className="italic">No value provided</div>
			)}
		</>
	) : (
		<Editor
			placeholder={placeholder}
			initialValue={initialValue}
			updateContentCallback={onChange}
		/>
	);
};

export default HtmlTextarea;

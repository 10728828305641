import React from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { Icon } from '../Icon';
import {
	ErrorText,
	getInputClasses,
	INPUT_HEIGHT_CLASS,
	HelpText,
	Label,
} from '../Input/Input.shared';
import { DropdownMenuResponse } from '../Menu/types';
import { mergeRefs } from '../utils';

type Option = {
	index: number;
	label: string;
	value: string;
};

type SelectProps = {
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
	errorText?: string;
	helpText?: string;
	id: string;
	invalid?: boolean;
	label: string;
	native?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
	value?: string;
};

export const SelectContext = React.createContext<{
	menu?: DropdownMenuResponse<HTMLButtonElement>;
	native?: boolean;
	onItemClick?: (newValue: string) => void;
	registerItem?: (option: Option) => void;
	removeItem?: (option: Option) => void;
	selectedValue?: string;
}>({});

const SelectArrow = () => (
	<div
		tabIndex={-1}
		aria-hidden
		className={[
			'absolute',
			'top-[22px]',
			'right-5',
			'flex',
			'items-center',
			'pointer-events-none',
			'text-neutral3',
		].join(' ')}
	>
		<Icon name="ChevronDown" size="md" aria-hidden />
	</div>
);

export const Select = React.forwardRef(
	(
		{
			children,
			className,
			disabled,
			errorText,
			helpText,
			id,
			invalid,
			label,
			native,
			onChange,
			value,
		}: SelectProps,
		ref?: React.ForwardedRef<HTMLSelectElement | HTMLInputElement>
	) => {
		const selectRef = React.useRef<HTMLSelectElement>(null);
		const inputRef = React.useRef<HTMLInputElement>(null);
		const [_value, setValue] = React.useState(value);
		const isControlled = value != null;

		React.useEffect(() => {
			setValue(value);
		}, [value]);

		const handleChange = (e: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => {
			if (!isControlled) {
				setValue(e.currentTarget.value);
			}

			onChange?.(e);
			menu.buttonProps.ref.current?.focus();
			// Menu will not close without the timeout
			setTimeout(() => menu.setIsOpen(false), 0);
		};

		const selectProps = {
			id,
			disabled,
			value,
		};

		const [itemCount, setItemCount] = React.useState(0);
		const [options, setOptions] = React.useState<{ [key: string]: Option }>({});
		const menu = useDropdownMenu(itemCount);
		const registerItem = (option: Option) => {
			setItemCount((ic) => ic + 1);
			setOptions((o) => ({
				...o,
				[option.value]: option,
			}));
		};
		const removeItem = (option: Option) => {
			setItemCount((ic) => ic - 1);
			setOptions((o) => {
				const options = { ...o };

				delete options[option.value];

				return options;
			});
		};
		const onItemClick = (newValue: string) => {
			if (inputRef.current) {
				// Apparently, react overrides the input value setter so it can dedupe multiple change events
				// Workaround outlined here: https://stackoverflow.com/a/46012210
				Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set?.call(
					inputRef.current,
					newValue
				);

				inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
			}
		};
		const onMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
			menu.buttonProps.onClick?.(e);
			if (!menu.isOpen && _value != null) {
				setTimeout(() => menu.moveFocus(options[_value ?? '']?.index), 0);
			}
		};
		const onMenuKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
			menu.buttonProps.onKeyDown?.(e);
			if (!menu.isOpen && _value != null) {
				setTimeout(() => menu.moveFocus(options[_value ?? '']?.index), 0);
			}
		};
		const buttonLabel = options[_value ?? '']?.label;

		return (
			<SelectContext.Provider
				value={{
					menu,
					native,
					onItemClick,
					registerItem,
					removeItem,
					selectedValue: _value,
				}}
			>
				<div className={className}>
					<div className={['relative', INPUT_HEIGHT_CLASS, 'group'].join(' ')}>
						{native ? (
							<select
								ref={ref ? mergeRefs([ref, selectRef]) : selectRef}
								{...selectProps}
								aria-invalid={invalid}
								aria-errormessage={invalid ? `${id}-error` : undefined}
								aria-describedby={helpText ? `${id}-hint` : undefined}
								className={[getInputClasses({ invalid }), 'appearance-none', 'pr-12'].join(' ')}
								onChange={handleChange}
							>
								{children}
							</select>
						) : (
							<>
								<input
									ref={ref ? mergeRefs([ref, inputRef]) : inputRef}
									id={id}
									name={id}
									type="hidden"
									value={_value ?? ''}
									onInput={handleChange}
								/>
								<button
									type="button"
									disabled={disabled}
									className={[getInputClasses({ invalid }), 'h-full', 'text-left'].join(' ')}
									{...menu.buttonProps}
									onClick={onMenuClick}
									onKeyDown={onMenuKeyDown}
								>
									{buttonLabel}
								</button>
								<ul
									role="menu"
									className={[
										'absolute',
										'z-10',
										'top-full',
										'w-full',
										'max-h-80',
										'overflow-y-auto',
										'p-2',
										'mt-2',
										'flex',
										'flex-col',
										'bg-neutralbkg1',
										'border',
										'border-neutralstroke2',
										'rounded-xl',
										'shadow-elevation1',
										'whitespace-nowrap',
										...(menu.isOpen ? ['visible'] : ['invisible']),
									].join(' ')}
								>
									{children}
								</ul>
							</>
						)}
						<Label
							disabled={disabled}
							id={id}
							invalid={invalid}
							hasValue={!!_value}
							variant="select"
						>
							{label}
						</Label>
						<SelectArrow />
					</div>
					<ErrorText id={id} errorText={errorText} invalid={invalid} />
					<HelpText id={id} errorText={errorText} helpText={helpText} invalid={invalid} />
				</div>
			</SelectContext.Provider>
		);
	}
);

Select.displayName = 'Select';

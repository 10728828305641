import React, { InputHTMLAttributes } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';
import Input from '../Input';

const RadioButton = ({
	allowedRoles = [],
	checked,
	className = '',
	label,
	name,
	onChange,
	onClick,
	...inputProps
}: InputHTMLAttributes<HTMLInputElement> & {
	allowedRoles?: UserRoleName[];
	checked: boolean;
	label: string;
	name: string;
	onChange?: (name: string) => void;
	onClick?: () => void;
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	const handleChange = (name = '') => {
		if (onChange) {
			onChange(name);
		} else if (onClick) {
			onClick();
		}

		return;
	};

	return (
		<button
			className={`flex items-center ${className}`}
			onClick={() => handleChange(name)}
			disabled={inputProps.disabled || isForbidden}
		>
			<Input
				{...inputProps}
				checked={checked}
				className="cursor-pointer"
				disabled={inputProps.disabled || isForbidden}
				type="radio"
			/>
			<span className="ml-2">{label}</span>
		</button>
	);
};

export default RadioButton;

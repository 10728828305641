import Label from '../Label';
import { formatDate } from '../../utils/formatters';

const AuctionSchedule = ({
	previewTimestamp = '',
	startTimestamp = '',
	endTimestamp = '',
}: {
	previewTimestamp?: string;
	startTimestamp?: string;
	endTimestamp?: string;
}) => {
	return (
		<div className="my-4 flex">
			<div>
				<Label htmlFor="start-date">Preview Date</Label>
				<div className="text-body1">{formatDate(previewTimestamp)}</div>
			</div>
			<div className="ml-4">
				<Label htmlFor="start-date">Start Date</Label>
				<div className="text-body1">{formatDate(startTimestamp)}</div>
			</div>
			<div className="ml-4">
				<Label htmlFor="start-date">End Date</Label>
				<div className="text-body1">{formatDate(endTimestamp)}</div>
			</div>
		</div>
	);
};

export default AuctionSchedule;

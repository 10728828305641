import { AccessorKeyColumnDef } from '@tanstack/react-table';
import CurrencyCell from '../cells/CurrencyCell';

export const currencyColumn = <T extends unknown>({
	accessorKey,
	header,
}: {
	accessorKey: keyof T;
	header: string;
}): AccessorKeyColumnDef<T> => ({
	accessorKey,
	header: () => <div className="text-right">{header}</div>,
	enableGlobalFilter: false,
	cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
});

import { useHistory } from 'react-router-dom';
import { Banner } from '../../../../../prizm-ui/Banner';
import { FixedPriceOffer } from '../../../../../services/offersService';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';

const SoldBanner = ({
	askingPrice,
	finalPrice,
	offers,
	orderNumber,
}: {
	askingPrice: number;
	finalPrice: number;
	offers: FixedPriceOffer[];
	orderNumber: string;
}) => {
	const history = useHistory();
	const onClick = () => history.push(`/orders/${orderNumber}`);

	const title =
		askingPrice === finalPrice
			? `Sold for the asking price of ${formatCurrency(finalPrice)}`
			: `Sold for ${formatCurrency(finalPrice)}`;

	const noOffersDescription = 'There were no offers below the asking price.';
	const withOffersDescription =
		askingPrice === finalPrice
			? `There were ${offers.length} lower offers but it sold for the asking Price.`
			: `There were ${offers.length} lower offers against the asking price of ${formatCurrency(
					askingPrice
			  )}`;
	const description = offers.length === 0 ? noOffersDescription : withOffersDescription;

	return (
		<Banner
			variant="success"
			title={title}
			description={description}
			icon="StarFilled"
			buttonText="View Order"
			onClick={onClick}
		/>
	);
};

export default SoldBanner;

import { isEmpty } from 'lodash';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelFixedPriceItemInternalNote, KernelLot } from './lotsService.model';

// Purposely this api does not take a list and only an individual lot because of lambdas which are scalable by parallelizing
// instead of doing it just in 1 lambda
// Here the object is just the lot object, you can just send the whole lot object or just the attributes
// which have been updated
// For example -
// {
//     "Company_Id": "f607a2e3-81a9-4e61-91f9-d7d0b8d01e34",
//     "latestVersion": 1,
//     "Lot_id": "202101-0422-4517-64cbc6f1-e660-4945-a1b9-9034e084aebe",
//     "Title": "DVT Rookie Card",
//     "version": 0
// }
// The only mandatory fields which are required will be Lot_id, Company_Id

export const updateLot = async (lot: Partial<KernelLot>) => {
	const { internal_notes = {} as KernelFixedPriceItemInternalNote } = lot;

	if (!isEmpty(internal_notes) && typeof internal_notes !== 'string') {
		lot.internal_notes = JSON.stringify(internal_notes);
	}

	const response = await axiosAuth.patch(`${config.api.lots}`, lot);
	const updatedLot = response.data.Attributes;

	if (response.status >= 400) {
		throw response;
	}

	return updatedLot;
};

import { Chip } from '../../../prizm-ui/Chip';
import { Tag } from '../../../prizm-ui/Tag';
import { Sentiment } from '../../../prizm-ui/types';
import { ProcessingStatus } from '../../../services/lotsService/lotsService.model';
import { titleCase } from '../../../utils/formatters';

const getSentimentForLotProcessingStatus = (status: ProcessingStatus): Sentiment => {
	switch (status) {
		case 'ToBeAuthenticated':
			return 'error';
		case 'WaitingForEditorial':
			return 'error';
		case 'FailedAuthentication':
			return 'error';
		case 'CurrentlyBeingAuthenticated':
			return 'primary';
		case 'ReadyForAuction':
			return 'success';
		case 'HoldForReturnToConsignor':
			return 'warning';
		case 'ReturnToConsignorComplete':
			return 'gray';
		default:
			return 'gray';
	}
};

const LotAuthenticationStatusChip = ({
	status,
	as = 'Chip',
}: {
	status: ProcessingStatus;
	as?: 'Tag' | 'Chip';
}) => {
	const Component = as === 'Chip' ? Chip : Tag;

	return (
		<Component variant={getSentimentForLotProcessingStatus(status)}>{titleCase(status)}</Component>
	);
};

export default LotAuthenticationStatusChip;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelLotAdjustment, LotAdjustmentCategory } from './lotsService.model';

export const createLotAdjustment = async ({
	category,
	type,
	amount,
	description,
	lotId,
}: {
	category: LotAdjustmentCategory;
	type: 'Credit' | 'Debit';
	amount: number;
	description: string;
	lotId: string;
}) => {
	const response = await axiosAuth.post(`${config.api.lot_adjustments}/lots/${lotId}/adjustments`, {
		category,
		type,
		amount,
		description,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data as KernelLotAdjustment;
};

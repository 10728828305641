import React from 'react';
import { TableCell } from '@material-ui/core';
import { SortableTableColumn } from '../../TypesafeSortableTable/TypesafeSortableTable.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SortableTableHeader = <T extends unknown>({
	columns,
	sortFunc,
	sortIconFunc = noop,
}: {
	columns: SortableTableColumn<T>[];
	sortFunc?: (columnToSortBy: string) => void;
	sortIconFunc?: (columnToSortBy: string) => 'up' | 'down' | 'sort' | void;
}) => {
	const visibleColumns = columns.filter((column) => !column.hidden);

	return visibleColumns.map((column, index) => {
		if (column.noSortIcon) {
			return (
				<TableCell
					key={column.id + index}
					align={column.align ? column.align : 'left'}
					style={{ minWidth: column.minWidth, width: column.width }}
					className="text-nowrap font-weight-bold"
				>
					{column.label}
				</TableCell>
			);
		}

		return (
			<TableCell
				key={column.id + index}
				align={column.align ? column.align : 'left'}
				style={{ minWidth: column.minWidth, width: column.width }}
				className="generic-link text-nowrap font-weight-bold"
				onClick={() => sortFunc && sortFunc(column.id)}
			>
				{column.label}
				<FontAwesomeIcon
					className="opacity-8 ml-2"
					icon={
						sortIconFunc(column.id) === 'up'
							? (faSortUp as IconProp)
							: sortIconFunc(column.id) === 'down'
							? (faSortDown as IconProp)
							: (faSort as IconProp)
					}
				/>
			</TableCell>
		);
	});
};

export default SortableTableHeader;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const deleteLot = async (lotId: string) => {
	const response = await axiosAuth.delete(`${config.api.lots}/${lotId}`);

	if (response.status >= 400) {
		throw response;
	}

	return lotId;
};

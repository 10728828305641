import { Chip } from '../../../prizm-ui/Chip';
import { Sentiment } from '../../../prizm-ui/types';
import { KernelInvoiceStatus } from '../../../services/invoicesService/invoicesService.model';
import { stripUnderscores } from '../../../utils/formatters';

const getSentimentForInvoiceStatus = (status: KernelInvoiceStatus): Sentiment => {
	switch (status) {
		case 'Awaiting_Payment':
			return 'primary';
		case 'Blocked':
			return 'error';
		case 'Defaulted':
			return 'error';
		case 'Draft':
			return 'gray';
		case 'Payment_Failed':
			return 'error';
		case 'Payment_Processing':
			return 'primary';
		case 'Pending_Approval':
			return 'primary';
		case 'Refunded':
			return 'success';
		case 'Paid':
			return 'success';
		case 'User_Submitted':
			return 'gray';
		case 'Void':
			return 'gray';
		default:
			return 'gray';
	}
};

const InvoiceStatusChip = ({ status }: { status?: KernelInvoiceStatus }) => {
	if (!status) return <></>;

	return <Chip variant={getSentimentForInvoiceStatus(status)}>{stripUnderscores(status)}</Chip>;
};

export default InvoiceStatusChip;

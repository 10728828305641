import { FacetTitle } from '../facetsService/getFacets.model';
import { AuctionType, FIXED_PRICE_AUCTION_ID } from '../auctionsService';
import { Pagination } from '../../types';

export type LotStatus =
	| 'Completed_Sold'
	| 'Completed_Unsold'
	| 'End'
	| 'Extended_Bidding'
	| 'Live'
	| 'Not_Started'
	| 'Pause'
	| 'Preview'
	| 'Unknown'
	| 'Withdrawn';

export type FixedPriceItemStatus = 'Live' | 'Withdrawn' | 'Completed_Sold';

export type LotsQueryType =
	| 'All'
	| 'Ending_Soonest'
	| 'Featured'
	| 'Highest_Bids'
	| 'Highest_Lot_Number'
	| 'Least_Bids'
	| 'Lowest_Bids'
	| 'Most_Bids'
	| 'Most_Recent_Bids';

export const CATEGORIES = ['Sport', 'Non-Sport', 'Collectible Shoes'] as const;

// prettier-ignore
export type LotCategory = (typeof CATEGORIES[number]); // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const ITEM_TYPES = [
	'Single Cards',
	'Cases/Boxes/Packs',
	'Sets and Collections',
	'Uncut Sheets',
	'Coins and Currency',
	'Tickets and Passes',
	'Photos and Video Tapes',
	'Video Games',
	'Comics',
	'Memorabilia',
	'Game-Used Memorabilia',
	'Experiences',
	'Programs and Publications',
	'Toys',
	'Art',
	'NFTs and Digital Cards',
	'Original/Vintage Photos',
	'Graded Magazines',
	'Other',
] as const;
// prettier-ignore
export type ItemType = typeof ITEM_TYPES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const SUB_CATEGORIES = [
	'Basketball',
	'Football',
	'Baseball',
	'Soccer',
	'Racing',
	'Hockey',
	'Boxing/MMA',
	'Wrestling',
	'Golf',
	'Tennis',
	'Olympics',
	'Other Sport',
	'Marvel',
	'DC',
	'Pokemon',
	'Star Wars',
	'Magic The Gathering',
	'Yu-Gi-Oh!',
	'Garbage Pail Kids',
	'MetaZoo',
	'VeeFriends',
	'Pop Culture/Entertainment',
	'History',
	'Other',
] as const;
// prettier-ignore
export type LotSubCategory = typeof SUB_CATEGORIES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const GRADING_COMPANIES = ['PSA', 'Beckett', 'CGC', 'SGC', 'WATA', 'Ungraded', 'Other'];
// prettier-ignore
export type GradingCompany = typeof GRADING_COMPANIES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const AUTHENTICATION_STATUSES = [
	'NeedsAuthentication',
	'NeedsReholder',
	'CurrentlyBeingAuthenticated',
	'Completed',
] as const;
// prettier-ignore
export type AuthenticationStatus = typeof AUTHENTICATION_STATUSES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const PROCESSING_STATUSES = [
	'ToBeAuthenticated',
	'WaitingForEditorial',
	'ReadyForAuction',
	'FailedAuthentication',
	'HoldForReturnToConsignor',
	'ReturnToConsignorComplete',
] as const;
// prettier-ignore
export type ProcessingStatus = typeof PROCESSING_STATUSES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const LOTS_SORT_BY_LABELS: { [key in LotsQueryType]: string } = {
	All: 'All Lots',
	Featured: 'Lowest Lot Number',
	Highest_Bids: 'Highest price',
	Lowest_Bids: 'Lowest Price',
	Most_Bids: 'Most Bids',
	Least_Bids: 'Least Bids',
	Most_Recent_Bids: 'Most Recent Bids',
	Highest_Lot_Number: 'Highest Lot Number',
	Ending_Soonest: 'Ending Soonest',
};
export type LotsPageSize = '24' | '48' | '96' | '240';

export type KernelLotKey = keyof KernelLot;

export interface KernelLot {
	adjustments: KernelLotAdjustment[];
	auction_id: string;
	authentication_notes?: string;
	authentication_status?: AuthenticationStatus;
	authentication?: string;
	auto_issuer?: string;
	autograph_grade?: string;
	autograph_type?: string;
	bin?: string;
	buyer_premium: number;
	card_lot_type?: string;
	category?: LotCategory;
	cert_number?: string;
	collectible_id?: string;
	collection?: string;
	collectors_images?: CollectorsImageItem[];
	commission: number;
	commissionDollarAmount?: string;
	company_id: string;
	consignment_manager_email?: string;
	consignment_manager_id?: string;
	contract_id?: string;
	created_at?: string;
	created_by?: string;
	current_price: number;
	decade?: string;
	description: string;
	enable_custom_shipping?: boolean;
	end_timestamp?: string;
	era?: string;
	estimated_value?: number;
	event?: string; // this is a structured content field, not a JS event
	extended_bidding_duration?: string;
	external_images?: CollectorsImageItem[];
	final_price: number;
	grade?: string;
	grading_company?: GradingCompany;
	has_description: boolean;
	has_end_timestamp_changed: boolean;
	images_objects?: string[];
	images: string[];
	internal_notes?: string | KernelFixedPriceItemInternalNote;
	internal_reserve_amount?: number;
	invoice_status?: string;
	item_serial_number?: string; // @TODO - find out is this different than serial_number?
	item_type?: ItemType;
	latest_version?: number;
	location: string;
	lot_grade?: string | number;
	lot_id: string | '__newlyCreatedLot__';
	lot_number: number | string;
	lot_status_changed?: LotStatus | true; // shrug emoji
	manufacturer?: string;
	memorabilia_type?: string;
	meta_description: string;
	meta_slug?: string;
	meta_title: string;
	min_bid_price: number;
	modified_by?: string;
	number_of_bids: number;
	number_of_images: number;
	parallel_type?: string;
	player_subject?: string;
	primary_image_name: string;
	primary_image_object?: string[];
	primary_image: string[];
	processing_status?: ProcessingStatus;
	reserve_amount: number;
	room: string;
	season?: string;
	seller_email?: string;
	seller_id?: string;
	seller_statement_id?: string;
	serial_number: string;
	session?: string;
	set?: string;
	spec_serial_number?: string;
	sport_memorabilia_type?: string;
	sport?: string;
	start_timestamp?: string;
	status: LotStatus;
	sub_category?: LotSubCategory;
	tag: string[];
	team?: string;
	thumbnail_image_name?: string;
	title: string;
	vaultable?: boolean;
	version?: number;
	winner_id?: string;
	year?: string;
}

export interface RemovedLot {
	auctionId: string;
	auctionType: AuctionType;
	buyerPremium: number;
	commission: number;
	createdBy: string;
	createdOn: string;
	currentPrice: number;
	finalPrice: number;
	lotId: string;
	lotNumber: number;
	metaSlug: string;
	reason: 'Invoice_New_Auction';
	sellerId: string;
	serialNumber: string;
	title: string;
	primaryImageName: string;
}

export type MissingFieldOption =
	| 'authentication_notes'
	| 'authentication_status'
	| 'buyer_premium'
	| 'consignment_manager_id'
	| 'category'
	| 'commission'
	| 'description'
	| 'internal_notes'
	| 'min_bid_price'
	| 'current_price'
	| 'reserve_amount'
	| 'status'
	| 'seller_id'
	| 'sub_category'
	| 'additional_images'
	| 'primary_image'
	| 'unsold_lots'
	| 'vaultable';

export interface LotFilters {
	unsold: boolean;
	missing: MissingFieldOption[];
}

export interface Lot {
	auctionId: string;
	buyerPremium: number;
	commission: number;
	companyId: string;
	currentPrice: number;
	description: string;
	endTimestamp: string;
	hasEndTimestampChanged: boolean;
	latestVersion: number;
	id: string;
	images: string[];
	location: string;
	lotNumber: number;
	metaDescription: string;
	metaSlug: string;
	metaTitle: string;
	minBidPrice: number;
	numberOfBids: number;
	numberOfImages: number;
	primaryImageName: string;
	reserveAmount: number;
	room: string;
	serialNumber: string;
	startTimestamp: string;
	status: LotStatus;
	title: string;
	version: number;
}

export type KernelSummaryLot = Partial<KernelLot> & {
	auction_id: string;
	lot_id: string;
	lot_number: number;
	serial_number: string;
	title: string;
};

export interface SearchFacetsList {
	'Featured Categories'?: string[];
	Category?: string[];
	'Sub Category'?: string[];
	Tags?: string[];
	Player?: string[];
	Sport?: string[];
	Team?: string[];
	Season?: string[];
	Manufacturer?: string[];
	Grade?: string[];
	Set?: string[];
}

export interface LotSearchParameters {
	Grade?: string[];
	Manufacturer?: string[];
	Player?: string[];
	Season?: string[];
	Set?: string[];
	Sport?: string[];
	Team?: string[];
	auctionId?: string[];
	category?: string[];
	featuredCategories?: string[];
	from?: number;
	keyword?: string;
	queryType: string;
	show_only?: string;
	size?: number;
	sortOrder?: string;
	subCategory?: string[];
	tags?: string[];
}

export type FacetSearchQueryKey =
	| 'category'
	| 'certification'
	| 'featured_categories'
	| 'grade'
	| 'item_type'
	| 'manufacturer'
	| 'player'
	| 'price'
	| 'season'
	| 'set'
	| 'sport'
	| 'sub_category'
	| 'tags'
	| 'team';

export const FACET_SEARCH_QUERY_KEYS: { [key in FacetTitle]: FacetSearchQueryKey } = {
	'Featured Categories': 'featured_categories',
	'Sub Category': 'sub_category',
	Category: 'category',
	Certification: 'certification',
	Grade: 'grade',
	'Item Type': 'item_type',
	Manufacturer: 'manufacturer',
	Player: 'player',
	Price: 'price',
	Season: 'season',
	Set: 'set',
	Sport: 'sport',
	Tags: 'tags',
	Team: 'team',
};

export type FacetSearchQuery = { [key in FacetSearchQueryKey]?: string[] };

export interface KernelPaginatedLotsResponse {
	lots: KernelLot[];
	total: number;
}
export interface PaginatedLotsResponse {
	lots: Lot[];
	total: number;
}

export type LotAdjustmentCategory = 'Payments' | 'Offsets' | 'Adjustments' | 'Advances';

export interface LotAdjustment {
	amount: number;
	category: LotAdjustmentCategory;
	createdAt?: string;
	createdBy?: {
		id: string;
		email: string;
		fullName: string;
	};
	description: string;
	type: 'Credit' | 'Debit';
}

export interface KernelLotAdjustment {
	amount: number;
	category: LotAdjustmentCategory;
	createdAt: string;
	createdBy: {
		id: string;
		email: string;
		fullName: string;
	};
	description: string;
	id: string;
	type: 'Credit' | 'Debit';
}

export interface BulkLotAdjustment {
	serial_number: string;
	adjustment_type: 'Credit' | 'Debit';
	category: LotAdjustmentCategory;
	description: string;
	amount: number;
}

export interface CollectorsImageItem {
	small: string;
	medium: string;
	large: string;
	xlarge: string;
}

export interface KernelFixedPriceItemInternalNote {
	author?: {
		author_display_name: string;
		author_id: string;
	};
	text: string;
	timestamp: string;
}

export interface KernelFixedPriceItem {
	allow_offers: boolean;
	auction_id: typeof FIXED_PRICE_AUCTION_ID;
	autograph_type?: string;
	buyer_premium: number;
	category?: LotCategory;
	cert_number: string;
	collectible_id: string;
	commission: number;
	created_at: string;
	current_price: string;
	estimated_value?: number;
	external_images: CollectorsImageItem[];
	final_price: number;
	grade: string;
	grading_company: string;
	internal_notes: string;
	item_type?: ItemType;
	lot_id: string;
	meta_slug: string;
	meta_title: string;
	min_offer_price: number;
	player_subject: string;
	seller_email: string;
	seller_id: string;
	serial_number: string;
	set_number: string;
	sport?: string;
	status: FixedPriceItemStatus;
	sub_category: LotSubCategory;
	title: string;
	winner_id: string;
	year: number;
}

export interface FixedPriceItemInternalNote {
	author?: {
		authorDisplayName: string;
		authorId: string;
	};
	text: string;
	timestamp: string;
}

export interface FixedPriceItem {
	allowOffers: boolean;
	auctionId: typeof FIXED_PRICE_AUCTION_ID;
	autographType?: string;
	buyerPremium: number;
	category?: LotCategory;
	certNumber: string;
	collectibleId: string;
	commission: number;
	createdAt: string;
	currentPrice: string;
	estimatedValue?: number;
	externalImages: CollectorsImageItem[];
	finalPrice: number;
	grade: string;
	gradingCompany: string;
	internalNotes: FixedPriceItemInternalNote;
	itemType?: ItemType;
	lotId: string;
	metaSlug: string;
	metaTitle: string;
	minOfferPrice: number;
	playerSubject: string;
	sellerEmail: string;
	sellerId: string;
	serialNumber: string;
	setNumber: string;
	sport?: string;
	status: FixedPriceItemStatus;
	subCategory: LotSubCategory;
	title: string;
	winnerId: string;
	year: number;
}

export interface KernelConsignedLotsResponse extends Pagination {
	lots: KernelLot[];
}

export interface BaseLotHistoryEntry {
	version: number;
	entity: 'Auction' | 'Invoice' | 'SellerStatement';
	date: string;
	modified_by: string;
	modified: {
		email: string;
		familyName: string;
		fullName: string;
		givenName: string;
		id: string;
	};
}

export interface KernelLotHistoryAuctionEntry extends BaseLotHistoryEntry {
	auctionId: string;
	auctionName: string;
}

export interface KernelLotHistoryInvoiceEntry extends BaseLotHistoryEntry {
	invoiceId: string;
	invoiceNumber: string;
	finalPrice: number;
	winnerId: string;
	winner: {
		email: string;
		familyName: string;
		fullName: string;
		givenName: string;
	};
}

export interface KernelLotHistorySellerStatementEntry extends BaseLotHistoryEntry {
	auctionId: string;
	auctionName: string;
	commission: number;
	finalPrice: number;
	sellerId: string;
	seller: {
		email: string;
		familyName: string;
		fullName: string;
		givenName: string;
	};
	sellerStatementId: string;
	sellerStatementNumber: string;
}

export type KernelLotHistory = (
	| KernelLotHistoryAuctionEntry
	| KernelLotHistoryInvoiceEntry
	| KernelLotHistorySellerStatementEntry
)[];

import { NewUser } from '../../../../../services/userService/createUser.model';

export const NEW_USER_TEMPLATE: NewUser = {
	address1: '',
	address2: '',
	birthdate: '',
	buyerNotes: '',
	cellPhone: '',
	city: '',
	company: '',
	country: 'US',
	email: '',
	eveningPhone: '',
	familyName: '',
	faxNumber: '',
	givenName: '',
	phoneNumber: '',
	sasAccountExecutiveId: '',
	sasBuyerId: '',
	sasSellerId: '',
	sellerCommission: 0,
	sellerNotes: '',
	state: '',
	taxpayerId: '',
	verifiedTin: false,
	zipCode: '',
};

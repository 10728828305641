import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import {
	KernelSellerStatement,
	SearchSellerStatementByAuctionParams,
} from './sellerStatementService.model';

export const getSellerStatements = async ({
	auctionId,
	status,
}: SearchSellerStatementByAuctionParams): Promise<KernelSellerStatement[]> => {
	const body = {
		auction_id: auctionId,
		company_id: config.organization.company_id,
		query_type: 'auction',
	};

	const bodyWithFilters =
		status === 'All' ? body : { ...body, filters: { seller_statement_status: status } };

	const response = await postAuthed<{ body: KernelSellerStatement[] }>(
		`${config.api.seller_statements}`,
		bodyWithFilters
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

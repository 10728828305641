import { InvoiceStatus } from '../../../../../../services/ordersService/ordersService.model';
import { stripUnderscores } from '../../../../../../utils/formatters';

export interface OrderStatusDropdownOption {
	label: string;
	value: InvoiceStatus;
}

export const FIXED_PRICE_ORDER_STATUS_DROPDOWN_OPTIONS: OrderStatusDropdownOption[] = [
	{
		label: 'Paid',
		value: 'Paid',
	},
	{
		label: 'Refunded',
		value: 'Refunded',
	},
];

export interface OrderStatusTransitionRule {
	allowedFrom: OrderStatusDropdownOption;
	allowedTo: OrderStatusDropdownOption[];
}

export const AWAITING_PAYMENT_TRANSITION_RULE: OrderStatusTransitionRule = {
	allowedFrom: {
		label: stripUnderscores('Awaiting_Payment'),
		value: 'Awaiting_Payment',
	},
	allowedTo: [
		{
			label: 'Blocked',
			value: 'Blocked',
		},
		{
			label: 'Defaulted',
			value: 'Defaulted',
		},
		{
			label: 'Paid',
			value: 'Paid',
		},
	],
};

export const BLOCKED_TRANSITION_RULE: OrderStatusTransitionRule = {
	allowedFrom: {
		label: 'Blocked',
		value: 'Blocked',
	},
	allowedTo: [
		{
			label: stripUnderscores('Awaiting_Payment'),
			value: 'Awaiting_Payment',
		},
		{
			label: 'Defaulted',
			value: 'Defaulted',
		},
		{
			label: 'Paid',
			value: 'Paid',
		},
	],
};

export const DEFAULTED_TRANSITION_RULE: OrderStatusTransitionRule = {
	allowedFrom: {
		label: 'Defaulted',
		value: 'Defaulted',
	},
	allowedTo: [
		{
			label: stripUnderscores('Awaiting_Payment'),
			value: 'Awaiting_Payment',
		},
		{
			label: 'Blocked',
			value: 'Blocked',
		},
		{
			label: stripUnderscores('User_Submitted'),
			value: 'User_Submitted',
		},
	],
};

export const PAYMENT_FAILED_TRANSITION_RULE: OrderStatusTransitionRule = {
	allowedFrom: {
		label: stripUnderscores('Payment_Failed'),
		value: 'Payment_Failed',
	},
	allowedTo: [
		{
			label: 'Blocked',
			value: 'Blocked',
		},
		{
			label: 'Defaulted',
			value: 'Defaulted',
		},
		{
			label: 'Paid',
			value: 'Paid',
		},
	],
};

export const USER_SUBMITTED_TRANSITION_RULE: OrderStatusTransitionRule = {
	allowedFrom: {
		label: stripUnderscores('User_Submitted'),
		value: 'User_Submitted',
	},
	allowedTo: [
		{
			label: stripUnderscores('Awaiting_Payment'),
			value: 'Awaiting_Payment',
		},
		{
			label: 'Blocked',
			value: 'Blocked',
		},
		{
			label: 'Defaulted',
			value: 'Defaulted',
		},
		{
			label: 'Paid',
			value: 'Paid',
		},
	],
};

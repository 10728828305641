import { BaseFixedPriceSellerStatementSearchParams } from '../../Pages/fixed-price/seller-summary/FixedPriceSellerStatementSearchForm/FixedPriceSellerStatementSearchForm.model';
import { KernelLot } from '../lotsService/lotsService.model';
import { KernelAddress } from '../userService/userService.model';

export type SellerStatementStatusType = 'Paid' | 'Unpaid';

export const SELLER_STATEMENT_SEARCH_FILTERS = ['All', 'Paid', 'Unpaid'];
// prettier-ignore
export type SellerStatementSearchFilter = typeof SELLER_STATEMENT_SEARCH_FILTERS[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds
interface BaseSellerStatementSearchParams {
	status: SellerStatementSearchFilter;
}
export interface SearchSellerStatementByAuctionParams extends BaseSellerStatementSearchParams {
	auctionId: string;
	queryBy: 'auctionId';
}
export interface SearchSellerStatementBySellerParams extends BaseSellerStatementSearchParams {
	sellerId: string;
	queryBy: 'sellerId';
}
export type SearchSellerStatementParams =
	| SearchSellerStatementByAuctionParams
	| SearchSellerStatementBySellerParams;

export const BLANK_FIXED_PRICE_QUERY_PARAMS: BaseFixedPriceSellerStatementSearchParams = {
	searchType: 'search-query',
	searchQuery: '',
	fromDate: '',
	toDate: '',
	status: 'Paid',
};

export interface KernelSellerStatement {
	adjustments: KernelSellerStatementAdjustment[];
	amount_owed: number;
	amount_received: number;
	auction_id: string;
	auction_title?: string;
	comments: string;
	commission: number;
	company_id: string;
	created_at: string;
	net_adjustments: number;
	payout_date: string;
	seller_email: string;
	seller_id: string;
	seller_name: string;
	seller_statement_id: string;
	seller_statement_number: string;
	seller_statement_status: SellerStatementStatusType;
	seller_stripe_payout_account_id: string;
	total_lot_value: number;
	total_ready_for_payout: number;
	total_statement_amount: number;
}

export interface DraftSellerStatementAdjustment {
	adjustment_type: KernelSellerStatementAdjustmentAdjustmentType;
	amount: number;
	category_type: KernelSellerStatementAdjustmentCategoryType;
	description: string;
}
export interface KernelSellerStatementAdjustment extends DraftSellerStatementAdjustment {
	adjustment_id: string;
	created_at: string;
	created_by: string;
}

export type KernelSellerStatementAdjustmentAdjustmentType = 'Credit' | 'Debit' | ''; //@TODO flesh out further

export type KernelSellerStatementAdjustmentCategoryType =
	| 'Adjustments'
	| 'Advances'
	| 'BalanceOffsetAdvance'
	| 'BalanceOffsetPurchase'
	| 'Offsets'
	| 'Payments';

export interface KernelSellerStatementCategory {
	label: string;
	value: KernelSellerStatementAdjustmentCategoryType;
}

export interface KernelFixedPriceSellerStatement {
	adjustments: KernelSellerStatementAdjustment[];
	amountOwed: number;
	amountReceived: number;
	auctionId: 'fixed_price_marketplace';
	comments: string;
	commission: number;
	createdAt: string;
	netAdjustments: number;
	payoutDate: string;
	sellerEmail: string;
	sellerId: string;
	sellerName: string;
	sellerStatementId: string;
	sellerStatementNumber: string;
	sellerStatementStatus: SellerStatementStatusType;
	sellerStripePayoutAccountId: string;
	totalLotValue: number;
	totalReadyForPayout: number;
	totalStatementAmount: number;
}

export interface SellerStatementAdjustment {
	adjustmentId: string;
	adjustmentType: KernelSellerStatementAdjustmentAdjustmentType;
	amount: number;
	categoryType: KernelSellerStatementAdjustmentCategoryType;
	createdAt: string;
	createdBy: string;
	description: string;
}

export interface SellerStatement {
	adjustments: SellerStatementAdjustment[];
	amountOwed: number;
	amountReceived: number;
	auctionId: string;
	commission: number;
	comments: string;
	companyId: string;
	createdAt: string;
	payoutDate: string;
	sellerEmail: string;
	sellerId: string;
	sellerName: string;
	sellerStatementId: string;
	sellerStatementNumber: string;
	sellerStatementStatus: SellerStatementStatusType;
	sellerStripePayoutAccountId: string;
	totalReadyForPayout: number;
}

export type SellerStatementAdjustmentCategory = 'Adjustments'; // @TODO flesh out more

export interface BulkSellerStatementAdjustment {
	seller_statement_number: string;
	adjustment_type: 'Credit' | 'Debit';
	amount: number;
	description: string;
	category_type: SellerStatementAdjustmentCategory;
}

export interface SellerStatementUserInfo {
	bidding_power: number;
	billing_address?: KernelAddress;
	company_id: string;
	credit_limit: number;
	current_balance?: number;
	date_of_birth: string;
	disabled: boolean;
	email: string;
	family_name: string;
	given_name: string;
	has_us_address: boolean;
	notes: string;
	registration_date: string;
	shipping_address?: KernelAddress;
	stripe_payout_account_id: string;
	stripe_payout_account_status: 'Incomplete' | 'Complete' | 'No account';
	user_id: string;
	verified_tin?: boolean;
}

export interface KernelSellerStatementDetails {
	amount_owed: number;
	adjustments?: KernelSellerStatementAdjustment[]; // only optional while developing and waiting for backend to catch up and send it
	auction_id: string;
	comments?: string;
	created_at?: string;
	lots: KernelLot[];
	net_adjustments: number;
	payout_date: string;
	removed_lots?: KernelSellerStatementDetailsRemovedLot[];
	seller_statement_id: string;
	seller_statement_number: string;
	seller_statement_status: SellerStatementStatusType;
	total_lot_value: number;
	total_ready_for_payout: number;
	total_statement_amount: number;
	tracking_id?: string;
	user_info: SellerStatementUserInfo;
}

export interface KernelSellerStatementDetailsRemovedLot {
	auction_id: string;
	auction_type: string;
	bin?: string;
	buyer_premium: number;
	commission: number;
	created_by: string;
	created_on: string;
	current_price: number;
	final_price: number;
	invoice_status?: string;
	lot_id: string;
	lot_number: number;
	meta_slug: string;
	primary_image_name: string;
	reason: 'Invoice_New_Auction';
	room?: string;
	seller_id: string;
	serial_number: string;
	title: string;
}

export interface SellerStatementDetailsRemovedLot {
	auctionId: string;
	auctionType: string;
	bin?: string;
	buyerPremium: number;
	commission: number;
	createdBy: string;
	createdOn: string;
	currentPrice: number;
	finalPrice: number;
	invoiceStatus?: string;
	lotId: string;
	lotNumber: number;
	metaSlug: string;
	primaryImageName: string;
	reason: 'Invoice_New_Auction';
	room?: string;
	sellerId: string;
	serialNumber: string;
	title: string;
}

export const SELLER_STATEMENT_REMOVED_LOTS_CSV_COLUMNS = [
	'auction_id',
	'auction_type',
	'bin',
	'buyer_premium',
	'commission',
	'created_by',
	'created_on',
	'current_price',
	'final_price',
	'invoice_status?',
	'lot_id',
	'lot_number',
	'meta_slug',
	'primary_image_name',
	'reason:',
	'room',
	'seller_id',
	'serial_number',
	'title',
];

export interface BulkStatementStatusCSV {
	statement_number: string;
	seller_email: string;
	statement_status: string;
}

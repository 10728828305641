import React, { FC, Children, isValidElement, cloneElement } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

/** @description Adapter for class components to get useful hooks without dealing with HOCs */
const HookInjector: FC = ({ children }) => {
	const queryClient = useQueryClient();
	const history = useHistory();

	const childrenWithQueryClient = Children.map(children, (child) => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.
		if (isValidElement(child)) {
			return cloneElement(child, { ...child.props, queryClient, history });
		}
		return child;
	});

	return <>{childrenWithQueryClient}</>;
};

export default HookInjector;

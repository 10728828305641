import Layout from '../../components/Layout';
import ProtectedRoute from '../../routing/ProtectedRoute/ProtectedRoute';
import ListingDetail from './ListingDetail';
import RequireAuth from '../../routing/RequireAuth';
import SearchAuctionListings from './SearchAuctionListings/SearchAuctionListings';

const InventoryRouter = ({ match }: { match: { url: string } }) => (
	<RequireAuth>
		<Layout>
			<ProtectedRoute path={`${match.url}/search/:query`} component={SearchAuctionListings} />
			<ProtectedRoute exact path={`${match.url}/search`} component={SearchAuctionListings} />
			<ProtectedRoute
				exact
				path={`${match.url}/listings/:lotId/:tabPath?`}
				component={ListingDetail}
			/>
		</Layout>
	</RequireAuth>
);

export default InventoryRouter;

export const formatFloat = (
	value: string | number,
	params?: {
		isPercentage?: boolean;
		fallback?: string;
		digits?: number;
	}
) => {
	const { fallback = '-', isPercentage = false, digits = 2 } = params ?? {};
	if (isNaN(Number(value))) {
		return fallback;
	}

	const floatValue = parseFloat(value.toString()).toFixed(digits);

	const floatValueAsPercentage = floatValue + '%';
	const floatValueForFlatPercentage = floatValueAsPercentage.replace('.00', '');

	return isPercentage ? floatValueForFlatPercentage : floatValue;
};

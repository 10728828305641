import { useParams } from 'react-router-dom';
import AuctionDashboardDetails from './AuctionDashboardDetails';
import AuctionDashboardHeader from './AuctionDashboardHeader';
import AuctionDashboardTable from './AuctionDashboardTable';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import useAuctionById from '../../../../queries/useAuctionById';
import useLotsForAuction from '../../../../queries/useLotsForAuction';

const AuctionDashboardView = () => {
	const { auctionId = '' } = useParams<{ auctionId: string }>();

	const { status: auctionStatus } = useAuctionById(auctionId);

	const { status: lotsStatus } = useLotsForAuction({ auctionId });

	return (
		<LoadingWrapper queryStatuses={[auctionStatus, lotsStatus]}>
			<AuctionDashboardHeader />
			{auctionId && (
				<>
					<AuctionDashboardDetails />
					<AuctionDashboardTable />
				</>
			)}
		</LoadingWrapper>
	);
};

export default AuctionDashboardView;

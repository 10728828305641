import apiEndpoints from './queries/apiEndpoints';

const myConfig = {
	api: apiEndpoints,
	assets: {
		base: process.env.API_CLOUDFRONT_IMAGES,
		images: `${process.env.API_CLOUDFRONT_IMAGES}/public/Lots`,
		banners: `${process.env.API_CLOUDFRONT_IMAGES}/public/CarouselImages`,
	},
	aws_sdk: {
		REGION: process.env.AWS_SDK_REGION,
		ADMIN_COMPRESSED_BUCKET: process.env.ADMIN_COMPRESSED_BUCKET,
		ADMIN_UNCOMPRESSED_BUCKET: process.env.ADMIN_UNCOMPRESSED_BUCKET,
	},
	cognito: {
		REGION: process.env.COGNITO_REGION,
		USER_POOL_ID: process.env.COGNITO_USER_POOL_ID,
		CUSTOMER_USER_POOL_ID: process.env.COGNITO_CUSTOMER_USER_POOL_ID,
		APP_CLIENT_ID: process.env.COGNITO_APP_CLIENT_ID,
		IDENTITY_POOL_ID: process.env.COGNITO_IDENTITY_POOL_ID,
	},
	environment: process.env.NODE_ENV,
	organization: {
		company_id: process.env.ORGANIZATION_COMPANY_ID,
	},
	shipping: {
		vendors: process.env.SHIPPING_VENDORS,
	},
	stripe: {
		invoices: process.env.STRIPE_INVOICE,
	},
};

export default myConfig;

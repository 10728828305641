import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import useOrderByNumber from '../../../../queries/useOrderByNumber';
import usePluralLots from '../../../../queries/usePluralLots';
import useUserById from '../../../../queries/useUserById';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { CheckoutOrder } from '../../../../services/ordersService/ordersService.model';
import OrderDashboardView from './OrderDashboardView';
import OrderDashboardHeader from './OrderDashboardHeader';
import useCurrentUser from '../../../../queries/useCurrentUser';

// orderNumber: '76543009588'
const OrderDashboard = () => {
	const { orderNumber } = useParams<{ orderNumber: string }>();
	const { data: order = {} as CheckoutOrder, status: orderByNumberStatus } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const { data: lotItems = [] as KernelLot[], status: lotsStatus } = usePluralLots(
		order.lotIds || []
	);

	const { status: winnerStatus } = useUserById(order.winnerId);

	const { status: currentUserStatus } = useCurrentUser();

	return (
		<div>
			<OrderDashboardHeader order={order} />
			<div className="my-4">
				<LoadingWrapper
					queryStatuses={[orderByNumberStatus, lotsStatus, winnerStatus, currentUserStatus]}
				>
					<OrderDashboardView order={order} lotItems={lotItems} />
				</LoadingWrapper>
			</div>
		</div>
	);
};

export default OrderDashboard;

import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { v4 as uuidv4 } from 'uuid';

export default class TinyMCEEditor extends Component {
	constructor(props) {
		super(props);

		this.handleEditorFocus = this.handleEditorFocus.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleEditorSave = this.handleEditorSave.bind(this);
		this.handleEditorSubmit = this.handleEditorSubmit.bind(this);

		const defaultValue = props.initialValue || '';

		this.state = {
			uuid: uuidv4(),
			content: defaultValue,
			height: props.height || 250,
			initialValue: defaultValue,
			updateContentCallback: props.updateContentCallback,
			placeHolder: props.placeholder || 'Enter your text here',
			className: props.className || '',
		};
	}

	componentDidMount() {
		{
			/* todo:load data from DB */
		}
	}

	handleEditorFocus = (event, editor) => {
		if (this.state.content === this.state.initialValue) {
			editor.selection.select(editor.getBody(), true);
		}
	};

	handleEditorChange = (content) => {
		this.state.content = content;
		if (this.state.updateContentCallback) this.state.updateContentCallback(content);
	};

	handleEditorSave = () => {
		//
	};

	handleEditorSubmit = (event) => {
		event.preventDefault();
	};

	render() {
		return (
			<form className={this.state.className} onSubmit={this.handleEditorSubmit}>
				<Editor
					apiKey="5kg9wsmkcidqo7xm84b7enkrfz2u7h72aqz8l1uo2juyszn9"
					initialValue={this.state.initialValue}
					init={{
						height: this.state.height,
						menubar: false,
						placeholder: this.state.placeHolder,
						plugins: [
							'save autosave advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount',
							'code',
						],
						toolbar:
							'save restoredraft | undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | code | help',
					}}
					onFocusIn={this.handleEditorFocus}
					onObjectSelected={this.handleEditorFocus}
					onEditorChange={this.handleEditorChange}
					onSaveContent={this.handleEditorSave}
				/>
			</form>
		);
	}
}

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { CashAccountSummary, EMPTY_CASH_ACCOUNT_SUMMARY } from './cashAccountsService.model';

export const getCashAccountSummary = async (userId: string) => {
	const response = await axiosAuth.get<CashAccountSummary>(
		`${apiEndpoints.cash_accounts}/${userId}/summary`
	);

	if (response.status === 404) {
		return EMPTY_CASH_ACCOUNT_SUMMARY;
	}

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

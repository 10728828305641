import { useState } from 'react';
import useImageUrl from '../../queries/useImageUrl';
import LoadingWrapper from '../LoadingWrapper';
import placeholderCard from '../../assets/images/placeholder-card-md.png';

const LotImage = ({
	imageName,
	lotId,
	className = '',
}: {
	imageName: string;
	lotId: string;
	className?: string;
}) => {
	const { status: imageLoadStatus, data: imageUrl = '' } = useImageUrl({
		itemId: imageName,
		parentPath: lotId,
		imageSize: '@1x',
		rootPath: 'Lots',
		storageProvider: 's3',
	});

	const [src, setSrc] = useState(imageUrl);
	const handleImageLoadError = () => setSrc(placeholderCard);

	return (
		<LoadingWrapper queryStatuses={[imageLoadStatus]}>
			<img
				src={src}
				className={`w-full object-contain object-center ${className}`}
				alt="Lot Image"
				key={src}
				onError={handleImageLoadError}
				loading="lazy"
			/>
		</LoadingWrapper>
	);
};

export default LotImage;

import { useQuery } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { getCurrentUser } from '../../services/userService';
import { QueryKeys } from '../queryKeys';

const useCurrentUser = () => {
	const queryKey = [QueryKeys.GetCurrentUser];
	const baseQuery = useQuery(queryKey, () => getCurrentUser(), {
		enabled: true,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the current user. Please refresh and try again.'
			),
	});

	return baseQuery;
};

export default useCurrentUser;

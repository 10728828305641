import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Chip } from '../../../../prizm-ui/Chip';
import { CollectorsImageItem } from '../../../../services/lotsService/lotsService.model';
import BasicImageThumbnail from '../BasicImageThumbnail';

const ExternalImages = ({ images }: { images: CollectorsImageItem[] }) => {
	const [shouldShowLightbox, setShouldShowLightbox] = useState(false);
	const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
	const openLightBox = (imageIndex: number) => {
		setCurrentLightboxIndex(imageIndex);
		setShouldShowLightbox(true);
	};

	const lightboxImages = images.map(({ large }) => large);

	return (
		<div className="mb-8">
			<div className="flex">
				<h3 className="text-subtitle1 mb-4 mr-2">External Images from Collectors</h3>
				<div className="pt-2">
					<Chip variant="error">Read Only</Chip>
				</div>
			</div>
			{images.length === 0 ? (
				<div className="italic text-body2 mt-4">No Images Found</div>
			) : (
				<ul className="grid list-none grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
					{images.map(({ small: thumbnailImage }, index) => (
						<li key={thumbnailImage} className="h-full">
							<BasicImageThumbnail src={thumbnailImage} onClickImage={() => openLightBox(index)} />
						</li>
					))}
				</ul>
			)}
			{shouldShowLightbox && (
				<Lightbox
					mainSrc={lightboxImages[currentLightboxIndex]}
					nextSrc={lightboxImages[(currentLightboxIndex + 1) % lightboxImages.length]}
					prevSrc={
						lightboxImages[
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						]
					}
					onCloseRequest={() => setShouldShowLightbox(false)}
					onMovePrevRequest={() =>
						setCurrentLightboxIndex(
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						)
					}
					onMoveNextRequest={() =>
						setCurrentLightboxIndex((currentLightboxIndex + 1) % lightboxImages.length)
					}
				/>
			)}
		</div>
	);
};

export default ExternalImages;

import React from 'react';
import formatCurrency from '../../../../../../../../utils/formatters/formatCurrency';
import { RecordRefundModalLotProps } from './RecordRefundModalLot.model';

const RecordRefundModalLot = ({ lot, lotAdjustments }: RecordRefundModalLotProps) => {
	const { buyerPremium, lotId, price, title } = lot;

	return (
		<div className="mb-4 max-w-xl" key={lotId}>
			<div className="mb-1 flex flex-row justify-between">
				<p className="mr-10 font-bold">{title}</p>
				<p>{formatCurrency(price)}</p>
			</div>
			{buyerPremium && (
				<div className="mb-2 flex flex-row justify-between">
					<p className="ml-4">{`- Buyer's Premium`}</p>
					<p>{formatCurrency(buyerPremium)}</p>
				</div>
			)}
			{lotAdjustments.length > 0 &&
				lotAdjustments.map(({ amount, description, id }) => (
					<div className="mb-2 flex flex-row justify-between" key={id}>
						<p className="ml-4">{`- ${description}`}</p>
						<p className={`${Number(amount) < 0 ? 'text-error1' : ''}`}>
							{formatCurrency(Number(amount))}
						</p>
					</div>
				))}
		</div>
	);
};

export default RecordRefundModalLot;

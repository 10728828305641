import React, { Fragment, Component } from 'react';
import classnames from 'classnames';
import { compareAsc } from 'date-fns';
import { Nav, NavItem, NavLink, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { Dialog } from '@material-ui/core';
import { shippingStatus } from '../../../../AdminUtils/invoiceUtils';
import SortableTable from '../../../../zenauction-ui-shared/Components/Elements/SortableTable';
import PickSheet from '../PickSheet';
import CheckoutOrderPickSheet from './CheckoutOrderPickSheet';
import moment from 'moment';
import { getInvoices } from '../../../../services/invoicesService';
import { formatDate } from '../../../../utils/formatters';

export default class ShipmentDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'Pending Shipments',
			auctionCardsKey: 1,
			auction: props.auction,
			auction_map: props.auction_map,
			auction_title: props.auction_title,
			invoices: [],
			shippingStatus: shippingStatus.UNPICKED,
			selectedInvoiceId: null,
			selectedInvoiceAuctionId: null,
			selectedInvoiceNumber: null,
			tabs: ['Pending Shipments', 'Completed Shipments'],
			tableKey: 1,
			usesCheckout: false,
		};
	}

	handleCloseInvoiceDetails = () => {
		this.setState({
			openInvoiceDetails: false,
		});
	};

	invoiceDetailsLink = (props) => {
		return (
			<div className="link cursor-pointer" onClick={() => this.openInvoiceModal(props)}>
				{props.invoice_number}
			</div>
		);
	};

	openInvoiceModal = async (props) => {
		console.log('InvoiceModal', props);
		let auction_title = '';
		if (this.state.auction_map) {
			auction_title = this.state.auction_map[props.auction_id];
		} else if (this.state.auction_title) {
			auction_title = this.state.auction_title;
		}
		this.setState({
			openInvoiceDetails: true,
			selectedInvoiceId: props.invoice_id,
			selectedInvoiceAuctionId: props.auction_id,
			auction_title: auction_title,
			paid_date: moment.utc(props.paid_date).local().format('M/D/YYYY h:mm a'),
			selectedInvoiceNumber: props.invoice_number,
			usesCheckout: props.uses_checkout,
		});
		return;
	};

	reloadInvoiceRows = async (auction_id) => {
		await new Promise((r) => setTimeout(r, 5000));
		this.prepareInvoiceRows(auction_id);
	};

	prepareInvoiceRows = async (auction_id) => {
		let invoices;
		if (this.props.getInvoices) {
			invoices = await this.props.getInvoices({
				invoice_status: this.paidInvoiceStatus,
				shipping_status: this.state.shippingStatus,
			});
		} else {
			const { invoices: fetchedInvoices } = await getInvoices({
				auction_id,
				invoice_status: this.paidInvoiceStatus,
				filters: {
					shipping_status: this.state.shippingStatus,
				},
			});

			invoices = fetchedInvoices;
		}
		invoices.forEach((val, index) => {
			invoices[index]['invoice_number'] = {
				invoice_number: val['invoice_number'],
				invoice_id: val['invoice_id'],
				auction_id: val['auction_id'],
				paid_date: val['paid_date'],
				uses_checkout: val['uses_checkout'] ? val['uses_checkout'] : false,
			};

			if (this.props.getInvoices) {
				let auction_title = this.state.auction_map[val['auction_id']];
				invoices[index]['auction_title'] = auction_title === 'undefined' ? '' : auction_title;
			}
			invoices[index]['amount_due'] = val['amount_due'] / 100;
			invoices[index]['tracking_id'] = JSON.parse(invoices[index]['tracking_id']);
			invoices[index]['auction_date'] = val['auction_date']
				? moment(val['auction_date']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['paid_date'] = val['paid_date']
				? moment(val['paid_date']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['date_shipped'] = val['date_shipped']
				? moment(val['date_shipped']).format('M/D/YYYY h:mm a')
				: '';
			invoices[index]['comments'] = {
				comments: val['comments'],
				uses_checkout: val['uses_checkout'] ? val['uses_checkout'] : false,
			};
		});
		this.setState({
			invoices: invoices,
			tableKey: this.state.tableKey + 1,
		});
	};

	toggle = (tab) => {
		if (this.state.activeTab === tab) return;
		let shipping_status = null;
		if (tab === 'Pending Shipments') {
			shipping_status = shippingStatus.UNPICKED;
		} else {
			shipping_status = shippingStatus.SHIPPED;
		}
		this.setState(
			{
				activeTab: tab,
				shippingStatus: shipping_status,
			},
			() => {
				this.prepareInvoiceRows(this.state.auction);
			}
		);
	};

	getComment = (props) => {
		if (props.uses_checkout) {
			if (props.comments == undefined || props.comments.length == 0) {
				return '';
			}

			const filteredComments = props.comments.filter(
				(comment) => comment.kind === 'order-shipping-comment'
			);

			if (filteredComments.length == 0) {
				return '';
			}

			const sortedComments = filteredComments.sort((a, b) =>
				compareAsc(new Date(a.timestamp), new Date(b.timestamp))
			);

			return sortedComments.pop().text;
		}

		return props.comments ? props.comments : '';
	};

	componentDidMount() {
		let auction_id = this.state.auction;
		this.prepareInvoiceRows(auction_id);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;
		if (prevProps.auction && prevProps.auction !== newProps.auction) {
			this.setState(
				{
					auction: this.props.auction,
					auction_title: this.props.auction_title,
					invoices: [],
				},
				() => {
					this.prepareInvoiceRows(this.props.auction);
				}
			);
		}
	}

	render() {
		let columns = [
			{
				id: 'invoice_number',
				label: 'Invoice No.',
				align: 'left',
				minWidth: 100,
				type: 'component',
				component: this.invoiceDetailsLink,
			},
			{ id: 'customer_name', label: 'Winner Name', align: 'left', minWidth: 150 },
			{ id: 'customer_email', label: 'Email', align: 'left' },
			{
				id: 'amount_due',
				label: 'Total Amount Due',
				align: 'left',
				format: (amount_due) =>
					new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
						amount_due.toFixed(2)
					),
			},
			{
				id: 'auction_date',
				label: 'Auction Date',
				align: 'left',
				type: 'timestamp',
			},
			{
				id: 'paid_date',
				label: 'Payment Date',
				align: 'left',
				type: 'timestamp',
			},
			{ id: 'payment_type', label: 'Payment Type', align: 'left' },
			{
				id: 'tracking_id',
				label: 'Tracking IDs',
				align: 'left',
				type: 'component',
				component: (trackingData) => (
					<>
						{Object.values(trackingData).map(({ tracking_id, Vendor }) => (
							<div key={tracking_id} className="mb-2">
								<span className="font-semibold">{Vendor}:</span> {tracking_id}
							</div>
						))}
					</>
				),
			},
			{ id: 'shipping_status', label: 'Status', align: 'left' },
			{
				id: 'date_shipped',
				label: 'Date Shipped',
				align: 'left',
				type: 'timestamp',
			},
			{
				align: 'left',
				id: 'comments',
				label: 'Comments',
				minWidth: 100,
				type: 'component',
				component: this.getComment,
			},
			{
				id: 'cost_metadata',
				label: 'Destination',
				align: 'left',
				type: 'component',
				component: (costMetadata) => <div className="capitalize">{costMetadata.address_type}</div>,
			},
			{
				id: 'printed_at',
				label: 'Printed On',
				align: 'left',
				type: 'timestamp',
			},
		];

		if (this.props.getInvoices) {
			let exists = columns.some(function (column) {
				return column.id === 'auction_title';
			});
			if (!exists) {
				let auction_column = {
					id: 'auction_title',
					label: 'Auction',
					align: 'left',
					minWidth: 100,
				};
				columns.splice(1, 0, auction_column);
			}
		}

		return (
			<Fragment>
				<Col md="12" className="display-grid">
					<Card tabs="true" className="mb-3">
						<CardHeader className="card-header-tab">
							<Nav>
								{this.state.tabs.map((value) => {
									return (
										<NavItem key={value}>
											<NavLink
												href="javascript:void(0);"
												className={classnames({ active: this.state.activeTab === value })}
												onClick={() => {
													this.toggle(value);
												}}
											>
												{value}
												{/* {" (" + (this.state.auctions ? this.state.auctions.filter(function(auction) { return auction.Status === value || auction.Status === 'Preview'; }).length : 0) + ")"} */}
											</NavLink>
										</NavItem>
									);
								})}
							</Nav>
						</CardHeader>
						<CardBody>
							<SortableTable
								key={this.state.tableKey}
								dataToSort={this.state.invoices}
								columns={columns}
								rowsPerPage={250}
							/>
							<Dialog
								open={this.state.openInvoiceDetails}
								onClose={this.handleCloseInvoiceDetails}
								fullWidth
								maxWidth="lg"
							>
								{this.state.usesCheckout ? (
									<CheckoutOrderPickSheet
										orderNumber={this.state.selectedInvoiceNumber}
										reloadRows={(auctionId) => this.reloadInvoiceRows(auctionId)}
										updateSuccess={() => this.setState({ openInvoiceDetails: false })}
									/>
								) : (
									<PickSheet
										auction_id={this.state.selectedInvoiceAuctionId}
										auction_title={this.state.auction_title}
										paid_date={this.state.paid_date}
										invoice_id={this.state.selectedInvoiceId}
										invoice_number={this.state.selectedInvoiceNumber}
										reload_rows_callback={this.reloadInvoiceRows}
									/>
								)}
							</Dialog>
						</CardBody>
					</Card>
				</Col>
			</Fragment>
		);
	}
}

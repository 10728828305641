import { Link, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import Button from '../../../../../../components/Button';
import Label from '../../../../../../components/Label';
import LoadingWrapper from '../../../../../../components/LoadingWrapper';
import SortableTable from '../../../../../../components/SortableTable';
import { SortableTableParams } from '../../../../../../components/SortableTable/SortableTable.model';
import { KernelBidByUserForAuction } from '../../../../../../services/bidderService/getBidsByUserForAuction.model';
import { WatchlistLot } from '../../../../../../services/watchlistService/getWatchlistForUser.model';
import { formatDate } from '../../../../../../utils/formatters';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import useBidsByUserForAuction from '../../../../../../queries/useBidsByUserForAuction';
import usePluralLots from '../../../../../../queries/usePluralLots';
import { KernelLot } from '../../../../../../services/lotsService/lotsService.model';

const WatchlistTable = ({
	openPlaceBidModal,
	watchlistLots,
}: {
	openPlaceBidModal: (lotId: string) => void;
	watchlistLots: WatchlistLot[];
}) => {
	const { auctionId = '', userId } = useParams<{ auctionId: string; userId: string }>();

	const {
		data: bidsByUserForAuction = [] as KernelBidByUserForAuction[],
		status: bidsByUserForAuctionStatus,
	} = useBidsByUserForAuction({ auctionId, userId });

	const watchlistLotsByAuction = watchlistLots.filter(
		(watchlistLot) => watchlistLot.auctionId === auctionId
	);
	const watchlistLotIdsByAuction = watchlistLotsByAuction.map(({ lotId }) => lotId);
	const { data: lots = [] as KernelLot[] } = usePluralLots(watchlistLotIdsByAuction);

	const columns: ColumnDef<WatchlistLot>[] = [
		{
			header: 'Date/Time',
			id: 'startTimestamp',
			accessorKey: 'startTimestamp',
			cell: ({ getValue }) => (
				<div className={`${getValue() ? '' : 'text-center'}`}>
					{formatDate(getValue() as string, { invalidDateMessage: '-' })}
				</div>
			),
		},
		{
			header: 'Lot No.',
			id: 'lotNumber',
			accessorFn: ({ lotNumber }) => (typeof lotNumber !== undefined ? lotNumber : ''),
			cell: ({ getValue, row: { id } }) => (
				<Link className="block link" to={`/auctions/${auctionId}/inventory-manager/${id}`}>
					{getValue() as string}
				</Link>
			),
		},
		{
			header: 'Lot Name',
			id: 'title',
			accessorFn: ({ title: { label } }) => (typeof label !== undefined ? label : ''),
			cell: ({ getValue, row: { id } }) => (
				<Link className="block link" to={`/auctions/${auctionId}/inventory-manager/${id}`}>
					{getValue() as string}
				</Link>
			),
		},
		{
			header: 'Bid Amount',
			enableGlobalFilter: false,
			accessorFn: ({ lotId }) =>
				bidsByUserForAuction.find((bid) => bid.lot_id === lotId)?.bid_amount,
			cell: ({ getValue, row: { id } }) => {
				const status = lots.find(({ lot_id }) => lot_id === id)?.status;

				return (
					<div className="flex flex-row items-center justify-between text-right w-full">
						<div>{formatCurrency(getValue() as string, '-')}</div>
						{status === 'Live' && (
							<Button
								kind="tertiary"
								className="link ml-2"
								isInline={true}
								onClick={() => openPlaceBidModal(id)}
							>
								Bid
							</Button>
						)}
					</div>
				);
			},
		},
		{
			header: 'Current Price',
			id: 'currentPrice',
			accessorFn: ({ lotId }) => lots.find((bid) => bid.lot_id === lotId)?.current_price,
			cell: ({ getValue }) => <div>{formatCurrency(getValue() as number, '-')}</div>,
		},
		{
			header: 'Winner',
			id: 'winner',
			accessorFn: ({ lotId }) =>
				bidsByUserForAuction.find((bid) => bid.lot_id === lotId)?.highest_bidder,
			cell: ({ getValue }) => (
				<div>{typeof getValue() === 'undefined' ? '-' : getValue() ? 'Yes' : ' No'}</div>
			),
		},
		{
			header: 'No. of Bids',
			id: 'bids',
			accessorFn: ({ lotId }) =>
				bidsByUserForAuction.find((bid) => bid.lot_id === lotId)?.number_of_bids,
			cell: ({ getValue, row: { id } }) => (
				<Link className="block link" to={`/inventory/listings/${id}/bids`}>
					{getValue() ? (getValue() as number) : 0}
				</Link>
			),
		},
	];

	const sortableTableParams: SortableTableParams<WatchlistLot> = {
		columns,
		data: watchlistLotsByAuction,
		getRowId: ({ lotId }) => lotId,
		initialState: {
			sorting: [
				{
					desc: true,
					id: 'startTimestamp',
				},
			],
		},
	};

	return (
		<>
			<Label>Lots</Label>
			<LoadingWrapper queryStatuses={[bidsByUserForAuctionStatus]}>
				<SortableTable
					noResultsMessage="This user is not watching anything."
					tableParams={sortableTableParams}
				/>
			</LoadingWrapper>
		</>
	);
};

export default WatchlistTable;

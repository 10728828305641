import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AttachRegular } from '@fluentui/react-icons';
import { uploadImage } from '../../services/storageService/uploadImage';
import { Spinner } from 'reactstrap';

const ImageUploadDropzone = ({
	bucketPath,
	onSuccess,
	maxImages = 1,
	className = '',
	filePrefix = '',
}: {
	bucketPath: string;
	onSuccess: (imageUrl: string) => void;
	maxImages?: number;
	className?: string;
	filePrefix?: string;
}) => {
	const [isUploading, setIsUploading] = useState(false);

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		setIsUploading(true);
		await Promise.all(
			acceptedFiles.map(async (file: File) => {
				const uploadedImageUrl = await uploadImage({
					image: file,
					bucketPath,
					filePrefix,
				});
				onSuccess(uploadedImageUrl);
			})
		);
		setIsUploading(false);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: maxImages,
		maxSize: 52428800,
		accept: 'image/*',
	});

	return (
		<div
			{...getRootProps()}
			className={`border-base h-full w-full rounded-lg px-4 py-8 transition duration-150 ease-in-out ${
				isDragActive ? 'bg-blue-100 scale-105 transform' : ''
			} ${className}`}
		>
			<input {...getInputProps()} />
			<div
				className={`relative flex h-full w-full cursor-pointer flex-col items-center justify-center text-center ${
					isUploading ? 'opacity-25' : 'opacity-100'
				}`}
			>
				<div className="relative">
					<div className="mb-4">
						<AttachRegular className="text-4xl" />
					</div>
					<div>
						<p className="text-lg">{`Drag 'n' drop or click to upload ${
							maxImages > 1 ? 'images' : 'an image'
						}`}</p>
						<div className="mt-4 text-xs uppercase">
							<p>max image size 50mb</p>
							{maxImages > 1 && <p>up to {maxImages} images total</p>}
						</div>
					</div>
				</div>
				{isUploading && (
					<div className="absolute flex h-full w-full items-center justify-center">
						<Spinner />
					</div>
				)}
			</div>
		</div>
	);
};

export default ImageUploadDropzone;

import React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import LoadingWrapper from '../../LoadingWrapper';
import { showErrorToast, showSuccessToast } from '../../Toast';
import { QueryKeys } from '../../../queries/queryKeys';
import useIndividualLot from '../../../queries/useIndividualLot';
import { updateLot } from '../../../services/lotsService';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import EditGeneralLotInfo from '../../../Pages/Auctions/LotManager/EditGeneralLotInfo';
import LotEditorView from './LotEditorView';

const LotEditor = ({
	lotDisplayStatus = '',
}: {
	lotDisplayStatus?: 'Active' | 'Inactive' | '';
}) => {
	const { lotId } = useParams<{ lotId: string; auctionId: string }>();
	const {
		data: lot = {} as KernelLot,
		status: lotStatus,
		updateLot: updateLotInCache,
	} = useIndividualLot(lotId);

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id],
		mutationFn: (lotChanges: Partial<KernelLot>) => updateLot(lotChanges),
	});
	const handleSave = (lotChanges: Partial<KernelLot>, onSuccess: () => void) =>
		doUpdateLot(lotChanges, {
			onSuccess: () => {
				showSuccessToast('Successfully updated lot fields');
				updateLotInCache(lotChanges);
				onSuccess();
			},
			onError: () => {
				showErrorToast('Something went wrong updating lot fields. Please try again later.');
			},
		});

	return (
		<LoadingWrapper queryStatuses={[lotStatus]}>
			<>
				<EditGeneralLotInfo
					lot={lot}
					isNewLot={false}
					onUpdate={handleSave}
					saveStatus={updateLotStatus}
					key={`${lot.lot_id}-general-info`}
				/>
				<LotEditorView lotDisplayStatus={lotDisplayStatus} key={`${lot.lot_id}-lot-editor`} />
			</>
		</LoadingWrapper>
	);
};

export default LotEditor;

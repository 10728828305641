import { capitalize } from 'lodash';
import React from 'react';
import { Chip } from '../../../../../../prizm-ui/Chip';
import { Sentiment } from '../../../../../../prizm-ui/types';
import { OfferStatus } from '../../../../../../services/offersService';

const SENTIMENT_BY_STATUS: { [key in OfferStatus]: Sentiment } = {
	ACCEPTED: 'success',
	ACTIVE: 'primary',
	PENDING: 'primary',
	COUNTERED: 'primary',
	EXPIRED: 'gray',
	DECLINED: 'error',
};

const OfferStatusChip = ({ status }: { status: OfferStatus }) =>
	status !== 'EXPIRED' ? (
		<Chip variant={SENTIMENT_BY_STATUS[status]}>{capitalize(status)}</Chip>
	) : (
		<></>
	);

export default OfferStatusChip;

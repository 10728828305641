import { axiosAuth as axios } from '../AdminUtils/httpUtils';
import config from '../config.js';

const bidder_url = config.api.bidder_info_actions;

export async function searchBidder(keyword) {
	try {
		const response = await axios.post(bidder_url, {
			keyword: keyword,
		});
		let users = response?.data?.body?.users || [];
		return users;
	} catch (err) {
		console.log(`Bidder Elastic Search Error: ${err}`);
		throw err;
	}
}

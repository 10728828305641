import { CheckoutOrderComment } from '../../../../../../../services/ordersService/ordersService.model';
import { formatDate } from '../../../../../../../utils/formatters';

const OrderCommentEntry = ({ comment }: { comment: CheckoutOrderComment }) => {
	return (
		<>
			<p className="text-caption1">{`${formatDate(comment.timestamp)} - ${
				comment.author?.authorDisplayName
			}`}</p>
			<p className="mt-1 break-words text-body1">{comment.text}</p>
		</>
	);
};

export default OrderCommentEntry;

const BasicImageThumbnail = ({ src, onClickImage }: { src: string; onClickImage: () => void }) => {
	return (
		<li className="border-base w-full rounded-lg bg-neutral2">
			<div className="flex flex-grow justify-center rounded-lg p-4">
				<button onClick={onClickImage} className="w-full">
					<img src={src} className="w-full object-contain object-center" alt="Lot Image" />
				</button>
			</div>
		</li>
	);
};

export default BasicImageThumbnail;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelLot } from './lotsService.model';

export const createLot = async (lot: KernelLot) => {
	const response = await axiosAuth.post<KernelLot>(`${config.api.lots}/create`, lot);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { ChipVariant } from '../../prizm-ui/Chip';
import { AuctionStatus } from '../../services/auctionsService';

export const getChipVariantForAuctionStatus = (auctionStatus: AuctionStatus): ChipVariant => {
	switch (auctionStatus) {
		case 'Active':
			return 'success';
		case 'Completed':
			return 'error';
		case 'Not_Started':
			return 'primary';
		case 'Preview':
			return 'success';
		case 'Pause':
			return 'primary';
		default:
			return 'gray';
	}
};

import { Auth } from 'aws-amplify';
import { CurrentUser, UserRoleName } from './userService.model';

// TS doesn't like CognitoUserSession as our type but we know this works
export interface HackCurrentSessionInterface {
	idToken?: {
		payload?: {
			'cognito:username'?: string;
			email?: string;
			given_name?: string;
			family_name?: string;
			'custom:role'?: UserRoleName;
		};
	};
}

export const getCurrentUser = async (): Promise<CurrentUser> => {
	const session = (await Auth.currentSession()) as HackCurrentSessionInterface;
	const currentUserId =
		session &&
		session.idToken &&
		session.idToken.payload &&
		session.idToken.payload['cognito:username']
			? session.idToken.payload['cognito:username']
			: '';
	const currentUserEmail =
		session && session.idToken && session.idToken.payload && session.idToken.payload['email']
			? session.idToken.payload['email']
			: '';
	const currentUserFullName =
		session &&
		session.idToken &&
		session.idToken.payload &&
		session.idToken.payload['given_name'] &&
		session.idToken.payload['family_name']
			? session.idToken.payload['given_name'] + ' ' + session.idToken.payload['family_name']
			: '';

	const currentUserRoleName =
		session && session.idToken && session.idToken.payload && session.idToken.payload['custom:role']
			? session.idToken.payload['custom:role']
			: 'Unknown';

	return {
		currentUserId,
		currentUserEmail,
		currentUserFullName,
		currentUserRoleName,
	};
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelSellerStatementAdjustment } from './sellerStatementService.model';

/** @TODO rewrite to accept only the adjustmentID to remove, rather than sending a list of remaining adjustments to replace the entire
 * thing on the backend. This will require backend work to create a proper DELETE endpoint.
 */
export const deleteSellerStatementAdjustment = async ({
	auctionId,
	statementId,
	updatedAdjustments,
}: {
	auctionId: string;
	statementId: string;
	updatedAdjustments: KernelSellerStatementAdjustment[];
}) => {
	const response = await axiosAuth.post(`${config.api.delete_statement_adjustment}`, {
		auction_id: auctionId,
		seller_statement_id: statementId,
		adjustments_list: updatedAdjustments,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

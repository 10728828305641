import { KernelMergeUserInfoResponseData, MergeUserInfoResponseData } from './mergeUserInfo.model';

export const mapKernelMergeUserInfoResponseDataToMergeUserInfoResponseData = (
	input: KernelMergeUserInfoResponseData
): MergeUserInfoResponseData => ({
	processedTime: input.current_time,
	processedAuctionLots: input.processed_auction_lots,
	processedBidTransactions: input.processed_bid_transactions,
	processedBidderInvoices: input.processed_bidder_invoices,
	processedSellerStatements: input.processed_seller_statements,
	processedWatchlist: input.processed_watchlist,
	processedUserActivityOutbid: input.processed_user_lot_activites_outbid,
	processedUserActivityWatchlist: input.processed_user_lot_activites_watchlist,
	processedUserActivityWinner: input.processed_user_lot_activites_winning,
});

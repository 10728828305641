import React, { FC } from 'react';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../../prizm-ui/Modal';
import ConfirmationButtons from '../ConfirmationButtons';
import { ModalProps } from '../Modal/Modal.model';

const ActionModal: FC<
	ModalProps & {
		confirmButtonLabel?: string;
		cancelButtonLabel?: string;
		canConfirm?: boolean;
		isLoading?: boolean;
		isDangerous?: boolean;
		className?: string;
		onCancel?: () => void;
		onConfirm: () => void;
	}
> = ({
	children,
	cancelButtonLabel = 'Cancel',
	canConfirm = true,
	confirmButtonLabel = 'Confirm',
	isDangerous = false,
	isLoading = false,
	title,
	onCancel,
	onClose,
	onConfirm,
}) => {
	return (
		<Modal isOpen={true}>
			<ModalHeader onClose={onClose}>{title}</ModalHeader>
			<ModalContent>{children}</ModalContent>
			<ModalFooter>
				<ConfirmationButtons
					cancelButtonLabel={cancelButtonLabel}
					confirmButtonLabel={confirmButtonLabel}
					canCancel={true}
					canConfirm={canConfirm}
					destructive={isDangerous}
					loading={isLoading}
					onCancel={onCancel ?? onClose}
					onConfirm={onConfirm}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default ActionModal;

import React from 'react';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import { SortableTableColumn } from '../../../../TypesafeSortableTable/TypesafeSortableTable.model';
import { noop } from 'lodash';

const IconButtonCell = <T extends unknown>({
	column,
	rowNumber,
}: {
	column: SortableTableColumn<T>;
	rowNumber: number;
	rowItem: T;
}) => {
	const color = column.buttonColor;
	const icon = column.buttonIcon;
	const onClick = column.clickCallback || noop;
	const title = column.buttonTitle;
	const tooltip = column.buttonTooltip;
	const size = column.buttonSize || 'medium';
	const styleClass = column.styleClass;

	const permaFalse = () => false;
	const disabledFunc = column.disabledFunc || permaFalse;
	const disabled = disabledFunc(rowNumber);

	return (
		<TableCell key={column.id + rowNumber} className={'align-middle ' + styleClass}>
			<Tooltip title={tooltip || ''}>
				<IconButton
					color={color}
					onClick={() => onClick(rowNumber)}
					className="modify-auction-or-lot-button"
					size={size}
					disabled={disabled}
				>
					{icon}
					{title}
				</IconButton>
			</Tooltip>
		</TableCell>
	);
};

export default IconButtonCell;

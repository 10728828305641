import moment from 'moment';
import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import { CheckoutOrder } from '../ordersService/ordersService.model';

export const updateInvoiceShippingStatus = async ({
	auctionId,
	invoiceId,
	newShippingStatus,
}: {
	auctionId: string;
	invoiceId: string;
	newShippingStatus: string;
}): Promise<CheckoutOrder> => {
	try {
		const response = await postAuthed(`${config.api.update_bidder_invoice}`, {
			auction_id: auctionId,
			company_id: config.organization.company_id,
			invoice_id: invoiceId,
			updates: {
				shipping_status: newShippingStatus,
				date_shipped: moment.utc().toISOString(),
			},
		});

		if (response.status >= 400) {
			throw response;
		}

		return response.data.body;
	} catch (err) {
		console.log(`Unable to update invoice tracking id: ${err}`);

		throw err;
	}
};

export default updateInvoiceShippingStatus;

import { QueryStatus } from 'react-query';
import { Auction } from '../../services/auctionsService';
import useAuctions from '../useAuctions';

/** Kind of a mock useQuery but it would be good to someday have a GET auctions/:auctionId endpoint */
const useAuctionById = (auctionId: string) => {
	if (auctionId === 'fixed_price_marketplace') {
		const fixedPriceAuction: Partial<Auction> = {
			id: auctionId,
			title: 'Fixed Price Marketplace',
			status: 'Active',
		};
		return { data: fixedPriceAuction as Auction, status: 'success' as QueryStatus };
	}

	const { data: auctions = [] as Auction[], status } = useAuctions();

	const thisAuction = auctions.find(({ id }) => id === auctionId);

	return { data: thisAuction, status };
};

export default useAuctionById;

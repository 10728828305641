import { isEmail } from '../../../../utils/validators';
import { FixedPriceOrderSearchType } from '../FixedPriceOrderSummaryPage.model';
import { FixedPriceOrderSearchParams } from '../../../../services/ordersService/ordersService.model';

export const getDefaultQueryType = (
	paramsFromURL: Partial<FixedPriceOrderSearchParams>
): FixedPriceOrderSearchType => {
	const { searchQuery, searchType } = paramsFromURL;

	if (searchType === 'date-range') return 'dateRange';

	if (isEmail(searchQuery)) return 'winnerEmail';

	return 'orderNumber';
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KindOfParams, LotsBySubsetParams } from './getLotsBySubset.model';
import { getRequestBodyForKindOfParams } from './getLotsBySubset.utils';
import {
	FacetSearchQuery,
	KernelLot,
	KernelSummaryLot,
	LotFilters,
	LotsQueryType,
} from './lotsService.model';

// This is the same as getLotsBySubset, but list:true in the query causes the backend to return a different shape
export const getListOfLots = async <T extends KernelSummaryLot>({
	params,
	filters,
	include,
	facetSearchQuery = {},
}: {
	params: LotsBySubsetParams;
	filters?: LotFilters;
	include?: Partial<keyof KernelLot>[];
	facetSearchQuery?: FacetSearchQuery;
}) => {
	const featuredKindsOfParams: KindOfParams[] = ['assigned', 'unassigned', 'search-term'];
	const fallbackQueryType: LotsQueryType = featuredKindsOfParams.includes(params.kind)
		? 'Featured'
		: 'All';

	const requestBodyForKindOfParams = getRequestBodyForKindOfParams(params);

	const query = {
		queryType: params.queryType ?? fallbackQueryType,
		...requestBodyForKindOfParams,
		from: params.from ?? 0,
		list: true,
		filters,
		include,
		...facetSearchQuery,
	};

	const response = await axiosAuth.post<{ lots: T[]; total: number }>(config.api.lots, query);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { Sentiment } from '../../../../prizm-ui/types';
import { FixedPriceItemStatus } from '../../../../services/lotsService/lotsService.model';

export const getSentimentForFixedPriceItemStatus = (status: FixedPriceItemStatus): Sentiment => {
	switch (status) {
		case 'Live':
			return 'success';
		case 'Withdrawn':
			return 'gray';
		case 'Completed_Sold':
			return 'error';
		default:
			return 'gray';
	}
};

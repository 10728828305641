import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export interface RegenerateSellerStatementParams {
	auctionId: string;
	sellerId: string;
	statementId: string;
}

export const regenerateSellerStatement = async ({
	auctionId,
	sellerId,
	statementId,
}: RegenerateSellerStatementParams) => {
	const response = await axiosAuth.post(
		`${config.api.regenerate_seller_statement}`,
		{
			auction_id: auctionId,
			seller_id: sellerId,
			seller_statement_id: statementId,
		},
		{
			/** @ts-ignore */
			company_id: config.organization.company_id,
		}
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { useQuery } from 'react-query';
import { QueryKeys } from '../queryKeys';
import { getConsignedLots } from '../../services/lotsService';

const useConsignedLots = ({
	sellerId,
	pageSize,
	page,
}: {
	sellerId: string;
	pageSize?: number;
	page?: number;
}) =>
	useQuery(
		[QueryKeys.GetConsignedLots, sellerId, pageSize, page],
		() => getConsignedLots({ sellerId, pageSize, page }),
		{
			enabled: !!sellerId,
		}
	);

export default useConsignedLots;

import { useState } from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import Checkbox from '../../../../../components/Checkbox';
import Dropdown from '../../../../../components/Dropdown';
import Input from '../../../../../components/Input';
import Label from '../../../../../components/Label';
import { QueryKeys } from '../../../../../queries/queryKeys';
import { NewUser } from '../../../../../services/userService/createUser.model';
import { createUser } from '../../../../../services/userService';
import {
	COUNTRY_DROPDOWN_OPTIONS,
	STATE_DROPDOWN_OPTIONS,
} from '../../../../orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderCustomerInfo/EditShippingAddressModal/EditShippingAddressModal.model';
import { NEW_USER_TEMPLATE } from './AddNewCustomerModal.model';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';

const AddNewCustomerModal = ({ onClose }: { onClose: () => void }) => {
	const [newUser, setNewUser] = useState<NewUser>(NEW_USER_TEMPLATE);

	const { mutate: doCreateUser, status: createUserStatus } = useMutation({
		mutationFn: () => createUser(newUser),
		mutationKey: [QueryKeys.CreateUser],
	});

	const handleCancel = () => {
		setNewUser(NEW_USER_TEMPLATE);

		onClose();
	};

	const handleConfirm = () => {
		doCreateUser(undefined, {
			onError: () =>
				showErrorToast(
					'Something went wrong while adding the new customer. Please refresh and try again.'
				),
			onSuccess: () => {
				showSuccessToast('Successfully added new customer.');
				setNewUser(NEW_USER_TEMPLATE);
			},
		});
	};

	const handleUpdateNewUser = (fieldsToUpdate: Partial<NewUser>) =>
		setNewUser({
			...newUser,
			...fieldsToUpdate,
		});

	const { email, familyName, givenName, phoneNumber } = newUser;
	const canConfirm =
		email.length > 0 && familyName.length > 0 && givenName.length > 0 && phoneNumber.length > 0;

	return (
		<ActionModal
			canConfirm={canConfirm}
			confirmButtonLabel="Save"
			isLoading={createUserStatus === 'loading'}
			title="Add New Customer"
			onCancel={handleCancel}
			onClose={onClose}
			onConfirm={handleConfirm}
		>
			<div className="max-h-96 overflow-y-auto p-4">
				<div className="flex flex-row justify-between w-full">
					<div className="grow">
						<Label htmlFor="given-name">First Name</Label>
						<Input
							className="w-full"
							debounce={500}
							name="given-name"
							type="text"
							value={newUser.givenName}
							onChange={({ target: { value } }) => handleUpdateNewUser({ givenName: value })}
						/>
					</div>
					<div className="grow ml-4">
						<Label htmlFor="family-name">Last Name</Label>
						<Input
							className="w-full"
							debounce={500}
							name="family-name"
							type="text"
							value={newUser.familyName}
							onChange={({ target: { value } }) => handleUpdateNewUser({ familyName: value })}
						/>
					</div>
				</div>
				<div className="flex flex-row justify-between mt-2 w-full">
					<div className="grow">
						<Label htmlFor="phone-number">Phone Number</Label>
						<Input
							className="w-full"
							debounce={500}
							name="phone-number"
							type="text"
							value={newUser.phoneNumber}
							onChange={({ target: { value } }) => handleUpdateNewUser({ phoneNumber: value })}
						/>
					</div>
					<div className="grow ml-4">
						<Label htmlFor="email">Email</Label>
						<Input
							className="w-full"
							debounce={500}
							name="email"
							type="text"
							value={newUser.email}
							onChange={({ target: { value } }) => handleUpdateNewUser({ email: value })}
						/>
					</div>
				</div>
				<hr className="my-8" />
				<div className="w-full">
					<div>
						<Label htmlFor="address-1">Address Line 1</Label>
						<Input
							className="w-full"
							debounce={500}
							name="address-1"
							type="text"
							value={newUser.address1}
							onChange={({ target: { value } }) => handleUpdateNewUser({ address1: value })}
						/>
					</div>
					<div className="mt-2">
						<Label htmlFor="address-2">Address Line 2</Label>
						<Input
							className="w-full"
							debounce={500}
							name="address-2"
							type="text"
							value={newUser.address2}
							onChange={({ target: { value } }) => handleUpdateNewUser({ address2: value })}
						/>
					</div>
					<div className="flex flex-row justify-between mt-2 w-full">
						<div className="w-full">
							<Label htmlFor="city">City</Label>
							<Input
								className="w-full"
								debounce={500}
								name="city"
								type="text"
								value={newUser.city}
								onChange={({ target: { value } }) => handleUpdateNewUser({ city: value })}
							/>
						</div>
						<div className="ml-4 w-full">
							<Label htmlFor="state-province-region">State / Province / Region</Label>
							{newUser.country === 'US' ? (
								<Dropdown
									key="add-new-customer-state"
									name="state-province-region"
									onChange={({ value }) => handleUpdateNewUser({ state: value as string })}
									options={STATE_DROPDOWN_OPTIONS}
								/>
							) : (
								<Input
									className="w-full"
									debounce={500}
									name="state"
									type="text"
									value={newUser.state}
									onChange={({ target: { value } }) => handleUpdateNewUser({ state: value })}
								/>
							)}
						</div>
					</div>
					<div className="flex flex-row justify-between mt-2 w-full">
						<div className="w-full">
							<Label htmlFor="postalCode">ZIP / Postal Code</Label>
							<Input
								className="w-full"
								debounce={500}
								name="postalCode"
								type="text"
								value={newUser.zipCode}
								onChange={({ target: { value } }) => handleUpdateNewUser({ zipCode: value })}
							/>
						</div>
						<div className="ml-4 w-full">
							<Label htmlFor="country">Country</Label>
							<Dropdown
								key="add-new-customer-country"
								name="country"
								onChange={({ value }) => handleUpdateNewUser({ country: value as string })}
								options={COUNTRY_DROPDOWN_OPTIONS}
							/>
						</div>
					</div>
				</div>
				<hr className="my-8" />
				<div>
					<div className="flex flex-row justify-between w-full">
						<div className="grow">
							<Label htmlFor="sas-buyer-id">SAS Buyer ID</Label>
							<Input
								className="w-full"
								debounce={500}
								name="sas-buyer-id"
								type="text"
								value={newUser.sasBuyerId}
								onChange={({ target: { value } }) => handleUpdateNewUser({ sasBuyerId: value })}
							/>
						</div>
						<div className="grow ml-4">
							<Label htmlFor="sas-seller-id">SAS Seller ID</Label>
							<Input
								className="w-full"
								debounce={500}
								name="sas-seller-id"
								type="text"
								value={newUser.sasSellerId}
								onChange={({ target: { value } }) => handleUpdateNewUser({ sasSellerId: value })}
							/>
						</div>
					</div>
					<div className="flex flex-row justify-between mt-2 w-full">
						<div className="w-1/2">
							<Label htmlFor="seller-commission">Seller Commission</Label>
							<Input
								className="w-full"
								debounce={500}
								min={0}
								name="seller-commission"
								step={1}
								type="number"
								value={newUser.sellerCommission}
								onChange={({ target: { value } }) =>
									handleUpdateNewUser({ sellerCommission: Number(value) })
								}
							/>
						</div>
						<div className="ml-4 w-1/2">
							<Label htmlFor="verified-tin">Verified TIN</Label>
							<Checkbox
								name="verified-tin"
								checked={newUser.verifiedTin || false}
								onChange={(newState: boolean) => handleUpdateNewUser({ verifiedTin: newState })}
								label=""
							/>
						</div>
					</div>
				</div>
			</div>
		</ActionModal>
	);
};

export default AddNewCustomerModal;

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import OrdersRoot from '../../Pages/orders/OrdersRoot';
import FixedPriceRoot from '../../Pages/fixed-price';
import RequireAuth from '../RequireAuth';
import Auctions from '../../Pages/Auctions';
import Dashboards from '../../Pages/Dashboards';
import Inventory from '../../Pages/Inventory';
import Customize from '../../Pages/Customize/CustomizeRouter';
import Login from '../../Pages/Auth/Login';
import ChangePassword from '../../Pages/Auth/ChangePassword';
import ForgotPassword from '../../Pages/Auth/ForgotPassword';
import ChangePasswordConfirm from '../../Pages/Auth/ChangePasswordConfirm';
import UserManagement from '../../Pages/UserManagement';
import FinanceRoot from '../../Pages/Finance/FinanceRoot';
import HookInjector from '../../components/HookInjector';

const MainSwitch = () => {
	return (
		<Switch>
			<Route
				path="/login"
				component={() => (
					<HookInjector>
						<Login />
					</HookInjector>
				)}
			/>
			<Route path="/forgotpassword" component={ForgotPassword} />
			<Route path="/changepassword" component={ChangePassword} />
			<Route path="/changepasswordconfirmation" component={ChangePasswordConfirm} />
			<RequireAuth>
				<Route path="/Performance" component={Dashboards} />
				<Route path="/auctions" component={Auctions} />
				<Route path="/inventory" component={Inventory} />
				<Route path="/customize" component={Customize} />
				<Route path="/usermanagement" component={UserManagement} />
				<Route path="/finance" component={FinanceRoot} />
				<Route path="/orders/:orderNumber" component={OrdersRoot} />
				<Route path="/fixed-price" component={FixedPriceRoot} />
				<Route exact path="/">
					<Redirect to="/Performance/Summary" />
				</Route>
			</RequireAuth>
			<Route>
				<Redirect to="/login" />
			</Route>
		</Switch>
	);
};

export default MainSwitch;

import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AuctionSchedule from '../../../components/AuctionSchedule';
import AuctionStatusChip from '../../../components/AuctionStatusChip';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import { Button } from '../../../prizm-ui/Button';
import useAuctionById from '../../../queries/useAuctionById';
import useEmployeeById from '../../../queries/useEmployeeById';
import { Auction } from '../../../services/auctionsService';
import { BaseUser } from '../../../services/userService/userService.model';
import AuctionImageEditor from './AuctionImageEditor';
import AuctionTechnicalDetails from './AuctionTechnicalDetails';
import CreateAuctionModal from './CreateAuctionModal';
import DeleteAuctionModal from './DeleteAuctionModal';
import EditAuctionFields from './EditAuctionFields';

const NewAuctionManager = () => {
	const { auctionId } = useParams<{ auctionId: string }>();
	const { data: thisAuction = {} as Auction, status: thisAuctionLoadStatus } =
		useAuctionById(auctionId);

	const history = useHistory();

	const [shouldShowCreateAuctionModal, setShouldShowCreateAuctionModal] = useState(false);
	const [shouldShowDeleteAuctionModal, setShouldShowDeleteAuctionModal] = useState(false);

	const { data: createdByUser = {} as BaseUser, status: createdByUserStatus } = useEmployeeById(
		thisAuction?.createdBy
	);
	const { data: modifiedByUser = {} as BaseUser, status: modifiedByUserStatus } = useEmployeeById(
		thisAuction?.modifiedBy
	);

	return (
		<>
			<Card>
				<h1 className="text-display4">Auction Manager</h1>
				<LoadingWrapper
					queryStatuses={[thisAuctionLoadStatus, createdByUserStatus, modifiedByUserStatus]}
					key={auctionId}
				>
					<div className="flex">
						<h2 className="text-display5 w-72 lg:w-auto">
							{thisAuction?.title ?? 'No auction selected'}
						</h2>
						<div className="ml-2 mt-1">
							<AuctionStatusChip status={thisAuction.status} />
						</div>
					</div>
					<div className="flex mt-2">
						<div className="text-body1 mr-4 lg:mr-2 flex flex-col lg:flex-row">
							<span className="mr-1">Created By </span>
							<span className="font-semibold">
								{`${createdByUser.givenName ?? ''} ${createdByUser.familyName ?? ''}`.trim() ||
									'Unknown User'}
							</span>
						</div>
						<div className="text-body1 pl-4 lg:pl-2 border-l-base flex flex-col lg:flex-row">
							<span className="mr-1">Last Modified By </span>
							<span className="font-semibold">
								{`${modifiedByUser.givenName ?? ''} ${modifiedByUser.familyName ?? ''}`.trim() ||
									'Unknown User'}
							</span>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row lg:justify-between mt-4">
						<div className="flex flex-col lg:flex-row">
							<div className="lg:mr-2 mb-4 lg:mb-0">
								<NewAuctionPicker
									selectedAuctionId={auctionId}
									setSelectedAuctionId={(selectedAuctionId) =>
										history.push(`/auctions/${selectedAuctionId}/auction-manager`)
									}
								/>
							</div>
							<div className="lg:mr-2 mb-4 lg:mb-0">
								<Button
									size="md"
									trailingIcon="AddFilled"
									variant="secondary"
									onClick={() => setShouldShowCreateAuctionModal(true)}
								>
									Create Auction
								</Button>
								{shouldShowCreateAuctionModal && (
									<CreateAuctionModal onClose={() => setShouldShowCreateAuctionModal(false)} />
								)}
							</div>
						</div>
						<div>
							<Button
								variant="primary"
								destructive={true}
								size="md"
								trailingIcon="Delete"
								onClick={() => setShouldShowDeleteAuctionModal(true)}
							>
								Delete Auction
							</Button>
							{shouldShowDeleteAuctionModal && (
								<DeleteAuctionModal
									auction={thisAuction}
									onClose={() => setShouldShowDeleteAuctionModal(false)}
								/>
							)}
						</div>
					</div>
					<div className="mt-4">
						<Link className="link" to={`/auctions/${auctionId}/inventory-manager`}>
							Manage Lots
						</Link>
					</div>
				</LoadingWrapper>
			</Card>
			<LoadingWrapper
				queryStatuses={[thisAuctionLoadStatus, createdByUserStatus, modifiedByUserStatus]}
			>
				<div className="mt-8">
					<Card>
						<h3 className="text-display5">Schedule</h3>
						<div className="mt-4">
							<AuctionSchedule
								startTimestamp={thisAuction.startTimestamp}
								endTimestamp={thisAuction.endTimestamp}
								previewTimestamp={thisAuction.previewTimestamp}
							/>
							<div className="mt-4">
								<Link className="link" to={`/auctions/AuctionPublisher/${auctionId}`}>
									Modify Schedule
								</Link>
							</div>
						</div>
					</Card>
				</div>
				<div className="my-8">
					<Card>
						<h3 className="text-display5">Auction Details</h3>
						<EditAuctionFields auction={thisAuction} />
					</Card>
				</div>
				<div className="mb-8">
					<AuctionTechnicalDetails
						commission={thisAuction.commission}
						buyerPremium={thisAuction.buyerPremium}
						extendedBiddingDuration={thisAuction.extendedBiddingDuration}
						type={thisAuction.type}
					/>
				</div>
				<AuctionImageEditor existingImage={thisAuction.primaryImageName} auctionId={auctionId} />
			</LoadingWrapper>
		</>
	);
};

export default NewAuctionManager;

export type OfferStatus = 'ACCEPTED' | 'ACTIVE' | 'PENDING' | 'EXPIRED' | 'DECLINED' | 'COUNTERED';

export interface FixedPriceOffer {
	createdAt: string;
	lotId: string;
	offerer: {
		id: string;
		fullName: string;
		email: string;
	};
	offerId: string;
	offerPrice: number;
	oldStripePaymentIntentId: string;
	paymentMethodId: string;
	offerStatus: OfferStatus;
	stripePaymentIntentId: string;
}

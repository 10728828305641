import { useQuery } from 'react-query';
import { GetInvoicesParams, getInvoicesV2 } from '../../services/invoicesService';
import { InvoicesQueryType } from '../../services/invoicesService/invoicesService.model';
import { QueryKeys } from '../queryKeys';

interface BaseUseInvoicesV2Params {
	queryType: InvoicesQueryType;
	mode: 'auction' | 'user';
	auctionId?: string;
	userId?: string;
}
interface UseInvoicesByUserParams extends BaseUseInvoicesV2Params {
	mode: 'user';
	userId: string;
}
interface UseInvoicesByAuctionParams extends BaseUseInvoicesV2Params {
	mode: 'auction';
	auctionId: string;
}
type UseInvoicesV2Params = UseInvoicesByUserParams | UseInvoicesByAuctionParams;

const useInvoicesV2 = (params: UseInvoicesV2Params) => {
	const { queryType, mode, auctionId, userId } = params;

	const getInvoicesParams: GetInvoicesParams =
		mode === 'auction' ? { mode, auctionId, queryType } : { mode, userId, queryType };

	return useQuery(
		[QueryKeys.GetInvoices, auctionId, userId, queryType],
		() => getInvoicesV2(getInvoicesParams),
		{ enabled: !!auctionId || !!userId }
	);
};

export default useInvoicesV2;

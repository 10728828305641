import { Auth } from 'aws-amplify';
import React, { FC, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const RequireAuth: FC<{ unauthRedirectTo?: string }> = ({
	children,
	unauthRedirectTo = '/login',
}) => {
	const location = useLocation();
	// Based on a pattern from https://serverless-stack.com/chapters/load-the-state-from-the-session.html
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		try {
			await Auth.currentSession();
			userHasAuthenticated(true);
		} catch (e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}

		setIsAuthenticating(false);
	}

	if (isAuthenticating) {
		return <></>;
	}

	if (!isAuthenticating && !isAuthenticated) {
		return (
			/** @ts-ignore */
			<Redirect
				to={{
					pathname: unauthRedirectTo,
					state: {
						from: location,
					},
				}}
			/>
		);
	}

	return <>{children}</>;
};

export default RequireAuth;

import React, { ClipboardEventHandler, FocusEventHandler } from 'react';
import { TableCell, Tooltip } from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
import { SortableTableColumn } from '../../../../TypesafeSortableTable/TypesafeSortableTable.model';
import { StripHtml } from '../../../../../../Utilities';
import { noop } from 'lodash';

const DescriptionCell = <T extends unknown>({
	column,
	value,
	contentEditable,
	rowNumber,
	handlePaste = noop,
	handleUpdateEditableContent,
	onFocus,
}: {
	column: SortableTableColumn<T>;
	value: string;
	contentEditable?: boolean;
	rowNumber: number;
	handlePaste?: ClipboardEventHandler;
	handleUpdateEditableContent: FocusEventHandler;
	onFocus: FocusEventHandler;
}) => {
	const formattedValue = column.format ? column.format(value) : value;

	return (
		<Tooltip title={StripHtml(formattedValue)}>
			<TableCell key={column.id + rowNumber} className="widget-content-left flex2 align-middle">
				{contentEditable && !column.readOnly ? (
					<ContentEditable
						html={value}
						data-column={column.id}
						data-row={rowNumber}
						className="content-editable"
						onBlur={handleUpdateEditableContent}
						onPaste={handlePaste}
						onFocus={onFocus}
						onChange={noop}
					/>
				) : (
					value
				)}
			</TableCell>
		</Tooltip>
	);
};

export default DescriptionCell;

import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import AuctionPickerModalContent from '../../../../../components/NewAuctionPicker/AuctionPickerModal/AuctionPickerModalContent/AuctionPickerModalContent';
import EditGeneralLotInfo from '../../EditGeneralLotInfo/EditGeneralLotInfo';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Modal, ModalContent, ModalHeader } from '../../../../../prizm-ui/Modal';
import { AddNewLotModalStep, ADD_NEW_LOT_MODAL_STEPS } from './AddNewLotModal.model';
import { BLANK_LOT } from '../CreateLot/CreateLot.model';
import { createLot } from '../../../../../services/lotsService';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useLotsForAuction from '../../../../../queries/useLotsForAuction/useLotsForAuction';

const AddNewLotModal = ({ onClose }: { onClose: () => void }) => {
	const [firstStep] = ADD_NEW_LOT_MODAL_STEPS;

	const [currentStep, setCurrentStep] = useState<AddNewLotModalStep>(firstStep);

	const [selectedAuctionId, setSelectedAuctionId] = useState('');

	useEffect(() => {
		if (selectedAuctionId !== '') {
			const nextStep = ADD_NEW_LOT_MODAL_STEPS.findIndex(({ id }) => id === currentStep.id) + 1;

			setCurrentStep(ADD_NEW_LOT_MODAL_STEPS[nextStep]);
		}
	}, [selectedAuctionId]);

	const newLot: KernelLot = {
		...BLANK_LOT,
		auction_id: selectedAuctionId,
	};

	const { mutate: doCreateLot, status: createLotStatus } = useMutation({
		mutationKey: [QueryKeys.CreateLot, new Date().toISOString()],
		mutationFn: (lot: KernelLot) => createLot(lot),
	});

	const { addLotsToCache } = useLotsForAuction({
		auctionId: selectedAuctionId,
	});

	const history = useHistory();
	const queryClient = useQueryClient();

	const saveNewLot = (draftLot: KernelLot) => {
		doCreateLot(draftLot, {
			onSuccess: (newlyCreatedLot: KernelLot) => {
				showSuccessToast('Successfully created new lot.');
				addLotsToCache([newlyCreatedLot]);
				queryClient.setQueryData(
					[QueryKeys.GetIndividualLot, newlyCreatedLot.lot_id],
					() => newlyCreatedLot
				);
				history.push(
					`/auctions/${newlyCreatedLot.auction_id}/inventory-manager/${newlyCreatedLot.lot_id}`
				);
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong creating the lot. Please try again later.');
			},
		});
	};

	return (
		<Modal isOpen={true}>
			<ModalHeader onClose={onClose}>{currentStep.title}</ModalHeader>
			<ModalContent>
				{currentStep.id === 'select-auction' ? (
					<AuctionPickerModalContent
						allowedAuctionStatuses={[]}
						closeAfterSelection={false}
						selectedAuctionId={selectedAuctionId}
						setSelectedAuctionId={setSelectedAuctionId}
						shouldShowUnassignedLots={false}
						onClose={onClose}
					/>
				) : (
					<EditGeneralLotInfo
						lot={newLot}
						isNewLot={true}
						saveStatus={createLotStatus}
						onClose={onClose}
						onCreate={saveNewLot}
					/>
				)}
			</ModalContent>
		</Modal>
	);
};

export default AddNewLotModal;

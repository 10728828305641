import config from '../../config';

export const getStripeStatementUrl = (payoutAccountId = ''): string => {
	if (!payoutAccountId) {
		return '#';
	}

	const baseUrl =
		config.environment === 'production'
			? 'https://dashboard.stripe.com'
			: 'https://dashboard.stripe.com/test';

	return baseUrl + `/connect/accounts/${payoutAccountId}/activity`;
};

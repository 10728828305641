import { Storage } from 'aws-amplify';
import config from '../config';

const uncompressedBucketConfig = {
	bucket: config.aws_sdk.ADMIN_UNCOMPRESSED_BUCKET,
};

export async function retrieveImageAlbum(imageQuery) {
	try {
		if (!imageQuery) return;

		let baseKey = imageQuery.type + '/' + (imageQuery.id ? imageQuery.id + '/' : '');
		const fileKeys = await Storage.list(baseKey, uncompressedBucketConfig);

		if (!fileKeys) return [];
		// const fileKeyRegex = new RegExp(/^.+@[1-3]x(\.(jpg|JPG|png|PNG))?$/, 'i');
		let resultFiles = [];
		for (let i = 0; i < fileKeys.length; i++) {
			// If one of the fileKeys is just pointing to the folder, skip it. We only want real files.
			if (fileKeys[i].key === imageQuery.type + '/') continue;
			const file = await Storage.get(fileKeys[i].key, uncompressedBucketConfig);
			console.log(file);
			resultFiles.push(file);
		}
		return resultFiles;
	} catch (err) {
		console.log(`Unable to retrieve images: ${err}`);
		throw err;
	}
}

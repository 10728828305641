import { Chip } from '../../../prizm-ui/Chip';
import { Sentiment } from '../../../prizm-ui/types';
import { CheckoutOrderCollectionsStatus } from '../../../services/ordersService/ordersService.model';

const getSentimentForCollectionsStatus = (status: CheckoutOrderCollectionsStatus): Sentiment => {
	switch (status) {
		case 'active':
			return 'success';
		case 'defaulted':
			return 'error';
		case 'en_route':
			return 'gray';
		case 'vip':
			return 'primary';
		default:
			return 'gray';
	}
};

const getCollectionsStatusLabel = (status: CheckoutOrderCollectionsStatus) => {
	switch (status) {
		case 'active':
			return 'Active';
		case 'defaulted':
			return 'Defaulted';
		case 'en_route':
			return 'En route';
		case 'vip':
			return 'VIP';
	}
};

const CollectionsStatusChip = ({ status }: { status?: CheckoutOrderCollectionsStatus }) => {
	if (!status) return <></>;

	return (
		<Chip variant={getSentimentForCollectionsStatus(status)}>
			{getCollectionsStatusLabel(status)}
		</Chip>
	);
};

export default CollectionsStatusChip;

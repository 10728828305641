import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Input } from '../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import useUserRole from '../../../../queries/useUserRole';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotFinancialDetailsModal = ({
	lot,
	onClose,
}: {
	lot: KernelLot;
	onClose: () => void;
}) => {
	const [draftLot, setDraftLot] = useState(lot);
	const updateDraftLot = (deltas: Partial<KernelLot>) => setDraftLot({ ...draftLot, ...deltas });

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () => {
		if (updatedFields.min_bid_price && updatedFields.min_bid_price < 0) {
			showErrorToast('Minimum Bid Price must be greater than or equal to zero.');

			return;
		}

		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated lot financial details');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating lot financial details. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});
	};

	const { data: userRole = 'Unknown' } = useUserRole();
	const canOverrideAfterSale = ['Admin', 'Finance'].includes(userRole);

	return (
		<ActionModal
			title="Edit Lot Financial Details"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={!isEqual(lot, draftLot)}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Input
					disabled={lot.status === 'Completed_Sold'}
					id="estimated_value"
					label="Estimated Value"
					min="0"
					type="number"
					value={draftLot.estimated_value}
					onChange={({ target: { value } }) => updateDraftLot({ estimated_value: Number(value) })}
				/>
				<Input
					disabled={lot.status === 'Completed_Sold' && !canOverrideAfterSale}
					id="commission"
					label="Commission"
					min="0"
					type="number"
					value={draftLot.commission}
					onChange={({ target: { value } }) => updateDraftLot({ commission: Number(value) })}
				/>
				<Input
					disabled={lot.status === 'Completed_Sold'}
					id="min_bid_price"
					label="Minimum Bid Price"
					min="0"
					type="number"
					value={draftLot.min_bid_price}
					onChange={({ target: { value } }) => updateDraftLot({ min_bid_price: Number(value) })}
				/>
				<Input
					disabled={lot.status === 'Completed_Sold'}
					id="reserve_amount"
					label="Reserve Amount"
					min="0"
					type="number"
					value={draftLot.reserve_amount}
					onChange={({ target: { value } }) => updateDraftLot({ reserve_amount: Number(value) })}
				/>
				<Input
					disabled={lot.status === 'Completed_Sold'}
					id="internal_reserve_amount"
					label="Internal Reserve Amount"
					min="0"
					type="number"
					value={draftLot.internal_reserve_amount}
					onChange={({ target: { value } }) =>
						updateDraftLot({ internal_reserve_amount: Number(value) })
					}
				/>
			</div>
		</ActionModal>
	);
};

export default EditLotFinancialDetailsModal;

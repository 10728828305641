import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Input } from '../../../../prizm-ui/Input';
import { Textarea } from '../../../../prizm-ui/Textarea';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import HtmlTextarea from '../../../HtmlTextarea';
import Label from '../../../Label';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotHeaderModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const [draftLot, setDraftLot] = useState(lot);
	const isFormValid = !!(draftLot.title && draftLot.title.trim().length);
	const updateDraftLot = (deltas: Partial<KernelLot>) => {
		setDraftLot((prevState) => ({ ...prevState, ...deltas }));
	};

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () => {
		if (!isFormValid) {
			return;
		}
		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated primary info for lot');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating primary info for lot. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});
	};

	return (
		<ActionModal
			title="Edit Primary Info for Lot"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={!isEqual(lot, draftLot) && isFormValid}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Input
					id="lot_number"
					value={draftLot.lot_number}
					label="Lot Number"
					type="number"
					onChange={({ target: { value } }) => updateDraftLot({ lot_number: value })}
				/>
				<Textarea
					id="title"
					value={draftLot.title}
					label="Title"
					onChange={({ target: { value } }) => updateDraftLot({ title: value })}
				/>
				<div>
					<Label htmlFor="description">Description</Label>
					<HtmlTextarea
						initialValue={draftLot.description}
						onChange={(value) => updateDraftLot({ description: value })}
						placeholder="Enter a description for the lot"
					/>
				</div>
			</div>
		</ActionModal>
	);
};

export default EditLotHeaderModal;

import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import SortableTable from '../../../../components/SortableTable';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import { AsyncUsername } from '../../../../components/Username';
import { Button } from '../../../../prizm-ui/Button';
import useAuctionById from '../../../../queries/useAuctionById';
import useSellerStatementDetails from '../../../../queries/useSellerStatementDetails';
import { Auction } from '../../../../services/auctionsService';
import {
	KernelSellerStatementAdjustment,
	KernelSellerStatementAdjustmentCategoryType,
	KernelSellerStatementDetails,
} from '../../../../services/sellerStatementService';
import { formatDate } from '../../../../utils/formatters';
import DeleteStatementAdjustmentButton from './DeleteStatementAdjustmentButton';
import SellerStatementAdjustmentModal from './SellerStatementAdjustmentModal';
import { SELLER_STATEMENT_ADJUSTMENT_CATEGORY_TYPE_LABEL } from './SellerStatementAdjustments.model';

const SellerStatementAdjustments = ({
	adjustments = [{} as KernelSellerStatementAdjustment],
	auctionId,
	statementId,
	statementNumber,
}: {
	adjustments?: KernelSellerStatementAdjustment[];
	auctionId: string;
	statementId: string;
	statementNumber: string;
}) => {
	const [shouldShowSellerStatementAdjustmentModal, setShouldShowSellerStatementAdjustmentModal] =
		useState(false);

	const columns: AccessorKeyColumnDef<KernelSellerStatementAdjustment>[] = [
		{
			accessorKey: 'adjustment_id',
			header: '',
			cell: ({ row: { original } }) => (
				<DeleteStatementAdjustmentButton
					adjustment={original}
					allAdjustments={adjustments ?? []}
					auctionId={auctionId}
					statementId={statementId}
				/>
			),
		},
		{
			accessorKey: 'description',
			header: 'Description',
		},
		{
			accessorKey: 'adjustment_type',
			header: 'Type',
		},
		{
			accessorKey: 'category_type',
			header: 'Category',
			cell: ({ getValue }) =>
				SELLER_STATEMENT_ADJUSTMENT_CATEGORY_TYPE_LABEL[
					getValue() as KernelSellerStatementAdjustmentCategoryType
				],
		},
		{
			accessorKey: 'amount',
			header: 'Amount',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatCurrency(getValue() as number),
		},
		{
			accessorKey: 'created_by',
			header: 'Created By',
			cell: ({ getValue }) => <AsyncUsername type="employee" id={(getValue() as string) ?? ''} />,
		},
		{
			accessorKey: 'created_at',
			header: 'Created At',
			cell: ({ getValue }) => formatDate(getValue() as string),
		},
	];

	const tableParams: SortableTableParams<KernelSellerStatementAdjustment> = {
		columns,
		data: adjustments,
		initialState: {
			sorting: [
				{
					desc: true,
					id: 'created_at',
				},
			],
		},
	};

	const { data: statementDetails = {} as KernelSellerStatementDetails } = useSellerStatementDetails(
		{
			auctionId,
			statementId,
		}
	);

	const { data: auction = {} as Auction } = useAuctionById(auctionId);

	const columnNames = ['seller_statement_number', ...columns.map(({ accessorKey }) => accessorKey)];
	const csvDocumentName = `Adjustments for Statement ${statementDetails.seller_statement_number} in Auction ${auction.title}`;

	return (
		<>
			<div className="w-full flex flex-col lg:flex-row lg:justify-between">
				<h2 className="text-display5">Adjustments</h2>
				<Button
					size="md"
					className="mt-4 lg:mt-0"
					onClick={() => setShouldShowSellerStatementAdjustmentModal(true)}
				>
					Make Payment/Adjustment
				</Button>
			</div>
			<div className="mt-4">
				<SortableTable tableParams={tableParams} />
			</div>
			<div className="mt-4">
				<DownloadCSVButton
					items={tableParams.data.map((adjustment) => ({
						...adjustment,
						seller_statement_number: statementNumber,
					}))}
					columnNames={columnNames}
					documentName={csvDocumentName}
				/>
			</div>
			{shouldShowSellerStatementAdjustmentModal && (
				<SellerStatementAdjustmentModal
					auctionId={auctionId}
					statementId={statementId}
					onClose={() => setShouldShowSellerStatementAdjustmentModal(false)}
				/>
			)}
		</>
	);
};

export default SellerStatementAdjustments;

import { putAuthed } from '../../AdminUtils/httpUtils';
import { Banner } from './layoutService.model';

export const updateBanners = async (banners: Banner[]) => {
	const response = await putAuthed(
		`${process.env.API_LAYOUTS}/api/home/banners`,
		banners.map((banner) => ({ ...banner, startDate: '', endDate: '' }))
	);

	if (response.status >= 400) {
		throw response;
	}

	return response?.data || response?.data?.errorMessage;
};

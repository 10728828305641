import { isBefore } from 'date-fns';
import { isArray } from 'lodash';
import { postAuthed } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { CheckoutOrderComment } from '../ordersService/ordersService.model';
import { InvoicesQueryType, KernelInvoice } from './invoicesService.model';

interface BaseGetInvoicesParams {
	queryType: InvoicesQueryType;
	userId?: string;
	auctionId?: string;
}
interface GetInvoicesByUserParams extends BaseGetInvoicesParams {
	userId: string;
	mode: 'user';
}
interface GetInvoicesByAuctionParams extends BaseGetInvoicesParams {
	auctionId: string;
	mode: 'auction';
}
export type GetInvoicesParams = GetInvoicesByUserParams | GetInvoicesByAuctionParams;

/**
 * Based on queryType, getInvoicesByUserV2 will POST to the config.api.auction_invoices endpoint
 * with filters for invoice_status and/or shipping_status, query_type of 'user', and winner_id
 * being userId. The queryType parameter comes from InvoicesQueryType.
 *
 * By default, the filters are empty strings for both invoice_status and shipping_status.
 *
 * If queryType is 'All', then we will use the default empty string filters.
 * If queryType is 'Blocked', then our invoice_status filter will be 'Blocked'.
 * If queryType is 'Pending_Approval', then our invoice_status filter will be 'Pending_Approval'.
 * If queryType is 'Awaiting_Payment', then our invoice_status filter will be 'Awaiting_Payment'.
 * If queryType is 'Payment_Failed', then our invoice_status filter will be 'Payment_Failed'.
 * If queryType is 'Payment_Received', then our invoice_status filter will be 'Paid' and shipping_status
 * filter will be 'Unpicked'.
 * If queryType is 'Past_Due', then our filters will be empty strings. We will then filter the results
 * to only include invoices with invoice_status not equal to 'Paid', where due_date is before today.
 * If queryType is 'Shipped', then our shipping_status filter will be 'Shipped' and the invoice_status
 * filter will be 'Paid'.
 *
 */

export const getInvoicesV2 = async (params: GetInvoicesParams) => {
	const { queryType, mode } = params;
	/**
	 * Note: only Github Copilot is allowed to do something this ugly, based on the commandments
	 * set forth above. This was just too complicated of logic to try to do in a more readable way.
	 */
	const filters = {
		invoice_status: '',
		shipping_status: '',
	};

	if (queryType === 'All') {
		// Do nothing, filters are already empty strings.
	} else if (queryType === 'Blocked') {
		filters.invoice_status = 'Blocked';
	} else if (queryType === 'Pending_Approval') {
		filters.invoice_status = 'Pending_Approval';
	} else if (queryType === 'Awaiting_Payment') {
		filters.invoice_status = 'Awaiting_Payment';
	} else if (queryType === 'Payment_Failed') {
		filters.invoice_status = 'Payment_Failed';
	} else if (queryType === 'Payment_Received') {
		filters.invoice_status = 'Paid';
		filters.shipping_status = 'Unpicked';
	} else if (queryType === 'Past_Due') {
		// Do nothing, filters are already empty strings.
	} else if (queryType === 'Shipped') {
		filters.shipping_status = 'Shipped';
		filters.invoice_status = 'Paid';
	}

	const getInvoicesByUsersHandler = async () =>
		await postAuthed<{ body: { invoices: KernelInvoice[] } }>(`${config.api.auction_invoices}`, {
			company_id: config.organization.company_id,
			filters,
			query_type: 'user',
			winner_id: params.userId,
		});

	const getInvoicesByAuctionHandler = async () =>
		await postAuthed<{ body: { invoices: KernelInvoice[] } }>(`${config.api.auction_invoices}`, {
			company_id: config.organization.company_id,
			filters: filters.shipping_status ? { shipping_status: filters.shipping_status } : undefined,
			query_type: 'auction',
			auction_id: params.auctionId,
			invoice_status: filters.invoice_status,
		});

	const handler = mode === 'user' ? getInvoicesByUsersHandler : getInvoicesByAuctionHandler;

	const response = await handler();

	if (response.status >= 400) {
		throw response;
	}

	/** The amount_due, amount_remaining, and amount_paid fields on invoices come to us as cents and need to be mapped to dollars.
	 *  The reason key is a string array for some reason, but the use case calls for it to be flattened down to a string as stringifiedReason.
	 *  Also tracking_id is a combination of tracking.vendor and tracking.tracking_id from the backend.
	 *  Comments also have to be normalized into a flat string based on whether the invoice uses_checkout,
	 *  and if comments comes across to us as an array or a string.
	 */

	const normalizeComments = (invoice: KernelInvoice) => {
		if (!invoice.uses_checkout) {
			return invoice.comments;
		}

		if (!isArray(invoice.comments)) {
			return invoice.comments;
		}

		const [_, lastComment = {} as CheckoutOrderComment] = invoice.comments;

		return lastComment?.text ?? '';
	};

	const normalizeTrackingNumbers = (invoice: KernelInvoice) => {
		const parsedTrackingInfo = JSON.parse(invoice.tracking_id);
		if (isArray(parsedTrackingInfo)) {
			return parsedTrackingInfo.map((tracking) => `${tracking.Vendor} ${tracking.tracking_id}`);
		}
		return [invoice.tracking_id];
	};

	const mappedInvoices = response.data.body.invoices.map((invoice: KernelInvoice) => ({
		...invoice,
		amount_due: invoice.amount_due / 100,
		amount_remaining: invoice.amount_remaining / 100,
		amount_paid: invoice.amount_paid / 100,
		comments: normalizeComments(invoice),
		stringified_reason: (invoice.reason ?? []).join(', '),
		tracking_numbers: normalizeTrackingNumbers(invoice),
	}));

	if (queryType === 'Past_Due') {
		const pastDueInvoices = mappedInvoices.filter(
			(invoice: KernelInvoice) =>
				invoice.invoice_status !== 'Paid' && isBefore(new Date(invoice.due_date ?? ''), new Date())
		);

		return pastDueInvoices;
	}

	return mappedInvoices;
};

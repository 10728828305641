import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelBidByUserForAuction } from './getBidsByUserForAuction.model';

const getBidsByUserForAuction = async ({
	userId,
	auctionId,
}: {
	userId: string;
	auctionId: string;
}): Promise<KernelBidByUserForAuction[]> => {
	const response = await postAuthed(`${apiEndpoints.user_bids}`, {
		user_id: userId,
		auction_id: auctionId,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

export default getBidsByUserForAuction;

import { Chip } from '../../prizm-ui/Chip';
import { AuctionStatus } from '../../services/auctionsService';
import { stripUnderscores } from '../../utils/formatters';
import { getChipVariantForAuctionStatus } from './AuctionStatusChip.utils';

const AuctionStatusChip = ({ status }: { status: AuctionStatus }) => {
	return <Chip variant={getChipVariantForAuctionStatus(status)}>{stripUnderscores(status)}</Chip>;
};

export default AuctionStatusChip;

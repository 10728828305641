import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelSellerStatementDetails } from './sellerStatementService.model';

export const updateSellerStatement = async ({
	auctionId,
	statementId,
	deltas,
}: {
	auctionId: string;
	statementId: string;
	deltas: Partial<KernelSellerStatementDetails>;
}) => {
	const response = await axiosAuth.post(`${config.api.update_seller_statement}`, {
		auction_id: auctionId,
		seller_statement_id: statementId,
		updates: deltas,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import AuctionStatusChip from '../../../components/AuctionStatusChip';
import Card from '../../../components/Card';
import SortableTable from '../../../components/SortableTable';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import useAuctions from '../../../queries/useAuctions';
import { Auction, AuctionStatus } from '../../../services/auctionsService';
import { formatDate, formatFloat } from '../../../utils/formatters';
import formatCurrency from '../../../utils/formatters/formatCurrency';

const PerformanceDashboard = () => {
	const [mostRecentRefetchTime, setMostRecentRefetchTime] = useState(new Date());

	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions({
		refetchInterval: 60 * 1000,
		onSuccess: () => setMostRecentRefetchTime(new Date()),
	});

	const filteredAuctions = auctions.filter(({ status }) =>
		['Active', 'Completed'].includes(status)
	);

	const columns: ColumnDef<Auction>[] = [
		{
			accessorKey: 'title',
			header: 'Title',
			cell: ({ getValue }) => <div className="w-72">{getValue()}</div>,
		},
		{
			accessorKey: 'status',
			header: 'Status',
			cell: ({ getValue }) => (
				<div className="flex justify-end">
					<AuctionStatusChip status={getValue() as AuctionStatus} />
				</div>
			),
		},
		{
			accessorKey: 'startTimestamp',
			header: 'Start Time',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatDate(getValue() as string),
		},
		{
			accessorKey: 'endTimestamp',
			header: 'End Time',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatDate(getValue() as string),
		},
		{
			accessorKey: 'totalBids',
			header: 'Total Bids',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			accessorKey: 'totalLots',
			header: 'Total Lots',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			accessorKey: 'totalViews',
			header: 'Views',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			accessorKey: 'uniqueBidders',
			header: 'Unique Bidders',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			accessorKey: 'uniqueSellers',
			header: 'Unique Sellers',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			accessorKey: 'bidsPerLot',
			header: 'Bids Per Lot',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-center">{formatFloat(getValue() as string)}</div>
			),
		},
		{
			accessorKey: 'totalBidValue',
			header: 'Total Bid Value',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorKey: 'averageBidValue',
			header: 'Average Bid Value',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorKey: 'totalBuyerPremium',
			header: 'Buyer Premium',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorKey: 'totalCommission',
			header: 'Commission',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorKey: 'sellthroughRate',
			header: 'Sellthrough Rate',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-center">
					{formatFloat(getValue() as string, { isPercentage: true })}
				</div>
			),
		},
		{
			accessorKey: 'unsoldLots',
			header: 'Unsold Lots',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
		},
		{
			id: 'lotsPerWinner',
			header: 'Lots Per Winner',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-center">{formatFloat(getValue() as string)}</div>
			),
		},
		{
			accessorKey: 'gmv',
			header: 'GMV',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
	];

	const sortableTableParams: SortableTableParams<Auction> = {
		columns,
		data: filteredAuctions,
		getRowId: ({ id }) => id,
		initialState: {
			sorting: [
				{
					desc: true,
					id: 'startTimestamp',
				},
			],
		},
	};

	return (
		<Card>
			<h1 className="text-display4">Performance Dashboard</h1>
			<div className="mb-4 text-body1">
				Most recent refresh at {formatDate(mostRecentRefetchTime.toString())}
			</div>
			<SortableTable tableParams={sortableTableParams} isLoading={auctionsStatus === 'loading'} />
		</Card>
	);
};

export default PerformanceDashboard;

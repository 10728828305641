import { compareDesc } from 'date-fns';
import { KernelBidByUserForAuction } from '../../../../../services/bidderService/getBidsByUserForAuction.model';
import BidAmountCell from './BidsTable/BidAmountCell';
import { KernelLotForBidsTable, LotWithBidData } from './BidsTabView.model';

export const parseToLotWithBidData = ({
	lot,
	bids,
}: {
	lot: KernelLotForBidsTable;
	bids: KernelBidByUserForAuction[];
}): LotWithBidData => {
	const { lot_id: thisLotId } = lot;
	const bidsForThisLot = bids.filter(({ lot_id: lotId }) => lotId === thisLotId);

	const [mostRecentBid] = bidsForThisLot.sort((a, b) =>
		compareDesc(new Date(a.timestamp ?? ''), new Date(b.timestamp ?? ''))
	);

	return {
		bidAmount: mostRecentBid?.bid_amount ?? 0,
		currentPrice: lot.current_price || lot.min_bid_price,
		hasUserBidOnThisLot: !!mostRecentBid,
		isCurrentWinner: mostRecentBid?.highest_bidder ?? false,
		id: lot.lot_id,
		lotId: lot.lot_id,
		lotNumber: lot.lot_number,
		mostRecentBidTimestamp: mostRecentBid?.timestamp ?? '',
		numberOfBids: lot.number_of_bids,
		status: lot.status,
		title: lot.title,
	};
};

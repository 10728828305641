import { v4 } from 'uuid';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import {
	KernelSellerStatementAdjustment,
	KernelSellerStatementDetails,
} from './sellerStatementService.model';

export const getSellerStatementDetails = async ({
	auctionId,
	statementId,
}: {
	auctionId: string;
	statementId: string;
}) => {
	if (!statementId) {
		throw new Error('Missing statementId in getSellerStatementDetails');
	}

	const statementLocator =
		statementId.split('-').length > 1
			? { seller_statement_id: statementId }
			: { seller_statement_number: statementId };
	const response = await axiosAuth.post<{
		body: KernelSellerStatementDetails;
		statusCode: number;
	}>(`${config.api.seller_statement_details}`, {
		company_id: config.organization.company_id,
		auction_id: auctionId,
		...statementLocator,
	});

	if (response.status >= 400 || response.data.statusCode >= 400) {
		throw response;
	}

	const {
		data: { body: statementDetails },
	} = response;
	const { adjustments = [] as KernelSellerStatementAdjustment[] } = statementDetails;

	/** This is a necessary stop-gap for deleting individual adjustments until the backend migrates ids onto all previous adjustments */
	const adjustmentsWithIds = adjustments?.map((adjustment) => ({
		...adjustment,
		adjustment_id: adjustment.adjustment_id ?? v4(),
	}));

	return { ...statementDetails, adjustments: adjustmentsWithIds };
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelAuction } from './auctionsService.model';

export const updateAuction = async (auctionId: string, diff: Partial<KernelAuction>) => {
	const params = {
		id: auctionId,
		auction_params: diff,
	};
	const response = await axiosAuth.put(`${config.api.auctions}/${auctionId}`, params);

	if (response.status >= 400) {
		throw response;
	}
	return response.data;
};

import { Sentiment } from '../../prizm-ui/types';
import isJSON from '../../utils/validators/isJSON';
import {
	FixedPriceItem,
	FixedPriceItemStatus,
	KernelFixedPriceItem,
	KernelFixedPriceItemInternalNote,
	KernelLot,
	LotStatus,
} from './lotsService.model';

export const getLotLocation = ({ room = '', bin = '' }: KernelLot) => {
	if (room || bin) {
		return `${room} ${bin}`.trim();
	}

	return 'Unknown';
};

export const kernelFixedPriceItemToDomainFixedPriceItem = (
	item: KernelFixedPriceItem
): FixedPriceItem => {
	const kernelInternalNotes: KernelFixedPriceItemInternalNote = isJSON(item.internal_notes)
		? JSON.parse(item.internal_notes)
		: {};

	return {
		allowOffers: item.allow_offers,
		auctionId: item.auction_id,
		autographType: item.autograph_type,
		buyerPremium: item.buyer_premium,
		category: item.category,
		certNumber: item.cert_number,
		collectibleId: item.collectible_id,
		commission: item.commission,
		createdAt: item.created_at,
		currentPrice: item.current_price,
		estimatedValue: item.estimated_value,
		externalImages: item.external_images || [],
		finalPrice: item.final_price,
		grade: item.grade,
		gradingCompany: item.grading_company,
		internalNotes: {
			author: {
				authorDisplayName: kernelInternalNotes.author?.author_display_name ?? '',
				authorId: kernelInternalNotes.author?.author_id ?? '',
			},
			text: kernelInternalNotes.text ?? '',
			timestamp: kernelInternalNotes.timestamp ?? '',
		},
		itemType: item.item_type,
		lotId: item.lot_id,
		metaSlug: item.meta_slug,
		metaTitle: item.meta_title,
		minOfferPrice: item.min_offer_price,
		playerSubject: item.player_subject,
		sellerEmail: item.seller_email,
		sellerId: item.seller_id,
		serialNumber: item.serial_number,
		setNumber: item.set_number,
		sport: item.sport,
		status: item.status,
		subCategory: item.sub_category,
		title: item.title,
		winnerId: item.winner_id ?? '',
		year: item.year,
	};
};

export const getChipSentimentForFixedPriceItemStatus = (
	status: FixedPriceItemStatus
): Sentiment => {
	switch (status) {
		case 'Completed_Sold':
			return 'error';
		case 'Live':
			return 'success';
		case 'Withdrawn':
			return 'error';
		default:
			return 'gray';
	}
};

export const getChipSentimentForAuctionItemStatus = (status: LotStatus): Sentiment => {
	switch (status) {
		case 'Live':
			return 'success';
		case 'Not_Started':
			return 'primary';
		case 'Preview':
			return 'primary';
		case 'Pause':
			return 'warning';
		case 'Withdrawn':
			return 'gray';
		case 'End':
			return 'error';
		case 'Completed_Sold':
			return 'error';
		case 'Completed_Unsold':
			return 'gray';
		default:
			return 'gray';
	}
};

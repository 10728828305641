import { useQuery, useQueryClient } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { getFixedPriceItem } from '../../services/lotsService/getFixedPriceItem';
import { FixedPriceItem } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';

const useFixedPriceItem = (itemId: string) => {
	const queryKey = [QueryKeys.GetIndividualFixedPriceItem, itemId];

	const baseQuery = useQuery(queryKey, () => getFixedPriceItem(itemId), {
		enabled: !!itemId,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the order. Please refresh and try again.'
			),
	});

	const queryClient = useQueryClient();

	const updateFixedPriceItem = (updatedFields: Partial<FixedPriceItem>) =>
		queryClient.setQueryData(
			queryKey,
			(originalFixedPriceItem: FixedPriceItem = {} as FixedPriceItem) => ({
				...originalFixedPriceItem,
				...updatedFields,
			})
		);

	return { ...baseQuery, updateFixedPriceItem };
};

export default useFixedPriceItem;

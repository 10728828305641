import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Tooltip } from '../../../../../prizm-ui/Tooltip';
import SortableTable from '../../../../SortableTable/SortableTable';
import { SortableTableParams } from '../../../../SortableTable/SortableTable.model';
import { KernelLotHistoryInvoiceEntry } from '../../../../../services/lotsService/lotsService.model';
import { formatDate } from '../../../../../utils/formatters';
import formatCurrency from '../../../../../utils/formatters/formatCurrency/formatCurrency';

const LotInvoiceHistoryContent = ({
	invoiceHistoryEntries,
}: {
	invoiceHistoryEntries: KernelLotHistoryInvoiceEntry[];
}) => {
	const columns: ColumnDef<KernelLotHistoryInvoiceEntry>[] = [
		{
			header: 'Date',
			id: 'date',
			accessorFn: ({ date }) => formatDate(date),
		},
		{
			header: 'Buyer',
			id: 'winner',
			cell: ({
				row: {
					original: { winner, winnerId },
				},
			}) =>
				winner ? (
					<Tooltip content={`View ${winner.fullName ?? ''}'s profile`} id="winner-link-tooltip">
						<Link
							className="block link"
							to={`/usermanagement/customer/${winnerId}/customer-overview`}
						>
							{winner.fullName ?? ''}
						</Link>
					</Tooltip>
				) : (
					''
				),
		},
		{
			header: 'Invoice #',
			id: 'invoice-number',
			accessorKey: 'invoiceNumber',
			cell: ({ getValue }) => (
				<Tooltip content={`View invoice number ${getValue()}`} id="invoice-number-link-tooltip">
					<Link className="block link" to={`/orders/${getValue()}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			header: 'Final Price',
			id: 'final-price',
			accessorFn: ({ finalPrice }) => formatCurrency(finalPrice),
		},
		{
			header: 'Admin',
			id: 'admin',
			accessorFn: ({ modified }) => (modified ? modified.fullName : ''),
		},
	];

	const sortableTableParams: SortableTableParams<KernelLotHistoryInvoiceEntry> = {
		columns,
		data: invoiceHistoryEntries,
		enableColumnFilters: false,
		getRowId: ({ invoiceId }) => invoiceId,
	};

	return (
		<div className="my-8">
			<div className="text-subtitle2">Invoices</div>
			<div className="mt-4">
				<SortableTable
					key="lot-invoice-history-content"
					tableParams={sortableTableParams}
					withGlobalFilter={false}
					withPagination={false}
				/>
			</div>
		</div>
	);
};

export default LotInvoiceHistoryContent;

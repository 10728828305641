import { RootPathForStorageBucket } from './storageService.model';

export const getStoredItemPath = ({
	rootPath,
	parentPath = '',
	itemId = '',
}: {
	rootPath: RootPathForStorageBucket;
	parentPath?: string;
	itemId?: string;
}) => {
	return parentPath ? `${rootPath}/${parentPath}/${itemId}` : `${rootPath}/${itemId}`;
};

import { getAuthed } from '../../AdminUtils/httpUtils';
import { Banner, LayoutConfig } from './layoutService.model';
import { mapKernelBannerToDomainBanner } from './layoutService.utils';

export const getLayout = async () => {
	const response = await getAuthed(`${process.env.API_LAYOUTS}/api/home/banners`);

	if (response.status >= 400) {
		throw response;
	}

	return {
		banners: response.data.map(mapKernelBannerToDomainBanner) as Banner[],
	} as LayoutConfig;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { DraftEmployee } from './userService.model';

export const createEmployee = async (newEmployee: DraftEmployee) => {
	const response = await axiosAuth.post(`${apiEndpoints.manage_employees}`, newEmployee);

	if (response.status > 400) {
		throw response;
	}

	return response.data;
};

import { FeatureFlag } from './useDecision.model';

const FALSE_FLAGS = [
	'wip-new-lot-selector',
	'wip-fixed-price-checkout-nav-items',
	'wip-optimizely-test',
];

/** @deprecated Delete the feature flag */
const useDecision = (flagName: FeatureFlag, fallbackDecision = false): boolean => {
	return !FALSE_FLAGS.includes(flagName);
};
export default useDecision;

import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import { Tag } from '../../../../../prizm-ui/Tag';
import { Auction } from '../../../../../services/auctionsService';
import useAuctionById from '../../../../../queries/useAuctionById';
import { getSentimentForAuctionStatus } from './AuctionDashboardDetails.utils';
import Label from '../../../../../components/Label';
import { formatDate, stripUnderscores } from '../../../../../utils/formatters';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';

const AuctionDashboardDetails = () => {
	const { auctionId = '' } = useParams<{ auctionId?: string }>();

	const { data: auction = {} as Auction } = useAuctionById(auctionId);

	return (
		<Card className="mb-4">
			<div className="flex flex-row items-center justify-between mb-4">
				<h2 className="text-display5">{`Details for ${auction.title}`}</h2>
				<Tag variant={getSentimentForAuctionStatus(auction.status)}>
					{stripUnderscores(auction.status)}
				</Tag>
			</div>
			<div className="mb-6">
				<p>{auction.description || 'Description not provided'}</p>
			</div>
			<div className="gap-4 grid grid-cols-1 lg:grid-cols-3 inline-grid mb-8">
				<div className="flex flex-column justify-between mr-6">
					<Label>Preview Date</Label>
					<p>{formatDate(auction.previewTimestamp as string)}</p>
				</div>
				<div className="flex flex-column justify-between mr-6">
					<Label>Start Date</Label>
					<p>{formatDate(auction.startTimestamp as string)}</p>
				</div>
				<div className="flex flex-column justify-between">
					<Label>End Date</Label>
					<p>{formatDate(auction.endTimestamp as string)}</p>
				</div>
			</div>
			<h2 className="mb-4 text-subtitle1">Financial Details</h2>
			<div className="gap-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 inline-grid mb-8">
				<div className="mr-6">
					<Label>Total Buyer Premium</Label>
					<p>{formatCurrency(auction.totalBuyerPremium)}</p>
				</div>
				<div className="mr-6">
					<Label>Total Commission</Label>
					<p>{formatCurrency(auction.totalCommission)}</p>
				</div>
				<div className="mr-6">
					<Label>Total Bid Value</Label>
					<p>{formatCurrency(auction.totalBidValue)}</p>
				</div>
				<div className="mr-6">
					<Label>Average Bid Value</Label>
					<p>{formatCurrency(auction.averageBidValue)}</p>
				</div>
			</div>
			<h2 className="mb-4 text-subtitle1">Statistical Details</h2>
			<div className="gap-4 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 inline-grid">
				<div className="mr-6">
					<Label>Total Lots</Label>
					<p>{auction.totalLots ?? '-'}</p>
				</div>
				<div className="mr-6">
					<Label>Unsold Lots</Label>
					<p>{auction.unsoldLots ?? '-'}</p>
				</div>
				<div className="mr-6">
					<Label>Total Bids</Label>
					<p>{auction.totalBids ?? '-'}</p>
				</div>
				<div className="mr-6">
					<Label>Bids Per Lot</Label>
					<p>{auction.bidsPerLot?.toFixed(2) ?? '-'}</p>
				</div>
				<div className="mr-6">
					<Label>Unique Bidders</Label>
					<p>{auction.uniqueBidders ?? '-'}</p>
				</div>
				<div className="mr-6">
					<Label>Unique Bidders Per Lot</Label>
					<p>{auction.uniqueBiddersPerLot?.toFixed(2) ?? '-'}</p>
				</div>
			</div>
		</Card>
	);
};

export default AuctionDashboardDetails;

import TextField from '@material-ui/core/TextField';
import { pick } from 'lodash';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ActionModal from '../../../../components/ActionModal';
import Label from '../../../../components/Label';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import {
	Auction,
	AuctionTimestampKey,
	AuctionTimestamps,
} from '../../../../services/auctionsService';
import { updateAuctionSchedule } from '../../../../services/auctionsService/updateAuctionSchedule';
import { formatDate } from '../../../../utils/formatters';

const EditAuctionDatesModal = ({ auction, onClose }: { auction: Auction; onClose: () => void }) => {
	const [timestamps, setTimestamps] = useState<AuctionTimestamps>({
		previewTimestamp: auction.previewTimestamp,
		startTimestamp: auction.startTimestamp,
		endTimestamp: auction.endTimestamp,
	});
	const updateTimestamps = (updatedTimestamp: AuctionTimestamps) =>
		setTimestamps({ ...timestamps, ...updatedTimestamp });

	const changedKeys = Object.keys(timestamps).filter(
		(key) => timestamps[key as AuctionTimestampKey] !== auction[key as AuctionTimestampKey]
	);
	const deltas: AuctionTimestamps = pick(timestamps, changedKeys);
	const canConfirm = changedKeys.length > 0;

	const { mutate: doUpdateAuctionSchedule, status: updateAuctionStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateAuction, auction.id, ...Object.keys(deltas)],
		mutationFn: () => updateAuctionSchedule({ auctionId: auction.id, deltas }),
	});
	const queryClient = useQueryClient();
	const handleUpdateTimestamps = () =>
		doUpdateAuctionSchedule(undefined, {
			onSuccess: () => {
				queryClient.invalidateQueries(QueryKeys.GetAuctions),
					showSuccessToast('Successfully updated auction schedule.');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating auction schedule. Please ping the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleUpdateTimestamps}
			title={`Edit Auction Dates for ${auction.title}`}
			canConfirm={canConfirm}
			isLoading={updateAuctionStatus === 'loading'}
		>
			<div>
				<Label htmlFor="start-date">Preview Date</Label>
				<TextField
					name="preview-date"
					type="datetime-local"
					defaultValue={formatDate(timestamps.previewTimestamp, {
						invalidDateMessage: '',
						utc: true,
					})}
					onChange={({ target: { value: previewTimestamp } }) => {
						if (previewTimestamp) {
							updateTimestamps({ previewTimestamp });
						}
					}}
				/>
			</div>
			<div className="mt-4">
				<Label htmlFor="start-date">Start Date</Label>
				<TextField
					name="start-date"
					type="datetime-local"
					defaultValue={formatDate(timestamps.startTimestamp, {
						invalidDateMessage: '',
						utc: true,
					})}
					onChange={({ target: { value: startTimestamp } }) => {
						if (startTimestamp) {
							updateTimestamps({ startTimestamp });
						}
					}}
				/>
			</div>
			<div className="mt-4">
				<Label htmlFor="end-date">End Date</Label>
				<TextField
					name="end-date"
					type="datetime-local"
					defaultValue={formatDate(timestamps.endTimestamp, { invalidDateMessage: '', utc: true })}
					onChange={({ target: { value: endTimestamp } }) => {
						if (endTimestamp) {
							updateTimestamps({ endTimestamp });
						}
					}}
				/>
			</div>
		</ActionModal>
	);
};

export default EditAuctionDatesModal;

import LoadingWrapper from '../../../../components/LoadingWrapper';
import WatchlistTabView from './WatchlistTabView';
import useAuctions from '../../../../queries/useAuctions';

const WatchlistTab = () => {
	const { status: auctionStatus } = useAuctions();

	return (
		<LoadingWrapper queryStatuses={[auctionStatus]}>
			<WatchlistTabView />
		</LoadingWrapper>
	);
};

export default WatchlistTab;

import React, { useContext } from 'react';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';
import { Loading, LoadingSize } from '../Loading';
import { ButtonGroupContext } from './ButtonGroup';
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from '../types';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonVariant = 'primary' | 'secondary' | 'text' | 'brand';

type Props = {
	destructive?: boolean;
	fullWidth?: boolean;
	icon?: IconName | JSX.Element;
	leadingIcon?: IconName | JSX.Element;
	loading?: boolean;
	size?: ButtonSize;
	trailingIcon?: IconName | JSX.Element;
	variant?: ButtonVariant;
};

export type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type ButtonComponent = <C extends React.ElementType = 'button'>(
	props: ButtonProps<C>
) => React.ReactElement | null;

const getClasses = ({
	as = 'button',
	className,
	destructive,
	disabled,
	fullWidth,
	isGroup,
	isIcon,
	isTextOnly,
	size,
	variant,
}: {
	as?: React.ElementType<any>;
	className?: string;
	destructive: boolean;
	disabled: boolean;
	fullWidth: boolean;
	isGroup: boolean;
	isIcon: boolean;
	isTextOnly: boolean;
	size: ButtonSize;
	variant: ButtonVariant;
}) => {
	const classes = [
		'flex',
		'items-center',
		'justify-center',
		'whitespace-nowrap',
		'transition-all',
		'focus:outline-none',
		'focus-visible:ring-2',
		'focus-visible:ring-offset-2',
		...(as !== 'button' ? ['inline-flex', 'hover:no-underline', 'focus:no-underline'] : []), // anchors do not render flex like buttons do, they will fill width instead of staying thin
		...(fullWidth ? ['w-full'] : []),
		destructive ? 'focus-visible:ring-error-500' : 'focus-visible:ring-primary-500',
		'disabled:border',
		'disabled:border-transparent',
		'disabled:pointer-events-none',
		...(className ? [className] : []),
	];

	switch (size) {
		case 'sm':
			classes.push('h-6', 'rounded-md');
			if (isIcon) {
				classes.push('w-6');
			} else {
				classes.push('min-w-[48px]', 'px-2', 'gap-1', 'text-caption2');
			}
			break;
		case 'md':
			if (isGroup) {
				classes.push('h-8', 'w-11', 'm-px', 'text-neutral3');
			} else if (isIcon) {
				classes.push('h-10', 'w-10');
			} else {
				classes.push('min-w-[80px]', 'h-10', 'px-4');
			}
			classes.push('gap-2', 'rounded-full', 'text-body2');
			break;
		case 'lg':
		default:
			classes.push('h-14', 'rounded-lg');
			if (isIcon) {
				classes.push('w-14');
			} else if (isTextOnly) {
				classes.push('min-w-[112px]', 'px-5');
			} else {
				classes.push('px-4');
			}
			classes.push('gap-2', 'text-body1');
	}

	const textColor = destructive ? 'text-error-500' : isGroup ? 'text-neutral2' : 'text-primary-500';

	switch (variant) {
		case 'text':
			const hoverColor = destructive
				? 'hover:bg-error-25'
				: isGroup
				? 'hover:bg-neutralbkg2'
				: 'hover:bg-primary-25';
			classes.push(
				textColor,
				'border',
				'border-transparent',
				hoverColor,
				...(disabled ? ['disabled:text-disabled'] : [])
			);
			break;
		case 'secondary':
			classes.push(
				'border',
				...(destructive ? ['text-error-500'] : ['text-neutral2']),
				...(destructive ? ['border-error-200'] : ['border-neutralstroke2']),
				...(destructive ? ['hover:bg-error-25'] : ['hover:bg-neutralbkg2']),
				...(disabled ? ['disabled:bg-neutralbkg2'] : []),
				...(disabled ? ['disabled:text-disabled'] : [])
			);
			break;
		case 'brand':
			classes.push(
				'border',
				'border-transparent',
				'text-inverted',
				'bg-brand-500',
				'hover:bg-brand-200',
				'disabled:opacity-25'
			);
			break;
		case 'primary':
		default:
			classes.push(destructive ? 'bg-error-500' : 'bg-primary-500');
			classes.push(
				'border',
				'border-transparent',
				'text-inverted',
				...(destructive ? ['hover:bg-error-700'] : ['hover:bg-primary-700']),
				...(disabled ? (destructive ? ['disabled:bg-error-200'] : ['disabled:bg-primary-200']) : [])
			);
	}
	return classes.join(' ');
};

export const Button: ButtonComponent & { displayName?: string } = React.forwardRef(
	<C extends React.ElementType = 'button'>(
		{
			'aria-label': ariaLabel,
			as,
			children,
			className,
			destructive = false,
			disabled,
			fullWidth = false,
			icon,
			leadingIcon,
			loading = false,
			size = 'lg',
			trailingIcon,
			variant = 'primary',
			...props
		}: ButtonProps<C>,
		ref?: PolymorphicRef<C>
	) => {
		const { isGroup } = useContext(ButtonGroupContext);
		const Component = as || 'button';
		const iconSize = size === 'sm' ? 3 : size;
		const loadingSize = (
			{
				sm: 'xs',
				md: 'sm',
				lg: 'md',
			} as { [_ in ButtonSize]: LoadingSize }
		)[size];

		return (
			<Component
				{...props}
				ref={ref}
				className={getClasses({
					as,
					destructive,
					disabled,
					fullWidth,
					isGroup,
					isIcon: !!icon,
					isTextOnly: !leadingIcon && !trailingIcon,
					size,
					variant,
					className,
				})}
				disabled={disabled || loading}
			>
				{icon ? (
					loading ? (
						<Loading size={loadingSize} />
					) : typeof icon === 'string' && iconList.includes(icon) ? (
						<Icon name={icon} size={iconSize} aria-hidden={false} aria-label={ariaLabel} />
					) : (
						React.isValidElement(icon) && icon
					)
				) : (
					<>
						{loading ? (
							<Loading size={loadingSize} />
						) : typeof leadingIcon === 'string' && iconList.includes(leadingIcon) ? (
							<div className="flex items-center" data-testid="button-leading-icon">
								<Icon name={leadingIcon} size={iconSize} aria-hidden />
							</div>
						) : (
							React.isValidElement(leadingIcon) && leadingIcon
						)}

						{children}

						{typeof trailingIcon === 'string' && iconList.includes(trailingIcon) ? (
							<div className="flex items-center" data-testid="button-trailing-icon">
								<Icon name={trailingIcon as IconName} size={iconSize} aria-hidden />
							</div>
						) : (
							React.isValidElement(trailingIcon) && trailingIcon
						)}
					</>
				)}
			</Component>
		);
	}
);
Button.displayName = 'Button';

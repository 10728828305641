import { Storage } from 'aws-amplify';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { uncompressedBucketConfig } from './storageService.model';

export const deleteImage = async (imageURI: string) => {
	await Storage.remove(imageURI, uncompressedBucketConfig);
	const response = await axiosAuth.delete(apiEndpoints.images, {
		data: {
			image: imageURI,
		},
	});

	if (response.status >= 400) {
		throw response;
	}

	return imageURI;
};

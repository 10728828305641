import React from 'react';
import ReactModal from 'react-modal';
import './Modal.css';
/*
  This exported hook should be called by the consumer for accessibility.
  If the modal is located inside an element with an ID, this hook would need to be called and placed somewhere outside the function component, before the modal is opened.
  ie: Modal.setAppElement('#main');
*/
export { setAppElement } from 'react-modal';

export interface ModalProps {
	children?: React.ReactNode;
	isOpen: boolean;
}

export const Modal = ({ isOpen, children }: ModalProps) => (
	<ReactModal
		aria={{
			labelledby: 'modal-header',
		}}
		/*
      For testing purposes, modal accessibility requires it be attached to an element
      This is needed so screen readers don't see main content when modal is opened.
    */
		ariaHideApp={process.env.NODE_ENV !== 'test'}
		overlayClassName="fixed z-50 inset-0 flex p-1 xs:p-4 bg-black/25"
		closeTimeoutMS={200}
		className="relative m-auto overflow-hidden w-[640px] max-w-full h-[640px] max-h-full bg-neutralbkg1 shadow-elevation3 rounded-2xl"
		isOpen={isOpen}
		shouldCloseOnEsc={false}
		shouldCloseOnOverlayClick={false}
	>
		<div className="absolute inset-0 flex flex-col">{children}</div>
	</ReactModal>
);

import { useParams } from 'react-router-dom';
import BidHistoryCardTable from './BidHistoryCardTable';
import Label from '../Label';
import LoadingWrapper from '../LoadingWrapper';
import {
	LotBidHistory,
	LotBidHistoryEntry,
	LotBidStatistics,
} from '../../services/lotsService/getLotBidHistory.model';
import useLotBidHistory from '../../queries/useLotBidHistory';

const BidHistoryCardView = ({ auctionId }: { auctionId: string }) => {
	const { lotId = '' } = useParams<{ lotId: string }>();

	const { data: lotBidHistory = {} as LotBidHistory, status: lotBidHistoryStatus } =
		useLotBidHistory({
			auctionId,
			lotId,
		});

	const { entries = [] as LotBidHistoryEntry[], statistics = {} as LotBidStatistics } =
		lotBidHistory;

	return (
		<LoadingWrapper queryStatuses={[lotBidHistoryStatus]}>
			<div className="flex flex-row justify-between mb-4 w-full">
				<Label>Bids</Label>
				<div className="flex flex-row">
					<div className="flex flex-row">
						<Label>Unique Bidders:</Label>
						<p className="ml-2">{statistics.uniqueBidders}</p>
					</div>
					<div className="flex flex-row mx-4">
						<Label>Total Bids:</Label>
						<p className="ml-2">{statistics.totalBids}</p>
					</div>
					<div className="flex flex-row">
						<Label>Time Remaining:</Label>
						<p className="ml-2">{statistics.timeRemaining}</p>
					</div>
				</div>
			</div>
			<BidHistoryCardTable auctionId={auctionId} entries={entries} />
		</LoadingWrapper>
	);
};

export default BidHistoryCardView;

import { useQuery } from 'react-query';
import { getInvoicesByUser } from '../../services/invoicesService';
import { GetInvoicesFilter } from '../../services/invoicesService/invoicesService.model';
import { QueryKeys } from '../queryKeys';

const useInvoicesByUser = ({
	filters,
	userId,
}: {
	filters?: GetInvoicesFilter | undefined;
	userId: string;
}) => useQuery([QueryKeys.GetInvoicesByUser, userId], () => getInvoicesByUser(userId, filters));

export default useInvoicesByUser;

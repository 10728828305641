import { UserRoleName } from '../../../../../../services/userService/userService.model';

export interface ManageCustomerTab {
	label: string;
	path: string;
}

export const BASE_MANAGE_CUSTOMER_TABS: ManageCustomerTab[] = [
	{ label: 'Invoices', path: 'invoices' },
	{ label: 'Seller Statements', path: 'seller-statements' },
	{ label: 'Balance', path: 'balance' },
	{ label: 'Shipment Manager', path: 'shipment-manager' },
	{ label: 'Activity', path: 'activity' },
	{ label: 'Bids', path: 'bids' },
	{ label: 'Watch List', path: 'watch-list' },
	{ label: 'Consigned Lots', path: 'consigned-lots' },
	{ label: 'Notes', path: 'notes' },
	{ label: 'Merge Accounts', path: 'merge-accounts' },
];

export const CUSTOMER_OVERVIEW_TAB: ManageCustomerTab = {
	label: 'Customer Overview',
	path: 'customer-overview',
};

export const CUSTOMER_OVERVIEW_ROLES: UserRoleName[] = ['Finance', 'Admin', 'CustomerSupport'];

export type CSVColumnNames = string[];

export const ACTIVITY_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'endTimestamp',
	'title',
	'numberOfBids',
	'numberWonLots',
	'totalValueWonLots',
];

export const BALANCE_HISTORY_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'timestamp',
	'description',
	'operationType',
	'amountChanged',
	'currentBalance',
	'createdBy',
	'internalNotes',
];

export const BIDS_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'auction_id',
	'start_timestamp',
	'end_timestamp',
	'lot_number',
	'title',
	'seller_email',
	'final_price',
	'current_price',
	'min_bid_price',
	'reserve_amount',
	'consignment_manager_email',
	'commission',
	'winner_id',
	'number_of_bids',
	'status',
];

export const CONSIGNED_LOTS_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'serial_number',
	'auction_id',
	'lot_number',
	'title',
	'current_price',
	'buyer_premium',
	'commission',
	'status',
];

export const CUSTOMER_MANAGER_SEARCH_CSV_COLUMN_NAMES: CSVColumnNames = [
	'id',
	'registrationDate',
	'isDisabled',
	'givenName',
	'familyName',
	'email',
	'phoneNumber',
	'stripeCustomerId',
	'shippingAddress',
	'biddingPower',
	'creditLimit',
	'consignmentManagerId',
	'commission',
	'hasUSAddress',
	'verifiedTin',
];

export const INVOICES_TAB_CSV_COLUMN_NAMES = [
	'invoice_number',
	'description',
	'customer_name',
	'customer_email',
	'amount_due',
	'amount_paid',
	'buyer_premium',
	'invoice_status',
	'date_shipped',
	'comments',
	'collections_status',
];

export const NOTES_TAB_CSV_COLUMN_NAMES: CSVColumnNames = ['timestamp', 'text', 'author'];

export const SELLER_STATEMENTS_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'auctionId',
	'sellerStatementNumber',
	'sellerName',
	'sellerEmail',
	'amountOwed',
	'amountReceived',
	'commission',
	'adjustments',
	'sellerStatementStatus',
];

export const SHIPMENT_MANAGER_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'invoice_number',
	'description',
	'customer_name',
	'customer_email',
	'amount_due',
	'auction_date',
	'payment_date',
	'payment_type',
	'tracking_id',
	'shipping_status',
	'date_shipped',
	'comments',
];

export const WATCHLIST_TAB_CSV_COLUMN_NAMES: CSVColumnNames = [
	'auction_id',
	'start_timestamp',
	'lot_number',
	'lot_id',
	'title',
	'current_price',
	'final_price',
	'min_bid_price',
	'buyer_premium',
	'commission',
	'number_of_bids',
	'number_of_views',
	'winner_id',
];

export const BULK_UPLOAD_CUSTOMERS_EXAMPLE = [
	{
		given_name: 'Charles',
		family_name: 'Xavier',
		phone_number: '8009636669',
		email: 'professorx@xmen.org',
	},
	{
		given_name: 'James',
		family_name: 'Howlett',
		phone_number: '8009636669',
		email: 'wolverine@xmen.org',
	},
	{
		given_name: 'Ororo',
		family_name: 'Munroe',
		phone_number: '8009636669',
		email: 'storm@xmen.org',
	},
	{
		given_name: 'Piotr',
		family_name: 'Rasputin',
		phone_number: '8009636669',
		email: 'colossus@xmen.org',
	},
	{
		given_name: 'Scott',
		family_name: 'Summers',
		phone_number: '8009636669',
		email: 'cyclops@xmen.org',
	},
	{
		given_name: 'Elizabeth',
		family_name: 'Braddock',
		phone_number: '8009636669',
		email: 'psylocke@xmen.org',
	},
	{
		given_name: 'Remy',
		family_name: 'LeBeau',
		phone_number: '8009636669',
		email: 'gambit@xmen.org',
	},
	{
		given_name: 'Anna Marie',
		family_name: 'LeBeau',
		phone_number: '8009636669',
		email: 'rogue@xmen.org',
	},
	{
		given_name: 'Robert',
		family_name: 'Drake',
		phone_number: '8009636669',
		email: 'iceman@xmen.org',
	},
];

import { Chip } from '../../../prizm-ui/Chip';
import { Tag } from '../../../prizm-ui/Tag';
import { Sentiment } from '../../../prizm-ui/types';
import { AuthenticationStatus } from '../../../services/lotsService/lotsService.model';
import { titleCase } from '../../../utils/formatters';

const getSentimentForLotAuthenticationStatus = (status: AuthenticationStatus): Sentiment => {
	switch (status) {
		case 'NeedsAuthentication':
			return 'error';
		case 'NeedsReholder':
			return 'error';
		case 'CurrentlyBeingAuthenticated':
			return 'primary';
		case 'Completed':
			return 'success';
		default:
			return 'gray';
	}
};

const LotAuthenticationStatusChip = ({
	status,
	as = 'Chip',
}: {
	status: AuthenticationStatus;
	as?: 'Tag' | 'Chip';
}) => {
	const Component = as === 'Chip' ? Chip : Tag;

	return (
		<Component variant={getSentimentForLotAuthenticationStatus(status)}>
			{titleCase(status)}
		</Component>
	);
};

export default LotAuthenticationStatusChip;

import { useQuery } from 'react-query';
import { FixedPriceSellerStatementSearchParams } from '../../Pages/fixed-price/seller-summary/FixedPriceSellerStatementSearchForm/FixedPriceSellerStatementSearchForm.model';
import { getFixedPriceSellerStatements } from '../../services/sellerStatementService';
import { QueryKeys } from '../queryKeys';

const useFixedPriceSellerStatements = (params: FixedPriceSellerStatementSearchParams) => {
	const isValidForSearchByQuery = params.searchType === 'search-query' && !!params.searchQuery;
	const isValidForSearchByDateRange =
		params.searchType === 'date-range' && (params.fromDate || params.toDate);

	return useQuery(
		[QueryKeys.GetFixedPriceSellerStatements, ...Object.values(params)],
		() => getFixedPriceSellerStatements(params),
		{
			enabled: !!(isValidForSearchByQuery || isValidForSearchByDateRange),
		}
	);
};

export default useFixedPriceSellerStatements;

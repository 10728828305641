import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSidebarContext } from '../../useShouldShowSidebar';

const NavItem = ({
	getIsOpen,
	label,
	path,
}: {
	getIsOpen: (pathname: string) => boolean;
	label: string;
	path: string;
}) => {
	const { pathname } = useLocation();
	const isCurrentPath = getIsOpen(pathname);

	const { closeSidebarIfNarrowViewport } = useSidebarContext();

	return (
		<Link
			to={path}
			key={label}
			className={`duration-400 ml-4 block w-full py-2 px-8 pt-2 text-left transition ease-in-out hover:bg-neutral2 hover:text-neutral1 hover:no-underline ${
				isCurrentPath ? 'bg-neutral2' : 'bg-neutral1'
			}`}
			onClick={closeSidebarIfNarrowViewport}
		>
			<span className={`${isCurrentPath ? 'text-body1 font-semibold' : 'text-body1'}`}>
				{label}
			</span>
		</Link>
	);
};

export default NavItem;

import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../../../../../../components/Button';
import Dropdown from '../../../../../../components/Dropdown';
import Input from '../../../../../../components/Input';
import { Delete20Regular } from '@fluentui/react-icons';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import { ShippingDetail, SHIPPING_VENDOR_OPTIONS } from './EditAddShippingDetailsForm.model';
import {
	CheckoutOrder,
	CheckoutOrderComment,
} from '../../../../../../services/ordersService/ordersService.model';
import { CurrentUser } from '../../../../../../services/userService/userService.model';
import { updateOrder } from '../../../../../../services/ordersService';
import useCurrentUser from '../../../../../../queries/useCurrentUser';
import { updateInvoiceComments } from '../../../../../../services/invoicesService';

const EditAddShippingDetailsForm = ({
	order,
	updateOrderOnSuccess,
}: {
	order: CheckoutOrder;
	updateOrderOnSuccess: (trackingId: Partial<CheckoutOrder>) => void;
}) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const defaultShippingDetail = { tracking_id: '', Vendor: '' };
	const getDefaultShippingDetails = (): ShippingDetail[] => {
		if (!order.trackingId) {
			return [defaultShippingDetail];
		}

		const orderShippingDetails = JSON.parse(order.trackingId);

		if (orderShippingDetails.length > 0) {
			return orderShippingDetails;
		} else {
			return [defaultShippingDetail];
		}
	};
	const [shippingDetails, setShippingDetails] = useState<ShippingDetail[]>(
		getDefaultShippingDetails()
	);

	const { mutate: doUpdateShippingDetails, status: updateShippingDetailsStatus } = useMutation({
		mutationFn: () =>
			updateOrder({
				orderNumber: order.invoiceNumber,
				fieldsToUpdate: {
					trackingId:
						shippingDetails.length > 0 ? JSON.stringify(shippingDetails) : JSON.stringify([]),
				},
			}),
		mutationKey: [QueryKeys.UpdateOrderShippingDetails, order.invoiceNumber],
	});

	const addAdditionalShippingDetail = () =>
		setShippingDetails([...shippingDetails, defaultShippingDetail]);

	const deleteShippingDetail = (index: number) => {
		const newShippingDetails = [...shippingDetails];

		newShippingDetails.splice(index, 1);

		setShippingDetails(newShippingDetails);
	};

	const defaultVendorOption = { value: '', label: 'Carrier ...' };
	const getVendorValue = (index: number) => {
		const selectedShipment = [...shippingDetails][index];

		if (selectedShipment) {
			return SHIPPING_VENDOR_OPTIONS.find(({ value }) => value === selectedShipment.Vendor);
		}

		return defaultVendorOption;
	};

	const handleConfirm = () => {
		if (!shippingDetailsAreValid()) {
			showErrorToast('Something is wrong with one or more tracking IDs. Please try again.');

			return;
		}

		doUpdateShippingDetails(undefined, {
			onError: () => showErrorToast('Something went wrong. Please try again later.'),
			onSuccess: async () => {
				updateOrderOnSuccess({
					trackingId:
						shippingDetails.length > 0 ? JSON.stringify(shippingDetails) : JSON.stringify([]),
				});

				showSuccessToast('Shipping details saved successfully.');

				const shippingDetailsComment: CheckoutOrderComment = {
					author: {
						authorDisplayName: currentUser.currentUserFullName || 'unknown',
						authorId: currentUser.currentUserId,
					},
					kind: 'order-shipping-details',
					text: 'Shipping details updated.',
					timestamp: new Date().toISOString(),
				};

				const updatedComments = Array.isArray(order.comments)
					? [...order.comments, shippingDetailsComment]
					: [shippingDetailsComment];

				updateInvoiceComments({
					auction_id: order.auctionId,
					comments: updatedComments,
					invoice_id: order.invoiceId,
				}).then(({ statusCode }) => {
					if (statusCode > 200) {
						showErrorToast('Something went wrong. Please try again later.');
					}
				});
			},
		});
	};

	const shippingDetailsAreValid = () => {
		const validityCount = shippingDetails.map((shippingDetail) =>
			trackingIdIsValid(shippingDetail)
		);

		return validityCount.includes(false) ? false : true;
	};

	const trackingIdIsValid = (shippingDetail: ShippingDetail) => {
		switch (shippingDetail.Vendor.toLowerCase()) {
			case 'fedex':
				return shippingDetail.tracking_id.length >= 12;
			case 'ups':
				return shippingDetail.tracking_id.length === 18;
			case 'usps':
				return shippingDetail.tracking_id.length >= 18;
			default:
				return false;
		}
	};

	const updateShipment = ({ index, key, value }: { index: number; key: string; value: string }) => {
		const newShippingDetails = [...shippingDetails];

		newShippingDetails[index][key] = value;

		setShippingDetails(newShippingDetails);
	};

	return (
		<div>
			{shippingDetails.map((shipment: ShippingDetail, index: number) => (
				<div className="mb-2 flex flex-row justify-between" key={`shipping-detail-row-${index}`}>
					<div className="flex w-full items-center">
						<div className="mr-2 w-32">
							<Dropdown
								key={`shipping-detail-row-dropdown-${index}`}
								name={`vendor-dropdown-${index}`}
								options={[defaultVendorOption, ...SHIPPING_VENDOR_OPTIONS]}
								value={getVendorValue(index)}
								onChange={({ value }) =>
									updateShipment({ index: index, key: 'Vendor', value: value as string })
								}
							/>
						</div>
						<div className="mr-2 flex-grow">
							<Input
								className="w-full"
								name={`tracking-id-input-${index}`}
								value={shipment.tracking_id}
								onChange={({ target: { value } }) =>
									updateShipment({ index: index, key: 'tracking_id', value: value as string })
								}
								placeholder="Tracking Number"
							/>
						</div>
						<Button
							className="mr-2"
							isInline
							kind="tertiary"
							onClick={() => deleteShippingDetail(index)}
							title="Delete Tracking Number"
						>
							<Delete20Regular />
						</Button>
					</div>
				</div>
			))}
			<div className="flex flex-row justify-between mt-2">
				<div className="flex flex-row">
					<Button
						className="mr-4 link pl-0 pr-0"
						isLoading={updateShippingDetailsStatus === 'loading'}
						kind="tertiary"
						onClick={handleConfirm}
					>
						Save
					</Button>
					<Button
						className="link pl-0 pr-0"
						disabled={isEqual(shippingDetails, getDefaultShippingDetails())}
						kind="tertiary"
						onClick={() => setShippingDetails(getDefaultShippingDetails())}
					>
						Cancel
					</Button>
				</div>
				<Button
					className="link mr-9 pl-0 pr-0"
					kind="tertiary"
					onClick={addAdditionalShippingDetail}
				>
					Add Additional
				</Button>
			</div>
		</div>
	);
};

export default EditAddShippingDetailsForm;

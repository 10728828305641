import { axiosAuth } from '../../AdminUtils/httpUtils';
import { FixedPriceOffer } from './offersService.model';
import config from '../../config';

export const getOffersForItem = async (itemId: string): Promise<FixedPriceOffer[]> => {
	const response = await axiosAuth.get<{ offers: FixedPriceOffer[] }>(
		`${config.api.offers}/lots/${itemId}/offers`
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.offers;
};

import { Auth } from 'aws-amplify';

/** @deprecated - use union types based on roles from services/userService.model UserRoleName */
export const UserRole: { [key: string]: string } = {
	Admin: 'Admin',
	Shipping: 'Shipping',
	AuctionManager: 'AuctionManager',
	Finance: 'Finance',
	Inventory: 'Inventory',
	CustomerSupport: 'CustomerSupport',
	Photography: 'Photography',
	Editorial: 'Editorial',
	Consignment: 'Consignment',
};

/** @deprecated - use getUserRole from services/userService */
export const getUserRole = async (): Promise<string | null> => {
	try {
		const currentSession = await Auth.currentSession();
		const userRolePayload = currentSession?.getIdToken()?.payload || {};
		const userRoleKey = userRolePayload['custom:role'] || null;

		if (userRoleKey && userRoleKey in UserRole) {
			return UserRole[userRoleKey];
		}

		return null;
	} catch (e) {
		/* eslint-disable-next-line */
		console.log(e);
		return null;
	}
};

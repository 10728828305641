import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';
import { CheckoutOrderComment } from '../ordersService/ordersService.model';

export const updateInvoiceComments = async ({
	auction_id,
	invoice_id,
	comments,
}: {
	auction_id: string;
	invoice_id: string;
	comments: CheckoutOrderComment[] | string;
}) => {
	try {
		const body = {
			auction_id,
			company_id: config.organization.company_id,
			invoice_id,
			updates: {
				comments,
			},
		};

		const response = await postAuthed(`${config.api.update_bidder_invoice}`, body);

		if (response.status >= 400) {
			throw response;
		}

		return response.data;
	} catch (err) {
		console.log(`Unable to update invoice comments: ${err}`);

		throw err;
	}
};

export default updateInvoiceComments;

import { useState } from 'react';
import { Banner } from '../../../prizm-ui/Banner';
import { Button } from '../../../prizm-ui/Button';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import Card from '../../Card';
import EditLotInternalNotesModal from './EditLotInternalNotesModal';

const LotInternalNotes = ({ lot }: { lot: KernelLot }) => {
	const [shouldShowAddInternalNotesModal, setShouldShowAddInternalNotesModal] = useState(false);

	return (
		<>
			<Card>
				<div className="flex flex-row justify-between items-center">
					<h2 className="text-display5">Internal Notes</h2>
					<Button
						size="md"
						variant="text"
						icon="Edit"
						onClick={() => setShouldShowAddInternalNotesModal(true)}
					/>
				</div>
				{lot.internal_notes ? (
					<div className="mt-4">
						<p className="break-words text-body1">{lot.internal_notes}</p>
					</div>
				) : (
					<div className="mt-2">
						<Banner
							variant="gray"
							icon="Info"
							title="There are no comments for this item yet"
							buttonText="Add Comment"
							onClick={() => setShouldShowAddInternalNotesModal(true)}
						/>
					</div>
				)}
			</Card>
			{shouldShowAddInternalNotesModal && (
				<EditLotInternalNotesModal
					lot={lot}
					onClose={() => setShouldShowAddInternalNotesModal(false)}
				/>
			)}
		</>
	);
};

export default LotInternalNotes;

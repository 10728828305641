import { UserRole, getUserRole } from './userRoles';

/** @deprecated use SideBar.model.ts */
export const NAV_ITEMS = {
	AuctionDashboard: 'AuctionDashboard',
	AuctionManager: 'AuctionManager',
	AuctionPreview: 'AuctionPreview',
	AuctionPublisher: 'AuctionPublisher',
	CarouselEditor: 'CarouselEditor',
	FixedPriceListings: 'FixedPriceListings',
	InventoryManager: 'InventoryManager',
	Listings: 'Listings',
	LiveAuctionDashboard: 'LiveAuctionDashboard',
	ManageCustomers: 'ManageCustomers',
	ManageEmployees: 'ManageEmployees',
	ManageFixedPrice: 'ManageFixedPrice',
	PerformanceDashboard: 'PerformanceDashboard',
	Reports: 'Reports',
	SellerSummary: 'SellerSummary',
	ShipmentManager: 'ShipmentManager',
	UserSiteViewer: 'UserSiteViewer',
	WinnerSummary: 'WinnerSummary',
};

const RoleToPageMapping = {
	Shipping: [
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.ShipmentManager,
	],
	AuctionManager: [
		NAV_ITEMS.AuctionDashboard,
		NAV_ITEMS.AuctionManager,
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.AuctionPublisher,
		NAV_ITEMS.CarouselEditor,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.Listings,
		NAV_ITEMS.LiveAuctionDashboard,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.ManageCustomers,
		NAV_ITEMS.ManageFixedPrice,
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.ShipmentManager,
		NAV_ITEMS.UserSiteViewer,
		NAV_ITEMS.WinnerSummary,
	],
	Finance: [
		NAV_ITEMS.AuctionDashboard,
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.Listings,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.ManageCustomers,
		NAV_ITEMS.ManageFixedPrice,
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.Reports,
		NAV_ITEMS.SellerSummary,
		NAV_ITEMS.WinnerSummary,
	],
	Inventory: [
		NAV_ITEMS.AuctionDashboard,
		NAV_ITEMS.AuctionManager,
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.AuctionPublisher,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.Listings,
		NAV_ITEMS.ManageCustomers,
		NAV_ITEMS.ManageFixedPrice,
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.SellerSummary,
		NAV_ITEMS.WinnerSummary,
	],
	CustomerSupport: [
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.CarouselEditor,
		NAV_ITEMS.UserSiteViewer,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.AuctionDashboard,
		NAV_ITEMS.ManageCustomers,
		NAV_ITEMS.ManageEmployees,
		NAV_ITEMS.ManageFixedPrice,
		NAV_ITEMS.WinnerSummary,
		NAV_ITEMS.ShipmentManager,
		NAV_ITEMS.SellerSummary,
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.Listings,
	],
	Photography: [
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.Listings,
		NAV_ITEMS.PerformanceDashboard,
	],
	Editorial: [
		NAV_ITEMS.AuctionDashboard,
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.CarouselEditor,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.Listings,
		NAV_ITEMS.PerformanceDashboard,
	],
	Consignment: [
		NAV_ITEMS.AuctionPreview,
		NAV_ITEMS.InventoryManager,
		NAV_ITEMS.FixedPriceListings,
		NAV_ITEMS.Listings,
		NAV_ITEMS.ManageCustomers,
		NAV_ITEMS.ManageFixedPrice,
		NAV_ITEMS.PerformanceDashboard,
		NAV_ITEMS.SellerSummary,
	],
};

export function checkAccessToPage(navItem) {
	try {
		return getUserRole().then(
			(userRole) => {
				if (userRole === UserRole.Admin) {
					return true;
				}
				let allowedPages = RoleToPageMapping[userRole];
				if (allowedPages.includes(navItem)) {
					return true;
				}
				return false;
			},
			(error) => {
				console.log(error);
				return false;
			}
		);
	} catch (err) {
		console.log(`Unable to fetch assigned lots: ${err}`);
		throw err;
	}
}

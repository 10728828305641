import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../components/ActionModal';
import DefinitionList from '../../../../../../components/DefinitionList';
import { DefinitionListRow } from '../../../../../../components/DefinitionList/DefinitionList.model';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { Banner } from '../../../../../../prizm-ui/Banner';
import { Input } from '../../../../../../prizm-ui/Input';
import { Toggle } from '../../../../../../prizm-ui/Toggle';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import useRateCardsForUser from '../../../../../../queries/useRateCardsForUser';
import useUserById from '../../../../../../queries/useUserById';
import {
	DraftRateCardRates,
	RateCardRates,
} from '../../../../../../services/rateCardService/rateCardService.model';
import { upsertRateCardForUser } from '../../../../../../services/rateCardService/upsertRateCardForUser';
import { User } from '../../../../../../services/userService/userService.model';
import {
	draftRateCardRatesToRateCardRates,
	rateCardRatesToDraftRateCardRates,
	teeShirtRatesToKernelRates,
} from '../RateCard.utils';

const EditRateCardModal = ({
	onClose,
	variant,
	rates,
}: {
	onClose: () => void;
	rates?: RateCardRates;
	variant: 'auction' | 'fixed-price';
}) => {
	const { userId } = useParams<{ userId: string }>();
	const { data: user = {} as User } = useUserById(userId);

	const [usesCustomRates, toggleUsesCustomRates] = useState(!!rates);

	/** If we have rates and they're all the same, then use the first one we come across */
	const hasFlatRate =
		rates && [rates.xs, rates.sm, rates.md, rates.lg, rates.xl].every((rate) => rate === rates.xs);
	const baseFlatCommissionRate = hasFlatRate ? String(rates.xs) : undefined;

	const [usesFlatRates, toggleUsesFlatRates] = useState(!rates || hasFlatRate);
	const [flatCommissionRate, setFlatCommissionRate] = useState(baseFlatCommissionRate);

	const [draftCommissionRates, setDraftCommissionRates] = useState<DraftRateCardRates>(
		rateCardRatesToDraftRateCardRates({ rates }) ?? ({} as DraftRateCardRates)
	);
	const updateDraftCommissionRate = (delta: Partial<DraftRateCardRates>) =>
		setDraftCommissionRates({ ...draftCommissionRates, ...delta });
	const updateFlatCommissionRate = (updatedFlatCommissionRate?: string) => {
		setFlatCommissionRate(updatedFlatCommissionRate);
		updateDraftCommissionRate({
			xs: updatedFlatCommissionRate,
			sm: updatedFlatCommissionRate,
			md: updatedFlatCommissionRate,
			lg: updatedFlatCommissionRate,
			xl: updatedFlatCommissionRate,
		});
	};

	const toggleRows: DefinitionListRow[] = [
		{
			title: 'Use custom commission rates',
			content: (
				<Toggle
					checked={usesCustomRates}
					aria-label={
						usesCustomRates
							? 'Toggle off custom commission rates'
							: 'Toggle on custom commission rates'
					}
					onChange={() => {
						if (usesCustomRates) {
							setFlatCommissionRate(undefined);
							updateDraftCommissionRate({
								xs: undefined,
								sm: undefined,
								md: undefined,
								lg: undefined,
								xl: undefined,
							});
						} else {
							updateFlatCommissionRate(baseFlatCommissionRate);
							updateDraftCommissionRate({
								xs: baseFlatCommissionRate,
								sm: baseFlatCommissionRate,
								md: baseFlatCommissionRate,
								lg: baseFlatCommissionRate,
								xl: baseFlatCommissionRate,
							});
						}
						toggleUsesCustomRates(!usesCustomRates);
					}}
				/>
			),
		},
		{
			title: 'Use flat commission rates',
			content: (
				<Toggle
					checked={usesFlatRates}
					aria-label={usesFlatRates ? 'Use tiered commission rates' : 'Use flat commission rates'}
					disabled={!usesCustomRates}
					onChange={() => toggleUsesFlatRates(!usesFlatRates)}
				/>
			),
		},
	];

	const canConfirm = !isEqual(rates, draftCommissionRates);

	const { mutate: doUpdateCommission, status: updateCommissionStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateCommissionRates, user.id, variant, draftCommissionRates],
		mutationFn: () =>
			upsertRateCardForUser({
				userId: user.id,
				teeShirtRateCard: {
					variant,
					rates: draftRateCardRatesToRateCardRates(draftCommissionRates),
				},
			}),
	});

	const { updateRateCard: updateAuctionRateCardInCache } = useRateCardsForUser({
		userId,
		variant: 'auction',
	});
	const { updateRateCard: updateMarketplaceRateCardInCache } = useRateCardsForUser({
		userId,
		variant: 'fixed-price',
	});

	const handleConfirm = () =>
		doUpdateCommission(undefined, {
			onSuccess: () => {
				const updateRateCardInCache =
					variant === 'auction' ? updateAuctionRateCardInCache : updateMarketplaceRateCardInCache;
				updateRateCardInCache(
					teeShirtRatesToKernelRates(draftRateCardRatesToRateCardRates(draftCommissionRates))
				);
				showSuccessToast(
					variant === 'auction'
						? 'Successfully updated auction commission rates'
						: 'Successfully updated marketplace commission rates'
				);
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating commission rates. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});

	const isValidRate = (input: string): boolean => {
		return !isNaN(Number(input));
	};

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleConfirm}
			canConfirm={canConfirm}
			isLoading={updateCommissionStatus === 'loading'}
			title={
				variant === 'auction'
					? 'Edit Auction Commission Rates'
					: 'Edit Marketplace Commission Rates'
			}
		>
			<DefinitionList rows={toggleRows} />
			{usesCustomRates ? (
				<div className="mt-4">
					{usesFlatRates ? (
						<Input
							id="flat-rate"
							label="Flat Commission Rate"
							value={flatCommissionRate}
							invalid={!isValidRate(flatCommissionRate?.toString() ?? '')}
							onChange={({ target: { value } }) => updateFlatCommissionRate(value)}
						/>
					) : (
						<div className="grid grid-cols-1 gap-4">
							<Input
								id="xs"
								label="Up to $2,499"
								invalid={!isValidRate(draftCommissionRates.xs?.toString() ?? '')}
								value={draftCommissionRates.xs}
								onChange={({ target: { value } }) => updateDraftCommissionRate({ xs: value })}
							/>
							<Input
								id="sm"
								label="$2,500 - $4,999"
								invalid={!isValidRate(draftCommissionRates.sm?.toString() ?? '')}
								value={draftCommissionRates.sm}
								onChange={({ target: { value } }) => updateDraftCommissionRate({ sm: value })}
							/>
							<Input
								id="md"
								label="$5,000 - $9,999"
								invalid={!isValidRate(draftCommissionRates.md?.toString() ?? '')}
								value={draftCommissionRates.md}
								onChange={({ target: { value } }) => updateDraftCommissionRate({ md: value })}
							/>
							<Input
								id="lg"
								label="$10,000 - $249,999"
								invalid={!isValidRate(draftCommissionRates.lg?.toString() ?? '')}
								value={draftCommissionRates.lg}
								onChange={({ target: { value } }) => updateDraftCommissionRate({ lg: value })}
							/>
							<Input
								id="xl"
								label="$250,000 +"
								invalid={!isValidRate(draftCommissionRates.xl?.toString() ?? '')}
								value={draftCommissionRates.xl}
								onChange={({ target: { value } }) => updateDraftCommissionRate({ xl: value })}
							/>
						</div>
					)}
				</div>
			) : (
				<div className="mt-4">
					<Banner
						icon="Info"
						variant="gray"
						title={variant === 'auction' ? 'Default Auction Rates' : 'Default Marketplace Rates'}
						description={`Toggle "Use custom commission rates" above to change`}
					/>
				</div>
			)}
		</ActionModal>
	);
};

export default EditRateCardModal;

import { useQuery } from 'react-query';
import getAuctions from '../../services/auctionsService/getAuctions';
import { QueryKeys } from '../queryKeys';

const useAuctions = (params: { refetchInterval: number; onSuccess?: () => void } | void) => {
	if (!params) {
		return useQuery(QueryKeys.GetAuctions, () => getAuctions());
	}

	return useQuery(QueryKeys.GetAuctions, () => getAuctions(), params);
};
export default useAuctions;

import { OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { useDecision as useOptimizelyDecision } from '@optimizely/react-sdk';
import { useAdBlockerContext } from '../useAdBlockerContext';
import { FeatureFlag } from './useDecision.model';

/** The basic useDecision hook from optimizely gives a bit too much, without type safety for which
 *  feature flags a user can request. This will return only the actual decision, for flags we've
 *  determined already exist.
 */
const useDecision = (flagName: FeatureFlag, fallbackDecision = false) => {
	const { isAdBlocked } = useAdBlockerContext();

	const [optimizelyDecision] = useOptimizelyDecision(flagName);

	return isAdBlocked
		? ({ enabled: fallbackDecision } as OptimizelyDecision)
		: optimizelyDecision.enabled;
};
export default useDecision;

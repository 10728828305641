import { Link } from 'react-router-dom';
import { Tooltip } from '../../prizm-ui/Tooltip';
import { formatName } from '../../utils/formatters';

export const Username = ({
	id,
	type,
	fallback = 'Unknown',
	className = '',
	firstName,
	lastName,
}: {
	className?: string;
	id: string;
	type: 'employee' | 'bidder';
	fallback?: string;
	firstName?: string;
	lastName?: string;
}) => {
	if (!id) {
		return <>{fallback}</>;
	}

	const fullName = formatName(firstName, lastName);

	return type === 'employee' ? (
		<>{fullName}</>
	) : (
		<Tooltip id={`view-${id}`} content={`View ${fullName}'s profile`}>
			<Link className={`link ${className}`} to={`/usermanagement/customer/${id}/customer-overview`}>
				{fullName}
			</Link>
		</Tooltip>
	);
};

import { KernelLot } from '../../../../../services/lotsService/lotsService.model';

export const NEW_LOT_ID = '__newlyCreatedLot__';

export const BLANK_LOT: KernelLot = {
	auction_id: 'Unassigned',
	authentication_notes: '',
	authentication_status: '',
	buyer_premium: 0,
	category: '',
	commission: 0,
	company_id: '',
	consignment_manager_id: '',
	current_price: 0,
	description: '',
	has_description: false,
	has_end_timestamp_changed: false,
	images: [],
	location: '',
	lot_id: NEW_LOT_ID,
	lot_number: '',
	meta_description: '',
	meta_slug: '',
	meta_title: '',
	min_bid_price: 0,
	number_of_bids: 0,
	number_of_images: 0,
	primary_image: [],
	primary_image_name: '',
	reserve_amount: 0,
	room: '',
	seller_id: '',
	serial_number: '',
	status: 'Not_Started',
	sub_category: '',
	tag: [],
	title: '',
};

import { useQuery, useQueryClient } from 'react-query';
import { getRateCardsForUser } from '../../services/rateCardService';
import {
	KernelRateCard,
	KernelRateCardRate,
	RateCardVariant,
} from '../../services/rateCardService/rateCardService.model';
import { QueryKeys } from '../queryKeys';

const useRateCardsForUser = ({ userId, variant }: { userId: string; variant: RateCardVariant }) => {
	const queryKey = [QueryKeys.GetRateCardsForUser, userId, variant];
	const baseQuery = useQuery(queryKey, () => getRateCardsForUser({ userId, variant }));

	const queryClient = useQueryClient();
	const updateRateCard = (updatedRates: KernelRateCardRate[]) => {
		queryClient.setQueryData(
			queryKey,
			(originalRateCard: KernelRateCard = {} as KernelRateCard) => ({
				...originalRateCard,
				rates: updatedRates,
			})
		);
	};

	return { ...baseQuery, updateRateCard };
};

export default useRateCardsForUser;

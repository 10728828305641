import { axiosAuth } from '../../AdminUtils/httpUtils';
import { isEmail } from '../../utils/validators';
import config from '../../config';
import {
	KernelFixedPriceSellerStatement,
	KernelSellerStatementAdjustment,
} from './sellerStatementService.model';
import { getQueryString } from '../../utils/formatters';
import { BaseFixedPriceSellerStatementSearchParams } from '../../Pages/fixed-price/seller-summary/FixedPriceSellerStatementSearchForm/FixedPriceSellerStatementSearchForm.model';

export const getFixedPriceSellerStatements = async ({
	searchType,
	searchQuery,
	status,
	fromDate,
	toDate,
}: BaseFixedPriceSellerStatementSearchParams) => {
	const rawParamsForQuerySearch = isEmail(searchQuery)
		? { seller_email: searchQuery }
		: { seller_statement_number: searchQuery };

	const rawParamsForDateRangeSearch = {
		seller_statement_status: status,
		from_date: fromDate,
		to_date: toDate,
	};
	const rawParamsForSearchType =
		searchType === 'search-query' ? rawParamsForQuerySearch : rawParamsForDateRangeSearch;

	const endpointUrl =
		config.api.get_sellers_fixed_price_statements + '?' + getQueryString(rawParamsForSearchType);

	const response = await axiosAuth.get<KernelFixedPriceSellerStatement[]>(endpointUrl);

	if (response.status >= 400) {
		throw response;
	}

	const mappedSellerStatements: KernelFixedPriceSellerStatement[] = response.data.map(
		(sellerStatement) => ({
			...sellerStatement,
			netAdjustments: (sellerStatement.adjustments || []).reduce(
				(accumulator: number, { adjustment_type, amount }: KernelSellerStatementAdjustment) => {
					if (!amount) {
						return accumulator;
					}
					if (adjustment_type === 'Credit') {
						return accumulator - amount;
					}
					return accumulator + amount;
				},
				0
			),
			totalLotValue: sellerStatement.amountOwed + sellerStatement.commission,
			totalStatementAmount:
				sellerStatement.amountOwed - Math.abs(sellerStatement.netAdjustments || 0),
		})
	);

	return mappedSellerStatements;
};

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import formatCurrency from '../../utils/formatters/formatCurrency';
import ActionModal from '../ActionModal';
import { showErrorToast, showSuccessToast } from '../Toast';
import UploadCSV, { UploadCSVProps } from '../UploadCSV/UploadCSV';
import { QueryKeys } from '../../queries/queryKeys';
import {
	BulkSellerStatementAdjustment,
	bulkUploadSellerStatementAdjustments,
} from '../../services/sellerStatementService';
import { CurrentUser } from '../../services/userService/userService.model';
import useCurrentUser from '../../queries/useCurrentUser';

const BulkUploadSellerStatementAdjustmentsModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const [bulkImportFile, setBulkImportFile] = useState<File | null>(null);

	const [bulkUploadedSellerStatementAdjustments, setBulkUploadedSellerStatementAdjustments] =
		useState<BulkSellerStatementAdjustment[]>([]);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { mutate: doBulkUploadLotAdjustments, status: bulkUploadSellerStatementAdjustmentsStatus } =
		useMutation({
			mutationKey: [QueryKeys.BulkUploadLotAdjustments],
			mutationFn: () =>
				bulkUploadSellerStatementAdjustments({
					bulkImportFile: bulkImportFile!,
					employeeId: currentUser.currentUserId,
				}),
		});

	const handleBulkUploadSellerStatementAdjustments = () => {
		if (bulkImportFile) {
			doBulkUploadLotAdjustments(undefined, {
				onSuccess: () => {
					showSuccessToast(
						`Submitted ${bulkUploadedSellerStatementAdjustments.length} Seller Summary adjustments for processing. Please wait a few minutes and refresh your browser to view the results.`
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting Seller Summary adjustments for processing. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkSellerStatementAdjustment> = {
		resultsColumns: [
			{ accessorKey: 'seller_statement_number', header: 'Seller Statement #' },
			{ accessorKey: 'category_type', header: 'Category' },
			{ accessorKey: 'description', header: 'Description' },
			{ accessorKey: 'adjustment_type', header: 'Adjustment Type' },
			{
				accessorKey: 'amount',
				header: 'Amount',
				cell: ({ getValue }) => (
					<div className="text-right">{formatCurrency(getValue() as number)}</div>
				),
			},
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkImportFile(uploadedFile);
			setBulkUploadedSellerStatementAdjustments(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkImportFile &&
		bulkUploadedSellerStatementAdjustments.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadSellerStatementAdjustmentsStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleBulkUploadSellerStatementAdjustments}
			isLoading={bulkUploadSellerStatementAdjustmentsStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Seller Statement Adjustments"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadSellerStatementAdjustmentsModal;

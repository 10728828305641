import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import ActionModal from '../../../../components/ActionModal';
import UploadCSV from '../../../../components/UploadCSV/UploadCSV';
import useCurrentUser from '../../../../queries/useCurrentUser';
import { CurrentUser } from '../../../../services/userService/userService.model';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { UploadCSVProps } from '../../../../components/UploadCSV/UploadCSV';
import {
	BulkStatementStatusCSV,
	bulkUploadStatementStatus,
} from '../../../../services/sellerStatementService';

const BulkUploadStatementStatusModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const [bulkUploadStatementStatusFile, setBulkUploadStatementStatusFile] = useState<File | null>(
		null
	);
	const [bulkUploadStatementStatusData, setBulkUploadStatementStatusData] = useState<
		BulkStatementStatusCSV[]
	>([]);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { mutate: bulkUploadStatementStatusMutation, status: bulkUploadStatementStatusStatus } =
		useMutation({
			mutationKey: [QueryKeys.BulkUploadStatementStatus, bulkUploadStatementStatusFile],
			mutationFn: (bulkUploadStatementStatusFile: File) =>
				bulkUploadStatementStatus({
					bulkUploadStatementStatusFile,
					employeeId: currentUser.currentUserId,
				}),
		});

	const bulkUploadStatementStatusHandler = () => {
		if (bulkUploadStatementStatusFile) {
			bulkUploadStatementStatusMutation(bulkUploadStatementStatusFile, {
				onSuccess: () => {
					showSuccessToast(
						"Bulk statement status are processing. You'll receive an email when complete."
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting bulk statement status. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkStatementStatusCSV> = {
		resultsColumns: [
			{ accessorKey: 'statement_number', header: 'Statement #' },
			{ accessorKey: 'seller_email', header: 'Seller Email' },
			{ accessorKey: 'statement_status', header: 'Statement Status' },
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadStatementStatusFile(uploadedFile);
			setBulkUploadStatementStatusData(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadStatementStatusFile &&
		bulkUploadStatementStatusData.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadStatementStatusStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={bulkUploadStatementStatusHandler}
			isLoading={bulkUploadStatementStatusStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Statement Status"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadStatementStatusModal;

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { isEqual } from 'lodash';
import Dropdown from '../../../../../../../components/Dropdown';
import Input from '../../../../../../../components/Input';
import Label from '../../../../../../../components/Label';
import {
	COUNTRY_DROPDOWN_OPTIONS,
	ShippableAddress,
	STATE_DROPDOWN_OPTIONS,
} from './EditShippingAddressModal.model';
import { updateOrderShippingAddress } from '../../../../../../../services/ordersService';
import { QueryKeys } from '../../../../../../../queries/queryKeys';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber';
import { showErrorToast, showSuccessToast } from '../../../../../../../components/Toast';
import ActionModal from '../../../../../../../components/ActionModal';

const EditShippingAddressModal = ({
	currentAddress,
	orderNumber,
	onClose,
}: {
	currentAddress: ShippableAddress;
	orderNumber: string;
	onClose: () => void;
}) => {
	const [draftShippingAddress, setDraftShippingAddress] =
		useState<ShippableAddress>(currentAddress);

	const { updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber: orderNumber,
		enabled: true,
	});

	const { mutate: doShippingAddressUpdate, status: shippingAddressUpdateStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderShippingAddress, orderNumber, `${new Date().getTime()}`],
		mutationFn: ({
			newShippingAddress,
			orderNumber,
		}: {
			newShippingAddress: ShippableAddress;
			orderNumber: string;
		}) =>
			updateOrderShippingAddress({
				orderNumber,
				shippingAddress: newShippingAddress,
			}),
	});

	const handleConfirm = () => {
		doShippingAddressUpdate(
			{ newShippingAddress: draftShippingAddress, orderNumber },
			{
				onSuccess: async (updatedOrder) => {
					updateOrderInCache(updatedOrder);

					showSuccessToast('Shipping address update succesful.');

					onClose();
				},
				onError: () => {
					showErrorToast('Something went wrong. Please try again later.');
				},
			}
		);
	};

	const handleUpdateShippingAddress = (fieldsToUpdate: Partial<ShippableAddress>) =>
		setDraftShippingAddress({ ...draftShippingAddress, ...fieldsToUpdate });

	const hasAllRequiredFields = [
		draftShippingAddress.line1,
		draftShippingAddress.city,
		draftShippingAddress.country,
		draftShippingAddress.postalCode,
	].every((item) => !!item);

	return (
		<ActionModal
			onClose={onClose}
			title="Edit Shipping Address"
			onConfirm={handleConfirm}
			canConfirm={!isEqual(currentAddress, draftShippingAddress) && hasAllRequiredFields}
			isLoading={shippingAddressUpdateStatus === 'loading'}
		>
			<div className="mt-6 flex flex-col space-y-4">
				<div>
					<Label htmlFor="line1">Address Line 1</Label>
					<Input
						className="w-full"
						name="line1"
						value={draftShippingAddress.line1}
						onChange={({ target: { value: updatedValue = '' } }) =>
							handleUpdateShippingAddress({ line1: updatedValue.trim() })
						}
					/>
				</div>
				<div>
					<Label htmlFor="line2">Address Line 2</Label>
					<Input
						className="w-full"
						name="line2"
						value={draftShippingAddress.line2}
						onChange={({ target: { value: updatedValue = '' } }) =>
							handleUpdateShippingAddress({ line2: updatedValue.trim() })
						}
					/>
				</div>
				<div>
					<Label htmlFor="city">City</Label>
					<Input
						className="w-full"
						name="city"
						value={draftShippingAddress.city}
						onChange={({ target: { value: updatedValue = '' } }) =>
							handleUpdateShippingAddress({ city: updatedValue.trim() })
						}
					/>
				</div>
				<div className="flex flex-row items-center">
					<div className="flex-grow mr-2">
						<Label htmlFor="state">State / Province / Region</Label>
						{currentAddress.country === 'US' && draftShippingAddress.country === 'US' ? (
							<Dropdown
								key={draftShippingAddress.state}
								name="state"
								onChange={({ value: updatedValue }) =>
									handleUpdateShippingAddress({ state: updatedValue as string })
								}
								options={STATE_DROPDOWN_OPTIONS}
								value={STATE_DROPDOWN_OPTIONS.find(
									(option) => option.value === draftShippingAddress.state
								)}
							/>
						) : (
							<Input
								name="state"
								value={draftShippingAddress.state}
								onChange={({ target: { value: updatedValue = '' } }) =>
									handleUpdateShippingAddress({ state: updatedValue.trim() })
								}
							/>
						)}
					</div>
					<div className="flex-grow mr-2">
						<Label htmlFor="postalCode">ZIP / Postal Code</Label>
						<Input
							name="postalCode"
							value={draftShippingAddress.postalCode}
							className="w-full"
							onChange={({ target: { value: updatedValue = '' } }) =>
								handleUpdateShippingAddress({ postalCode: updatedValue.trim() })
							}
						/>
					</div>
					<div className="flex-grow">
						<Label htmlFor="country">Country</Label>
						<Dropdown
							key={draftShippingAddress.country}
							name="country"
							onChange={({ value: updatedValue }) =>
								handleUpdateShippingAddress({ country: updatedValue as string })
							}
							options={COUNTRY_DROPDOWN_OPTIONS}
							value={COUNTRY_DROPDOWN_OPTIONS.find(
								(option) => option.value === draftShippingAddress.country
							)}
						/>
					</div>
				</div>
			</div>
		</ActionModal>
	);
};

export default EditShippingAddressModal;

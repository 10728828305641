import { pickBy, uniqBy } from 'lodash';
import {
	Adjustment,
	StructuredFee,
	StructuredFees,
	STRUCTURED_FEES,
} from '../../../../../../services/ordersService/ordersService.model';
import { FeeStructure } from './OrderSummary.model';
import { KernelLot, RemovedLot } from '../../../../../../services/lotsService/lotsService.model';
import { AuctionType } from '../../../../../../services/auctionsService';

export const getChangedFees = ({
	original,
	current,
}: {
	original: FeeStructure;
	current: FeeStructure;
}): Partial<FeeStructure> => {
	const comparableFeeOverrides = {
		shipping: { currentValue: current.shipping, originalValue: original.shipping },
		handling: { currentValue: current.handling, originalValue: original.handling },
		insurance: { currentValue: current.insurance, originalValue: original.insurance },
	};
	const changedFees = pickBy(
		comparableFeeOverrides,
		(feeType) => feeType.currentValue !== feeType.originalValue
	);
	const changedFeeValues = Object.keys(changedFees).reduce(
		(accumulator, thisFee) => ({ ...accumulator, [thisFee]: changedFees[thisFee].currentValue }),
		{}
	);
	return changedFeeValues;
};

export const getUpdatedAdjustments = ({
	currentAdjustments,
	newAdjustments,
	adjustmentIdsStagedForDeletion,
}: {
	currentAdjustments: Adjustment[];
	newAdjustments: Adjustment[];
	adjustmentIdsStagedForDeletion: string[];
}): Adjustment[] => {
	const structuredFeeCategoriesFromNewAdjustments = newAdjustments
		.map(({ category }) => category)
		.filter((category) => STRUCTURED_FEES.includes(category));

	const currentAdjustmentsWithoutStructuredFeesFromNewAdjustments: Adjustment[] =
		currentAdjustments.filter(
			({ category }) => !structuredFeeCategoriesFromNewAdjustments.includes(category)
		);

	const adjustmentsWithoutDeletedItems = [
		...currentAdjustmentsWithoutStructuredFeesFromNewAdjustments,
		...newAdjustments,
	].filter(({ id = '' }) => !adjustmentIdsStagedForDeletion.includes(id) || !id);

	return uniqBy(adjustmentsWithoutDeletedItems, ({ id = '', category }) => id || category);
};

export const getStructuredFee = ({
	feeType,
	adjustments = [],
	fees,
}: {
	feeType: StructuredFee;
	adjustments?: Adjustment[];
	fees: StructuredFees;
}): number => {
	const adjustmentForThisFee = adjustments.find(({ category }) => category === feeType);

	if (fees && adjustmentForThisFee === undefined) {
		return fees[feeType];
	}

	return Number(adjustmentForThisFee?.amount || 0);
};

export const getStructuredFees = ({
	fees,
	adjustments,
}: {
	fees: StructuredFees;
	adjustments: Adjustment[];
}): StructuredFees => ({
	shipping: getStructuredFee({ feeType: 'shipping', fees, adjustments }),
	handling: getStructuredFee({ feeType: 'handling', fees, adjustments }),
	insurance: getStructuredFee({ feeType: 'insurance', fees, adjustments }),
	tax: fees.tax,
});

export const kernelLotToRemovedLot = (lot: KernelLot, auctionType: AuctionType): RemovedLot => ({
	auctionId: lot.auction_id,
	auctionType: auctionType,
	buyerPremium: lot.buyer_premium,
	commission: lot.commission,
	createdBy: lot.created_by ?? '',
	createdOn: lot.created_at ?? '',
	currentPrice: lot.current_price,
	finalPrice: lot.final_price,
	lotId: lot.lot_id,
	lotNumber: Number(lot.lot_number),
	metaSlug: lot.meta_slug ?? '',
	reason: 'Invoice_New_Auction',
	sellerId: lot.seller_id ?? '',
	serialNumber: lot.serial_number ?? '',
	title: lot.title,
	primaryImageName: lot.primary_image_name ?? '',
});

import { useQuery } from 'react-query';
import {
	SearchSellerStatementByAuctionParams,
	SearchSellerStatementBySellerParams,
	SearchSellerStatementParams,
} from '../../services/sellerStatementService';
import { getSellerStatements } from '../../services/sellerStatementService/getSellerStatements';
import { getSellerStatementsByUser } from '../../services/sellerStatementService/getSellerStatementsByUser';
import { QueryKeys } from '../queryKeys';

const useSellerStatements = (params: SearchSellerStatementParams) => {
	const handler =
		params.queryBy === 'auctionId'
			? () => getSellerStatements(params as SearchSellerStatementByAuctionParams)
			: () => getSellerStatementsByUser(params as SearchSellerStatementBySellerParams);
	return useQuery([QueryKeys.GetSellerStatements, params], () => handler());
};

export default useSellerStatements;

import React, { FC } from 'react';
import { ArrowUndo20Regular, Delete20Regular } from '@fluentui/react-icons';
import CurrencyValue from '../CurrencyValue';
import LineItemLayout from '../LineItemLayout';
import { LineItemProps } from '../LineItem.model';
import { useEditSummaryContext } from '../../useEditSummary';

interface DeletableLineItemProps extends LineItemProps {
	isStagedForDeletion: boolean;
	toggleAdjustmentDeletion: (adjustmentId: string) => void;
	adjustmentId: string;
}

const DeletableLineItem: FC<DeletableLineItemProps> = ({
	kind,
	children,
	amount,
	isStagedForDeletion,
	adjustmentId,
	toggleAdjustmentDeletion,
}) => {
	const { isEditing } = useEditSummaryContext();

	const stagedForDeletionClass = isStagedForDeletion ? 'italic line-through opacity-50' : '';

	return (
		<LineItemLayout kind={kind} label={children} className={stagedForDeletionClass}>
			<CurrencyValue
				amount={amount}
				className={`${stagedForDeletionClass} ${isEditing ? '' : 'mr-8'}`}
			/>
			{isEditing && (
				<button
					title="Remove adjustment"
					className="ml-2"
					onClick={() => toggleAdjustmentDeletion(adjustmentId)}
				>
					{isStagedForDeletion ? <ArrowUndo20Regular /> : <Delete20Regular className="mb-1" />}
				</button>
			)}
		</LineItemLayout>
	);
};

export default DeletableLineItem;

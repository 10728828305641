import { capitalize, kebabCase } from 'lodash';

export const titleCase = (input = '') =>
	kebabCase(input)
		.split('-')
		.map((segment, index) => {
			if (index > 0 && ['for', 'to', 'with'].includes(segment)) {
				return segment.toLowerCase();
			}
			return capitalize(segment);
		})
		.join(' ');

import AuctionDashboardRoot from './AuctionDashboard/AuctionDashboardRoot';
import AuctionManager from './AuctionManager';
import AuctionPreview from './AuctionPreview';
import AuctionPublisher from './AuctionPublisher';
import Layout from '../../components/Layout';
import LotManager from './LotManager';
import PickSheetPage from './ShipmentManager/PickSheetPage';
import ProtectedRoute from '../../routing/ProtectedRoute/ProtectedRoute';
import RequireAuth from '../../routing/RequireAuth';
import SellerStatement from './SellerStatement';
import SellerSummary from './SellerSummary';
import ShipmentManager from './ShipmentManager';
import WinnerSummary from './WinnerSummary';

const AuctionsRoot = ({ match }: { match: { url: string } }) => {
	return (
		<RequireAuth unauthRedirectTo={`${match.url}/auction-dashboard`}>
			<Layout>
				<ProtectedRoute
					path={`${match.url}/auction-dashboard/:auctionId?`}
					component={AuctionDashboardRoot}
				/>
				{/* @deprecated - use /:auctionId/preview */}
				<ProtectedRoute path={`${match.url}/preview/:auctionId`} component={AuctionPreview} />
				<ProtectedRoute path={`${match.url}/:auctionId/preview`} component={AuctionPreview} />
				<ProtectedRoute path={`${match.url}/preview`} component={AuctionPreview} />
				<ProtectedRoute exact path={`${match.url}/winnersummary`} component={WinnerSummary} />
				<ProtectedRoute
					exact
					path={`${match.url}/winnersummary/:auctionId/:queryType?`}
					component={WinnerSummary}
				/>
				<ProtectedRoute exact path={`${match.url}/sellersummary`} component={SellerSummary} />
				<ProtectedRoute
					path={`${match.url}/:auctionId/statements/:statementId`}
					component={SellerStatement}
				/>
				<ProtectedRoute
					exact
					path={`${match.url}/sellersummary/:auctionId`}
					component={SellerSummary}
				/>
				<ProtectedRoute exact path={`${match.url}/auctionmanager`} component={AuctionManager} />
				<ProtectedRoute
					exact
					path={`${match.url}/auctionmanager/:auctionId/:lotId?`}
					component={AuctionManager}
				/>
				<ProtectedRoute
					path={`${match.url}/:auctionId/auction-manager`}
					component={AuctionManager}
				/>
				<ProtectedRoute
					exact
					path={`${match.url}/:auctionId/inventory-manager/:lotId?`}
					component={LotManager}
				/>
				<ProtectedRoute
					exact
					path={`${match.url}/:auctionId/shipment-manager/:invoiceId`}
					component={PickSheetPage}
				/>
				<ProtectedRoute exact path={`${match.url}/inventory-manager`} component={LotManager} />
				{/* @deprecated - use /:auctionId/inventory-manager/:lotId */}
				<ProtectedRoute
					exact
					path={`${match.url}/inventorymanager/:auctionId?/:lotId?`}
					component={LotManager}
				/>
				<ProtectedRoute
					path={`${match.url}/auctionpublisher/:auctionId?`}
					component={AuctionPublisher}
				/>
				<ProtectedRoute exact path={`${match.url}/shipmentmanager`} component={ShipmentManager} />
				<ProtectedRoute
					exact
					path={`${match.url}/shipmentmanager/:auctionId`}
					component={ShipmentManager}
				/>
			</Layout>
		</RequireAuth>
	);
};

export default AuctionsRoot;

import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import AddLotAdjustmentModal from './AddLotAdjustmentModal';
import DeleteLotAdjustmentModal from './DeleteLotAdjustmentModal';
import Label from '../../Label';
import RestrictedArea from '../../RestrictedArea';
import SortableTable from '../../SortableTable';
import { Button } from '../../../prizm-ui/Button';
import { SortableTableParams } from '../../SortableTable/SortableTable.model';
import formatCurrency from '../../../utils/formatters/formatCurrency';
import { formatDate } from '../../../utils/formatters';
import { KernelLot, KernelLotAdjustment } from '../../../services/lotsService/lotsService.model';
import { EDITOR_ROLES } from '../../../services/userService/userService.model';
import { deleteLotAdjustmentModalBaseState } from './DeleteLotAdjustmentModal/DeleteLotAdjustmentModal.model';
import useIndividualLot from '../../../queries/useIndividualLot';

const LotAdjustments = () => {
	const { lotId } = useParams<{ lotId: string }>();
	const [shouldShowAddLotAdjustmentModal, setShouldShowAddLotAdjustmentModal] = useState(false);

	const { data: thisLot = {} as KernelLot } = useIndividualLot(lotId);

	const [deleteAdjustmentModalState, setDeleteAdjustmentModalState] = useState(
		deleteLotAdjustmentModalBaseState
	);
	const {
		isOpen: shouldShowDeleteAdjustmentModal,
		adjustmentId: adjustmentIdToDelete,
		description: adjustmentDescriptionToDelete,
	} = deleteAdjustmentModalState;

	const closeDeleteAdjustmentModal = () =>
		setDeleteAdjustmentModalState(deleteLotAdjustmentModalBaseState);
	const openDeleteAdjustmentModal = ({
		adjustmentId,
		description,
	}: {
		adjustmentId: string;
		description: string;
	}) => {
		const updatedDeletedAdjustmentModalState = { adjustmentId, description, isOpen: true };
		setDeleteAdjustmentModalState(updatedDeletedAdjustmentModalState);
	};

	const columns: ColumnDef<KernelLotAdjustment>[] = [
		{
			id: 'adjustment_id',
			header: '',
			cell: ({
				row: {
					original: { id, description },
				},
			}) => (
				<Button
					icon="Delete"
					variant="text"
					destructive={true}
					disabled={!id}
					size="md"
					onClick={() => openDeleteAdjustmentModal({ adjustmentId: id, description })}
				/>
			),
		},
		{
			accessorKey: 'description',
			header: 'Description',
		},
		{
			accessorKey: 'category',
			header: 'Category',
		},
		{
			accessorKey: 'type',
			header: 'Type',
		},
		{
			accessorKey: 'amount',
			header: 'Amount',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorKey: 'createdAt',
			header: 'Created At',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatDate(getValue() as string),
		},
		{
			accessorFn: ({ createdBy }) => createdBy?.email ?? '',
			header: 'Created By',
			cell: ({ getValue, row }) => (
				<a href={`mailto:${getValue()}`} className="link">
					{row?.original?.createdBy?.fullName ?? 'Unknown'}
				</a>
			),
		},
	];

	const tableParams: SortableTableParams<KernelLotAdjustment> = {
		columns,
		data: thisLot.adjustments ?? ([] as KernelLotAdjustment[]),
		initialState: {
			sorting: [
				{
					desc: true,
					id: 'createdAt',
				},
			],
		},
	};

	return (
		<div>
			<div className="flex flex-row items-start justify-between mb-2">
				<h2 className="text-subtitle1">Adjustments</h2>
				<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
					<Button
						variant="brand"
						className="mr-2"
						size="md"
						onClick={() => setShouldShowAddLotAdjustmentModal(true)}
					>
						Add Adjustment
					</Button>
					{shouldShowAddLotAdjustmentModal && (
						<AddLotAdjustmentModal
							onClose={() => setShouldShowAddLotAdjustmentModal(false)}
							lotId={lotId}
						/>
					)}
					{shouldShowDeleteAdjustmentModal && (
						<DeleteLotAdjustmentModal
							adjustmentId={adjustmentIdToDelete}
							description={adjustmentDescriptionToDelete}
							lotId={lotId}
							onClose={closeDeleteAdjustmentModal}
						/>
					)}
				</RestrictedArea>
			</div>
			<div>
				<Label>Adjustments for this Lot</Label>
				<SortableTable tableParams={tableParams} />
			</div>
		</div>
	);
};

export default LotAdjustments;

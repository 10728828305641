import { getFilenameForLegacyImage } from '../../../../../AdminUtils/imageUtils.helpers';
import emptyLogo from '../../../../../assets/images/placeholder-card-lg.png';
import { getCloudFrontImageUrl } from '../../../../../services/storageService';
import { ImageSize } from '../../../../../services/storageService/storageService.model';
import { isUrl } from '../../../../../utils/validators';

export const getPrimaryImageUrlForImageSize = ({
	primaryImageName,
	lotId,
	imageSize,
}: {
	primaryImageName?: string;
	lotId: string;
	imageSize: ImageSize;
}) => {
	if (isUrl(primaryImageName)) {
		return primaryImageName;
	}

	return primaryImageName
		? getCloudFrontImageUrl({
				parentPath: lotId,
				itemId: getFilenameForLegacyImage({ imageIdentifier: primaryImageName }),
				imageSize,
				rootPath: 'Lots',
		  })
		: emptyLogo;
};

const getSrcSetDeclaration = ({
	imageName,
	lotId,
	imageSize,
}: {
	imageName?: string;
	lotId: string;
	imageSize: ImageSize;
}): string => {
	const resolution = imageSize.replace('@', '');
	const imageUrl = getPrimaryImageUrlForImageSize({
		primaryImageName: imageName,
		lotId,
		imageSize,
	});
	return `${imageUrl} ${resolution}`;
};

export const getSrcSetDeclarations = ({
	imageName = '',
	lotId,
}: {
	imageName?: string;
	lotId: string;
}): string => {
	if (isUrl(imageName)) {
		return imageName;
	}

	const allSizes: ImageSize[] = ['@4x', '@3x', '@2x', '@1x'];
	const srcSetDeclarations = allSizes.map((imageSize) =>
		getSrcSetDeclaration({ imageName, lotId, imageSize })
	);
	return srcSetDeclarations.join(',');
};

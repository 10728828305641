import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export interface EmailSellerStatementPDFParams {
	auctionId: string;
	statementId: string;
	emailTo: string;
}
export const emailSellerStatementPDF = async ({
	auctionId,
	statementId,
	emailTo,
}: EmailSellerStatementPDFParams) => {
	const response = await axiosAuth.post(`${config.api.seller_statement_pdf}`, {
		auction_id: auctionId,
		seller_statement_id: statementId,
		email_to: emailTo,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { compareDesc } from 'date-fns';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import ActionModal from '../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import useAuctions from '../../../../queries/useAuctions';
import { Auction } from '../../../../services/auctionsService';
import { deleteAuction } from '../../../../services/auctionsService/deleteAuction';

const DeleteAuctionModal = ({ auction, onClose }: { onClose: () => void; auction: Auction }) => {
	const history = useHistory();
	const queryClient = useQueryClient();

	const { mutate: doDeleteAuction, status: deleteAuctionStatus } = useMutation({
		mutationKey: [QueryKeys.DeleteAuction, auction.id],
		mutationFn: () => deleteAuction(auction.id),
	});

	const { data: auctions = [] as Auction[] } = useAuctions();
	const [firstNotStartedAuction] = auctions
		.filter(({ id }) => id !== auction.id)
		.filter(({ status }) => status === 'Not_Started')
		.sort((a, b) => compareDesc(new Date(a.createdAt ?? ''), new Date(b.createdAt ?? '')));

	const handleDelete = () =>
		doDeleteAuction(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully deleted auction');
				queryClient.invalidateQueries([QueryKeys.GetAuctions]);
				onClose();
				history.push(`/auctions/${firstNotStartedAuction.id}/auction-manager`);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong deleting the auction. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleDelete}
			title="Delete Auction"
			isDangerous={true}
			isLoading={deleteAuctionStatus === 'loading'}
		>
			<div className="text-body1">
				Are you sure you want to delete{' '}
				<span className="font-semibold text-error1">{auction.title}</span>? This action cannot be
				reversed.
			</div>
		</ActionModal>
	);
};

export default DeleteAuctionModal;

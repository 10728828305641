import React from 'react';
import classNames from 'classnames';

const PageTitle = ({
	icon,
	shouldShowPageTitleIcon = true,
	heading,
	subheading,
}: {
	icon: string;
	shouldShowPageTitleIcon?: boolean;
	heading: string;
	subheading?: string;
}) => {
	return (
		<div className="flex items-center">
			<div
				className={classNames('flex items-center justify-center rounded-md bg-neutral1 pr-4', {
					'd-none': !shouldShowPageTitleIcon,
				})}
			>
				<i className={`${icon} m-auto flex h-14 w-14 items-center justify-center text-5xl`} />
			</div>
			<h1 className="text-subtitle1">{heading}</h1>
			{subheading && <div className="text-lg">{subheading}</div>}
		</div>
	);
};

export default PageTitle;

import { useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useAuctionById from '../../../queries/useAuctionById';
import useSellerStatementDetails from '../../../queries/useSellerStatementDetails';
import {
	KernelSellerStatementDetails,
	KernelSellerStatementDetailsRemovedLot,
	SellerStatementDetailsRemovedLot,
} from '../../../services/sellerStatementService';
import SellerStatementAccountDetails from './SellerStatementAccountDetails';
import SellerStatementAdjustments from './SellerStatementAdjustments';
import SellerStatementGeneralInfo from './SellerStatementGeneralInfo';
import SellerStatementLots from './SellerStatementLots';
import SellerStatementPrivateComments from './SellerStatementPrivateComments';
import SellerStatementRemovedLots from './SellerStatementRemovedLots/SellerStatementRemovedLots';

const SellerStatement = () => {
	const { auctionId, statementId: statementIdFromParams } = useParams<{
		auctionId: string;
		statementId: string;
	}>();

	const {
		data: statementDetails = {} as KernelSellerStatementDetails,
		status: statementDetailsStatus,
	} = useSellerStatementDetails({
		auctionId,
		statementId: statementIdFromParams,
	});

	const {
		removed_lots: kernel_removed_lots = [] as KernelSellerStatementDetailsRemovedLot[],
		seller_statement_id: statementId,
		seller_statement_number: statementNumber,
	} = statementDetails;

	const removedLots = kernel_removed_lots.map((lot) => ({
		auctionId: lot.auction_id,
		auctionType: lot.auction_type,
		bin: lot.bin ?? '',
		buyerPremium: lot.buyer_premium,
		commission: lot.commission,
		createdBy: lot.created_by,
		createdOn: lot.created_on,
		currentPrice: lot.current_price,
		finalPrice: lot.final_price,
		invoiceStatus: lot.invoice_status ?? '',
		lotId: lot.lot_id,
		lotNumber: lot.lot_number,
		metaSlug: lot.meta_slug,
		primaryImageName: lot.primary_image_name,
		reason: 'Invoice_New_Auction',
		room: lot.room ?? '',
		sellerId: lot.seller_id,
		serialNumber: lot.serial_number,
		title: lot.title,
	})) as SellerStatementDetailsRemovedLot[];

	const { status: auctionStatus } = useAuctionById(auctionId);

	return (
		<LoadingWrapper queryStatuses={[statementDetailsStatus, auctionStatus]}>
			<Card>
				<SellerStatementGeneralInfo auctionId={auctionId} statementDetails={statementDetails} />
			</Card>
			<Card className="mt-4">
				<SellerStatementAccountDetails
					auctionId={auctionId}
					statementDetails={statementDetails}
					statementId={statementId}
				/>
			</Card>
			<Card className="mt-4">
				<SellerStatementLots
					auctionId={auctionId}
					lots={statementDetails.lots}
					statementId={statementId}
				/>
			</Card>
			<Card className="mt-4">
				<SellerStatementAdjustments
					adjustments={statementDetails.adjustments ?? []}
					auctionId={auctionId}
					statementId={statementId}
					statementNumber={statementNumber}
				/>
			</Card>
			<Card className="mt-4">
				<SellerStatementPrivateComments
					auctionId={auctionId}
					incomingComments={statementDetails.comments}
					statementId={statementId}
				/>
			</Card>
			{removedLots && (
				<Card className="mt-4">
					<SellerStatementRemovedLots removedLots={removedLots} />
				</Card>
			)}
		</LoadingWrapper>
	);
};

export default SellerStatement;

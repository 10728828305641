import { getAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const getPickOrderSheets = async () => {
	const response = await getAuthed(`${apiEndpoints.checkout}/pickordersheets`);

	if (response.status >= 400) {
		throw response;
	}

	return new Blob([response.data as string], { type: 'text/html' });
};

import React, { Component } from 'react';
import {
	FilledInput,
	InputLabel,
	IconButton,
	InputAdornment,
	FormControl,
	Snackbar,
	Fade,
	AppBar,
} from '@material-ui/core';
import { Search, Clear, Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';

// Generates the filter widget
export default class SearchFilter extends Component {
	constructor(props) {
		super(props);

		this.filterData = this.filterData.bind(this);
		this.handleFilter = this.handleFilter.bind(this);

		let filteredData = this.filterData(props.searchTerm, props.items);

		this.state = {
			id: uuidv4(),
			defaultValue: props.searchTerm,
			searchTerm: props.searchTerm,
			items: props.items,
			filteredDataCallback: props.filteredDataCallback,
			filteredData: filteredData,
			filterDelay: props.filterDelay || 500,
			disableAutocomplete: props.disableAutocomplete,
		};
	}

	filterData = (searchTerm, fullItems) => {
		if (!searchTerm || searchTerm == '') return fullItems;

		let filteredData = [];
		for (const [index, item] of fullItems.entries()) {
			// Scrub the item of properties that we don't want to search on
			const { ...scrubbedItem } = item;

			let filterMatch = Object.filter(scrubbedItem, (elem) => {
				if (!elem) return false;
				return JSON.stringify(elem).toLowerCase().includes(searchTerm.toLowerCase());
			});
			if (filterMatch && Object.keys(filterMatch).length > 0) {
				filteredData.push(item);
			}
		}
		return filteredData;
	};

	queueFilter = (event) => {
		if (!event || !event.target) return;

		this.setState({ processingAlertOpen: true });

		const filterCallbackKey = this.state.id + '_filter_callback';
		const val = event.target.value;
		if (window[filterCallbackKey]) clearTimeout(window[filterCallbackKey]);
		if (!this.state.disableAutocomplete) {
			window[filterCallbackKey] = setTimeout(() => {
				this.handleFilter(val);
			}, this.state.filterDelay);
		}
	};

	handleSearchOnEnter = (event) => {
		if (!event || !event.target) return;
		if (!this.state.disableAutocomplete) return;
		if (event.key != 'Enter') return;

		this.setState({ processingAlertOpen: true });

		const filterCallbackKey = this.state.id + '_filter_callback';
		const val = event.target.value;
		if (window[filterCallbackKey]) clearTimeout(window[filterCallbackKey]);
		window[filterCallbackKey] = setTimeout(() => {
			this.handleFilter(val);
		}, this.state.filterDelay);
	};

	handleFilter = (searchTerm) => {
		// No need to refresh if there is no search term, no items, or the search term
		// hasn't changed since the last search
		if (searchTerm === undefined || !this.state.items || searchTerm === this.state.searchTerm)
			return;

		let filteredData = this.filterData(searchTerm, this.state.items);
		if (this.state.filteredDataCallback) this.state.filteredDataCallback(filteredData, searchTerm);

		this.setState({
			searchTerm: searchTerm,
			filteredData: filteredData,
		});
	};

	render() {
		let filterHtml = (
			<div>
				<FormControl fullWidth variant="filled">
					<InputLabel htmlFor={this.state.id}>Search</InputLabel>
					<FilledInput
						className="text-justify"
						id={this.state.id}
						placeholder="I'm looking for..."
						defaultValue={this.state.defaultValue}
						onChange={this.queueFilter}
						onKeyDown={this.handleSearchOnEnter}
						startAdornment={
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									color="secondary"
									style={{ padding: 10 }}
									aria-label="clear"
									onClick={(evt) => {
										document.getElementById(this.state.id).value = '';
										evt.target.value = '';
										this.queueFilter(evt).bind(this);
									}}
								>
									<Clear />
								</IconButton>
							</InputAdornment>
						}
						aria-describedby="standard-weight-helper-text"
						inputProps={{ 'aria-label': 'search' }}
					/>
				</FormControl>
				<AppBar key={this.state.id + '-searchFilterAlertAppBar'}>
					<Snackbar
						id={this.state.id + '-processingAlert'}
						open={this.state.processingAlertOpen}
						onClose={() => {
							this.setState({ processingAlertOpen: false });
						}}
						autoHideDuration={2000}
						TransitionComponent={Fade}
						key={Fade.name}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					>
						<Alert elevation={6} variant="filled" severity="info">
							Filtering...
							<>
								<IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => {
										this.setState({ processingAlertOpen: false });
									}}
								>
									<Close fontSize="small" />
								</IconButton>
							</>
						</Alert>
					</Snackbar>
				</AppBar>
			</div>
		);

		return filterHtml;
	}
}

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Checkbox from '../../../../../components/Checkbox';
import AuctionPicker from '../../../../../components/NewAuctionPicker';
import BidsTable from './BidsTable';
import PlaceBidForUserModal from './PlaceBidForUserModal';
import { KernelBidByUserForAuction } from '../../../../../services/bidderService/getBidsByUserForAuction.model';
import useBidsByUserForAuction from '../../../../../queries/useBidsByUserForAuction';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import useAuctionById from '../../../../../queries/useAuctionById';
import { Auction } from '../../../../../services/auctionsService';
import useListOfLots from '../../../../../queries/useListOfLots';
import { KernelLotForBidsTable } from './BidsTabView.model';
import { parseToLotWithBidData } from './BidsTabView.utils';

const BidsTabView = () => {
	const { auctionId = '', userId } = useParams<{ auctionId?: string; userId: string }>();
	const history = useHistory();

	const [shouldShowAllLots, setShouldShowAllLots] = useState(false);

	const [selectedAuctionId, setSelectedAuctionId] = useState(auctionId);
	useEffect(() => {
		history.push(`/usermanagement/customer/${userId}/bids/${selectedAuctionId}`);
	}, [selectedAuctionId]);

	const [selectedLotId, setSelectedLotId] = useState('');
	const [shouldShowPlaceBidModal, setShouldShowPlaceBidModal] = useState(false);
	const openPlaceBidModal = (lotId: string) => {
		setSelectedLotId(lotId);
		setShouldShowPlaceBidModal(true);
	};
	const closePlaceBidModal = () => {
		setSelectedLotId('');
		setShouldShowPlaceBidModal(false);
		if (shouldShowAllLots) {
			setShouldShowAllLots(false);
		}
	};

	const { data: auction = {} as Auction } = useAuctionById(auctionId);

	const {
		data: bidsByUserForAuction = [] as KernelBidByUserForAuction[],
		status: bidsByUserForAuctionStatus,
	} = useBidsByUserForAuction({ userId, auctionId });

	const {
		data: listOfLots = { lots: [] as KernelLotForBidsTable[], total: 0 },
		status: listOfLotsStatus,
	} = useListOfLots<KernelLotForBidsTable>({
		params: { kind: 'assigned', auctionId },
		include: ['current_price', 'min_bid_price', 'number_of_bids', 'status'],
	});

	const lotsWithBidData = listOfLots.lots.map((lot) =>
		parseToLotWithBidData({ lot, bids: bidsByUserForAuction })
	);

	const lotsToShow = shouldShowAllLots
		? lotsWithBidData
		: lotsWithBidData.filter(({ hasUserBidOnThisLot }) => hasUserBidOnThisLot);

	const isLotSelected = selectedLotId && lotsToShow.length;

	const isWinnerOfSelectedLot = isLotSelected
		? lotsToShow.filter((lot) => lot.lotId === selectedLotId)[0].isCurrentWinner
		: false;

	return (
		<>
			<h2 className="text-display5">{auction.title}</h2>
			<div className="flex w-full items-center mt-2">
				<div className="mr-4">
					<AuctionPicker
						selectedAuctionId={selectedAuctionId}
						setSelectedAuctionId={setSelectedAuctionId}
						shouldShowUnassignedLots={false}
						allowedAuctionStatuses={['Active', 'Completed']}
					/>
				</div>
				<div className="h-full">
					<Checkbox
						checked={shouldShowAllLots}
						onChange={() => setShouldShowAllLots(!shouldShowAllLots)}
						name="show-all-lots"
						label="Show All Lots"
					/>
				</div>
			</div>
			<div className="mt-4">
				<LoadingWrapper queryStatuses={[bidsByUserForAuctionStatus, listOfLotsStatus]}>
					<BidsTable
						shouldShowAllLots={shouldShowAllLots}
						lotsWithBidData={lotsToShow}
						openPlaceBidModal={openPlaceBidModal}
					/>
				</LoadingWrapper>
			</div>
			{shouldShowPlaceBidModal && (
				<PlaceBidForUserModal
					lotId={selectedLotId}
					onClose={() => setShouldShowPlaceBidModal(false)}
					isCurrentWinner={isWinnerOfSelectedLot}
				/>
			)}
		</>
	);
};

export default BidsTabView;

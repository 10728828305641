import { isEqual, pickBy } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import Categories from '../../../../../components/ManageIndividualLot/LotEditor/LotCategories/LotCategories';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Input } from '../../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useFixedPriceItem from '../../../../../queries/useFixedPriceItem';
import { updateLot } from '../../../../../services/lotsService';
import { FixedPriceItem, KernelLot } from '../../../../../services/lotsService/lotsService.model';
import { snakeObject } from '../../../../../utils/formatters';
import { getDeltas } from '../../../../../utils/formatters/getDeltas';

const EditFixedPriceItemModal = ({ onClose, itemId }: { itemId: string; onClose: () => void }) => {
	const { data: item = {} as FixedPriceItem, updateFixedPriceItem: updateItemInCache } =
		useFixedPriceItem(itemId);

	const [draftItem, setDraftItem] = useState(item);
	const updateDraftItem = (deltas: Partial<FixedPriceItem>) =>
		setDraftItem({ ...draftItem, ...deltas });

	const canConfirm = !isEqual(item, draftItem);

	const updateCategories = ({
		category,
		item_type: itemType,
		sub_category: subCategory,
	}: Partial<KernelLot>) => {
		const deltas = pickBy({ category, itemType, subCategory }, (value) => !!value);

		return updateDraftItem(deltas);
	};

	const deltas = getDeltas<FixedPriceItem>({
		olderVersion: item,
		newerVersion: draftItem,
	});

	const { mutate: doUpdateItem, status: updateItemStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateFixedPriceItem, Object.entries(deltas)],
		mutationFn: () => updateLot(snakeObject({ lotId: item.lotId, ...deltas })),
	});

	const handleUpdateItem = () =>
		doUpdateItem(undefined, {
			onSuccess: () => {
				updateItemInCache(deltas);
				showSuccessToast('Successfully updated item.');

				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating the item. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	return (
		<ActionModal
			onConfirm={handleUpdateItem}
			onClose={onClose}
			title="Edit Item"
			confirmButtonLabel="Save Changes"
			canConfirm={canConfirm}
			isLoading={updateItemStatus === 'loading'}
		>
			<h2 className="text-subtitle1 mb-2">General Information</h2>
			<Input
				id="title"
				label="Title"
				value={draftItem.title}
				onChange={({ target: { value } }) => updateDraftItem({ title: value })}
			/>
			<div className="grid grid-cols-2 gap-4 mt-4">
				<Input
					id="certNumber"
					label="Cert No."
					value={draftItem.certNumber}
					onChange={({ target: { value } }) => updateDraftItem({ certNumber: value })}
				/>
				<Input
					id="estimatedValue"
					label="Estimated Value"
					value={draftItem.estimatedValue}
					type="number"
					onChange={({ target: { value } }) => updateDraftItem({ estimatedValue: Number(value) })}
				/>
			</div>
			<div className="my-8">
				<h2 className="text-subtitle1 mb-2">Categories</h2>
				<div className="grid grid-cols-2 gap-4">
					<Categories
						currentCategory={draftItem.category}
						currentItemType={draftItem.itemType}
						currentSubcategory={draftItem.subCategory}
						updateLotField={updateCategories}
					/>
				</div>
			</div>
			<div className="my-8">
				<h2 className="text-subtitle1 mb-2">Details</h2>
				<Input
					id="playerSubject"
					label="Player/Subject"
					value={draftItem.playerSubject}
					onChange={({ target: { value } }) => updateDraftItem({ playerSubject: value })}
				/>
				<div className="grid grid-cols-2 gap-4 mt-4">
					<Input
						id="sport"
						label="Sport"
						value={draftItem.sport}
						onChange={({ target: { value } }) => updateDraftItem({ sport: value })}
					/>
					<Input
						id="year"
						label="Year"
						value={draftItem.year}
						type="number"
						onChange={({ target: { value } }) => updateDraftItem({ year: Number(value) })}
					/>
					<Input
						id="gradingCompany"
						label="Grading Company"
						value={draftItem.gradingCompany}
						onChange={({ target: { value } }) => updateDraftItem({ gradingCompany: value })}
					/>
					<Input
						id="grade"
						label="Grade"
						value={draftItem.grade}
						onChange={({ target: { value } }) => updateDraftItem({ grade: value })}
					/>
					<Input
						id="autographType"
						label="Autograph Type"
						value={draftItem.autographType}
						onChange={({ target: { value } }) => updateDraftItem({ autographType: value })}
					/>
				</div>
			</div>
		</ActionModal>
	);
};

export default EditFixedPriceItemModal;

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import CurrencyCell from '../SortableTable/cells/CurrencyCell/CurrencyCell';
import CollectionsStatusChip from '../statusChips/CollectionsStatusChip/CollectionsStatusChip';
import InvoiceStatusChip from '../statusChips/InvoiceStatusChip/InvoiceStatusChip';
import RadioButton from '../RadioButton/RadioButton';
import SortableTable from '../SortableTable/SortableTable';
import { SortableTableParams } from '../SortableTable/SortableTable.model';
import { Auction } from '../../services/auctionsService';
import {
	InvoicesQueryType,
	KernelInvoice,
} from '../../services/invoicesService/invoicesService.model';
import {
	CheckoutOrderCollectionsStatus,
	InvoiceStatus,
} from '../../services/ordersService/ordersService.model';
import useAuctions from '../../queries/useAuctions/useAuctions';
import Label from '../Label/Label';
import RightAlignedHeader from '../SortableTable/headers/RightAlignedHeader';
import { getInvoiceColumnsByQueryType } from './InvoicesTable.utils';
import DateCell from '../SortableTable/cells/DateCell';
import useInvoicesV2 from '../../queries/useInvoicesV2';
import { formatName, stripUnderscores } from '../../utils/formatters';
import { noop } from 'lodash';
import { Button } from '../../prizm-ui/Button';
import DownloadCSVButton from '../DownloadCSVButton';
import { User } from '../../services/userService/userService.model';
import useUserById from '../../queries/useUserById';
import useAuctionById from '../../queries/useAuctionById';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../queries/queryKeys';
import { sendDraftInvoices } from '../../services/invoicesService';
import { showErrorToast, showSuccessToast } from '../Toast';
import InvoiceDetailsCard from '../../Pages/Auctions/WinnerSummary/InvoiceDetailsCard';
import { Dialog } from '@material-ui/core';
import { Username } from '../Username';
import { getTestIdentifier } from '../../utils/test-identifiers/getTestIdentifier';

const InvoicesTable = ({
	mode,
	auctionId = '',
	userId = '',
	defaultQueryType = 'All',
	onChangeQueryType = noop,
}: {
	auctionId?: string;
	mode: 'auction' | 'user';
	userId?: string;
	defaultQueryType?: InvoicesQueryType;
	onChangeQueryType?: (queryType: InvoicesQueryType) => void;
}) => {
	const [queryType, setQueryType] = useState<InvoicesQueryType>(defaultQueryType);

	useEffect(() => {
		onChangeQueryType(queryType);
	}, [queryType]);

	const { data: auctions = [] as Auction[] } = useAuctions();
	const { data: user = {} as User } = useUserById(userId);
	const { data: selectedAuction = {} as Auction } = useAuctionById(auctionId);

	const auctionsById = new Map(auctions.map((auction) => [String(auction.id), { ...auction }]));

	const [selectedLegacyInvoice, setSelectedLegacyInvoice] = useState<KernelInvoice>();
	const { data: auctionForLegacyInvoice = {} as Auction } = useAuctionById(
		selectedLegacyInvoice?.auction_id ?? ''
	);
	const [shouldShowLegacyInvoiceDetailsCard, setShouldShowLegacyInvoiceDetailsCard] =
		useState(false);

	const openLegacyInvoiceDetails = (invoiceId: string) => {
		setSelectedLegacyInvoice(invoices.find((invoice) => invoice.invoice_id === invoiceId));
		setShouldShowLegacyInvoiceDetailsCard(true);
	};
	const closeLegacyInvoiceDetails = () => {
		setSelectedLegacyInvoice(undefined);
		setShouldShowLegacyInvoiceDetailsCard(false);
	};

	const {
		data: invoices = [] as KernelInvoice[],
		status: invoicesStatus,
		refetch: refetchInvoices,
	} = useInvoicesV2({
		mode,
		auctionId,
		userId,
		queryType,
	});

	const ALL_COLUMNS: ColumnDef<KernelInvoice>[] = [
		{
			accessorKey: 'invoice_number',
			id: 'invoice_number',
			header: 'Invoice No.',
			accessorFn: ({ invoice_number }) => invoice_number.toString(),
			cell: ({
				getValue,
				row: {
					original: { uses_checkout, invoice_id },
				},
			}) => (
				<>
					{uses_checkout ? (
						<Link
							className="block link"
							data-testid={getTestIdentifier({
								componentName: 'InvoicesTable',
								descriptor: 'invoice-number-link',
								pagePath: 'winnersummary',
								sectionPath: 'auctions',
							})}
							to={`/orders/${getValue()}`}
							target="_blank"
						>
							{getValue() as string}
						</Link>
					) : (
						<button
							className="link"
							data-testid={getTestIdentifier({
								componentName: 'InvoicesTable',
								descriptor: 'invoice-number-button',
								pagePath: 'winnersummary',
								sectionPath: 'auctions',
							})}
							onClick={() => openLegacyInvoiceDetails(invoice_id)}
						>
							{getValue() as string}
						</button>
					)}
				</>
			),
		},
		{
			accessorKey: 'created_at',
			id: 'invoice_date',
			header: 'Invoice Date',
			enableGlobalFilter: false,
			cell: ({ getValue }) => <DateCell value={getValue() as string} shouldIncludeHours={false} />,
		},
		{
			id: 'customer_name',
			header: 'Customer Name',
			accessorKey: 'customer_name',
			cell: ({
				row: {
					original: { customer_name, winner_id },
				},
			}) => (
				<div className="w-48">
					<Username
						type="bidder"
						id={winner_id}
						fallback={customer_name}
						firstName={customer_name}
					/>
				</div>
			),
		},
		{
			id: 'customer_email',
			header: 'Email',
			accessorKey: 'customer_email',
		},
		{
			accessorFn: ({ auction_id }) =>
				auction_id === 'fixed_price_marketplace'
					? 'Fixed Price Marketplace'
					: auctionsById.get(auction_id)?.title,
			accessorKey: 'auction_id',
			id: 'auction_id',
			header: 'Auction',
			cell: ({ getValue }) => <div className="w-48">{getValue() as string}</div>,
		},
		{
			accessorKey: 'amount_due',
			id: 'amount_due',
			enableGlobalFilter: false,
			header: () => <RightAlignedHeader>Total Amount Due</RightAlignedHeader>,
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
		},
		{
			accessorKey: 'buyer_premium',
			id: 'buyer_premium',
			enableGlobalFilter: false,
			header: () => <RightAlignedHeader>{`Buyer's Premium`}</RightAlignedHeader>,
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
		},
		{
			accessorKey: 'amount_remaining',
			id: 'amount_remaining',
			enableGlobalFilter: false,
			header: () => <RightAlignedHeader>Amount Owed</RightAlignedHeader>,
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
		},
		{
			accessorKey: 'amount_paid',
			id: 'amount_paid',
			enableGlobalFilter: false,
			header: () => <RightAlignedHeader>Amount Received</RightAlignedHeader>,
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
		},
		{
			accessorKey: 'invoice_status',
			id: 'invoice_status',
			enableGlobalFilter: false,
			header: 'Status',
			cell: ({ getValue }) => <InvoiceStatusChip status={getValue() as InvoiceStatus} />,
		},
		{
			accessorKey: 'due_date',
			id: 'due_date',
			enableGlobalFilter: false,
			header: 'Due Date',
			cell: ({ getValue }) => <DateCell shouldIncludeHours={false} value={getValue() as string} />,
		},
		{
			header: 'Ship To',
			id: 'ship_to',
			accessorFn: ({ cost_metadata }) => {
				if (!cost_metadata) {
					return '';
				}

				const { address } = cost_metadata;

				if (!address) {
					return '';
				}

				const { city, country, state } = address;

				return [city, state, country].filter((token) => token && token).join(', ');
			},
			cell: ({ getValue }) => <div>{getValue()}</div>,
		},
		{
			accessorKey: 'date_shipped',
			id: 'date_shipped',
			enableGlobalFilter: false,
			header: 'Date Shipped',
			cell: ({ getValue }) => <DateCell shouldIncludeHours={false} value={getValue() as string} />,
		},
		{
			accessorKey: 'tracking_id',
			id: 'tracking_id',
			enableGlobalFilter: false,
			header: 'Tracking Number',
			cell: ({
				row: {
					original: { tracking_numbers = [] },
				},
			}) =>
				tracking_numbers.map((trackingNumber, index) => (
					<div className="w-48" key={trackingNumber}>
						{trackingNumber}
						{index < tracking_numbers.length - 1 ? ', ' : ''}
					</div>
				)),
		},
		{
			accessorKey: 'paid_date',
			id: 'paid_date',
			enableGlobalFilter: false,
			header: 'Payment Date',
			cell: ({ getValue }) => <DateCell shouldIncludeHours={false} value={getValue() as string} />,
		},
		{
			accessorKey: 'payment_type',
			id: 'payment_type',
			enableGlobalFilter: false,
			header: 'Payment Type',
			cell: ({ getValue }) => stripUnderscores(getValue() as string),
		},
		{
			accessorKey: 'invoice_sent_date',
			id: 'invoice_sent_date',
			enableGlobalFilter: false,
			header: () => 'Invoice Sent Date',
			cell: ({ getValue }) => <DateCell shouldIncludeHours={false} value={getValue() as string} />,
		},
		{
			accessorKey: 'stringified_reason',
			id: 'reason',
			enableGlobalFilter: false,
			header: 'Blocked Reason',
		},
		{
			accessorKey: 'comments',
			id: 'comments',
			enableGlobalFilter: false,
			header: 'Comments',
			cell: ({ getValue }) => <div className="w-48">{getValue()}</div>,
		},
		{
			accessorKey: 'collections_status',
			id: 'collections_status',
			enableGlobalFilter: false,
			header: () => <RightAlignedHeader>Collections Status</RightAlignedHeader>,
			cell: ({ getValue }) =>
				getValue() && (
					<div className="text-right">
						<CollectionsStatusChip status={getValue() as CheckoutOrderCollectionsStatus} />
					</div>
				),
		},
	];

	const columns = getInvoiceColumnsByQueryType({
		queryType,
		mode,
		columns: ALL_COLUMNS,
	});

	const tableParams: SortableTableParams<KernelInvoice> = {
		columns,
		data: invoices as KernelInvoice[],
		getRowId: ({ invoice_id }) => invoice_id,
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 50,
			},
		},
	};

	const { mutate: doSendDraftInvoices, status: sendDraftInvoicesStatus } = useMutation({
		mutationKey: [QueryKeys.SendDraftInvoices, auctionId],
		mutationFn: () => sendDraftInvoices(auctionId),
	});

	const handleSendDraftInvoices = () =>
		doSendDraftInvoices(undefined, {
			onSuccess: () => {
				refetchInvoices();
				showSuccessToast('Draft invoices sent successfully');
			},
			onError: () =>
				showErrorToast(
					'Failed to send draft invoices. If the issue persists, please notify the #mvp-cs-channel.'
				),
		});

	return (
		<div className="mt-8">
			<div className="mb-4">
				<Label className="mb-4">Invoice Status</Label>
				<div className="flex">
					<RadioButton
						className="mr-4"
						label="All"
						name="searchByFilters"
						value="All"
						checked={queryType === 'All'}
						onClick={() => setQueryType('All')}
					/>
					<RadioButton
						className="mr-4"
						label="Blocked"
						name="searchByFilters"
						value="Blocked"
						checked={queryType === 'Blocked'}
						onClick={() => setQueryType('Blocked')}
					/>
					<RadioButton
						checked={queryType === 'Awaiting_Payment'}
						className="mr-4"
						label="Awaiting Payment"
						name="searchByFilters"
						value="Awaiting_Payment"
						onClick={() => setQueryType('Awaiting_Payment')}
					/>
					<RadioButton
						checked={queryType === 'Payment_Failed'}
						className="mr-4"
						label="Payment Failed"
						name="searchByFilters"
						value="Payment_Failed"
						onClick={() => setQueryType('Payment_Failed')}
					/>
					<RadioButton
						checked={queryType === 'Payment_Received'}
						className="mr-4"
						label="Payment Received"
						name="searchByFilters"
						value="Paid"
						onClick={() => setQueryType('Payment_Received')}
					/>
					<RadioButton
						checked={queryType === 'Past_Due'}
						className="mr-4"
						label="Past Due"
						name="searchByFilters"
						value="Past_Due"
						onClick={() => setQueryType('Past_Due')}
					/>
					<RadioButton
						checked={queryType === 'Shipped'}
						className="mr-4"
						label="Shipped"
						name="searchByFilters"
						value="Shipped"
						onClick={() => setQueryType('Shipped')}
					/>
				</div>

				<div className="mt-4 flex justify-between">
					<div>
						{queryType === 'Pending_Approval' && mode === 'auction' && (
							<Button
								size="md"
								variant="primary"
								trailingIcon="Send"
								disabled={invoices.length === 0}
								loading={sendDraftInvoicesStatus === 'loading'}
								onClick={handleSendDraftInvoices}
							>
								Send Draft Invoices
							</Button>
						)}
					</div>
					<DownloadCSVButton
						columnNames={columns.map(({ id }) => id) as string[]}
						items={invoices}
						kind="secondary"
						disabled={invoices.length === 0}
						documentName={
							mode === 'user'
								? `${stripUnderscores(queryType)} Invoices for ${
										(formatName(user.givenName), user.familyName)
								  }`
								: `${stripUnderscores(queryType)} Invoices for ${selectedAuction.title}`
						}
					/>
				</div>
			</div>
			<SortableTable tableParams={tableParams} isLoading={invoicesStatus === 'loading'} />
			{shouldShowLegacyInvoiceDetailsCard && selectedLegacyInvoice && (
				<Dialog
					open={shouldShowLegacyInvoiceDetailsCard}
					onClose={closeLegacyInvoiceDetails}
					fullWidth
					maxWidth="lg"
				>
					<InvoiceDetailsCard
						auction_id={selectedLegacyInvoice.auction_id}
						auction_title={auctionForLegacyInvoice.title}
						paid_date={selectedLegacyInvoice.paid_date as string}
						invoice_id={selectedLegacyInvoice.invoice_id}
						invoice_number={selectedLegacyInvoice.invoice_number}
					/>
				</Dialog>
			)}
		</div>
	);
};

export default InvoicesTable;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const splitLotsToNewOrder = async ({
	fromOrderNumber,
	lotIds,
}: {
	fromOrderNumber: string;
	lotIds: string[];
}) => {
	const body = { fromOrderNumber, lotIds };
	const response = await axiosAuth.put(`${apiEndpoints.checkout}/transfer_lots`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

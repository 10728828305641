import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const getSellerStatementPDF = async ({
	auctionId,
	statementId,
}: {
	auctionId: string;
	statementId: string;
}) => {
	const response = await axiosAuth.post(`${config.api.seller_statement_pdf}`, {
		auction_id: auctionId,
		seller_statement_id: statementId,
	});

	if (response.status >= 400) {
		throw response;
	}

	const binaryString = window.atob(response.data.body);
	const binaryStringLength = binaryString.length;
	const binaryStringBytes = new Uint8Array(binaryStringLength);

	for (let i = 0; i < binaryStringLength; i++) {
		binaryStringBytes[i] = binaryString.charCodeAt(i);
	}

	return new Blob([binaryStringBytes.buffer], {
		type: 'application/pdf',
	});
};

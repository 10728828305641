import { axiosAuth as axios } from './httpUtils';
import config from '../config';

/** @deprecated */
export async function fetchAuctions() {
	try {
		//console.log(await Auth.currentUserInfo());
		//console.log(await Auth.currentSession());
		const response = await axios.get(`${config.api.auctions}`);
		let result = JSON.parse(response.data);
		let auctions = [];
		result.map(function (auction) {
			let newAuction = auction;
			newAuction.Id = auction.auction_id;
			auctions.push(newAuction);
		});
		return auctions;
	} catch (err) {
		console.log(`Unable to fetch auctions: ${err}`);
		throw err;
	}
}

export async function getAuctionDashboard(auctionId) {
	try {
		const response = await axios.post(`${config.api.auctions}/dashboard`, {
			auction_id: auctionId,
		});
		return response.data;
	} catch (err) {
		console.log(`Unable to fetch auction dashboard: ${err}`);
		return [];
	}
}

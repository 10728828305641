import { getAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const getPickOrderSheetPdf = async (orderNumber: string) => {
	const response = await getAuthed(`${apiEndpoints.checkout}/pickordersheets/${orderNumber}/pdf`);

	if (response.status >= 400) {
		throw response;
	}

	const binaryString = window.atob(response.data);
	const binaryStringLength = binaryString.length;
	const binaryStringBytes = new Uint8Array(binaryStringLength);

	for (let i = 0; i < binaryStringLength; i++) {
		binaryStringBytes[i] = binaryString.charCodeAt(i);
	}

	return new Blob([binaryStringBytes.buffer], {
		type: 'application/pdf',
	});
};

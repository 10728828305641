import { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../components/ActionModal';
import { Textarea } from '../../../../../../prizm-ui/Textarea';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import {
	FixedPriceItem,
	KernelFixedPriceItemInternalNote,
} from '../../../../../../services/lotsService/lotsService.model';
import { CurrentUser } from '../../../../../../services/userService/userService.model';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import { updateLot } from '../../../../../../services/lotsService';
import useCurrentUser from '../../../../../../queries/useCurrentUser';
import useFixedPriceItem from '../../../../../../queries/useFixedPriceItem';

const AddInternalNotesModal = ({ onClose }: { onClose: () => void }) => {
	const { itemId = '' } = useParams<{ itemId: string }>();

	const { data: item = {} as FixedPriceItem, updateFixedPriceItem: updateFixedPriceItemInCache } =
		useFixedPriceItem(itemId);

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const [newNote, setNewNote] = useState('');

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationFn: (newInternalNotes: KernelFixedPriceItemInternalNote) =>
			updateLot({ lot_id: itemId, internal_notes: newInternalNotes }),
		mutationKey: [QueryKeys.UpdateLot, itemId, item.internalNotes],
	});

	const handleConfirm = () => {
		const newInternalNotes: KernelFixedPriceItemInternalNote = {
			author: {
				author_display_name: currentUser.currentUserFullName,
				author_id: currentUser.currentUserId,
			},
			timestamp: new Date().toISOString(),
			text: newNote,
		};

		doUpdateLot(newInternalNotes, {
			onError: () => {
				showErrorToast(
					'Something went wrong while saving the internal note. Please refresh and try again.'
				);
			},
			onSuccess: () => {
				onClose();

				showSuccessToast('Successfully saved internal note.');

				updateFixedPriceItemInCache({
					internalNotes: {
						author: {
							authorDisplayName: newInternalNotes.author?.author_display_name ?? 'Unknown',
							authorId: newInternalNotes.author?.author_id ?? '',
						},
						text: newInternalNotes.text,
						timestamp: newInternalNotes.timestamp,
					},
				});
			},
		});
	};

	return (
		<ActionModal
			title="Add Comment"
			confirmButtonLabel="Save"
			isLoading={updateLotStatus === 'loading'}
			onCancel={onClose}
			onClose={onClose}
			onConfirm={handleConfirm}
		>
			<Textarea
				autoFocus
				defaultValue={item.internalNotes.text}
				id="fixed-price-item-add-internal-note"
				label=""
				onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => setNewNote(value)}
			/>
		</ActionModal>
	);
};

export default AddInternalNotesModal;

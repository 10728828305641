import { FieldInfo, Parser } from 'json2csv';
import downloadFile from 'js-file-download';
import { isEmpty } from 'lodash';
import { Button } from '../../prizm-ui/Button';

const DownloadCSVButton = ({
	className = '',
	columnNames,
	disabled = false,
	documentName = 'Exported Data',
	items,
	kind = 'secondary',
}: {
	className?: string;
	columnNames: string[] | FieldInfo<unknown>[];
	disabled?: boolean;
	documentName?: string;
	items: unknown;
	kind?: 'primary' | 'secondary';
}) => {
	const jsonParser = new Parser({ eol: '\n', fields: columnNames });
	const onClick = () =>
		downloadFile(jsonParser.parse(items as { [key: string]: unknown }), `${documentName}.csv`);

	return (
		<Button
			className={className}
			disabled={disabled || isEmpty(items)}
			variant={kind}
			onClick={onClick}
			size="md"
			trailingIcon="ArrowCircleDown"
		>
			Download CSV
		</Button>
	);
};

export default DownloadCSVButton;

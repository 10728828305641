import useInvoices from '../useInvoices';

const useInvoiceById = ({ auctionId, invoiceId }: { auctionId: string; invoiceId: string }) => {
	const baseQuery = useInvoices({
		auctionId,
		filters: { invoice_id: invoiceId },
	});
	const { data = { invoices: [] } } = baseQuery;

	// @ts-expect-error
	const [invoice] = data.invoices;

	return { ...baseQuery, data: invoice };
};

export default useInvoiceById;

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuctionPicker from '../../../components/AuctionPicker';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Chip from '../../../components/Chip';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useAuctions from '../../../queries/useAuctions';
import useListOfLots from '../../../queries/useListOfLots';
import { Auction } from '../../../services/auctionsService';
import { stripUnderscores } from '../../../utils/formatters';
import { canTakeAction, getSentimentForAuctionStatus } from './AuctionPublisher.utils';
import EditAuctionDatesModal from './EditAuctionDatesModal';
import useDecision from '../../../optimizely/useDecision';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import AuctionSchedule from '../../../components/AuctionSchedule';
import { FeatureFlags } from '../../../optimizely/useDecision/useDecision.model';

const AuctionPublisher = () => {
	const { auctionId = '' } = useParams<{ auctionId?: string }>();
	const history = useHistory();
	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();

	useEffect(() => {
		if (!auctionId && auctions.length > 0) {
			const [firstAuction = {} as Auction] = auctions;
			history.push(`/auctions/AuctionPublisher/${firstAuction?.id}`);
		}
	}, [auctions.length]);

	const thisAuction = auctions.find(({ id }) => id === auctionId) || ({} as Auction);

	const { data: listOfLots = { total: undefined }, status: listOfLotsStatus } = useListOfLots({
		params: {
			auctionId,
			kind: 'assigned',
		},
	});

	const [shouldShowEditAuctionDatesModal, setShouldShowEditAuctionDatesModal] = useState(false);

	const shouldShowNewAuctionPicker = useDecision(FeatureFlags.NewAuctionPicker);

	return (
		<Card>
			<h1 className="text-display4">Auction Publisher</h1>
			<LoadingWrapper queryStatuses={[auctionsStatus, listOfLotsStatus]}>
				{shouldShowNewAuctionPicker && (
					<div className="flex flex-col lg:flex-row">
						<h2 className="text-display5 max-w-md xl:max-w-2xl">
							{auctionId ? thisAuction.title : 'No auction selected'}
						</h2>
						{auctionId && (
							<div className="flex-grow-0 mt-2 lg:ml-2">
								<Chip sentiment={getSentimentForAuctionStatus(thisAuction.status)}>
									{stripUnderscores(thisAuction.status)}
								</Chip>
							</div>
						)}
					</div>
				)}
				<div className="mt-4">
					<div className="flex">
						<div className="w-3/4 sm:w-auto">
							{shouldShowNewAuctionPicker ? (
								<NewAuctionPicker
									selectedAuctionId={auctionId}
									setSelectedAuctionId={(selectedAuctionId: string) =>
										history.push(`/auctions/AuctionPublisher/${selectedAuctionId}`)
									}
									shouldShowUnassignedLots={false}
								/>
							) : (
								<AuctionPicker
									selectedAuctionId={auctionId}
									setSelectedAuctionId={(selectedAuctionId: string) =>
										history.push(`/auctions/AuctionPublisher/${selectedAuctionId}`)
									}
									shouldShowUnassignedLots={false}
								/>
							)}
						</div>
						{!shouldShowNewAuctionPicker && (
							<div className="flex-grow-0 mt-8 ml-2 lg:ml-0">
								<Chip sentiment={getSentimentForAuctionStatus(thisAuction.status)}>
									{stripUnderscores(thisAuction.status)}
								</Chip>
							</div>
						)}
					</div>
					{auctionId && canTakeAction({ type: 'edit', status: thisAuction.status }) && (
						<div className="mt-4 flex">
							<Button
								kind="secondary"
								onClick={() => history.push(`/auctions/${auctionId}/auction-manager`)}
							>
								Edit Auction Details
							</Button>
							<Button
								kind="secondary"
								className="ml-2"
								onClick={() => history.push(`/auctions/${auctionId}/inventory-manager`)}
							>
								Edit Lots ({listOfLots.total})
							</Button>
						</div>
					)}
				</div>
				{auctionId && (
					<>
						<div className="mt-8">
							<h2 className="text-display5">Schedule</h2>
							<AuctionSchedule
								previewTimestamp={thisAuction.previewTimestamp ?? ''}
								startTimestamp={thisAuction.startTimestamp ?? ''}
								endTimestamp={thisAuction.endTimestamp ?? ''}
							/>
							{canTakeAction({ type: 'edit', status: thisAuction.status }) && (
								<Button kind="secondary" onClick={() => setShouldShowEditAuctionDatesModal(true)}>
									Edit Dates
								</Button>
							)}
							{shouldShowEditAuctionDatesModal && (
								<EditAuctionDatesModal
									auction={thisAuction}
									onClose={() => setShouldShowEditAuctionDatesModal(false)}
								/>
							)}
						</div>
					</>
				)}
			</LoadingWrapper>
		</Card>
	);
};

export default AuctionPublisher;

import React, { useEffect, useState } from 'react';
import { CardBody, CardHeader, Row, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ListingDetailHeader from './ListingDetailHeader';
import Card from '../../../components/Card';
import LotWatchList from './LotWatchList';
import { ListingDetailTab, ListingDetailTabPath, LISTING_DETAIL_TABS } from './ListingDetail.model';
import { useHistory, useParams } from 'react-router-dom';
import useIndividualLot from '../../../queries/useIndividualLot';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { Auction } from '../../../services/auctionsService/auctionsService.model';
import useAuctions from '../../../queries/useAuctions';
import LoadingWrapper from '../../../components/LoadingWrapper';
import ManageIndividualLot from '../../../components/ManageIndividualLot';
import BidHistoryCardView from '../../../components/BidHistoryCard/BidHistoryCardView';
import useUserRole from '../../../queries/useUserRole/useUserRole';
import { UserRoleName } from '../../../services/userService/userService.model';

const ListingDetail = () => {
	const { data: userRole = 'Unknown' as UserRoleName } = useUserRole();

	const tabsToShow = LISTING_DETAIL_TABS.filter(({ allowedRoles }) =>
		allowedRoles.includes(userRole)
	);

	const [firstAllowedTab = {} as ListingDetailTab] = tabsToShow;

	const { lotId, tabPath = firstAllowedTab.path } = useParams<{
		lotId: string;
		tabPath?: ListingDetailTabPath;
	}>();

	const history = useHistory();

	useEffect(() => {
		if (tabsToShow.length === 0) {
			history.push('/');

			return;
		}

		const isUserAllowedTabAccess =
			tabsToShow.find(
				({ allowedRoles, path }) => path === tabPath && allowedRoles.includes(userRole)
			) !== undefined;

		if (!isUserAllowedTabAccess) {
			history.push(`/inventory/listings/${lotId}/${firstAllowedTab.path}`);

			return;
		}

		selectTabPath(tabPath);
	}, [tabPath]);

	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();

	const { data: lot = {} as KernelLot, status: individualLotStatus } = useIndividualLot(lotId);

	const [activeTabPath, setActiveTabPath] = useState<ListingDetailTabPath>('manage');

	const selectTabPath = (selectedTabPath: ListingDetailTabPath) => {
		if (activeTabPath !== selectedTabPath) {
			setActiveTabPath(selectedTabPath);

			history.push(`/inventory/listings/${lotId}/${selectedTabPath}`);
		}
	};

	return (
		<div className="mb-8">
			<ReactCSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<LoadingWrapper queryStatuses={[individualLotStatus, auctionsStatus]}>
					<>
						<div className="mb-4">
							<ListingDetailHeader
								title={lot.title}
								auctionName={auctions.find(({ id }) => id === lot.auction_id)?.title || ''}
								auctionId={lot.auction_id}
								status={lot.status}
								lotId={lotId}
							/>
						</div>
						<Card className="p-0">
							<CardHeader>
								<Nav>
									{tabsToShow.map(({ label, path }) => (
										<NavItem key={path}>
											<NavLink
												className={classnames({ active: activeTabPath === path })}
												onClick={() => {
													selectTabPath(path as ListingDetailTabPath);
												}}
											>
												{label}
											</NavLink>
										</NavItem>
									))}
								</Nav>
							</CardHeader>
							<CardBody>
								<div className="px-8 pt-4">
									{activeTabPath === 'manage' && <ManageIndividualLot />}
									{activeTabPath === 'bids' && (
										<Row>
											<BidHistoryCardView auctionId={lot.auction_id} />
										</Row>
									)}
									{activeTabPath === 'lot-watch-list' && <LotWatchList lotId={lot.lot_id} />}
								</div>
							</CardBody>
						</Card>
					</>
				</LoadingWrapper>
			</ReactCSSTransitionGroup>
		</div>
	);
};

export default ListingDetail;

import { Address as AddressType } from './Address.model';

const Address = ({
	firstName = '',
	lastName = '',
	line1 = '',
	line2 = '',
	city = '',
	state = '',
	postalCode = '',
	country = '',
}: Partial<AddressType>) => {
	return (
		<div>
			<div>{`${firstName} ${lastName}`}</div>
			<div>{line1}</div>
			{line2 && <div>{line2}</div>}
			<div>
				{city && <span>{`${city}, `}</span>}
				<span>{`${state} ${postalCode}`.trim()}</span>
			</div>
			<div>{country}</div>
		</div>
	);
};

export default Address;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelUser, User } from './userService.model';

export const getUser = async (userId: string): Promise<User> => {
	const response = await axiosAuth.post<{ body: { users: KernelUser[] } }>(
		apiEndpoints.bidder_info_actions,
		{
			keyword: userId,
		}
	);

	if (response.status >= 400) {
		throw response;
	}

	const users = response?.data?.body?.users ?? [];
	const mappedUsers: User[] = users.map((user) => ({
		biddingPower: user.bidding_power,
		companyId: user.company_id,
		commission: user.commission,
		consignmentManagerId: user.consignment_manager_id,
		creditLimit: user.credit_limit,
		isDisabled: user.disabled,
		isVip: user.is_vip ?? false,
		email: user.email,
		familyName: user.family_name,
		givenName: user.given_name,
		hasUSAddress: user.has_us_address,
		verifiedTin: user.verified_tin ?? false,
		latestVersion: user.latest_version,
		notes: user.notes ?? '',
		paymentAddress: {
			city: user.payment_address?.city || '',
			country: user.payment_address?.country || '',
			line1: user.payment_address?.line_1 || '',
			line2: user.payment_address?.line_2 || '',
			postalCode: user.payment_address?.postal_code || '',
			state: user.payment_address?.state || '',
		},
		phoneNumber: user.phone_number,
		registrationDate: user.registration_date,
		sendPaymentReminder: user.send_payment_reminder ?? true,
		shippingAddress: {
			city: user.shipping_address_city,
			country: user.shipping_address_country,
			line1: user.shipping_address_line1,
			line2: user.shipping_address_line2,
			postalCode: user.shipping_address_postal_code,
			state: user.shipping_address_state,
		},
		stripeCustomerId: user.stripe_customer_id,
		id: user.user_id,
		goldinAuthVersion: user.goldin_auth_version && Number(user.goldin_auth_version),
		cidMigratedOn: user.cid_migrated_on,
	}));

	return mappedUsers[0];
};

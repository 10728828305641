import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import useBulkLotReassignmentMutation from '../../../../../queries/useBulkLotReassignmentMutation';
import AuctionPicker from '../../../../../components/AuctionPicker';

const BulkAssignLotsToAuctionModal = ({
	onClose,
	bulkSelectedLotIds,
	setIsBulkEditMode,
	setBulkSelectedLotIds,
}: {
	onClose: () => void;
	bulkSelectedLotIds: string[];
	setIsBulkEditMode: (nextState: boolean) => void;
	setBulkSelectedLotIds: (nextState: string[]) => void;
}) => {
	const { auctionId } = useParams<{ auctionId: string }>();
	const [selectedAuctionId, setSelectedAuctionId] = useState(auctionId);

	const onReassignmentSuccess = () => {
		setIsBulkEditMode(false);
		setBulkSelectedLotIds([]);
		onClose();
	};

	const {
		isReady: canReassignLotsToAuction,
		mutate: handleUpdateLots,
		status: updateLotsStatus,
	} = useBulkLotReassignmentMutation({
		originalAuctionId: auctionId,
		destinationAuctionId: selectedAuctionId,
		lotIds: bulkSelectedLotIds,
		onSuccess: onReassignmentSuccess,
	});

	return (
		<ActionModal
			title="Bulk assign lots to auction"
			onClose={onClose}
			onConfirm={handleUpdateLots}
			canConfirm={canReassignLotsToAuction}
			isLoading={updateLotsStatus === 'loading'}
		>
			<AuctionPicker
				setSelectedAuctionId={setSelectedAuctionId}
				selectedAuctionId={selectedAuctionId}
			/>
		</ActionModal>
	);
};

export default BulkAssignLotsToAuctionModal;

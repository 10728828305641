import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../../../components/ActionModal';
import Label from '../../../../../../../../components/Label';
import LoadingWrapper from '../../../../../../../../components/LoadingWrapper';
import { showErrorToast, showSuccessToast } from '../../../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../../../queries/queryKeys';
import useOrderByNumber from '../../../../../../../../queries/useOrderByNumber';
import usePluralLots from '../../../../../../../../queries/usePluralLots';
import { splitLotsToNewOrder } from '../../../../../../../../services/ordersService';
import { CheckoutOrder } from '../../../../../../../../services/ordersService/ordersService.model';
import SplittableLot from './SplittableLot';

const SplitLotsToNewOrderModal = ({
	onClose,
	selectedLotId,
}: {
	onClose: () => void;
	selectedLotId: string;
}) => {
	const { orderNumber = '' } = useParams<{ orderNumber: string }>();
	const {
		data: order = {} as CheckoutOrder,
		status: orderStatus,
		updateOrder: updateOrderInCache,
	} = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const { lotIds = [] } = order;
	const { data: lots = [], status: lotsStatus } = usePluralLots(lotIds);

	const otherLots = lots.filter((lot) => lot.lot_id !== selectedLotId);

	const [lotIdsStagedForSplitting, setLotIdsStagedForSplitting] = useState([selectedLotId]);
	const lotWord = lotIdsStagedForSplitting.length === 1 ? 'Lot' : 'Lots';

	const handleStagingLotsForSplitting = (selectedLotId: string) => {
		if (lotIdsStagedForSplitting.includes(selectedLotId)) {
			const updatedLotIdsStagedForSplitting = lotIdsStagedForSplitting.filter(
				(lotId) => lotId !== selectedLotId
			);
			return setLotIdsStagedForSplitting(updatedLotIdsStagedForSplitting);
		}

		const updatedLotIdsStagedForSplitting = [...lotIdsStagedForSplitting, selectedLotId];
		setLotIdsStagedForSplitting(updatedLotIdsStagedForSplitting);
	};

	const { mutate: doSplitLotsToNewOrder, status: splitLotsToNewOrderStatus } = useMutation({
		mutationKey: [QueryKeys.SplitLotsToNewOrder, orderNumber, ...lotIdsStagedForSplitting],
		mutationFn: ({ fromOrderNumber, lotIds }: { fromOrderNumber: string; lotIds: string[] }) =>
			splitLotsToNewOrder({
				fromOrderNumber,
				lotIds,
			}),
	});

	const handleSplitLotsToNewOrder = () =>
		doSplitLotsToNewOrder(
			{
				fromOrderNumber: orderNumber,
				lotIds: lotIdsStagedForSplitting,
			},
			{
				onSuccess: () => {
					showSuccessToast(
						`Successfully split ${lotIdsStagedForSplitting.length} ${lotWord} onto a new invoice.`
					);
					updateOrderInCache({
						...order,
						lotIds: order.lotIds.filter((lotId) => !lotIdsStagedForSplitting.includes(lotId)),
					});
					onClose();
				},
				onError: () => {
					showErrorToast(
						`Something went wrong splitting ${lotWord} to a new order. Please try again later.`
					);
				},
			}
		);

	return (
		<ActionModal
			isDangerous={true}
			title="Split Lots to New Order"
			onClose={onClose}
			onConfirm={handleSplitLotsToNewOrder}
			confirmButtonLabel={`Split ${lotIdsStagedForSplitting.length} ${lotWord} Off`}
			canConfirm={lotIdsStagedForSplitting.length > 0}
			isLoading={splitLotsToNewOrderStatus === 'loading'}
		>
			<Label>Select Lots</Label>
			<LoadingWrapper queryStatuses={[orderStatus, lotsStatus]}>
				<div className="max-h-96 max-w-2xl overflow-y-auto">
					<SplittableLot
						lotId={selectedLotId}
						onClick={() => handleStagingLotsForSplitting(selectedLotId)}
						isStagedForSplitting={lotIdsStagedForSplitting.includes(selectedLotId)}
					/>
					{otherLots.map((lot) => (
						<SplittableLot
							lotId={lot.lot_id}
							onClick={() => handleStagingLotsForSplitting(lot.lot_id)}
							isStagedForSplitting={lotIdsStagedForSplitting.includes(lot.lot_id)}
							key={lot.lot_id}
						/>
					))}
				</div>
			</LoadingWrapper>
		</ActionModal>
	);
};

export default SplitLotsToNewOrderModal;

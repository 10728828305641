import { InvoicesQueryType } from '../../services/invoicesService/invoicesService.model';

/** INVOICE_COLUMN_NAMES are the unique values found in the array keys of INVOICE_COLUMNS_BY_QUERY_TYPE */
export const INVOICE_COLUMN_NAMES = [
	'invoice_number',
	'invoice_date',
	'auction_id',
	'customer_name',
	'customer_email',
	'amount_due',
	'buyer_premium',
	'amount_remaining',
	'amount_paid',
	'invoice_status',
	'ship_to',
	'date_shipped',
	'comments',
	'collections_status',
	'reason',
	'invoice_sent_date',
	'paid_date',
	'payment_type',
	'due_date',
	'tracking_id',
] as const;
// prettier-ignore
export type InvoiceColumn = typeof INVOICE_COLUMN_NAMES[number]; // ignoring bc conflicting rules between ESLint and Prettier failing builds

export const INVOICE_COLUMNS_BY_QUERY_TYPE: { [key in InvoicesQueryType]: InvoiceColumn[] } = {
	All: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'amount_remaining',
		'amount_paid',
		'invoice_status',
		'ship_to',
		'date_shipped',
		'comments',
		'collections_status',
	],
	Blocked: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'reason',
		'comments',
		'collections_status',
	],
	Pending_Approval: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'comments',
		'collections_status',
	],
	Awaiting_Payment: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'amount_remaining',
		'amount_paid',
		'invoice_sent_date',
		'comments',
		'collections_status',
	],
	Payment_Failed: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'amount_remaining',
		'amount_paid',
		'invoice_sent_date',
		'comments',
		'collections_status',
	],
	Payment_Received: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'amount_remaining',
		'amount_paid',
		'paid_date',
		'payment_type',
		'comments',
		'collections_status',
	],
	Past_Due: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'invoice_status',
		'due_date',
		'amount_due',
		'buyer_premium',
		'amount_remaining',
		'amount_paid',
		'paid_date',
		'payment_type',
		'comments',
		'collections_status',
	],
	Shipped: [
		'invoice_number',
		'invoice_date',
		'auction_id',
		'customer_name',
		'customer_email',
		'amount_due',
		'buyer_premium',
		'ship_to',
		'date_shipped',
		'tracking_id',
		'comments',
		'collections_status',
	],
};

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';

import { Card, CardBody, CardTitle } from 'reactstrap';
import {
	Button,
	Grid,
	FormControl,
	InputLabel,
	OutlinedInput,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			showDialog: false,
			showResetForm: false,
			showError: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	closeDialog = () => {
		this.setState({
			showDialog: false,
		});
	};

	handleSubmit = async () => {
		Auth.forgotPassword(this.state.email)
			.then(() => {
				this.setState({
					showDialog: true,
				});
			})
			.catch((err) => {
				console.error('Error: ' + JSON.stringify(err));
				this.setState({
					showError: true,
				});
			});
	};

	handleReset = async () => {
		Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.newPassword)
			.then(() => {
				this.setState({
					resetSuccess: true,
				});
			})
			.catch((err) => {
				console.error('Error: ' + JSON.stringify(err));
				this.setState({
					showError: true,
				});
			});
	};

	render() {
		return (
			<Layout isAuthenticated={false}>
				<div align="center" padding="20px">
					<h1 className="legacy-h1">Forgot Password</h1>
				</div>
				<div className="reset-form" align="center">
					<Card className="main-card mb-3">
						<CardTitle>Forgot Password</CardTitle>
						<CardBody className="reset-card">
							<Grid container spacing={2} alignItems="center" justifyContent="flex-start">
								<Grid item xs={12}>
									<FormControl className="mt-2 mb-2" variant="outlined">
										<InputLabel htmlFor="outlined-adornment-title" required={true}>
											Email
										</InputLabel>
										<OutlinedInput
											id="outlined-adornment-title"
											defaultValue=""
											labelWidth={50}
											required={true}
											type="email"
											onChange={(event) => {
												if (event && event.target) {
													this.setState({
														email: event.target.value,
													});
												}
											}}
										/>
										<Button
											className="d-inline-block btn-wide btn-shadow floating-footer-button m-1 p-2 text-center"
											variant="contained"
											color="primary"
											onClick={this.handleSubmit}
										>
											Send Verification Email
										</Button>
									</FormControl>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
					<Dialog open={this.state.showDialog}>
						<DialogContent>
							<DialogContentText>
								You will recieve an email with a verification code to enter below
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeDialog} color="primary">
								OK
							</Button>
						</DialogActions>
					</Dialog>
					<Card className="main-card mb-3" hidden={this.state.showResetForm}>
						<CardBody className="reset-card">
							<Grid container spacing={2} alignItems="center" justifyContent="flex-start">
								<Grid item xs={12}>
									<FormControl className="mt-2 mb-2" variant="outlined">
										<InputLabel htmlFor="outlined-adornment-code" required={true}>
											Verification Code
										</InputLabel>
										<OutlinedInput
											id="outlined-adornment-code"
											defaultValue=""
											labelWidth={50}
											type="number"
											required={true}
											onChange={(event) => {
												if (event && event.target) {
													this.setState({
														verificationCode: event.target.value,
													});
												}
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl className="mt-2 mb-2" variant="outlined">
										<InputLabel htmlFor="outlined-adornment-password" required={true}>
											New Password
										</InputLabel>
										<OutlinedInput
											id="outlined-adornment-password"
											defaultValue=""
											labelWidth={50}
											type="password"
											required={true}
											onChange={(event) => {
												if (event && event.target) {
													this.setState({
														newPassword: event.target.value,
													});
												}
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Button
										className="d-inline-block btn-wide btn-shadow floating-footer-button m-1 p-2 text-center"
										variant="contained"
										color="primary"
										onClick={this.handleReset}
									>
										Reset Password
									</Button>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
					<Dialog open={this.state.resetSuccess}>
						<DialogContent>
							<DialogContentText>Your password has been reset, you may now login</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button component={Link} to="/login" color="primary">
								Login
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={this.state.showError}>
						<DialogContent>
							<DialogContentText>Error, please try again or contact us for help</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() =>
									this.setState({
										showError: false,
									})
								}
								color="primary"
							>
								OK
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</Layout>
		);
	}
}

export default ForgotPassword;

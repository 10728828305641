import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { TeeShirtRateCard } from './rateCardService.model';

export const upsertRateCardForUser = async ({
	userId,
	teeShirtRateCard,
}: {
	userId: string;
	teeShirtRateCard: TeeShirtRateCard;
}) => {
	const response = await axiosAuth.post(`${apiEndpoints.rate_cards}/${userId}`, {
		auctionType: teeShirtRateCard.variant === 'auction' ? 'auction' : 'fixed_price_marketplace',
		itemType: 'all',
		rates: [
			{
				priceMin: 0,
				priceMax: 2499,
				commission: teeShirtRateCard.rates.xs ?? null,
				type: 'Default',
			},
			{
				priceMin: 2500,
				priceMax: 4999,
				commission: teeShirtRateCard.rates.sm ?? null,
				type: 'Default',
			},
			{
				priceMin: 5000,
				priceMax: 9999,
				commission: teeShirtRateCard.rates.md ?? null,
				type: 'Default',
			},
			{
				priceMin: 10000,
				priceMax: 249999,
				commission: teeShirtRateCard.rates.lg ?? null,
				type: 'Default',
			},
			{ priceMin: 250000, commission: teeShirtRateCard.rates.xl ?? null, type: 'Default' },
		],
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { Link, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import DownloadCSVButton from '../../../../components/DownloadCSVButton/DownloadCSVButton';
import InvoiceStatusChip from '../../../../components/statusChips/InvoiceStatusChip/InvoiceStatusChip';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import { InvoiceStatus } from '../../../../services/ordersService/ordersService.model';
import {
	SELLER_STATEMENT_REMOVED_LOTS_CSV_COLUMNS,
	SellerStatementDetailsRemovedLot,
} from '../../../../services/sellerStatementService';
import formatCurrency from '../../../../utils/formatters/formatCurrency/formatCurrency';

const SellerStatementRemovedLots = ({
	removedLots,
}: {
	removedLots: SellerStatementDetailsRemovedLot[];
}) => {
	const { sellerStatementNumber = '' } = useParams<{ sellerStatementNumber: string }>();

	const columns: ColumnDef<SellerStatementDetailsRemovedLot>[] = [
		{
			accessorKey: 'invoiceStatus',
			header: 'Invoice Status',
			cell: ({ getValue }) => <InvoiceStatusChip status={getValue() as InvoiceStatus} />,
		},
		{
			accessorKey: 'lotNumber',
			header: 'Lot #',
		},
		{
			accessorKey: 'serialNumber',
			header: 'Serial #',
			cell: ({
				getValue,
				row: {
					original: { lotId },
				},
			}) => (
				<Tooltip
					content={`View serial number ${getValue()}`}
					id={getValue() as string}
					placement="top"
					trigger="hover"
				>
					<Link className="block link" to={`/inventory/listings/${lotId}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: 'title',
			header: 'Title',
		},
		{
			header: 'Location',
			cell: ({
				row: {
					original: { bin, room },
				},
			}) => (bin && room ? `${room}, ${bin}` : 'Unknown'),
		},
		{
			accessorKey: 'finalPrice',
			header: 'Amount',
			cell: ({ getValue }) => formatCurrency(getValue() as number),
		},
		{
			header: 'Commission',
			cell: ({
				row: {
					original: { commission, finalPrice },
				},
			}) => formatCurrency(commission * finalPrice, '$0.00'),
		},
	];

	const sortableTableParams: SortableTableParams<SellerStatementDetailsRemovedLot> = {
		columns,
		data: removedLots,
		getRowId: ({ lotId }) => lotId,
	};

	return (
		<>
			<h2 className="text-display5">Removed Lots</h2>
			<div className="mt-4">
				<SortableTable tableParams={sortableTableParams} />
			</div>
			<div className="mt-4">
				<DownloadCSVButton
					items={removedLots}
					columnNames={SELLER_STATEMENT_REMOVED_LOTS_CSV_COLUMNS}
					documentName={`Removed lots Statement ${sellerStatementNumber}`}
				/>
			</div>
		</>
	);
};

export default SellerStatementRemovedLots;

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../prizm-ui/Button';
import Card from '../../../../../components/Card';
import AddInternalNotesModal from './AddInternalNotesModal';
import { formatDate } from '../../../../../utils/formatters';
import {
	FixedPriceItem,
	FixedPriceItemInternalNote,
} from '../../../../../services/lotsService/lotsService.model';
import useFixedPriceItem from '../../../../../queries/useFixedPriceItem';
import { Banner } from '../../../../../prizm-ui/Banner';

const InternalNotes = () => {
	const { itemId = '' } = useParams<{ itemId: string }>();

	const { data: item = {} as FixedPriceItem } = useFixedPriceItem(itemId);

	const [shouldShowAddInternalNotesModal, setShouldShowAddInternalNotesModal] = useState(false);

	const { internalNotes = {} as FixedPriceItemInternalNote } = item;

	return (
		<>
			<Card>
				<div className="flex flex-row justify-between items-center">
					<h2 className="text-display5">Comments</h2>
					{internalNotes.text && (
						<Button
							size="md"
							variant="secondary"
							trailingIcon="AddCircle"
							onClick={() => setShouldShowAddInternalNotesModal(true)}
						>
							Add Comment
						</Button>
					)}
				</div>
				{internalNotes.text ? (
					<div className="mt-4">
						<div className="text-caption1 text-neutral2dark">
							<span>{`${formatDate(internalNotes.timestamp)}`}</span>
							<span className="mx-1">-</span>
							<span className="text-neutral2dark uppercase">
								{internalNotes.author?.authorDisplayName}
							</span>
						</div>
						<p className="break-words text-body1">{internalNotes.text}</p>
					</div>
				) : (
					<div className="mt-2">
						<Banner
							variant="gray"
							icon="Info"
							title="There are no comments for this item yet"
							buttonText="Add Comment"
							onClick={() => setShouldShowAddInternalNotesModal(true)}
						/>
					</div>
				)}
			</Card>
			{shouldShowAddInternalNotesModal && (
				<AddInternalNotesModal onClose={() => setShouldShowAddInternalNotesModal(false)} />
			)}
		</>
	);
};

export default InternalNotes;

import React from 'react';
import { SearchContext } from './Search';

type SearchResultsProps = {
	children: React.ReactNode;
};

export const SearchResults = ({ children }: SearchResultsProps) => {
	const { count, id } = React.useContext(SearchContext);
	return (
		<>
			<div
				role="listbox"
				id={`${id}-results`}
				className={[
					'hidden',
					'absolute',
					'z-[1]',
					'top-0',
					'w-full',
					'overflow-hidden',
					'pt-12',
					'rounded-2xl',
					'shadow-elevation3',
					'transition',
					'group-focus-within:block',
				].join(' ')}
			>
				<div className="overflow-y-auto max-h-80 py-2 bg-inverted">{children}</div>
			</div>
			<div className="sr-only" role="status" aria-live="polite" aria-atomic>
				{`${count} result${count === 1 ? '' : 's'}`}
			</div>
		</>
	);
};

import { Pagination } from '../../types';

export interface GetCashAccountEntriesProps {
	category?: CashAccountCategoryFilter;
	userId: string;
	page?: number;
	pageSize?: number;
}

export type CashAccountCategoryFilter =
	| 'adjustment'
	| 'deposit'
	| 'pending'
	| 'purchase'
	| 'withdrawal';

export interface KernelCashAccountEntry {
	amountChanged: number;
	auctionId: string;
	auctionName: string;
	createdBy: {
		email: string;
		familyName: string;
		givenName: string;
		id: string;
	};
	currentBalance: number;
	description: string;
	internalNotes: string;
	invoiceId: string;
	invoiceNumber?: string;
	lotId: string;
	operationType: CashAccountCategoryFilter;
	sellerStatementId: string;
	sellerStatementNumber?: string;
	stripeTransactionId: string;
	timestamp: string;
	transactionId?: string;
	userId: string;
	withdrawalType: string;
}

export type CashAccountEntry = KernelCashAccountEntry;

export interface UpdateCashAccountProps {
	amount: number;
	auctionId?: string;
	category: string;
	description: string;
	internalNotes?: string;
	transactionId?: string;
	userId: string;
}

export interface CashAccountSummary {
	currentBalance: number;
	available: number;
	pendingTotal: number;
}

export const EMPTY_CASH_ACCOUNT_SUMMARY: CashAccountSummary = {
	currentBalance: 0,
	available: 0,
	pendingTotal: 0,
};

export interface CashAccountEntriesResponse extends Pagination {
	entries: KernelCashAccountEntry[];
}

import React from 'react';
import { Icon } from '../Icon';

export interface ModalHeaderProps {
	children?: React.ReactNode;
	onClose?: () => void;
	onReturn?: () => void;
}

export const ModalHeader = ({ children, onClose, onReturn }: ModalHeaderProps) => {
	return (
		<div>
			{onReturn && (
				<button
					type="button"
					aria-label="return"
					onClick={onReturn}
					className="absolute top-0 left-0 p-5 transition text-neutral3 hover:text-primary-500 focus:text-primary-500"
				>
					<Icon name="ChevronDown" rotate="cw" aria-hidden />
				</button>
			)}
			<div
				data-testid="modalheader"
				id="modal-header"
				className="flex w-full items-center justify-center px-16 py-5 text-body1 text-neutral1 text-center font-bold border-b border-neutralstroke2"
			>
				{children}
			</div>
			{onClose && (
				<button
					type="button"
					aria-label="close"
					onClick={onClose}
					className="absolute top-0 right-0 p-5 transition text-neutral3 hover:text-primary-500 focus:text-primary-500"
				>
					<Icon name="Dismiss" aria-hidden />
				</button>
			)}
		</div>
	);
};

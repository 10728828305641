import {
	CheckoutOrderComment,
	CheckoutOrderCommentKind,
} from '../../../../../services/ordersService/ordersService.model';
import { getUserInfo } from '../../../../../AdminUtils/userUtils';

export const assembleNewComments = async ({
	kind,
	previousComments = [],
	text,
}: {
	kind: CheckoutOrderCommentKind;
	previousComments: CheckoutOrderComment[];
	text: string;
}): Promise<CheckoutOrderComment[]> => {
	// @TODO leverage useEmployeeById or something so we don't need async logic
	const userInfo = await getUserInfo();

	return [
		...previousComments,
		{
			author: {
				authorDisplayName: userInfo?.currentUserFullName || 'unknown',
				authorId: userInfo?.currentUserId,
			},
			kind,
			text,
			timestamp: new Date().toISOString(),
		},
	];
};

import { SortDirection } from '../../components/TableHeader/TableHeader.model';
import { sortBy } from 'lodash';
import { removeSort, sortAscending, sortDescending } from './sortByColumnReducer';
import { SortableColumnStateItem, SortByColumnAction } from './useSortByColumns.model';

export const getNextSortingAction = <T>({
	columnName,
	sortingState,
}: {
	columnName: keyof T;
	sortingState: SortableColumnStateItem<T>[];
}): SortByColumnAction<T> => {
	const currentStateForColumn = sortingState.find((columnConfig) => columnConfig[0] === columnName);

	if (!currentStateForColumn) {
		return sortAscending(columnName);
	}

	if (currentStateForColumn[1] === 'ascending') {
		return sortDescending(columnName);
	}

	return removeSort(columnName);
};

export const sortRows = <T>({
	rowsToSort,
	sortingConfigs,
}: {
	rowsToSort: T[];
	sortingConfigs: SortableColumnStateItem<T>[];
}): T[] => {
	let sortedRows = rowsToSort;

	sortingConfigs.forEach(([columnName, sortDirection]) => {
		if (sortDirection === 'ascending') {
			sortedRows = sortBy(sortedRows, (row) => row[columnName]);
		}
		if (sortDirection === 'descending') {
			sortedRows = sortBy(sortedRows, (row) => row[columnName]).reverse();
		}
	});

	return sortedRows;
};

export const _getSortDirectionForColumn = <T>({
	columnName,
	sortingConfigs,
}: {
	columnName: keyof T;
	sortingConfigs: SortableColumnStateItem<T>[];
}): SortDirection => {
	const currentStateForColumn = sortingConfigs.find(
		(columnConfig) => columnConfig[0] === columnName
	);

	if (!currentStateForColumn) {
		return 'none';
	}

	return currentStateForColumn[1];
};

import { useState } from 'react';
import { Button } from '../../../prizm-ui/Button';
import Card from '../../Card';
import LoadingWrapper from '../../LoadingWrapper';
import BidderLotURL from '../../BidderLotURL';
import DefinitionList from '../../DefinitionList';
import EditLotMetadataModal from './EditLotMetadataModal';
import RegenerateBidderUrlModal from './RegenerateBidderUrlModal';
import RestrictedArea from '../../RestrictedArea';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { BaseUser, EDITOR_ROLES } from '../../../services/userService/userService.model';
import useEmployeeById from '../../../queries/useEmployeeById';
import useIndividualLot from '../../../queries/useIndividualLot';

const LotMetadata = ({ lotId }: { lotId: string }) => {
	const [shouldShowConfirmRegenerateBidderURLModal, setShouldShowConfirmRegenerateBidderURLModal] =
		useState(false);

	const { data: currentLot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);

	const { data: createdByUser = {} as BaseUser, status: createdByUserStatus } = useEmployeeById(
		currentLot?.created_by
	);
	const createdByUserName =
		createdByUser.givenName && createdByUser.familyName
			? `${createdByUser.givenName} ${createdByUser.familyName}`
			: 'Unknown';
	const { data: modifiedByUser = {} as BaseUser, status: modifiedByUserStatus } = useEmployeeById(
		currentLot?.modified_by
	);
	const modifiedByUserName =
		modifiedByUser.givenName && modifiedByUser.familyName
			? `${modifiedByUser.givenName} ${modifiedByUser.familyName}`
			: 'Unknown';

	const [shouldShowEditLotMetadataModal, setShouldShowEditLotMetadataModal] = useState(false);

	return (
		<>
			<Card>
				<div className="flex justify-between">
					<h2 className="text-display5">Lot Metadata</h2>
					<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
						{currentLot.status !== 'Completed_Sold' && (
							<Button
								variant="text"
								size="md"
								icon="Edit"
								onClick={() => setShouldShowEditLotMetadataModal(true)}
							/>
						)}
						{shouldShowEditLotMetadataModal && (
							<EditLotMetadataModal
								lot={currentLot}
								onClose={() => setShouldShowEditLotMetadataModal(false)}
							/>
						)}
					</RestrictedArea>
				</div>
				<div>
					<LoadingWrapper queryStatuses={[lotStatus, createdByUserStatus, modifiedByUserStatus]}>
						<DefinitionList
							rows={[
								{ title: 'Serial Number', content: currentLot.serial_number ?? '-' },
								{ title: 'Cert Number', content: currentLot.cert_number ?? '-' },
								{ title: 'Contract ID', content: currentLot.contract_id ?? '-' },
								{ title: 'Created By', content: createdByUserName },
								{ title: 'Modified By', content: modifiedByUserName },
								{
									title: 'Bidder URL',
									content: <BidderLotURL auctionId={currentLot.auction_id} lotId={lotId} />,
								},
							]}
						/>

						{currentLot.meta_slug && currentLot.status !== 'Completed_Sold' && (
							<Button
								variant="secondary"
								size="md"
								onClick={() => setShouldShowConfirmRegenerateBidderURLModal(true)}
								className="my-4 w-full"
							>
								Regenerate Bidder URL
							</Button>
						)}
					</LoadingWrapper>
				</div>
			</Card>
			{shouldShowConfirmRegenerateBidderURLModal && (
				<RegenerateBidderUrlModal
					lotId={lotId}
					onClose={() => setShouldShowConfirmRegenerateBidderURLModal(false)}
				/>
			)}
		</>
	);
};

export default LotMetadata;

import slugify from 'slugify';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import { updateLot } from '../../../../services/lotsService';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import ActionModal from '../../../ActionModal';

const RegenerateBidderUrlModal = ({ lotId, onClose }: { lotId: string; onClose: () => void }) => {
	const { data: currentLot = {} as KernelLot, updateLot: updateLotInCache } =
		useIndividualLot(lotId);

	const [randomString] = uuidv4().split('-');
	const { title = '' } = currentLot;
	const clientGeneratedMetaSlug =
		slugify(title.slice(0, 70), { lower: true }) + '-' + randomString.slice(0, 5);

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, 'meta_slug', clientGeneratedMetaSlug],
		mutationFn: () => updateLot({ lot_id: lotId, meta_slug: clientGeneratedMetaSlug }),
	});

	const handleGenerateBidderSiteURL = () =>
		doUpdateLot(undefined, {
			onSuccess: (updatedLot: KernelLot) => {
				showSuccessToast('Successfully generated Bidder URL');
				updateLotInCache({ meta_slug: updatedLot.meta_slug ?? '' });
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong generating Bidder URL. If the issue persists, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			title="Regenerate Bidder URL"
			onClose={onClose}
			isDangerous={true}
			onConfirm={handleGenerateBidderSiteURL}
			isLoading={updateLotStatus === 'loading'}
		>
			Are you sure? Regenerating the URL will break all previously-sent links.
		</ActionModal>
	);
};

export default RegenerateBidderUrlModal;

import { isValidElement } from 'react';
import { PolymorphicComponentProps } from '../types';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';

export type BannerVariant = 'primary' | 'success' | 'warning' | 'gray';

type Props = {
	buttonText: string | JSX.Element;
	dark?: boolean;
	description?: string;
	external?: boolean;
	icon: IconName | JSX.Element;
	onClick?: () => void;
	title?: string;
	variant?: BannerVariant;
};

export type BannerProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

type BannerButtonComponent = <C extends React.ElementType = 'a'>(
	props: BannerProps<C>
) => React.ReactElement | null;

export const Banner: BannerButtonComponent = <C extends React.ElementType = 'a'>({
	as,
	buttonText,
	dark = false,
	description,
	external = false,
	icon,
	onClick,
	title,
	variant = 'primary',
	...rest
}: BannerProps<C>) => {
	const Component = as || 'button';

	const props = {
		...(external && rest.href && { target: '_blank', rel: 'noopener noreferrer' }),
		...rest,
	};

	const combination = `${variant}.${dark ? 'dark' : 'regular'}` as const;
	const bannerColors: Record<typeof combination, string> = {
		'gray.dark': 'bg-neutral3',
		'gray.regular': 'bg-neutralbkg2',
		'primary.dark': 'bg-primary-500',
		'primary.regular': 'bg-primary-25',
		'success.dark': 'bg-success-500',
		'success.regular': 'bg-success-25',
		'warning.dark': 'bg-warning-700',
		'warning.regular': 'bg-warning-25',
	};
	const iconColors: Record<typeof combination, string[]> = {
		'gray.dark': ['bg-neutral2', 'text-inverted'],
		'gray.regular': ['bg-neutralstroke2', 'text-neutral2'],
		'primary.dark': ['bg-primary-700', 'text-inverted'],
		'primary.regular': ['bg-primary-100', 'text-primary-500'],
		'success.dark': ['bg-success-700', 'text-inverted'],
		'success.regular': ['bg-success-100', 'text-success-500'],
		'warning.dark': ['bg-warning-900', 'text-inverted'],
		'warning.regular': ['bg-warning-100', 'text-warning-900'],
	};
	const textColors: Record<typeof combination, string> = {
		'gray.dark': 'text-inverted',
		'gray.regular': 'text-neutral2',
		'primary.dark': 'text-inverted',
		'primary.regular': 'text-primary-500',
		'success.dark': 'text-inverted',
		'success.regular': 'text-success-500',
		'warning.dark': 'text-inverted',
		'warning.regular': 'text-warning-900',
	};
	const buttonColors: Record<typeof combination, string[]> = {
		'gray.dark': [
			'bg-neutral1',
			'hover:bg-neutral1/0',
			'backdrop-brightness-0',
			'focus-visible:ring-neutral1',
			'focus-visible:ring-offset-neutral3',
		],
		'gray.regular': [
			'bg-neutral2',
			'hover:bg-neutral1',
			'focus-visible:ring-neutral2',
			'focus-visible:ring-offset-neutralbkg2',
		],
		'primary.dark': [
			'bg-primary-900',
			'hover:bg-primary-900/60',
			'backdrop-brightness-0',
			'focus-visible:ring-primary-900',
			'focus-visible:ring-offset-primary-500',
		],
		'primary.regular': [
			'bg-primary-500',
			'hover:bg-primary-700',
			'focus-visible:ring-primary-700',
			'focus-visible:ring-offset-primary-25',
		],
		'success.dark': [
			'bg-success-900',
			'hover:bg-success-900/25',
			'backdrop-brightness-50',
			'focus-visible:ring-success-900',
			'focus-visible:ring-offset-success-500',
		],
		'success.regular': [
			'bg-success-700',
			'hover:bg-success-900',
			'focus-visible:ring-success-900',
			'focus-visible:ring-offset-success-25',
		],
		'warning.dark': [
			'bg-warning-900/75',
			'hover:bg-warning-900/50',
			'backdrop-brightness-50',
			'focus-visible:ring-warning-900',
			'focus-visible:ring-offset-warning-700',
		],
		'warning.regular': [
			'bg-warning-900',
			'hover:bg-warning-900/75',
			'backdrop-brightness-0',
			'focus-visible:ring-warning-900',
			'focus-visible:ring-offset-warning-25',
		],
	};
	return (
		<div
			className={[
				'@container/banner',
				'flex',
				'gap-3',
				'items-center',
				'p-4',
				'w-full',
				'rounded-[20px]',
				bannerColors[combination],
			].join(' ')}
		>
			<div
				className={[
					'hidden',
					'@md/banner:flex',
					'shrink-0',
					'items-center',
					'justify-center',
					'w-12',
					'h-12',
					'rounded-full',
					...iconColors[combination],
				].join(' ')}
			>
				{typeof icon === 'string' && iconList.includes(icon) ? (
					<Icon name={icon} aria-hidden />
				) : (
					isValidElement(icon) && icon
				)}
			</div>
			<div className="flex gap-3 items-center w-full">
				<div className={['grow', 'flex', 'flex-col', 'gap-0.5', textColors[combination]].join(' ')}>
					{title && <span className="text-body1 font-semibold">{title}</span>}
					{description && <span className="text-body2 font-normal">{description}</span>}
				</div>
				{buttonText && (
					<Component
						{...props}
						{...(Component === 'button' && { type: 'button' })}
						className={[
							'shrink-0',
							'px-4',
							'py-2.5',
							'mt-0',
							'rounded-xl',
							'text-inverted',
							'text-body2',
							'whitespace-nowrap',
							'transition',
							'focus:outline-none',
							'focus-visible:ring-2',
							'focus-visible:ring-offset-2',
							...buttonColors[combination],
						].join(' ')}
						onClick={onClick}
						data-testid="action"
					>
						{buttonText}
					</Component>
				)}
			</div>
		</div>
	);
};

import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ConfirmationButtons from '../../../../components/ConfirmationButtons';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { Textarea } from '../../../../prizm-ui/Textarea';
import { QueryKeys } from '../../../../queries/queryKeys';
import { updateSellerStatement } from '../../../../services/sellerStatementService';

const SellerStatementPrivateComments = ({
	auctionId,
	incomingComments = '',
	statementId,
}: {
	auctionId: string;
	incomingComments?: string;
	statementId: string;
}) => {
	const [comments, setComments] = useState(incomingComments);
	useEffect(() => {
		if (incomingComments !== comments) {
			setComments(incomingComments);
		}
	}, [incomingComments]);

	const { mutate: doUpdateSellerStatementComments, status: updateSellerStatementCommentsStatus } =
		useMutation({
			mutationKey: [QueryKeys.UpdateSellerStatement, auctionId, statementId, { comments }],
			mutationFn: () => updateSellerStatement({ auctionId, statementId, deltas: { comments } }),
		});

	const queryClient = useQueryClient();
	const handleUpdateSellerStatementComments = () =>
		doUpdateSellerStatementComments(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully updated private comments.');
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementDetails]);
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementPDF]);
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating private comments. If the issue persists, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<>
			<h2 className="text-display5">Private Comments</h2>
			<Textarea
				className="mt-4"
				label=""
				value={comments}
				id="private-comments"
				onChange={({ target: { value } }) => setComments(value)}
			/>
			<ConfirmationButtons
				className="mt-4"
				confirmButtonLabel="Save Comments"
				disabled={comments === incomingComments}
				loading={updateSellerStatementCommentsStatus === 'loading'}
				onCancel={() => setComments(incomingComments ?? '')}
				onConfirm={handleUpdateSellerStatementComments}
			/>
		</>
	);
};

export default SellerStatementPrivateComments;

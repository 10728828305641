import { formatDate } from '../../../../utils/formatters';

const DateCell = ({
	value = '',
	shouldIncludeHours = true,
	canBeEmpty = true,
}: {
	value?: string;
	canBeEmpty?: boolean;
	shouldIncludeHours?: boolean;
}) => {
	const dateFormat = shouldIncludeHours ? undefined : 'P';
	if (canBeEmpty && !value) {
		return <></>;
	}

	return <div>{formatDate(value, { dateFormat })}</div>;
};

export default DateCell;

import useEmployeeById from '../../queries/useEmployeeById';
import useUserById from '../../queries/useUserById';
import { BaseUser } from '../../services/userService/userService.model';
import { Username } from './Username';

export const AsyncUsername = ({
	id,
	type,
	fallback = 'Unknown',
	className = '',
}: {
	className?: string;
	id: string;
	type: 'employee' | 'bidder';
	fallback?: string;
}) => {
	if (!id) {
		return <>{fallback}</>;
	}

	const employeeId = type === 'employee' ? id : '';
	const bidderId = type === 'bidder' ? id : '';

	const { data: employee = {} as BaseUser } = useEmployeeById(employeeId);
	const { data: bidder = {} as BaseUser } = useUserById(bidderId);

	const user = type === 'employee' ? employee : bidder;
	const { familyName = '', givenName = '' } = user;

	return (
		<Username
			id={id}
			type={type}
			fallback={fallback}
			className={className}
			firstName={givenName}
			lastName={familyName}
		/>
	);
};

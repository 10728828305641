import { useQuery } from 'react-query';
import { QueryKeys } from '../queryKeys';
import { getLatestCashAccountEntry } from '../../services/cashAccountsService';

const useLatestCashAccountEntry = (userId: string) =>
	useQuery([QueryKeys.GetLatestCashAccountEntry, userId], () => getLatestCashAccountEntry(userId), {
		enabled: !!userId,
	});

export default useLatestCashAccountEntry;

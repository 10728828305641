import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SellerStatementTable from '../../../../components/SellerStatementTable';
import SendPaymentReminderToggle from './SendPaymentReminderToggle';

const SellerStatementsTab = () => {
	const { userId } = useParams<{ userId: string }>();
	useState(false);

	return (
		<div className="max-w-full">
			<div className="mb-2">
				<SendPaymentReminderToggle />
			</div>
			<SellerStatementTable queryBy="sellerId" sellerId={userId} status="All" />
		</div>
	);
};

export default SellerStatementsTab;

import { AuctionStatus } from '../../../services/auctionsService';
import { Sentiment } from '../../../types';
import { AuctionPublisherAction } from './AuctionPublisher.model';

export const getSentimentForAuctionStatus = (auctionStatus: AuctionStatus): Sentiment => {
	if (auctionStatus === 'Active') {
		return 'success';
	}
	if (auctionStatus === 'Completed') {
		return 'warning';
	}
	return 'neutral';
};

export const canTakeAction = ({
	type,
	status,
}: {
	type: AuctionPublisherAction;
	status: AuctionStatus;
}): boolean => {
	if (type === 'edit' && status !== 'Completed') {
		return true;
	}

	if (['pause', 'complete'].includes(type)) {
		const stoppableStatuses: AuctionStatus[] = ['Active', 'Preview'];
		return stoppableStatuses.includes(status);
	}

	if (type === 'start') {
		const startableStatuses: AuctionStatus[] = ['Not_Started', 'Pause', 'Preview', 'Withdrawn'];
		return startableStatuses.includes(status);
	}

	return false;
};

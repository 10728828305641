import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelLot } from './lotsService.model';
import { getLotLocation } from './lotsService.utils';

export const getIndividualLot = async (lotId = ''): Promise<KernelLot> => {
	const query = {
		queryType: 'All',
		id: [lotId],
		from: 0,
		size: 1,
	};

	const response = await axiosAuth.post<{ lots: KernelLot[] }>(`${config.api.lots}`, query);

	if (response.status >= 400) {
		throw response;
	}

	const {
		data: { lots = [] },
	} = response;
	const [thisLot = {} as KernelLot] = lots;

	return { ...thisLot, location: getLotLocation(thisLot) };
};

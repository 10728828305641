import moment from 'moment';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { snakeObject } from '../../utils/formatters';
import {
	AuctionTimestampKey,
	AuctionTimestamps,
	AuctionTechnicalDetails,
} from './auctionsService.model';

export const updateAuctionSchedule = async ({
	auctionId,
	deltas = {},
	fieldsThatHaveNothingToDoWithAuctionScheduleButHitThatEndpointAnyway = {},
}: {
	auctionId: string;
	deltas?: AuctionTimestamps;
	fieldsThatHaveNothingToDoWithAuctionScheduleButHitThatEndpointAnyway?: Omit<
		AuctionTechnicalDetails,
		'commission'
	>;
}) => {
	const utcDeltas = Object.keys(deltas).reduce((accumulator, currentKey) => {
		const updatedAccumulator = {
			...accumulator,
			[currentKey]: moment(deltas[currentKey as AuctionTimestampKey])
				.utc()
				.format(),
		};
		return updatedAccumulator;
	}, {});
	const params = {
		id: auctionId,
		...snakeObject(utcDeltas),
		...fieldsThatHaveNothingToDoWithAuctionScheduleButHitThatEndpointAnyway,
	};
	const response = await axiosAuth.post(`${config.api.auctions}/update_schedule`, params);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import React from 'react';
import { Avatar, AvatarSize } from './Avatar';

export const AvatarGroupContext = React.createContext<{
	isGroup: boolean;
	groupSize?: AvatarSize;
}>({
	isGroup: false,
	groupSize: undefined,
});

export type AvatarGroupSize = Exclude<AvatarSize, 'lg' | 'xl'>;

export type AvatarGroupProps = {
	plus?: number;
	size?: AvatarGroupSize;
} & React.ComponentPropsWithoutRef<'div'>;

const getClasses = ({ size }: { size?: AvatarGroupSize }) => {
	const classes = ['flex'];

	switch (size) {
		case 'xs':
			classes.push('-space-x-1');
			break;
		case 'sm':
			classes.push('-space-x-2');
			break;
		case 'md':
			classes.push('-space-x-4');
			break;
	}

	return classes.join(' ');
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ children, plus, size = 'md' }) => {
	return (
		<AvatarGroupContext.Provider value={{ isGroup: true, groupSize: size }}>
			<div className={getClasses({ size })}>
				{children}
				{plus && <Avatar name={`+ ${plus}`} />}
			</div>
		</AvatarGroupContext.Provider>
	);
};

import { useQuery } from 'react-query';
import { Auction } from '../../services/auctionsService/auctionsService.model';
import getWatchlistForUser from '../../services/watchlistService/getWatchlistForUser';
import { QueryKeys } from '../queryKeys';

const useWatchlistForUser = ({ userId, auctions }: { userId: string; auctions: Auction[] }) =>
	useQuery(
		[QueryKeys.GetWatchlistByUser, userId],
		() => getWatchlistForUser({ userId, auctions }),
		{ enabled: auctions.length > 0 }
	);

export default useWatchlistForUser;

import { useQuery } from 'react-query';
import getBidsByUserForAuction from '../../services/bidderService/getBidsByUserForAuction';
import { QueryKeys } from '../queryKeys';

const useBidsByUserForAuction = ({ userId, auctionId }: { userId: string; auctionId: string }) =>
	useQuery(
		[QueryKeys.GetBidsByUserForAuction, userId, auctionId],
		() => getBidsByUserForAuction({ userId, auctionId }),
		{ enabled: !!userId && !!auctionId }
	);

export default useBidsByUserForAuction;

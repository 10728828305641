import React from 'react';
import { toast } from 'react-toastify';
import Toast, { Sentiment } from './Toast';

export const showToast = ({
	sentiment = 'neutral',
	message,
}: {
	sentiment?: Sentiment;
	message: string;
}) => {
	toast(<Toast sentiment={sentiment} message={message} />);
};

export const showSuccessToast = (message: string) => {
	showToast({ sentiment: 'success', message });
};
export const showErrorToast = (message: string) => {
	showToast({ sentiment: 'warning', message });
};

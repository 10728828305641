import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelUser } from './userService.model';

export const updateUser = async ({
	userId,
	fieldsToUpdate,
}: {
	userId: string;
	fieldsToUpdate: Partial<KernelUser>;
}) => {
	const response = await axiosAuth.post(`${config.api.update_user}`, {
		user_id: userId,
		payload: fieldsToUpdate,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../Card';
import LoadingWrapper from '../../LoadingWrapper';
import useEmployeeById from '../../../queries/useEmployeeById';
import useIndividualLot from '../../../queries/useIndividualLot';
import useUserById from '../../../queries/useUserById';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { User } from '../../../services/userService/userService.model';
import InterestedPartiesView from './InterestedPartiesView';

const InterestedParties = () => {
	const { lotId } = useParams<{ lotId: string }>();
	const { data: currentLot = {} as KernelLot, status: currentLotStatus } = useIndividualLot(lotId);
	const { data: currentSellerUser = {} as User, status: currentSellerUserStatus } = useUserById(
		currentLot.seller_id
	);
	const {
		data: currentConsigmentManagerUser = {} as User,
		status: currentConsigmentManagerUserStatus,
	} = useEmployeeById(currentLot.consignment_manager_id);

	return (
		<Card>
			<h2 className="text-display5">Interested Parties</h2>
			<div className="mt-4">
				<LoadingWrapper
					queryStatuses={[
						currentConsigmentManagerUserStatus,
						currentSellerUserStatus,
						currentLotStatus,
					]}
				>
					<InterestedPartiesView
						currentConsignmentManager={currentConsigmentManagerUser}
						currentSeller={currentSellerUser}
					/>
				</LoadingWrapper>
			</div>
		</Card>
	);
};

export default InterestedParties;

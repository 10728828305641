import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import NewAuctionPicker from '../../../../../components/NewAuctionPicker';
import { Auction } from '../../../../../services/auctionsService';
import useAuctionById from '../../../../../queries/useAuctionById';
import { getTestIdentifier } from '../../../../../utils/test-identifiers/getTestIdentifier';

const AuctionDashboardHeader = () => {
	const history = useHistory();

	const { auctionId = '' } = useParams<{ auctionId: string }>();

	const { data: auction = {} as Auction } = useAuctionById(auctionId);

	return (
		<Card className="mb-4">
			<h1 className="text-display4">Auction Dashboard</h1>
			<h2 className="text-display5 mb-4">{auction?.title ?? 'No Auction Selected'}</h2>
			<NewAuctionPicker
				selectedAuctionId={auctionId}
				setSelectedAuctionId={(selectedAuctionId) =>
					history.push(`/auctions/auction-dashboard/${selectedAuctionId}`)
				}
				testIdentifier={getTestIdentifier({
					componentName: 'AuctionDashboardHeader',
					descriptor: 'new-auction-picker-button',
					pagePath: 'auction-dashboard',
					sectionPath: 'auctions',
				})}
			/>
		</Card>
	);
};

export default AuctionDashboardHeader;

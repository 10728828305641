import React, { useState } from 'react';
import { NavSection, AdminNavItem } from '../SideBar.model';
import { ChevronRight20Regular } from '@fluentui/react-icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import NavItem from '../NavItem';
import { useSidebarContext } from '../../useShouldShowSidebar';
import useAuctions from '../../../../queries/useAuctions';
import { Auction } from '../../../../services/auctionsService';

const NavSection = ({ label, items = [] }: { label: NavSection; items?: AdminNavItem[] }) => {
	const { pathname } = useLocation();
	const { auctionId } = useParams<{ auctionId?: string }>();

	const isSectionSelected = items.some(({ getIsOpen }) => getIsOpen(pathname));
	const [isSectionOpen, setIsSectionOpen] = useState(isSectionSelected);

	const [firstItem = {} as AdminNavItem] = items;

	const { closeSidebarIfNarrowViewport } = useSidebarContext();

	const { data: auctions = [] } = useAuctions();
	const [firstAuction = {} as Auction] = auctions;

	return (
		<div
			className={`w-full py-2 ${
				isSectionSelected && items.length === 1 ? 'bg-cream' : 'bg-neutral1'
			}`}
		>
			{items.length > 1 ? (
				<button
					className={`flex w-full items-center justify-between px-8 text-left ${
						isSectionOpen ? 'pb-2' : ''
					}`}
					onClick={() => setIsSectionOpen(!isSectionOpen)}
				>
					<div className="mr-2 text-body1">{items.length === 1 ? firstItem?.label : label}</div>
					{items.length > 1 && (
						<ChevronRight20Regular
							className={`transform transition ${isSectionOpen ? 'rotate-90' : 'rotate-0'}`}
						/>
					)}
				</button>
			) : (
				<Link
					to={firstItem?.getPath(auctionId ?? firstAuction?.id)}
					className="block h-full w-full px-8 text-body1 hover:bg-neutral2 hover:text-neutral1 hover:no-underline"
					onClick={closeSidebarIfNarrowViewport}
				>
					{firstItem?.label}
				</Link>
			)}
			{isSectionOpen &&
				items.length > 1 &&
				items.map(({ label, getPath, key, getIsOpen }) => (
					<NavItem
						label={label}
						path={getPath(auctionId ?? firstAuction?.id)}
						key={key}
						getIsOpen={getIsOpen}
					/>
				))}
		</div>
	);
};

export default NavSection;

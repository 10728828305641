import React, { FC } from 'react';
import { Sentiment } from '../../types';

const sentimentClasses: { [key in Sentiment]: string } = {
	success: 'bg-success3 text-success2',
	neutral: 'bg-blue3 text-blue1',
	warning: 'bg-warning3 text-warning2',
};

const Chip: FC<{ sentiment: Sentiment }> = ({ sentiment, children }) => (
	<span
		className={`rounded-full  py-1 px-2 text-caption1 capitalize ${sentimentClasses[sentiment]}`}
	>
		{children}
	</span>
);

export default Chip;

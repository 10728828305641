import React, { useState } from 'react';
import { useMutation } from 'react-query';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import ActionModal from '../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import UploadCSV, { UploadCSVProps } from '../../../../../components/UploadCSV/UploadCSV';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useCurrentUser from '../../../../../queries/useCurrentUser';
import { bulkUploadLotAdjustments } from '../../../../../services/lotsService/bulkUploadLotAdjustments';
import { BulkLotAdjustment } from '../../../../../services/lotsService/lotsService.model';
import { CurrentUser } from '../../../../../services/userService/userService.model';

const BulkUploadLotAdjustmentsModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const [bulkImportFile, setBulkImportFile] = useState<File | null>(null);

	const [bulkUploadedLotAdjustments, setBulkUploadedLotAdjustments] = useState<BulkLotAdjustment[]>(
		[]
	);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { mutate: doBulkUploadLotAdjustments, status: bulkUploadLotAdjustmentsStatus } =
		useMutation({
			mutationKey: [QueryKeys.BulkUploadLotAdjustments],
			mutationFn: () =>
				bulkUploadLotAdjustments({
					bulkImportFile: bulkImportFile!,
					employeeId: currentUser.currentUserId,
				}),
		});

	const handleBulkUploadLotAdjustments = () => {
		if (bulkImportFile) {
			doBulkUploadLotAdjustments(undefined, {
				onSuccess: () => {
					showSuccessToast(
						`Submitted ${bulkUploadedLotAdjustments.length} lot adjustments for processing. Please wait a few minutes and refresh your browser to view the results.`
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting lots adjustments for processing. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkLotAdjustment> = {
		resultsColumns: [
			{ accessorKey: 'serial_number', header: 'Serial #' },
			{ accessorKey: 'category', header: 'Category' },
			{ accessorKey: 'description', header: 'Description' },
			{ accessorKey: 'adjustment_type', header: 'Adjustment Type' },
			{
				accessorKey: 'amount',
				header: 'Amount',
				cell: ({ getValue }) => (
					<div className="text-right">{formatCurrency(getValue() as number)}</div>
				),
			},
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkImportFile(uploadedFile);
			setBulkUploadedLotAdjustments(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkImportFile &&
		bulkUploadedLotAdjustments.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadLotAdjustmentsStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleBulkUploadLotAdjustments}
			isLoading={bulkUploadLotAdjustmentsStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Lot Adjustments"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadLotAdjustmentsModal;

import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Chip } from '../../../../prizm-ui/Chip';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import InvoiceStatusChip from '../../../../components/statusChips/InvoiceStatusChip/InvoiceStatusChip';
import SortableTable from '../../../../components/SortableTable';
import useAuctionById from '../../../../queries/useAuctionById';
import useSellerStatementDetails from '../../../../queries/useSellerStatementDetails';
import { Auction } from '../../../../services/auctionsService';
import { KernelInvoiceStatus } from '../../../../services/invoicesService/invoicesService.model';
import { getLotLocation, KernelLot } from '../../../../services/lotsService';
import { KernelSellerStatementDetails } from '../../../../services/sellerStatementService';
import formatCurrency from '../../../../utils/formatters/formatCurrency';

const SellerStatementLots = ({
	auctionId,
	lots,
	statementId,
}: {
	auctionId: string;
	lots: KernelLot[];
	statementId: string;
}) => {
	const lotsWithFormattedCommission: KernelLot[] = lots.map((lot) => {
		const { commission: commissionAsFullNumber, final_price: finalPrice } = lot;
		const commissionPercentage = commissionAsFullNumber * 0.01;
		const commissionDollarAmount = finalPrice * commissionPercentage;

		const formattedCommission =
			commissionDollarAmount >= 0
				? formatCurrency(commissionDollarAmount)
				: `(${formatCurrency(Math.abs(commissionDollarAmount))})`;

		return { ...lot, commissionDollarAmount: formattedCommission };
	});

	const columns: ColumnDef<KernelLot>[] = [
		{
			accessorKey: 'is_gpp',
			header: '',
			enableGlobalFilter: false,
			cell: ({
				getValue,
				row: {
					original: { invoice_status },
				},
			}) => {
				const isReadyForPayout = getValue() || invoice_status === 'Paid';

				return isReadyForPayout ? (
					<div className="w-32">
						<Chip variant="success">Ready for Payout</Chip>
					</div>
				) : (
					<></>
				);
			},
		},
		{
			accessorKey: 'invoice_status',
			header: 'Invoice Status',
			cell: ({ getValue }) => <InvoiceStatusChip status={getValue() as KernelInvoiceStatus} />,
		},
		{
			id: 'lot_number',
			accessorFn: ({ lot_number }) => lot_number?.toString() ?? '',
			header: 'Lot #',
			cell: ({ getValue }) => getValue(),
		},
		{
			accessorKey: 'serial_number',
			header: 'Serial #',
			cell: ({
				row: {
					original: { lot_id, serial_number },
				},
			}) => (
				<Tooltip content="View item details" id={serial_number} placement="top">
					<Link className="block link" to={`/inventory/listings/${lot_id}`}>
						{serial_number}
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: 'title',
			header: 'Title',
			cell: ({ getValue }) => <div className="min-w-120">{getValue()}</div>,
		},
		{
			id: 'location',
			header: 'Location',
			accessorFn: (lot) => getLotLocation(lot) ?? 'Unknown',
			enableGlobalFilter: false,
		},
		{
			accessorKey: 'final_price',
			header: 'Amount',
			enableGlobalFilter: false,
			cell: ({ getValue }) => formatCurrency(getValue() as number),
		},
		{
			accessorKey: 'commissionDollarAmount',
			header: 'Commission',
			enableGlobalFilter: false,
		},
	];

	const tableParams: SortableTableParams<KernelLot> = {
		columns,
		data: lotsWithFormattedCommission,
	};

	const {
		data: statementDetails = {} as KernelSellerStatementDetails,
		status: statementDetailsStatus,
	} = useSellerStatementDetails({
		auctionId,
		statementId,
	});

	const { data: auction = {} as Auction, status: auctionStatus } = useAuctionById(auctionId);

	const [firstLot = {} as KernelLot] = lotsWithFormattedCommission;
	const columnNames = ['is_gpp', ...Object.keys(firstLot)];
	const csvDocumentName = `Lots for Statement ${statementDetails.seller_statement_number} in Auction ${auction.title}`;

	return (
		<>
			<h2 className="text-display5">Lots</h2>
			<div className="mt-4">
				<SortableTable tableParams={tableParams} />
				<div className="mt-4">
					<DownloadCSVButton
						items={lotsWithFormattedCommission}
						columnNames={columnNames}
						documentName={csvDocumentName}
						disabled={[auctionStatus, statementDetailsStatus].includes('loading')}
					/>
				</div>
			</div>
		</>
	);
};

export default SellerStatementLots;

import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelUser, User } from '../userService/userService.model';

const getBiddersByKeyword = async (keyword: string): Promise<User[]> => {
	const response = await postAuthed<{
		body: {
			users: KernelUser[];
		};
	}>(apiEndpoints.bidder_info_actions, { keyword });

	if (response.status >= 400) {
		throw response;
	}

	const { users = [] as KernelUser[] } = response.data.body;

	const mappedUsers = users.map((user) => ({
		biddingPower: user.bidding_power,
		commission: user.commission || 0,
		companyId: user.company_id,
		consignmentManagerId: user.consignment_manager_id,
		creditLimit: user.credit_limit,
		email: user.email,
		familyName: user.family_name,
		givenName: user.given_name,
		hasUSAddress: user.has_us_address,
		id: user.user_id,
		isDisabled: user.disabled,
		latestVersion: user.latest_version,
		notes: user.notes,
		phoneNumber: user.phone_number || '',
		registrationDate: user.registration_date,
		verifiedTin: user.verified_tin || false,
		shippingAddress: {
			city: user.shipping_address_city,
			country: user.shipping_address_country,
			line1: user.shipping_address_line1,
			line2: user.shipping_address_line2,
			postalCode: user.shipping_address_postal_code,
			state: user.shipping_address_state,
		},
		stripeCustomerId: user.stripe_customer_id,
	}));

	return mappedUsers;
};

export default getBiddersByKeyword;

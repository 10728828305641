import React, { ReactElement } from 'react';

const ErrorMessage = ({ children }: { children: string | ReactElement }) => {
	return (
		<div
			className="bg-red-100 border-red-400 text-red-700 relative mt-2 rounded border px-4 py-3"
			role="alert"
		>
			{children}
		</div>
	);
};

export default ErrorMessage;

import { Chip } from '../../../../prizm-ui/Chip';
import { ALL_AUCTION_TYPES, AuctionType } from '../../../../services/auctionsService';

const AuctionTypeChips = ({
	selectedAuctionTypes,
	setSelectedAuctionTypes,
}: {
	selectedAuctionTypes: AuctionType[];
	setSelectedAuctionTypes: (selectedAuctionTypes: AuctionType[]) => void;
}) => {
	const toggleSelectedAuctionType = (auctionTypeToToggle: AuctionType) => {
		if (selectedAuctionTypes.includes(auctionTypeToToggle)) {
			const updatedSelectedAuctionTypes = selectedAuctionTypes.filter(
				(thisAuctionType) => thisAuctionType !== auctionTypeToToggle
			);
			return setSelectedAuctionTypes(updatedSelectedAuctionTypes);
		}

		return setSelectedAuctionTypes([...selectedAuctionTypes, auctionTypeToToggle]);
	};

	return (
		<div className="flex">
			{ALL_AUCTION_TYPES.map((auctionType) => (
				<div className="mr-2" key={auctionType}>
					<Chip
						as="button"
						onClick={() => toggleSelectedAuctionType(auctionType)}
						trailingIcon="Dismiss"
						variant={selectedAuctionTypes.includes(auctionType) ? 'primary' : 'gray'}
					>
						{auctionType}
					</Chip>
				</div>
			))}
		</div>
	);
};

export default AuctionTypeChips;

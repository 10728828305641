import { ArrowUndo20Regular } from '@fluentui/react-icons';
import React, { ChangeEventHandler, FC, useState } from 'react';
import Input from '../../../../../../../../components/Input';
import { useEditSummaryContext } from '../../useEditSummary';
import CurrencyValue from '../CurrencyValue';
import { LineItemProps } from '../LineItem.model';
import LineItemLayout from '../LineItemLayout';

interface EditableLineItemProps extends LineItemProps {
	name: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	onReset: () => void;
}

const EditableLineItem: FC<EditableLineItemProps> = ({
	kind,
	children,
	name,
	amount,
	onChange,
	onReset,
}) => {
	const [originalAmount] = useState(amount);
	const { isEditing } = useEditSummaryContext();
	return (
		<LineItemLayout kind={kind} label={children}>
			{isEditing ? (
				<Input
					name={name}
					value={amount || 0}
					className="mr-7 w-20 text-right"
					type="number"
					onChange={onChange}
				/>
			) : (
				<CurrencyValue amount={amount || 0} className="mr-8" />
			)}
			{isEditing && amount !== originalAmount && (
				<button
					className={`ml-2 ${amount === originalAmount ? 'cursor-not-allowed opacity-50' : ''}`}
					title="Reset"
					onClick={onReset}
					disabled={amount === originalAmount}
				>
					<ArrowUndo20Regular />
				</button>
			)}
		</LineItemLayout>
	);
};

export default EditableLineItem;

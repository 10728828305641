import {
	FixedPriceOrderSearchParams,
	FixedPriceOrderStatusType,
} from '../../../services/ordersService/ordersService.model';

export interface FixedPriceOrderQueryKindOption {
	label: string;
	value: FixedPriceOrderStatusType;
}

export const FIXED_PRICE_ORDER_STATUS: FixedPriceOrderQueryKindOption[] = [
	{
		label: 'Paid',
		value: 'Paid',
	},
	{
		label: 'Refunded',
		value: 'Refunded',
	},
];

export const DEFAULT_FIXED_PRICE_ORDER_SEARCH_PARAMS: FixedPriceOrderSearchParams = {
	searchQuery: '',
	searchType: 'query',
	status: 'Paid',
};

export type FixedPriceOrderSearchType = 'dateRange' | 'orderNumber' | 'winnerEmail';

import { pick } from 'lodash';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { INTERESTED_PARTIES_FIELDS } from './LotEditorView.model';

// Returns an empty string when everything is filled, otherwise a list of the missing fields
export const getTextContentForMissingRequiredFields = (lot: KernelLot): string => {
	let textContent = '';
	if (!lot.lot_number) textContent += (textContent.length > 1 ? ', ' : '') + 'Lot Number';
	if (!lot.title) textContent += (textContent.length > 1 ? ', ' : '') + 'Title';
	if (!lot.category) textContent += (textContent.length > 1 ? ', ' : '') + 'Category';
	if (!lot.sub_category) textContent += (textContent.length > 1 ? ', ' : '') + 'SubCategory';
	if (!lot.status) textContent += (textContent.length > 1 ? ', ' : '') + 'Status';
	if (!lot.seller_id) textContent += (textContent.length > 1 ? ', ' : '') + 'Seller';
	if (!lot.consignment_manager_id)
		textContent += (textContent.length > 1 ? ', ' : '') + 'Consignment Manager';
	if (!lot.min_bid_price)
		textContent += (textContent.length > 1 ? ', ' : '') + 'Minimum Opening Price';

	return textContent;
};

export const getChangedFields = ({
	draftLot,
	currentLot,
}: {
	draftLot: Partial<KernelLot>;
	currentLot: KernelLot;
}) => {
	const SCREENED_OUT_FIELDS = [INTERESTED_PARTIES_FIELDS, 'auction_id', 'description', 'title'];
	const relevantFields = Object.keys(draftLot).filter(
		(fieldName) => !SCREENED_OUT_FIELDS.includes(fieldName)
	);
	const changedFields = relevantFields.filter((key) => {
		const currentContent = currentLot[key as keyof KernelLot];
		const draftContent = draftLot[key as keyof KernelLot];

		return currentContent !== draftContent;
	});

	const fieldsToUpdate = pick(draftLot, changedFields);
	return fieldsToUpdate;
};

import config from '../../config';
import {
	KernelLotBidHistoryEntry,
	LotBidHistory,
	LotBidHistoryEntry,
	LotBidStatistics,
} from './getLotBidHistory.model';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const getLotBidHistory = async ({
	auctionId,
	lotId,
}: {
	auctionId: string;
	lotId: string;
}): Promise<LotBidHistory> => {
	const body = { auction_id: auctionId, lot_id: lotId };

	const response = await postAuthed<{
		body: {
			bid_history: KernelLotBidHistoryEntry[];
			time_remaining: string;
			total_bids: number;
			unique_bidders: number;
		};
		status: number;
	}>(`${config.api.bid_history}`, body);

	if (response.status >= 400) {
		throw response;
	}

	const {
		bid_history = [],
		time_remaining = '0 days, 0 hours, 0 minutes',
		total_bids = 0,
		unique_bidders = 0,
	} = response.data.body;

	const entries: LotBidHistoryEntry[] = bid_history.map((bid) => ({
		currentBid: bid.current_bid,
		email: bid.email,
		name: bid.name,
		timestamp: bid.timestamp,
		userId: bid.user_id,
	}));

	const statistics: LotBidStatistics = {
		timeRemaining: time_remaining,
		totalBids: total_bids,
		uniqueBidders: unique_bidders,
	};

	const lotBidHistory: LotBidHistory = {
		entries,
		statistics,
	};

	return lotBidHistory;
};

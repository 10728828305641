import { UserRoleName } from '../../../services/userService/userService.model';

export type ListingDetailTabLabel = 'Bids' | 'Manage Lot' | 'Watchers';

export type ListingDetailTabPath = 'bids' | 'lot-watch-list' | 'manage';

export interface ListingDetailTab {
	allowedRoles: UserRoleName[];
	label: ListingDetailTabLabel;
	path: ListingDetailTabPath;
}

export const LISTING_DETAIL_TABS: ListingDetailTab[] = [
	{
		allowedRoles: [
			'Admin',
			'AuctionManager',
			'Consignment',
			'CustomerSupport',
			'Editorial',
			'Finance',
			'Inventory',
			'Photography',
			'Shipping',
		],
		label: 'Manage Lot',
		path: 'manage',
	},
	{
		allowedRoles: ['Admin', 'AuctionManager', 'Consignment', 'CustomerSupport', 'Finance'],
		label: 'Bids',
		path: 'bids',
	},
	{
		allowedRoles: ['Admin', 'AuctionManager', 'Consignment', 'CustomerSupport', 'Finance'],
		label: 'Watchers',
		path: 'lot-watch-list',
	},
];

import { useState } from 'react';
import ActionModal from '../../../../components/ActionModal/ActionModal';
import {
	DraftEmployee,
	Employee,
	UserRoleName,
} from '../../../../services/userService/userService.model';
import { Input } from '../../../../prizm-ui/Input';
import { Option, Select } from '../../../../prizm-ui/Select';
import { QueryKeys } from '../../../../queries/queryKeys';
import { createEmployee } from '../../../../services/userService';
import { useMutation } from 'react-query';
import { capitalize, noop } from 'lodash';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import useEmployees from '../../../../queries/useEmployees/useEmployees';

export const CreateEmployeeModal = ({
	onClose,
	onSuccess,
}: {
	onClose: () => void;
	onSuccess: (newlyCreatedEmployee: DraftEmployee) => void;
}) => {
	const [draftEmployee, setDraftEmployee] = useState<DraftEmployee>({
		givenName: '',
		familyName: '',
		email: '',
		role: 'Unknown',
		password: '',
	});

	const USER_ROLE_OPTIONS: { value: UserRoleName; label: string }[] = [
		{ value: 'Shipping', label: 'Shipping' },
		{ value: 'AuctionManager', label: 'Auction Manager' },
		{ value: 'Finance', label: 'Finance' },
		{ value: 'Inventory', label: 'Inventory' },
		{ value: 'CustomerSupport', label: 'CustomerSupport' },
		{ value: 'Photography', label: 'Photography' },
		{ value: 'Editorial', label: 'Editorial' },
		{ value: 'Consignment', label: 'Consignment' },
	];

	const { mutate: doCreateEmployee, status: createEmployeeStatus } = useMutation({
		mutationKey: [QueryKeys.CreateEmployee, draftEmployee],
		mutationFn: () => createEmployee(draftEmployee),
	});

	const { addEmployee } = useEmployees();

	const handleCreateEmployee = () =>
		doCreateEmployee(undefined, {
			onSuccess: (newlyCreatedEmployee: Employee) => {
				showSuccessToast('Employee created successfully');
				addEmployee(newlyCreatedEmployee);
				onSuccess(draftEmployee);
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong creating the new employee. If the issue persists after refresh, please notify the #mvp-cs-channel'
				);
			},
		});

	const canCreateEmployee = !!(
		draftEmployee.givenName &&
		draftEmployee.familyName &&
		draftEmployee.email &&
		draftEmployee.role !== 'Unknown'
	);

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleCreateEmployee}
			title="Create Employee"
			canConfirm={canCreateEmployee}
			isLoading={createEmployeeStatus === 'loading'}
		>
			<div className="grid gap-4">
				<Input
					id="first-name"
					value={draftEmployee.givenName}
					onChange={(e) =>
						setDraftEmployee({
							...draftEmployee,
							givenName: e.target.value,
							password: e.target.value ? capitalize(`${e.target.value}@12345`) : 'Unset',
						})
					}
					label="First Name"
				/>
				<Input
					id="last-name"
					value={draftEmployee.familyName}
					onChange={(e) => setDraftEmployee({ ...draftEmployee, familyName: e.target.value })}
					label="Last Name"
				/>
				<Input
					id="email"
					value={draftEmployee.email}
					onChange={(e) => setDraftEmployee({ ...draftEmployee, email: e.target.value })}
					label="Email"
				/>

				<Select
					value={draftEmployee.role}
					onChange={(e) =>
						setDraftEmployee({
							...draftEmployee,
							role: e.target.value as UserRoleName,
						})
					}
					id="role"
					label="Role"
				>
					{USER_ROLE_OPTIONS.map(({ value, label }) => (
						<Option key={value} value={value}>
							{label}
						</Option>
					))}
				</Select>
				<Input
					id="password"
					value={draftEmployee.password}
					disabled={true}
					onChange={noop}
					label="Temporary Password"
				/>
			</div>
		</ActionModal>
	);
};

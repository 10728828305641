import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';

export const getUsersBulkUploadUrl = async () => {
	const response = await postAuthed(`${apiEndpoints.user_bulk_upload_url}`, {});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { useState } from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import { Banner } from '../../../../../prizm-ui/Banner';
import { QueryKeys } from '../../../../../queries/queryKeys';
import { bulkUploadLots } from '../../../../../services/lotsService';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import UploadCSV from '../../../../../components/UploadCSV';
import { UploadCSVProps } from '../../../../../components/UploadCSV/UploadCSV';
import { CurrentUser } from '../../../../../services/userService/userService.model';
import useCurrentUser from '../../../../../queries/useCurrentUser/useCurrentUser';

const BulkImportLotsModal = ({ onClose }: { onClose: () => void }) => {
	const [bulkUploadFile, setBulkUploadFile] = useState<File | null>(null);

	const [bulkUploadedLots, setBulkUploadedLots] = useState<KernelLot[]>([]);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const { mutate: doBulkUploadLots, status: bulkUploadLotsStatus } = useMutation({
		mutationKey: [QueryKeys.BulkUploadLots, new Date().toISOString()],
		mutationFn: (importedData: File) =>
			bulkUploadLots({
				bulkImportFile: importedData,
				employeeId: currentUser.currentUserId,
			}),
	});

	const handleBulkUploadLots = () => {
		if (bulkUploadFile) {
			doBulkUploadLots(bulkUploadFile, {
				onSuccess: () => {
					showSuccessToast(
						`Submitted ${bulkUploadedLots.length} lots for processing. Please wait a few minutes and refresh your browser to view the results.`
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting lots for processing. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<KernelLot> = {
		resultsColumns: [
			{
				accessorKey: 'lot_number',
				header: 'Lot Number',
			},
			{
				accessorKey: 'serial_number',
				header: 'Serial Number',
			},
			{
				accessorKey: 'title',
				header: 'Lot Title',
			},
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadFile(uploadedFile);
			setBulkUploadedLots(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadFile &&
		bulkUploadLots.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadLotsStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleBulkUploadLots}
			title="Bulk Import Lots"
			confirmButtonLabel={
				bulkUploadedLots.length === 0 ? 'Import Lots' : `Import ${bulkUploadedLots.length} Lots`
			}
			canConfirm={canConfirm}
			isLoading={bulkUploadLotsStatus === 'loading'}
		>
			<div className="mb-10">
				<Banner
					description="Please provide auction IDs for any new lots or lots that will be moved to another auction."
					icon="Info"
					variant="primary"
				/>
			</div>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkImportLotsModal;

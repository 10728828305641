import { noop } from 'lodash';
import { createContext, useContext } from 'react';

const bulkEditContext = createContext<{
	isBulkEditMode: boolean;
	setIsBulkEditMode: (isBulkEditMode: boolean) => void;
	bulkSelectedLotIds: string[];
	setBulkSelectedLotIds: (updatedBulkSelectedLotIds: string[]) => void;
}>({
	isBulkEditMode: false,
	setIsBulkEditMode: noop,
	bulkSelectedLotIds: [],
	setBulkSelectedLotIds: noop,
});

export const { Provider: BulkEditContextProvider } = bulkEditContext;

export const useBulkEditContext = () => useContext(bulkEditContext);

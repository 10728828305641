import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelLot } from './lotsService.model';
import { getLotLocation } from './lotsService.utils';

export const getPluralLots = async (lotIds: string[]): Promise<KernelLot[]> => {
	const query = {
		queryType: 'All',
		id: lotIds,
		from: 0,
		size: lotIds.length,
	};

	const response = await axiosAuth.post<{ lots: KernelLot[] }>(`${config.api.lots}`, query);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.lots.map((lot) => ({
		...lot,
		location: getLotLocation(lot),
	}));
};

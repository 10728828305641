import React from 'react';
import { SortableTableHeaderProps, TableHeaderProps } from './TableHeader.model';
import { ArrowSortUpRegular, ArrowSortDownRegular, ArrowSortRegular } from '@fluentui/react-icons';

const TableHeader = ({
	label,
	className,
	kind = 'static',
	...sortableTableHeaderProps
}: TableHeaderProps) => {
	if (kind === 'static') {
		return (
			<div className="w-full">
				<span className={`font-semibold ${className}`}>{label}</span>
			</div>
		);
	}

	const { onClick, sortDirection } = sortableTableHeaderProps as SortableTableHeaderProps;

	return (
		<button className={`w-full text-left ${className}`} onClick={onClick}>
			<span>{label}</span>
			{sortDirection === 'ascending' && (
				<span className="ml-1">
					<ArrowSortUpRegular />
				</span>
			)}
			{sortDirection === 'descending' && (
				<span className="ml-1">
					<ArrowSortDownRegular />
				</span>
			)}
			{sortDirection === 'none' && (
				<span className="ml-1">
					<ArrowSortRegular />
				</span>
			)}
		</button>
	);
};

export default TableHeader;

import React from 'react';

export interface ModalFooterProps {
	children: React.ReactNode;
}

export const ModalFooter = ({ children }: ModalFooterProps) => {
	return (
		<div
			role="contentinfo"
			className="flex flex-row-reverse justify-between px-4 sm:px-6 py-3 border-t border-neutralstroke2"
		>
			{children}
		</div>
	);
};

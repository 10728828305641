export interface ShippingDetail {
	tracking_id: string;
	Vendor: string;
}

export const SHIPPING_VENDOR_OPTIONS = [
	{
		label: 'Fedex',
		value: 'Fedex',
	},
	{
		label: 'UPS',
		value: 'UPS',
	},
	{
		label: 'USPS',
		value: 'USPS',
	},
];

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import ActionModal from '../../../../components/ActionModal';
import UploadCSV from '../../../../components/UploadCSV/UploadCSV';
import useCurrentUser from '../../../../queries/useCurrentUser';
import { CurrentUser } from '../../../../services/userService/userService.model';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { UploadCSVProps } from '../../../../components/UploadCSV/UploadCSV';
import { BulkInvoicePaymentsStatusCSV } from '../../../../services/invoicesService/invoicesService.model';
import { bulkUploadInvoicePaymentsStatus } from '../../../../services/invoicesService';

const BulkUploadInvoicePaymentsStatusModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const [bulkUploadInvoicePaymentsStatusFile, setBulkUploadInvoicePaymentsStatusFile] =
		useState<File | null>(null);
	const [bulkUploadInvoicePaymentsStatusData, setBulkUploadInvoicePaymentsStatusData] = useState<
		BulkInvoicePaymentsStatusCSV[]
	>([]);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const {
		mutate: bulkUploadInvoicePaymentsStatusMutation,
		status: bulkUploadInvoicePaymentsStatusStatus,
	} = useMutation({
		mutationKey: [QueryKeys.BulkUploadInvoicePaymentsStatus, bulkUploadInvoicePaymentsStatusFile],
		mutationFn: (bulkUploadInvoicePaymentsStatusFile: File) =>
			bulkUploadInvoicePaymentsStatus({
				bulkUploadInvoicePaymentsStatusFile,
				employeeId: currentUser.currentUserId,
			}),
	});

	const bulkUploadInvoicePaymentsStatusHandler = () => {
		if (bulkUploadInvoicePaymentsStatusFile) {
			bulkUploadInvoicePaymentsStatusMutation(bulkUploadInvoicePaymentsStatusFile, {
				onSuccess: () => {
					showSuccessToast(
						"Bulk invoice payments / status are processing. You'll receive an email when complete."
					);
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting bulk invoice payments / status for processing. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkInvoicePaymentsStatusCSV> = {
		resultsColumns: [
			{ accessorKey: 'invoice_number', header: 'Invoice #' },
			{ accessorKey: 'buyer_email', header: 'Buyer Email' },
			{ accessorKey: 'payment_method', header: 'Payment Method' },
			{ accessorKey: 'notes', header: 'Notes' },
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadInvoicePaymentsStatusFile(uploadedFile);
			setBulkUploadInvoicePaymentsStatusData(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadInvoicePaymentsStatusFile &&
		bulkUploadInvoicePaymentsStatusData.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadInvoicePaymentsStatusStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={bulkUploadInvoicePaymentsStatusHandler}
			isLoading={bulkUploadInvoicePaymentsStatusStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Invoice Payments / Status"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadInvoicePaymentsStatusModal;

import { axiosAuth as axios } from '../../AdminUtils/httpUtils';
import config from '../../config.js';
import { UserSearchResults } from './searchBidder.model';

const bidder_url = config.api.bidder_info_actions;

export const searchBidder = async (keyword: string) => {
	const response = await axios.post(bidder_url, {
		keyword: keyword,
	});

	if (response.status >= 400) {
		throw response;
	}

	return ((response?.data?.body?.users || []) as UserSearchResults[]).map((user) => ({
		id: user.user_id,
		givenName: user.given_name,
		familyName: user.family_name,
		email: user.email,
		biddingPower: user.bidding_power,
	}));
};

import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import CheckboxSelectionList from './CheckboxSelectionList';
import { CompareObjectsByKey, objNot, objIntersection, objUnion } from '../../../Utilities';

/* All options need to have these attributes:
    { id: <unique id for item>, name: <name of item>, description: <description to use as subtitle> }
*/
export default class TransferList extends Component {
	constructor(props) {
		super(props);

		this.numberOfChecked = this.numberOfChecked.bind(this);
		this.handleMoveCheckedRight = this.handleMoveCheckedRight.bind(this);
		this.handleMoveCheckedLeft = this.handleMoveCheckedLeft.bind(this);
		this.checkedUpdateCallback = this.checkedUpdateCallback.bind(this);

		let availableOptions = props.availableOptions || [];
		let selectedOptions = props.selectedOptions || [];

		if (props.sortBy) {
			availableOptions.sort((a, b) => CompareObjectsByKey(a, b, props.sortBy));
			selectedOptions.sort((a, b) => CompareObjectsByKey(a, b, props.sortBy));
		}

		this.state = {
			id: uuidv4(),
			availableKey: 1,
			availableTitle: props.availableTitle || 'Available',
			idField: props.idField || 'Id',
			left: availableOptions,
			leftChecked: [],
			right: selectedOptions,
			rightChecked: [],
			searchTerms: {},
			selectedKey: 1,
			selectedTitle: props.selectedTitle || 'Selected',
			sortBy: props.sortBy,
			updateSelectedCallback: props.updateSelectedCallback,
		};
	}

	componentDidMount() {
		// todo:load data from DB
	}

	numberOfChecked(items) {
		return objIntersection(this.state.checked, items, this.state.idField).length;
	}

	handleMoveCheckedRight() {
		const newRight = this.state.right.concat(this.state.leftChecked),
			newLeft = objNot(this.state.left, this.state.leftChecked, this.state.idField);

		if (this.state.sortBy) {
			newRight.sort((a, b) => CompareObjectsByKey(a, b, this.state.sortBy));
			newLeft.sort((a, b) => CompareObjectsByKey(a, b, this.state.sortBy));
		}

		this.setState({
			right: newRight,
			left: newLeft,
			leftChecked: [],
			availableKey: this.state.availableKey + 1,
			selectedKey: this.state.selectedKey + 1,
		});
	}

	handleMoveCheckedLeft() {
		const newRight = objNot(this.state.right, this.state.rightChecked, this.state.idField),
			newLeft = this.state.left.concat(this.state.rightChecked);

		if (this.state.sortBy) {
			newRight.sort((a, b) => CompareObjectsByKey(a, b, this.state.sortBy));
			newLeft.sort((a, b) => CompareObjectsByKey(a, b, this.state.sortBy));
		}

		this.setState({
			right: newRight,
			left: newLeft,
			rightChecked: [],
			availableKey: this.state.availableKey + 1,
			selectedKey: this.state.selectedKey + 1,
		});
	}

	checkedUpdateCallback(listKey, newChecked) {
		if (listKey == 'available') {
			this.setState({
				leftChecked: newChecked,
			});
		} else if (listKey == 'selected') {
			this.setState({
				rightChecked: newChecked,
			});
		}
	}

	render() {
		return (
			<Grid
				container
				spacing={2}
				alignItems="center"
				className="flex-grow-1 m-auto"
				justifyContent="flex-start"
			>
				<Grid item xs>
					<CheckboxSelectionList
						key={this.state.id + '-available-' + this.state.availableKey}
						title={this.state.availableTitle}
						items={this.state.left}
						checkedUpdateCallback={(newChecked) => {
							this.checkedUpdateCallback('available', newChecked);
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Grid container direction="column" justifyContent="center" alignItems="center">
						<span title="Add selected">
							<Button
								variant="outlined"
								size="small"
								onClick={() => {
									this.handleMoveCheckedRight();
									if (this.state.updateSelectedCallback)
										this.state.updateSelectedCallback(
											this.state.leftChecked
												? objUnion(this.state.right, this.state.leftChecked, this.state.idField)
												: this.state.right,
											{ additionalLotsToAddToThisAuction: this.state.leftChecked }
										);
								}}
								disabled={!this.state.leftChecked || this.state.leftChecked.length === 0}
								aria-label="move selected right"
								className="transfer-list-button"
								color="primary"
								endIcon={<KeyboardArrowRight />}
							>
								Add
							</Button>
						</span>
						<span title="Remove selected">
							<Button
								variant="outlined"
								size="small"
								onClick={() => {
									this.handleMoveCheckedLeft();
									if (this.state.updateSelectedCallback)
										this.state.updateSelectedCallback(
											this.state.rightChecked
												? objNot(this.state.right, this.state.rightChecked, this.state.idField)
												: this.state.right,
											{ additionalLotsToUnassign: this.state.rightChecked }
										);
								}}
								disabled={!this.state.rightChecked || this.state.rightChecked.length === 0}
								aria-label="move selected left"
								className="transfer-list-button"
								color="secondary"
								startIcon={<KeyboardArrowLeft />}
							>
								Remove
							</Button>
						</span>
					</Grid>
				</Grid>
				<Grid item xs>
					<CheckboxSelectionList
						key={this.state.id + '-selected-' + this.state.selectedKey}
						title={this.state.selectedTitle}
						items={this.state.right}
						checkedUpdateCallback={(newChecked) => {
							this.checkedUpdateCallback('selected', newChecked);
						}}
					/>
				</Grid>
			</Grid>
		);
	}
}

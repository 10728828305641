import { Link, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import SortableTable from '../../SortableTable';
import { SortableTableParams } from '../../SortableTable/SortableTable.model';
import { Lot } from '../../../services/lotsService/lotsService.model';
import { LotBidHistoryEntry } from '../../../services/lotsService/getLotBidHistory.model';
import formatCurrency from '../../../utils/formatters/formatCurrency';
import { formatDate } from '../../../utils/formatters';
import useIndividualLot from '../../../queries/useIndividualLot';
import { UserRoleName } from '../../../services/userService/userService.model';
import useUserRole from '../../../queries/useUserRole';

const BidHistoryCardTable = ({
	auctionId,
	entries,
}: {
	auctionId: string;
	entries: LotBidHistoryEntry[];
}) => {
	const { lotId = '' } = useParams<{ lotId: string }>();

	const { data: lot = {} as Lot } = useIndividualLot(lotId);

	const highestCurrentBid = Math.max(
		...entries.filter((entry) => entry.currentBid !== undefined).map((entry) => entry.currentBid)
	);

	const { data: userRole = '' as UserRoleName } = useUserRole();

	const canViewNames = ['Admin', 'CustomerSupport', 'Finance'].includes(userRole);

	const getBidderDisplayName = ({
		currentBid,
		name,
		userId,
	}: {
		currentBid: number;
		name: string;
		userId: string;
	}) => {
		if (canViewNames) {
			return name;
		}

		const isUnderBidder = !currentBid || currentBid < highestCurrentBid;

		if (isUnderBidder || !userId) {
			return '';
		}

		if (lot.status !== 'Completed_Sold') {
			return '';
		}

		return name;
	};

	const baseColumns: ColumnDef<LotBidHistoryEntry>[] = [
		{
			header: 'Bid Time',
			accessorFn: ({ timestamp }) => formatDate(timestamp),
		},
		{
			header: 'Bid Amount',
			accessorFn: ({ currentBid }) => formatCurrency(currentBid),
		},
	];

	const bidderNameColumn: ColumnDef<LotBidHistoryEntry> = {
		header: 'Bidder Name',
		accessorFn: ({ currentBid, name, userId }) =>
			getBidderDisplayName({ currentBid, name, userId }),
		cell: ({
			getValue,
			row: {
				original: { userId },
			},
		}) =>
			getValue() ? (
				<Link className="block link" to={`/usermanagement/customer/${userId}/bids/${auctionId}`}>
					{getValue() as string}
				</Link>
			) : (
				<></>
			),
	};

	const canSeeBidderDisplayNames = canViewNames || lot.status === 'Completed_Sold';
	const columns = canSeeBidderDisplayNames ? [...baseColumns, bidderNameColumn] : baseColumns;

	const sortableTableParams: SortableTableParams<LotBidHistoryEntry> = {
		columns,
		data: entries,
		getRowId: ({ timestamp }) => timestamp,
	};

	return (
		<div className="w-full">
			<SortableTable
				globalFilterPlaceholder="Filter results ..."
				key="bidding-history-entries-table"
				noResultsMessage="No bids to show."
				tableParams={sortableTableParams}
			/>
		</div>
	);
};

export default BidHistoryCardTable;

export enum QueryKeys {
	BulkUploadAdjustments = 'BULK_UPLOAD_ADJUSTMENTS',
	BulkUploadInvoicePaymentsStatus = 'BULK_UPLOAD_INVOICE_PAYMENTS_STATUS',
	BulkUpdateLots = 'BULK_UPDATE_LOTS',
	BulkUploadLotAdjustments = 'BULK_UPLOAD_LOT_ADJUSTMENTS',
	BulkUploadLots = 'BULK_UPLOAD_LOTS',
	BulkUploadPayouts = 'BULK_UPLOAD_PAYOUTS',
	BulkUploadStatementStatus = 'BULK_UPLOAD_STATEMENT_STATUS',
	BulkUploadUsers = 'BULK_UPLOAD_USERS',
	BulkUploadVaultCollection = 'BULK_UPLOAD_VAULT_COLLECTION',
	ChangeBuyer = 'CHANGE_BUYER',
	CreateAuction = 'CREATE_AUCTION',
	CreateEmployee = 'CREATE_EMPLOYEE',
	CreateLot = 'CREATE_LOT',
	CreateLotAdjustment = 'CREATE_LOT_ADJUSTMENT',
	CreateSellerStatementAdjustment = 'CREATE_SELLER_STATEMENT_ADJUSTMENT',
	CreateUser = 'CREATE_USER',
	DeactivateUser = 'DEACTIVATE_USER',
	DeleteAuction = 'DELETE_AUCTION',
	DeleteLotAdjustment = 'DELETE_LOT_ADJUSTMENT',
	DeleteSellerStatementAdjustment = 'DELETE_SELLER_STATEMENT_ADJUSTMENT',
	DeleteLots = 'DELETE_LOTS',
	EmailSellerStatementPDF = 'EMAIL_SELLER_STATEMENT_PDF',
	GetAuctions = 'GET_AUCTIONS',
	GetBidActivityForUser = 'GET_BID_ACTIVITY_FOR_USER',
	GetBiddersByKeyword = 'GET_BIDDERS_BY_KEYWORD',
	GetBidsByUserForAuction = 'GET_BIDS_BY_USER_FOR_AUCTION',
	GetCashAccountEntries = 'GET_CASH_ACCOUNT_ENTRIES',
	GetCashAccountSummary = 'GET_CASH_ACCOUNT_SUMMARY',
	GetConsignedLots = 'GET_CONSIGNED_LOTS',
	GetCurrentUser = 'GET_CURRENT_USER',
	GetEmployeeById = 'GET_EMPLOYEE_BY_ID',
	GetEmployees = 'GET_EMPLOYEES',
	GetFacets = 'GET_FACETS',
	GetFixedPriceOrders = 'GET_FIXED_PRICE_ORDERS',
	GetFixedPriceSellerStatements = 'GET_FIXED_PRICE_SELLER_STATEMENTS',
	GetImage = 'GET_IMAGE',
	GetImageUrl = 'GET_IMAGE_URL',
	GetIndividualFixedPriceItem = 'GET_INDIVIDUAL_FIXED_PRICE_ITEM',
	GetIndividualLot = 'GET_INDIVIDUAL_LOT',
	GetInvoiceDetails = 'GET_INVOICE_DETAILS',
	GetInvoices = 'GET_INVOICES',
	GetInvoicesByUser = 'GET_INVOICES_BY_USER',
	GetLatestCashAccountEntry = 'GET_LATEST_CASH_ACCOUNT_ENTRY',
	GetLayouts = 'GET_LAYOUTS',
	GetListOfLots = 'GET_LIST_OF_LOTS',
	GetLotBidHistory = 'GET_LOT_BIDDING_HISTORY',
	GetLotHistory = 'GET_LOT_HISTORY',
	GetLotsBySubset = 'GET_LOTS_BY_SUBSET',
	GetLotsForAuction = 'GET_LOTS_FOR_AUCTION',
	GetLotWatchers = 'GET_LOT_WATCHERS',
	GetNextWeeklyAuction = 'GET_NEXT_WEEKLY_AUCTION',
	GetOffersForItem = 'GET_OFFERS_FOR_ITEM',
	GetOrderByNumber = 'GET_ORDER_BY_NUMBER',
	GetOrderInvoicePDF = 'GET_ORDER_INVOICE_PDF',
	GetPaginatedLots = 'GET_PAGINATED_LOTS',
	GetPendingCashAccountEntries = 'GET_PENDING_CASH_ACCOUNT_ENTRIES',
	GetPickOrderSheet = 'GET_PICK_ORDER_SHEET',
	GetPluralLots = 'GET_PLURAL_LOTS',
	GetRateCardsForUser = 'GET_RATE_CARDS_FOR_USER',
	GetSellerStatementDetails = 'GET_SELLER_STATEMENT_DETAILS',
	GetSellerStatementPDF = 'GET_SELLER_STATMENT_PDF',
	GetSellerStatements = 'GET_SELLER_STATEMENTS',
	GetSellerStatementsByUser = 'GET_SELLER_STATEMENTS_BY_USER',
	GetUserById = 'GET_USER_BY_ID',
	GetUserRole = 'GET_USER_ROLE',
	GetWatchlistByUser = 'GET_WATCHLIST_BY_USER',
	IssueOrderRefund = 'ISSUE_ORDER_REFUND',
	Login = 'LOGIN',
	MergeUserInfo = 'MERGE_USER_INFO',
	ReassignLotsFromInvoice = 'REASSIGN_LOTS_FROM_INVOICE',
	ReassignLot = 'REASSIGN_LOT',
	RegenerateSellerStatement = 'REGENERATE_SELLER_STATEMENT',
	RegenerateSellerStatementForLot = 'REGENERATE_SELLER_STATEMENT_FOR_LOT',
	SaveLotImages = 'SAVE_LOT_IMAGES',
	SendDraftInvoices = 'SEND_DRAFT_INVOICES',
	SendInvoice = 'SEND_INVOICE',
	SplitLotsToNewOrder = 'SPLIT_LOTS_TO_NEW_ORDER',
	UpdateAuction = 'UPDATE_AUCTION',
	UpdateAuctionImage = 'UPDATE_AUCTION_IMAGE',
	UpdateAuctionSchedule = 'UPDATE_AUCTION_SCHEDULE',
	UpdateAuctionStatus = 'UPDATE_AUCTION_STATUS',
	UpdateAuctionTechnicalDetails = 'UPDATE_AUCTION_TECHNICAL_DETAILS',
	UpdateBanners = 'UPDATE_BANNERS',
	UpdateCashAccount = 'UPDATE_CASH_ACCOUNT',
	UpdateCreditLimit = 'UPDATE_CREDIT_LIMIT',
	UpdateCommissionRates = 'UPDATE_COMMISSION_RATES',
	UpdateFixedPriceItem = 'UPDATE_FIXED_PRICE_ITEM',
	UpdateFixedPriceItemStatus = 'UPDATE_FIXED_PRICE_ITEM_STATUS',
	UpdateInvoiceComments = 'UPDATE_INVOICE_COMMENTS',
	UpdateLot = 'UPDATE_LOT',
	UpdateOrderAdjustments = 'UPDATE_ORDER_ADJUSTMENTS',
	UpdateOrderCollectionsStatus = 'UPDATE_ORDER_COLLECTIONS_STATUS',
	UpdateOrderDueDate = 'UPDATE_ORDER_DUE_DATE',
	UpdateOrderPaymentReminders = 'UPDATE_ORDER_PAYMENT_REMINDERS',
	UpdateOrderShippingAddress = 'UPDATE_ORDER_SHIPPING_ADDRESS',
	UpdateOrderShippingDetails = 'UPDATE_ORDER_SHIPPING_DETAILS',
	UpdateOrderShippingStatus = 'UPDATE_ORDER_SHIPPING_STATUS',
	UpdateOrderStatus = 'UPDATE_ORDER_STATUS',
	UpdateSellerStatement = 'UPDATE_SELLER_STATEMENT',
	UpdateUser = 'UPDATE_USER',
	UpdateUserShippingAddress = 'UPDATE_USER_SHIPPING_ADDRESS',
	WithdrawFromCashAccount = 'WITHDRAW_FROM_CASH_ACCOUNT',
}

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuctionPicker from '../../../components/AuctionPicker';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import { TypedDropdownMenuItem } from '../../../components/Dropdown/Dropdown.model';
import Input from '../../../components/Input';
import Label from '../../../components/Label';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useAuctionById from '../../../queries/useAuctionById';
import useFacets from '../../../queries/useFacets';
import { Auction } from '../../../services/auctionsService';
import {
	LotsQueryType,
	LOTS_SORT_BY_LABELS,
} from '../../../services/lotsService/lotsService.model';
import AuctionPreviewFacets from './AuctionPreviewFacets';
import { useFacetSelection } from './AuctionPreviewFacets/useFacetSelection';
import AuctionPreviewCards from './AuctionPreviewCards';
import useDecision from '../../../optimizely/useDecision';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import { getTestIdentifier } from '../../../utils/test-identifiers/getTestIdentifier';

const AuctionPreview = () => {
	const { auctionId } = useParams<{ auctionId: string }>();
	const [selectedAuctionId, setSelectedAuctionId] = useState(auctionId);

	const { data: facets = [], status: facetsStatus } = useFacets({ auctionId: selectedAuctionId });
	const { facetSearchQuery, toggleFacetValue, resetFacetSearchQuery } = useFacetSelection();

	const [sortBy, setSortBy] = useState<LotsQueryType>('Featured');
	const sortByOptions: TypedDropdownMenuItem<LotsQueryType>[] = Object.entries(
		LOTS_SORT_BY_LABELS
	).map(([key, label]) => ({
		value: key as LotsQueryType,
		label,
	}));

	const [searchTerm, setSearchTerm] = useState('');

	const history = useHistory();

	useEffect(() => setSelectedAuctionId(auctionId), [auctionId]);

	useEffect(() => {
		if (auctionId !== selectedAuctionId) {
			setSortBy('Featured');
			setSearchTerm('');
			resetFacetSearchQuery();
			history.push(`/auctions/${selectedAuctionId}/preview`);
		}
	}, [selectedAuctionId]);

	const { data: thisAuction = {} as Auction, status: thisAuctionLoadStatus } =
		useAuctionById(selectedAuctionId);

	const shouldShowNewAuctionPicker = useDecision('new-auction-picker');

	return (
		<Card>
			<h1 className="text-display4">Auction Preview</h1>
			<LoadingWrapper queryStatuses={[thisAuctionLoadStatus, facetsStatus]}>
				<a
					className="text-subtitle1 link"
					href={`${process.env.BIDDER_SITE_URL}/buy/?Auctions=${auctionId}`}
					data-testid={getTestIdentifier({
						componentName: 'AuctionPreview',
						descriptor: 'auction-title-header',
						pagePath: 'preview',
						sectionPath: 'auctions',
					})}
				>
					{thisAuction.title}
				</a>
				<div className="mt-4 flex flex-col xl:flex-row justify-between">
					{shouldShowNewAuctionPicker ? (
						<NewAuctionPicker
							selectedAuctionId={selectedAuctionId}
							setSelectedAuctionId={setSelectedAuctionId}
							shouldShowUnassignedLots={false}
						/>
					) : (
						<AuctionPicker
							selectedAuctionId={selectedAuctionId}
							setSelectedAuctionId={setSelectedAuctionId}
							shouldShowUnassignedLots={false}
						/>
					)}
					<div className="mr-4 w-full lg:w-72 xl:ml-4 mt-4 xl:mt-0">
						<Label htmlFor="sortBy">Sort By</Label>
						<Dropdown
							options={sortByOptions}
							name="sortBy"
							onChange={(selectedOption) =>
								selectedOption && setSortBy(selectedOption.value as LotsQueryType)
							}
							defaultOption={{ label: 'Lowest Lot Number', value: 'Featured' }}
						/>
					</div>
				</div>
				<div className="mt-4 w-full">
					<Label htmlFor="search-term">Filter Lots</Label>
					<div className="text-caption1 mb-2">
						You can narrow down by Lot Number, Title, or Serial Number
					</div>
					<Input
						name="search-term"
						value={searchTerm}
						onChange={({ target: { value } }) => setSearchTerm(value)}
						className="w-full"
					/>
				</div>
				<div className="mt-4 lg:mt-8 flex flex-col lg:flex-row">
					<div className="flex-grow min-w-80">
						<AuctionPreviewFacets
							facets={facets.filter((facet) => facet.buckets && facet.buckets.length > 0)}
							toggleFacetValue={toggleFacetValue}
							facetSearchQuery={facetSearchQuery}
						/>
					</div>
					<div className="flex-grow ml-0 lg:ml-8">
						<AuctionPreviewCards
							auctionId={selectedAuctionId}
							facetSearchQuery={facetSearchQuery}
							searchTerm={searchTerm}
							sortBy={sortBy}
						/>
					</div>
				</div>
			</LoadingWrapper>
		</Card>
	);
};

export default AuctionPreview;

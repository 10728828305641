import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelNewUser, NewUser } from './createUser.model';

export const createUser = async (newUser: NewUser) => {
	const mappedNewUser: KernelNewUser = {
		address1: newUser.address1 || '',
		address2: newUser.address2 || '',
		birthdate: newUser.birthdate || '',
		buyer_notes: newUser.buyerNotes || '',
		cell_phone: newUser.cellPhone || '',
		city: newUser.city || '',
		company: newUser.company || '',
		country: newUser.country || '',
		email: newUser.email || '',
		evening_phone: newUser.eveningPhone || '',
		family_name: newUser.familyName || '',
		fax_number: newUser.faxNumber || '',
		given_name: newUser.givenName || '',
		phone_number: newUser.phoneNumber || '',
		sas_account_executive_id: newUser.sasAccountExecutiveId || '',
		sas_buyer_id: newUser.sasBuyerId || '',
		sas_seller_id: newUser.sasSellerId || '',
		seller_commission: newUser.sellerCommission || 0,
		seller_notes: newUser.sellerNotes || '',
		state: newUser.state || '',
		taxpayer_id: newUser.taxpayerId || '',
		verified_tin: newUser.verifiedTin || false,
		zipcode: newUser.zipCode || '',
	};

	const response = await axiosAuth.post<KernelNewUser>(apiEndpoints.create_user, mappedNewUser);

	if (response.status > 400) {
		throw response;
	}

	return response.data;
};

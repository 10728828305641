import { Link } from 'react-router-dom';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import SortableTable from '../SortableTable';
import { SortableTableParams } from '../SortableTable/SortableTable.model';
import {
	KernelSellerStatement,
	SearchSellerStatementParams,
	SellerStatementSearchFilter,
} from '../../services/sellerStatementService';
import { formatDate } from '../../utils/formatters';
import { Tooltip } from '../../prizm-ui/Tooltip';
import StatementNumberCell from '../SortableTable/cells/StatementNumberCell';
import { Chip } from '../../prizm-ui/Chip';
import { useState } from 'react';
import useSellerStatements from '../../queries/useSellerStatements/useSellerStatements';
import RadioButton from '../RadioButton';
import Label from '../Label';
import DownloadCSVButton from '../DownloadCSVButton';
import useUserById from '../../queries/useUserById';
import { User } from '../../services/userService/userService.model';
import useAuctionById from '../../queries/useAuctionById';
import { Auction } from '../../services/auctionsService';
import { currencyColumn } from '../SortableTable/columns';
import { Button } from '../../prizm-ui/Button';
import BulkUploadSellerStatementAdjustmentsModal from '../BulkUploadSellerStatementAdjustmentsModal';
import useAuctions from '../../queries/useAuctions';
import { remove } from 'lodash';

const SellerStatementTable = (props: SearchSellerStatementParams) => {
	const [searchBy, setSearchBy] = useState<SellerStatementSearchFilter>(props.status);

	const { data: sellerStatements = [] as KernelSellerStatement[], status: sellerStatementsStatus } =
		useSellerStatements({
			...props,
			status: searchBy,
		});

	const { data: auctions = [] as Auction[] } = useAuctions();

	const sellerStatementsWithAuctionTitle: KernelSellerStatement[] = sellerStatements.map(
		(statement) => ({
			...statement,
			auction_title:
				(auctions.find((auction) => auction.id === statement.auction_id) ?? ({} as Auction))
					?.title ?? '',
		})
	);

	const columns: AccessorKeyColumnDef<KernelSellerStatement>[] = [
		{
			header: 'Statement No.',
			accessorKey: 'seller_statement_number',
			cell: ({
				getValue,
				row: {
					original: { auction_id, seller_statement_id, seller_stripe_payout_account_id },
				},
			}) => (
				<StatementNumberCell
					auctionId={auction_id}
					statementId={seller_statement_id}
					statementNumber={getValue() as string}
					stripePayoutAccountId={seller_stripe_payout_account_id}
					urlSchema="auction"
				/>
			),
		},
		{
			header: 'Statement Date',
			accessorKey: 'created_at',
			cell: ({ getValue }) => <div>{formatDate(getValue() as string)}</div>,
		},
		{
			header: () => <div className="text-right">Status</div>,
			accessorKey: 'seller_statement_status',
			cell: ({ getValue }) => (
				<div className="text-right">
					<Chip variant={getValue() === 'Paid' ? 'success' : 'error'}>{getValue()}</Chip>
				</div>
			),
		},
		{
			header: 'Auction',
			accessorKey: 'auction_title',
			cell: ({ getValue }) => <div className="w-48">{getValue()}</div>,
		},
		{
			header: 'Seller Name',
			accessorKey: 'seller_name',
			cell: ({
				getValue,
				row: {
					original: { seller_id },
				},
			}) => (
				<div className="w-32">
					<Tooltip content={`View ${getValue()}'s Profile`} id={`view-${seller_id}`}>
						<Link to={`/usermanagement/customer/${seller_id}/customer-overview`} className="link">
							{getValue() as string}
						</Link>
					</Tooltip>
				</div>
			),
		},
		{
			header: 'Seller Email',
			accessorKey: 'seller_email',
		},
		currencyColumn<KernelSellerStatement>({
			accessorKey: 'total_lot_value',
			header: 'Total Lot Value',
		}),
		currencyColumn<KernelSellerStatement>({
			accessorKey: 'commission',
			header: 'Commission',
		}),
		currencyColumn<KernelSellerStatement>({
			accessorKey: 'net_adjustments',
			header: 'Net Adjustments',
		}),
		currencyColumn<KernelSellerStatement>({
			accessorKey: 'total_statement_amount',
			header: 'Total Statement Amount',
		}),
		currencyColumn<KernelSellerStatement>({
			accessorKey: 'total_ready_for_payout',
			header: 'Total Ready for Payout',
		}),
		{
			header: 'Payout Date',
			accessorKey: 'payout_date',
			cell: ({
				getValue,
				row: {
					original: { seller_statement_status },
				},
			}) => (seller_statement_status === 'Paid' ? formatDate(getValue() as string) : ''),
		},
		{
			header: 'Comments',
			accessorKey: 'comments',
		},
	];

	if (props.queryBy === 'auctionId') {
		remove(columns, ({ accessorKey }) => accessorKey == 'auction_title');
	}

	const sortableTableParams: SortableTableParams<KernelSellerStatement> = {
		columns,
		data: sellerStatementsWithAuctionTitle,
		getRowId: ({ seller_statement_id }) => seller_statement_id,
	};

	const { data: seller = {} as User } = useUserById(
		props.queryBy === 'sellerId' ? props.sellerId : ''
	);
	const { data: auction = {} as Auction } = useAuctionById(
		props.queryBy === 'auctionId' ? props.auctionId : ''
	);
	const csvFilename = `${searchBy} seller statements for ${
		props.queryBy === 'auctionId'
			? auction.title
			: `${seller.givenName} ${seller.familyName}`.trim()
	} - ${new Date().toISOString()}`;

	const [shouldShowUploadAdjustmentsModal, setShouldShowUploadAdjustmentsModal] = useState(false);

	return (
		<div>
			<div className="mb-4 flex justify-between">
				<div>
					<Label>Statement Status</Label>
					<div className="flex">
						<RadioButton
							name="searchBy"
							checked={searchBy === 'All'}
							label="All"
							value="All"
							className="mr-2"
							onClick={() => setSearchBy('All')}
						/>
						<RadioButton
							name="searchBy"
							checked={searchBy === 'Paid'}
							value="Paid"
							label="Paid"
							className="mr-2"
							onClick={() => setSearchBy('Paid')}
						/>
						<RadioButton
							name="searchBy"
							checked={searchBy === 'Unpaid'}
							label="Unpaid"
							value="Unpaid"
							onClick={() => setSearchBy('Unpaid')}
						/>
					</div>
				</div>
				<div className="flex flex-col items-end">
					<Button
						className="mb-2"
						size="md"
						variant="primary"
						onClick={() => setShouldShowUploadAdjustmentsModal(true)}
					>
						Upload Statement Adjustments
					</Button>
					{shouldShowUploadAdjustmentsModal && (
						<BulkUploadSellerStatementAdjustmentsModal
							onClose={() => setShouldShowUploadAdjustmentsModal(false)}
						/>
					)}
					<DownloadCSVButton
						columnNames={columns.map((column) => column.accessorKey)}
						items={sellerStatementsWithAuctionTitle}
						documentName={csvFilename}
					/>
				</div>
			</div>
			<SortableTable
				globalFilterPlaceholder="Filter ..."
				noResultsMessage="No statements to show"
				tableParams={sortableTableParams}
				isLoading={sellerStatementsStatus === 'loading'}
			/>
		</div>
	);
};

export default SellerStatementTable;

import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Input } from '../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotMetadataModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const [draftLot, setDraftLot] = useState(lot);
	const updateDraftLot = (deltas: Partial<KernelLot>) => setDraftLot({ ...draftLot, ...deltas });

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () =>
		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated lot metadata');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating lot metadata. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			title="Edit Lot Metadata"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={!isEqual(lot, draftLot)}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Input
					id="serial_number"
					value={draftLot.serial_number}
					onChange={({ target: { value } }) => updateDraftLot({ serial_number: value })}
					label="Serial Number"
				/>
				<Input
					id="cert_number"
					value={draftLot.cert_number}
					onChange={({ target: { value } }) => updateDraftLot({ cert_number: value })}
					label="Cert Number"
				/>
				<Input
					id="contract_id"
					value={draftLot.contract_id}
					onChange={({ target: { value } }) => updateDraftLot({ contract_id: value })}
					label="Contract ID"
				/>
			</div>
		</ActionModal>
	);
};

export default EditLotMetadataModal;

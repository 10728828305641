import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Row, Col } from 'reactstrap';
import SortableTable from '../../../zenauction-ui-shared/Components/Elements/SortableTable';
import { fetchAuctions } from '../../../AdminUtils/auctionUtils';
import { getAuctionDashboard } from '../../../AdminUtils/auctionUtils';
import { UserRole, getUserRole } from '../../../AdminUtils/userRoles';
import { LightBlueButton } from '../../../zenauction-ui-shared/Utilities';
import moment from 'moment';
import { checkAccessToPage } from '../../../AdminUtils/permissionUtils';
import { NAV_ITEMS } from '../../../AdminUtils/permissionUtils';
import AuctionPicker from '../../../components/AuctionPicker';
import Card from '../../../components/Card';
import { getTestIdentifier } from '../../../utils/test-identifiers/getTestIdentifier';
import { formatDate } from '../../../utils/formatters';

class LiveAuctionDashboard extends Component {
	constructor(props) {
		super();

		this.toggle = this.toggle.bind(this);
		this.toggleActivityMonitorTab = this.toggleActivityMonitorTab.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.getLotLink = this.getLotLink.bind(this);
		this.getUserLink = this.getUserLink.bind(this);
		this.getBidsLink = this.getBidsLink.bind(this);
		const selectedAuction = props?.match?.params?.auctionId;

		this.state = {
			auctions: [],
			dropdownOpen: false,
			selectedAuction: selectedAuction,
			auctionData: null,
			auctionStats: null,
			id: uuidv4(),
			sortableTableId: 1,
			isAdmin: false,
			lastRefresh: moment().format('h:mm:ss a'),
			disableAutoRefresh: false,
			rowsPerPage: 250,
			biddingHistoryLot: null,
		};

		this.refresh = setInterval(() => this.refreshData(), 60 * 1000);
	}

	toggle() {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	}

	toggleActivityMonitorTab(tab) {
		if (this.state.activeTab1 !== tab) {
			this.setState({
				activeTab1: tab,
			});
		}
	}

	onSelectAuction(auction) {
		this.setState(
			{
				selectedAuction: auction,
			},
			() => {
				this.props.history.push(`/Performance/LiveAuction/${auction}`);
				this.refreshData(true);
			}
		);
	}

	componentDidMount() {
		checkAccessToPage(NAV_ITEMS.LiveAuctionDashboard).then((result) => {
			if (!result) {
				window.location.href = window.location.origin;
			}
		});
		fetchAuctions().then((auctions) => {
			let filteredAuctions =
				auctions && auctions.length
					? auctions.filter(function (auction) {
							return auction.status == 'Completed' || auction.status == 'Active';
					  })
					: [];
			// Sort by start time then title, descending
			filteredAuctions.sort(function (a, b) {
				if (a.start_timestamp != b.start_timestamp)
					return a.start_timestamp > b.start_timestamp ? -1 : 1;
				return a.title > b.title ? -1 : 1;
			});
			this.setState({
				auctions: filteredAuctions,
			});

			const selectedAuction = this.props?.match?.params?.auctionId;
			if (selectedAuction) {
				this.refreshData(true);
			}
		});
		getUserRole().then((userRole) => {
			if (userRole === UserRole.Admin)
				this.setState({
					isAdmin: true,
				});
		});
	}

	componentDidUpdate(prevProps) {
		const auctionId = this.props?.match?.params?.auctionId;
		if (auctionId && auctionId != prevProps?.match?.params?.auctionId) {
			this.onSelectAuction(auctionId);
		}
	}

	refreshData(force = false) {
		if (!force && (!this.state.selectedAuction || this.state.disableAutoRefresh)) return;
		getAuctionDashboard(this.state.selectedAuction).then((auctionData) => {
			this.setState({
				auctionData: auctionData,
				sortableTableId: this.state.sortableTableId + 1,
				lastRefresh: moment().format('h:mm:ss a'),
			});
		});
		fetchAuctions().then((data) => {
			const currentAuction = data.find((auction) => {
				return auction.auction_id == this.state.selectedAuction;
			});
			this.setState({
				auctionStats: currentAuction,
			});
		});
	}

	formatCurrency(value) {
		if (!value) return;
		return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
	}

	getLotLink(lotId, value) {
		return (
			<a
				className="link"
				target="_blank"
				rel="noopener noreferrer"
				href={`/#/auctions/${this.state.selectedAuction}/inventory-manager/${lotId}`}
			>
				{value}
			</a>
		);
	}

	getListingsLink(lotId, value) {
		return (
			<a
				className="link"
				target="_blank"
				rel="noopener noreferrer"
				href={`/#/inventory/listings/${lotId}`}
			>
				{value}
			</a>
		);
	}

	getUserLink(userId, userName) {
		if (!userId) return '';
		return (
			<a className="link" href={`/#/usermanagement/customer/${userId}`}>
				{userName}
			</a>
		);
	}

	getBidsLink(lotId, currentPrice) {
		return (
			<a
				className="link"
				target="_blank"
				rel="noopener noreferrer"
				href={`/#/inventory/listings/${lotId}/bids`}
			>
				{new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(currentPrice)}
			</a>
		);
	}

	getBidsLinkWithNoOfBids(lotId, number_of_bids) {
		return (
			<a
				className="link"
				target="_blank"
				rel="noopener noreferrer"
				href={`/#/inventory/listings/${lotId}/bids`}
			>
				{number_of_bids}
			</a>
		);
	}

	render() {
		const columns = [
			{
				id: 'last_bid_timestamp',
				label: 'Last Bid',
				align: 'left',
				formatFull: (props) => (
					<div
						data-testid={getTestIdentifier({
							componentName: 'LiveAuctionDashboard',
							descriptor: `bids-table-entry-${props.lot_id}`,
							pagePath: 'LiveAuction',
							sectionPath: 'Performance',
						})}
					>
						{formatDate(props.last_bid_timestamp)}
					</div>
				),
			},
			{
				id: 'lot_number',
				label: 'Lot',
				align: 'left',
				formatFull: (props) => this.getLotLink(props.lot_id, props.lot_number),
			},
			{ id: 'title', label: 'Title', align: 'left', minWidth: '200px' },
			{ id: 'status', label: 'Status', align: 'left' },
			{
				id: 'current_price',
				label: 'Current Price',
				align: 'left',
				formatFull: (props) => this.getBidsLink(props.lot_id, props.current_price),
			},
			{
				id: 'min_bid_price',
				label: 'Min Bid',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
				},
			},
			{
				id: 'number_of_views',
				label: 'Views',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en').format(value);
				},
			},
			{
				id: 'number_of_watchers',
				label: 'Watchers',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en').format(value);
				},
			},
			{
				id: 'number_of_bids',
				label: 'Bids',
				align: 'left',
				formatFull: (props) => this.getBidsLinkWithNoOfBids(props.lot_id, props.number_of_bids),
			},
			{
				id: 'highest_bidder',
				label: 'Highest Bidder',
				align: 'left',
				formatFull: (props) => this.getUserLink(props.highest_bidder_id, props.highest_bidder),
			},
			{
				id: 'under_bidder',
				label: 'Under Bidder',
				align: 'left',
				formatFull: (props) => this.getUserLink(props.under_bidder_id, props.under_bidder),
			},
			{
				id: 'seller',
				label: 'Seller',
				align: 'left',
				formatFull: (props) => this.getUserLink(props.seller_id, props.seller),
			},
			{ id: 'consignment_manager', label: 'Consignment Director', align: 'left' },
			{ id: 'end_timestamp', label: 'End Time', type: 'timestamp', align: 'left' },
			{
				id: 'serial_number',
				label: 'Serial',
				align: 'left',
				formatFull: (props) => this.getListingsLink(props.lot_id, props.serial_number),
			},
			{
				id: 'reserve_amount',
				label: 'Reserve',
				align: 'left',
				format: (value) => {
					return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
				},
			},
		];

		return (
			<Fragment>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}
				>
					<Card>
						<h1 className="text-display4 mb-4">Live Auction Dashboard</h1>
						<AuctionPicker
							selectedAuctionId={this.state.selectedAuction?.auction_id}
							setSelectedAuctionId={this.onSelectAuction.bind(this)}
							shouldShowUnassignedLots={false}
						/>

						{this.state.selectedAuction && (
							<Row>
								<Col md="12">
									<Card className="main-card mb-3">
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="left">
														<b>Total Bids</b>
													</TableCell>
													<TableCell align="left">
														<b>Total Unique Bidders</b>
													</TableCell>
													<TableCell align="left">
														<b>Total Bid Value</b>
													</TableCell>
													<TableCell align="left">
														<b>Total BP + Commission</b>
													</TableCell>
													<TableCell align="left">
														<b>GMV</b>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell align="left">{this.state.auctionStats?.total_bids}</TableCell>
													<TableCell align="left">
														{this.state.auctionStats?.unique_bidders}
													</TableCell>
													<TableCell align="left">
														{this.formatCurrency(this.state.auctionStats?.total_bid_value)}
													</TableCell>
													<TableCell align="left">
														{this.formatCurrency(
															this.state.auctionStats?.total_buyer_premium +
																this.state.auctionStats?.total_commission
														)}
													</TableCell>
													<TableCell align="left">
														{this.formatCurrency(this.state.auctionStats?.gmv)}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Card>
								</Col>
							</Row>
						)}

						{this.state.selectedAuction && (
							<Row>
								<Col md="12">
									<Card className="main-card mb-3">
										<LightBlueButton
											variant="contained"
											color="primary"
											startIcon={<Refresh />}
											onClick={() =>
												this.setState({ disableAutoRefresh: !this.state.disableAutoRefresh })
											}
											className="refresh-button"
										>
											{this.state.disableAutoRefresh
												? 'Re-enable Auto Refresh'
												: 'Disable Auto Refresh'}
										</LightBlueButton>
										<SortableTable
											key={this.state.id + this.state.sortableTableId}
											dataToSort={this.state.auctionData}
											sortKey="last_bid_timestamp"
											sortDirection="descending"
											columns={columns}
											rowsPerPage={this.state.rowsPerPage}
										/>
										<div className="card-footer">
											<b>{`Data last refreshed: ${this.state.lastRefresh}, next refresh in 1 minute`}</b>
										</div>
									</Card>
								</Col>
							</Row>
						)}
					</Card>
				</ReactCSSTransitionGroup>
			</Fragment>
		);
	}
}

export default withRouter(LiveAuctionDashboard);

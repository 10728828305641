import { TypedDropdownMenuItem } from '../../../../components/Dropdown/Dropdown.model';

export type FixedPriceSellerStatementSearchType = 'search-query' | 'date-range';

export type FixedPriceSellerStatementSearchOrderStatusOption = 'Paid' | 'Unpaid';
export const FIXED_PRICE_SELLER_STATEMENT_SEARCH_ORDER_STATUS_OPTIONS: TypedDropdownMenuItem<FixedPriceSellerStatementSearchOrderStatusOption>[] =
	[
		{ value: 'Paid', label: 'Paid' },
		{ value: 'Unpaid', label: 'Unpaid' },
	];

export interface BaseFixedPriceSellerStatementSearchParams {
	searchType: FixedPriceSellerStatementSearchType;
	searchQuery?: string;
	fromDate?: string;
	toDate?: string;
	status?: FixedPriceSellerStatementSearchOrderStatusOption;
}
interface FixedPriceSellerStatementSearchByQueryParams
	extends BaseFixedPriceSellerStatementSearchParams {
	searchQuery: string;
}
interface FixedPriceSellerStatementSearchByDateRangeParams
	extends BaseFixedPriceSellerStatementSearchParams {
	fromDate: string;
	toDate: string;
	status: FixedPriceSellerStatementSearchOrderStatusOption;
}
export type FixedPriceSellerStatementSearchParams =
	| FixedPriceSellerStatementSearchByQueryParams
	| FixedPriceSellerStatementSearchByDateRangeParams
	| BaseFixedPriceSellerStatementSearchParams;

export const BLANK_SELLER_STATEMENT_SEARCH_BY_QUERY_PARAMS: FixedPriceSellerStatementSearchByQueryParams =
	{
		searchType: 'search-query',
		searchQuery: '',
	};
export const BLANK_SELLER_STATEMENT_SEARCH_BY_DATE_RANGE_PARAMS: FixedPriceSellerStatementSearchByDateRangeParams =
	{
		searchType: 'date-range',
		status: 'Paid',
		fromDate: '',
		toDate: '',
	};

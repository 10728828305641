import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../../../queries/queryKeys';
import useOrderByNumber from '../../../../../../../../queries/useOrderByNumber';
import { reassignLotToAuction } from '../../../../../../../../services/lotsService';
import { CheckoutOrder } from '../../../../../../../../services/ordersService/ordersService.model';
import AuctionPickerModal from '../../../../../../../../components/NewAuctionPicker/AuctionPickerModal';
import { Auction } from '../../../../../../../../services/auctionsService';
import useAuctionById from '../../../../../../../../queries/useAuctionById';
import {
	KernelLot,
	RemovedLot,
} from '../../../../../../../../services/lotsService/lotsService.model';
import { kernelLotToRemovedLot } from '../../OrderSummary.utils';

const ChangeAuctionModal = ({
	onClose,
	lot,
	currentAuctionId,
}: {
	onClose: () => void;
	lot: KernelLot;
	currentAuctionId: string;
}) => {
	const { orderNumber = '' } = useParams<{ orderNumber: string }>();
	const { data: order = {} as CheckoutOrder, updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const [selectedAuctionId, setSelectedAuctionId] = useState('Unassigned');
	const { data: selectedAuction = {} as Auction } = useAuctionById(selectedAuctionId);
	const { data: currentAuction = {} as Auction } = useAuctionById(currentAuctionId);

	const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

	const { mutate: doChangeAuction, status: changeAuctionStatus } = useMutation({
		mutationKey: [QueryKeys.ReassignLot, lot.lot_id, selectedAuctionId],
		mutationFn: () =>
			reassignLotToAuction({ lotId: lot.lot_id, destinationAuctionId: selectedAuctionId }),
	});

	const handleChangeAuction = () =>
		doChangeAuction(undefined, {
			onSuccess: () => {
				const removedLot: RemovedLot = kernelLotToRemovedLot(lot, currentAuction.auctionType);
				updateOrderInCache({
					...order,
					lotIds: order.lotIds.filter((thisLotId) => thisLotId !== lot.lot_id),
					removedLots: [...(order?.removedLots ?? []), removedLot],
				});
				showSuccessToast('Successfully reassigned lot.');
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong reassigning lot. Please try again later.');
			},
		});

	return (
		<>
			<AuctionPickerModal
				allowedAuctionStatuses={['Active', 'Not_Started', 'Preview', 'Pause', 'Withdrawn']}
				onClose={onClose}
				selectedAuctionId={selectedAuctionId}
				setSelectedAuctionId={(auctionId: string) => {
					setSelectedAuctionId(auctionId);
					setShouldShowConfirmationModal(true);
				}}
				shouldShowUnassignedLots={false}
				closeAfterSelection={false}
			/>
			{shouldShowConfirmationModal && (
				<ActionModal
					title="Assign Lots to Auction"
					onClose={onClose}
					onConfirm={handleChangeAuction}
					isDangerous={true}
					isLoading={changeAuctionStatus === 'loading'}
				>
					<div className="h-full flex grow items-center justify-center text-subtitle1">
						{`Are you sure you want to assign "${lot.title}" to ${selectedAuction.title}? This process cannot be undone.`}
					</div>
				</ActionModal>
			)}
		</>
	);
};

export default ChangeAuctionModal;

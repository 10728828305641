import React, { FC } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';

const Card: FC<{ allowedRoles?: UserRoleName[]; className?: string }> = ({
	allowedRoles = [],
	children,
	className = '',
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	return isForbidden ? (
		<></>
	) : (
		<div className={`w-full rounded-xl bg-neutral1 p-4 shadow-elevation1 ${className}`}>
			{children}
		</div>
	);
};

export default Card;

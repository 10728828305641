import { Sentiment } from '../../../../../prizm-ui/types';
import { AuctionStatus } from '../../../../../services/auctionsService';

export const getSentimentForAuctionStatus = (auctionStatus: AuctionStatus): Sentiment => {
	if (['Active', 'Preview'].includes(auctionStatus)) {
		return 'success';
	}

	if (auctionStatus === 'Completed') {
		return 'error';
	}

	return 'primary';
};

import { useState } from 'react';
import { format, isEqual, isValid } from 'date-fns';
import { useMutation } from 'react-query';
import Label from '../../../../../../../../components/Label';
import { showErrorToast, showSuccessToast } from '../../../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../../../queries/queryKeys';
import { CheckoutOrder } from '../../../../../../../../services/ordersService/ordersService.model';
import { updateOrder } from '../../../../../../../../services/ordersService';
import { useParams } from 'react-router-dom';
import useOrderByNumber from '../../../../../../../../queries/useOrderByNumber';
import ActionModal from '../../../../../../../../components/ActionModal';
import { getUTCNonISO } from '../../../../../../../../utils/formatters';

const EditDueDateModal = ({ onClose }: { onClose: () => void }) => {
	const { orderNumber } = useParams<{ orderNumber: string }>();
	const { data: order = {} as CheckoutOrder, updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const defaultDueDate = isValid(new Date(order.dueDate))
		? format(new Date(order.dueDate), 'yyyy-MM-dd')
		: format(new Date(), 'yyyy-MM-dd');

	const [dueDate, setDueDate] = useState(defaultDueDate);

	const { mutate: doDueDateUpdate, status: dueDateUpdateStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderDueDate, order.invoiceNumber],
		mutationFn: () =>
			updateOrder({
				fieldsToUpdate: {
					dueDate,
				},
				orderNumber,
			}),
	});

	const handleSaveDueDate = () => {
		doDueDateUpdate(undefined, {
			onError: () => {
				showErrorToast('Something went wrong updating due date. Please try again later.');
			},
			onSuccess: (updatedOrder: CheckoutOrder) => {
				updateOrderInCache(updatedOrder);
				showSuccessToast('Order due date successfully updated.');
				onClose();
			},
		});
	};

	return (
		<ActionModal
			title="Edit Due Date"
			onClose={onClose}
			isLoading={dueDateUpdateStatus === 'loading'}
			canConfirm={!isEqual(new Date(defaultDueDate), new Date(dueDate))}
			confirmButtonLabel="Save Due Date"
			onConfirm={handleSaveDueDate}
		>
			<div>
				<Label htmlFor="edit-due-date-picker">Select New Date</Label>
				<input
					className="border-base focus:border-blue-500 focus:ring-blue-500 block rounded-lg p-2"
					defaultValue={defaultDueDate}
					name="edit-due-date-picker"
					type="date"
					onChange={({ target: { value } }) => setDueDate(getUTCNonISO(new Date(value)))}
				/>
			</div>
		</ActionModal>
	);
};

export default EditDueDateModal;

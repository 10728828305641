import axios from 'axios';
import { Auth } from 'aws-amplify';
import config from '../config.js';

const options = {
	Accept: 'application/json',
	'Content-Type': 'application/json;charset=UTF-8',
	company_id: config.organization.company_id,
};

export const axiosAuth = axios.create();
export const axiosUnauthed = axios.create();

export const getAuthed = axiosAuth.get;
export const getUnauthed = axiosUnauthed.get;

export const postAuthed = axiosAuth.post;
export const postUnauthed = axiosUnauthed.post;

export const putAuthed = axiosAuth.put;
export const putUnauthed = axiosUnauthed.put;

axiosAuth.interceptors.request.use(
	async (config) => {
		const headersToAdd = JSON.parse(JSON.stringify(options));
		headersToAdd['Authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken();
		config.headers = {
			...config.headers,
			...headersToAdd,
		};
		return config;
	},
	(error) => {
		console.error('Authorization failed');
		return Promise.reject(error);
	}
);

import fileDownload from 'js-file-download';
import { noop } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuid4 } from 'uuid';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import EditAddShippingDetailsForm from './EditAddShippingDetailsForm';
import Address from '../../../../../components/Address';
import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import InvoiceStatusChip from '../../../../../components/statusChips/InvoiceStatusChip';
import Label from '../../../../../components/Label';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import ShippingStatusChip from '../../../../../components/statusChips/ShippingStatusChip';
import SortableTable from '../../../../../zenauction-ui-shared/Components/Elements/SortableTable';
import OrderComments from '../../../../orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderComments';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useOrderByNumber from '../../../../../queries/useOrderByNumber';
import useOrderInvoicePDF from '../../../../../queries/useOrderInvoicePDF';
import usePluralLots from '../../../../../queries/usePluralLots';
import useUserById from '../../../../../queries/useUserById';
import { ShippingStatus } from '../../../../../services/invoicesService/invoicesService.model';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';
import {
	BaseCostMetadata,
	CheckoutOrder,
	CheckoutOrderCommentKind,
} from '../../../../../services/ordersService/ordersService.model';
import { User } from '../../../../../services/userService/userService.model';
import { updateInvoiceComments } from '../../../../../services/invoicesService';
import { updateOrder } from '../../../../../services/ordersService';
import { getPickOrderSheetPdf } from '../../../../../services/ordersService';
import { assembleNewComments } from '../../../../orders/OrdersRoot/OrderDashboard/OrderDashboardView/OrderDashboardView.util';
import { formatDate } from '../../../../../utils/formatters';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';

const CheckoutOrderPickSheet = ({
	orderNumber,
	onClose = noop,
	reloadRows = noop,
}: {
	orderNumber: string;
	onClose: () => void;
	reloadRows?: (auctionId: string) => void;
}) => {
	const {
		data: order = {} as CheckoutOrder,
		status: orderByNumberStatus,
		updateOrder: updateOrderInCache,
	} = useOrderByNumber({
		orderNumber,
		enabled: true,
	});
	const { data: checkoutOrderPDF = new Blob() } = useOrderInvoicePDF(orderNumber);

	const {
		comments: orderComments = [],
		costMetadata = {} as BaseCostMetadata,
		lotIds = [],
	} = order;

	const { lotsTotal = 0 } = costMetadata;

	const { data: lots = [] as KernelLot[], status: pluralLotsStatus } = usePluralLots(lotIds);
	const { data: user = {} as User, status: userByIdStatus } = useUserById(order.winnerId);

	const [getPickSheetStatus, setGetPickSheetStatus] = useState('idle');

	const { mutate: doShippingStatusUpdate, status: shippingStatusUpdateStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderShippingStatus, orderNumber],
		mutationFn: () =>
			updateOrder({
				orderNumber,
				fieldsToUpdate: {
					shippingStatus: 'Shipped',
				},
			}),
	});

	const handlePrintPickSheetNewTab = () => {
		setGetPickSheetStatus('loading');
		getPickOrderSheetPdf(orderNumber)
			.then((pickSheetPdfBlob) => {
				setGetPickSheetStatus('success');
				const pickSheetUrl = URL.createObjectURL(pickSheetPdfBlob);
				window.open(pickSheetUrl, '_blank');
			})
			.catch((err: string) => {
				setGetPickSheetStatus('error');
				showErrorToast(`Error loading pick sheet: ${JSON.stringify(err)}`);
			});
	};

	const handleDoShippingStatusUpdate = () => {
		doShippingStatusUpdate(undefined, {
			onError: () => showErrorToast('Something went wrong. Please try again later.'),
			onSuccess: () => {
				updateOrderInCache({ shippingStatus: 'Shipped' });

				handleUpdateOrderComments('Shipping status updated to "Shipped".', 'order-shipping-status');

				reloadRows(order.auctionId);

				onClose();
			},
		});
	};

	const handleDownloadClick = () =>
		fileDownload(new Blob([checkoutOrderPDF]), `order-${orderNumber}-invoice.pdf`);

	const handleUpdateOrder = (trackingIdChanges: Partial<CheckoutOrder>) => {
		updateOrderInCache(trackingIdChanges);

		reloadRows(order.auctionId);
	};

	const handleUpdateOrderComments = async (newComment: string, kind: CheckoutOrderCommentKind) => {
		updateInvoiceComments({
			auction_id: order.auctionId,
			comments: await assembleNewComments({
				kind,
				previousComments: orderComments,
				text: newComment,
			}),
			invoice_id: order.invoiceId,
		}).then(({ statusCode }) => {
			if (statusCode === 200) {
				showSuccessToast('Comment saved successfully.');
			} else {
				showErrorToast('Something went wrong. Please try again later.');
			}
		});
	};

	const columns = [
		{ id: 'lot_number', label: 'Lot #', align: 'left' },
		{ id: 'serial_number', label: 'Serial #', align: 'left' },
		{ id: 'title', label: 'Title', align: 'left', minWidth: 300 },
		{ id: 'location', label: 'Location', align: 'left' },
		{
			id: 'final_price',
			label: 'Amount',
			align: 'left',
			format: (value: number) => formatCurrency(value),
		},
	];

	return (
		<LoadingWrapper queryStatuses={[orderByNumberStatus, pluralLotsStatus, userByIdStatus]}>
			<Card className="p-8">
				<div className="flex-column flex">
					<div className="mb-8">
						<h1 className="text-2xl font-bold">{`${user.givenName} ${user.familyName}`}</h1>
					</div>
					<div className="flex flex-row">
						<div className="basis-2/5">
							<h2 className="mb-4 text-subtitle1 font-bold">Details</h2>
							<div className="mb-4">
								<Label className="text-disabled">Invoice Number</Label>
								<p>{order.invoiceNumber}</p>
							</div>
							<div className="mb-4">
								<Label className="text-disabled">Account Details</Label>
								<p>{`${user.givenName} ${user.familyName}`}</p>
								<p>{user.email}</p>
							</div>
							<div className="mb-4">
								<Label className="text-disabled">Shipping Details</Label>
								<Address
									firstName={user.givenName}
									lastName={user.familyName}
									line1={order.costMetadata?.address?.line1}
									line2={order.costMetadata?.address?.line2}
									city={order.costMetadata?.address?.city}
									state={order.costMetadata?.address?.state}
									postalCode={order.costMetadata?.address?.postalCode}
									country={order.costMetadata?.address?.country}
								/>
							</div>
							<div>
								<div className="flex flex-row items-baseline justify-between">
									<h2 className="mb-4 text-subtitle1 font-bold">Shipping Details</h2>
								</div>
								<EditAddShippingDetailsForm
									order={order}
									updateOrderOnSuccess={(trackingId) => handleUpdateOrder(trackingId)}
								/>
							</div>
						</div>
						<div className="basis-3/5 pl-8">
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="mr-4 font-bold">Print Pick Sheet:</Label>
								<Button
									className="link mr-4 pl-0 pr-0"
									kind="tertiary"
									onClick={() => handlePrintPickSheetNewTab()}
									isLoading={getPickSheetStatus === 'loading'}
								>
									PDF
								</Button>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="mr-4 font-bold">Printed On:</Label>
								<p>{formatDate(order.printedAt, { invalidDateMessage: 'Not Printed' })}</p>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold mr-4">Status:</Label>
								<InvoiceStatusChip status={order.invoiceStatus} />
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold">Payment Date</Label>
								<p className="ml-4 text-body1">
									{formatDate(order.paymentDate, { invalidDateMessage: 'Invalid Payment Date' })}
								</p>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold mr-4">Shipping Status:</Label>
								<ShippingStatusChip status={order.shippingStatus as ShippingStatus} />
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="mr-4 font-bold">Payments:</Label>
								<Button
									className="link mr-4 pl-0 pr-0"
									disabled={
										order.shippingStatus == 'Shipped' || shippingStatusUpdateStatus == 'loading'
									}
									kind="tertiary"
									onClick={() => handleDoShippingStatusUpdate()}
								>
									Mark as Shipped
								</Button>
								<Button className="link pl-0 pr-0" kind="tertiary" onClick={handleDownloadClick}>
									Download Invoice
								</Button>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold">Auction:</Label>
								<p className="ml-4 text-body1">{order.description}</p>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold">Total Lots Value:</Label>
								<p className="ml-4 text-body1">{formatCurrency(lotsTotal)}</p>
							</div>
							<div className="mb-4 flex flex-row items-baseline">
								<Label className="font-bold">Total Invoice Amount:</Label>
								<p className="ml-4 text-body1">{formatCurrency(order.totalAmount)}</p>
							</div>
							<div className="mb-8">
								<Label className="mb- font-bold">Lots Won:</Label>
								<LoadingWrapper queryStatuses={[pluralLotsStatus]}>
									<SortableTable columns={columns} dataToSort={lots} key={uuid4()} />
								</LoadingWrapper>
							</div>
							<div>
								<Label className="mb-4 font-bold">Shipping Comments:</Label>
								<OrderComments
									filters={['order-shipping-comment', 'order-shipping-details']}
									newCommentKind="order-shipping-comment"
									orderNumber={order.invoiceNumber}
								/>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</LoadingWrapper>
	);
};

export default CheckoutOrderPickSheet;

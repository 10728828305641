import { useQuery } from 'react-query';
import { getEmployeeById } from '../../services/userService';
import { QueryKeys } from '../queryKeys';

const useEmployeeById = (employeeId = '') =>
	useQuery([QueryKeys.GetEmployeeById, employeeId], () => getEmployeeById(employeeId), {
		enabled: !!employeeId,
	});

export default useEmployeeById;

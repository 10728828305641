import { noop } from 'lodash';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	debounce?: number;
	allowedRoles?: UserRoleName[];
};

const Input = (props: InputProps) => {
	const { onChange = noop, debounce = 0, allowedRoles = [] } = props;
	const [value, setValue] = useState(props.value);

	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange({ target: { name: props.name, value } });
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<input
			{...props}
			data-testid={props.name}
			className={`border-base rounded-lg p-2 focus:outline-inverteddark ${props.className}`}
			onChange={({ target: { value } }) => setValue(value)}
			value={value}
			disabled={props.disabled || isForbidden}
		/>
	);
};

export default Input;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelUser } from './userService.model';

export const updateCreditLimit = async ({
	userId,
	newCreditLimit,
	oldCreditLimit,
	oldBiddingPower,
	notes,
}: {
	userId: string;
	newCreditLimit: number;
	oldCreditLimit: number;
	notes: string;
	oldBiddingPower: number;
}) => {
	const response = await axiosAuth.post<{ Attributes: KernelUser; statusCode: number }>(
		`${config.api.update_credit_limit}`,
		{
			user: {
				user_id: userId,
				new_credit_limit: newCreditLimit,
				credit_limit: oldCreditLimit,
				notes,
				bidding_power: oldBiddingPower,
			},
		}
	);

	if (response.status >= 400 || response.data.statusCode >= 400) {
		throw response;
	}

	return response.data.Attributes;
};

import { ComponentPropsWithoutRef, useContext } from 'react';
import { AlertContext, AlertVariant } from './Alert';

export type AlertLinkProps = ComponentPropsWithoutRef<'a'>;

export const AlertLink = (props: AlertLinkProps) => {
	const { variant } = useContext(AlertContext);
	const colorClass = (
		{
			primary: 'text-primary-700',
			neutral: 'text-neutral1',
			success: 'text-success-700',
			warning: 'text-warning-700',
			error: 'text-error-700',
		} as { [_ in AlertVariant]: string }
	)[variant];
	return (
		<a
			{...props}
			className={['mt-2', 'text-body2', 'font-semibold', 'underline', colorClass].join(' ')}
		/>
	);
};

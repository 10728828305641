import React, { useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { placeBid, PlaceBidParams } from '../../../../services/bidderService';
import { BidderDropdownMenuItem, PlaceBidModalFormValues } from './PlaceBidModal.model';
import PlaceBidForm from './PlaceBidForm';
import { getIncrementAmount } from '../../../../AdminUtils/bidUtils';
import Modal from '../../../Modal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const PlaceBidModal = ({
	auctionId,
	lotId,
	lotNumber,
	minBid,
	numberOfBids,
	currentPrice,
	onClose,
}: Omit<PlaceBidParams, 'maxBid' | 'userId'> & { numberOfBids: number; onClose: () => void }) => {
	const [selectedBidder, setSelectedBidder] = useState<BidderDropdownMenuItem | null>();

	const basisForMinimumViableBid = Number(currentPrice) || Number(minBid) || 0;
	const minimumViableBid =
		numberOfBids > 0
			? basisForMinimumViableBid + getIncrementAmount(basisForMinimumViableBid)
			: basisForMinimumViableBid;

	const placeManualBidSchema = Yup.object().shape({
		selectedUserOption: Yup.object().shape({
			label: Yup.string(),
			value: Yup.object()
				.shape({
					userId: Yup.string().required('Bidder userId not found'),
					biddingPower: Yup.number().moreThan(
						minimumViableBid,
						'Bidding power less than minimum viable bid'
					),
				})
				.nullable()
				.required('No bidder selected'),
		}),
		maxBid: Yup.number()
			.min(minimumViableBid, 'Maximum bid must be greater than minimum viable bid')
			.max(
				selectedBidder?.value?.biddingPower || minimumViableBid,
				'Maximum bid must be within bidding power'
			)
			.required('Maximum bid required'),
	});

	const { mutate: placeMaximumBid, status: placeMaximumBidStatus } = useMutation({
		mutationFn: ({
			selectedUserOption,
			maxBid = 0,
		}: {
			selectedUserOption: BidderDropdownMenuItem | null;
			maxBid?: number;
		}) =>
			placeBid({
				auctionId,
				lotId,
				lotNumber,
				userId: selectedUserOption?.value.userId || '',
				maxBid: Number(maxBid),
				minBid,
				currentPrice,
			}),
	});

	const handleSubmit = ({ selectedUserOption, maxBid }: PlaceBidModalFormValues) => {
		placeMaximumBid(
			{ selectedUserOption, maxBid },
			{
				onSuccess: () => {
					showSuccessToast('Successfully placed manual bid');
					onClose();
				},
				onError: () => {
					showErrorToast('Something went wrong placing bid. Please try again later');
				},
			}
		);
	};

	return (
		<Modal title="Place manual bid" onClose={onClose}>
			<Formik
				initialValues={{ selectedUserOption: null, maxBid: undefined } as PlaceBidModalFormValues}
				onSubmit={handleSubmit}
				validationSchema={placeManualBidSchema}
			>
				{({ isValid, dirty }) => (
					<PlaceBidForm
						minimumViableBid={minimumViableBid}
						setSelectedBidder={setSelectedBidder}
						isValid={isValid}
						dirty={dirty}
						placeMaximumBidStatus={placeMaximumBidStatus}
					/>
				)}
			</Formik>
		</Modal>
	);
};

export default PlaceBidModal;

import { Storage } from 'aws-amplify';
import myConfig from '../../config';
import {
	ImageSize,
	RootPathForStorageBucket,
	StorageProvider,
	uncompressedBucketConfig,
} from './storageService.model';
import { getStoredItemPath } from './storageService.utils';

export const getCloudFrontImageUrl = ({
	itemId,
	parentPath,
	rootPath,
	imageSize = '',
}: {
	itemId: string;
	parentPath?: string;
	rootPath: RootPathForStorageBucket;
	imageSize?: ImageSize | '';
}) => {
	const basePath = `${myConfig.assets.base}/public/`;
	const itemPath = getStoredItemPath({ rootPath, parentPath, itemId });

	const path = basePath + itemPath + imageSize;

	return path.includes('/@') ? '' : path; // @size should only be appended to an image name. Return an empty string rather than a broken link
};

export const getImageUrl = async ({
	itemId,
	parentPath,
	rootPath,
	imageSize,
	storageProvider,
}: {
	itemId: string;
	parentPath?: string;
	rootPath: RootPathForStorageBucket;
	imageSize?: ImageSize;
	storageProvider: StorageProvider;
}) => {
	if (storageProvider === 's3') {
		const itemPath = getStoredItemPath({ itemId, parentPath, rootPath });
		const url = (await Storage.get(itemPath, uncompressedBucketConfig)) as string;

		if (!url) {
			throw new Error('Storage returned an empty string');
		}

		return url;
	}

	return getCloudFrontImageUrl({ itemId, parentPath, rootPath, imageSize });
};

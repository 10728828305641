import React from 'react';
import { Delete20Regular, ArrowUndo20Regular, GridDotsRegular } from '@fluentui/react-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LoadingWrapper from '../../../LoadingWrapper';
import useImageUrl from '../../../../queries/useImageUrl';

const LotImageThumbnail = ({
	lotId,
	imageName,
	handleDelete: deleteImage,
	isStagedForDeletion,
	onClickImage,
	activeImageName,
	id,
}: {
	lotId: string;
	imageName: string;
	handleDelete: (imageUrlToDelete: string) => void;
	isStagedForDeletion: boolean;
	onClickImage: () => void;
	activeImageName?: string;
	id: string;
}) => {
	const { status: imageLoadStatus, data: imageSrc } = useImageUrl({
		itemId: imageName,
		parentPath: lotId,
		imageSize: '@1x',
		rootPath: 'Lots',
		storageProvider: 's3',
	});

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<li
			className={`border-base w-full rounded-lg bg-neutral2 ${
				activeImageName === imageName ? 'opacity-50 grayscale filter' : 'opacity-100'
			} flex flex-col`}
			ref={setNodeRef}
			style={style}
			id={id}
		>
			<div className="border-b-base flex w-full justify-between rounded-t-lg bg-neutral1 py-2">
				<button
					{...attributes}
					{...listeners}
					className="hover:bg-blue-100 ml-2 flex cursor-move items-center justify-center rounded-lg px-1"
				>
					<GridDotsRegular />
				</button>
				<button className="pr-2" onClick={() => deleteImage(imageName)}>
					{isStagedForDeletion ? <ArrowUndo20Regular /> : <Delete20Regular />}
				</button>
			</div>
			<div className="flex flex-grow justify-center rounded-lg p-4">
				<button onClick={onClickImage} className="w-full">
					<LoadingWrapper queryStatuses={[imageLoadStatus]}>
						<img
							src={imageSrc}
							className={`w-full object-contain object-center ${
								isStagedForDeletion ? 'opacity-50' : 'opacity-100'
							}`}
							alt="Lot Image"
							key={imageSrc}
						/>
					</LoadingWrapper>
				</button>
			</div>
		</li>
	);
};

export default LotImageThumbnail;

import React, { useState } from 'react';
import { compareAsc, compareDesc } from 'date-fns';
import AddCommentModal from './AddCommentModal/AddCommentModal';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import OrderCommentEntry from './OrderCommentEntry';
import { OrderCommentsSortDirection } from './OrderComments.model';
import {
	CheckoutOrder,
	CheckoutOrderComment,
	CheckoutOrderCommentKind,
} from '../../../../../../services/ordersService/ordersService.model';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';

const OrderComments = ({
	orderNumber,
	newCommentKind = 'order-comment',
	filters = [],
}: {
	orderNumber: string;
	newCommentKind?: CheckoutOrderCommentKind;
	filters?: CheckoutOrderCommentKind[];
}) => {
	const { data: order = {} as CheckoutOrder } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const [shouldShowAddCommentModal, setShouldShowAddCommentModal] = useState(false);

	const { comments = [] } = order;

	const getSortedComments = (direction: OrderCommentsSortDirection): CheckoutOrderComment[] => {
		if (comments.length === 0) {
			return comments;
		}

		if (direction === 'Ascending') {
			return comments.sort((a, b) => compareAsc(new Date(a.timestamp), new Date(b.timestamp)));
		}

		return comments.sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)));
	};
	const sortedComments = getSortedComments('Descending');

	const filteredComments =
		filters.length > 0 && sortedComments.length > 0
			? sortedComments.filter((comment) => filters.includes(comment.kind))
			: sortedComments;

	return (
		<>
			<Card>
				<div className="flex flex-row items-baseline justify-between">
					<h2 className="text-subtitle1">Comments</h2>
					<div className="flex">
						<Button
							className="link pr-0"
							kind="tertiary"
							onClick={() => setShouldShowAddCommentModal(true)}
						>
							Add Comment
						</Button>
					</div>
				</div>
				{comments.length > 0 ? (
					<div className="mt-6 max-h-80 overflow-y-auto">
						{filteredComments.map((comment, index) => (
							<div className={index > 0 ? 'mt-4' : ''} key={`${comment.timestamp}-${comment.text}`}>
								<OrderCommentEntry comment={comment} />
							</div>
						))}
					</div>
				) : (
					<div className="text-body1 italic">No comments yet.</div>
				)}
			</Card>
			{shouldShowAddCommentModal && (
				<AddCommentModal
					onClose={() => setShouldShowAddCommentModal(false)}
					orderNumber={orderNumber}
					newCommentKind={newCommentKind}
				/>
			)}
		</>
	);
};

export default OrderComments;

import { compareAsc } from 'date-fns';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../components/ActionModal/ActionModal';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Dropdown from '../../../../../../components/Dropdown';
import Input from '../../../../../../components/Input';
import Label from '../../../../../../components/Label';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import DueDateSection from './DueDateSection';
import RecordRefundModal from './RecordRefundModal';
import { OrderStatusTransitionRule } from './OrderStatus.model';
import { getTransitionRule } from './OrderStatus.utils';
import { updateInvoiceComments } from '../../../../../../services/invoicesService';
import {
	CheckoutOrder,
	CheckoutOrderComment,
	InvoiceStatus,
	PAYMENT_METHODS_WITH_SURCHARGES,
	PaymentMethod,
	PaymentMethodFees,
	TaxesByTaxablePaymentMethod,
} from '../../../../../../services/ordersService/ordersService.model';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import useCurrentUser from '../../../../../../queries/useCurrentUser';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';
import useOrderInvoicePDF from '../../../../../../queries/useOrderInvoicePDF';
import { updateOrder } from '../../../../../../services/ordersService';
import { CurrentUser } from '../../../../../../services/userService/userService.model';
import { formatDate } from '../../../../../../utils/formatters';
import usePickOrderSheetPdf from '../../../../../../queries/usePickOrderSheetPdf';

const OrderStatus = ({ paymentMethod }: { paymentMethod: PaymentMethod }) => {
	const { orderNumber } = useParams<{ orderNumber: string }>();

	const { data: order = {} as CheckoutOrder } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const { data: orderInvoicePDF = new Blob() } = useOrderInvoicePDF(orderNumber);

	const { data: pickOrderSheetPdf } = usePickOrderSheetPdf(orderNumber);

	const [orderStatus, setOrderStatus] = useState<InvoiceStatus>(order.invoiceStatus);

	const [selectedOrderStatus, setSelectedOrderStatus] = useState<InvoiceStatus>(
		order.invoiceStatus
	);
	const [paidOrderStatusNotes, setPaidOrderStatusNotes] = useState('');

	const [shouldShowDefaultedConfirmationModal, setShouldShowDefaultedConfirmationModal] =
		useState(false);
	const [shouldShowRecordRefundModal, setShouldShowRecordRefundModal] = useState<boolean>(false);
	const [transitionRules, setTransitionRules] = useState<OrderStatusTransitionRule | null>(
		getTransitionRule(orderStatus)
	);

	useEffect(() => {
		setOrderStatus(order.invoiceStatus);
		const transitionRules = getTransitionRule(order.invoiceStatus);
		setTransitionRules(transitionRules);
	}, [order.invoiceStatus]);

	useEffect(() => {
		if (order.invoiceStatus !== 'Defaulted' && selectedOrderStatus === 'Defaulted') {
			setShouldShowDefaultedConfirmationModal(true);
		}

		return;
	}, [selectedOrderStatus]);

	const { mutate: doUpdateOrder, status: updateOrderStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderStatus, order.invoiceNumber, selectedOrderStatus],
		mutationFn: () =>
			updateOrder({
				fieldsToUpdate: {
					invoiceStatus: selectedOrderStatus as InvoiceStatus,
					...(selectedOrderStatus === 'Paid' && { paymentMethod: paymentMethod as PaymentMethod }),
				},
				orderNumber: order.invoiceNumber,
			}),
	});

	const { updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber: order.invoiceNumber,
		enabled: true,
	});

	const getUpdatedComments = () => {
		const author = {
			authorDisplayName: currentUser.currentUserFullName || 'unknown',
			authorId: currentUser.currentUserId,
		};
		const timestamp = new Date().toISOString();
		const paidStatusComment: CheckoutOrderComment = {
			author: author,
			kind: 'order-paid',
			text: paidOrderStatusNotes,
			timestamp,
		};
		const updatedStatusComment: CheckoutOrderComment = {
			author: author,
			kind: 'order-status',
			text: `Status updated to ${selectedOrderStatus}.`,
			timestamp,
		};

		const orderCommentsIsArray = Array.isArray(order.comments);

		if (selectedOrderStatus === 'Paid' && orderCommentsIsArray) {
			return [...order.comments, updatedStatusComment, paidStatusComment];
		} else if (selectedOrderStatus === 'Paid' && !orderCommentsIsArray) {
			return [updatedStatusComment, paidStatusComment];
		} else if (selectedOrderStatus !== 'Paid' && orderCommentsIsArray) {
			return [...order.comments, updatedStatusComment];
		} else {
			return [updatedStatusComment];
		}
	};

	const handleCancelClick = () => {
		setSelectedOrderStatus(order.invoiceStatus);

		if (shouldShowDefaultedConfirmationModal) {
			setShouldShowDefaultedConfirmationModal(false);
		}
	};

	const handleDownloadClick = () =>
		fileDownload(new Blob([orderInvoicePDF]), `order-${orderNumber}-invoice.pdf`);

	const handleKeyDown = (key: string) => {
		if (key === 'Enter' && paidOrderStatusNotes) {
			handleOrderStatusUpdate();
		} else if (key === 'Enter' && !paidOrderStatusNotes) {
			showErrorToast(
				"Comments are required when marking an order as 'Paid'. Please enter a comment and try again."
			);
		}
	};

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const handleOrderStatusUpdate = () => {
		if (selectedOrderStatus === 'Paid') {
			if (!paidOrderStatusNotes) {
				showErrorToast(
					"Comments are required when marking an order as 'Paid'. Please enter a comment and try again."
				);

				return;
			}

			if (paymentMethod === 'balance' && typeof accountBalance === 'undefined') {
				showErrorToast('Undefined accountBalance error. Please notify the #mvp-cs-channel');

				return;
			}

			if (
				paymentMethod === 'balance' &&
				typeof accountBalance === 'number' &&
				accountBalance < orderTotal
			) {
				showErrorToast('Insufficient balance. Add a balance adjustment to use balance.');

				return;
			}
		}

		doUpdateOrder(undefined, {
			onSuccess: async (updatedOrder: CheckoutOrder) => {
				showSuccessToast('Order status updated.');

				setOrderStatus(selectedOrderStatus as InvoiceStatus);
				setSelectedOrderStatus(selectedOrderStatus);

				const newRules: OrderStatusTransitionRule | null = getTransitionRule(selectedOrderStatus);
				setTransitionRules(newRules);

				const updatedComments = getUpdatedComments();

				updateInvoiceComments({
					auction_id: order.auctionId,
					invoice_id: order.invoiceId,
					comments: updatedComments,
				});

				updateOrderInCache({ ...updatedOrder, comments: updatedComments });

				if (shouldShowDefaultedConfirmationModal) {
					setShouldShowDefaultedConfirmationModal(false);
				}
			},
			onError: () => showErrorToast('Something went wrong. Please try again later.'),
		});
	};

	const handlePrintPickSheet = () => {
		const pickSheetUrl = URL.createObjectURL(pickOrderSheetPdf);

		window.open(pickSheetUrl, '_blank');
	};

	const canSelect = transitionRules?.allowedTo && updateOrderStatus !== 'loading';
	const canClick = canSelect && selectedOrderStatus.length > 0;
	const defaultStatusOption = { value: '', label: 'Select ...' };
	const transitionOptions = transitionRules?.allowedTo || [];
	const selectedTransitionOption = transitionOptions.find(
		({ value }) => value === selectedOrderStatus
	);

	const {
		comments = [],
		costMetadata: {
			accountBalance,
			paymentOptionFees = {} as PaymentMethodFees,
			subtotal,
			taxes = {} as TaxesByTaxablePaymentMethod,
		} = {},
	} = order;

	const mostRecentOrderPaidNote = comments
		.filter((comment) => comment.kind === 'order-paid')
		.sort((a, b) => compareAsc(new Date(a.timestamp), new Date(b.timestamp)))
		.pop();

	const hasPaymentMethodSurcharge = PAYMENT_METHODS_WITH_SURCHARGES.includes(paymentMethod);
	const paymentMethodSurcharge = paymentOptionFees[paymentMethod] || 0;
	const taxesByPaymentMethod = taxes[paymentMethod] || 0;

	const orderTotal = hasPaymentMethodSurcharge
		? subtotal + paymentMethodSurcharge + taxesByPaymentMethod
		: subtotal + taxesByPaymentMethod;

	return (
		<Card>
			<div className="flex flex-row items-baseline justify-between">
				<h2 className="text-subtitle1">Order Status</h2>
				<div>
					<Button
						className="link pr-0"
						kind="tertiary"
						onClick={handlePrintPickSheet}
						disabled={!pickOrderSheetPdf}
					>
						Print Pick Sheet
					</Button>
					<Button
						className="link pr-0"
						kind="tertiary"
						onClick={handleDownloadClick}
						disabled={orderStatus === 'Blocked'}
					>
						Download Invoice
					</Button>
				</div>
			</div>
			{order.auctionId !== 'fixed_price_marketplace' && (
				<>
					<div className="mt-4">
						<Label>Invoice Date</Label>
						<p>{formatDate(order.approvedDate, { invalidDateMessage: 'Not Provided' })}</p>
					</div>
					<DueDateSection />
				</>
			)}
			<div className="mt-4">
				<Label>Payment Date</Label>
				<p>{formatDate(order.paymentDate, { invalidDateMessage: 'Not Paid' })}</p>
			</div>
			<div className="border-b-base m-8" />
			<div className="mt-4">
				<div className="flex items-baseline justify-between">
					<Label>Current Status</Label>
					{orderStatus === 'Paid' && (
						<Button
							className="link"
							isInline={true}
							kind="tertiary"
							onClick={() => setShouldShowRecordRefundModal(true)}
						>
							Issue Refund
						</Button>
					)}
				</div>
				<p>{orderStatus.replace('_', ' ')}</p>
			</div>
			{order.invoiceStatus === 'Paid' && mostRecentOrderPaidNote?.text && (
				<div className="mt-4">
					<Label>Notes</Label>
					<div>{mostRecentOrderPaidNote?.text}</div>
				</div>
			)}
			{orderStatus !== 'Paid' && (
				<>
					<div className="mt-4">
						<Label htmlFor="payment-method">Update Status To</Label>
						<Dropdown
							isDisabled={!canSelect}
							name="order-status-transition"
							onChange={({ value }) => {
								setSelectedOrderStatus(value as InvoiceStatus);
							}}
							key={orderStatus}
							options={[defaultStatusOption, ...transitionOptions]}
							value={selectedTransitionOption || defaultStatusOption}
							placeholder="Select ..."
						/>
					</div>
					{selectedTransitionOption?.value === 'Paid' && (
						<div className="mt-4">
							<Label htmlFor="paid-status-notes">Notes</Label>
							<Input
								name="paid-status-notes"
								className="w-full"
								defaultValue={paidOrderStatusNotes}
								onChange={({ target: { value } }) => setPaidOrderStatusNotes(value)}
								onKeyDown={({ key }) => handleKeyDown(key)}
							/>
						</div>
					)}

					<div className="mt-4 flex flex-row items-center">
						<Button
							className="mr-2"
							disabled={!canClick}
							id="button-save"
							isLoading={updateOrderStatus === 'loading'}
							kind="primary"
							onClick={handleOrderStatusUpdate}
							size="medium"
						>
							Save Changes
						</Button>
						<Button
							className="mr-2"
							disabled={!canClick}
							id="button-cancel"
							kind="secondary"
							onClick={handleCancelClick}
							size="medium"
						>
							Cancel
						</Button>
					</div>
				</>
			)}
			{shouldShowRecordRefundModal && (
				<RecordRefundModal
					order={order}
					paymentMethod={paymentMethod}
					onClose={() => setShouldShowRecordRefundModal(false)}
				/>
			)}
			{shouldShowDefaultedConfirmationModal && (
				<ActionModal
					confirmButtonLabel="Save"
					isDangerous={true}
					title="Defaulting Invoice"
					onCancel={handleCancelClick}
					onClose={() => setShouldShowDefaultedConfirmationModal(false)}
					onConfirm={handleOrderStatusUpdate}
				>
					<div className="h-full flex grow items-center justify-center text-subtitle1">
						Are you sure you want to default this invoice?
					</div>
				</ActionModal>
			)}
		</Card>
	);
};

export default OrderStatus;

import { useQuery } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { QueryKeys } from '../queryKeys';
import { getPickOrderSheetPdf } from '../../services/ordersService';

const usePickOrderSheetPdf = (orderNumber: string) => {
	const queryKey = [QueryKeys.GetPickOrderSheet, orderNumber];

	return useQuery(queryKey, () => getPickOrderSheetPdf(orderNumber), {
		enabled: !!orderNumber,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the pick order sheet. Please refresh and try again.'
			),
	});
};

export default usePickOrderSheetPdf;

import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import NewAuctionPicker from '../../../components/NewAuctionPicker';
import useAuctionById from '../../../queries/useAuctionById';
import ShipmentDashboard from './Dashboard';
import PrintPickSheetsButton from './PrintPickSheetsButton';

const ShipmentManager = () => {
	const history = useHistory();
	const { auctionId = '' } = useParams<{ auctionId?: string }>();
	const { data: auction, status: auctionStatus } = useAuctionById(auctionId);

	return (
		<LoadingWrapper queryStatuses={[auctionStatus]}>
			<Card className="mb-4">
				<h1 className="text-display4">Shipment Manager</h1>
				<h2 className="text-display5 mb-4">{auction?.title ?? 'No Auction Selected'}</h2>
				<div className="flex">
					<NewAuctionPicker
						selectedAuctionId={auctionId}
						setSelectedAuctionId={(selectedAuctionId) =>
							history.push(`/auctions/shipmentmanager/${selectedAuctionId}`)
						}
						allowedAuctionStatuses={['Completed']}
					/>
					<PrintPickSheetsButton />
				</div>
			</Card>
			{auction && <ShipmentDashboard auction={auctionId} auction_title={auction.title} />}
		</LoadingWrapper>
	);
};

export default ShipmentManager;

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Route, Redirect } from 'react-router-dom';

/** @deprecated - use RequireAuth */
class ProtectedRoute extends Component {
	constructor(props) {
		super(props);

		this.onLoad = this.onLoad.bind(this);

		this.state = {
			isAuthenticating: true,
			isAuthenticated: false,
		};
	}

	componentDidMount() {
		if (!this.state.isAuthenticated) this.onLoad();
	}

	shouldComponentUpdate(nextProps, nextState) {
		// If we're not in the middle of an authentication but the user isn't logged in, let's try to
		if (
			!this.state.isAuthenticating &&
			!nextState.isAuthenticating &&
			!this.state.isAuthenticated &&
			!nextState.isAuthenticated
		) {
			// If this is set, it means we just kicked off another onLoad, so no need to start it again
			if (!nextState.isAuthenticating) {
				this.onLoad();
			}
		}

		// We should re-render as long as we're not currently waiting for an auth token
		return !nextState.isAuthenticating;
	}

	// Based on a pattern from https://serverless-stack.com/chapters/load-the-state-from-the-session.html
	async onLoad() {
		this.setState({
			isAuthenticating: true,
		});

		try {
			await Auth.currentSession();

			this.setState({
				isAuthenticated: true,
			});
		} catch (e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}

		this.setState({
			isAuthenticating: false,
		});
	}

	render() {
		if (this.state.isAuthenticating) return null;

		const {
			component: Component,
			isAllowedByRole = true,
			redirectPath = '/',
			...rest
		} = this.props;

		return (
			<Route
				{...rest}
				render={(props) => {
					if (this.state.isAuthenticated && isAllowedByRole) {
						return <Component {...props} />;
					} else {
						return (
							<Redirect
								to={{
									pathname: redirectPath,
									state: {
										from: props.location,
									},
								}}
							/>
						);
					}
				}}
			/>
		);
	}
}

export default ProtectedRoute;

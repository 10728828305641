import React from 'react';
import Select, { GroupBase, StylesConfig, Theme } from 'react-select';
import AsyncSelect from 'react-select/async';
import useUserRole from '../../queries/useUserRole';
import { AsyncDropdownMenuProps, DropdownMenuItem, DropdownMenuProps } from './Dropdown.model';

const Dropdown = (props: DropdownMenuProps | AsyncDropdownMenuProps) => {
	const {
		allowedRoles = [],
		inputValue,
		isDisabled,
		kind = 'static',
		name,
		onChange,
		options,
		placeholder,
	} = props;
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	const sharedStyles: StylesConfig<DropdownMenuItem, false, GroupBase<DropdownMenuItem>> = {
		indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
	};

	const generateTheme = (theme: Theme) => ({
		...theme,
		borderRadius: '.5rem',
		colors: {
			...theme.colors,
			primary: '#000',
			primary25: 'rgba(250, 250, 250, 1)',
			primary50: 'rgba(250, 250, 250, 1)',
		},
	});

	if (kind === 'async') {
		const { onInputChange, loadOptions, onChange } = props as AsyncDropdownMenuProps;

		return (
			<AsyncSelect
				inputValue={inputValue}
				isClearable={true}
				isDisabled={isDisabled || isForbidden}
				loadOptions={loadOptions}
				name={name}
				/** @ts-ignore - weird typing on their part */
				onChange={onChange}
				onInputChange={onInputChange}
				options={options}
				placeholder={placeholder}
				styles={sharedStyles}
				/** @ts-ignore - weird typing on their part */
				theme={generateTheme}
			/>
		);
	}

	const [firstOption] = options || [];

	return (
		<Select
			defaultValue={props.defaultOption || firstOption}
			isDisabled={isDisabled || isForbidden}
			name={name}
			/** @ts-ignore - weird typing on their part */
			onChange={onChange}
			options={options}
			styles={sharedStyles}
			value={(props as DropdownMenuProps).value}
			/** @ts-ignore - weird typing on their part */
			theme={generateTheme}
		/>
	);
};

export default Dropdown;

import React, { Fragment, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Switch } from '@material-ui/core';
import { StripHtml } from '../../../zenauction-ui-shared/Utilities';
import Editor from '../../../zenauction-ui-shared/Components/Elements/Editor';
import config from '../../../config';
import InvoiceDetailsTable from './InvoiceDetailsTable';
import {
	getInvoiceDetails,
	updateInvoiceComments,
	updateInvoicePaymentReminderStatus,
} from '../../../services/invoicesService';

const useStyles = makeStyles({
	bold: {
		fontWeight: 'bold',
	},
	details: {
		marginBottom: '8px',
		'& .title': {
			color: 'gray',
		},
	},
});

const InvoiceDetailsCard = ({
	auction_id = '',
	invoice_id = '',
	invoice_number = '',
	auction_title = '',
	paid_date = '',
}) => {
	const classes = useStyles();
	const [invoiceDetails, setInvoiceValue] = useState({});
	const [comments, setComments] = useState(0);
	const [invoiceAmount, setInvoiceAmount] = useState(0);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [disablePaymentReminders, setDisablePaymentReminders] = useState(false);

	useEffect(() => {
		getInvoiceDetails({
			auction_id,
			invoice_id,
		}).then((invoiceObj) => {
			console.log(invoiceObj);
			let totalAmount = 0;
			invoiceObj.lots.forEach((lot) => {
				lot.location =
					lot.room || lot.bin ? `${lot.room || ''} ${lot.bin || ''}`.trim() : 'Unknown';
				if (lot.buyer_premium) {
					totalAmount += lot.current_price * (lot.buyer_premium / 100);
				}
				totalAmount += lot.current_price;
			});
			setInvoiceValue(invoiceObj);
			setInvoiceAmount(totalAmount);
			setComments(invoiceObj.comments);
			setDisablePaymentReminders(invoiceObj.disable_payment_reminders);
			setIsDataLoaded(true);
		});
	}, []);

	const handleCommentUpdates = (data) => {
		if (!data || data === comments) return;
		updateInvoiceComments({
			auction_id: auction_id,
			invoice_id: invoice_id,
			comments: data,
		}).then((result) => {
			console.log('Comments updated: ' + result);

			setComments(data);
		});
	};

	const togglePaymentReminders = () => {
		const toggle = !disablePaymentReminders;
		updateInvoicePaymentReminderStatus({
			auction_id: auction_id || '',
			invoice_id: invoice_id || '',
			disable_payment_reminders: toggle,
		}).then(() => {
			setDisablePaymentReminders(toggle);
		});
	};

	const {
		invoice_status,
		stripe_customer_email,
		stripe_customer_name,
		stripe_customer_shipping,
		stripe_invoice_id,
		lots,
	} = invoiceDetails;
	const details = [
		{ title: 'Invoice Number', content: [invoice_number || 'N/A'] },
		{
			title: 'Account details',
			content: [stripe_customer_name || 'N/A', stripe_customer_email || 'N/A'],
		},
		{
			title: 'Shipping Details',
			content: [
				stripe_customer_shipping?.name || 'N/A',
				stripe_customer_shipping?.address?.line1 + ' ' + stripe_customer_shipping?.address?.line2 ||
					'N/A',
				stripe_customer_shipping?.address?.city || 'N/A',
				stripe_customer_shipping?.address?.state || 'N/A',
				stripe_customer_shipping?.address?.country || 'N/A',
				stripe_customer_shipping?.address?.postal_code || 'N/A',
			],
		},
	];

	return !isDataLoaded ? (
		<div style={{ display: 'flex', padding: '30px', height: '800px' }}>
			<div style={{ position: 'absolute', left: '50%', top: '50%' }}>
				<CircularProgress />
			</div>
		</div>
	) : (
		<div style={{ display: 'flex', padding: '30px', height: 'fit-content' }}>
			<div style={{ width: '200px', marginRight: '16px' }}>
				<h3 className={`legacy-h3 ${classes.bold}`} style={{ marginBottom: '60px' }}>
					{stripe_customer_name}
				</h3>
				<h6 className={`legacy-h6 ${classes.bold}`}>Details</h6>
				{details?.map((detail, i) => {
					return (
						<div key={i} className={classes.details}>
							<span className="title">{detail.title}</span>
							<br />
							{detail.content?.map((line, j) => {
								return (
									<Fragment key={i + j}>
										<span key={i + j}>{line}</span>
										<br />
									</Fragment>
								);
							})}
						</div>
					);
				})}
				<br />
				{invoice_status === 'Awaiting_Payment' || invoice_status === 'Payment_Failed' ? (
					<div>
						<span className="title">Payment Reminders</span>
						<br />
						<Switch
							color="primary"
							checked={!disablePaymentReminders}
							onChange={() => togglePaymentReminders()}
							name="disablePaymentReminder"
						/>
					</div>
				) : null}
			</div>
			<div style={{ width: '1000px' }}>
				<div style={{ display: 'flex', margin: '30px 0' }}>
					<span className={classes.bold} style={{ marginRight: '4px' }}>
						Status:
					</span>
					{invoice_status}
				</div>
				<div style={{ display: 'flex', margin: '10px 0' }}>
					<span className={classes.bold} style={{ marginRight: '4px' }}>
						Payment Date:
					</span>
					{paid_date}
				</div>
				<div style={{ marginBottom: '30px' }}>
					<p className={classes.bold}>Payments:</p>
					<Button
						onClick={() => window.open(config.stripe.invoices + stripe_invoice_id, '_blank')}
						style={{ textTransform: 'none' }}
						color="primary"
						variant="outlined"
					>
						Click to manage in Stripe
					</Button>
				</div>
				<div style={{ display: 'flex', margin: '10px 0' }}>
					<span className={classes.bold} style={{ marginRight: '4px' }}>
						Auction:
					</span>
					{auction_title}
				</div>
				<div style={{ display: 'flex', margin: '10px 0' }}>
					<span className={classes.bold} style={{ marginRight: '4px' }}>
						Total Invoice Amount:
					</span>
					{invoiceAmount}
				</div>
				<div style={{ marginBottom: '30px' }}>
					<div>
						<p className={classes.bold}>Lots Won:</p>
						<InvoiceDetailsTable lots={lots} invoiceNumber={invoice_number} />
					</div>
				</div>
				<div>
					<p className={classes.bold}>Private Comments:</p>
					{isDataLoaded && (
						<Editor
							placeHolder="Enter private comments here (optional)"
							initialValue={comments}
							updateContentCallback={(value) => {
								if (window.commentEventCallback) clearTimeout(window.commentEventCallback);
								window.commentEventCallback = setTimeout(
									() => handleCommentUpdates(StripHtml(value)),
									1000
								);
							}}
							className="m-1"
						/>
					)}
					<br />
				</div>
			</div>
		</div>
	);
};
export default InvoiceDetailsCard;

import { compareDesc } from 'date-fns';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelLotWatcher, LotWatcher } from './getLotWatchers.model';

export const getLotWatchers = async ({ lotId }: { lotId: string }): Promise<LotWatcher[]> => {
	const response = await axiosAuth.get<KernelLotWatcher[]>(`${apiEndpoints.lotWatchers}/${lotId}`);

	if (response.status >= 400) {
		throw response;
	}

	return response.data
		.map((kernelLotWatcher: KernelLotWatcher) => ({
			lotId: kernelLotWatcher.lot_id,
			auctionId: kernelLotWatcher.auction_id,
			userId: kernelLotWatcher.user_id,
			timestamp: kernelLotWatcher.timestamp,
			name: kernelLotWatcher.name,
		}))
		.sort((thisWatcher, nextWatcher) =>
			compareDesc(new Date(thisWatcher.timestamp), new Date(nextWatcher.timestamp))
		);
};

import { cloneDeep } from 'lodash';
import { retrieveImageAlbum } from '../../../AdminUtils/imageUtils';
import { KernelLot } from '../../../services/lotsService/lotsService.model';

export interface ImageQuery {
	type: 'Lots' | 'CarouselImages' | 'Auctions';
	id: string;
	number_of_images: number;
}

/** @deprecated Delete after providing out LotManagerV2 */
export const loadLotImages = async (lot: KernelLot) => {
	if (!lot.number_of_images || lot.number_of_images <= 0) return lot;
	if (lot.images) {
		return { ...lot, hasPersistedImageArray: true };
	}

	const lotWithImages = cloneDeep(lot);

	const imageQuery = {} as ImageQuery;
	imageQuery.type = 'Lots';
	imageQuery.id = lotWithImages.lot_id;
	imageQuery.number_of_images = lotWithImages.number_of_images;
	const images = await retrieveImageAlbum(imageQuery);
	if (!images) return lotWithImages;

	// Pull out the primary image first
	let primaryImageIndex = -1;
	for (const [index, image] of images.entries()) {
		const imageUrl = new URL(image as string);
		if (imageUrl.pathname.substr(imageUrl.pathname.lastIndexOf('/') + 1) == '__PRIMARY-IMAGE__') {
			primaryImageIndex = index;
			lotWithImages.primary_image = [images[primaryImageIndex] as string];
			break;
		}
	}

	// Now save the rest of the images, removing the primary image if there is one
	if (primaryImageIndex >= 0) images.splice(primaryImageIndex, 1);
	lotWithImages.images = images as string[];

	return lotWithImages;
};

// Simplify a lot object for the transfer list widget
// Takes an object that contains { lotNumber: {<lot attrs>}, ...}
// and converts it to [{key, description}, ... ]
export const convertLotToTransferListArray = (lot: KernelLot) => {
	if (!lot) return undefined;
	let lot_number = lot.lot_number as number | string;
	if (lot_number) {
		lot_number = lot_number.toString();
	}
	const transferListItem = {
		title: lot.title,
		description: lot.description,
		lot_number: lot_number,
		serial_number: lot.serial_number,
		lot_id: '',
	};
	transferListItem['lot_id'] = lot['lot_id'];

	return transferListItem;
};

export const convertLotsToTransferListArray = (lots: KernelLot[] = []) => {
	if (!lots) return [];

	return lots
		.map((lot) => convertLotToTransferListArray(lot))
		.filter((convertedLot) => !!convertedLot);
};

import React from 'react';
import Hamburger from 'react-hamburgers';
import GoldinLogo from './GoldinLogo';
import { CurrentUser } from '../../../services/userService/userService.model';
import useCurrentUser from '../../../queries/useCurrentUser';
import AvatarMenu from './AvatarMenu';

const AppHeader = ({
	shouldShowSidebar,
	setShouldShowSidebar,
	isAuthenticated,
}: {
	isAuthenticated: boolean;
	shouldShowSidebar: boolean;
	setShouldShowSidebar: (newValue: boolean) => void;
}) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	return (
		<div className="h-18 sticky top-0 flex w-full items-center justify-between bg-neutral1 shadow-elevation1">
			<div className="ml-4 flex items-center">
				<GoldinLogo className="h-18" />
				{isAuthenticated && (
					<div className="ml-2 mt-2">
						<Hamburger
							active={shouldShowSidebar}
							type="elastic"
							onClick={() => setShouldShowSidebar(!shouldShowSidebar)}
						/>
					</div>
				)}
			</div>
			{isAuthenticated && (
				<div className="mr-4">
					<div>
						<AvatarMenu
							name={currentUser.currentUserFullName}
							email={currentUser.currentUserEmail}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default AppHeader;

import { LotsBySubsetParams } from './getLotsBySubset.model';

export const getRequestBodyForKindOfParams = (params: LotsBySubsetParams) => {
	switch (params.kind) {
		case 'assigned':
			return { auction_id: [params.auctionId] };
		case 'unassigned':
			return { unassigned: true };
		case 'search-term':
			return params.auctionId
				? { keyword: params.searchTerm, auction_id: [params.auctionId] }
				: { keyword: params.searchTerm };
		case 'consigned':
			return { seller_id: [params.sellerId] };
		case 'winner':
			return { winner_id: [params.winnerId] };
		default:
			return {};
	}
};

import { useQuery } from 'react-query';
import { getOffersForItem } from '../../services/offersService';
import { QueryKeys } from '../queryKeys';

const useOffersForItem = (itemId: string) =>
	useQuery([QueryKeys.GetOffersForItem, itemId], () => getOffersForItem(itemId), {
		enabled: !!itemId,
	});

export default useOffersForItem;

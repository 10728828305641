import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuctionPicker from '../../../../../components/AuctionPicker';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import PlaceBidForUserModal from '../../BidsTab/BidsTabView/PlaceBidForUserModal';
import WatchlistTable from './WatchlistTable';
import { WatchlistLot } from '../../../../../services/watchlistService/getWatchlistForUser.model';
import useWatchlistForUser from '../../../../../queries/useWatchlistForUser';
import useAuctions from '../../../../../queries/useAuctions';
import { Auction } from '../../../../../services/auctionsService';

const WatchlistTabView = () => {
	const history = useHistory();

	const { auctionId: watchlistAuctionId = '', userId } = useParams<{
		auctionId: string;
		userId: string;
	}>();

	const { data: auctions = [] as Auction[] } = useAuctions();

	const { data: watchlistLots = [] as WatchlistLot[], status: watchlistLotsStatus } =
		useWatchlistForUser({
			userId: userId,
			auctions: auctions,
		});

	const [selectedWatchlistAuctionId, setSelectedWatchlistAuctionId] = useState(watchlistAuctionId);
	const [selectedWatchlistLotId, setSelectedWatchlistLotId] = useState('');
	const [shouldShowPlaceBidModal, setShouldShowPlaceBidModal] = useState(false);

	useEffect(() => {
		history.push(`/usermanagement/customer/${userId}/watch-list/${selectedWatchlistAuctionId}`);
	}, [selectedWatchlistAuctionId]);

	const closePlaceBidModal = () => {
		setSelectedWatchlistLotId('');
		setShouldShowPlaceBidModal(false);
	};

	const openPlaceBidModal = (lotId: string) => {
		setSelectedWatchlistLotId(lotId);
		setShouldShowPlaceBidModal(true);
	};

	const uniqueWatchlistAuctionIds = watchlistLots
		.map(({ auctionId }) => auctionId)
		.filter((value, index, self) => self.indexOf(value) === index);

	return (
		<>
			{watchlistLots.length > 0 && (
				<div className="mb-4 px-4">
					<AuctionPicker
						selectedAuctionId={selectedWatchlistAuctionId}
						setSelectedAuctionId={setSelectedWatchlistAuctionId}
						shouldShowUnassignedLots={false}
						allowedAuctionIds={uniqueWatchlistAuctionIds}
						allowedAuctionStatuses={['Active', 'Completed']}
					/>
				</div>
			)}
			<div className="px-4">
				<LoadingWrapper queryStatuses={[watchlistLotsStatus]}>
					<WatchlistTable openPlaceBidModal={openPlaceBidModal} watchlistLots={watchlistLots} />
				</LoadingWrapper>
			</div>
			{shouldShowPlaceBidModal && (
				<PlaceBidForUserModal lotId={selectedWatchlistLotId} onClose={closePlaceBidModal} />
			)}
		</>
	);
};

export default WatchlistTabView;

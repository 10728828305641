import { Chip } from '../../../../prizm-ui/Chip';
import { Tag } from '../../../../prizm-ui/Tag';

const UserStatusChip = ({ status, as }: { status: 'Active' | 'Inactive'; as: 'Chip' | 'Tag' }) =>
	as === 'Chip' ? (
		<Chip variant={status === 'Active' ? 'success' : 'error'}>{status}</Chip>
	) : (
		<Tag variant={status === 'Active' ? 'success' : 'error'}>{status}</Tag>
	);

export default UserStatusChip;

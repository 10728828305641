import React from 'react';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import useLayouts from '../../../queries/useLayout';
import { LayoutConfig } from '../../../services/layoutService/layoutService.model';
import BannerEditorView from './BannerEditorView';

const BannerEditor = () => {
	const { data: layoutConfig = {} as LayoutConfig, status: layoutsStatus } = useLayouts();

	return (
		<Card className="mb-8">
			<LoadingWrapper queryStatuses={[layoutsStatus]}>
				<BannerEditorView banners={layoutConfig?.banners || []} />
			</LoadingWrapper>
		</Card>
	);
};

export default BannerEditor;

import React, { useState } from 'react';
import TransferList from '../../../../zenauction-ui-shared/Components/Elements/TransferList';
import { Auction } from '../../../../services/auctionsService/auctionsService.model';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { useParams } from 'react-router-dom';
import useAuctions from '../../../../queries/useAuctions';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import Card from '../../../../components/Card';
import useLotsForAuction from '../../../../queries/useLotsForAuction';
import { convertLotsToTransferListArray } from '../LotManager.utils';
import useBulkLotReassignmentMutation from '../../../../queries/useBulkLotReassignmentMutation';
import { difference } from 'lodash';
import Button from '../../../../components/Button';

const AddLotsToAuction = () => {
	const { auctionId } = useParams<{ auctionId: string }>();
	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();
	const activeAuction = auctions.find(({ id }) => id === auctionId);

	const { data: unassignedLots = [] as KernelLot[], status: unassignedLotsStatus } =
		useLotsForAuction({ auctionId: 'Unassigned' });

	const { data: lotsForThisAuction = [] as KernelLot[], status: lotsForThisAuctionStatus } =
		useLotsForAuction({ auctionId });
	const lotIdsForThisAuction = lotsForThisAuction.map(({ lot_id }) => lot_id);

	const [lotsToUnassignFromThisAuction, setLotsToUnassignFromThisAuction] = useState<KernelLot[]>(
		[]
	);
	const [lotsToAddToThisAuction, setLotsToAddToThisAuction] = useState<KernelLot[]>([]);

	const handleLotMovement = (allLotsForThisAuction: KernelLot[]) => {
		const updatedLotIdsForThisAuction = allLotsForThisAuction.map(({ lot_id }) => lot_id);
		const lotIdsMovedLeft = difference(lotIdsForThisAuction, updatedLotIdsForThisAuction);
		const lotIdsMovedRight = difference(updatedLotIdsForThisAuction, lotIdsForThisAuction);

		const lotIdsToAddToThisAuction = lotIdsMovedRight.filter(
			(lotId) => !lotIdsForThisAuction.includes(lotId)
		);
		const updatedLotsToAddToThisAuction = lotIdsToAddToThisAuction.map(
			(lotId) => unassignedLots.find(({ lot_id }) => lot_id === lotId)!
		);
		setLotsToAddToThisAuction(updatedLotsToAddToThisAuction);

		const lotIdsToUnassignFromThisAuction = lotIdsMovedLeft.filter((lotId) =>
			lotIdsForThisAuction.includes(lotId)
		);
		const updatedLotsToUnassignFromThisAuction = lotIdsToUnassignFromThisAuction.map(
			(lotId) => lotsForThisAuction.find(({ lot_id }) => lotId === lot_id)!
		);
		setLotsToUnassignFromThisAuction(updatedLotsToUnassignFromThisAuction);
	};

	const {
		isReady: canAssignLots,
		mutate: handleAssigningLots,
		status: assignLotsStatus,
	} = useBulkLotReassignmentMutation({
		originalAuctionId: 'Unassigned',
		destinationAuctionId: auctionId,
		lotIds: lotsToAddToThisAuction.map(({ lot_id }) => lot_id),
	});
	const {
		isReady: canUnassignLots,
		mutate: handleUnassigningLots,
		status: unassignLotsStatus,
	} = useBulkLotReassignmentMutation({
		originalAuctionId: auctionId,
		destinationAuctionId: 'Unassigned',
		lotIds: lotsToUnassignFromThisAuction.map(({ lot_id }) => lot_id),
	});

	const isLoading = [unassignLotsStatus, assignLotsStatus].includes('loading');
	const isDisabled = [canAssignLots, canUnassignLots].some((value) => !value) || isLoading;

	const handleLotSwapping = () => {
		if (lotsToAddToThisAuction.length > 0) {
			handleAssigningLots();
		}
		if (lotsToUnassignFromThisAuction.length > 0) {
			handleUnassigningLots();
		}
	};

	return (
		<LoadingWrapper
			queryStatuses={[auctionsStatus, unassignedLotsStatus, lotsForThisAuctionStatus]}
		>
			<>
				<Card className="mb-8">
					<h2 className="mb-8 text-subtitle1">{`Add lots to "${activeAuction?.title}"`}</h2>
					<div className="mb-4">
						<TransferList
							availableOptions={convertLotsToTransferListArray(unassignedLots)}
							selectedOptions={convertLotsToTransferListArray(lotsForThisAuction)}
							updateSelectedCallback={handleLotMovement}
							idField="lot_id"
							sortBy="title"
							availableTitle="Available Lots"
							selectedTitle="Lots Assigned To This Auction"
						/>
						<Button
							kind="primary"
							onClick={handleLotSwapping}
							disabled={isDisabled}
							isLoading={isLoading}
							className="mt-4"
						>
							Save Selected Lot Changes To Auction
						</Button>
					</div>
				</Card>
			</>
		</LoadingWrapper>
	);
};

export default AddLotsToAuction;

import React from 'react';
import { Icon as Iconify, IconProps as IconifyProps } from '@iconify/react';
import { GlobalIconsContext } from './GlobalIcons';
import { ICONS, IconName } from './icons.shared';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | number;
export type IconRotate = 'cw' | 'ccw' | '180';

type AriaProps =
	| {
			'aria-hidden'?: false | undefined;
			'aria-label': string;
	  }
	| {
			'aria-hidden': true;
			'aria-label'?: string;
	  };

export type IconProps = {
	name: IconName;
	rotate?: IconRotate;
	size?: IconSize;
	spin?: boolean;
} & Omit<IconifyProps, 'aria-hidden' | 'aria-label' | 'icon' | 'rotate'> &
	AriaProps;

const getClasses = ({
	flip,
	rotate,
	size,
	spin,
}: {
	flip?: IconifyProps['flip'];
	rotate?: IconRotate;
	size: IconSize;
	spin: boolean;
}) => {
	const classes = ['transition', ...(spin ? ['animate-spin'] : [])];
	if (typeof size === 'string') {
		const iconSize = (
			{
				xs: 2,
				sm: 4,
				md: 5,
				lg: 6,
				xl: 8,
				xxl: 12,
			} as { [_ in Exclude<IconSize, number>]: number }
		)[size];

		classes.push(`h-${iconSize}`, `w-${iconSize}`);
	} else {
		classes.push(`h-${size}`, `w-${size}`);
	}

	switch (flip) {
		case 'horizontal':
			classes.push('transform', '-scale-y-100');
			break;
		case 'vertical':
			classes.push('transform', '-scale-x-100');
			break;
	}

	switch (rotate) {
		case 'cw':
			classes.push('transform', 'rotate-90');
			break;
		case 'ccw':
			classes.push('transform', '-rotate-90');
			break;
		case '180':
			classes.push('transform', 'rotate-180');
			break;
	}

	return classes.join(' ');
};

export const Icon = ({
	flip,
	name,
	rotate,
	size = 'lg',
	spin = false,
	'aria-hidden': ariaHidden = true,
	'aria-label': ariaLabel,
	...rest
}: IconProps) => {
	const { icons } = React.useContext(GlobalIconsContext);

	const props = {
		className: getClasses({ flip, rotate, size, spin }),
		role: 'img',
		'aria-hidden': ariaHidden,
		'aria-label': ariaLabel,
		...rest,
	};

	if (icons.includes(name))
		return (
			<svg viewBox="0 0 24 24" {...props}>
				<use xlinkHref={`#svg-${name}`} />
			</svg>
		);

	return <Iconify icon={ICONS[name]} {...props} />;
};

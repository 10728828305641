import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelUser, User, UserAccountNote } from './userService.model';

/** @TODO slim down to only the necessary fields */
export const updateUserNotes = async ({
	notes,
	user,
}: {
	notes: UserAccountNote[];
	user: User;
}) => {
	const updatedUser: KernelUser = {
		bidding_power: user.biddingPower,
		commission: user.commission,
		company_id: user.companyId,
		consignment_manager_id: user.consignmentManagerId,
		credit_limit: user.creditLimit,
		disabled: user.isDisabled,
		email: user.email,
		family_name: user.familyName,
		given_name: user.givenName,
		has_us_address: user.hasUSAddress,
		latest_version: user.latestVersion,
		notes: JSON.stringify(notes),
		payment_address: {
			city: user?.paymentAddress?.city ?? '',
			country: user?.paymentAddress?.country ?? '',
			line1: user?.paymentAddress?.line1 ?? '',
			line2: user?.paymentAddress?.line2 ?? '',
			postal_code: user?.paymentAddress?.postalCode ?? '',
			state: user?.paymentAddress?.state ?? '',
		},
		phone_number: user.phoneNumber,
		registration_date: user.registrationDate,
		shipping_address_city: user.shippingAddress.city,
		shipping_address_country: user.shippingAddress.country,
		shipping_address_line1: user.shippingAddress.line1,
		shipping_address_line2: user.shippingAddress.line2,
		shipping_address_postal_code: user.shippingAddress.postalCode,
		shipping_address_state: user.shippingAddress.state,
		stripe_customer_id: user.stripeCustomerId,
		verified_tin: user.verifiedTin,
		user_id: user.id,
	};

	const body = {
		user: updatedUser,
	};

	const response = await postAuthed(`${apiEndpoints.update_notes}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.Attributes;
};

import { useQuery, useQueryClient } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { getOrderByNumber } from '../../services/ordersService';
import { CheckoutOrder } from '../../services/ordersService/ordersService.model';
import { QueryKeys } from '../queryKeys';

const useOrderByNumber = ({
	orderNumber,
	enabled = false,
}: {
	orderNumber: string;
	enabled?: boolean;
}) => {
	const queryKey = [QueryKeys.GetOrderByNumber, orderNumber];
	const baseQuery = useQuery(queryKey, () => getOrderByNumber(orderNumber), {
		enabled,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the order. Please refresh and try again.'
			),
	});

	const queryClient = useQueryClient();
	const updateOrder = (updatedFields: Partial<CheckoutOrder>) =>
		queryClient.setQueryData(queryKey, (originalOrder: CheckoutOrder = {} as CheckoutOrder) => ({
			...originalOrder,
			...updatedFields,
		}));

	return { ...baseQuery, updateOrder };
};

export default useOrderByNumber;

import { UserRoleName } from '../../../../services/userService/userService.model';

//	Sorted in display order
export const ALL_CUSTOMER_MANAGEMENT_TAB_PATHS = {
	customerOverview: 'customer-overview',
	invoices: 'invoices',
	sellerStatements: 'seller-statements',
	balance: 'balance',
	shipmentManager: 'shipment-manager',
	activity: 'activity',
	bids: 'bids',
	watchList: 'watch-list',
	consignedLots: 'consigned-lots',
	vault: 'vault',
	notes: 'notes',
	mergeAccounts: 'merge-accounts',
};

export interface CustomerManagementTab {
	allowedRoles?: UserRoleName[];
	label: string;
	path: string;
}

export const DEFAULT_ALLOWED_ROLES: UserRoleName[] = [
	'Admin',
	'AuctionManager',
	'Consignment',
	'CustomerSupport',
	'Finance',
	'Inventory',
];

export const BASE_CUSTOMER_MANAGEMENT_TABS: CustomerManagementTab[] = [
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Customer Overview',
		path: 'customer-overview',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Invoices',
		path: 'invoices',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Seller Statements',
		path: 'seller-statements',
	},
	{
		allowedRoles: ['Admin', 'CustomerSupport', 'Finance'],
		label: 'Balance',
		path: 'balance',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Shipment Manager',
		path: 'shipment-manager',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Activity',
		path: 'activity',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Bids',
		path: 'bids',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Watch List',
		path: 'watch-list',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Consigned Lots',
		path: 'consigned-lots',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Vault',
		path: 'vault',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Notes',
		path: 'notes',
	},
	{
		allowedRoles: DEFAULT_ALLOWED_ROLES,
		label: 'Merge Accounts',
		path: 'merge-accounts',
	},
];

export const CUSTOMER_OVERVIEW_ROLES: UserRoleName[] = ['Finance', 'Admin', 'CustomerSupport'];

export type CustomerManagerTabProps = {
	activeTab: CustomerManagementTab;
	userRole: UserRoleName | null;
	onSelect: (selectedTab: CustomerManagementTab) => void;
	selectedTabFromParams: CustomerManagementTab | '';
};

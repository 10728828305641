import { compareDesc } from 'date-fns';
import { Auction } from '../../../../services/auctionsService';

export const sortAuctionsByStatus = (auctions: Auction[]): Auction[] => {
	const activeAuctions = auctions
		.filter(({ status }) => status === 'Active')
		.sort((a, b) =>
			compareDesc(new Date(a.startTimestamp ?? ''), new Date(b.startTimestamp ?? ''))
		);
	const previewAuctions = auctions
		.filter(({ status }) => status === 'Preview')
		.sort((a, b) =>
			compareDesc(new Date(a.startTimestamp ?? ''), new Date(b.startTimestamp ?? ''))
		);

	const notStartedAuctions = auctions
		.filter(({ status }) => status === 'Not_Started')
		.sort((a, b) => compareDesc(new Date(a.createdAt ?? ''), new Date(b.createdAt ?? '')));
	const pausedAuctions = auctions.filter(({ status }) => status === 'Pause');

	const completedAuctions = auctions
		.filter(({ status }) => status === 'Completed' || status === 'Withdrawn')
		.sort((a, b) => compareDesc(new Date(a.endTimestamp ?? ''), new Date(b.endTimestamp ?? '')));

	return [
		...activeAuctions,
		...previewAuctions,
		...notStartedAuctions,
		...pausedAuctions,
		...completedAuctions,
	];
};

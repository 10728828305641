export const getFilenameForLegacyImage = ({
	imageIdentifier,
}: {
	/** Legacy "identifier" will be a URL, whereas non-legacy will be a simple filename.
	 * This is not to be mistaken with any kind of proper "id" and hence the non-abbreviated spelling.
	 */
	imageIdentifier: string | { path: string };
}): string => {
	if (!imageIdentifier) {
		return imageIdentifier;
	}

	/** Even more legacy than legacy, smh */
	if (typeof imageIdentifier !== 'string') {
		return imageIdentifier.path.split('.')[0];
	}

	if (!imageIdentifier.includes('/')) {
		return imageIdentifier;
	}

	const [filenameSegment] = imageIdentifier.split('/').slice(-1);
	const [filename] = filenameSegment.split('?');
	return filename;
};

import Card from '../../../../components/Card';
import Checkbox from '../../../../components/Checkbox';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import { LOT_MANAGER_CSV_COLUMN_NAMES } from './LotManagerFilters.model';
import { KernelSummaryLot } from '../../../../services/lotsService/lotsService.model';
import { LotManagerFiltersType } from './LotManagerFilters.model';
import { KernelAuction } from '../../../../services/auctionsService/auctionsService.model';

const LotManagerFilters = ({
	currentAuction = {} as KernelAuction,
	setFilters,
	filters,
	filteredLots,
}: {
	currentAuction?: KernelAuction;
	setFilters: (newFilters: LotManagerFiltersType) => void;
	filters: LotManagerFiltersType;
	filteredLots: KernelSummaryLot[];
}) => {
	const handleFilterChange = (name: keyof LotManagerFiltersType, value: boolean) =>
		setFilters({ ...filters, [name]: value });

	const filterItems = [
		{
			name: 'authentication_status',
			value: '',
			checked: !!filters['authentication_status'],
			label: 'Authentication Status',
		},
		{ name: 'category', value: '', checked: !!filters['category'], label: 'Category' },
		{ name: 'description', value: '', checked: !!filters['description'], label: 'Description' },
		{
			name: 'buyer_premium',
			value: '',
			checked: !!filters['buyer_premium'],
			label: 'Buyer Premium',
		},
		{ name: 'commission', value: '', checked: !!filters['commission'], label: 'Commission' },
		{
			name: 'number_of_images',
			value: '',
			checked: !!filters['number_of_images'],
			label: 'Additional Images',
		},
		{
			name: 'primary_image_name',
			value: '',
			checked: !!filters['primary_image_name'],
			label: 'Primary Image',
		},
		{
			name: 'min_bid_price',
			value: '',
			checked: !!filters['min_bid_price'],
			label: 'Minimum Bid Price',
		},
		{
			name: 'current_price',
			value: '',
			checked: !!filters['current_price'],
			label: 'Current Price',
		},
		{
			name: 'reserve_amount',
			value: '',
			checked: !!filters['reserve_amount'],
			label: 'Reserve Amount',
		},
		{ name: 'sub_category', value: '', checked: !!filters['sub_category'], label: 'SubCategory' },
		{ name: 'seller_id', value: '', checked: !!filters['seller_id'], label: 'Seller' },
		{
			name: 'consignment_manager_id',
			value: '',
			checked: !!filters['consignment_manager_id'],
			label: 'Consignment Manager',
		},
		{ name: 'status', value: '', checked: !!filters['status'], label: 'Unsold Lots' },
		{
			name: 'internal_notes',
			value: '',
			checked: !!filters['internal_notes'],
			label: 'Internal Notes',
		},
		{
			name: 'authentication_notes',
			value: '',
			checked: !!filters['authentication_notes'],
			label: 'Authentication Notes',
		},
		{
			name: 'vaultable',
			value: '',
			checked: !!filters['vaultable'],
			label: 'Vaultable',
		},
	];

	const canDownloadCSV = filteredLots.length > 0;

	return (
		<Card className="mb-8">
			<h2 className="mb-4 text-subtitle1">Only show lots that are missing</h2>
			<div className="mb-4 grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
				{filterItems.map(({ name, value, checked, label }) => (
					<div className="p-2" key={name}>
						<Checkbox
							name={name}
							value={value}
							checked={checked}
							onChange={(newState: boolean) =>
								handleFilterChange(name as keyof LotManagerFiltersType, newState)
							}
							label={label}
						/>
					</div>
				))}
			</div>
			<DownloadCSVButton
				className="mr-4"
				columnNames={LOT_MANAGER_CSV_COLUMN_NAMES}
				disabled={!canDownloadCSV}
				documentName={`missing-fields-for-${currentAuction.title ?? 'unassigned-lots'}`}
				kind="secondary"
				items={filteredLots}
			/>
		</Card>
	);
};

export default LotManagerFilters;

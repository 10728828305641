import fileDownload from 'js-file-download';
import { useState } from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../../components/ActionModal';
import Button from '../../../../components/Button';
import { Parser } from 'json2csv';
import UploadCSV from '../../../../components/UploadCSV';
import { UploadCSVProps } from '../../../../components/UploadCSV/UploadCSV';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { BULK_UPLOAD_CUSTOMERS_EXAMPLE } from './BulkUploadCustomersModal.model';
import { KernelNewUser } from '../../../../services/userService/createUser.model';
import { CurrentUser } from '../../../../services/userService/userService.model';
import { QueryKeys } from '../../../../queries/queryKeys';
import { bulkUploadUsers } from '../../../../services/userService';
import useCurrentUser from '../../../../queries/useCurrentUser/useCurrentUser';

const BulkUploadCustomersModal = ({ onClose }: { onClose: () => void }) => {
	const [bulkUploadedUsers, setBulkUploadedUsers] = useState<KernelNewUser[]>([]);
	const [bulkUploadFile, setBulkUploadFile] = useState<File | null>(null);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const { mutate: doBulkUploadUsers, status: bulkUploadUsersStatus } = useMutation({
		mutationFn: (file: File) =>
			bulkUploadUsers({
				employeeId: currentUser.currentUserId,
				file,
			}),
		mutationKey: [QueryKeys.BulkUploadUsers, new Date().toISOString()],
	});

	const handleExportExampleCSV = () => {
		const jsonParser = new Parser({ eol: '\n' });

		const csv = jsonParser.parse(BULK_UPLOAD_CUSTOMERS_EXAMPLE);

		fileDownload(csv, 'bulk-upload-customers-example.csv');
	};

	const handleCancel = () => {
		setBulkUploadedUsers([]);
		setBulkUploadFile(null);
		setUploadErrors([]);

		onClose();
	};

	const handleConfirm = () => {
		if (!bulkUploadFile) {
			return;
		}

		doBulkUploadUsers(bulkUploadFile, {
			onError: () =>
				showErrorToast(
					'Something went wrong while bulk uploading customers. Please refresh and try again.'
				),
			onSuccess: () => {
				onClose();

				showSuccessToast(
					`Submitted ${bulkUploadedUsers.length} customers for processing. Please wait a few minutes and refresh your browser to view the results.`
				);
			},
		});
	};

	const uploadCSVProps: UploadCSVProps<KernelNewUser> = {
		resultsColumns: [
			{
				accessorKey: 'given_name',
				header: 'Given Name',
			},
			{
				accessorKey: 'family_name',
				header: 'Family Name',
			},
			{
				accessorKey: 'email',
				header: 'Email',
			},
			{
				accessorKey: 'phone_number',
				header: 'Phone Number',
			},
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadFile(uploadedFile);
			setBulkUploadedUsers(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadFile &&
		bulkUploadUsers.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadUsersStatus !== 'loading';

	return (
		<ActionModal
			canConfirm={canConfirm}
			confirmButtonLabel="Submit"
			isLoading={bulkUploadUsersStatus === 'loading'}
			title="Bulk Upload Customers"
			onClose={onClose}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
		>
			<Button className="link px-0 py-0" kind="tertiary" onClick={handleExportExampleCSV}>
				Download an example CSV
			</Button>
			<div className="mt-2">
				<UploadCSV
					dropzoneText={'Drag and drop a CSV (comma-separated) file here or click to select one'}
					resultsColumns={uploadCSVProps.resultsColumns}
					onComplete={uploadCSVProps.onComplete}
				/>
			</div>
		</ActionModal>
	);
};

export default BulkUploadCustomersModal;

import React, { FC } from 'react';
import { LineItemLayoutProps } from '../LineItem.model';

const LineItemLayout: FC<LineItemLayoutProps> = ({
	kind = 'primary',
	children,
	label,
	className = '',
}) => {
	return (
		<div className="mt-1 flex items-start justify-between">
			<div className={`mx-4 text-body1 ${className}`}>
				{kind === 'primary' ? (
					<h3 className={label ? 'font-semibold' : 'italic'}>{label || '(No Description)'}</h3>
				) : (
					<p className={`ml-2 ${label ? '' : 'italic'} text-body2`}>
						- {label || '(No Description)'}
					</p>
				)}
			</div>
			<div>
				<div className="text-body2">
					<div className="flex items-center">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default LineItemLayout;

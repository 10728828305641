import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import { Description, DeleteForever } from '@material-ui/icons';
import cloneDeep from 'lodash.clonedeep';
import SearchFilter from '../SearchFilter';
import { LightBlueButton } from '../../../Utilities';
import { Parser } from 'json2csv';
import fileDownload from 'js-file-download';
import SortableTableHeader from './SortableTableHeader';
import SortableTableBody from './SortableTableBody';

/* ********* SORTABLE TABLE STUFF ********** */
// based on https://www.smashingmagazine.com/2020/03/sortable-tables-react/
class SortableTable extends Component {
	constructor(props) {
		super(props);

		this.requestSort = this.requestSort.bind(this);
		this.getSortIconFor = this.getSortIconFor.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.filteredDataCallback = this.filteredDataCallback.bind(this);
		this.handleContentEditableUpdate = this.handleContentEditableUpdate.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.pasteAsPlainText = this.pasteAsPlainText.bind(this);
		this.highlightAll = this.highlightAll.bind(this);
		this.convertPagedDataIndexToItemsIndex = this.convertPagedDataIndexToItemsIndex.bind(this);

		const dataToSort = props.dataToSort;
		let page = 0;
		if (props.page) page = props.page;
		let rowsPerPage = 25;
		if (props.rowsPerPage) rowsPerPage = props.rowsPerPage;

		let rowsPerPageOptions = [5, 25, 50, 250];
		if (props.rowsPerPageOptions) rowsPerPageOptions = props.rowsPerPageOptions;

		let columns = props.columns;
		if (props.addRemoveRowButton) {
			columns = [
				{
					id: '_removeRowButtonId_',
					label: '',
					type: 'icon_button',
					buttonIcon: <DeleteForever />,
					buttonTitle: '',
					buttonColor: 'secondary',
					buttonTooltip: 'Delete',
					clickCallback: props.removeButtonCallback,
					noSortIcon: true,
					styleClass: 'p-0',
				},
				...columns,
			];
		}

		let disableAutocomplete = false;
		if (props.disableAutocomplete) {
			disableAutocomplete = true;
		}

		this.state = {
			id: uuidv4(),
			items: dataToSort,
			page: page,
			rowsPerPage: rowsPerPage,
			rowsPerPageOptions: rowsPerPageOptions,
			filteredData: dataToSort,
			pagedData: dataToSort
				? dataToSort.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				: undefined,
			sortableTableKey: 1,
			searchFilterKey: 1,
			searchTerm: this.props.lastSearchTerm || null,
			columns: columns,
			currentRowNum: null,
			disableAutocomplete: disableAutocomplete,
			exportButtonTop: props.exportButtonTop || false,
		};
	}

	// create headers for the csv
	csvHeaders = (columns) =>
		columns.filter((column) => column.export !== false).map((column) => column.id);

	// This takes an item's index in this.state.pagedData and calculates the index of that same item in this.state.items
	convertPagedDataIndexToItemsIndex(index) {
		return this.state.page * this.state.rowsPerPage + parseInt(index, 10);
	}

	// Based on https://www.taniarascia.com/content-editable-elements-in-javascript-react/
	handleContentEditableUpdate = (event) => {
		// We don't know which row is being edited
		if (!event.currentTarget || !event.currentTarget.hasAttribute('data-row')) return;

		// We don't know what column to update
		if (!event.currentTarget || !event.currentTarget.hasAttribute('data-column')) return;

		const itemsIndex = this.convertPagedDataIndexToItemsIndex(
			event.currentTarget.getAttribute('data-row')
		);
		const targetColumn = event.currentTarget.getAttribute('data-column');

		let items = cloneDeep(this.state.items);
		items[itemsIndex][targetColumn] = event.target.innerHTML;

		// todo:save this back to the DB
		// This doesn't update filteredData, which I guess *might* lead to problems later?
		this.setState({
			items: items,
			pagedData: items.slice(
				this.state.page * this.state.rowsPerPage,
				this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
			),
		});
	};

	deleteRow = (id) => {
		const { store } = this.state;

		this.setState({
			store: store.filter((item) => id !== item.id),
		});
	};

	pasteAsPlainText = (event) => {
		event.preventDefault();

		const text = event.clipboardData.getData('text/plain');
		document.execCommand('insertHTML', false, text);
	};

	highlightAll = () => {
		setTimeout(() => {
			document.execCommand('selectAll', false, null);
		}, 0);
	};

	createCsv = (items, columns) => {
		let formattedItems = JSON.parse(JSON.stringify(items));
		formattedItems.forEach((item) => {
			if (item.invoice_number) {
				item.invoice_number = item.invoice_number?.invoice_number;
			}
			if (item.tracking_id?.length) {
				item.tracking_id = item.tracking_id[0]?.Tracking_Id || 'Unknown';
			}
			if (item.seller_statement_number) {
				item.seller_statement_number = item.seller_statement_number?.seller_statement_number;
			}
		});

		const jsonParser = new Parser({ eol: '\n', fields: columns });
		const csv = jsonParser.parse(formattedItems);
		fileDownload(csv, 'Exported Data.csv');
	};

	requestSort = (key) => {
		let direction = 'ascending';
		if (
			this.state.config &&
			this.state.config.key === key &&
			this.state.config.direction === 'ascending'
		) {
			direction = 'descending';
		}
		this.setState({
			config: {
				key: key,
				direction: direction,
			},
		});
	};

	getSortIconFor = (colName) => {
		if (!this.state.config) {
			return;
		}
		if (this.state.config.key != colName) return 'sort';
		if (this.state.config.direction === 'ascending') return 'up';
		else if (this.state.config.direction === 'descending') return 'down';
		else return 'sort';
	};

	handleChangePage = async (event, newPage) => {
		this.setState({
			page: newPage,
			pagedData: this.state.filteredData
				? this.state.filteredData.slice(
						newPage * this.state.rowsPerPage,
						newPage * this.state.rowsPerPage + this.state.rowsPerPage
				  )
				: undefined,
		});
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({
			rowsPerPage: +event.target.value,
			page: 0,
			pagedData: this.state.filteredData
				? this.state.filteredData.slice(0, +event.target.value)
				: undefined,
		});
	};

	filteredDataCallback = (filteredData, searchTerm) => {
		if (this.props.filterUsersCallback) this.props.filterUsersCallback(searchTerm);
		if (this.props.filterDataCallback) this.props.filterDataCallback(searchTerm);
		this.setState({
			filteredData: filteredData,
			page: 0,
			pagedData: filteredData
				? filteredData.slice(
						this.state.page * this.state.rowsPerPage,
						this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
				  )
				: undefined,
			searchTerm: searchTerm,
		});
	};

	componentDidMount() {
		this.csvHeaders(this.state.columns);
		// Check to see if this is the initial load, and if so do the initial sort
		if (!this.state.config && (this.props.sortKey || this.props.sortDirection)) {
			let sortConfig = {};
			if (this.props.sortKey) sortConfig.key = this.props.sortKey;
			if (this.props.sortDirection) sortConfig.direction = this.props.sortDirection;

			this.setState({
				config: sortConfig,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(!this.state.items && !this.props.dataToSort) || // no data to sort
			((this.state.items === this.props.dataToSort ||
				(this.state.items && this.state.items === prevState.items)) && // no changes in items or config
				(this.state.config === this.props.config ||
					(this.state.config && this.state.config === prevState.config)))
		)
			return;

		// the order of these options is important here
		// 1. save the full list of items
		// 2. sort the full list of items
		// 3. apply the filter on the sorted list
		const items = this.state.items ? this.state.items : this.props.dataToSort;
		let sortableItems = this.state.searchTerm ? this.state.filteredData : items;
		let sortConfig = this.state.config;

		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				try {
					let sortColumn = this.state.columns.find((col) => col.id == sortConfig.key);
					let isLink = sortColumn.type == 'external-link' || sortColumn.type == 'internal-link';

					if (isLink) {
						if (a[sortConfig.key]['label'] < b[sortConfig.key]['label']) {
							return sortConfig.direction === 'ascending' ? -1 : 1;
						}
						if (a[sortConfig.key]['label'] > b[sortConfig.key]['label']) {
							return sortConfig.direction === 'ascending' ? 1 : -1;
						}
					} else {
						if (a[sortConfig.key] < b[sortConfig.key]) {
							return sortConfig.direction === 'ascending' ? -1 : 1;
						}
						if (a[sortConfig.key] > b[sortConfig.key]) {
							return sortConfig.direction === 'ascending' ? 1 : -1;
						}
					}
				} catch {
					return 0;
				}
				return 0;
			});
		}

		// items will contain the full original unfiltered list of items
		// For now, we set the entire item list and increment the searchFilterKey to filter the items
		let data = this.state.searchTerm ? this.state.filteredData : items;
		this.setState({
			items: items,
			config: sortConfig,
			filteredData: data,
			searchTerm: this.state.searchTerm,
			pagedData: data
				? data.slice(
						this.state.page * this.state.rowsPerPage,
						this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
				  )
				: null,
			sortableTableKey: this.state.sortableTableKey + 1,
			searchFilterKey: this.state.searchFilterKey + 1,
		});
	}

	exportTableButton() {
		return (
			<LightBlueButton
				variant="contained"
				color="primary"
				startIcon={<Description />}
				onClick={() => this.createCsv(this.state.items, this.csvHeaders(this.state.columns))}
				className="export-csv-button"
			>
				Export to CSV
			</LightBlueButton>
		);
	}

	render() {
		return (
			<Paper className="table-responsive">
				{this.state.exportButtonTop && this.exportTableButton()}
				<SearchFilter
					key={this.state.id + 'searchFilter' + this.state.searchFilterKey}
					items={this.state.items}
					filteredDataCallback={this.filteredDataCallback.bind(this)}
					searchTerm={this.state.searchTerm}
					disableAutocomplete={this.state.disableAutocomplete}
				/>
				{this.state.pagedData ? (
					<div>
						<TableContainer>
							<Table
								aria-label="sticky table"
								className="table-borderless table-striped table-hover mb-0 table align-middle"
							>
								<TableHead>
									<TableRow hover role="checkbox" tabIndex={-1}>
										<SortableTableHeader
											sortFunc={this.requestSort.bind(this)}
											sortIconFunc={this.getSortIconFor.bind(this)}
											columns={this.state.columns}
										/>
									</TableRow>
								</TableHead>
								<TableBody>
									<SortableTableBody
										tableData={this.state.pagedData}
										columns={this.state.columns}
										contentEditable={this.props.contentEditable}
										handlePaste={this.props.pasteAsPlainText ? this.pasteAsPlainText : undefined}
										handleUpdateEditableContent={this.handleContentEditableUpdate}
										onFocus={this.highlightAll}
									/>
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={this.state.rowsPerPageOptions}
							component="div"
							count={this.state.items ? this.state.items.length : null}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							onChangePage={this.handleChangePage.bind(this)}
							onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
						/>

						{!this.state.exportButtonTop && this.exportTableButton()}
					</div>
				) : (
					<div className="widget-content mb-3 p-0">Loading data...</div>
				)}
			</Paper>
		);
	}
}

export default withRouter(SortableTable);

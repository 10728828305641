import { Auth } from 'aws-amplify';
import { axiosAuth as axios, axiosUnauthed } from './httpUtils';
import config from '../config';

export async function getUserBulkUploadS3Url() {
	try {
		const response = await axios.post(`${config.api.user_bulk_upload_url}`, {});
		console.log(response);
		return response.data.body;
	} catch (err) {
		console.log(`getUserBulkLoadS3Url error: ${err}`);
	}
}

export async function updateUserPassword(user) {
	try {
		console.log(user);
		const response = await axios.post(`${config.api.update_user_password}`, {
			username: user.user_id,
			email: user.email,
			password: user.new_password,
		});
		let ret = response.data.body == 'Success' ? true : false;
		return ret;
	} catch (err) {
		console.log(`Unable to update user password: ${err}`);
		throw err;
	}
}

export async function updateUserNotes(user) {
	try {
		const response = await axios.post(`${config.api.update_notes}`, {
			user: user,
		});

		let ret = response.data.body == 'Server Error' ? false : true;
		return ret;
	} catch (err) {
		console.log(`Unable to update shipping address: ${err}`);
		throw err;
	}
}

export async function uploadUsersCSVFile(file) {
	try {
		let signed_url = await getUserBulkUploadS3Url();
		let req_options = {
			headers: {
				'Content-Type': file.type,
				// Do not include authorization here
			},
		};
		await axiosUnauthed.put(signed_url, file, req_options);
	} catch (err) {
		console.log(`Unable to upload CSV file: ${err}`);
	}
}

export async function getUserInfo() {
	try {
		const session = await Auth.currentSession();
		const currentUserId =
			session &&
			session.idToken &&
			session.idToken.payload &&
			session.idToken.payload['cognito:username']
				? session.idToken.payload['cognito:username']
				: null;
		const currentUserEmail =
			session && session.idToken && session.idToken.payload && session.idToken.payload['email']
				? session.idToken.payload['email']
				: null;
		const currentUserFullName =
			session &&
			session.idToken &&
			session.idToken.payload &&
			session.idToken.payload['given_name'] &&
			session.idToken.payload['family_name']
				? session.idToken.payload['given_name'] + ' ' + session.idToken.payload['family_name']
				: null;

		return {
			currentUserId,
			currentUserEmail,
			currentUserFullName,
		};
	} catch (e) {
		if (e !== 'No current user') {
			console.log(e);
		}
	}
}

export async function getUserBids(userId, auctionId) {
	try {
		const response = await axios.post(`${config.api.user_bids}`, {
			user_id: userId,
			auction_id: auctionId,
		});
		return response.data.body;
	} catch (e) {
		console.error(e);
		return [];
	}
}

export function getCognitoUserFullName(user) {
	return (
		getCognitoUserAttributeValue(user, 'given_name') +
		' ' +
		getCognitoUserAttributeValue(user, 'family_name')
	);
}

export function getCognitoUserSub(user) {
	return getCognitoUserAttributeValue(user, 'sub');
}

export function getCognitoUserEmail(user) {
	return getCognitoUserAttributeValue(user, 'email');
}

export function getCognitoUserAttributeValue(user, attribute) {
	for (let attr of user.Attributes) {
		if (attr.Name == attribute) return attr.Value;
	}
}

export function getCustomerFullName(user) {
	if (!user) return '';
	return [user.given_name, user.family_name, user.email].join(' ');
}

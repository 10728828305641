import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Input } from '../../../../prizm-ui/Input';
import { Option, Select } from '../../../../prizm-ui/Select';
import { Toggle } from '../../../../prizm-ui/Toggle';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import {
	AUTHENTICATION_STATUSES,
	KernelLot,
	PROCESSING_STATUSES,
} from '../../../../services/lotsService/lotsService.model';
import { titleCase } from '../../../../utils/formatters';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotPhysicalItemDetailsModal = ({
	lot,
	onClose,
}: {
	lot: KernelLot;
	onClose: () => void;
}) => {
	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const [draftLot, setDraftLot] = useState(lot);
	const updateDraftLot = (deltas: Partial<KernelLot>) => setDraftLot({ ...draftLot, ...deltas });

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () =>
		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated physical item details for lot');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating physical item details for lot. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});

	const canConfirm = !isEqual(lot, draftLot);

	return (
		<ActionModal
			title="Edit Physical Item Details"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={canConfirm}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Select
					id="authentication_status"
					label="Authentication Status"
					value={draftLot.authentication_status}
					onChange={({ target: { value } }) => updateDraftLot({ authentication_status: value })}
				>
					{AUTHENTICATION_STATUSES.map((value, index) => (
						<Option key={value} value={value} index={index}>
							{titleCase(value)}
						</Option>
					))}
				</Select>
				<Input
					id="authentication_notes"
					label="Authentication Notes"
					value={draftLot.authentication_notes}
					onChange={({ target: { value } }) => updateDraftLot({ authentication_notes: value })}
				/>
				<Select
					id="processing_status"
					label="Processing Status"
					value={draftLot.processing_status}
					onChange={({ target: { value } }) => updateDraftLot({ processing_status: value })}
				>
					{PROCESSING_STATUSES.map((value, index) => (
						<Option key={value} value={value} index={index}>
							{titleCase(value)}
						</Option>
					))}
				</Select>
				<Input
					id="room"
					value={draftLot.room}
					label="Room"
					onChange={({ target: { value } }) => updateDraftLot({ room: value })}
				/>
				<Input
					id="bin"
					value={draftLot.bin}
					label="Bin"
					onChange={({ target: { value } }) => updateDraftLot({ bin: value })}
				/>
				<div className="py-2 flex justify-between items-center">
					<span className="pl-2">Require Custom Shipping</span>
					<Toggle
						checked={draftLot.enable_custom_shipping}
						aria-label={
							draftLot.enable_custom_shipping
								? 'Toggle to allow default shipping'
								: 'Toggle to require custom shipping'
						}
						onChange={() =>
							updateDraftLot({ enable_custom_shipping: !draftLot.enable_custom_shipping })
						}
					/>
				</div>
				<div className="py-2 flex justify-between items-center">
					<span className="pl-2">Vaultable</span>
					<Toggle
						checked={draftLot.vaultable}
						aria-label={
							draftLot.vaultable
								? 'Toggle to disallow ship to vault'
								: 'Toggle to allow ship to vault'
						}
						onChange={() => updateDraftLot({ vaultable: !draftLot.vaultable })}
					/>
				</div>
			</div>
		</ActionModal>
	);
};

export default EditLotPhysicalItemDetailsModal;

import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import BulkUploadCustomersModal from './BulkUploadCustomersModal';
import Card from '../../../components/Card';
import DownloadCSVButton from '../../../components/DownloadCSVButton';
import SortableTable from '../../../components/SortableTable';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import { BASE_CUSTOMER_MANAGEMENT_TABS } from '../CustomerManager/CustomerManagerTabs/CustomerManagerTabs.model';
import { CUSTOMER_MANAGER_SEARCH_CSV_COLUMN_NAMES } from '../CustomerManager/CustomerManagerView/ManageCustomerView/ManageCustomerTabs/ManageCustomerTabs.model';
import { User } from '../../../services/userService/userService.model';
import useBiddersByKeyword from '../../../queries/useBiddersByKeyword/useBiddersByKeyword';
import { Button } from '../../../prizm-ui/Button';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import { Input } from '../../../prizm-ui/Input';
import AddNewCustomerButton from './AddNewCustomerButton';
import { getQueryParamsObject } from '../../../utils/formatters';
import UserStatusChip from './UserStatusChip';
import { UserRoleName } from '../../../services/userService/userService.model';
import useUserRole from '../../../queries/useUserRole/useUserRole';
import RestrictedArea from '../../../components/RestrictedArea/RestrictedArea';

const CustomerManagerSearch = () => {
	const history = useHistory();
	const { search } = useLocation();
	const { query: queryFromParams = '' } = getQueryParamsObject<{ query: string }>(search);

	const [draftSearchQuery, setDraftSearchQuery] = useState(queryFromParams);
	const [shouldShowImportCustomersModal, setShouldShowImportCustomersModal] = useState(false);
	const [submittedSearchQuery, setSubmittedSearchQuery] = useState(draftSearchQuery);

	useEffect(() => {
		setDraftSearchQuery(queryFromParams);
		setSubmittedSearchQuery(queryFromParams);
	}, [queryFromParams]);

	const { data: userRoleName = 'Unknown' as UserRoleName } = useUserRole();

	const { data: bidders = [] as User[] } = useBiddersByKeyword(submittedSearchQuery);

	const getInitialTabPath = () => {
		const possibleTabs = BASE_CUSTOMER_MANAGEMENT_TABS.filter(({ allowedRoles }) =>
			allowedRoles?.includes(userRoleName)
		);

		const [initialTab] = possibleTabs;

		return initialTab.path;
	};

	const handleSubmitSearchQuery = () => {
		setSubmittedSearchQuery(draftSearchQuery);
		history.push(`/usermanagement/search/customers?query=${encodeURIComponent(draftSearchQuery)}`);
	};

	const columns: ColumnDef<User>[] = [
		{
			header: 'Name',
			accessorFn: ({ familyName, givenName }) => `${givenName} ${familyName}`,
			cell: ({
				getValue,
				row: {
					original: { id, isDisabled },
				},
			}) => (
				<Tooltip id={`select-${getValue()}`} content={`View ${getValue()}'s Profile`}>
					<Link
						className={`block link ${isDisabled ? 'text-neutral2dark' : ''}`}
						to={`/usermanagement/customer/${id}/${getInitialTabPath()}`}
					>
						<span>{getValue()}</span>
					</Link>
				</Tooltip>
			),
		},
		{
			header: 'Email',
			accessorFn: ({ email }) => email,
			cell: ({
				getValue,
				row: {
					original: { isDisabled },
				},
			}) => <div className={isDisabled ? 'text-neutral2dark' : ''}>{getValue()}</div>,
		},
		{
			header: 'Phone',
			accessorFn: ({ phoneNumber }) => phoneNumber,
			cell: ({
				getValue,
				row: {
					original: { isDisabled },
				},
			}) => <div className={`${isDisabled ? 'text-neutral2dark' : ''}`}>{getValue()}</div>,
		},
		{
			header: 'Status',
			accessorKey: 'isDisabled',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<UserStatusChip status={getValue() ? 'Inactive' : 'Active'} as="Chip" />
			),
		},
	];

	const sortableTableParams: SortableTableParams<User> = {
		columns,
		data: bidders,
		getRowId: ({ id }) => id,
	};

	return (
		<div>
			<Card
				allowedRoles={[
					'Admin',
					'AuctionManager',
					'Consignment',
					'CustomerSupport',
					'Finance',
					'Inventory',
				]}
			>
				<h1 className="text-display4">Customer Manager</h1>
				<h2 className="text-display5 mt-2">Search for Customers</h2>
				<div className="flex flex-col lg:flex-row lg:items-center mt-2">
					<Input
						id="search-customer-input"
						className="flex-grow mb-2 lg:mb-0 lg:mr-2"
						label="Email, first name, last name, or phone number ..."
						type="text"
						value={draftSearchQuery}
						onChange={({ target: { value } }) => setDraftSearchQuery(value)}
						onKeyDown={({ key }) => key === 'Enter' && handleSubmitSearchQuery()}
					/>
					<Button
						variant="primary"
						trailingIcon="Search"
						disabled={submittedSearchQuery === draftSearchQuery}
						onClick={handleSubmitSearchQuery}
					>
						Search
					</Button>
				</div>

				<div className="mt-2">
					<SortableTable
						globalFilterPlaceholder="Filter ..."
						key="customer-manager-search-table"
						tableParams={sortableTableParams}
					/>
				</div>
				<RestrictedArea allowedRoles={['Admin', 'CustomerSupport', 'Finance', 'Inventory']}>
					<div className="mt-2">
						<DownloadCSVButton
							columnNames={CUSTOMER_MANAGER_SEARCH_CSV_COLUMN_NAMES}
							documentName={`customer-search-results`}
							items={bidders}
							kind="secondary"
						/>
					</div>
					{shouldShowImportCustomersModal && (
						<BulkUploadCustomersModal onClose={() => setShouldShowImportCustomersModal(false)} />
					)}
					<div className="mt-4">
						<h2 className="text-display5">Add Customers</h2>
						<div className="flex mt-2">
							<AddNewCustomerButton />
							<Button
								className="mx-2"
								variant="secondary"
								size="md"
								onClick={() => setShouldShowImportCustomersModal(true)}
							>
								Bulk Upload Customers
							</Button>
						</div>
					</div>
				</RestrictedArea>
			</Card>
		</div>
	);
};

export default CustomerManagerSearch;

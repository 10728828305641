import { Parser } from 'json2csv';
import fileDownload from 'js-file-download';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';
import {
	Adjustment,
	CheckoutOrder,
	CouponAdjustment,
	FreeformAdjustment,
	LatePaymentAdjustment,
	LotAdjustment,
	PAYMENT_METHODS_WITH_SURCHARGES,
	PaymentMethod,
	PaymentMethodFees,
	STRUCTURED_FEES,
	StructuredFee,
	StructuredFeeAdjustment,
	StructuredFees,
	TaxesByTaxablePaymentMethod,
} from '../../../../../../services/ordersService/ordersService.model';
import { updateOrderAdjustments } from '../../../../../../services/ordersService/updateOrderAdjustments';
import AddAdjustmentModal from './AddAdjustmentModal';
import DeletableLineItem from './LineItem/DeletableLineItem';
import { FeeStructure } from './OrderSummary.model';
import { getChangedFees, getStructuredFees, getUpdatedAdjustments } from './OrderSummary.utils';
import OrderSummaryLot from './OrderSummaryLot';
import OrderSummaryStructuredFee from './OrderSummaryStructuredFee';
import { EditSummaryContextProvider } from './useEditSummary';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import { FIXED_PRICE_ORDER_CSV_COLUMNS } from './OrderSummary.model';
import useUserById from '../../../../../../queries/useUserById';
import { User } from '../../../../../../services/userService/userService.model';
import AssignLotsToAuctionModal from './AssignLotsToAuctionModal/AssignLotsToAuctionModal';
import { KernelLot, RemovedLot } from '../../../../../../services/lotsService/lotsService.model';
import { AdjustmentTypeEnum } from './AddAdjustmentModal/AddAdjustmentModal';

const OrderSummary = ({
	order,
	lotItems,
	paymentMethod,
	removedLots,
}: {
	order: CheckoutOrder;
	lotItems: KernelLot[];
	paymentMethod: PaymentMethod;
	removedLots: RemovedLot[];
}) => {
	const { refetch: refetchOrder } = useOrderByNumber({
		orderNumber: order.invoiceNumber,
		enabled: !!order.invoiceNumber,
	});

	const { data: winner = {} as User } = useUserById(order.winnerId);

	const [adjustmentIdsStagedForDeletion, setAdjustmentIdsStagedForDeletion] = useState<string[]>(
		[]
	);
	const [isEditing, setIsEditing] = useState(false);
	const [shouldShowAddAdjustmentModal, setShouldShowAddAdjustmentModal] = useState(false);

	const { costMetadata, invoiceNumber: orderNumber } = order;

	const {
		accountBalance,
		adjustments = [],
		fees = {} as StructuredFees,
		paymentOptionFees = {} as PaymentMethodFees,
		subtotal = 0,
		taxes = {} as TaxesByTaxablePaymentMethod,
	} = costMetadata;

	const incomingFees = getStructuredFees({ fees, adjustments });

	const [shipping, setShipping] = useState(incomingFees.shipping);
	const [handling, setHandling] = useState(incomingFees.handling);
	const [insurance, setInsurance] = useState(incomingFees.insurance);

	const originalFees = {
		shipping: incomingFees.shipping,
		handling: incomingFees.handling,
		insurance: incomingFees.insurance,
	};
	const currentFees = {
		shipping,
		handling,
		insurance,
	};
	const changedFees = getChangedFees({
		original: originalFees,
		current: currentFees,
	});

	const hasChanges =
		adjustmentIdsStagedForDeletion.length > 0 || !isEqual(originalFees, currentFees);

	const { mutate: doUpdateAdjustments, status: updateAdjustmentsStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderAdjustments, ...Object.entries(changedFees).flat()],
		mutationFn: (updatedAdjustments: Adjustment[]) =>
			updateOrderAdjustments({
				orderNumber,
				updatedAdjustments,
			}),
	});

	const handleCancel = () => {
		setAdjustmentIdsStagedForDeletion([]);
		setShipping(incomingFees.shipping);
		setInsurance(incomingFees.insurance);
		setHandling(incomingFees.handling);
		setIsEditing(false);
	};

	const handleDownloadCSV = () => {
		const jsonParser = new Parser({
			eol: '\n',
			fields: FIXED_PRICE_ORDER_CSV_COLUMNS,
		});
		const csv = jsonParser.parse({ ...order, email: winner.email });

		fileDownload(
			csv,
			`fixed-price-order-${orderNumber}-${winner.givenName.toLowerCase()}-${winner.familyName.toLowerCase()}.csv`
		);
	};

	const handleSave = () => {
		const feeAdjustments: StructuredFeeAdjustment[] = Object.keys(changedFees).map((feeType) => ({
			category: feeType as StructuredFee,
			amount: changedFees[feeType as keyof FeeStructure]!,
			taxable: false,
		}));

		const hasFeeOrAdjustmentChanges =
			feeAdjustments.length > 0 || adjustmentIdsStagedForDeletion.length > 0;
		if (hasFeeOrAdjustmentChanges) {
			const updatedAdjustments = getUpdatedAdjustments({
				currentAdjustments: adjustments,
				newAdjustments: feeAdjustments,
				adjustmentIdsStagedForDeletion,
			});
			return doUpdateAdjustments(updatedAdjustments, {
				onSuccess: () => {
					showSuccessToast('Fees successfully updated.');

					refetchOrder();
					setIsEditing(false);
				},
				onError: () => {
					showErrorToast('Something went wrong updating fees. Please try again later.');
				},
			});
		}
	};

	const toggleAdjustmentDeletion = (adjustmentId: string) => {
		if (adjustmentIdsStagedForDeletion.includes(adjustmentId)) {
			const updatedAdjustmentsStagedForDeletion = adjustmentIdsStagedForDeletion.filter(
				(thisAdjustmentId) => thisAdjustmentId !== adjustmentId
			);
			return setAdjustmentIdsStagedForDeletion(updatedAdjustmentsStagedForDeletion);
		}

		return setAdjustmentIdsStagedForDeletion([...adjustmentIdsStagedForDeletion, adjustmentId]);
	};

	const lotOverrides = adjustments.filter(({ category }) => category === 'lot') as LotAdjustment[];
	const structuredFeeAdjustments = adjustments.filter(({ category }) =>
		STRUCTURED_FEES.includes(category)
	) as StructuredFeeAdjustment[];
	const freeformAdjustments = adjustments.filter(
		({ category }) => category === 'other'
	) as FreeformAdjustment[];
	const offsets = adjustments.filter(
		({ category }) => category === 'offset'
	) as FreeformAdjustment[];
	const advances = adjustments.filter(
		({ category }) => category === 'advance'
	) as FreeformAdjustment[];
	const latePaymentAdjustments = adjustments.filter(
		({ category }) => category === 'late_payment'
	) as LatePaymentAdjustment[];
	const couponAdjustments = adjustments.filter(
		({ category }) => category === 'coupon'
	) as CouponAdjustment[];
	const creditsAdjustments = adjustments.filter(
		({ category }) => category === AdjustmentTypeEnum.Credits
	) as FreeformAdjustment[];
	const partialPaymentsAdjustments = adjustments.filter(
		({ category }) => category === AdjustmentTypeEnum.PartialPayment
	) as FreeformAdjustment[];

	const hasPaymentMethodSurcharge = PAYMENT_METHODS_WITH_SURCHARGES.includes(paymentMethod);
	const paymentMethodSurcharge = paymentOptionFees[paymentMethod] || 0;
	const taxesByPaymentMethod = taxes[paymentMethod] || 0;

	const orderTotal = hasPaymentMethodSurcharge
		? subtotal + paymentMethodSurcharge + taxesByPaymentMethod
		: subtotal + taxesByPaymentMethod;

	const balanceAdjustmentFound = adjustments.find(({ category }) => category === 'balance');

	const [shouldShowAuctionPickerModal, setShouldShowAuctionPickerModal] = useState(false);

	return (
		<Card>
			{costMetadata && (
				<>
					<div className="flex items-baseline justify-between">
						<h2 className="mb-4 text-subtitle1">{isEditing ? 'Summary (Edit Mode)' : 'Summary'}</h2>
						<div className="flex">
							{order.auctionId === 'fixed_price_marketplace' && (
								<Button className="link pr-2" kind="tertiary" onClick={handleDownloadCSV}>
									Download CSV
								</Button>
							)}
							{!isEditing && order.invoiceStatus !== 'Paid' && lotItems.length > 0 && (
								<Button kind="tertiary" onClick={() => setShouldShowAuctionPickerModal(true)}>
									Assign to Auction
								</Button>
							)}
							{shouldShowAuctionPickerModal && (
								<AssignLotsToAuctionModal
									onClose={() => setShouldShowAuctionPickerModal(false)}
									orderNumber={orderNumber}
									lotItems={lotItems}
								/>
							)}
							{order.invoiceStatus !== 'Paid' &&
								(isEditing ? (
									<Button
										kind="primary"
										onClick={() => {
											isEditing ? handleSave() : setIsEditing(true);
										}}
										isLoading={updateAdjustmentsStatus === 'loading'}
										disabled={!hasChanges || updateAdjustmentsStatus === 'loading'}
										className="link"
									>
										Save Changes
									</Button>
								) : (
									<Button
										kind="tertiary"
										onClick={() => setShouldShowAddAdjustmentModal(true)}
										className="link"
									>
										Add Adjustment
									</Button>
								))}
							{order.invoiceStatus !== 'Paid' && (
								<Button
									kind="tertiary"
									className={`ml-2 pl-0 pr-0 ${isEditing ? '' : 'link'}`}
									onClick={() => {
										isEditing ? handleCancel() : setIsEditing(true);
									}}
								>
									{isEditing ? 'Cancel' : 'Edit Line Items'}
								</Button>
							)}
							{shouldShowAddAdjustmentModal && (
								<AddAdjustmentModal
									onClose={() => setShouldShowAddAdjustmentModal(false)}
									lotItems={lotItems}
									order={order}
									orderTotal={orderTotal}
									isMissingSubtotal={typeof subtotal === 'undefined'}
								/>
							)}
						</div>
					</div>
					<EditSummaryContextProvider value={{ isEditing }}>
						<div className="mt-4 w-full">
							{lotItems.map((lot) => (
								<OrderSummaryLot
									lot={lot}
									key={lot.lot_id}
									order={order}
									adjustments={lotOverrides.filter(({ lotId }) => lotId === lot.lot_id)}
									adjustmentIdsStagedForDeletion={adjustmentIdsStagedForDeletion}
									toggleAdjustmentDeletion={toggleAdjustmentDeletion}
								/>
							))}
							{lotItems.length === 0 && removedLots.length > 0 && (
								<div className="flex justify-center items-center h-40 text-subtitle2">
									All items have been removed from this order.
								</div>
							)}
							<div>
								{freeformAdjustments.map(({ description, amount, id = '' }, i) => (
									<div className="mb-1 flex" key={`${description + amount}-${i}`}>
										<div className="w-8" />
										<div className="ml-0.5 w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												{description}
											</DeletableLineItem>
										</div>
									</div>
								))}
								<div className="border-b-base my-8 mx-20" />
								<OrderSummaryStructuredFee
									name="handling"
									label="Handling"
									amount={Number(handling)}
									adjustments={structuredFeeAdjustments.filter(
										({ category }) => category === 'handling'
									)}
									onChange={({ target: { value } }) => setHandling(Number(value))}
									onReset={() => setHandling(incomingFees.handling)}
								/>
								<OrderSummaryStructuredFee
									name="insurance"
									label="Insurance"
									amount={Number(insurance)}
									adjustments={structuredFeeAdjustments.filter(
										({ category }) => category === 'insurance'
									)}
									onChange={({ target: { value } }) => setInsurance(Number(value))}
									onReset={() => setInsurance(incomingFees.insurance)}
								/>
								<OrderSummaryStructuredFee
									name="shipping"
									label="Shipping"
									amount={Number(shipping)}
									adjustments={structuredFeeAdjustments.filter(
										({ category }) => category === 'shipping'
									)}
									onChange={({ target: { value } }) => setShipping(Number(value))}
									onReset={() => setShipping(incomingFees.shipping)}
								/>
								<OrderSummaryStructuredFee
									amount={taxesByPaymentMethod}
									isEditable={false}
									label="Tax"
									name="tax"
								/>
								{latePaymentAdjustments.map(({ description, amount, id = '' }) => (
									<div className="mb-1 flex" key={id}>
										<div className="w-8" />
										<div className="w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												{description}
											</DeletableLineItem>
										</div>
									</div>
								))}
								<OrderSummaryStructuredFee
									name="payment-method-surcharge"
									label="Payment Method Surcharge"
									amount={paymentMethodSurcharge}
									isEditable={false}
								/>
								{balanceAdjustmentFound && (
									<div className="flex">
										<div className="w-6" />
										<div className="ml-0.5 w-full capitalize">
											<DeletableLineItem
												amount={Number(balanceAdjustmentFound.amount)}
												kind="primary"
												adjustmentId={balanceAdjustmentFound.id || ''}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(
													balanceAdjustmentFound.id || ''
												)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												<span className="ml-2">{`Balance (${formatCurrency(
													accountBalance
												)})`}</span>
											</DeletableLineItem>
										</div>
									</div>
								)}
								{(offsets.length > 0 || advances.length > 0) && (
									<div className="border-b-base my-8 mx-20" />
								)}
								{offsets.map(({ description, amount, id = '' }, i) => (
									<div className="flex" key={`${description + amount}-${i}`}>
										<div className="w-6" />
										<div className="ml-0.5 w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												<span className="ml-2">Offset: {description}</span>
											</DeletableLineItem>
										</div>
									</div>
								))}
								{advances.map(({ description, amount, id = '' }, i) => (
									<div className="flex" key={`${description + amount}-${i}`}>
										<div className="w-6" />
										<div className="ml-0.5 w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												<span className="ml-2">Advance: {description}</span>
											</DeletableLineItem>
										</div>
									</div>
								))}
								{couponAdjustments.map(
									({ metadata: { couponCode }, description, amount, id = '' }, i) => (
										<div className="flex" key={`${(description ?? couponCode) + amount}-${i}`}>
											<div className="w-6" />
											<div className="ml-0.5 w-full capitalize">
												<DeletableLineItem
													amount={amount ? Number(amount) : undefined}
													kind="primary"
													adjustmentId={id}
													isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
													toggleAdjustmentDeletion={toggleAdjustmentDeletion}
												>
													<span className="ml-2">Coupon: ({couponCode})</span>
												</DeletableLineItem>
											</div>
										</div>
									)
								)}
								{creditsAdjustments.map(({ description, amount, id = '' }) => (
									<div className="mb-1 flex" key={id}>
										<div className="w-8" />
										<div className="w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												{description}
											</DeletableLineItem>
										</div>
									</div>
								))}
								{partialPaymentsAdjustments.map(({ description, amount, id = '' }) => (
									<div className="mb-1 flex" key={id}>
										<div className="w-8" />
										<div className="w-full capitalize">
											<DeletableLineItem
												amount={amount ? Number(amount) : undefined}
												kind="primary"
												adjustmentId={id}
												isStagedForDeletion={adjustmentIdsStagedForDeletion.includes(id)}
												toggleAdjustmentDeletion={toggleAdjustmentDeletion}
											>
												{description}
											</DeletableLineItem>
										</div>
									</div>
								))}
								<div className="border-b-base my-8 mx-20" />
								<OrderSummaryStructuredFee
									name="total"
									label="Total"
									amount={orderTotal}
									className="font-semibold"
									isEditable={false}
								/>
							</div>
						</div>
					</EditSummaryContextProvider>
				</>
			)}
		</Card>
	);
};

export default OrderSummary;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KindOfParams, LotsBySubsetParams, QueryType } from './getLotsBySubset.model';
import { getRequestBodyForKindOfParams } from './getLotsBySubset.utils';
import { KernelLot } from './lotsService.model';
import { getLotLocation } from './lotsService.utils';

export const getLotsBySubset = async (params: LotsBySubsetParams) => {
	const featuredKindsOfParams: KindOfParams[] = ['assigned', 'search-term', 'unassigned'];
	const queryType: QueryType = featuredKindsOfParams.includes(params.kind) ? 'Featured' : 'All';

	const requestBodyForKindOfParams = getRequestBodyForKindOfParams(params);

	const query = {
		from: params.from || 0,
		list: params.list,
		queryType: params.queryType ? params.queryType : queryType,
		...requestBodyForKindOfParams,
		size: params.size || 3500,
		...(params.include && { include: params.include }),
	};

	const response = await axiosAuth.post<{ lots: KernelLot[] }>(config.api.lots, query);

	if (response.status >= 400) {
		throw response;
	}

	const {
		data: { lots = [] as KernelLot[] },
	} = response;

	return lots.map((lot) => ({
		...lot,
		location: getLotLocation(lot),
	}));
};

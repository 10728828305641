import { FC } from 'react';
import { ErrorBoundaryProps } from 'react-error-boundary';
import { Icon } from '../../../prizm-ui/Icon';

const FallbackComponent: FC<ErrorBoundaryProps> = () => {
	return (
		<Icon
			aria-label="Something went wrong. If the issue persists after reloading, please contact the #mvp-cs-channel"
			aria-hidden={false}
			name="ErrorCircleFilled"
		/>
	);
};

export default FallbackComponent;

import { MissingFieldOption } from '../../../../services/lotsService/lotsService.model';

export type LotManagerFiltersType = {
	[key in MissingFieldOption]: boolean;
};

export const LOT_MANAGER_CSV_COLUMN_NAMES = [
	'lot_number',
	'serial_number',
	'title',
	'item_type',
	'min_bid_price',
	'current_price',
	'reserve_amount',
	'internal_reserve_amount',
	'status',
	'processing_status',
	'commission',
	'consignment_manager_email',
	'seller_email',
	'category',
	'sub_category',
	'room',
	'bin',
	'grading_company',
	'year',
	'contract_id',
	'estimated_value',
	'cert_number',
	'vaultable',
];

import { Link } from 'react-router-dom';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import {
	KernelSellerStatementDetails,
	SellerStatementUserInfo,
} from '../../../../services/sellerStatementService';
import { KernelAddress } from '../../../../services/userService/userService.model';
import DownloadStatementPDFButton from './DownloadStatementPDFButton';
import EmailStatementPDFButton from './EmailStatementPDFButton';

const SellerStatementAccountDetails = ({
	auctionId,
	statementDetails,
	statementId,
}: {
	auctionId: string;
	statementDetails: KernelSellerStatementDetails;
	statementId: string;
}) => {
	const sellerFirstName = statementDetails?.user_info?.given_name ?? '';
	const sellerLastName = statementDetails?.user_info?.family_name ?? '';

	const sellerName = `${sellerFirstName} ${sellerLastName}`.trim();

	const { user_info: userInfo = {} as SellerStatementUserInfo } = statementDetails;
	const tinStatus = userInfo.verified_tin ? 'Verified' : 'Not Verified';
	const shippingAddress = userInfo.shipping_address ?? ({} as KernelAddress);

	const { user_id: sellerId, current_balance: currentBalance } = userInfo;

	return (
		<div className="flex flex-col lg:flex-row justify-between">
			<div>
				<h2 className="text-display5">Account Details</h2>
				<Tooltip
					content={`View ${sellerFirstName || 'Unknown User'}'s Profile`}
					id="link-to-customer"
				>
					<Link
						className="link"
						to={`/usermanagement/customer/${statementDetails.user_info.user_id}`}
					>
						{sellerName}
					</Link>
				</Tooltip>
				<div className="text-body2">{userInfo.email}</div>
				<div className="mt-4">
					<div className="text-body1">
						<span>Balance: </span>
						<Tooltip
							content={`View ${sellerFirstName || 'Unknown User'}'s Balance`}
							id="link-to-balance-history"
						>
							<Link to={`/usermanagement/customer/${sellerId}/balance`} className="link">
								{formatCurrency(currentBalance)}
							</Link>
						</Tooltip>
					</div>
					<div className="text-body1">
						<span>TIN: </span>
						<span className={tinStatus === 'Verified' ? '' : 'text-error2'}>{tinStatus}</span>
					</div>
					<div className="text-body1">
						<span>Stripe Connect: </span>
						<span
							className={userInfo.stripe_payout_account_status === 'Complete' ? '' : 'text-error2'}
						>
							{userInfo.stripe_payout_account_status ?? 'Unknown'}
						</span>
					</div>
				</div>
				<div className="mt-4">
					<h2 className="text-display5">Shipping Details</h2>
					<div className="text-body1">
						<div>{sellerName}</div>
						{shippingAddress.line1 && <div>{shippingAddress.line1}</div>}
						{shippingAddress.line2 && <div>{shippingAddress.line2}</div>}
						<div>
							{shippingAddress.city && <span>{shippingAddress.city}</span>}
							{shippingAddress.state && <span>, {shippingAddress.state}</span>}
							{shippingAddress.postal_code && <span> {shippingAddress.postal_code}</span>}
						</div>
						{shippingAddress.country && <div>{shippingAddress.country}</div>}
					</div>
				</div>
			</div>
			<div className="mt-8 lg:mt-0">
				<EmailStatementPDFButton
					auctionId={auctionId}
					defaultEmail={userInfo.email}
					statementId={statementId}
					statementStatus={statementDetails.seller_statement_status}
				/>
				<DownloadStatementPDFButton auctionId={auctionId} statementId={statementId} />
			</div>
		</div>
	);
};

export default SellerStatementAccountDetails;

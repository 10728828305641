import { getIncrementAmount } from '../../AdminUtils/bidUtils';
import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { PlaceBidParams } from './placeBid.model';

export const placeBid = async ({
	auctionId,
	lotId,
	lotNumber,
	userId,
	maxBid,
	minBid,
	currentPrice = 0,
}: PlaceBidParams) => {
	const response = await postAuthed(`${apiEndpoints.bidding_actions}/place_bid`, {
		auction_id: auctionId,
		lot_id: lotId,
		lot_number: lotNumber,
		user_id: userId,
		maximum_bid: maxBid,
		bid_type: 'Maximum',
		min_bid_price: minBid,
		increment_value: getIncrementAmount(currentPrice || 0),
	});

	if (response.status >= 400) {
		throw response;
	}

	return response?.data?.body?.responseType || response?.data?.errorMessage;
};

import { AxiosError } from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DefinitionList from '../../../../../components/DefinitionList';
import LoadingWrapper from '../../../../../components/LoadingWrapper';
import RestrictedArea from '../../../../../components/RestrictedArea';
import { Button } from '../../../../../prizm-ui/Button';
import { Chip } from '../../../../../prizm-ui/Chip';
import useCashAccountSummary from '../../../../../queries/useCashAccountSummary';
import useEmployeeById from '../../../../../queries/useEmployeeById';
import useUserById from '../../../../../queries/useUserById';
import { CashAccountSummary } from '../../../../../services/cashAccountsService/cashAccountsService.model';
import { User } from '../../../../../services/userService/userService.model';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import VerifiedTinChip from '../../CustomerHeader/VerifiedTinChip';
import EditGeneralInfoModal from './EditGeneralInfoModal';

const CustomerOverviewGeneralInfo = () => {
	const { userId } = useParams<{ userId: string }>();
	const { data: user = {} as User, status: userStatus } = useUserById(userId);

	const {
		data: cashAccountSummary = {} as CashAccountSummary,
		status: cashAccountSummaryStatus,
		error: cashAccountSummaryError,
	} = useCashAccountSummary(userId);

	const { data: consignmentManager = {} as User, status: consignmentManagerStatus } =
		useEmployeeById(user.consignmentManagerId);

	const [shouldShowEditGeneralInfoModal, setShouldShowEditGeneralInfoModal] = useState(false);

	const getCurrentBalance = () => {
		if (cashAccountSummaryStatus === 'loading') {
			return 'Loading...';
		}
		if (cashAccountSummaryStatus === 'error') {
			const typedError = cashAccountSummaryError as AxiosError;
			return typedError.response?.data?.errorCode === 'no_balance_account_records' ? (
				'-'
			) : (
				<Chip variant="error">Error Loading</Chip>
			);
		}
		if (cashAccountSummaryStatus === 'success') {
			return formatCurrency(cashAccountSummary.currentBalance);
		}

		return '';
	};

	return (
		<LoadingWrapper queryStatuses={[userStatus, consignmentManagerStatus]}>
			<div className="flex justify-between mb-4">
				<h2 className="text-display5">General Info</h2>
				<RestrictedArea allowedRoles={['Admin', 'CustomerSupport', 'Finance']}>
					<Button
						variant="primary"
						size="md"
						trailingIcon="Edit"
						onClick={() => setShouldShowEditGeneralInfoModal(true)}
					>
						Edit
					</Button>
				</RestrictedArea>
				{shouldShowEditGeneralInfoModal && (
					<EditGeneralInfoModal onClose={() => setShouldShowEditGeneralInfoModal(false)} />
				)}
			</div>

			<DefinitionList
				rows={[
					{
						title: 'Credit Limit',
						content: <div className="flex items-center">{formatCurrency(user.creditLimit)} </div>,
					},
					{ title: 'Bidding Power', content: formatCurrency(user.biddingPower) },
					{ title: 'Balance', content: getCurrentBalance() },
					{
						title: 'Consignment Manager',
						content: (
							<div className="flex items-center">
								{user.consignmentManagerId
									? `${consignmentManager.givenName} ${consignmentManager.familyName}`.trim()
									: 'Unset'}
							</div>
						),
					},
					{
						title: 'TIN Status',
						content: <VerifiedTinChip as="Chip" status={user.verifiedTin} />,
					},
					{
						title: 'Payment Reminder Emails',
						content: (
							<Chip variant={user.sendPaymentReminder ? 'success' : 'error'}>
								{user.sendPaymentReminder ? 'Enabled' : 'Disabled'}
							</Chip>
						),
					},
				]}
			/>
		</LoadingWrapper>
	);
};

export default CustomerOverviewGeneralInfo;

export type AuctionStatus =
	| 'Completed'
	| 'Active'
	| 'Not_Started'
	| 'Preview'
	| 'Pause'
	| 'Withdrawn';
export const ALL_AUCTION_STATUSES: AuctionStatus[] = [
	'Active',
	'Preview',
	'Not_Started',
	'Completed',
];

export type AuctionType = 'Elite' | 'Thematic' | 'Weekly' | 'Unset';
export const ALL_AUCTION_TYPES: AuctionType[] = ['Elite', 'Thematic', 'Weekly', 'Unset'];

export interface KernelAuction {
	auction_id: string;
	auction_type: AuctionType;
	average_bid_value?: number;
	bids_per_lot?: number;
	buyer_premium?: number;
	commission?: number;
	company_id: string;
	crated_by?: string;
	created_at?: string;
	description?: string;
	end_timestamp?: string;
	extended_bidding_duration?: number;
	gmv?: number;
	increment?: string;
	internal_notes?: string;
	modified_at?: string;
	modified_by?: string;
	number_of_images?: number;
	preview_timestamp?: string;
	primary_image_name?: string;
	sellthrough_rate?: string;
	start_timestamp?: string;
	status: AuctionStatus;
	tags?: string[];
	title?: string;
	total_bid_value?: number;
	total_bids?: number;
	total_buyer_premium?: number;
	total_commission?: number;
	total_lots?: number;
	total_views?: number;
	unique_bidders?: number;
	unique_bidders_per_lot?: number;
	unique_sellers?: number;
	unsold_lots?: number;
}

export interface Auction {
	averageBidValue?: number;
	auctionType: AuctionType;
	bidsPerLot?: number;
	buyerPremium?: number;
	commission?: number;
	companyId: string;
	createdAt?: string;
	createdBy?: string;
	description: string;
	endTimestamp?: string;
	extendedBiddingDuration?: number;
	gmv?: number;
	id: string;
	increment?: string;
	internalNotes?: string;
	modifiedAt?: string;
	modifiedBy?: string;
	numberOfImages?: number;
	previewTimestamp?: string;
	primaryImageName?: string;
	sellthroughRate?: string;
	startTimestamp?: string;
	status: AuctionStatus;
	tags?: string[];
	title?: string;
	totalBidValue?: number;
	totalBids?: number;
	totalBuyerPremium?: number;
	totalCommission?: number;
	totalLots?: number;
	type: AuctionType;
	uniqueBidders?: number;
	uniqueBiddersPerLot?: number;
	uniqueSellers?: number;
	unsoldLots?: number;
}

export type AuctionTimestampKey = 'previewTimestamp' | 'startTimestamp' | 'endTimestamp';
export type AuctionTimestamps = { [key in AuctionTimestampKey]?: string };

export interface AuctionTechnicalDetails {
	commission?: number;
	extended_bidding_duration?: number;
	buyer_premium?: number;
	auction_type?: AuctionType;
}

export const FIXED_PRICE_AUCTION_ID = 'fixed_price_marketplace';

export interface CreateAuctionParams {
	title: string;
	auction_type: AuctionType;
}

export interface KernelWeeklyAuctionSummary {
	auctionId: string;
	endTimestamp: string;
	minBidPrice: number;
	startTimestamp: string;
}

import React from 'react';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import TableHeader from '../../../../components/TableHeader';
import useSortByColumns from '../../../../hooks/useSortByColumns';
import useLotWatchers from '../../../../queries/useLotWatchers';
import { LotWatcher } from '../../../../services/lotsService/getLotWatchers.model';
import LotWatchListRows from './LotWatchListRows';

const LotWatchList = ({ lotId }: { lotId: string }) => {
	const { data: lotWatchers = [] as LotWatcher[], status: lotWatchersStatus } =
		useLotWatchers(lotId);

	const {
		sortedRows: sortedLotWatchers,
		getSortDirectionForColumn,
		performNextSort,
	} = useSortByColumns<LotWatcher>(lotWatchers);

	return (
		<div>
			<div className="border-base grid grid-cols-3 rounded-t-md py-2 px-4">
				<div className="col-span-1">
					<TableHeader
						label="Date/Time"
						kind="sortable"
						sortDirection={getSortDirectionForColumn('timestamp')}
						onClick={() => performNextSort('timestamp')}
					/>
				</div>
				<div className="col-span-2">
					<TableHeader
						label="Watcher"
						kind="sortable"
						sortDirection={getSortDirectionForColumn('name')}
						onClick={() => performNextSort('name')}
					/>
				</div>
			</div>
			<div className="border-base w-full rounded-b-md border-t-0">
				<LoadingWrapper queryStatuses={[lotWatchersStatus]}>
					<LotWatchListRows lotWatchers={sortedLotWatchers} />
				</LoadingWrapper>
			</div>
		</div>
	);
};

export default LotWatchList;

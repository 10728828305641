import { isEqual } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Textarea } from '../../../../prizm-ui/Textarea';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { getDeltas } from '../../../../utils/formatters/getDeltas';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';

const EditLotMetadataModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const [draftLot, setDraftLot] = useState(lot);
	const updateDraftLot = (deltas: Partial<KernelLot>) => setDraftLot({ ...draftLot, ...deltas });

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const updatedFields = getDeltas<KernelLot>({ olderVersion: lot, newerVersion: draftLot });
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id, updatedFields],
		mutationFn: () => updateLot({ lot_id: lot.lot_id, ...updatedFields }),
	});
	const handleUpdateLot = () =>
		doUpdateLot(undefined, {
			onSuccess: () => {
				updateLotInCache(updatedFields);
				showSuccessToast('Successfully updated lot internal notes');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating lot internal notes. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			title="Edit Lot Internal Notes"
			onClose={onClose}
			onConfirm={handleUpdateLot}
			canConfirm={!isEqual(lot, draftLot)}
			isLoading={updateLotStatus === 'loading'}
		>
			<div className="grid grid-cols-1 gap-4">
				<Textarea
					id="internal_notes"
					value={
						typeof lot.internal_notes === 'string' ? lot.internal_notes : lot.internal_notes?.text
					}
					onChange={({ target: { value } }) => updateDraftLot({ internal_notes: value })}
					label="Internal Notes"
				/>
			</div>
		</ActionModal>
	);
};

export default EditLotMetadataModal;

import { useQuery } from 'react-query';
import { getSellerStatementPDF } from '../../services/sellerStatementService';
import { QueryKeys } from '../queryKeys';

const useSellerStatementPDF = ({
	auctionId,
	statementId,
}: {
	auctionId: string;
	statementId: string;
}) =>
	useQuery([QueryKeys.GetSellerStatementPDF], () =>
		getSellerStatementPDF({ auctionId, statementId })
	);

export default useSellerStatementPDF;

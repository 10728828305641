import { Alert } from '../../../prizm-ui/Alert';
import { KernelLot } from '../../../services/lotsService/lotsService.model';

// Returns an empty string when everything is filled, otherwise a list of the missing fields
const getTextContentForMissingRequiredFields = (lot: KernelLot): string => {
	let textContent = '';
	if (!lot.lot_number) textContent += (textContent.length > 1 ? ', ' : '') + 'Lot Number';
	if (!lot.title) textContent += (textContent.length > 1 ? ', ' : '') + 'Title';
	if (!lot.category) textContent += (textContent.length > 1 ? ', ' : '') + 'Category';
	if (!lot.sub_category) textContent += (textContent.length > 1 ? ', ' : '') + 'Sub-Category';
	if (!lot.status) textContent += (textContent.length > 1 ? ', ' : '') + 'Status';
	if (!lot.seller_id) textContent += (textContent.length > 1 ? ', ' : '') + 'Seller';
	if (!lot.consignment_manager_id)
		textContent += (textContent.length > 1 ? ', ' : '') + 'Consignment Manager';
	if (!lot.min_bid_price)
		textContent += (textContent.length > 1 ? ', ' : '') + 'Minimum Opening Price';

	return textContent;
};

const RequiredFieldsAlert = ({ lot }: { lot: KernelLot }) => {
	const missingFieldsText = getTextContentForMissingRequiredFields(lot);

	return (
		<Alert
			variant={missingFieldsText ? 'error' : 'success'}
			title={
				missingFieldsText
					? 'This lot is missing required fields'
					: 'This lot has all required fields'
			}
			description={missingFieldsText ? `Please supply values for ${missingFieldsText}` : ''}
		/>
	);
};

export default RequiredFieldsAlert;

import { InvoiceStatus } from '../../../../../../services/ordersService/ordersService.model';
import {
	OrderStatusTransitionRule,
	AWAITING_PAYMENT_TRANSITION_RULE,
	BLOCKED_TRANSITION_RULE,
	DEFAULTED_TRANSITION_RULE,
	PAYMENT_FAILED_TRANSITION_RULE,
	USER_SUBMITTED_TRANSITION_RULE,
} from './OrderStatus.model';

export const getTransitionRule = (
	currentStatus: InvoiceStatus
): OrderStatusTransitionRule | null => {
	switch (currentStatus) {
		case 'Awaiting_Payment':
			return AWAITING_PAYMENT_TRANSITION_RULE;
		case 'Blocked':
			return BLOCKED_TRANSITION_RULE;
		case 'Defaulted':
			return DEFAULTED_TRANSITION_RULE;
		case 'Payment_Failed':
			return PAYMENT_FAILED_TRANSITION_RULE;
		case 'User_Submitted':
			return USER_SUBMITTED_TRANSITION_RULE;
		default:
			return null;
	}
};

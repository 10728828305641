import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import BidderLotURL from '../../../../components/BidderLotURL';
import { Button } from '../../../../prizm-ui/Button';
import Card from '../../../../components/Card';
import PageTitle from '../../../../components/PageTitle';
import Toast from '../../../../components/Toast';
import { Slide, toast } from 'react-toastify';
import { QueryKeys } from '../../../../queries/queryKeys';
import { regenerateSellerStatementByLot } from '../../../../services/sellerStatementService';

const ListingDetailHeader = ({
	title,
	auctionName,
	status,
	lotId,
	auctionId = 'Unassigned',
}: {
	title: string;
	auctionName: string;
	status: string;
	lotId: string;
	auctionId?: string;
}) => {
	const { mutate: doRegenerateSellerStatement, status: regenerateSellerStatementStatus } =
		useMutation({
			mutationKey: [QueryKeys.RegenerateSellerStatementForLot, lotId],
			mutationFn: (id: string) => regenerateSellerStatementByLot(id),
		});

	const handleRegenerateSellerStatement = () =>
		doRegenerateSellerStatement(lotId, {
			onSuccess: () => {
				toast(<Toast message="Successfully regenerated seller statement." sentiment="success" />, {
					transition: Slide,
				});
			},
			onError: () => {
				toast(
					<Toast
						message="Error regenerating seller statement. Please try again later."
						sentiment="warning"
					/>,
					{
						transition: Slide,
					}
				);
			},
		});

	return (
		<Card>
			<PageTitle
				heading={title}
				icon="pe-7s-users icon-gradient bg-premium-dark font-weight-bold"
			/>
			<div className="my-8">
				<div className="legacy-h6">
					Auction:{' '}
					<Link className="link" to={`/auctions/${auctionId}/inventory-manager/${lotId}`}>
						{auctionName}
					</Link>
				</div>
				<div className="legacy-h6 flex">
					<div className="mr-2">Bidder URL:</div>
					<BidderLotURL auctionId={auctionId} lotId={lotId} />
				</div>
				<div className="legacy-h6">
					Lot Status: <span className="font-semibold">{status}</span>
				</div>
			</div>
			<div className="flex">
				<Button
					disabled={!['Completed_Sold', 'Completed_Unsold'].includes(status)}
					size="md"
					variant="secondary"
					loading={regenerateSellerStatementStatus === 'loading'}
					onClick={handleRegenerateSellerStatement}
				>
					Regenerate Seller Statement
				</Button>
			</div>
		</Card>
	);
};

export default ListingDetailHeader;

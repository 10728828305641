import { useQuery } from 'react-query';
import { QueryKeys } from '../queryKeys';
import { getCashAccountEntries } from '../../services/cashAccountsService';
import { CashAccountCategoryFilter } from '../../services/cashAccountsService/cashAccountsService.model';

const useCashAccountEntries = ({
	category,
	userId,
	page,
	pageSize,
}: {
	category?: CashAccountCategoryFilter;
	userId: string;
	page?: number;
	pageSize?: number;
}) =>
	useQuery(
		[QueryKeys.GetCashAccountEntries, userId, page, pageSize],
		() => getCashAccountEntries({ category, userId, page, pageSize }),
		{
			enabled: !!userId,
		}
	);

export default useCashAccountEntries;

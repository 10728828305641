import React from 'react';
import { Icon } from '../Icon';
import { SelectContext } from './Select';

export type OptionProps = {
	children?: string;
	index?: number;
	value?: string;
};

export const Option = ({ children, index, value }: OptionProps) => {
	const { menu, native, onItemClick, registerItem, removeItem, selectedValue } =
		React.useContext(SelectContext);

	const option = {
		index: index ?? -1,
		label: children || '',
		value: value || children || '',
	};

	React.useEffect(() => {
		registerItem?.(option);

		return () => removeItem?.(option);
	}, []);

	return (
		<>
			{native ? (
				<option value={value}>{children}</option>
			) : (
				<a
					{...menu?.itemProps[index ?? -1]}
					className={[
						'cursor-pointer',
						'flex',
						'items-center',
						'justify-between',
						'py-2.5',
						'px-4',
						'rounded-lg',
						'whitespace-pre',
						'transition-colors',
						'text-neutral2',
						'hover:bg-neutralbkg2',
						'focus:bg-neutralbkg2',
					].join(' ')}
					onClick={() => onItemClick?.(value || '')}
				>
					{children || <>&nbsp;</>}
					{value != null && value === selectedValue && (
						<div className="text-primary-500">
							<Icon name="Checkmark" size="md" aria-hidden />
						</div>
					)}
				</a>
			)}
		</>
	);
};

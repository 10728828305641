import config from '../../config';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const sendPaymentReminder = async ({ invoiceNumber }: { invoiceNumber: string }) => {
	try {
		const body = { invoiceNumber };

		const response = await postAuthed(`${config.api.send_payment_reminder}`, body);

		if (response.status >= 400) {
			throw response;
		}

		return;
	} catch (err) {
		console.log(`Unable to send payment reminder: ${err}`);

		throw err;
	}
};

export default sendPaymentReminder;

import React from 'react';
import { AvatarSize } from './Avatar';

type AvatarLabelProps = {
	email?: string;
	name?: string;
	size?: AvatarSize;
};

const getNameClasses = ({ size }: { size?: AvatarSize }) => {
	const classes = ['text-neutral1'];

	switch (size) {
		case 'xs':
			classes.push('hidden');
			break;
		case 'sm':
			classes.push('text-body1');
			break;
		case 'md':
			classes.push('text-subtitle1');
			break;
		case 'lg':
			classes.push('text-display2');
			break;
		case 'xl':
			classes.push('hidden');
			break;
	}

	return classes.join(' ');
};

const getEmailClasses = ({ size }: { size?: AvatarSize }) => {
	const classes = ['text-neutral3'];

	switch (size) {
		case 'xs':
			classes.push('hidden');
			break;
		case 'sm':
			classes.push('text-body2');
			break;
		case 'md':
		case 'lg':
			classes.push('text-body1');
			break;
		case 'xl':
			classes.push('hidden');
			break;
	}

	return classes.join(' ');
};

export const AvatarLabel: React.FC<AvatarLabelProps> = ({ email, name, size }) => {
	return (
		<div className="flex flex-col justify-center" data-testid="avatar-label">
			{name && <span className={getNameClasses({ size })}>{name}</span>}
			{email && <span className={getEmailClasses({ size })}>{email}</span>}
		</div>
	);
};

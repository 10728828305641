export interface Lot {
	lot_number: number;
	serial_number: string;
	title: string;
	location: string;
	current_price: number;
}

export const invoiceDetailsColumnWidthClasses: { [columnName: string]: string } = {
	lot_number: 'col-span-1',
	serial_number: 'col-span-2',
	title: 'col-span-4',
	location: 'col-span-2',
	current_price: 'col-span-2',
};

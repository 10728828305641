import React from 'react';
import Layout from '../../components/Layout';
import RequireAuth from '../../routing/RequireAuth';
import ReportsRoot from './Reports';
import BulkUploadsRoot from './BulkUploads';

const FinanceRoot = ({ match }: { match: { url: string } }) => {
	return (
		<RequireAuth>
			<Layout>
				<ReportsRoot match={match} />
				<BulkUploadsRoot match={match} />
			</Layout>
		</RequireAuth>
	);
};

export default FinanceRoot;

import React from 'react';
import {
	CATEGORIES,
	ITEM_TYPES,
	KernelLot,
	LotCategory,
	LotSubCategory,
	SUB_CATEGORIES,
} from '../../../../services/lotsService/lotsService.model';
import { Select, Option } from '../../../../prizm-ui/Select';
import { ItemType } from 'aws-sdk/clients/mediastoredata';

const Categories = ({
	currentCategory,
	currentItemType,
	currentSubcategory,
	updateLotField,
}: {
	currentCategory?: LotCategory;
	currentItemType?: ItemType;
	currentSubcategory?: LotSubCategory;
	updateLotField: (fieldsToUpdate: Partial<KernelLot>) => void;
}) => {
	return (
		<>
			<Select
				id="category"
				onChange={({ target: { value } }) => {
					updateLotField({
						category: value as LotCategory,
						sub_category: undefined,
					});
				}}
				key="lot-editor-category"
				label="Category"
				value={currentCategory}
			>
				{CATEGORIES.map((category, index) => (
					<Option index={index} value={category} key={category}>
						{category}
					</Option>
				))}
			</Select>
			<Select
				id="item-type"
				key="lot-editor-item-type"
				label="Item Type"
				onChange={({ target: { value } }) => updateLotField({ item_type: value as ItemType })}
				value={currentItemType}
			>
				{ITEM_TYPES.map((itemType, index) => (
					<Option index={index} value={itemType} key={itemType}>
						{itemType}
					</Option>
				))}
			</Select>
			<Select
				className="w-full"
				id="subCategory"
				key="lot-editor-sub-category"
				onChange={({ target: { value } }) =>
					updateLotField({ sub_category: value as LotSubCategory })
				}
				label="Sub-Category"
				value={currentSubcategory}
			>
				{SUB_CATEGORIES.map((subCategory, index) => (
					<Option index={index} value={subCategory} key={subCategory}>
						{subCategory}
					</Option>
				))}
			</Select>
		</>
	);
};

export default Categories;

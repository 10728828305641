import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import formatCurrency from '../../../utils/formatters/formatCurrency';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import SortableTable from '../../../components/SortableTable';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import { Button } from '../../../prizm-ui/Button';
import { Input } from '../../../prizm-ui/Input';
import { Tag } from '../../../prizm-ui/Tag';
import { Tooltip } from '../../../prizm-ui/Tooltip';
import useAuctions from '../../../queries/useAuctions';
import { formatDate, stripUnderscores } from '../../../utils/formatters';
import { getTestIdentifier } from '../../../utils/test-identifiers/getTestIdentifier';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import useLotsBySubset from '../../../queries/useLotsBySubset';
import { Auction } from '../../../services/auctionsService';
import { getChipSentimentForAuctionItemStatus } from '../../../services/lotsService';

const SearchAuctionListings = () => {
	const { query = '' } = useParams<{ query?: string }>();
	const decodedQuery = decodeURIComponent(query);
	const history = useHistory();

	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();

	const [draftSearchQuery, setDraftSearchQuery] = useState(decodedQuery);
	const [submittedSearchQuery, setSubmittedSearchQuery] = useState(draftSearchQuery);
	const handleSubmitSearchQuery = () => {
		setSubmittedSearchQuery(draftSearchQuery);
		history.push(`/inventory/search/${encodeURIComponent(draftSearchQuery)}`);
	};

	const { data: lots = [] as KernelLot[], status: lotsStatus } = useLotsBySubset({
		kind: 'search-term',
		queryType: 'All',
		searchTerm: submittedSearchQuery,
	});

	const columns: ColumnDef<KernelLot>[] = [
		{
			accessorKey: 'serial_number',
			header: 'Serial',
			cell: ({
				getValue,
				row: {
					original: { lot_id: lotId, serial_number },
				},
			}) => (
				<Tooltip id="view-listing" content="View Listing">
					<Link
						className="link"
						data-testid={getTestIdentifier({
							componentName: 'SearchAuctionListings',
							descriptor: `serial-number-${serial_number}`,
							sectionPath: 'inventory',
							pagePath: 'search',
						})}
						to={`/inventory/listings/${lotId}/manage`}
					>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
		{ accessorKey: 'cert_number', header: 'Cert No.' },
		{
			accessorFn: ({ auction_id }) => {
				const thisAuction = auctions.find(({ id }) => id === auction_id);
				return thisAuction?.title ?? 'Unknown';
			},
			id: 'auction_id',
			header: 'Auction',
		},
		{
			accessorFn: ({ lot_number = '' }) => lot_number?.toString(),
			header: 'Lot',
			id: 'lot_number',
		},
		{
			accessorKey: 'title',
			header: 'Title',
		},
		{
			/** Keeping 'Completed_Sold' as the actual cell value breaks column sorting */
			accessorFn: ({ status }) => (status === 'Completed_Sold' ? 'Sold' : status),
			accessorKey: 'status',
			header: 'Status',
			cell: ({
				getValue,
				row: {
					original: { status },
				},
			}) => (
				<div className="w-fit">
					<Tag variant={getChipSentimentForAuctionItemStatus(status)}>
						{stripUnderscores(getValue() as string)}
					</Tag>
				</div>
			),
		},
		{
			accessorKey: 'end_timestamp',
			header: 'End Time',
			cell: ({ getValue }) => formatDate(getValue() as string),
			enableGlobalFilter: false,
		},
		{
			accessorKey: 'current_price',
			header: 'Current Price',
			cell: ({ getValue }) => formatCurrency(getValue() as number),
			enableGlobalFilter: false,
		},
		{
			accessorKey: 'min_bid_price',
			header: 'Min Bid',
			cell: ({ getValue }) => formatCurrency(getValue() as number),
			enableGlobalFilter: false,
		},
		{
			accessorKey: 'reserve_amount',
			header: 'Reserve',
			cell: ({ getValue }) => formatCurrency(getValue() as number),
			enableGlobalFilter: false,
		},
		{
			accessorKey: 'number_of_bids',
			header: 'Bids',
			enableGlobalFilter: false,
			cell: ({
				getValue,
				row: {
					original: { lot_id: lotId },
				},
			}) => (
				<Tooltip id="view-bid-history" content="View Bid History">
					<Link className="link" to={`/inventory/listings/${lotId}/bids`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
		},
	];

	const sortableTableParams: SortableTableParams<KernelLot> = {
		data: lots,
		columns,
		getRowId: ({ lot_id }) => lot_id,
	};

	const noResultsMessage = submittedSearchQuery
		? 'No results to show.'
		: 'Please enter search term to begin.';

	return (
		<Card>
			<h1 className="text-display4">Search Auction Listings</h1>
			<div className="flex flex-col lg:flex-row lg:items-center mt-2">
				<Input
					id="search-customer-input"
					className="flex-grow mb-2 lg:mb-0 lg:mr-2"
					label="Search by title, serial, or cert number ..."
					type="text"
					value={draftSearchQuery}
					onChange={({ target: { value } }) => setDraftSearchQuery(value)}
					onKeyDown={({ key }) => key === 'Enter' && handleSubmitSearchQuery()}
				/>
				<Button
					variant="primary"
					trailingIcon="Search"
					disabled={submittedSearchQuery === draftSearchQuery}
					onClick={handleSubmitSearchQuery}
				>
					Search
				</Button>
			</div>
			<div className="mt-4">
				<LoadingWrapper queryStatuses={[auctionsStatus]}>
					<SortableTable
						globalFilterPlaceholder="Filter ..."
						tableParams={sortableTableParams}
						isLoading={lotsStatus === 'loading'}
						noResultsMessage={noResultsMessage}
					/>
				</LoadingWrapper>
			</div>
		</Card>
	);
};

export default SearchAuctionListings;

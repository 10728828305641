import React, { Component } from 'react';
import moment from 'moment';

// Based on https://www.florin-pop.com/blog/2019/05/countdown-built-with-react/
export default class CountdownTimer extends Component {
	state = {
		days: undefined,
		hours: undefined,
		minutes: undefined,
		seconds: undefined,
	};

	componentDidMount() {
		this.interval = setInterval(() => {
			const { timeTillDate, timeFormat } = this.props;
			const then = moment(timeTillDate, timeFormat);
			const now = moment();
			const days = then.diff(now, 'days');
			const hours = then.diff(now, 'hours') % 24; //countdown.format('HH');
			const minutes = then.diff(now, 'minutes') % 60; //countdown.format('mm');
			const seconds = then.diff(now, 'seconds') % 60; //countdown.format('ss');

			this.setState({ days, hours, minutes, seconds });
		}, 1000);
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	render() {
		const { days, hours, minutes, seconds } = this.state;

		if (typeof seconds === 'undefined') return null;

		return (
			<div className="countdown-wrapper">
				<div className="countdown-item">
					{days}
					<span>days</span>
				</div>

				<div className="countdown-item">
					{hours}
					<span>hours</span>
				</div>

				<div className="countdown-item">
					{minutes}
					<span>minutes</span>
				</div>

				<div className="countdown-item">
					{seconds}
					<span>seconds</span>
				</div>
			</div>
		);
	}
}

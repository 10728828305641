import { TypedDropdownMenuItem } from '../../../components/Dropdown/Dropdown.model';
import {
	KernelFixedPriceSellerStatement,
	KernelSellerStatementAdjustment,
	SellerStatementStatusType,
} from '../../../services/sellerStatementService';
import { formatDate } from '../../../utils/formatters';

export const SELLER_STATEMENT_TYPE_OPTIONS: TypedDropdownMenuItem<SellerStatementStatusType>[] = [
	{ value: 'Paid', label: 'Paid' },
	{ value: 'Unpaid', label: 'Unpaid' },
];

export const FIXED_PRICE_SELLER_STATEMENT_SUMMARY_CSV_COLUMNS = [
	{
		label: 'Statement Date',
		value: (row: KernelFixedPriceSellerStatement) => formatDate(row.createdAt),
	},
	{
		label: 'Statement Number',
		value: 'sellerStatementNumber',
	},
	{
		label: 'Seller Name',
		value: 'sellerName',
	},
	{
		label: 'Email',
		value: 'sellerEmail',
	},
	{
		label: 'Total Lot Value',
		value: 'totalLotValue',
	},
	{
		label: 'Commission',
		value: 'commission',
	},
	{
		label: 'Total Statement Amount',
		value: 'totalStatementAmount',
	},
	{
		label: 'Net Adjustments',
		value: (row: KernelFixedPriceSellerStatement): number =>
			row.adjustments
				? row.adjustments.reduce(
						(accumulator: number, thisAdjustment: KernelSellerStatementAdjustment) =>
							accumulator + Number(thisAdjustment.amount),
						0
				  )
				: 0,
	},
	{
		label: 'Total Ready for Payout',
		value: 'totalReadyForPayout',
	},
	{
		label: 'Status',
		value: 'sellerStatementStatus',
	},
];

import React from 'react';

const GoldinLogo = ({ className }: { className: string }) => (
	<svg
		id="Goldin_Logo"
		role="image"
		className={className}
		version="1.1"
		viewBox="0 0 397.3 264.9"
		x="0px"
		xmlns="http://www.w3.org/2000/svg"
		y="0px"
	>
		<g>
			<path
				d="M141.3,117.1c-18.2,0-29.4,13.1-29.4,29.9c0,5.4,1.4,10.2,1.9,11.3c3.8,10.7,13.1,18.2,26.8,18.2
    c18.2,0,29.5-13.1,29.5-30C170.1,130.2,159.5,117.1,141.3,117.1z M141.8,171c-9.4,0-14.6-11.2-14.6-25.8c0-13.6,4.6-22.3,13-22.3
    c9.4,0,14.5,11,14.5,25.5C154.7,162,150.2,171,141.8,171z M297,170.8h-1.8c-1.4,0-5.2-3.7-5.2-5.5l0-47.9c0-0.2-0.2-0.3-0.4-0.3
    l-21.1,7.1c-0.2,0.1-0.3,0.4-0.1,0.5l7.3,5.9c0.1,0.1,0.1,0.1,0.1,0.2l0,34.4c0,1.8-4,5.5-5.5,5.5h-2.7c-0.2,0-0.3,0.1-0.3,0.3v3.9
    c0,0.2,0.1,0.3,0.3,0.3l29.2,0c0.2,0,0.3-0.1,0.3-0.3v-3.9C297.3,170.9,297.1,170.8,297,170.8z M282.4,113.3c4.6,0,8.3-3.7,8.3-8.3
    s-3.7-8.3-8.3-8.3c-4.6,0-8.3,3.7-8.3,8.3S277.8,113.3,282.4,113.3z M364.3,170.8h-2.4c-1.3,0-4.9-4-4.9-5.9l0-29.6
    c0-9.3-6-14.9-9.7-16.5c-15.9-7-28.2,10.6-28.2,10.7l0-0.1c0,0,0,0.1,0,0.1h-0.3v-12.2c0-0.2-0.2-0.3-0.4-0.3
    c-1.9,0.9-8.4,4.3-18.5,7.7c-0.2,0.1-0.3,0.3-0.1,0.5l8.2,7v32.6c0,1.8-3.6,5.9-4.9,5.9h-2.4c-0.2,0-0.3,0.1-0.3,0.3v3.9
    c0,0.2,0.1,0.3,0.3,0.3h29.1c0.2,0,0.3-0.1,0.3-0.3v-3.9c0-0.2-0.1-0.3-0.3-0.3H327c-1.5,0-5-4.1-5-5.9v-25.1v-1.1
    c4.2-6.6,8.9-11.6,14.8-11.5c4.8,0.1,6.1,5.7,6.1,9.5l0,28.2c0,1.8-3.5,5.9-5,5.9h-2.3c-0.2,0-0.3,0.1-0.3,0.3v3.9
    c0,0.2,0.1,0.3,0.3,0.3h28.7c0.2,0,0.3-0.1,0.3-0.3v-3.9C364.6,170.9,364.5,170.8,364.3,170.8z M243.1,123.9
    c-3.6-3.9-10-6.8-16.4-6.8c-14.5,0-27,10.6-27,29.2c0,14.9,9.8,28.9,25.5,30.1c7.1,0.6,14.6-2.3,19.5-7.5l2.5,5.9
    c0.1,0.2,0.3,0.3,0.5,0.3l16.5,0c0.2,0,0.3-0.1,0.3-0.3v-3.9c0-0.2-0.1-0.3-0.3-0.3h-1.8c-1.4,0-5.2-3.7-5.2-5.5l0-76.7
    c0-0.2-0.2-0.3-0.4-0.3l-21.1,7.1c-0.2,0.1-0.3,0.4-0.1,0.5l7.3,5.9c0.1,0.1,0.1,0.1,0.1,0.2L243.1,123.9z M242.9,155.1
    c-0.5,8.7-5.7,13.9-13.8,13.9c-7.1,0-12.5-6.3-13.5-14.8c-0.6-5.1-0.6-10.9,0-15.9c1.1-8.7,4.9-14.2,12.8-14.2
    c7.1,0,14,7.2,14.5,15.8C243.1,144.6,243.1,150.3,242.9,155.1z M102.8,175.3h2.8c0.1,0,0.3-0.1,0.2-0.3l0-41.2
    c0-0.2-0.1-0.3-0.3-0.3l-22.6,0c-0.2,0-0.3,0.1-0.3,0.3v3.9c0,0.2,0.1,0.3,0.3,0.3h3.2c1.6,0,5.5,3.7,5.5,5.5v17
    c-3.9,2-8.8,3.4-15,3.4c-17,0-28.3-15.2-28.3-33.1c0-21.7,10-32.5,23-32.5c11.9,0,18.4,10.3,16.7,22c0,0.2,0.1,0.3,0.3,0.3l5.5,0
    c0.1,0,0.2-0.1,0.3-0.2l7.9-19.8c0-0.1,0-0.2-0.1-0.3c-7.3-6.7-17.7-9.6-30-9.6c-19.8,0-39.3,16.6-39.3,43
    c0,25.2,15.8,42.8,39.3,42.8c10,0,17.3-3,22.4-7.3l8.2,5.8C102.6,175.2,102.7,175.3,102.8,175.3z M177.9,165.3c0,0.2,0,0.4-0.1,0.6
    c-0.6,1.9-4,4.9-5.4,4.9h-3.2c-0.2,0-0.3,0.1-0.3,0.3v3.9c0,0.2,0.1,0.3,0.3,0.3h31.5c0.2,0,0.3-0.1,0.3-0.3v-3.9
    c0-0.2-0.1-0.3-0.3-0.3h-3.2c-1.4,0-4.8-3-5.4-4.9c-0.1-0.2-0.1-0.4-0.1-0.6h0l0-76.7c0-0.2-0.2-0.3-0.4-0.3l-21.1,7.1
    c-0.2,0.1-0.3,0.4-0.1,0.5l7.3,5.9c0.1,0.1,0.1,0.1,0.1,0.2L177.9,165.3z"
			/>
		</g>
	</svg>
);

export default GoldinLogo;

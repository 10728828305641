import { axiosAuth, axiosUnauthed } from '../../AdminUtils/httpUtils';
import config from '../../config';

const getBulkPayoutsS3Url = async () => {
	const response = await axiosAuth.get(config.api.bulk_payouts_upload_url);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

/** @description Uploads bulk payouts to the S3 */
export const bulkUploadStripePayouts = async ({
	bulkPayoutsFile,
	employeeId,
}: {
	bulkPayoutsFile: File;
	employeeId: string;
}) => {
	const signedUrl = await getBulkPayoutsS3Url();
	const baseHeaders = {
		'Content-Type': 'text/csv',
	};
	const headers = {
		...baseHeaders,
		'x-amz-meta-user-id': employeeId,
	};

	const response = await axiosUnauthed.put(signedUrl, bulkPayoutsFile, { headers });

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

export const getInterestedPartyLabel = ({
	familyName = '',
	givenName = '',
	email = '',
}: {
	familyName?: string;
	givenName?: string;
	email?: string;
}) => {
	const interestedPartyName = [givenName, familyName].join(' ');

	if (!interestedPartyName && email) {
		return email;
	}

	if (interestedPartyName && email) {
		return `${interestedPartyName} (${email})`;
	}

	return '';
};

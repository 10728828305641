import React, { useEffect } from 'react';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';
import { MenuContext } from './Menu';

export type MenuItemProps = {
	href?: string;
	icon?: IconName | JSX.Element;
	index: number;
	isActive?: boolean;
	value?: string;
} & React.ComponentPropsWithoutRef<'a'>;

export const MenuItem: React.FC<MenuItemProps> = ({
	children,
	icon,
	index,
	isActive,
	value,
	...props
}) => {
	const { menu, onSelect, registerItem, removeItem } = React.useContext(MenuContext);

	useEffect(() => {
		registerItem();

		return () => removeItem();
	}, [registerItem, removeItem]);

	const handleClick = () => {
		onSelect?.(value);
		// Menu will not close without the timeout
		setTimeout(() => menu?.setIsOpen(false), 0);
	};

	const getIconName = (iconName: IconName) => {
		const iconFilled = `${iconName}Filled` as IconName;
		if (isActive && iconList.includes(iconFilled)) return iconFilled;

		return iconName;
	};

	return (
		<a
			{...props}
			{...menu?.itemProps[index]}
			className={[
				'cursor-pointer',
				'inline-flex',
				'items-center',
				'gap-3',
				'w-60',
				'py-2.5',
				'px-4',
				'rounded-lg',
				'text-body1',
				'transition-colors',
				'hover:bg-neutralbkg2',
				'focus:bg-neutralbkg2',
				...(isActive ? ['bg-neutralbkg2', 'text-neutral1'] : ['text-neutral2']),
			].join(' ')}
			onClick={handleClick}
		>
			{typeof icon === 'string' && iconList.includes(icon) ? (
				<span className={['flex', isActive ? 'text-primary-500' : 'text-neutral3'].join(' ')}>
					<Icon name={getIconName(icon)} size="md" aria-hidden />
				</span>
			) : (
				React.isValidElement(icon) && icon
			)}
			{children}
		</a>
	);
};

import { ColumnDef } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import formatCurrency from '../../../../../utils/formatters/formatCurrency';
import Card from '../../../../../components/Card';
import SortableTable from '../../../../../components/SortableTable';
import { SortableTableParams } from '../../../../../components/SortableTable/SortableTable.model';
import useOffersForItem from '../../../../../queries/useOffersForItem';
import { FixedPriceOffer, OfferStatus } from '../../../../../services/offersService';
import { formatDate } from '../../../../../utils/formatters';
import OfferStatusChip from './OfferStatusChip';
import TimeRemainingCell from './TimeRemainingCell';
import { Banner } from '../../../../../prizm-ui/Banner';
import { FixedPriceItemStatus } from '../../../../../services/lotsService/lotsService.model';

const OffersTable = ({
	allowsOffers,
	itemStatus,
}: {
	allowsOffers: boolean;
	itemStatus: FixedPriceItemStatus;
}) => {
	const { itemId } = useParams<{ itemId: string }>();

	const { data: offers = [] as FixedPriceOffer[], status: offersStatus } = useOffersForItem(itemId);

	const columns: ColumnDef<FixedPriceOffer>[] = [
		{
			accessorKey: 'createdAt',
			header: 'Offer Time',
			cell: ({ getValue }) => formatDate(getValue() as string),
		},
		{
			accessorKey: 'offerPrice',
			header: () => <div className="text-right">Offer Amount</div>,
			cell: ({ getValue }) => (
				<div className="text-right">{formatCurrency(getValue() as number)}</div>
			),
		},
		{
			accessorFn: ({ offerer: { fullName } }) => fullName,
			header: 'Offerer',
			cell: ({
				getValue,
				row: {
					original: {
						offerer: { id },
					},
				},
			}) => (
				<Link className="link" to={`/usermanagement/customer/${id}/customer-overview`}>
					{getValue() as string}
				</Link>
			),
		},
		{
			accessorKey: 'offerStatus',
			header: 'Offer Status',
			cell: ({ getValue }) => <OfferStatusChip status={getValue() as OfferStatus} />,
		},
		{
			header: 'Time Remaining',
			cell: ({
				row: {
					original: { createdAt },
				},
			}) => <TimeRemainingCell createdAt={createdAt} />,
		},
	];

	const tableParams: SortableTableParams<FixedPriceOffer> = {
		columns,
		data: offers,
	};

	return (
		<Card>
			<h2 className="text-display5">Offers</h2>
			{allowsOffers ? (
				<>
					{offersStatus === 'success' && offers.length === 0 ? (
						<div className="mt-2">
							<Banner variant="gray" icon="Info" title="There are no offers for this item yet" />
						</div>
					) : (
						<div className="mt-4">
							<SortableTable tableParams={tableParams} isLoading={offersStatus === 'loading'} />
						</div>
					)}
				</>
			) : (
				<div className="mt-2">
					<Banner
						variant="gray"
						icon="LockClosedFilled"
						title={`Offers ${
							itemStatus === 'Completed_Sold' ? 'were' : 'are'
						} not enabled for this item`}
					/>
				</div>
			)}
		</Card>
	);
};

export default OffersTable;

export enum FeatureFlags {
	Balance = 'balance',
	CollectorsImagesInInventoryManager = 'collectors-images-in-inventory-manager',
	CreateEmployee = 'create-employee',
	DisableVaultListing = 'disable_vault_listing',
	LimitLotsListResponseSize = 'limit-lots-list-response-size',
	NewAuctionPicker = 'new-auction-picker',
	NewPerformanceDashboard = 'new-performance-dashboard',
	NewSellerStatement = 'new-seller-statement',
	NewUserManagement = 'new-user-management',
	NewInvoicesTable = 'new-invoices-table',
	NewLotEditor = 'new-lot-editor',
	SellerRateTable = 'seller-rate-table',
	TieredCommissionRates = 'tiered-commission-rates',
	VaultCollection = 'vault-collection',
}

export type FeatureFlag = `${FeatureFlags}`;

import { AppBar, Toolbar } from '@material-ui/core';
import { Button } from '../../../../../prizm-ui/Button';

const SaveFooter = ({
	handleCancel,
	handleSave,
	isDisabled,
	isLoading,
}: {
	handleCancel: () => void;
	handleSave: () => void;
	isDisabled: boolean;
	isLoading: boolean;
}) => {
	return (
		<AppBar key="lot-mgr-footer" className="floating-footer-toolbar margin-h-center rounded-lg">
			<Toolbar>
				<div className="flex">
					<Button
						className="mr-2"
						disabled={isDisabled || isLoading}
						size="md"
						variant="brand"
						loading={isLoading}
						onClick={handleSave}
					>
						Save Lot
					</Button>
					<Button
						disabled={isDisabled || isLoading}
						size="md"
						variant="secondary"
						onClick={handleCancel}
					>
						Discard Changes
					</Button>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default SaveFooter;

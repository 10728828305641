import { Search24Regular } from '@fluentui/react-icons';
import { noop } from 'lodash';
import React, { ChangeEvent } from 'react';
import Input from '../../Input';
import { useTable } from '../useTableContext';

const GlobalFilter = ({
	placeholder,
	onChangeSearchQuery = noop,
}: {
	placeholder: string;
	onChangeSearchQuery?: (newQuery: string) => void;
}) => {
	const { getState, setGlobalFilter } = useTable();

	const handleChange = ({ target: { value = '' } }: ChangeEvent<HTMLInputElement>) => {
		setGlobalFilter(value);
		onChangeSearchQuery(value);
	};

	return (
		<div className="flex">
			<div className="p-2">
				<Search24Regular />
			</div>
			<Input
				type="text"
				value={getState().globalFilter || ''}
				onChange={handleChange}
				className="relative w-full rounded-b-none rounded-l-none border-none"
				placeholder={placeholder}
				debounce={200}
			/>
		</div>
	);
};

export default GlobalFilter;

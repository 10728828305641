import React from 'react';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import { MenuContext } from './Menu';

export type MenuButtonProps = {
	withChevron?: boolean;
} & ButtonProps<'button'>;

export const MenuButton: React.FC<MenuButtonProps> = ({
	children,
	withChevron = false,
	...props
}) => {
	const { menu } = React.useContext(MenuContext);
	const trailingIcon = withChevron ? (
		<Icon name={menu?.isOpen ? 'ChevronUp' : 'ChevronDown'} size="sm" aria-hidden />
	) : undefined;
	return (
		<Button trailingIcon={trailingIcon} {...props} {...menu?.buttonProps}>
			{children}
		</Button>
	);
};

import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { deleteLotAdjustment } from '../../../../services/lotsService/deleteLotAdjustment';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast/showToast';

const DeleteLotAdjustmentModal = ({
	adjustmentId,
	description,
	lotId,
	onClose,
}: {
	adjustmentId: string;
	description: string;
	lotId: string;
	onClose: () => void;
}) => {
	const { mutate: doDeleteAdjustment, status: deleteAdjustmentStatus } = useMutation({
		mutationKey: [QueryKeys.DeleteLotAdjustment, adjustmentId, lotId],
		mutationFn: () =>
			deleteLotAdjustment({
				adjustmentId,
				lotId,
			}),
	});

	const { data: lot = {} as KernelLot, updateLot: updateLotInCache } = useIndividualLot(lotId);

	const handleDeleteAdjustment = () =>
		doDeleteAdjustment(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully deleted lot adjustment.');
				console.log('pre', lot.adjustments);
				const updatedAdjustments = lot.adjustments.filter(({ id }) => !!id && id !== adjustmentId);
				updateLotInCache({
					adjustments: updatedAdjustments,
				});
				console.log('post', lot.adjustments);
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong deleting lot adjustment. If the issue persists after refreshing your browser, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			onConfirm={handleDeleteAdjustment}
			onClose={onClose}
			isDangerous={true}
			isLoading={deleteAdjustmentStatus === 'loading'}
			title="Delete Adjustment"
		>
			{`Are you sure you want to delete "${description}"?`}
		</ActionModal>
	);
};

export default DeleteLotAdjustmentModal;

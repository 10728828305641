import React, { InputHTMLAttributes } from 'react';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';
import { Checkbox as PrizmCheckbox } from '../../prizm-ui/Checkbox';

const Checkbox = ({
	allowedRoles = [],
	checked,
	disabled,
	label,
	name,
	onChange,
	...inputProps
}: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> & {
	allowedRoles?: UserRoleName[];
	checked: boolean;
	label: string;
	name: string;
	onChange: (newState: boolean) => void;
}) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	return (
		<PrizmCheckbox
			checked={checked}
			disabled={disabled || isForbidden}
			type="checkbox"
			onChange={() => onChange(!checked)}
			size="md"
			id={name}
			{...inputProps}
		>
			{label}
		</PrizmCheckbox>
	);
};

export default Checkbox;

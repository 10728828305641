import React from 'react';
import { Icon, IconName } from '../Icon';
import { iconList } from '../Icon/icons.shared';
import { PolymorphicComponentProps } from '../types';

export type ChipVariant = 'gray' | 'primary' | 'error' | 'warning' | 'success';

type Props = {
	icon?: IconName | JSX.Element;
	leadingIcon?: IconName | JSX.Element;
	trailingIcon?: IconName | JSX.Element;
	variant?: ChipVariant;
};

type ChipProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

const getClasses = ({
	isIcon,
	onClick,
	variant,
}: {
	isIcon: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	variant: ChipVariant;
}) => {
	const classes = [
		'inline-flex',
		'items-center',
		'justify-center',
		'gap-1',
		'min-w-[24px]',
		'h-6',
		'rounded-full',
		'text-body2',
		...(isIcon ? [] : ['px-2']),
		...(onClick
			? [
					'focus-visible:ring-2',
					'focus-visible:ring-offset-2',
					'focus-visible:ring-primary-500',
					'focus:outline-none',
			  ]
			: []),
	];

	switch (variant) {
		case 'gray':
			if (onClick) classes.push('hover:bg-neutralstroke2');
			classes.push('bg-neutralbkg2', 'text-neutral2');
			break;
		case 'primary':
			if (onClick) classes.push('hover:bg-primary-100');
			classes.push('bg-primary-25', 'text-primary-500');
			break;
		case 'error':
			if (onClick) classes.push('hover:bg-error-100');
			classes.push('bg-error-25', 'text-error-500');
			break;
		case 'warning':
			if (onClick) classes.push('hover:bg-warning-100');
			classes.push('bg-warning-25', 'text-warning-500');
			break;
		case 'success':
			if (onClick) classes.push('hover:bg-success-100');
			classes.push('bg-success-25', 'text-success-500');
			break;
	}

	return classes.join(' ');
};

export const Chip = <C extends React.ElementType = 'div'>({
	'aria-label': ariaLabel,
	as,
	children,
	icon,
	leadingIcon,
	onClick,
	trailingIcon,
	variant = 'gray',
	...props
}: ChipProps<C>) => {
	const Component = as || 'div';
	return (
		<Component
			{...props}
			className={getClasses({ isIcon: !!icon, onClick, variant })}
			onClick={onClick}
		>
			{icon ? (
				typeof icon === 'string' && iconList.includes(icon) ? (
					<Icon name={icon} size={3} aria-hidden={false} aria-label={ariaLabel} />
				) : (
					React.isValidElement(icon) && icon
				)
			) : (
				<>
					{typeof leadingIcon === 'string' && iconList.includes(leadingIcon) ? (
						<span className="flex items-center" data-testid="chip-leading-icon">
							<Icon name={leadingIcon} size={3} aria-hidden />
						</span>
					) : (
						React.isValidElement(leadingIcon) && leadingIcon
					)}
					<span className="whitespace-nowrap">{children}</span>
					{typeof trailingIcon === 'string' && iconList.includes(trailingIcon) ? (
						<span className="flex items-center" data-testid="chip-trailing-icon">
							<Icon name={trailingIcon} size={3} aria-hidden />
						</span>
					) : (
						React.isValidElement(trailingIcon) && trailingIcon
					)}
				</>
			)}
		</Component>
	);
};

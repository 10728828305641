import React, { useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import Papa from 'papaparse';
import { ColumnDef } from '@tanstack/react-table';
import { SortableTableParams } from '../SortableTable/SortableTable.model';
import SortableTable from '../SortableTable';

export interface UploadCSVProps<T> {
	dropzoneText?: string;
	resultsColumns: ColumnDef<T>[];
	onComplete: ({
		data,
		errors,
		uploadedFile,
	}: {
		data: T[];
		errors: unknown[];
		uploadedFile: File;
	}) => void;
}

const UploadCSV = <T extends unknown>({
	dropzoneText = 'Drag and drop a csv/comma-separated txt file here or click here ',
	resultsColumns,
	onComplete,
}: UploadCSVProps<T>) => {
	const [bulkUploadedItems, setBulkUploadedItems] = useState<T[]>([]);
	const [importProgress, setImportProgress] = useState({ itemsImported: 0, totalItemCount: 0 });

	const shouldShowUploadDropzone =
		importProgress.totalItemCount === 0 ||
		(importProgress.itemsImported !== importProgress.totalItemCount &&
			bulkUploadedItems.length > 0);

	const tableParams: SortableTableParams<T> = { columns: resultsColumns, data: bulkUploadedItems };

	return (
		<>
			{shouldShowUploadDropzone ? (
				<div>
					<DropzoneArea
						acceptedFiles={['.csv', '.txt']}
						dropzoneText={dropzoneText}
						dropzoneClass="p-4"
						dropzoneParagraphClass="text-body2"
						filesLimit={1}
						/* 100mb limit */
						maxFileSize={104857600}
						onChange={(files) => {
							if (files.length > 0) {
								const [thisFile] = files;
								Papa.parse(thisFile, {
									header: true,
									delimiter: ',',
									dynamicTyping: true,
									complete: ({ data, errors }: { data: T[]; errors: unknown[] }) => {
										const itemCount = data.length;
										setImportProgress({ itemsImported: itemCount, totalItemCount: itemCount });
										setBulkUploadedItems(data);
										onComplete({ data, errors, uploadedFile: thisFile });
									},
								});
							}
						}}
						showAlerts={false}
						previewGridProps={{
							container: { alignContent: 'center', alignItems: 'center', justify: 'center' },
						}}
						getPreviewIcon={() => <Description />}
						showFileNames={true}
					/>
					<div className="mt-2">
						<LinearProgress
							variant="determinate"
							value={
								importProgress.totalItemCount > 0
									? (importProgress.itemsImported / importProgress.totalItemCount) * 100
									: 1
							}
						/>
						<p className="mt-1 text-body2">
							{`Validating CSV Data: ${
								importProgress.totalItemCount > 0
									? Math.round(
											Math.floor(
												(importProgress.itemsImported / importProgress.totalItemCount) * 100
											)
									  )
									: 0
							}% (${importProgress.itemsImported} of ${
								importProgress.totalItemCount
							} items processed)`}
						</p>
					</div>
				</div>
			) : (
				<div className="max-h-72 max-w-xl overflow-auto">
					<SortableTable tableParams={tableParams} />
				</div>
			)}
		</>
	);
};

export default UploadCSV;

import React from 'react';
import { Icon } from '../Icon';
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from '../types';
import { AvatarGroupContext } from './AvatarGroup';
import { AvatarLabel } from './AvatarLabel';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
	email?: string;
	image?: string | JSX.Element;
	name?: string;
	size?: AvatarSize;
	withLabel?: boolean;
};

export type AvatarProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type AvatarComponent = <C extends React.ElementType = 'div'>(
	props: AvatarProps<C>
) => React.ReactElement | null;

const getAvatarClasses = ({
	isGroup,
	onClick,
	size,
}: {
	isGroup?: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	size: AvatarSize;
}) => {
	const classes = [
		'relative',
		'bg-neutralbkg2',
		'rounded-full',
		'overflow-hidden',
		'transition-all',
		'focus-visible:ring-primary-500',
		'focus-visible:ring-2',
		'focus-visible:ring-offset-2',
		'focus-visible:outline-none',
		...(onClick
			? [
					'hover:ring-primary-500',
					'hover:ring-2',
					'hover:ring-offset-2',
					'hover:outline-none',
					'cursor-pointer',
			  ]
			: []),
		...(isGroup ? ['ring-2', 'ring-inverted'] : []),
	];

	switch (size) {
		case 'xs':
			classes.push('h-6', 'w-6');
			break;
		case 'sm':
			classes.push('h-10', 'w-10');
			break;
		case 'md':
			classes.push('h-16', 'w-16');
			break;
		case 'lg':
			classes.push('h-32', 'w-32');
			break;
		case 'xl':
			classes.push('h-48', 'w-48');
			break;
	}

	return classes.join(' ');
};

const getImageClasses = ({ size }: { size?: AvatarSize }) => {
	const classes = ['object-cover', 'object-center'];

	switch (size) {
		case 'xs':
			classes.push('h-6', 'w-6');
			break;
		case 'sm':
			classes.push('h-10', 'w-10');
			break;
		case 'md':
			classes.push('h-16', 'w-16');
			break;
		case 'lg':
			classes.push('h-32', 'w-32');
			break;
		case 'xl':
			classes.push('h-48', 'w-48');
			break;
	}

	return classes.join(' ');
};

const getContentClasses = ({ name, size }: { name?: string; size?: AvatarSize }) => {
	const classes = ['flex', 'items-center', 'justify-center', 'h-full', 'w-full'];

	if (name) {
		classes.push('uppercase', 'font-bold', 'text-neutral2');

		switch (size) {
			case 'xs':
				classes.push('text-caption2');
				break;
			case 'sm':
				classes.push('text-body1');
				break;
			case 'md':
				classes.push('text-display5');
				break;
			case 'lg':
				classes.push('text-display4');
				break;
			case 'xl':
				classes.push('text-display3');
				break;
		}
	} else {
		classes.push('text-disabled');
	}

	return classes.join(' ');
};

const getAvatarContent = ({
	ariaLabel,
	image,
	name,
	size = 'md',
}: {
	ariaLabel?: string;
	image?: string | JSX.Element;
	name?: string;
	size?: AvatarSize;
}) => {
	if (image) {
		if (React.isValidElement(image)) {
			return image;
		} else if (typeof image === 'string') {
			return <img src={image} alt={ariaLabel || name} className={getImageClasses({ size })} />;
		}
	}

	const contentClasses = getContentClasses({ name, size });

	if (name) {
		const initials = name
			.split(' ')
			.map((n) => n[0])
			.slice(0, 2)
			.join('');

		return (
			<span className={contentClasses} aria-label={ariaLabel || name}>
				{initials}
			</span>
		);
	}

	const iconSize = (
		{
			xs: 4,
			sm: 6,
			md: 8,
			lg: 16,
			xl: 24,
		} as { [_ in AvatarSize]: number }
	)[size];

	return (
		<span className={contentClasses}>
			<Icon
				name="PersonFilled"
				size={iconSize}
				aria-hidden={false}
				aria-label={ariaLabel || name || ''}
			/>
		</span>
	);
};

const getContainerClasses = ({ size }: { size: AvatarSize }) => {
	const classes = ['inline-flex'];

	switch (size) {
		case 'xs':
			break;
		case 'sm':
			classes.push('gap-3');
			break;
		case 'md':
			classes.push('gap-4');
			break;
		case 'lg':
			classes.push('gap-5');
			break;
		case 'xl':
			break;
	}

	return classes.join(' ');
};

export const Avatar: AvatarComponent = React.forwardRef(
	<C extends React.ElementType = 'div'>(
		{
			as,
			'aria-label': ariaLabel,
			email = '',
			image = '',
			name = '',
			onClick,
			size = 'md',
			withLabel = false,
			...props
		}: AvatarProps<C>,
		ref?: PolymorphicRef<C>
	) => {
		const Component = as || 'div';
		const { groupSize, isGroup } = React.useContext(AvatarGroupContext);

		return (
			<>
				{withLabel ? (
					<div className={getContainerClasses({ size })}>
						<Component
							{...props}
							ref={ref}
							onClick={onClick}
							className={getAvatarClasses({
								isGroup,
								onClick,
								size: groupSize || size,
							})}
							data-testid="avatar"
						>
							{getAvatarContent({
								ariaLabel,
								image,
								name,
								size: groupSize || size,
							})}
						</Component>
						<AvatarLabel email={email} name={name} size={groupSize || size} />
					</div>
				) : (
					<Component
						{...props}
						ref={ref}
						onClick={onClick}
						className={getAvatarClasses({
							isGroup,
							onClick,
							size: groupSize || size,
						})}
						data-testid="avatar"
					>
						{getAvatarContent({
							ariaLabel,
							image,
							name,
							size: groupSize || size,
						})}
					</Component>
				)}
			</>
		);
	}
);

Avatar.displayName = 'Avatar';

import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import ActionModal from '../../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { User } from '../../../../../../services/userService/userService.model';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import { deactivateUser } from '../../../../../../services/userService';
import useUserById from '../../../../../../queries/useUserById';

const DeactivateCustomerModal = ({
	onClose,
	onSuccess,
}: {
	onClose: () => void;
	onSuccess: () => void;
}) => {
	const { userId = '' } = useParams<{ userId: string }>();

	const { data: user = {} as User, updateUser: updateUserInCache } = useUserById(userId);

	const { mutate: doDeactivateUser, status: deactivateUserStatus } = useMutation({
		mutationFn: () => deactivateUser(user.id),
		mutationKey: [QueryKeys.DeactivateUser, user.id],
	});

	const handleConfirm = () => {
		doDeactivateUser(undefined, {
			onError: () =>
				showErrorToast(
					'Something went wrong while deactivating the customer. If the issue persists after refresh, please notify the #mvp-cs-channel.'
				),
			onSuccess: () => {
				updateUserInCache({ isDisabled: true });
				onSuccess();
				showSuccessToast('Successfully deactivated customer.');
			},
		});
	};

	return (
		<ActionModal
			confirmButtonLabel="Deactivate"
			isDangerous={true}
			isLoading={deactivateUserStatus === 'loading'}
			title="Deactivate Account"
			onCancel={onClose}
			onClose={onClose}
			onConfirm={handleConfirm}
		>
			<p>
				{`Are you sure you want to deactivate ${user.givenName} ${user.familyName}? You won't be able to reactivate this account without support from the #mvp-cs-channel.`}
			</p>
		</ActionModal>
	);
};

export default DeactivateCustomerModal;

import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { uncompressedBucketConfig } from './storageService.model';

export const uploadImage = async ({
	image,
	bucketPath,
	filePrefix = '',
}: {
	image: File;
	bucketPath: string;
	filePrefix?: string;
}) => {
	const imageNameWithoutExtension = image.name.split('.')[0];
	const imageName = imageNameWithoutExtension.replace(/[^a-zA-Z0-9]/g, '');
	const imageId = `${filePrefix}${imageName}__${uuidv4()}`;
	await Storage.put(bucketPath + imageId, image, uncompressedBucketConfig);
	return imageId;
};

import { Chip } from '../../../prizm-ui/Chip';
import { Tag } from '../../../prizm-ui/Tag';
import { Sentiment } from '../../../prizm-ui/types';
import { LotStatus } from '../../../services/lotsService/lotsService.model';
import { stripUnderscores } from '../../../utils/formatters';

const getSentimentForLotStatus = (status: LotStatus): Sentiment => {
	switch (status) {
		case 'Live':
			return 'success';
		case 'Completed_Sold':
			return 'error';
		case 'Completed_Unsold':
			return 'gray';
		case 'End':
			return 'gray';
		case 'Not_Started':
			return 'gray';
		case 'Pause':
			return 'warning';
		case 'Preview':
			return 'primary';
		case 'Withdrawn':
			return 'gray';
		case 'Extended_Bidding':
			return 'success';
		default:
			return 'gray';
	}
};

const LotStatusChip = ({
	status = 'Not_Started',
	as = 'Chip',
}: {
	status?: LotStatus;
	as?: 'Tag' | 'Chip';
}) => {
	const statusLabel = status.includes('Completed_')
		? status.replace('Completed_', '')
		: stripUnderscores(status);

	const Component = as === 'Chip' ? Chip : Tag;

	return <Component variant={getSentimentForLotStatus(status)}>{statusLabel}</Component>;
};

export default LotStatusChip;

import { Chip } from '../../../prizm-ui/Chip';
import { Tag } from '../../../prizm-ui/Tag';
import { Sentiment } from '../../../prizm-ui/types';
import { ShippingStatus } from '../../../services/invoicesService/invoicesService.model';

const getSentimentForLotStatus = (status: ShippingStatus): Sentiment => {
	switch (status) {
		case 'Ready':
			return 'primary';
		case 'Shipped':
			return 'success';
		case 'Unpicked':
			return 'warning';
	}
};

const ShippingStatusChip = ({
	status = 'Ready',
	as = 'Chip',
}: {
	status?: ShippingStatus;
	as?: 'Tag' | 'Chip';
}) => {
	const Component = as === 'Chip' ? Chip : Tag;

	return <Component variant={getSentimentForLotStatus(status)}>{status}</Component>;
};

export default ShippingStatusChip;

import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import Layout from '../../components/Layout';
import FixedPriceItemPage from './listings/FixedPriceItemPage';
import FixedPriceOrderSummaryPage from './order-summary';
import SearchFixedPriceListings from './search/SearchFixedPriceListings';
import FixedPriceSellerSummaryPage from './seller-summary';

const FixedPriceRoot = () => {
	const { path: basePath } = useRouteMatch();

	return (
		<Layout>
			<Route path={`${basePath}/listings/:itemId`}>
				<FixedPriceItemPage />
			</Route>
			<Route path={`${basePath}/search/:query`}>
				<SearchFixedPriceListings />
			</Route>
			<Route exact path={`${basePath}/search`}>
				<SearchFixedPriceListings />
			</Route>
			<Route exact path={`${basePath}/order-summary`}>
				<FixedPriceOrderSummaryPage />
			</Route>
			<Route exact path={`${basePath}/seller-summary`}>
				<FixedPriceSellerSummaryPage />
			</Route>
		</Layout>
	);
};

export default FixedPriceRoot;

import { useMutation } from 'react-query';
import { Auction } from '../../services/auctionsService/auctionsService.model';
import { KernelLot } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';
import useAuctions from '../useAuctions';
import useLotsForAuction from '../useLotsForAuction';
import { bulkUpdateLots } from '../../services/lotsService';
import { showSuccessToast, showErrorToast } from '../../components/Toast';
import { noop } from 'lodash';
import { bulkReassignLotsToAuction } from '../../services/lotsService/bulkReassignLotsToAuction';

const useBulkLotReassignmentMutation = ({
	lotIds,
	originalAuctionId,
	destinationAuctionId,
	onSuccess = noop,
}: {
	lotIds: string[];
	originalAuctionId: string;
	destinationAuctionId: string;
	onSuccess?: () => void;
}) => {
	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();
	const originalAuction = auctions.find(({ id }) => id === originalAuctionId);

	const destinationAuction = auctions.find(({ id }) => id === destinationAuctionId);
	const inheritableAuctionAttributes: Partial<KernelLot> = {
		start_timestamp: destinationAuction?.startTimestamp,
		end_timestamp: destinationAuction?.endTimestamp,
		buyer_premium: destinationAuction?.buyerPremium,
		extended_bidding_duration: destinationAuction?.extendedBiddingDuration,
	};

	const {
		data: lotsForOriginalAuction = [] as KernelLot[],
		status: lotsForOriginalAuctionStatus,
		removeLotsFromCache: removeLotsFromOriginalAuctionCache,
	} = useLotsForAuction({ auctionId: originalAuctionId });

	const lotItems = lotsForOriginalAuction.filter(({ lot_id }) => lotIds.includes(lot_id));
	const updatedLotItems = lotItems.map((lotItem) => ({
		lot_id: lotItem.lot_id,
		auction_id: destinationAuctionId,
		status: destinationAuction?.status === 'Preview' ? 'Preview' : 'Not_Started',
		...inheritableAuctionAttributes,
	})) as KernelLot[];

	const {
		status: lotsForDestinationAuctionStatus,
		addLotsToCache: addLotsToDestinationAuctionCache,
	} = useLotsForAuction({ auctionId: destinationAuctionId });

	const { mutate: doUpdateLots, status: updateLotsStatus } = useMutation({
		mutationKey: [QueryKeys.BulkUpdateLots, ...lotIds, originalAuctionId, destinationAuctionId],
		mutationFn: () => bulkReassignLotsToAuction({ lotIds, destinationAuctionId }),
	});

	const lotWord = lotIds.length === 1 ? 'lot' : 'lots';
	const lotPhrase = `${lotIds.length} ${lotWord}`;
	const auctionTransferPhrase = `from "${originalAuction?.title || 'Unassigned'}" to "${
		destinationAuction?.title || 'Unassigned'
	}"`;

	const handleUpdateLots = () =>
		doUpdateLots(undefined, {
			onSuccess: () => {
				removeLotsFromOriginalAuctionCache(lotIds);
				addLotsToDestinationAuctionCache(updatedLotItems);
				showSuccessToast(`Successfully reassigned ${lotPhrase} ${auctionTransferPhrase}.`);
				onSuccess();
			},
			onError: () => {
				showErrorToast(
					`Something went wrong reassigning ${lotPhrase} ${auctionTransferPhrase}. Please try again later.`
				);
			},
		});

	const isReady = [
		auctionsStatus,
		lotsForOriginalAuctionStatus,
		lotsForDestinationAuctionStatus,
	].every((queryStatus) => queryStatus === 'success');

	return { isReady, mutate: handleUpdateLots, status: updateLotsStatus };
};

export default useBulkLotReassignmentMutation;

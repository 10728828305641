import { useState } from 'react';
import { Button } from '../../prizm-ui/Button';
import { AuctionStatus } from '../../services/auctionsService';
import AuctionPickerModal from './AuctionPickerModal';

const NewAuctionPicker = ({
	allowedAuctionStatuses = [],
	isDisabled = false,
	selectedAuctionId,
	setSelectedAuctionId,
	shouldShowUnassignedLots = false,
	testIdentifier,
}: {
	allowedAuctionStatuses?: AuctionStatus[];
	isDisabled?: boolean;
	selectedAuctionId: string;
	setSelectedAuctionId: (auctionId: string) => void;
	shouldShowUnassignedLots?: boolean;
	testIdentifier?: string;
}) => {
	const [shouldShowAuctionPickerModal, setShouldShowAuctionPickerModal] = useState(false);
	return (
		<>
			<Button
				trailingIcon="Options"
				onClick={() => setShouldShowAuctionPickerModal(true)}
				variant="brand"
				disabled={isDisabled}
				size="md"
				data-testid={testIdentifier}
			>
				Select Auction
			</Button>
			{shouldShowAuctionPickerModal && (
				<AuctionPickerModal
					allowedAuctionStatuses={allowedAuctionStatuses}
					onClose={() => setShouldShowAuctionPickerModal(false)}
					selectedAuctionId={selectedAuctionId}
					setSelectedAuctionId={setSelectedAuctionId}
					shouldShowUnassignedLots={shouldShowUnassignedLots}
				/>
			)}
		</>
	);
};

export default NewAuctionPicker;

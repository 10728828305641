import { SortableColumnStateItem, SortByColumnAction } from './useSortByColumns.model';

export const sortAscending = <T>(columnName: keyof T): SortByColumnAction<T> => ({
	type: 'SORT_ASCENDING',
	columnName,
});
export const sortDescending = <T>(columnName: keyof T): SortByColumnAction<T> => ({
	type: 'SORT_DESCENDING',
	columnName,
});
export const removeSort = <T>(columnName: keyof T): SortByColumnAction<T> => ({
	type: 'REMOVE_SORT',
	columnName,
});

export const removeColumnConfig = <T>({
	columnName,
	columnConfigs,
}: {
	columnName: keyof T;
	columnConfigs: SortableColumnStateItem<T>[];
}): SortableColumnStateItem<T>[] => {
	return columnConfigs.filter((thisColumnConfig: SortableColumnStateItem<T>) => {
		return columnName !== thisColumnConfig[0];
	});
};

const createSortByColumnReducer =
	<T>() =>
	(
		state: SortableColumnStateItem<T>[],
		action: SortByColumnAction<T>
	): SortableColumnStateItem<T>[] => {
		switch (action.type) {
			case 'SORT_ASCENDING':
				return [
					...removeColumnConfig<T>({ columnConfigs: state, columnName: action.columnName }),
					[action.columnName, 'ascending'],
				];
			case 'SORT_DESCENDING':
				return [
					...removeColumnConfig<T>({ columnConfigs: state, columnName: action.columnName }),
					[action.columnName, 'descending'],
				];
			case 'REMOVE_SORT':
				return removeColumnConfig<T>({ columnConfigs: state, columnName: action.columnName });
			default:
				return state;
		}
	};

export default createSortByColumnReducer;

import { axiosAuth, axiosUnauthed } from '../../AdminUtils/httpUtils';
import config from '../../config';

const getLotAdjustmentsBulkUploadS3Url = async () => {
	const response = await axiosAuth.post(`${config.api.lots_bulk_adjustments_upload_url}`);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

/** @description Uploads bulk imported lot adjustments to the S3 */
export const bulkUploadLotAdjustments = async ({
	bulkImportFile,
	employeeId,
}: {
	bulkImportFile: File;
	employeeId: string;
}) => {
	const signedUrl = await getLotAdjustmentsBulkUploadS3Url();
	const baseHeaders = {
		'Content-Type': 'text/csv',
		// Do not include authorization here
	};
	const headers = {
		...baseHeaders,
		'x-amz-meta-user-id': employeeId,
	};

	const response = await axiosUnauthed.put(signedUrl, bulkImportFile, { headers });

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelRateCard, RateCardVariant } from './rateCardService.model';

export const getRateCardsForUser = async ({
	userId,
	variant,
}: {
	userId: string;
	variant: RateCardVariant;
}) => {
	const response = await axiosAuth.get<KernelRateCard[]>(`${apiEndpoints.rate_cards}/${userId}`);

	if (response.status >= 400) {
		throw response;
	}

	const rateCards =
		variant === 'auction'
			? response.data.filter(({ auctionType }) => auctionType === 'auction')
			: response.data.filter(({ auctionType }) => auctionType === 'fixed_price_marketplace');

	const [firstMatchingRateCard] = rateCards;

	return firstMatchingRateCard;
};

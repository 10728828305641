import { useField } from 'formik';
import React from 'react';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import Input from '../../../../../Input';
import { PlaceBidFormInputProps } from '../PlaceBidForm.model';

const MaxBidInput = ({ minimumViableBid, form, ...formikProps }: PlaceBidFormInputProps) => {
	const [field, _, helpers] = useField({ ...formikProps, name: 'maxBid' });
	const { setValue } = helpers;
	const { value: maxBid } = field;

	const {
		values: { selectedUserOption },
	} = form;
	const canBid = selectedUserOption && selectedUserOption.value.biddingPower > minimumViableBid;

	return (
		<>
			<label htmlFor="maxBid" className="mb-2 block">
				Maximum Bid
			</label>
			<Input
				value={maxBid}
				type="number"
				name="maxBid"
				onChange={({ target: { value: newMaxBid } }) => setValue(newMaxBid)}
				disabled={!canBid}
			/>
			<div className="mt-1">
				<span className="text-xs italic">Minimum Bid: {formatCurrency(minimumViableBid)}</span>
			</div>
		</>
	);
};

export default MaxBidInput;

import { useQuery } from 'react-query';
import { getPendingCashAccountEntries } from '../../services/cashAccountsService';
import { QueryKeys } from '../queryKeys';

const usePendingCashAccountEntries = (userId: string) =>
	useQuery([QueryKeys.GetPendingCashAccountEntries, userId], () =>
		getPendingCashAccountEntries(userId)
	);

export default usePendingCashAccountEntries;

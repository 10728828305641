import { VerticalAlignmentValue } from '../SortableTable.model';
import TableBody from './TableBody';
import TableHead from './TableHead';

const DefaultTable = ({ verticalAlignment }: { verticalAlignment: VerticalAlignmentValue }) => {
	return (
		<div className="w-full overflow-x-auto">
			<table className="w-full table-auto bg-neutral1">
				<TableHead />
				<TableBody verticalAlignment={verticalAlignment} />
			</table>
		</div>
	);
};

export default DefaultTable;

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../../queries/queryKeys';
import ActionModal from '../../../../components/ActionModal';
import UploadCSV from '../../../../components/UploadCSV/UploadCSV';
import useCurrentUser from '../../../../queries/useCurrentUser';
import { CurrentUser } from '../../../../services/userService/userService.model';
import { bulkUploadStripePayouts } from '../../../../services/BulkPayoutsService';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { UploadCSVProps } from '../../../../components/UploadCSV/UploadCSV';
import { BulkPayoutsCSV } from '../../../../services/BulkPayoutsService/bulkPayoutsService.model';
import formatCurrency from '../../../../utils/formatters/formatCurrency';

const BulkUploadPayoutsModal = ({ onClose }: { onClose: () => void }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const [bulkUploadPayoutsFile, setBulkUploadPayoutsFile] = useState<File | null>(null);
	const [bulkUploadPayoutsData, setBulkUploadPayoutsData] = useState<BulkPayoutsCSV[]>([]);
	const [uploadErrors, setUploadErrors] = useState<unknown[]>([]);

	const { mutate: bulkUploadPayoutsMutation, status: bulkUploadPayoutsStatus } = useMutation({
		mutationKey: [QueryKeys.BulkUploadPayouts, bulkUploadPayoutsFile],
		mutationFn: (bulkUploadPayoutsFile: File) =>
			bulkUploadStripePayouts({
				bulkPayoutsFile: bulkUploadPayoutsFile,
				employeeId: currentUser.currentUserId,
			}),
	});

	const bulkStripePayoutsHandler = () => {
		if (bulkUploadPayoutsFile) {
			bulkUploadPayoutsMutation(bulkUploadPayoutsFile, {
				onSuccess: () => {
					showSuccessToast("Payouts are processing. You'll receive an email when complete.");
					onClose();
				},
				onError: () => {
					showErrorToast(
						'Something went wrong submitting payouts for processing. Please try again later.'
					);
				},
			});
		}
	};

	const uploadCSVProps: UploadCSVProps<BulkPayoutsCSV> = {
		resultsColumns: [
			{ accessorKey: 'seller_email', header: 'Seller Email' },
			{
				accessorKey: 'amount',
				header: 'Amount',
				cell: ({ getValue }) => (
					<div className="text-right">{formatCurrency(getValue() as number)}</div>
				),
			},
			{ accessorKey: 'description', header: 'Description' },
		],
		onComplete: ({ data, errors, uploadedFile }) => {
			setBulkUploadPayoutsFile(uploadedFile);
			setBulkUploadPayoutsData(data);
			setUploadErrors(errors);
		},
	};

	const canConfirm =
		!!bulkUploadPayoutsFile &&
		bulkUploadPayoutsData.length > 0 &&
		uploadErrors.length === 0 &&
		bulkUploadPayoutsStatus !== 'loading';

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={bulkStripePayoutsHandler}
			isLoading={bulkUploadPayoutsStatus === 'loading'}
			canConfirm={canConfirm}
			title="Bulk Upload Stripe Payouts"
		>
			<UploadCSV
				onComplete={uploadCSVProps.onComplete}
				resultsColumns={uploadCSVProps.resultsColumns}
			/>
		</ActionModal>
	);
};

export default BulkUploadPayoutsModal;

import React, { useEffect, useState } from 'react';
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
	DragStartEvent,
	DragOverlay,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { getFilenameForLegacyImage } from '../../../AdminUtils/imageUtils.helpers';
import { deleteImage, getCloudFrontImageUrl } from '../../../services/storageService';
import ImageUploadDropzone from '../../ImageUploadDropzone';
import LotImageThumbnail from './LotImageThumbnail';
import { Button } from '../../../prizm-ui/Button';
import Lightbox from 'react-image-lightbox';
import useLotImagesReducer from './useLotImagesReducer';
import { difference, first, without } from 'lodash';
import { getStoredItemPath } from '../../../services/storageService/storageService.utils';
import { useMutation } from 'react-query';
import { QueryKeys } from '../../../queries/queryKeys';
import { toast } from 'react-toastify';
import Toast, { showErrorToast } from '../../Toast';
import { updateLot } from '../../../services/lotsService/updateLot';

const ManageLotImages = ({
	hasPersistedImageArray = false,
	imageIdentifiers = [],
	legacyPrimaryImageName = '',
	lotId,
}: {
	/** In legacy code, the \_\_PRIMARY\_\_ image was saved as a separate key. The refactored code relies on position
	 * in the imageIdentifiers array to determine which image is primary (ie, first image).
	 */
	legacyPrimaryImageName?: string;
	/** Legacy "identifiers" will be an array of URLs, whereas non-legacy will be an array of simple filenames.
	 * This is not to be mistaken with any kind of proper "id" and hence the non-abbreviated spelling.
	 */
	imageIdentifiers?: string[];
	lotId: string;
	hasPersistedImageArray?: boolean;
}) => {
	const incomingPersistedImageIdentifiers = hasPersistedImageArray
		? imageIdentifiers
		: [legacyPrimaryImageName, ...imageIdentifiers];
	const backwardCompatiblePersistedImageNames = incomingPersistedImageIdentifiers
		.filter((imageIdentifier) => !!imageIdentifier)
		.map((imageIdentifier: string) => getFilenameForLegacyImage({ imageIdentifier }));

	const [
		{
			currentPrimaryImageName,
			newImageNames,
			deletedImageNames,
			hasUnsavedChanges,
			orderedImageNames,
		},
		dispatch,
	] = useLotImagesReducer({
		primaryImageName: backwardCompatiblePersistedImageNames.filter(
			(imageName) => !imageName.includes('LOA_')
		)[0],
		persistedImageNames: backwardCompatiblePersistedImageNames,
	});

	const lightboxImages = orderedImageNames.map((imageName: string) =>
		getCloudFrontImageUrl({
			parentPath: lotId,
			itemId: imageName,
			imageSize: '@4x',
			rootPath: 'Lots',
		})
	);

	const addNewImageName = (imageName: string) =>
		dispatch({ type: 'ADD_IMAGE', payload: imageName });

	const handleDelete = (imageName: string) => {
		if (deletedImageNames.includes(imageName)) {
			return dispatch({ type: 'UNDELETE_IMAGE', payload: imageName });
		}

		return dispatch({ type: 'DELETE_IMAGE', payload: imageName });
	};

	const [shouldShowLightbox, setShouldShowLightbox] = useState(false);
	const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
	const openLightBox = (imageIndex: number) => {
		setCurrentLightboxIndex(imageIndex);
		setShouldShowLightbox(true);
	};

	const onSaveChanges = async () => {
		if (deletedImageNames.length > 0) {
			await Promise.all(
				deletedImageNames.map(async (imageName) => {
					const imageURI = getStoredItemPath({
						rootPath: 'Lots',
						parentPath: lotId,
						itemId: imageName,
					});
					await deleteImage(imageURI);
					return imageName;
				})
			);
		}

		// Non-LOA images should come first in array, followed by LOA images
		const allRemainingImages = difference(orderedImageNames, deletedImageNames)
			.filter((imageName) => imageName && !imageName.includes('LOA_'))
			.concat(
				difference(orderedImageNames, deletedImageNames).filter(
					(imageName) => imageName && imageName.includes('LOA_')
				)
			);

		const allRemainingImagesWithCorrectPrimaryImage =
			currentPrimaryImageName && legacyPrimaryImageName !== currentPrimaryImageName
				? [currentPrimaryImageName, ...without(allRemainingImages, currentPrimaryImageName)]
				: allRemainingImages;

		await updateLot({
			lot_id: lotId,
			images: allRemainingImagesWithCorrectPrimaryImage,
			primary_image_name:
				first(
					allRemainingImagesWithCorrectPrimaryImage.filter(
						(imageName) => imageName && !imageName.includes('LOA_')
					)
				) || '',
			thumbnail_image_name:
				first(
					allRemainingImagesWithCorrectPrimaryImage.filter(
						(imageName) => imageName && !imageName.includes('LOA_')
					)
				) || '',
		});
	};

	const { mutate: doSaveChanges, status: saveChangesStatus } = useMutation({
		mutationKey: [QueryKeys.SaveLotImages, lotId, ...newImageNames, ...deletedImageNames],
		mutationFn: () => onSaveChanges(),
	});
	const handleSave = () =>
		doSaveChanges(undefined, {
			onSuccess: () => {
				dispatch({ type: 'SAVE_CHANGES' });
				toast(<Toast sentiment="success" message="Successfully updated lot images" />);
			},
			onError: () => {
				toast(
					<Toast
						sentiment="warning"
						message="Something went wrong updating lot images. Please try again later."
					/>
				);
			},
		});

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	// This indicates the thumbnail that the user is actively dragging to reorder images
	const [activeImageName, setActiveImageName] = useState<string | null>(null);
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;

		if (active) {
			setActiveImageName(active.id);
		}
	};
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!!over && active.id !== over.id) {
			const oldIndex = orderedImageNames.indexOf(active.id);
			const newIndex = orderedImageNames.indexOf(over.id);

			const updatedOrderedImageNames = arrayMove(orderedImageNames, oldIndex, newIndex);
			dispatch({
				type: 'CHANGE_IMAGE_ORDER',
				payload: updatedOrderedImageNames,
			});
		}
		setActiveImageName(null);
	};

	const productImages = orderedImageNames.filter((imageName) => !imageName.includes('LOA_'));
	const countOfProductImages = backwardCompatiblePersistedImageNames.filter(
		(imageName) => !imageName.includes('LOA_')
	).length;

	const lettersOfAuthenticity = orderedImageNames.filter((imageName) => imageName.includes('LOA_'));
	const countOfLettersOfAuthenticity = backwardCompatiblePersistedImageNames.filter((imageName) =>
		imageName.includes('LOA_')
	).length;

	const [hasShownDiscrepancyErrorToast, setHasShownDiscrepancyErrorToast] = useState(false);
	useEffect(() => {
		const hasCountOfProductImagesDiscrepancy =
			productImages.length === 0 && countOfProductImages > 0;
		const hasCountOfLettersOfAuthenticityDiscrepancy =
			lettersOfAuthenticity.length === 0 && countOfLettersOfAuthenticity > 0;
		const hasCountOfImagesDiscrepancy =
			hasCountOfProductImagesDiscrepancy || hasCountOfLettersOfAuthenticityDiscrepancy;

		if (hasCountOfImagesDiscrepancy && !hasShownDiscrepancyErrorToast) {
			showErrorToast('Something went wrong loading images. Please refresh your browser');
			setHasShownDiscrepancyErrorToast(true);
		}
	}, [
		productImages.length,
		countOfProductImages,
		lettersOfAuthenticity,
		countOfLettersOfAuthenticity,
	]);

	return (
		<>
			<div className="w-full">
				<h3 className="text-subtitle1 mb-4">{`Product Images (${countOfProductImages})`}</h3>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
				>
					<ul className="grid list-none grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
						<li>
							<ImageUploadDropzone
								key={newImageNames.length}
								onSuccess={addNewImageName}
								maxImages={100}
								bucketPath={getStoredItemPath({ rootPath: 'Lots', parentPath: lotId })}
							/>
						</li>
						<SortableContext items={orderedImageNames}>
							{productImages.map((imageName, index) => (
								<LotImageThumbnail
									lotId={lotId}
									id={imageName}
									imageName={imageName}
									key={`${lotId}-${activeImageName}-${index}}`}
									handleDelete={handleDelete}
									isStagedForDeletion={deletedImageNames.includes(imageName)}
									onClickImage={() => openLightBox(orderedImageNames.indexOf(imageName))}
									activeImageName={activeImageName || undefined}
								/>
							))}
						</SortableContext>
						<DragOverlay>
							{activeImageName && (
								<LotImageThumbnail
									lotId={lotId}
									id={activeImageName}
									imageName={activeImageName}
									key={`${lotId}-${activeImageName}`}
									handleDelete={handleDelete}
									isStagedForDeletion={deletedImageNames.includes(activeImageName)}
									onClickImage={() => openLightBox(orderedImageNames.indexOf(activeImageName))}
								/>
							)}
						</DragOverlay>
					</ul>
				</DndContext>
				<h3 className="text-subtitle1 mb-4 mt-8">{`Letters of Authenticity (${countOfLettersOfAuthenticity})`}</h3>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
				>
					<ul className="grid list-none grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
						<li>
							<ImageUploadDropzone
								key={newImageNames.length}
								onSuccess={addNewImageName}
								maxImages={100}
								bucketPath={getStoredItemPath({ rootPath: 'Lots', parentPath: lotId })}
								filePrefix="LOA_"
							/>
						</li>
						<SortableContext items={orderedImageNames}>
							{lettersOfAuthenticity.map((imageName, index) => (
								<LotImageThumbnail
									lotId={lotId}
									id={imageName}
									imageName={imageName}
									key={`${lotId}-${activeImageName}-${index}}`}
									handleDelete={handleDelete}
									isStagedForDeletion={deletedImageNames.includes(imageName)}
									onClickImage={() => openLightBox(orderedImageNames.indexOf(imageName))}
									activeImageName={activeImageName || undefined}
								/>
							))}
						</SortableContext>
						<DragOverlay>
							{activeImageName && (
								<LotImageThumbnail
									lotId={lotId}
									id={activeImageName}
									imageName={activeImageName}
									key={`${lotId}-${activeImageName}`}
									handleDelete={handleDelete}
									isStagedForDeletion={deletedImageNames.includes(activeImageName)}
									onClickImage={() => openLightBox(orderedImageNames.indexOf(activeImageName))}
								/>
							)}
						</DragOverlay>
					</ul>
				</DndContext>
			</div>
			<div className="my-8">
				<div className="flex">
					<Button
						disabled={!hasUnsavedChanges}
						size="md"
						variant="brand"
						loading={saveChangesStatus === 'loading'}
						onClick={handleSave}
					>
						Save Changes
					</Button>
					<Button
						className="ml-2"
						disabled={!hasUnsavedChanges}
						size="md"
						variant="secondary"
						onClick={() => dispatch({ type: 'DISCARD_CHANGES' })}
					>
						Cancel
					</Button>
				</div>
			</div>
			{shouldShowLightbox && (
				/** @ts-ignore */
				<Lightbox
					mainSrc={lightboxImages[currentLightboxIndex]}
					nextSrc={lightboxImages[(currentLightboxIndex + 1) % lightboxImages.length]}
					prevSrc={
						lightboxImages[
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						]
					}
					onCloseRequest={() => setShouldShowLightbox(false)}
					onMovePrevRequest={() =>
						setCurrentLightboxIndex(
							(currentLightboxIndex + lightboxImages.length - 1) % lightboxImages.length
						)
					}
					onMoveNextRequest={() =>
						setCurrentLightboxIndex((currentLightboxIndex + 1) % lightboxImages.length)
					}
				/>
			)}
		</>
	);
};

export default ManageLotImages;

import { ColumnDef } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import Pagination from '../../../../components/SortableTable/Pagination';
import useReactTable from '../../../../components/SortableTable/useReactTable';
import { TableContextProvider } from '../../../../components/SortableTable/useTableContext';
import useListOfLots from '../../../../queries/useListOfLots';
import {
	FacetSearchQuery,
	KernelSummaryLot,
	LotsQueryType,
} from '../../../../services/lotsService/lotsService.model';
import { getQueryParamsObject } from '../../../../utils/formatters';
import LotPreviewCard from './LotPreviewCard';

const AuctionPreviewCards = ({
	auctionId,
	facetSearchQuery,
	sortBy,
	searchTerm,
}: {
	auctionId: string;
	facetSearchQuery: FacetSearchQuery;
	sortBy: LotsQueryType;
	searchTerm: string;
}) => {
	const [safeFacetSearchQuery, setSafeFacetSearchQuery] = useState(facetSearchQuery);
	const [safeSortBy, setSafeSortBy] = useState<LotsQueryType>(sortBy);
	const history = useHistory();
	const { search } = useLocation();

	useEffect(() => {
		if (!isEqual(safeFacetSearchQuery, facetSearchQuery)) {
			setSafeFacetSearchQuery(facetSearchQuery);
		}
		if (!isEqual(safeSortBy, sortBy)) {
			setSafeSortBy(sortBy);
		}
	}, [facetSearchQuery, sortBy]);

	const { data: response = { lots: [], total: 0 }, status: listOfLotsStatus } = useListOfLots({
		params: { auctionId, kind: 'assigned', queryType: safeSortBy },
		facetSearchQuery: safeFacetSearchQuery,
		include: [
			'consignment_manager_email',
			'current_price',
			'meta_slug',
			'min_bid_price',
			'seller_email',
		],
	});

	const lotPreviewCardColumns: ColumnDef<KernelSummaryLot>[] = [
		{
			accessorFn: ({ lot_number }) => (lot_number ?? '').toString(),
			accessorKey: 'lot_number',
		},
		{
			accessorKey: 'title',
		},
		{
			accessorKey: 'serial_number',
		},
		{
			accessorKey: 'seller_email',
		},
		{
			accessorKey: 'consignment_manager_email',
		},
	];

	const { pageIndex: pageIndexFromUrl, pageSize: pageSizeFromUrl } = getQueryParamsObject<{
		pageIndex?: string;
		pageSize?: string;
	}>(search);
	const pageIndex = pageIndexFromUrl ?? 0;
	const pageSize = pageSizeFromUrl ?? 100;
	const lotsTable = useReactTable<KernelSummaryLot>({
		columns: lotPreviewCardColumns,
		data: response.lots ?? [],
		state: { globalFilter: searchTerm ?? '' },
		initialState: {
			pagination: { pageIndex: Number(pageIndex), pageSize: Number(pageSize) },
		},
	});
	const { rows: previewLots } = lotsTable.getRowModel();
	const currentPageIndex = lotsTable.getState().pagination.pageIndex;
	const currentPageSize = lotsTable.getState().pagination.pageSize;

	useEffect(() => {
		if (Number(pageIndex) !== currentPageIndex || Number(pageSize) !== currentPageSize) {
			history.push(
				`/auctions/${auctionId}/preview?pageIndex=${currentPageIndex}&pageSize=${currentPageSize}`
			);
		}
	}, [currentPageIndex, currentPageSize]);

	return (
		<div className="flex-grow lg:min-w-80 xl:min-w-120">
			<LoadingWrapper queryStatuses={[listOfLotsStatus]}>
				<h3 className="text-display5 mb-4">Lots</h3>
				{previewLots.length === 0 ? (
					<div className="text-subtitle1 w-full">No lots found.</div>
				) : (
					<TableContextProvider value={lotsTable}>
						<div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
							{previewLots.map(({ original: lot }) => (
								<LotPreviewCard lot={lot} key={lot.lot_id} />
							))}
						</div>
						<div className="mt-8">
							<Pagination />
						</div>
					</TableContextProvider>
				)}
			</LoadingWrapper>
		</div>
	);
};

export default AuctionPreviewCards;

import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { ArrowSortDown20Regular, ArrowSortUp20Regular } from '@fluentui/react-icons';
import { useTable } from '../../useTableContext';

const TableHead = () => {
	const { getHeaderGroups } = useTable();

	return (
		<thead className="border-b-base">
			<tr>
				{getHeaderGroups().map((headerGroup) =>
					headerGroup.headers.map((header) => (
						<th key={header.id} colSpan={header.colSpan} className="whitespace-nowrap px-2 py-3">
							{header.isPlaceholder ? null : (
								<>
									<div
										{...{
											className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
											onClick: header.column.getToggleSortingHandler(),
										}}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
										{{
											asc: <ArrowSortUp20Regular />,
											desc: <ArrowSortDown20Regular />,
										}[header.column.getIsSorted() as string] ?? null}
									</div>
								</>
							)}
						</th>
					))
				)}
			</tr>
		</thead>
	);
};

export default TableHead;

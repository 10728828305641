import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { omit } from 'lodash';
import ActionModal from '../../../../../../../components/ActionModal';
import Checkbox from '../../../../../../../components/Checkbox';
import Dropdown from '../../../../../../../components/Dropdown';
import { SimpleDropdownMenuItem } from '../../../../../../../components/Dropdown/Dropdown.model';
import Input from '../../../../../../../components/Input';
import Label from '../../../../../../../components/Label';
import RadioButton from '../../../../../../../components/RadioButton';
import { showErrorToast, showSuccessToast } from '../../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../../queries/queryKeys';
import {
	Adjustment,
	CheckoutOrder,
	CheckoutOrderComment,
	FreeformAdjustment,
	LotAdjustment,
	StructuredFeeAdjustment,
} from '../../../../../../../services/ordersService/ordersService.model';
import { KernelLot } from '../../../../../../../services/lotsService/lotsService.model';
import { CurrentUser } from '../../../../../../../services/userService/userService.model';
import useDecision from '../../../../../../../optimizely/useDecision';
import useCurrentUser from '../../../../../../../queries/useCurrentUser';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber';
import { updateOrderAdjustments } from '../../../../../../../services/ordersService/updateOrderAdjustments';
import { updateInvoiceComments } from '../../../../../../../services/invoicesService';
import formatCurrency from '../../../../../../../utils/formatters/formatCurrency';
import { titleCase } from '../../../../../../../utils/formatters';

export enum AdjustmentTypeEnum {
	Lot = 'lot',
	Advance = 'advance',
	Offset = 'offset',
	Balance = 'balance',
	Credits = 'credits',
	PartialPayment = 'partial_payment',
	Other = 'other',
}

type AdjustmentType = `${AdjustmentTypeEnum}`;

const ADJUSTMENT_TYPE_OPTIONS = Object.values(AdjustmentTypeEnum);

const adjustmentDescriptions: { [key in AdjustmentType]: string } = {
	advance: 'advance',
	balance: 'balance adjustment',
	credits: 'credits',
	lot: 'lot adjustment',
	offset: 'offset',
	other: 'miscellaneous adjustment',
	partial_payment: 'partial payment',
};

const AddAdjustmentModal = ({
	onClose,
	lotItems,
	order,
	orderTotal,
	isMissingSubtotal,
}: {
	onClose: () => void;
	lotItems: KernelLot[];
	order: CheckoutOrder;
	orderTotal: number;
	isMissingSubtotal: boolean;
}) => {
	const featureBalanceEnabled = useDecision('balance');

	const baseAdjustment = {
		description: '',
		amount: 0,
		taxable: true,
	};
	const blankAdjustment: Adjustment = {
		category: 'lot',
		isNew: true,
		lotId: lotItems[0].lot_id,
		...baseAdjustment,
	};
	const [newAdjustment, setNewAdjustment] = useState<Adjustment>(blankAdjustment);
	const updateNewAdjustment = (fieldsToUpdate: Partial<Adjustment>) => {
		if (
			newAdjustment.category === 'lot' &&
			fieldsToUpdate.category &&
			fieldsToUpdate.category !== 'lot'
		) {
			const updatedAdjustment = { ...newAdjustment, ...fieldsToUpdate } as LotAdjustment;
			return setNewAdjustment(omit(updatedAdjustment, 'lotId') as Adjustment);
		}

		if (
			newAdjustment.category !== 'lot' &&
			fieldsToUpdate.category === 'lot' &&
			!fieldsToUpdate.lotId
		) {
			return setNewAdjustment({
				...newAdjustment,
				...fieldsToUpdate,
				lotId: lotItems[0].lot_id,
			} as LotAdjustment);
		}

		return setNewAdjustment({
			...newAdjustment,
			...fieldsToUpdate,
		} as FreeformAdjustment);
	};

	const lotOptions: SimpleDropdownMenuItem[] = lotItems.map((lot) => ({
		value: lot.lot_id,
		label: `${lot.title} (Serial Number ${lot.serial_number} - Lot ${lot.lot_number})`,
	}));

	const { mutate: doUpdateAdjustments, status: updateAdjustmentsStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderAdjustments, ...Object.entries(newAdjustment).flat()],
		mutationFn: ({
			orderNumber,
			updatedAdjustments,
		}: {
			orderNumber: string;
			updatedAdjustments: (StructuredFeeAdjustment | FreeformAdjustment | LotAdjustment)[];
		}) => updateOrderAdjustments({ orderNumber, updatedAdjustments }),
	});

	const { refetch: refetchOrder } = useOrderByNumber({
		orderNumber: order.invoiceNumber,
		enabled: !!order.invoiceNumber,
	});

	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const handleSave = () => {
		if (
			newAdjustment.category === AdjustmentTypeEnum.Balance ||
			newAdjustment.category === AdjustmentTypeEnum.Credits ||
			newAdjustment.category === AdjustmentTypeEnum.PartialPayment
		) {
			if (newAdjustment.category === AdjustmentTypeEnum.Balance && balanceAdjustmentFound) {
				showErrorToast('Orders cannot have multiple balance adjustments.');

				return;
			}

			const adjustmentTypeCategory = titleCase(newAdjustment.category);

			if (Number(newAdjustment.amount) >= 0) {
				showErrorToast(`${adjustmentTypeCategory} adjustment amount must be less than zero.`);

				return;
			}

			if (Math.abs(Number(newAdjustment.amount)) > orderTotal) {
				showErrorToast(`${adjustmentTypeCategory} adjustment must not exceed the order total.`);

				return;
			}
		}

		doUpdateAdjustments(
			{
				orderNumber: order.invoiceNumber,
				updatedAdjustments: [...order.costMetadata.adjustments, newAdjustment],
			},
			{
				onSuccess: () => {
					const newAdjustmentText = newAdjustment.description
						? `Issued ${
								adjustmentDescriptions[newAdjustment.category as AdjustmentType]
						  } of ${formatCurrency(Number(newAdjustment.amount))} with description "${
								newAdjustment.description
						  }".`
						: `Issued ${
								adjustmentDescriptions[newAdjustment.category as AdjustmentType]
						  } of ${formatCurrency(Number(newAdjustment.amount))}."`;

					const newAdjustmentComment: CheckoutOrderComment = {
						author: {
							authorDisplayName: currentUser.currentUserFullName || 'unknown',
							authorId: currentUser.currentUserId,
						},
						kind: 'order-adjustment',
						text: newAdjustmentText,
						timestamp: new Date().toISOString(),
					};

					const updatedComments = Array.isArray(order.comments)
						? [...order.comments, newAdjustmentComment]
						: [newAdjustmentComment];

					updateInvoiceComments({
						auction_id: order.auctionId,
						comments: updatedComments,
						invoice_id: order.invoiceId,
					}).then(() => {
						setNewAdjustment({ ...newAdjustment, ...baseAdjustment });
						/** Ordinarily we can rely on useOrder.updateOrder() to simply update the order in the cache, but in this case
						 *  we need to imperatively refetch the order altogether so the backend can return correctly updated numbers
						 */
						onClose();

						refetchOrder();

						showSuccessToast('Successfully added adjustment.');
					});
				},
				onError: () => {
					showErrorToast('Something went wrong adding the adjustment. Please try again later.');
				},
			}
		);
	};

	const {
		costMetadata: { accountBalance, adjustments = [] },
	} = order;

	const balanceAdjustmentFound = adjustments.find(({ category }) => category === 'balance');

	return (
		<ActionModal
			canConfirm={updateAdjustmentsStatus !== 'loading' && newAdjustment.amount != 0} // intentional == here
			confirmButtonLabel="Save Adjustment"
			isLoading={updateAdjustmentsStatus === 'loading'}
			onClose={onClose}
			onConfirm={handleSave}
			title="Add Adjustment"
		>
			{isMissingSubtotal ? (
				<div>
					<div className="text-body1 text-error2">Error: Subtotal Missing</div>
					<div className="text-body2">Please notify the #mvp-cs-channel for help fixing this</div>
				</div>
			) : (
				<>
					<div>
						<Label htmlFor="adjustment-type">Adjustment Type</Label>
						<div className="flex flex-wrap gap-4">
							{ADJUSTMENT_TYPE_OPTIONS.map((adjustmentType) => {
								let adjustmentData: Partial<Adjustment> = { category: adjustmentType };
								if (adjustmentType === AdjustmentTypeEnum.Lot) {
									adjustmentData = { ...adjustmentData, lotId: lotItems[0].lot_id };
								}
								if (adjustmentType === AdjustmentTypeEnum.Balance && !featureBalanceEnabled) {
									return null;
								} else {
									return (
										<RadioButton
											key={adjustmentType}
											checked={newAdjustment.category === adjustmentType}
											label={titleCase(adjustmentType)}
											name="adjustment-type"
											onChange={() => updateNewAdjustment(adjustmentData)}
										/>
									);
								}
							})}
						</div>
					</div>
					{newAdjustment.category === 'lot' && (
						<div className="mt-4 max-w-xl">
							<Label htmlFor="lot">Lot</Label>
							<Dropdown
								name="lot"
								options={lotOptions}
								onChange={({ value }) => updateNewAdjustment({ lotId: value as string })}
							/>
						</div>
					)}
					<div className="mt-4">
						<Label htmlFor="description">Description</Label>
						<Input
							name="description"
							className="w-full"
							value={newAdjustment.description}
							onChange={({ target: { value } }) => updateNewAdjustment({ description: value })}
						/>
					</div>
					{newAdjustment.category === 'balance' && (
						<div className="mt-4">
							<Label>Available Balance</Label>
							<p>{formatCurrency(accountBalance)}</p>
						</div>
					)}
					<div className="mt-4 flex items-center">
						<div>
							<Label htmlFor="amount">Amount</Label>
							<div className="flex items-center">
								<Input
									className="w-full"
									name="amount"
									type="number"
									value={newAdjustment.amount}
									onChange={({ target: { value } }) => updateNewAdjustment({ amount: value })}
								/>
								<Checkbox
									checked={newAdjustment.taxable && newAdjustment.category !== 'balance'}
									className="ml-2"
									disabled={
										newAdjustment.category === 'balance' || newAdjustment.category === 'lot'
									}
									label="Taxable"
									name="taxable"
									onChange={(newValue: boolean) => updateNewAdjustment({ taxable: newValue })}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</ActionModal>
	);
};

export default AddAdjustmentModal;

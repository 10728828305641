import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
	getLotsBySubset,
	getRequestBodyForKindOfParams,
	LotsBySubsetParams,
} from '../../services/lotsService';
import { KernelLot } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';

const useLotsBySubset = (params: LotsBySubsetParams) => {
	const requestBodyForKindOfParams = getRequestBodyForKindOfParams(params);
	const queryKey = [QueryKeys.GetLotsBySubset, params.kind, requestBodyForKindOfParams];

	const query = useQuery(queryKey, () => getLotsBySubset(params));

	// seed individual lot queries when loading lots for auction
	const queryClient = useQueryClient();
	const { data: lotsForSubset = [] as KernelLot[] } = query;
	useEffect(() => {
		if (lotsForSubset.length > 0) {
			lotsForSubset.forEach((lot) =>
				queryClient.setQueryData([QueryKeys.GetIndividualLot, lot.lot_id], () => lot)
			);
		}
	}, [lotsForSubset.length]);

	return query;
};

export default useLotsBySubset;

import { KernelSellerStatementAdjustmentCategoryType } from '../../../../services/sellerStatementService';

export const SELLER_STATEMENT_ADJUSTMENT_CATEGORY_TYPE_LABEL: Record<
	KernelSellerStatementAdjustmentCategoryType,
	string
> = {
	Adjustments: 'Adjustments',
	Advances: 'Advances',
	BalanceOffsetAdvance: 'Balance Offset Advance',
	BalanceOffsetPurchase: 'Balance Offset Purchase',
	Offsets: 'Offsets',
	Payments: 'Payments',
};

import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef, OnChangeFn, PaginationState } from '@tanstack/react-table';
import SortableTable from '../../../../../../components/SortableTable';
import { SortableTableParams } from '../../../../../../components/SortableTable/SortableTable.model';
import { Tooltip } from '../../../../../../prizm-ui/Tooltip';
import { CashAccountEntry } from '../../../../../../services/cashAccountsService/cashAccountsService.model';
import { CASH_ACCOUNT_ENTRY_CATEGORY_LABEL_MAP } from './BalanceHistoryTable.model';
import { formatDate } from '../../../../../../utils/formatters';
import CurrencyCell from '../../../../../../components/SortableTable/cells/CurrencyCell';

const BalanceHistoryTable = ({
	cashAccountEntries,
	isLoading,
	userId,
	pagination,
	setPagination,
	status,
	totalPages,
	totalItems,
}: {
	cashAccountEntries: CashAccountEntry[];
	isLoading: boolean;
	userId: string;
	status: 'completed' | 'pending';
	// The following are only used for completed transactions
	pagination: PaginationState;
	setPagination: OnChangeFn<PaginationState>;
	totalPages: number;
	totalItems: number;
}) => {
	const columns: ColumnDef<CashAccountEntry>[] = [
		{
			accessorFn: ({ timestamp }) => formatDate(timestamp, { dateFormat: 'M/dd/yy' }),
			header: 'Date',
		},
		{
			accessorKey: 'description',
			header: 'Description',
		},
		{
			accessorKey: 'auctionName',
			header: 'Auction',
		},
		{
			header: 'Transaction ID',
			accessorFn: ({ invoiceNumber, operationType, sellerStatementNumber, transactionId }) => {
				if (operationType === 'deposit') {
					return transactionId ?? sellerStatementNumber;
				}

				if (operationType === 'purchase') {
					return transactionId ?? invoiceNumber;
				}

				return '';
			},
			cell: ({
				getValue,
				row: {
					original: { auctionId, operationType },
				},
			}) => {
				if (operationType === 'deposit') {
					return (
						<Tooltip
							content={`View statement ${getValue()}`}
							id={getValue() as string}
							placement="top"
							trigger="hover"
						>
							<Link className="block link" to={`/auctions/${auctionId}/statements/${getValue()}`}>
								{getValue() as string}
							</Link>
						</Tooltip>
					);
				}

				if (operationType === 'purchase') {
					return (
						<Tooltip
							content={`View order ${getValue()}`}
							id={getValue() as string}
							placement="top"
							trigger="hover"
						>
							<Link className="block link" to={`/orders/${getValue()}`}>
								{getValue() as string}
							</Link>
						</Tooltip>
					);
				}

				return '';
			},
		},
		{
			accessorFn: ({ operationType }) => CASH_ACCOUNT_ENTRY_CATEGORY_LABEL_MAP[operationType],
			header: 'Category',
		},
		{
			accessorKey: 'amountChanged',
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
			enableGlobalFilter: false,
			header: () => <div className="w-full text-right">Amount</div>,
		},
		{
			accessorKey: 'currentBalance',
			cell: ({ getValue }) => <CurrencyCell amount={getValue() as number} />,
			enableGlobalFilter: false,
			header: () => <div className="w-full text-right">Ending Balance</div>,
		},
		{
			accessorFn: ({ createdBy }) =>
				createdBy ? `${createdBy.givenName} ${createdBy.familyName}` : '',
			header: 'Created By',
		},
		{
			accessorKey: 'internalNotes',
			header: 'Internal Notes',
		},
	];

	const pendingTableParams: SortableTableParams<CashAccountEntry> = {
		columns,
		data: cashAccountEntries,
		getRowId: ({ timestamp }) => timestamp,
	};

	const { pageIndex, pageSize } = pagination;
	const completedTableParams: SortableTableParams<CashAccountEntry> = {
		columns,
		data: cashAccountEntries,
		getRowId: ({ timestamp }) => timestamp,
		manualPagination: true,
		pageCount: totalPages,
		state: {
			pagination: useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]),
		},
		onPaginationChange: setPagination,
	};

	const sortableTableParams = status === 'completed' ? completedTableParams : pendingTableParams;

	return (
		<SortableTable
			globalFilterPlaceholder="Filter results ..."
			isLoading={isLoading}
			key={`${userId}-${status}`}
			noResultsMessage={isLoading ? 'Loading...' : 'No balance history to show.'}
			tableParams={sortableTableParams}
			totalItems={status === 'completed' ? totalItems : undefined}
		/>
	);
};

export default BalanceHistoryTable;

import React, { FC, useEffect, useState } from 'react';
import useAuctions from '../../queries/useAuctions';
import { useWindowSize } from '../../utils/hooks';
import AppHeader from './AppHeader';
import SideBar from './SideBar';
import { SidebarContextProvider } from './useShouldShowSidebar';

const Layout: FC<{ isAuthenticated?: boolean }> = ({ children, isAuthenticated = true }) => {
	const { width } = useWindowSize();
	const [currentWidth, setCurrentWidth] = useState(width);

	const [shouldShowSidebar, setShouldShowSidebar] = useState(
		typeof width !== 'undefined' && width > 768
	);

	useEffect(() => {
		if (
			typeof currentWidth !== 'undefined' &&
			currentWidth > 768 &&
			typeof width !== 'undefined' &&
			width < 768 &&
			shouldShowSidebar
		) {
			setShouldShowSidebar(false);
		}

		if (
			typeof currentWidth !== 'undefined' &&
			currentWidth < 768 &&
			typeof width !== 'undefined' &&
			width > 768 &&
			!shouldShowSidebar
		) {
			setShouldShowSidebar(true);
		}

		if (typeof currentWidth === 'undefined' && typeof width !== 'undefined' && width > 768) {
			setShouldShowSidebar(true);
		}

		setCurrentWidth(width);
	}, [width]);

	const closeSidebarIfNarrowViewport = () => {
		if (typeof currentWidth !== 'undefined' && currentWidth < 768) {
			setShouldShowSidebar(false);
		}
	};

	const isLargeViewport = typeof currentWidth !== 'undefined' && currentWidth > 768;
	const isSmallViewport = typeof currentWidth !== 'undefined' && currentWidth <= 768;

	const shouldShowContent = isLargeViewport || (isSmallViewport && !shouldShowSidebar);

	// pre-load auctions for use in NavItem paths
	useAuctions();

	return (
		<SidebarContextProvider value={{ closeSidebarIfNarrowViewport }}>
			<div className="flex min-h-screen flex-col">
				<AppHeader
					isAuthenticated={isAuthenticated}
					shouldShowSidebar={shouldShowSidebar}
					setShouldShowSidebar={setShouldShowSidebar}
				/>
				<div className="flex flex-grow">
					{isAuthenticated && (
						<aside
							className={`${shouldShowSidebar ? 'flex' : 'hidden'} ${
								shouldShowContent ? '' : 'flex-grow'
							}`}
						>
							<SideBar />
						</aside>
					)}
					{shouldShowContent && (
						<main className="min-w-0 flex-grow bg-neutral2">
							<div className="overflow-y-auto p-8" style={{ height: 'calc(100vh - 4.75rem)' }}>
								{children}
							</div>
						</main>
					)}
				</div>
			</div>
		</SidebarContextProvider>
	);
};

export default Layout;

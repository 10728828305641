import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card';
import useFixedPriceItem from '../../../../../queries/useFixedPriceItem';
import { FixedPriceItem } from '../../../../../services/lotsService/lotsService.model';

const ListingMeta = () => {
	const { itemId = '' } = useParams<{ itemId: string }>();
	const { data: item = {} as FixedPriceItem } = useFixedPriceItem(itemId);

	return (
		<Card className="self-start">
			<h2 className="text-display5 pb-2">Item Metadata</h2>
			<div className="py-4 flex justify-between">
				<span>Cert Number</span>
				<span className="text-right">
					<span className="text-right">{item.certNumber ?? '-'}</span>
				</span>
			</div>
			<div className="py-4 border-t-light flex justify-between">
				<span>Collectible ID</span>
				<span className="text-right">
					<span className="text-right">{item.collectibleId ?? '-'}</span>
				</span>
			</div>
		</Card>
	);
};

export default ListingMeta;

import React from 'react';
import BannerContent from '../BannerContent';

const AddBanner = () => {
	return (
		<>
			<h2 className="text-display4">Add Banner</h2>
			<div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
				<div className="border-base col-span-1 rounded-lg bg-neutral2 p-4">
					<div className="animate__animated animate__fadeIn">
						<BannerContent kind="new" />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddBanner;

import { useState } from 'react';
import { Button } from '../../../prizm-ui/Button';
import useIndividualLot from '../../../queries/useIndividualLot';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { EDITOR_ROLES } from '../../../services/userService/userService.model';
import Card from '../../Card';
import DefinitionList from '../../DefinitionList';
import { DefinitionListRow } from '../../DefinitionList/DefinitionList.model';
import LoadingWrapper from '../../LoadingWrapper';
import RestrictedArea from '../../RestrictedArea';
import EditLotDetailsModal from './EditLotDetailsModal';

const LotDetails = ({ lotId }: { lotId: string }) => {
	const { data: lot = {} as KernelLot, status: lotStatus } = useIndividualLot(lotId);

	const coreFields: DefinitionListRow[] = [
		{ title: 'Category', content: lot.category },
		{ title: 'Item Type', content: lot.item_type },
		{ title: 'Sub-Category', content: lot.sub_category },
		{ title: 'Grading Company', content: lot.grading_company },
		{ title: 'Year', content: lot.year },
	];
	const optionalRows: DefinitionListRow[] = [
		{ title: 'Grade', content: lot.grade },
		{ title: 'Memorabilia Type', content: lot.memorabilia_type },
		{ title: 'Sport Memorabilia Type', content: lot.sport_memorabilia_type },
		{ title: 'Player/Subject', content: lot.player_subject },
		{ title: 'Sport', content: lot.sport },
		{ title: 'Era', content: lot.era },
		{ title: 'Decade', content: lot.decade },
		{ title: 'Season', content: lot.season },
		{ title: 'Event', content: lot.event },
		{ title: 'Autograph Type', content: lot.autograph_type },
		{ title: 'Autograph Grade', content: lot.autograph_grade },
		{ title: 'Autograph Issuer', content: lot.auto_issuer },
		{ title: 'Spec Serial Number', content: lot.spec_serial_number },
		{ title: 'Team', content: lot.team },
		{ title: 'Manufacturer', content: lot.manufacturer },
		{ title: 'Collection', content: lot.collection },
		{ title: 'Set', content: lot.set },
	];

	const fieldsToShow = [...coreFields, ...optionalRows.filter((row) => !!row.content)].map(
		(row) => ({
			...row,
			content: row.content || '-',
		})
	);

	const [shouldShowEditLotDetailsModal, setShouldShowEditLotDetailsModal] = useState(false);

	return (
		<Card>
			<LoadingWrapper queryStatuses={[lotStatus]}>
				<div className="flex justify-between">
					<h2 className="text-display5">Lot Details</h2>
					<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
						{lot.status !== 'Completed_Sold' && (
							<Button
								variant="text"
								size="md"
								icon="Edit"
								onClick={() => setShouldShowEditLotDetailsModal(true)}
							/>
						)}
						{shouldShowEditLotDetailsModal && (
							<EditLotDetailsModal
								lot={lot}
								onClose={() => setShouldShowEditLotDetailsModal(false)}
							/>
						)}
					</RestrictedArea>
				</div>
				<DefinitionList rows={fieldsToShow} />
			</LoadingWrapper>
		</Card>
	);
};

export default LotDetails;

//

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelCashAccountEntry } from './cashAccountsService.model';

export const getLatestCashAccountEntry = async (
	userId: string
): Promise<KernelCashAccountEntry> => {
	const response = await axiosAuth.get<KernelCashAccountEntry>(
		`${apiEndpoints.cash_accounts}/${userId}`
	);

	if (response.status === 404) {
		return {} as KernelCashAccountEntry;
	} else if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

import { axiosAuth as axios } from './httpUtils';
import config from '../config';

const bidIncrementCount = [
	{
		maxAmount: 49,
		increment: 2,
	},
	{
		maxAmount: 99,
		increment: 5,
	},
	{
		maxAmount: 199,
		increment: 10,
	},
	{
		maxAmount: 499,
		increment: 25,
	},
	{
		maxAmount: 999,
		increment: 50,
	},
	{
		maxAmount: 2499,
		increment: 100,
	},
	{
		maxAmount: 4999,
		increment: 250,
	},
	{
		maxAmount: 9999,
		increment: 500,
	},
	{
		maxAmount: 19999,
		increment: 1000,
	},
	{
		maxAmount: 29999,
		increment: 2000,
	},
	{
		maxAmount: 49999,
		increment: 3000,
	},
	{
		maxAmount: 99999,
		increment: 5000,
	},
	{
		maxAmount: 199999,
		increment: 10000,
	},
	{
		maxAmount: 299999,
		increment: 20000,
	},
	{
		maxAmount: 499999,
		increment: 25000,
	},
	{
		maxAmount: 1499999,
		increment: 50000,
	},
	{
		maxAmount: 1500000,
		increment: 100000,
	},
];

export function getIncrementAmount(currentBidPrice) {
	for (let i = 0; i < bidIncrementCount.length - 1; i += 1) {
		if (bidIncrementCount[i].maxAmount >= currentBidPrice && i === 0) {
			return bidIncrementCount[i].increment;
		}
		if (
			bidIncrementCount[i].maxAmount >= currentBidPrice &&
			bidIncrementCount[i - 1].maxAmount < currentBidPrice
		) {
			return bidIncrementCount[i].increment;
		}
	}
	return bidIncrementCount[bidIncrementCount.length - 1].increment;
}

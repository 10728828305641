import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const changeLotBuyer = async ({
	lotId,
	buyerId,
	price,
}: {
	lotId: string;
	buyerId: string;
	price: number;
}) => {
	const response = await axiosAuth.patch(config.api.reallocate_lot, {
		lot_id: lotId,
		new_winner_id: buyerId,
		bid_amount: price,
		operation_type: 'NEW_WINNER',
	});

	if (response.status >= 400 || (response.data.statusCode && response.data.statusCode >= 400)) {
		throw response;
	}

	return response.data;
};

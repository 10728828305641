import { axiosAuth, axiosUnauthed } from '../../AdminUtils/httpUtils';
import config from '../../config';

const getSellerStatementAdjustmentsBulkUploadS3Url = async () => {
	const response = await axiosAuth.post(
		`${config.api.seller_statement_adjustment_bulk_upload_url}`
	);

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

/** @description Uploads bulk imported lot adjustments to the S3 */
export const bulkUploadSellerStatementAdjustments = async ({
	bulkImportFile,
	employeeId,
}: {
	bulkImportFile: File;
	employeeId: string;
}) => {
	const signedUrl = await getSellerStatementAdjustmentsBulkUploadS3Url();
	const baseHeaders = {
		'Content-Type': bulkImportFile.type,
		// Do not include authorization here
	};
	const headers = {
		...baseHeaders,
		'x-amz-meta-user-id': employeeId,
	};

	const response = await axiosUnauthed.put(signedUrl, bulkImportFile, { headers });

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

import { useState } from 'react';
import AuctionPickerModal from '../../../../../../../components/NewAuctionPicker/AuctionPickerModal/AuctionPickerModal';
import ActionModal from '../../../../../../../components/ActionModal';
import useAuctionById from '../../../../../../../queries/useAuctionById/useAuctionById';
import { Auction } from '../../../../../../../services/auctionsService';
import { useMutation } from 'react-query';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber/useOrderByNumber';
import { QueryKeys } from '../../../../../../../queries/queryKeys';
import { reallocateLotsToAuction } from '../../../../../../../services/invoicesService';
import { showErrorToast, showSuccessToast } from '../../../../../../../components/Toast';
import { KernelLot } from '../../../../../../../services/lotsService/lotsService.model';
import { kernelLotToRemovedLot } from '../OrderSummary.utils';
import { CheckoutOrder } from '../../../../../../../services/ordersService/ordersService.model';

const AssignLotsToAuctionModal = ({
	onClose,
	orderNumber,
	lotItems,
}: {
	onClose: () => void;
	orderNumber: string;
	lotItems: KernelLot[];
}) => {
	const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);
	const [selectedAuctionId, setSelectedAuctionId] = useState('');

	const { data: selectedAuction = {} as Auction } = useAuctionById(selectedAuctionId);

	const { data: order = {} as CheckoutOrder, updateOrder } = useOrderByNumber({ orderNumber });
	const { data: currentAuction = {} as Auction } = useAuctionById(order.auctionId);

	const { mutate: doReassignLotsFromInvoice, status: reassignLotsFromInvoiceStatus } = useMutation({
		mutationKey: [QueryKeys.ReassignLotsFromInvoice, orderNumber, selectedAuctionId],
		mutationFn: () => reallocateLotsToAuction({ newAuctionId: selectedAuctionId, orderNumber }),
	});

	const handleReassignLotsFromInvoice = () =>
		doReassignLotsFromInvoice(undefined, {
			onSuccess: () => {
				updateOrder({
					lotIds: [],
					removedLots: lotItems.map((lot) =>
						kernelLotToRemovedLot(lot, currentAuction.auctionType)
					),
				});
				showSuccessToast('Lots successfully assigned to auction');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong assigning lots to auction. If the issue persists, please contact the #mvp-cs-channel.'
				);
			},
		});

	return (
		<>
			<AuctionPickerModal
				allowedAuctionStatuses={['Active', 'Not_Started', 'Preview', 'Pause', 'Withdrawn']}
				onClose={onClose}
				selectedAuctionId={selectedAuctionId}
				setSelectedAuctionId={(auctionId: string) => {
					setSelectedAuctionId(auctionId);
					setShouldShowConfirmationModal(true);
				}}
				shouldShowUnassignedLots={false}
				closeAfterSelection={false}
			/>
			{shouldShowConfirmationModal && (
				<ActionModal
					title="Assign Lots to Auction"
					onClose={onClose}
					onConfirm={handleReassignLotsFromInvoice}
					isDangerous={true}
					isLoading={reassignLotsFromInvoiceStatus === 'loading'}
				>
					<div className="h-full flex grow items-center justify-center text-subtitle1">
						Are you sure you want to assign all the lots from this invoice to{' '}
						{selectedAuction.title?.trim()}? This process cannot be undone.
					</div>
				</ActionModal>
			)}
		</>
	);
};

export default AssignLotsToAuctionModal;

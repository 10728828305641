import React from 'react';
import { useMutation } from 'react-query';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';

const PauseLotModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const diffs: Partial<KernelLot> = {
		lot_id: lot.lot_id,
		status: 'Pause',
		lot_status_changed: 'Pause',
	};
	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot, lot.lot_id],
		mutationFn: (updates: Partial<KernelLot>) => updateLot(updates),
	});

	const { updateLot: updateLotInCache } = useIndividualLot(lot.lot_id);

	const handlePauseLot = () =>
		doUpdateLot(diffs, {
			onSuccess: (updatedLot: KernelLot) => {
				updateLotInCache(updatedLot);
				showSuccessToast('Successfully paused lot.');
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong pausing lot. Please try again later.');
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			title="End Lot"
			onConfirm={handlePauseLot}
			isLoading={updateLotStatus === 'loading'}
			className="max-w-xl"
		>
			{`You are about to pause "${lot.title}". Are you sure?`}
		</ActionModal>
	);
};

export default PauseLotModal;

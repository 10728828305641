import { useQuery } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { QueryKeys } from '../queryKeys';
import { getLotHistory } from '../../services/lotsService';

const useLotHistory = (lotId: string) =>
	useQuery([QueryKeys.GetLotHistory, lotId], () => getLotHistory({ lotId }), {
		enabled: !!lotId,
		onError: () =>
			showErrorToast(
				'Something went wrong while retrieving the lot history. Please refresh and try again.'
			),
	});

export default useLotHistory;

import Card from '../../../../../../components/Card';
import { RemovedLot } from '../../../../../../services/lotsService/lotsService.model';
import { RemovedLotItem } from './RemovedLotItem';

const OrderRemovedLots = ({ removedLots }: { removedLots: RemovedLot[] }) => {
	return (
		<Card className="mt-4">
			<h2 className="text-subtitle1">Removed Items</h2>
			<div className="mt-4">
				{removedLots.map((lot) => (
					<RemovedLotItem lot={lot} key={lot.lotId} />
				))}
			</div>
		</Card>
	);
};

export default OrderRemovedLots;

import React, { memo, Component } from 'react';
import memoize from 'memoize-one';
import isEqual from 'lodash.isequal';
import {
	Card,
	CardHeader,
	Checkbox,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import SearchFilter from '../../SearchFilter';
import { objIntersection, objNot, objUnion, StripHtml } from '../../../../Utilities';

class CheckboxSelectionList extends Component {
	constructor(props) {
		super(props);

		this.handleToggle = this.handleToggle.bind(this);
		this.numberOfChecked = this.numberOfChecked.bind(this);
		this.handleToggleAll = this.handleToggleAll.bind(this);
		this.createAutoSizerItemData = this.createAutoSizerItemData.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragStop = this.onDragStop.bind(this);

		this.state = {
			items: props.items || [],
			filteredData: props.items || [],
			checked: props.checked || [],
			searchTerm: props.searchTerm || '',
			title: props.title || '',
			noItemsText: props.noItemsText || ' No items available',
			checkedUpdateCallback: props.checkedUpdateCallback,
			primaryKey: props.primaryKey || 'title',
			secondaryKey: props.secondaryKey || 'description',
			draggable: typeof props.draggable !== undefined ? props.draggable : false,
			dragStartIndex: undefined,
			searchFilterKey: 1,
		};
	}

	handleToggle(value) {
		const currentIndex = this.state.checked.indexOf(value);
		const newChecked = [...this.state.checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		if (this.state.checkedUpdateCallback) this.state.checkedUpdateCallback(newChecked);

		this.setState({
			checked: newChecked,
		});
	}

	numberOfChecked(items) {
		return objIntersection(this.state.checked, items, 'Id').length;
	}

	handleToggleAll(items) {
		let newChecked = [];
		const itemsToToggle = this.state.filteredData ? this.state.filteredData : items;
		if (this.numberOfChecked(itemsToToggle) === itemsToToggle.length) {
			newChecked = objNot(this.state.checked, itemsToToggle, 'Id');
		} else {
			newChecked = objUnion(this.state.checked, itemsToToggle, 'Id');
		}

		if (this.state.checkedUpdateCallback) this.state.checkedUpdateCallback(newChecked);

		this.setState({
			checked: newChecked,
		});
	}

	onDragStart() {
		/*
        this.setState({
            dragStartIndex: (node && node.attributes && node.attributes.index && node.attributes.index.value ? node.attributes.index.value : undefined)
        });
        */
	}

	onDragStop(event, draggableData) {
		const { node } = draggableData;

		const dragEndIndex =
			node && node.attributes && node.attributes.index && node.attributes.index.value
				? node.attributes.index.value
				: undefined;

		if (this.state.dragStartIndex && dragEndIndex) {
			// todo: do the swap
		}

		this.setState({
			dragStartIndex: undefined,
		});
	}

	customListRow = memo((props) => {
		const { data, index, style } = props;
		const { items, checked, HandleToggle } = data;

		if (!items || !items[index]) return null;

		const labelId = `transfer-list-item-${items[index].Id}-label`;
		const DOMId = 'customListItem-' + items[index].Id;
		const listItemClasses = 'transfer-list-item' + (this.state.draggable ? ' cursor-grab' : '');

		const ListRowHtml = (
			<ListItem
				id={DOMId}
				key={items[index].Id}
				button
				onClick={() => HandleToggle(items[index])}
				className={listItemClasses}
				style={style}
				index={index}
			>
				<ListItemIcon key={items[index].Id + '-icon'}>
					<Checkbox
						checked={checked.indexOf(items[index]) !== -1}
						tabIndex={-1}
						disableRipple
						inputProps={{ 'aria-labelledby': labelId }}
						title="Select item"
					/>
				</ListItemIcon>
				<ListItemText
					key={items[index].Id + '-text'}
					id={labelId}
					primary={
						items[index][this.state.primaryKey]
							? StripHtml(items[index][this.state.primaryKey])
							: ' '
					}
					secondary={
						items[index][this.state.secondaryKey]
							? StripHtml(items[index][this.state.secondaryKey])
							: ' '
					}
					className="mt-auto"
					classes={{
						primary: 'font-weight-bold align-items-start transfer-list-title',
						secondary: 'align-items-start transfer-list-description',
					}}
					title="Select item"
				/>
			</ListItem>
		);

		return this.state.draggable ? (
			<Draggable axis="y" onStart={this.onDragStart} onStop={this.onDragStop}>
				{ListRowHtml}
			</Draggable>
		) : (
			ListRowHtml
		);
	}, isEqual);

	// This helper function memoizes incoming props,
	// To avoid causing unnecessary re-renders pure customListRow components.
	// This is only needed since we are passing multiple props with a wrapper object.
	// If we were only passing a single, stable value (e.g. items),
	// We could just pass the value directly.
	createAutoSizerItemData = memoize((items, checked, HandleToggle) => ({
		items,
		checked,
		HandleToggle,
	}));

	filteredDataCallback = (newFilteredData, newSearchTerm) => {
		this.setState({
			filteredData: newFilteredData,
			searchTerm: newSearchTerm,
		});
	};

	render() {
		const numberOfChecked = this.numberOfChecked(this.state.items);
		const listItemCount = this.state.filteredData.length;

		return (
			<Card>
				<CardHeader
					avatar={
						<Checkbox
							onClick={() => this.handleToggleAll(this.state.items)}
							checked={numberOfChecked === this.state.items.length && this.state.items.length !== 0}
							indeterminate={numberOfChecked !== this.state.items.length && numberOfChecked !== 0}
							disabled={this.state.items.length === 0}
							inputProps={{ 'aria-label': 'all items selected' }}
						/>
					}
					title={this.state.title}
					subheader={`${numberOfChecked}/${this.state.items.length} selected`}
				/>
				<Divider />
				<SearchFilter
					key={this.state.searchFilterKey}
					items={this.state.items}
					filteredDataCallback={(filteredData, searchTerm) =>
						this.filteredDataCallback(filteredData, searchTerm)
					}
					searchTerm={this.state.searchTerm}
				/>
				<div className="transfer-list">
					{this.state.filteredData && this.state.filteredData.length > 0 ? (
						<AutoSizer>
							{({ height, width }) => (
								<FixedSizeList
									height={height}
									width={width}
									itemSize={65}
									itemCount={listItemCount}
									itemData={this.createAutoSizerItemData(
										this.state.filteredData,
										this.state.checked,
										this.handleToggle
									)}
									itemKey={() => uuidv4()}
								>
									{this.customListRow}
								</FixedSizeList>
							)}
						</AutoSizer>
					) : (
						<div className="selection-list-no-items">{this.state.noItemsText}</div>
					)}
				</div>
			</Card>
		);
	}
}

export default CheckboxSelectionList;

import { KernelSellerStatementCategory } from '../../../../../services/sellerStatementService';

// Do not sort alphabetically- these set the order of menu options
export const SELLER_STATEMENT_CATEGORIES: KernelSellerStatementCategory[] = [
	{
		label: 'Payments',
		value: 'Payments',
	},
	{
		label: 'Offsets',
		value: 'Offsets',
	},
	{
		label: 'Advances',
		value: 'Advances',
	},
	{
		label: 'Adjustments',
		value: 'Adjustments',
	},
	{
		label: 'Balance Offset Advance',
		value: 'BalanceOffsetAdvance',
	},
	{
		label: 'Balance Offset Purchase',
		value: 'BalanceOffsetPurchase',
	},
];

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../../../../components/ActionModal';
import Input from '../../../../../../../../components/Input';
import Label from '../../../../../../../../components/Label';
import LoadingWrapper from '../../../../../../../../components/LoadingWrapper';
import PersonSelector from '../../../../../../../../components/PersonSelector';
import { PersonOption } from '../../../../../../../../components/PersonSelector/PersonSelector';
import { showErrorToast, showSuccessToast } from '../../../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../../../queries/queryKeys';
import useIndividualLot from '../../../../../../../../queries/useIndividualLot';
import useOrderByNumber from '../../../../../../../../queries/useOrderByNumber';
import { changeLotBuyer } from '../../../../../../../../services/lotsService';
import { KernelLot } from '../../../../../../../../services/lotsService/lotsService.model';
import { CheckoutOrder } from '../../../../../../../../services/ordersService/ordersService.model';

const ChangeBuyerModal = ({ onClose, lotId }: { onClose: () => void; lotId: string }) => {
	const { orderNumber = '' } = useParams<{ orderNumber: string }>();
	const {
		data: order = {} as CheckoutOrder,
		status: orderStatus,
		updateOrder: updateOrderInCache,
	} = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const { data: thisLot = {} as KernelLot, status: thisLotStatus } = useIndividualLot(lotId);

	const [selectedBuyer, setSelectedBuyer] = useState<PersonOption>();
	const [lotPrice, setLotPrice] = useState(thisLot.current_price);
	useEffect(() => {
		setLotPrice(thisLot.current_price);
	}, [thisLot.current_price]);

	const { mutate: doChangeBuyer, status: changeBuyerStatus } = useMutation({
		mutationKey: [QueryKeys.ChangeBuyer, order.winnerId, selectedBuyer?.id],
		mutationFn: (buyerId: string) => changeLotBuyer({ lotId, buyerId, price: lotPrice }),
	});

	const handleChangeBuyer = () => {
		doChangeBuyer(selectedBuyer?.id || '', {
			onSuccess: () => {
				updateOrderInCache({
					...order,
					lotIds: order.lotIds.filter((thisLotId) => thisLotId !== lotId),
				});
				showSuccessToast(`Successfully changed buyer to ${selectedBuyer?.email}`);
				onClose();
			},
			onError: () => {
				showErrorToast('Something went wrong changing buyer. Please try again later.');
			},
		});
	};

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleChangeBuyer}
			title="Change Buyer"
			canConfirm={!!selectedBuyer}
			isLoading={changeBuyerStatus === 'loading'}
		>
			<LoadingWrapper queryStatuses={[orderStatus, thisLotStatus]}>
				<PersonSelector
					label="Select New Buyer"
					name="buyer"
					onChange={setSelectedBuyer}
					onClear={() => setSelectedBuyer(undefined)}
				/>
				<div className="mt-4">
					<Label htmlFor="lotPrice">Lot Price</Label>
					<Input
						name="lotPrice"
						type="number"
						onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
							setLotPrice(Number(value) || 0)
						}
						value={lotPrice}
					/>
				</div>
			</LoadingWrapper>
		</ActionModal>
	);
};

export default ChangeBuyerModal;

import { useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import CardImage from './CardImage';
import InternalNotes from './InternalNotes';
import ListingDetails from './ListingDetails';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import OffersTable from './OffersTable';
import {
	FixedPriceItem,
	CollectorsImageItem,
	FixedPriceItemStatus,
} from '../../../../services/lotsService/lotsService.model';
import { FixedPriceOrder } from '../../../../services/ordersService/ordersService.model';
import useFixedPriceItem from '../../../../queries/useFixedPriceItem';
import useFixedPriceOrders from '../../../../queries/useFixedPriceOrders';
import useUserById from '../../../../queries/useUserById';
import { EDITOR_ROLES, User } from '../../../../services/userService/userService.model';
import { Tag } from '../../../../prizm-ui/Tag';
import { getSentimentForFixedPriceItemStatus } from './FixedPriceItemPage.utils';
import { FixedPriceOffer } from '../../../../services/offersService';
import useOffersForItem from '../../../../queries/useOffersForItem';
import SoldBanner from './SoldBanner';
import { formatDate } from '../../../../utils/formatters';
import { AsyncUsername } from '../../../../components/Username';
import { Button } from '../../../../prizm-ui/Button';
import { useState } from 'react';
import EditFixedPriceItemModal from './EditFixedPriceItemModal';
import RestrictedArea from '../../../../components/RestrictedArea';
import { useMutation } from 'react-query';
import { updateLot } from '../../../../services/lotsService';
import { QueryKeys } from '../../../../queries/queryKeys';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import ListingMeta from './ListingMeta';
import ListingFinancials from './ListingFinancials';

const FixedPriceItemPage = () => {
	const { itemId = '' } = useParams<{ itemId: string }>();
	const {
		data: item = {} as FixedPriceItem,
		status: itemLoadStatus,
		updateFixedPriceItem: updateItemInCache,
	} = useFixedPriceItem(itemId);

	const { data: seller = {} as User, status: sellerStatus } = useUserById(item.sellerId);
	const { data: winner = {} as User, status: winnerStatus } = useUserById(item.winnerId);

	const { data: fixedPriceOrders = [] as FixedPriceOrder[], status: fixedPriceOrdersStatus } =
		useFixedPriceOrders({
			fromDate: '',
			searchQuery: winner.email,
			searchType: 'query',
			status: 'Paid',
			toDate: '',
		});

	const { data: offers = [] as FixedPriceOffer[], status: offersStatus } = useOffersForItem(itemId);

	const [relevantFixedPriceOrder = {} as FixedPriceOrder] = fixedPriceOrders.filter(({ lotIds }) =>
		lotIds.includes(item.lotId)
	);

	const { externalImages = [] } = item;
	const [frontImage = {} as CollectorsImageItem, backImage = {} as CollectorsImageItem] =
		externalImages;

	const itemStatusSentiment = getSentimentForFixedPriceItemStatus(item.status);

	const [shouldShowEditFixedPriceItemModal, setShouldShowEditFixedPriceItemModal] = useState(false);

	const { mutate: doUpdateLot, status: toggleItemStatusMutationStatus } = useMutation({
		mutationFn: (newStatus: FixedPriceItemStatus) =>
			updateLot({ lot_id: itemId, lot_status_changed: newStatus, status: newStatus }),
		mutationKey: [QueryKeys.UpdateFixedPriceItemStatus, itemId],
	});

	const toggleItemStatus = () => {
		const newStatus: FixedPriceItemStatus = item.status === 'Live' ? 'Withdrawn' : 'Live';
		doUpdateLot(newStatus, {
			onSuccess: () => {
				showSuccessToast(`Successfully set item status to ${newStatus}`);
				updateItemInCache({ status: newStatus });
			},
			onError: () => {
				showErrorToast('Something went wrong updating item status. Please try again later.');
			},
		});
	};

	return (
		<LoadingWrapper
			queryStatuses={[
				itemLoadStatus,
				fixedPriceOrdersStatus,
				sellerStatus,
				winnerStatus,
				offersStatus,
			]}
		>
			<div className="mb-12 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
				<div>
					<div
						className="col-span-1 overflow-hidden rounded-xl border-b border-neutralstroke2 bg-neutral1 p-11 text-center lg:border"
						aria-hidden="true"
					>
						<CardImage imageFront={frontImage.medium} imageBack={backImage.medium} />
					</div>
				</div>
				<div className="col-span-2">
					<Card>
						<div className="flex justify-between">
							<div>
								<div className="w-fit flex">
									<Tag variant={itemStatusSentiment}>
										{item.status === 'Completed_Sold' ? 'Sold' : item.status}
									</Tag>
									{item.allowOffers && (
										<div className="ml-2">
											<Tag
												variant={item.status === 'Completed_Sold' ? 'gray' : 'primary'}
												icon="TicketDiagonal"
											>
												{offers.length} {offers.length === 1 ? 'Offer' : 'Offers'}
											</Tag>
										</div>
									)}
									<div className="ml-2 text-caption1">
										{item.gradingCompany} {item.grade}
									</div>
								</div>
								<h1 className="text-display4">
									<a
										href={`${process.env.BIDDER_SITE_URL}/fixed-price/item/${item.metaSlug}`}
										target="_blank"
										rel="noreferrer"
										className="hover:link"
									>
										{item.title}
									</a>
								</h1>
								<div className="text-caption1">
									<span className="uppercase">Listed on {formatDate(item.createdAt)} by </span>
									<AsyncUsername type="bidder" id={seller.id} className="uppercase" />
									{item.status === 'Completed_Sold' && (
										<>
											<span className="uppercase">, sold to </span>
											<AsyncUsername type="bidder" id={winner.id} className="uppercase" />
										</>
									)}
								</div>
							</div>
							<div className="flex flex-col items-end">
								{item.status !== 'Completed_Sold' && (
									<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
										<Button
											variant="primary"
											size="md"
											trailingIcon="Edit"
											onClick={() => setShouldShowEditFixedPriceItemModal(true)}
										>
											Edit Item
										</Button>
										<Button
											className="mt-2"
											variant="secondary"
											size="md"
											trailingIcon={item.status === 'Live' ? 'EyeOff' : 'Eye'}
											destructive={item.status === 'Live'}
											onClick={toggleItemStatus}
											loading={toggleItemStatusMutationStatus === 'loading'}
										>
											{item.status === 'Live' ? 'Withdraw Item' : 'Set to Live'}
										</Button>
										{shouldShowEditFixedPriceItemModal && (
											<EditFixedPriceItemModal
												itemId={item.lotId}
												onClose={() => setShouldShowEditFixedPriceItemModal(false)}
											/>
										)}
									</RestrictedArea>
								)}
							</div>
						</div>
					</Card>
					{item.status === 'Completed_Sold' && (
						<div className="mt-4">
							<SoldBanner
								askingPrice={Number(item.currentPrice)}
								finalPrice={item.finalPrice}
								offers={offers}
								orderNumber={relevantFixedPriceOrder.invoiceNumber}
							/>
						</div>
					)}
					<div className="grid gap-4 lg:grid-cols-2 my-4">
						<ListingDetails />
						<div>
							<ListingMeta />
							<div className="mt-4">
								<ListingFinancials />
							</div>
						</div>
					</div>
					<InternalNotes />
					<div className="mt-4">
						<OffersTable allowsOffers={item.allowOffers} itemStatus={item.status} />
					</div>
				</div>
			</div>
		</LoadingWrapper>
	);
};

export default FixedPriceItemPage;

import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ActionModal from '../../../../../components/ActionModal';
import { Input } from '../../../../../prizm-ui/Input';
import { Select, Option } from '../../../../../prizm-ui/Select';
import { Textarea } from '../../../../../prizm-ui/Textarea';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import {
	createSellerStatementAdjustment,
	DraftSellerStatementAdjustment,
	KernelSellerStatementAdjustmentCategoryType,
} from '../../../../../services/sellerStatementService';
import { SELLER_STATEMENT_CATEGORIES } from './SellerStatementAdjustmentModal.model';
import { QueryKeys } from '../../../../../queries/queryKeys';

const SellerStatementAdjustmentModal = ({
	onClose,
	auctionId,
	statementId,
}: {
	onClose: () => void;
	auctionId: string;
	statementId: string;
}) => {
	const [draftAdjustment, setDraftAdjustment] = useState<DraftSellerStatementAdjustment>({
		adjustment_type: 'Credit',
		amount: 0,
		category_type: 'Payments',
		description: '',
	});
	const updateDraftAdjustment = (deltas: Partial<DraftSellerStatementAdjustment>) =>
		setDraftAdjustment({ ...draftAdjustment, ...deltas });

	const {
		mutate: doCreateSellerStatementAdjustment,
		status: createSellerStatementAdjustmentStatus,
	} = useMutation({
		mutationKey: [
			QueryKeys.CreateSellerStatementAdjustment,
			auctionId,
			statementId,
			draftAdjustment,
		],
		mutationFn: () =>
			createSellerStatementAdjustment({ auctionId, statementId, adjustment: draftAdjustment }),
	});

	const queryClient = useQueryClient();

	const handleCreateSellerStatementAdjustment = () =>
		doCreateSellerStatementAdjustment(undefined, {
			onSuccess: () => {
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementDetails]);
				queryClient.invalidateQueries([QueryKeys.GetSellerStatementPDF]);
				showSuccessToast('Successfully created statement adjustment.');
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong creating statement adjustment. If the issue persists, please notify the #mvp-cs-channel.'
				);
			},
		});

	return (
		<ActionModal
			title="Make Payment/Adjustment"
			onConfirm={handleCreateSellerStatementAdjustment}
			onClose={onClose}
			isLoading={createSellerStatementAdjustmentStatus === 'loading'}
			canConfirm={draftAdjustment.amount >= 0 && !!draftAdjustment.description}
		>
			<Select
				id="category_type"
				label="Category"
				onChange={({ target: { value } }) =>
					updateDraftAdjustment({
						category_type: value as KernelSellerStatementAdjustmentCategoryType,
					})
				}
			>
				{SELLER_STATEMENT_CATEGORIES.map((category, index) => (
					<Option index={index} value={category.value} key={`${category.value}-${index}`}>
						{category.label}
					</Option>
				))}
			</Select>
			<Select
				className="mt-2"
				id="category_type"
				label="Adjustment Type"
				onChange={({ target: { value } }) =>
					updateDraftAdjustment({
						adjustment_type: value as 'Credit' | 'Debit',
					})
				}
			>
				<Option index={0} value="Credit" key="Credit">
					Credit
				</Option>
				<Option index={1} value="Debit" key="Debit">
					Debit
				</Option>
			</Select>
			<Input
				className="mt-2"
				label="Amount"
				id="amount"
				type="number"
				step={0.01}
				value={draftAdjustment.amount}
				onChange={({ target: { value } }) => updateDraftAdjustment({ amount: Number(value) })}
			/>
			<Textarea
				className="mt-2"
				label="Description"
				id="description"
				value={draftAdjustment.description}
				onChange={({ target: { value } }) => updateDraftAdjustment({ description: value })}
			/>
		</ActionModal>
	);
};

export default SellerStatementAdjustmentModal;

export interface AddNewLotModalStep {
	id: 'add-new-lot' | 'select-auction';
	title: 'Add New Lot' | 'Select Auction';
}

export const ADD_NEW_LOT_MODAL_STEPS: AddNewLotModalStep[] = [
	{
		id: 'select-auction',
		title: 'Select Auction',
	},
	{
		id: 'add-new-lot',
		title: 'Add New Lot',
	},
];

import React from 'react';
import { useMutation } from 'react-query';
import Card from '../../../../../../components/Card';
import Checkbox from '../../../../../../components/Checkbox';
import Dropdown from '../../../../../../components/Dropdown';
import Label from '../../../../../../components/Label';
import { showErrorToast, showSuccessToast } from '../../../../../../components/Toast';
import { QueryKeys } from '../../../../../../queries/queryKeys';
import { PAYMENT_OPTIONS } from './OrderPaymentDetails.model';
import useOrderByNumber from '../../../../../../queries/useOrderByNumber';
import {
	CheckoutOrder,
	PaymentMethod,
} from '../../../../../../services/ordersService/ordersService.model';
import { sendPaymentReminder } from '../../../../../../services/invoicesService';
import { updateOrder } from '../../../../../../services/ordersService';
import Button from '../../../../../../components/Button';
import useDecision from '../../../../../../optimizely/useDecision';
import { FeatureFlags } from '../../../../../../optimizely/useDecision/useDecision.model';

const OrderPaymentDetails = ({
	order,
	selectedPaymentMethod,
	setSelectedPaymentMethod,
}: {
	order: CheckoutOrder;
	selectedPaymentMethod: PaymentMethod;
	setSelectedPaymentMethod: (newPaymentMethod: PaymentMethod) => void;
}) => {
	const featureBalanceEnabled = useDecision(FeatureFlags.Balance);

	const { updateOrder: updateOrderInCache } = useOrderByNumber({
		orderNumber: order.invoiceNumber,
		enabled: true,
	});

	const { mutate: doUpdateOrder, status: updateOrderStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateOrderPaymentReminders, order.invoiceNumber],
		mutationFn: ({ disablePaymentReminders }: { disablePaymentReminders: boolean }) =>
			updateOrder({
				fieldsToUpdate: {
					disablePaymentReminders,
				},
				orderNumber: order.invoiceNumber,
			}),
	});

	const handleUpdateOrder = (isChecked: boolean) => {
		doUpdateOrder(
			{ disablePaymentReminders: isChecked },
			{
				onError: () => showErrorToast('Something went wrong. Please try again later.'),
				onSuccess: () => {
					showSuccessToast('Payment reminder changes saved successfully.');

					updateOrderInCache({ ...order, disablePaymentReminders: isChecked });
				},
			}
		);
	};

	const { mutate: doSendInvoice, status: sendInvoiceStatus } = useMutation({
		mutationKey: [QueryKeys.SendInvoice, order.invoiceNumber],
		mutationFn: () => sendPaymentReminder({ invoiceNumber: order.invoiceNumber }),
	});

	const handleSendInvoice = () =>
		doSendInvoice(undefined, {
			onSuccess: () => {
				showSuccessToast('Invoice email sent to buyer.');
			},
			onError: () => {
				showErrorToast('Something went wrong sending invoice to buyer. Please try again later.');
			},
		});

	const handleSetSelectedPaymentMethod = (value: PaymentMethod) => {
		if (value === 'balance' && balanceAdjustmentFound) {
			showErrorToast(
				'Balance adjustment exists for order. Remove the adjustment to pay with balance.'
			);

			return;
		}

		setSelectedPaymentMethod(value);
	};

	const {
		costMetadata: { adjustments = [] },
	} = order;

	const balanceAdjustmentFound = adjustments.find(({ category }) => category === 'balance');

	const paymentOptions = featureBalanceEnabled
		? PAYMENT_OPTIONS
		: PAYMENT_OPTIONS.filter((paymentOption) => paymentOption.value !== 'balance');

	return (
		<Card>
			<div className="flex flex-row items-start justify-between">
				<h2 className="text-subtitle1">Payment Details</h2>
				{order.invoiceStatus !== 'Paid' && (
					<Button
						className="link"
						kind="tertiary"
						isInline={true}
						onClick={handleSendInvoice}
						isLoading={sendInvoiceStatus === 'loading'}
						disabled={order.invoiceStatus === 'Blocked'}
					>
						Send Invoice
					</Button>
				)}
			</div>
			<div className="mt-4">
				<Label htmlFor="payment-method">Payment Method</Label>
				<Dropdown
					defaultOption={paymentOptions.find(({ value }) => value === selectedPaymentMethod)}
					isDisabled={order.invoiceStatus === 'Paid'}
					name="payment-method"
					options={paymentOptions}
					onChange={({ value }) => handleSetSelectedPaymentMethod(value as PaymentMethod)}
				/>
			</div>
			<div className="mt-4">
				<Checkbox
					checked={order.disablePaymentReminders}
					label="Disable Payment Reminders"
					name="payment-reminders-checkbox"
					onChange={(newValue: boolean) => handleUpdateOrder(newValue)}
					disabled={order.invoiceStatus === 'Paid' || updateOrderStatus === 'loading'}
				/>
			</div>
		</Card>
	);
};

export default OrderPaymentDetails;

import fuzzysearch from 'fuzzysearch';
import { useState } from 'react';
import AuctionPickerResultsSection from '../AuctionPickerResultsSection';
import AuctionStatusMenu from '../AuctionStatusMenu/AuctionStatusMenu';
import AuctionTypeChips from '../AuctionTypeChips/AuctionTypeChips';
import Button from '../../../Button/Button';
import LoadingWrapper from '../../../LoadingWrapper/LoadingWrapper';
import { Search } from '../../../../prizm-ui/Search';
import {
	Auction,
	AuctionStatus,
	AuctionType,
	ALL_AUCTION_TYPES,
} from '../../../../services/auctionsService';
import useAuctions from '../../../../queries/useAuctions/useAuctions';

const AuctionPickerModalContent = ({
	allowedAuctionStatuses,
	closeAfterSelection = true,
	selectedAuctionId,
	shouldShowUnassignedLots,
	onClose,
	setSelectedAuctionId,
}: {
	allowedAuctionStatuses: AuctionStatus[];
	closeAfterSelection?: boolean;
	selectedAuctionId: string;
	shouldShowUnassignedLots: boolean;
	onClose: () => void;
	setSelectedAuctionId: (selectedAuctionId: string) => void;
}) => {
	const { data: auctions = [] as Auction[], status: auctionsStatus } = useAuctions();
	const auctionsPermittedByStatus =
		allowedAuctionStatuses.length === 0
			? auctions
			: auctions.filter(({ status }) => allowedAuctionStatuses.includes(status));

	const [searchTerm, setSearchTerm] = useState('');
	const auctionTitlesFilteredBySearchTerm = auctionsPermittedByStatus.filter(({ title = '' }) => {
		return title.toLowerCase().includes(searchTerm.toLowerCase()) || fuzzysearch(searchTerm, title);
	});

	const [selectedAuctionStatus, setSelectedAuctionStatus] = useState<AuctionStatus>();
	const auctionsFilteredByStatus = selectedAuctionStatus
		? auctionTitlesFilteredBySearchTerm.filter(({ status }) => status === selectedAuctionStatus)
		: auctionTitlesFilteredBySearchTerm;

	const [selectedAuctionTypes, setSelectedAuctionTypes] = useState<AuctionType[]>([]);
	const selectedAuctionTypeSectionsToShow =
		selectedAuctionTypes.length === 0 ? ALL_AUCTION_TYPES : selectedAuctionTypes;
	const auctionTypeSectionsWithResults = selectedAuctionTypeSectionsToShow.filter(
		(auctionType) => auctionsFilteredByStatus.filter(({ type }) => type === auctionType).length > 0
	);

	const clearFilters = () => {
		setSearchTerm('');
		setSelectedAuctionStatus(undefined);
		setSelectedAuctionTypes([]);
	};

	const selectAuction = (auctionId: string) => {
		setSelectedAuctionId(auctionId);

		closeAfterSelection && onClose();
	};

	return (
		<LoadingWrapper queryStatuses={[auctionsStatus]}>
			<Search
				autoFocus={true}
				placeholder="Search Auctions"
				id="search-auctions"
				value={searchTerm}
				onChange={({ target: { value } }) => setSearchTerm(value)}
			/>
			<div className="mt-4 flex justify-between items-center">
				<AuctionTypeChips
					selectedAuctionTypes={selectedAuctionTypes}
					setSelectedAuctionTypes={setSelectedAuctionTypes}
				/>
				<AuctionStatusMenu
					allowedAuctionStatuses={allowedAuctionStatuses}
					setSelectedAuctionStatus={setSelectedAuctionStatus}
				/>
			</div>

			{auctionTypeSectionsWithResults.map((auctionType) => (
				<AuctionPickerResultsSection
					key={auctionType}
					title={auctionType}
					auctions={auctionsFilteredByStatus.filter(({ type }) => type === auctionType)}
					selectedAuctionId={selectedAuctionId}
					selectAuction={selectAuction}
				/>
			))}
			{shouldShowUnassignedLots && selectedAuctionTypes.length === 0 && !selectedAuctionStatus && (
				<AuctionPickerResultsSection
					title="Unassigned"
					auctions={[
						{ title: 'Unassigned Lots', id: 'Unassigned', status: 'Not_Started' } as Auction,
					]}
					selectAuction={selectAuction}
					selectedAuctionId={selectedAuctionId}
				/>
			)}
			{auctionTypeSectionsWithResults.length === 0 && (
				<div className="mt-36 flex flex-col items-center justify-center">
					<div className="text-subtitle1">No results found.</div>
					<Button kind="tertiary" onClick={clearFilters}>
						Clear Filters
					</Button>
				</div>
			)}
		</LoadingWrapper>
	);
};

export default AuctionPickerModalContent;

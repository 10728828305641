import { flexRender } from '@tanstack/react-table';
import { useTable } from '../../useTableContext';
import { VerticalAlignmentValue } from '../../SortableTable.model';

const TableBody = ({ verticalAlignment }: { verticalAlignment: VerticalAlignmentValue }) => {
	const { getRowModel } = useTable();
	const { rows } = getRowModel();

	return (
		<tbody>
			{rows.map((row, index) => {
				return (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<td
								key={cell.id}
								className={`px-2 py-3 align-${verticalAlignment} ${index > 0 && 'border-t-light'}`}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				);
			})}
		</tbody>
	);
};

export default TableBody;

import React from 'react';
import { Link } from 'react-router-dom';
import StripePayoutLink from './StripePayoutLink';
import { Tooltip } from '../../../../prizm-ui/Tooltip';

const StatementNumberCell = ({
	auctionId,
	stripePayoutAccountId,
	statementId,
	statementNumber,
	urlSchema,
}: {
	auctionId: string;
	stripePayoutAccountId: string;
	statementId: string;
	statementNumber: string;
	urlSchema: 'fixed-price' | 'auction';
}) => {
	const statementUrl =
		urlSchema === 'fixed-price'
			? `/auctions/fixed_price_marketplace/statements/${statementId}`
			: `/auctions/${auctionId}/statements/${statementNumber}`;
	return (
		<div className="w-32">
			<div className="flex items-center">
				{stripePayoutAccountId && <StripePayoutLink payoutAccountId={stripePayoutAccountId} />}
				<div>
					<Tooltip id={`view-statement-${statementId}`} content="View Statement">
						<Link className="link" to={statementUrl}>
							{statementNumber}
						</Link>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default StatementNumberCell;

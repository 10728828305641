export default {
	add_statement_adjustment: `${process.env.API_ADD_STATEMENT_ADJUSTMENT}/api/add_statement_adjustment`,
	auction_invoices: `${process.env.API_AUCTION_INVOICES}/api/get_bidder_invoices`,
	auctions: `${process.env.API_AUCTIONS}/api/auctions`,
	bid_history: `${process.env.API_BIDDING_ACTIONS}/api/get_bid_history`,
	bidder_info_actions: `${process.env.API_BIDDER_ELASTIC_SEARCH}/api/bidder_info`,
	bidding_actions: `${process.env.API_BIDDING_ACTIONS}/api`,
	bulk_payouts: `${process.env.API_BULK_PAYOUTS}/api/bulk-payouts`,
	bulk_payouts_upload_url: `${process.env.API_BULK_PAYOUTS}/api/bulk-payouts/generate-s3-signed-url`,
	cash_accounts: `${process.env.API_CASH_ACCOUNTS}/api/cash-account`,
	cash_accounts_stripe_payout: `${process.env.API_CASH_ACCOUNTS}/api/withdraw`,
	checkout: `${process.env.API_CHECKOUT}/api/checkout`,
	create_user: `${process.env.API_USER_MANAGEMENT}/api/create_user`,
	delete_statement_adjustment: `${process.env.API_DELETE_STATEMENT_ADJUSTMENT}/api/delete_statement_adjustment`,
	disable_user: `${process.env.API_DISABLE_USER}/api/disable_user`,
	get_fixed_price_orders: `${process.env.API_FIXED_PRICE_ORDERS}/api/get_fixed_price_orders`,
	get_sellers_fixed_price_orders: `${process.env.API_FIXED_PRICE_ORDERS}/api/get_sellers_fixed_price_orders`,
	get_sellers_fixed_price_statements: `${process.env.API_FIXED_PRICE_ORDERS}/api/get_sellers_fixed_price_statements`,
	goldin_cloudfront_url: process.env.API_CLOUDFRONT_IMAGES,
	images: `${process.env.API_LOTS}/api/image`,
	invoice_bulk_payments_status_upload_url: `${process.env.API_INVOICE_BULK}/api/orders/bulk-upload/mark-as-paid/generate-s3-signed-url`,
	invoice_bulk_adjustments_url: `${process.env.API_INVOICE_BULK}/api/orders/bulk-upload/add-adjustments/generate-s3-signed-url`,
	invoice_details: `${process.env.API_INVOICE_DETAILS}/api/get_invoice_details`,
	send_payment_reminder: `${process.env.API_SEND_PAYMENT_REMINDER}/api/send_payment_reminder`,
	lotWatchers: `${process.env.API_LOTS}/api/watchers`,
	lot_adjustments: `${process.env.API_LOTS_ADJUSTMENT}/api`,
	lots: `${process.env.API_LOTS}/api/lots`,
	lots_bulk_upload_url: `${process.env.API_LOTS_BULK_UPLOAD_URL}/api/get_bulk_upload_url`,
	lots_bulk_adjustments_upload_url: `${process.env.API_LOTS_BULK_UPLOAD_URL}/api/get_bulk_adjustments_upload_url`,
	manage_employees: `${process.env.API_MANAGE_EMPLOYEES}/api/employees`,
	offers: `${process.env.API_OFFERS_SERVICE}/api`,
	rate_cards: `${process.env.API_RATE_CARD}/api/user`,
	reallocate_lot: `${process.env.API_REALLOCATE_LOT}/api/reallocate_bid`,
	reallocate_lots_from_invoice: `${process.env.API_AUCTION_INVOICES}/api/invoices`,
	regenerate_seller_statement: `${process.env.API_UPDATE_SELLER_STATEMENT}/api/regenerate_seller_statement`,
	reports: `${process.env.API_REPORTS}/api`,
	seller_statement_adjustment_bulk_upload_url: `${process.env.API_SELLER_STATEMENT}/api/get_bulk_upload_url`,
	seller_statement_details: `${process.env.API_SELLER_STATEMENT_DETAILS}/api/get_seller_statement_details`,
	seller_statement_pdf: `${process.env.API_SELLER_STATEMENT}/api/get_seller_statement_pdf`,
	seller_statements: `${process.env.API_SELLER_STATEMENT}/api/get_seller_statements`,
	seller_statements_status_bulk_upload_url: `${process.env.API_SELLER_STATEMENT}/api/seller-statements/bulk-upload/mark-as-paid/generate-s3-signed-url`,
	send_draft_invoices: `${process.env.API_SEND_DRAFT_INVOICES}/api/send_stripe_invoices`,
	update_bidder_invoice: `${process.env.API_UPDATE_BIDDER_INVOICE}/api/update_bidder_invoice`,
	update_credit_limit: `${process.env.API_DISABLE_USER}/api/update_credit_limit`,
	update_notes: `${process.env.API_USER_MANAGEMENT}/api/update_notes`,
	update_seller_statement: `${process.env.API_UPDATE_SELLER_STATEMENT}/api/update_seller_statement`,
	update_shipping_address: `${process.env.API_DISABLE_USER}/api/update_shipping_address`,
	update_user: `${process.env.API_USER_MANAGEMENT}/api/update_user`,
	update_user_email: `${process.env.API_USER_MANAGEMENT}/api/update_user_email`,
	update_user_password: `${process.env.API_USER_MANAGEMENT}/api/update_user_password`,
	user_bids: `${process.env.API_BIDDING_ACTIONS}/api/get_user_bids`,
	user_bulk_upload_url: `${process.env.API_USER_MANAGEMENT}/api/get_bulk_upload_url`,
	user_merge_userinfo: `${process.env.API_USER_MANAGEMENT}/api/merge_user_info`,
};

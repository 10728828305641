import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { CheckoutOrder, KernelCheckoutOrder } from './ordersService.model';

export const getOrderByNumber = async (orderNumber: string): Promise<CheckoutOrder> => {
	const response = await axiosAuth.get<KernelCheckoutOrder>(
		`${apiEndpoints.checkout}/orders/${orderNumber}`
	);

	if (response.status >= 400) {
		throw response;
	}

	return {
		...(response.data as CheckoutOrder),
		comments:
			typeof response.data.comments === 'string'
				? [
						{
							text: response.data.comments,
							timestamp: '',
							kind: 'unknown',
						},
				  ]
				: response.data.comments,
	};
};

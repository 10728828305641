import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { AuctionStatus } from './auctionsService.model';

export const updateAuctionStatus = async ({
	auctionId,
	status,
}: {
	auctionId: string;
	status: AuctionStatus;
}) => {
	const params = { id: auctionId, status };
	const response = await axiosAuth.post(`${config.api.auctions}/status`, params);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

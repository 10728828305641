import { noop } from 'lodash';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import ActionModal from '../../../ActionModal';
import { showErrorToast, showSuccessToast } from '../../../Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import useLotsForAuction from '../../../../queries/useLotsForAuction';
import { deleteLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';

const DeleteLotModal = ({ lot, onClose }: { lot: KernelLot; onClose: () => void }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const {
		data: lotsForThisAuction = [] as KernelLot[],
		removeLotsFromCache: removeLotsFromAuctionCache,
	} = useLotsForAuction({
		auctionId: lot?.auction_id,
	});
	const indexOfThisLot = lotsForThisAuction.findIndex(({ lot_id }) => lot_id === lot.lot_id);
	const nextLot =
		indexOfThisLot === 0 ? lotsForThisAuction[1] : lotsForThisAuction[indexOfThisLot - 1];
	const nextLotId = nextLot?.lot_id || '';

	const { mutate: doDeleteLot, status: deleteLotStatus } = useMutation({
		mutationKey: [QueryKeys.DeleteLots, lot.lot_id],
		mutationFn: (lotId: string) => deleteLot(lotId),
	});
	const handleDeleteLot = () =>
		doDeleteLot(lot.lot_id, {
			onSuccess: () => {
				const nextLocation = pathname.includes('inventory-manager')
					? `/auctions/${lot.auction_id || 'Unassigned'}/inventory-manager/${nextLotId}`
					: `/auctions/auctionmanager/${lot.auction_id}`;

				showSuccessToast(`Successfully deleted ${lot.title}.`);
				removeLotsFromAuctionCache([lot.lot_id]);
				history.push(nextLocation);
				onClose();
			},
			onError: () => {
				showErrorToast(`Something went wrong deleting ${lot.title}. Please try again later.`);
			},
		});

	return (
		<ActionModal
			title={`Deleting lot "${lot.title}"`}
			onClose={onClose}
			onConfirm={handleDeleteLot}
			confirmButtonLabel="Delete Forever"
			isDangerous={true}
			isLoading={deleteLotStatus === 'loading'}
		>
			{`Are you sure you want to delete this lot? You won't be able to recover it.`}
		</ActionModal>
	);
};

export default DeleteLotModal;

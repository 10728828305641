import React, { FC } from 'react';
import Layout from '../Layout';

const UnauthedLayout: FC = ({ children }) => {
	return (
		<Layout isAuthenticated={false}>
			<div className="flex h-full w-full items-center justify-center">
				<div className="mb-20 w-96 rounded-xl bg-neutral1 p-8">{children}</div>
			</div>
		</Layout>
	);
};

export default UnauthedLayout;

import { DefinitionListRow } from './DefinitionList.model';

const DefinitionList = ({ rows }: { rows: DefinitionListRow[] }) => {
	return (
		<dl className="text-body1 text-neutral2">
			{rows.map(({ title, content }, index) => (
				<div
					className={`flex justify-between items-center gap-4 w-full py-4 ${
						index === rows.length - 1 ? '' : 'border-b-light'
					}`}
					key={title}
				>
					<dt className="whitespace-nowrap mr-4">{title}</dt>
					<dd className="text-right font-semibold">{content}</dd>
				</div>
			))}
		</dl>
	);
};

export default DefinitionList;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelCashAccountEntry } from './cashAccountsService.model';

export const getPendingCashAccountEntries = async (userId: string) => {
	const response = await axiosAuth.get<KernelCashAccountEntry[]>(
		`${apiEndpoints.cash_accounts}/${userId}/pending_proceeds`
	);

	if (response.status === 404) {
		return [] as KernelCashAccountEntry[];
	}

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

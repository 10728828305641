import React, { FC, useRef } from 'react';
import { SortableTableParams, VerticalAlignmentValue } from './SortableTable.model';
import useTable from './useReactTable';
import Pagination from './Pagination';
import { TableContextProvider } from './useTableContext';
import { Table } from '@tanstack/react-table';
import LoadingWrapper from '../LoadingWrapper';
import { noop } from 'lodash';
import GlobalFilter from './GlobalFilter';
import DefaultTable from './DefaultTable';

const SortableTable = <T extends unknown>({
	customTableComponent,
	globalFilterPlaceholder = 'Search ...',
	isLoading = false,
	noResultsMessage = 'No results to show.',
	tableParams,
	totalItems,
	verticalAlignment = 'middle',
	withGlobalFilter = true,
	withPagination = true,
	onChangeSearchQuery = noop,
}: {
	customTableComponent?: FC;
	globalFilterPlaceholder?: string;
	isLoading?: boolean;
	noResultsMessage?: string;
	tableParams: SortableTableParams<T>;
	totalItems?: number; // Only used for manual pagination
	verticalAlignment?: VerticalAlignmentValue;
	withGlobalFilter?: boolean;
	withPagination?: boolean;
	onChangeSearchQuery?: (newQuery: string) => void;
}) => {
	const table = useTable<T>(tableParams);

	const tableContainerRef = useRef<HTMLDivElement>(null);

	const { rows } = table.getRowModel();
	/** A custom table component lets you use all the groovy stuff from React Table and our GlobalFilter/Pagintation,
	 *  but without the constraints of an automated table layout.
	 */
	const Table = customTableComponent || DefaultTable;

	return (
		<TableContextProvider value={table as Table<unknown>}>
			<div className="border-base rounded-lg bg-neutral1">
				{withGlobalFilter && (
					<GlobalFilter
						placeholder={globalFilterPlaceholder}
						onChangeSearchQuery={onChangeSearchQuery}
					/>
				)}
				<div
					className={`min-h-24
						${isLoading ? 'flex flex-col items-center justify-center' : ''}
						${withGlobalFilter ? 'border-t-base' : ''}
					`}
				>
					<LoadingWrapper queryStatuses={isLoading ? ['loading'] : []}>
						<div ref={tableContainerRef}>
							{rows.length > 0 ? (
								<>
									<Table verticalAlignment={verticalAlignment} />
									{withPagination && <Pagination totalItems={totalItems} />}
								</>
							) : (
								<div className="flex h-24 w-full items-center justify-center rounded-lg text-subtitle1">
									{noResultsMessage}
								</div>
							)}
						</div>
					</LoadingWrapper>
				</div>
			</div>
		</TableContextProvider>
	);
};

export default SortableTable;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { unregister } from './registerServiceWorker';
import './assets/base.css';
import './assets/tailwind.css';
import './assets/toast.css';
import './assets/material.css';
import './assets/collectors.css';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { Amplify } from 'aws-amplify';
import config from './config';
import { sentryInit } from './sentry';
import App from './App';
import * as awsUtils from './AwsUtils';
import MainSwitch from './routing/MainSwitch';

const container = document.getElementById('root');
const root = createRoot(container);

Amplify.configure({
	Auth: {
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
	},
	Storage: {
		bucket: config.aws_sdk.ADMIN_COMPRESSED_BUCKET,
		region: config.aws_sdk.REGION,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
	},
});

awsUtils.configureAWS().catch((e) => {
	console.error('AWS credential configuration failed with: ', e);
});

sentryInit();

const renderApp = (Component) => {
	root.render(
		<App>
			<Component />
		</App>
	);
};

renderApp(MainSwitch);

if (module.hot) {
	module.hot.accept('./routing/MainSwitch/index.ts', () => {
		renderApp(MainSwitch);
	});
}
unregister();

import React, { FC, useEffect, useState } from 'react';
import useCurrentUser from '../../queries/useCurrentUser';
import { CurrentUser } from '../../services/userService/userService.model';
import {
	createInstance,
	OptimizelyProvider as BaseOptimizelyProvider,
} from '@optimizely/react-sdk';
import config from '../../config';
import LoadingWrapper from '../../components/LoadingWrapper';
import { showErrorToast } from '../../components/Toast';
import { AdBlockerContextProvider } from '../useAdBlockerContext';

const optimizelyClient = createInstance({ sdkKey: config.optimizely.SDK_KEY });
const isOptimizelyClientValid = () => optimizelyClient.getOptimizelyConfig() !== null;

const OptimizelyProvider: FC = ({ children }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();
	const { currentUserId = '' } = currentUser;

	const [isOptimizelyReadyPromiseFulfilled, setIsOptimizelyReadyPromiseFulfilled] = useState(false);

	useEffect(() => {
		optimizelyClient.onReady().then(() => setIsOptimizelyReadyPromiseFulfilled(true));
	}, []);

	const isAdBlocked = isOptimizelyReadyPromiseFulfilled && !isOptimizelyClientValid();

	useEffect(() => {
		if (isAdBlocked) {
			showErrorToast(
				'Some features may be unavailable due to your ad-blocking settings. Please enable in your browser.'
			);
		}
	}, [isOptimizelyReadyPromiseFulfilled]);

	return (
		<BaseOptimizelyProvider user={{ id: currentUserId }} optimizely={optimizelyClient}>
			<AdBlockerContextProvider value={{ isAdBlocked }}>
				<LoadingWrapper
					queryStatuses={isOptimizelyReadyPromiseFulfilled ? ['success'] : ['loading']}
				>
					{children}
				</LoadingWrapper>
			</AdBlockerContextProvider>
		</BaseOptimizelyProvider>
	);
};

export default OptimizelyProvider;

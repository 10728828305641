import React from 'react';
import { ButtonHTMLAttributes } from 'react';
import { Spinner } from 'reactstrap';
import useUserRole from '../../queries/useUserRole';
import { UserRoleName } from '../../services/userService/userService.model';

export type ButtonKind = 'primary' | 'secondary' | 'tertiary' | 'danger';
export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	allowedRoles?: UserRoleName[];
	isInline?: boolean;
	isLoading?: boolean;
	kind: ButtonKind;
	size?: ButtonSize;
};

const baseButtonStyles = 'rounded-lg font-medium';

const textSylesBySize: { [K in ButtonSize]: string } = {
	small: 'text-md',
	medium: 'text-md',
	large: 'text-lg',
};
const paddingSylesBySize: { [K in ButtonSize]: string } = {
	small: 'py-0 px-2',
	medium: 'py-2 px-4',
	large: 'py-4 px-8',
};

const stylesByKind: { [K in ButtonKind]: string } = {
	primary: 'border-base bg-brand-500 text-white min-w-37',
	secondary: 'border-base min-w-37',
	tertiary: 'text-primary-500',
	danger: 'bg-error2 text-white min-w-37',
};

const PRIMARY_SECONDARY_DISABLED_STYLE =
	'border-base cursor-not-allowed bg-neutral2 text-disabled min-w-37';
const TERTIARY_DISABLED_STYLE = 'cursor-not-allowed text-disabled';

const Button = ({
	allowedRoles = [],
	children,
	className = '',
	disabled,
	isInline = false,
	isLoading = false,
	kind,
	size = 'medium',
	...buttonHtmlProps
}: ButtonProps) => {
	const { data: userRole = 'Unknown' } = useUserRole();
	const isForbidden = allowedRoles.length > 0 ? !allowedRoles.includes(userRole) : false;

	const disabledStyle =
		kind === 'tertiary' ? TERTIARY_DISABLED_STYLE : PRIMARY_SECONDARY_DISABLED_STYLE;

	const buttonColorStyles = disabled ? disabledStyle : stylesByKind[kind];

	const fallbackContent = typeof children === 'string' ? children : '';

	return (
		<button
			{...buttonHtmlProps}
			disabled={isForbidden || disabled || isLoading}
			name={buttonHtmlProps.name || fallbackContent}
			title={buttonHtmlProps.title || fallbackContent}
			className={`${baseButtonStyles} ${buttonColorStyles} ${textSylesBySize[size]} ${
				isInline ? 'p-0' : paddingSylesBySize[size]
			} ${className}`}
		>
			<div className="flex items-center justify-center">
				{children}
				{isLoading && (
					<div className="ml-2">
						<Spinner className="h-4 w-4" />
					</div>
				)}
			</div>
		</button>
	);
};

export default Button;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	ArrowHookDownLeft24Regular,
	ArrowMaximize24Regular,
	ArrowMinimize24Regular,
	ZoomIn24Regular,
	ZoomOut24Regular,
} from '@fluentui/react-icons';
import DEFAULT_CARD_IMAGE_LG from '../../../../../assets/images/placeholder-card-lg.png';
import Button from '../../../../../components/Button';

const CardImage = ({ imageFront, imageBack }: { imageFront?: string; imageBack?: string }) => {
	const [imageFlipped, setImageFlipped] = useState(false);
	const [imageMaximized, setImageMaximized] = useState(false);
	const canFlipImages = imageFront && imageBack;
	const zoomLevelMin = 1;

	const imageSettings = useRef({
		zoomLevelMax: 2, //always allowing at least 1 zoom, even if it makes image blurry, for now
		zoomLevelCurrent: 1,
		xDragStart: 0,
		yDragStart: 0,
		xDragAmount: 0,
		yDragAmount: 0,
		xDragCurrent: 0,
		yDragCurrent: 0,
	});

	const calculateTransform = () => {
		return {
			transform: `scale(${imageSettings.current.zoomLevelCurrent}) translate(${
				imageSettings.current.xDragAmount / imageSettings.current.zoomLevelCurrent
			}px, ${imageSettings.current.yDragAmount / imageSettings.current.zoomLevelCurrent}px)`,
			cursor: imageSettings.current.zoomLevelCurrent > zoomLevelMin ? 'move' : 'auto',
		};
	};

	const [imageTransform, setImageTransform] = useState(calculateTransform);

	const zoomOut = () => {
		if (imageSettings.current.zoomLevelCurrent > zoomLevelMin) {
			imageSettings.current.zoomLevelCurrent = imageSettings.current.zoomLevelCurrent - 1;
		}

		if (imageSettings.current.zoomLevelCurrent === zoomLevelMin) {
			imageSettings.current.xDragAmount = 0;
			imageSettings.current.yDragAmount = 0;
			imageSettings.current.xDragCurrent = 0;
			imageSettings.current.yDragCurrent = 0;
		}

		setImageTransform(calculateTransform);
	};

	const zoomIn = () => {
		if (imageSettings.current.zoomLevelCurrent < imageSettings.current.zoomLevelMax) {
			imageSettings.current.zoomLevelCurrent = imageSettings.current.zoomLevelCurrent + 1;
		}

		setImageTransform(calculateTransform);
	};

	//calculate max zoom, maybe not be needed if we request images at a specific width (ie. ?w=1200)
	const imageFrontRef = useRef<HTMLImageElement>(null);
	const imageBackRef = useRef<HTMLImageElement>(null);

	const zoomLevelMaxCalc = (img: HTMLImageElement) => {
		const result = Math.floor(img.naturalWidth / img.offsetWidth);

		if (result > imageSettings.current.zoomLevelMax) {
			imageSettings.current.zoomLevelMax = result;
		}
	};

	useEffect(() => {
		if (imageFrontRef.current?.complete) {
			zoomLevelMaxCalc(imageFrontRef.current);
		}

		if (imageBackRef.current?.complete) {
			zoomLevelMaxCalc(imageBackRef.current);
		}
	}, [imageFrontRef, imageBackRef]);

	//dragging when zoomed
	const onDragStart = (e: any) => {
		if (imageSettings.current.zoomLevelCurrent <= zoomLevelMin) {
			return;
		}

		let x = 0;
		let y = 0;

		if (e.clientX) {
			x = e.clientX;
			y = e.clientY;
			window.addEventListener('mousemove', onDragMove, true);
			e.stopPropagation();
			e.preventDefault();
		} else {
			x = e.targetTouches[0].clientX;
			y = e.targetTouches[0].clientY;
			window.addEventListener('touchmove', onDragMove, true);
		}

		imageSettings.current.xDragStart = x;
		imageSettings.current.yDragStart = y;
	};

	const onDragMove = useCallback((e: any) => {
		let x = 0;
		let y = 0;

		if (e.clientX) {
			x = e.clientX;
			y = e.clientY;
			e.stopPropagation();
			e.preventDefault();
		} else {
			x = e.targetTouches[0].clientX;
			y = e.targetTouches[0].clientY;
		}

		imageSettings.current.xDragAmount =
			x - imageSettings.current.xDragStart + imageSettings.current.xDragCurrent;

		imageSettings.current.yDragAmount =
			y - imageSettings.current.yDragStart + imageSettings.current.yDragCurrent;

		setImageTransform(calculateTransform);
	}, []);

	const onDragEnd = (e: any) => {
		imageSettings.current.xDragCurrent = imageSettings.current.xDragAmount;
		imageSettings.current.yDragCurrent = imageSettings.current.yDragAmount;

		if (e.clientX) {
			window.removeEventListener('mousemove', onDragMove, true);
		} else {
			window.removeEventListener('touchmove', onDragMove, true);
		}
	};

	const catchKeyboard = useCallback((e) => {
		if (e.code === 'Escape') {
			setImageMaximized(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', catchKeyboard);

		return () => {
			document.removeEventListener('keydown', catchKeyboard);
		};
	}, [catchKeyboard]);

	const buttonWrapperClasses = `inline-block ${
		imageMaximized ? 'bg-disableddark' : 'bg-neutral2'
	} rounded-2.5x1`;

	return !imageFront && !imageBack ? (
		<img
			src={DEFAULT_CARD_IMAGE_LG}
			width="300"
			height="533"
			alt="placeholder"
			className="mx-auto lg:my-9"
		/>
	) : (
		<div
			className={
				imageMaximized
					? 'fixed inset-0 z-10 flex items-center justify-center overflow-hidden bg-neutral2dark'
					: 'relative'
			}
		>
			<div>
				<div
					className={`
						relative mx-auto max-w-[300px] touch-none
						${imageFlipped ? 'flipped' : ''}
						${canFlipImages ? 'flip' : ''}
          			`}
					style={imageTransform}
					onMouseDown={onDragStart}
					onTouchStart={onDragStart}
					onMouseUp={onDragEnd}
					onMouseOut={onDragEnd}
					onTouchEnd={onDragEnd}
				>
					{imageFront && (
						<img
							src={imageFront}
							alt="front"
							className={`${canFlipImages ? 'flip-side flip-side-front' : ''} mx-auto`}
							loading="lazy"
							ref={imageFrontRef}
						/>
					)}

					{imageBack && (
						<img
							src={imageBack}
							alt="back"
							className={`${canFlipImages ? 'flip-side flip-side-back' : ''} mx-auto`}
							loading="lazy"
							ref={imageBackRef}
						/>
					)}
				</div>

				<div
					className={`print:hidden ${buttonWrapperClasses}
          ${
						imageMaximized
							? 'fixed top-0 left-0 w-full bg-neutral2dark/80 p-4 backdrop-blur-lg'
							: 'relative mt-8'
					}
        `}
				>
					<Button title="zoom in" onClick={zoomIn} kind="tertiary">
						<ZoomIn24Regular />
					</Button>
					<Button title="zoom out" onClick={zoomOut} kind="tertiary">
						<ZoomOut24Regular />
					</Button>

					{canFlipImages && (
						<div className={buttonWrapperClasses}>
							<Button
								kind="tertiary"
								title="flip image between back and front"
								onClick={() => setImageFlipped(!imageFlipped)}
							>
								<ArrowHookDownLeft24Regular />
							</Button>
						</div>
					)}

					<div className={buttonWrapperClasses}>
						<Button kind="tertiary" onClick={() => setImageMaximized(!imageMaximized)}>
							{imageMaximized ? <ArrowMinimize24Regular /> : <ArrowMaximize24Regular />}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardImage;

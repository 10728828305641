import useImageUrl from '../../../../../../../queries/useImageUrl';
import { RemovedLot } from '../../../../../../../services/lotsService/lotsService.model';
import blankLotImage from '../../../../../../../assets/images/placeholder-card-xs.png';
import { VanillaLineItem } from '../../OrderSummary/LineItem';
import { Link } from 'react-router-dom';

export const RemovedLotItem = ({ lot }: { lot: RemovedLot }) => {
	const { data: auctionImageUrl = '' } = useImageUrl({
		storageProvider: 'cloudfront',
		itemId: lot.primaryImageName || '',
		rootPath: 'Lots',
		parentPath: lot.lotId,
		imageSize: '@1x',
	});

	const imageUrl =
		lot.auctionId === 'fixed_price_marketplace' ? lot.primaryImageName : auctionImageUrl;

	return (
		<div className="mb-4 flex w-full items-start">
			<div className="flex pt-1">
				{imageUrl ? (
					<img className="w-8 rounded-md object-contain" src={imageUrl} />
				) : (
					<img className="w-8 object-contain" src={blankLotImage} />
				)}
			</div>
			<div className="w-full">
				<VanillaLineItem kind="primary" amount={lot.finalPrice}>
					<div className="mr-8">
						<Link
							className="link"
							to={
								lot.auctionId === 'fixed_price_marketplace'
									? `/fixed-price/listings/${lot.lotId}`
									: `/inventory/listings/${lot.lotId}`
							}
						>
							{lot.title} (Serial Number {lot.serialNumber} - Lot {lot.lotNumber})
						</Link>
					</div>
				</VanillaLineItem>
				<VanillaLineItem
					amount={lot.buyerPremium}
					kind="secondary"
				>{`Buyer's Premium`}</VanillaLineItem>
			</div>
		</div>
	);
};

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

// Based on https://stackoverflow.com/a/37616104
// Add filtering to Objects -- var filtered = Object.filter(scores, score => score > 1);
Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter((key) => predicate(obj[key]))
		.reduce((res, key) => ((res[key] = obj[key]), res), {});

export const LightBlueButton = withStyles((theme) => ({
	root: {
		color: theme.palette.getContrastText('#000'),
		backgroundColor: lightBlue[700],
		'&:hover': {
			//color: theme.palette.getContrastText("#fff"),
			backgroundColor: lightBlue[400],
		},
	},
}))(Button);

// props options:
// - alertIsOpen -- should the dialog be open
// - dialogTitle -- the title of the deletion dialog
// - dialogBody -- the body of the deletion dialog
// - closeCallback -- a function to call with a bool about whether or not the user responded with delete
export function DeleteDialog(props) {
	const handleClose = (shouldDelete) => {
		if (props.closeCallback) props.closeCallback(shouldDelete && shouldDelete == true);
	};

	return (
		<div>
			<Dialog
				key={uuidv4()}
				open={Boolean(props.alertIsOpen) || false}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{props.dialogTitle || ''}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.dialogBody || ''}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(true)} color="secondary" variant="contained">
						{props.deleteText || 'Delete Forever'}
					</Button>
					<Button onClick={() => handleClose(false)} autoFocus>
						{props.cancelText || 'Cancel'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

// Returns -1 if there's no match; otherwise, returns the first match
export function getValueIndex(array, key, val) {
	if (!array || !array.length || !key || key.length === 0) return -1;

	return array.findIndex((item) => item[key] === val);
}

export function CompareObjectsByKey(a, b, key) {
	if (!a || !b || !key || key.length === 0 || !a[key] || !b[key]) return 0;

	const aVal = a[key];
	const bVal = b[key];

	let comparison = 0;
	if (aVal > bVal) {
		comparison = 1;
	} else if (aVal < bVal) {
		comparison = -1;
	}
	return comparison;
}

export function objNot(a, b, key) {
	if (!a || !key || key.length === 0) return [];
	else if (!b) return a;

	return a.filter((aVal) => b.findIndex((bVal) => bVal[key] === aVal[key]) === -1);
}

export function objIntersection(a, b, key) {
	if (!a || !b || !key || key.length === 0) return [];

	return a.filter((aVal) => b.findIndex((bVal) => bVal[key] === aVal[key]) !== -1);
}

export function objUnion(a, b, key) {
	if (!key || key.length === 0) return [];

	if (!a) return b;
	else if (!b) return a;

	return [...a, ...objNot(b, a, key)];
}

export function StripHtml(str) {
	if (!str) return str;

	var div = document.createElement('div');
	div.innerHTML = str;
	return div.textContent || div.innerText || '';
}

import { createContext, useContext } from 'react';

export interface EditSummaryContextValue {
	isEditing: boolean;
}

const editSummaryContext = createContext({ isEditing: false });

export const EditSummaryContextProvider = editSummaryContext.Provider;

export const useEditSummaryContext = () => useContext(editSummaryContext);

import Carousel from '../../../../../components/Carousel';
import { useImageUrls } from '../../../../../queries/useImageUrl';
import { useManageBannersContext } from '../ManageBanners/useManageBannersState';
import { getTestIdentifier } from '../../../../../utils/test-identifiers/getTestIdentifier';

const CarouselPreview = () => {
	const {
		manageBannersState: { sortedBanners },
	} = useManageBannersContext();

	const imageUrlResults = useImageUrls({
		storageProvider: 's3',
		rootPath: 'CarouselImages',
		parentPath: 'home',
		itemIds: sortedBanners.map(({ id }) => id),
		isEnabled: sortedBanners.length > 0,
	});

	const imageUrls = imageUrlResults.map(({ data }) => data);

	return (
		<div
			data-testid={getTestIdentifier({
				componentName: 'CarouselPreview',
				descriptor: 'carousel',
				pagePath: 'carousel',
				sectionPath: 'customize',
			})}
		>
			<h2 className="mb-4 text-display4">Carousel Preview</h2>
			{sortedBanners.length > 0 ? (
				<Carousel
					key={imageUrls.join('-')}
					items={imageUrls.map((imageUrl, index) => ({
						img: imageUrl,
						title: sortedBanners[index].title,
					}))}
					interval={5000}
				/>
			) : (
				<div className="selection-list-no-items"> No carousel images uploaded.</div>
			)}
		</div>
	);
};

export default CarouselPreview;

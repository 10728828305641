import { axiosAuth as axios } from './httpUtils';
import config from '../config';

const reportTypeMapping = {
	accountPaymentsReport: 'account_payments_report',
	accountReceivableReport: 'account_receivable_report',
	bidReport: 'bid_report',
	buyerReport: 'buyer_report',
	cashAccountReport: 'cash_account_report',
	sellerReport: 'seller_report',
	statementReport: 'statement_report',
};

export function isAccountReportType(reportType) {
	return (
		reportType === 'accountPaymentsReport' ||
		reportType === 'accountReceivableReport' ||
		reportType === 'cashAccountReport' ||
		reportType === 'statementReport'
	);
}

export async function sendReportRequest(reportType, auctionId, email, extras) {
	try {
		const extraKeyMap = {
			dateFrom: 'date_from',
			dateTo: 'date_to',
			dueDateFrom: 'due_date_from',
			dueDateTo: 'due_date_to',
			chargeDateFrom: 'charge_date_from',
			chargeDateTo: 'charge_date_to',
			operationType: 'operation_type',
		};
		const report = reportTypeMapping[reportType];
		const content = {
			auction_id: auctionId,
			report_type: report,
			email: email,
		};
		for (var key in extras) {
			if (extras.hasOwnProperty(key)) {
				content[extraKeyMap[key]] = extras[key];
			}
		}

		const response = await axios.post(`${config.api.reports}/generate_report`, content);

		return response.data;
	} catch (err) {
		console.log(`Request to report api failed: ${err}`);
	}
}

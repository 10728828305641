import { ColumnDef } from '@tanstack/react-table';
import Card from '../../../components/Card';
import LoadingWrapper from '../../../components/LoadingWrapper';
import SortableTable from '../../../components/SortableTable';
import UserStatusChip from '../../../components/statusChips/UserStatusChip/UserStatusChip';
import { SortableTableParams } from '../../../components/SortableTable/SortableTable.model';
import { DraftEmployee, Employee } from '../../../services/userService/userService.model';
import useEmployees from '../../../queries/useEmployees';
import Button from '../../../components/Button';
import { useState } from 'react';
import { CreateEmployeeModal } from './CreateEmployeeModal';
import { Alert } from '../../../prizm-ui/Alert';
import useDecision from '../../../optimizely/useDecision/useDecision';

const EmployeeManagerSearch = () => {
	const { data: employees = [] as Employee[], status: employeesStatus } = useEmployees();

	const [isCreateEmployeeModalOpen, setIsCreateEmployeeModalOpen] = useState(false);
	const [newlyCreatedEmployee, setNewlyCreatedEmployee] = useState<DraftEmployee>();

	const columns: ColumnDef<Employee>[] = [
		{
			accessorKey: 'givenName',
			header: 'First Name',
		},
		{
			accessorKey: 'familyName',
			header: 'Last Name',
		},
		{
			accessorKey: 'email',
			header: 'Email',
		},
		{
			accessorKey: 'role',
			header: 'Role',
		},
		{
			header: 'Status',
			accessorFn: ({ status }) => (status ? 'Active' : 'Disabled'),
			cell: ({
				row: {
					original: { status },
				},
			}) => <UserStatusChip status={status} />,
		},
		{
			accessorKey: 'id',
			header: 'ID',
		},
	];

	const sortableTableParams: SortableTableParams<Employee> = {
		columns,
		data: employees,
		getRowId: ({ id }) => id,
		initialState: {
			sorting: [
				{
					id: 'role',
					desc: false,
				},
			],
			pagination: {
				pageIndex: 0,
				pageSize: 100,
			},
		},
	};

	const isCreateEmployeeModalEnabled = useDecision('create-employee');

	return (
		<Card allowedRoles={['Admin', 'CustomerSupport']}>
			{newlyCreatedEmployee && (
				<div className="mb-4">
					<Alert
						variant="success"
						title={`Successfully created new ${newlyCreatedEmployee.role} employee for ${newlyCreatedEmployee.givenName} ${newlyCreatedEmployee.familyName}.`}
						description={`The user can login with ${newlyCreatedEmployee.email} and temporary password ${newlyCreatedEmployee.password}. They will have to change this after logging in the first time.`}
					/>
				</div>
			)}
			<div className="flex justify-between items-center">
				<h1 className="text-display4 mb-8">Employee Manager</h1>
				{isCreateEmployeeModalEnabled && (
					<>
						<Button
							kind="primary"
							onClick={() => setIsCreateEmployeeModalOpen(true)}
							allowedRoles={['Admin']}
						>
							Create Employee
						</Button>
						{isCreateEmployeeModalOpen && (
							<CreateEmployeeModal
								onClose={() => setIsCreateEmployeeModalOpen(false)}
								onSuccess={(employee: DraftEmployee) => setNewlyCreatedEmployee(employee)}
							/>
						)}
					</>
				)}
			</div>
			<LoadingWrapper queryStatuses={[employeesStatus]}>
				<SortableTable
					globalFilterPlaceholder="Filter by name, email, role, or ID ..."
					noResultsMessage="No employees to show"
					tableParams={sortableTableParams}
				/>
			</LoadingWrapper>
		</Card>
	);
};

export default EmployeeManagerSearch;

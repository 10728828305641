import { Row } from '@tanstack/react-table';
import { KernelSummaryLot } from '../../../services/lotsService/lotsService.model';
import { useTable } from '../../SortableTable/useTableContext';
import LotSelectorHeaders from './LotSelectorHeaders';
import LotSelectorRow from './LotSelectorRow';

const LotSelectorLots = () => {
	const { getRowModel } = useTable();
	const { rows } = getRowModel();

	return (
		<>
			<div className="border-b-base grid grid-cols-12 gap-4">
				<LotSelectorHeaders />
			</div>
			<div className="max-h-96 overflow-y-auto">
				{rows.map((thisRow, index) => {
					const { id: thisLotId } = thisRow;

					return (
						<LotSelectorRow row={thisRow as Row<KernelSummaryLot>} index={index} key={thisLotId} />
					);
				})}
			</div>
		</>
	);
};

export default LotSelectorLots;

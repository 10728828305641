import { postAuthed } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import {
	KernelMergeUserInfoResponseData,
	MergeUserInfoResponse,
	MergeUserInfoResponseData,
} from './mergeUserInfo.model';
import { mapKernelMergeUserInfoResponseDataToMergeUserInfoResponseData } from './mergeUserInfo.utils';

export const mergeUserInfo = async ({
	userIdFrom,
	userIdTo,
}: {
	userIdFrom: string;
	userIdTo: string;
}): Promise<MergeUserInfoResponseData> => {
	const body = {
		user_id_merge_from: userIdFrom,
		user_id_merge_to: userIdTo,
	};

	const response: MergeUserInfoResponse = await postAuthed(
		`${apiEndpoints.user_merge_userinfo}`,
		body
	);

	if (response.data.statusCode >= 400) {
		throw response;
	}

	return mapKernelMergeUserInfoResponseDataToMergeUserInfoResponseData(
		response.data.body as KernelMergeUserInfoResponseData
	);
};

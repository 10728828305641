export interface Address {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postalCode: string;
	state: string;
}

export interface UserAccountNote {
	author?: {
		authorDisplayName: string | 'Unknown';
		authorId: string;
	};
	text: string;
	timestamp: string;
}

export interface BaseUser {
	email: string;
	familyName: string;
	givenName: string;
	id: string;
	phoneNumber?: string;
	status: boolean;
}

export interface Employee extends BaseUser {
	role: UserRoleName;
}

export type DraftEmployee = Omit<Employee, 'id' | 'status'> & { password: string };

export interface CurrentUser {
	currentUserId: string;
	currentUserEmail: string;
	currentUserFullName: string;
	currentUserRoleName: UserRoleName;
}

export interface KernelAddress {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postal_code: string;
	state: string;
}

export interface KernelPaymentAddress {
	city: string;
	country: string;
	line_1: string;
	line_2: string;
	postal_code: string;
	state: string;
}

export interface KernelUser {
	bidding_power: number;
	commission?: number | string;
	company_id: string;
	consignment_manager_id: string;
	credit_limit: number;
	disabled: boolean;
	email: string;
	family_name: string;
	given_name: string;
	has_us_address: boolean;
	is_vip?: boolean;
	latest_version: number;
	notes: string;
	payment_address: KernelPaymentAddress;
	phone_number: string;
	registration_date: string;
	send_payment_reminder?: boolean;
	shipping_address_city: string;
	shipping_address_country: string;
	shipping_address_line1: string;
	shipping_address_line2: string;
	shipping_address_postal_code: string;
	shipping_address_state: string;
	stripe_customer_id: string;
	user_id: string;
	verified_tin: boolean;
	goldin_auth_version?: string;
	cid_migrated_on?: string;
}

export interface User extends BaseUser {
	biddingPower: number;
	commission?: number | string;
	companyId: string;
	consignmentManagerId: string;
	creditLimit: number;
	isDisabled: boolean;
	isVip: boolean;
	hasUSAddress: boolean;
	verifiedTin: boolean;
	latestVersion: number;
	notes: string;
	paymentAddress?: Address;
	phoneNumber: string;
	registrationDate: string;
	sendPaymentReminder?: boolean;
	shippingAddress: Address;
	stripeCustomerId: string;
	goldinAuthVersion?: number;
	cidMigratedOn?: string;
}

export enum UserRoles {
	Admin = 'Admin',
	AuctionManager = 'AuctionManager',
	Consignment = 'Consignment',
	CustomerSupport = 'CustomerSupport',
	Editorial = 'Editorial',
	Finance = 'Finance',
	Inventory = 'Inventory',
	Photography = 'Photography',
	Shipping = 'Shipping',
	Unknown = 'Unknown',
}

export type UserRoleName =
	| 'Admin'
	| 'Shipping'
	| 'AuctionManager'
	| 'Finance'
	| 'Inventory'
	| 'CustomerSupport'
	| 'Photography'
	| 'Editorial'
	| 'Consignment'
	| 'Unknown';

export const UserRole: { [key: string]: UserRoleName } = {
	Admin: 'Admin',
	AuctionManager: 'AuctionManager',
	Consignment: 'Consignment',
	CustomerSupport: 'CustomerSupport',
	Editorial: 'Editorial',
	Finance: 'Finance',
	Inventory: 'Inventory',
	Photography: 'Photography',
	Shipping: 'Shipping',
};

export const BLANK_BASE_USER: BaseUser = {
	email: '',
	familyName: '',
	givenName: '',
	id: '',
	phoneNumber: '',
};

export const EDITOR_ROLES: UserRoleName[] = [
	UserRoles.Admin,
	UserRoles.Editorial,
	UserRoles.Finance,
	UserRoles.Inventory,
];

export const PHOTO_EDITOR_ROLES: UserRoleName[] = [
	'Admin',
	'Editorial',
	'Inventory',
	'Photography',
];

import { useReducer } from 'react';
import createSortByColumnReducer from './sortByColumnReducer';
import { SortableColumnStateItem } from './useSortByColumns.model';
import {
	getNextSortingAction,
	sortRows,
	_getSortDirectionForColumn,
} from './useSortByColumns.utils';

const useSortByColumns = <T>(unsortedRows: T[]) => {
	const [sortingState, dispatch] = useReducer(
		createSortByColumnReducer<T>(),
		[] as SortableColumnStateItem<T>[]
	);

	const performNextSort = (columnName: keyof T) => {
		dispatch(getNextSortingAction<T>({ sortingState, columnName }));
	};

	const sortedRows = sortRows({ rowsToSort: unsortedRows, sortingConfigs: sortingState });
	const getSortDirectionForColumn = (columnName: keyof T) =>
		_getSortDirectionForColumn<T>({
			columnName,
			sortingConfigs: sortingState,
		});

	return { sortedRows, getSortDirectionForColumn, performNextSort };
};

export default useSortByColumns;

import AddFilled from '@iconify-icons/fluent/add-24-filled';
import AddCircle from '@iconify-icons/fluent/add-circle-24-regular';
import Alert from '@iconify-icons/fluent/alert-24-regular';
import AlertFilled from '@iconify-icons/fluent/alert-24-filled';
import AppStore from '@iconify-icons/fluent/app-store-24-regular';
import ArrowCircleDown from '@iconify-icons/fluent/arrow-circle-down-24-regular';
import ArrowForward from '@iconify-icons/fluent/arrow-forward-24-regular';
import ArrowReply from '@iconify-icons/fluent/arrow-reply-24-regular';
import ArrowHookDownLeft from '@iconify-icons/fluent/arrow-hook-down-left-24-regular';
import ArrowLeft from '@iconify-icons/fluent/arrow-left-24-regular';
import ArrowLeftFilled from '@iconify-icons/fluent/arrow-left-24-filled';
import ArrowMaximize from '@iconify-icons/fluent/arrow-maximize-24-regular';
import ArrowMinimize from '@iconify-icons/fluent/arrow-minimize-24-regular';
import ArrowRight from '@iconify-icons/fluent/arrow-right-24-regular';
import ArrowRightFilled from '@iconify-icons/fluent/arrow-right-24-filled';
import ArrowSquareDown from '@iconify-icons/fluent/arrow-square-down-24-regular';
import ArrowTrending from '@iconify-icons/fluent/arrow-trending-24-regular';
import ArrowTrendingCheckmark from '@iconify-icons/fluent/arrow-trending-checkmark-24-regular';
import ArrowTrendingSettings from '@iconify-icons/fluent/arrow-trending-settings-24-regular';
import BuildingShop from '@iconify-icons/fluent/building-shop-24-regular';
import CalendarLtr from '@iconify-icons/fluent/calendar-ltr-24-regular';
import Call from '@iconify-icons/fluent/call-24-regular';
import CallFilled from '@iconify-icons/fluent/call-24-filled';
import Camera from '@iconify-icons/fluent/camera-24-regular';
import CameraFilled from '@iconify-icons/fluent/camera-24-filled';
import Cart from '@iconify-icons/fluent/cart-24-regular';
import CartFilled from '@iconify-icons/fluent/cart-24-filled';
import CertificateFilled from '@iconify-icons/fluent/certificate-24-filled';
import ChatHelp from '@iconify-icons/fluent/chat-help-24-regular';
import ChatHelpFilled from '@iconify-icons/fluent/chat-help-24-filled';
import Checkmark from '@iconify-icons/fluent/checkmark-24-regular';
import CheckmarkFilled from '@iconify-icons/fluent/checkmark-24-filled';
import CheckboxCheckedFilled from '@iconify-icons/fluent/checkbox-checked-24-filled';
import CheckboxIndeterminate from '@iconify-icons/fluent/checkbox-indeterminate-24-regular';
import CheckboxUnchecked from '@iconify-icons/fluent/checkbox-unchecked-24-regular';
import CheckmarkCircle from '@iconify-icons/fluent/checkmark-circle-24-regular';
import CheckmarkCircleFilled from '@iconify-icons/fluent/checkmark-circle-24-filled';
import CheckmarkStarburst from '@iconify-icons/fluent/checkmark-starburst-24-regular';
import CheckmarkStarburstFilled from '@iconify-icons/fluent/checkmark-starburst-24-filled';
import ChevronDown from '@iconify-icons/fluent/chevron-down-24-regular';
import ChevronUp from '@iconify-icons/fluent/chevron-up-24-regular';
import Circle from '@iconify-icons/fluent/circle-24-regular';
import Clock from '@iconify-icons/fluent/clipboard-clock-24-regular';
import Collections from '@iconify-icons/fluent/collections-24-regular';
import CollectionsFilled from '@iconify-icons/fluent/collections-24-filled';
import CollectionsAdd from '@iconify-icons/fluent/collections-add-24-regular';
import CollectionsAddFilled from '@iconify-icons/fluent/collections-add-24-filled';
import ContactCard from '@iconify-icons/fluent/contact-card-24-regular';
import ContactCardFilled from '@iconify-icons/fluent/contact-card-24-filled';
import Copy from '@iconify-icons/fluent/copy-24-regular';
import Delete from '@iconify-icons/fluent/delete-24-regular';
import DeleteFilled from '@iconify-icons/fluent/delete-24-filled';
import Dismiss from '@iconify-icons/fluent/dismiss-24-regular';
import DismissCircle from '@iconify-icons/fluent/dismiss-circle-24-regular';
import DismissCircleFilled from '@iconify-icons/fluent/dismiss-circle-24-filled';
import Edit from '@iconify-icons/fluent/edit-24-regular';
import EditFilled from '@iconify-icons/fluent/edit-24-filled';
import ErrorCircle from '@iconify-icons/fluent/error-circle-24-regular';
import ErrorCircleFilled from '@iconify-icons/fluent/error-circle-24-filled';
import EyeOff from '@iconify-icons/fluent/eye-off-24-regular';
import Eye from '@iconify-icons/fluent/eye-24-regular';
import Filter from '@iconify-icons/fluent/filter-24-regular';
import Gavel from '@iconify-icons/fluent/gavel-24-regular';
import GavelFilled from '@iconify-icons/fluent/gavel-24-filled';
import Gift from '@iconify-icons/fluent/gift-24-regular';
import GiftFilled from '@iconify-icons/fluent/gift-24-filled';
import Grid from '@iconify-icons/fluent/grid-24-regular';
import Heart from '@iconify-icons/fluent/heart-24-regular';
import History from '@iconify-icons/fluent/history-24-regular';
import ImageEdit from '@iconify-icons/fluent/image-edit-24-regular';
import Image from '@iconify-icons/fluent/image-24-regular';
import Info from '@iconify-icons/fluent/info-24-regular';
import Link from '@iconify-icons/fluent/link-24-regular';
import List from '@iconify-icons/fluent/list-24-regular';
import LockClosed from '@iconify-icons/fluent/lock-closed-24-regular';
import LockClosedFilled from '@iconify-icons/fluent/lock-closed-24-filled';
import LockOpen from '@iconify-icons/fluent/lock-open-24-regular';
import Mail from '@iconify-icons/fluent/mail-24-regular';
import MailFilled from '@iconify-icons/fluent/mail-24-filled';
import MoreHorz from '@iconify-icons/fluent/more-horizontal-24-regular';
import MoreVertical from '@iconify-icons/fluent/more-vertical-24-regular';
import Navigation from '@iconify-icons/fluent/navigation-24-regular';
import OpenFilled from '@iconify-icons/fluent/open-24-filled';
import Options from '@iconify-icons/fluent/options-24-regular';
import QuestionCircle from '@iconify-icons/fluent/question-circle-24-regular';
import Person from '@iconify-icons/fluent/person-24-regular';
import PersonFilled from '@iconify-icons/fluent/person-24-filled';
import Print from '@iconify-icons/fluent/print-24-regular';
import RadioButtonFilled from '@iconify-icons/fluent/radio-button-20-filled';
import Reorder from '@iconify-icons/fluent/re-order-dots-vertical-24-regular';
import Search from '@iconify-icons/fluent/search-24-regular';
import Send from '@iconify-icons/fluent/send-24-regular';
import SendFilled from '@iconify-icons/fluent/send-24-filled';
import Settings from '@iconify-icons/fluent/settings-24-regular';
import ShareIos from '@iconify-icons/fluent/share-ios-24-regular';
import SignatureFilled from '@iconify-icons/fluent/signed-24-filled';
import SignOut from '@iconify-icons/fluent/sign-out-24-regular';
import SignOutFilled from '@iconify-icons/fluent/sign-out-24-filled';
import Sparkle from '@iconify-icons/fluent/sparkle-24-regular';
import Star from '@iconify-icons/fluent/star-24-regular';
import StarFilled from '@iconify-icons/fluent/star-24-filled';
import Tag from '@iconify-icons/fluent/target-24-regular';
import TagFilled from '@iconify-icons/fluent/tag-24-filled';
import TagDismiss from '@iconify-icons/fluent/tag-dismiss-24-regular';
import TagDismissFilled from '@iconify-icons/fluent/tag-dismiss-24-filled';
import ThumbLikeFilled from '@iconify-icons/fluent/thumb-like-24-filled';
import TicketDiagonal from '@iconify-icons/fluent/ticket-diagonal-24-regular';
import TicketDiagonalFilled from '@iconify-icons/fluent/ticket-diagonal-24-filled';
import Vault from '@iconify-icons/fluent/vault-24-regular';
import VaultFilled from '@iconify-icons/fluent/vault-24-filled';
import VehicleTruckProfile from '@iconify-icons/fluent/vehicle-truck-profile-24-regular';
import VehicleTruckProfileFilled from '@iconify-icons/fluent/vehicle-truck-profile-24-filled';
import Wallet from '@iconify-icons/fluent/wallet-24-regular';
import Warning from '@iconify-icons/fluent/warning-24-regular';
import WifiWarning from '@iconify-icons/fluent/wifi-warning-24-regular';
import ZoomIn from '@iconify-icons/fluent/zoom-in-24-regular';
import ZoomOut from '@iconify-icons/fluent/zoom-out-24-regular';

export const ICONS = {
	AddFilled,
	AddCircle,
	Alert,
	AlertFilled,
	AppStore,
	ArrowCircleDown,
	ArrowForward,
	ArrowReply,
	ArrowHookDownLeft,
	ArrowLeft,
	ArrowLeftFilled,
	ArrowMaximize,
	ArrowMinimize,
	ArrowRight,
	ArrowRightFilled,
	ArrowSquareDown,
	ArrowTrending,
	ArrowTrendingCheckmark,
	ArrowTrendingSettings,
	BuildingShop,
	CalendarLtr,
	Call,
	CallFilled,
	Camera,
	CameraFilled,
	Cart,
	CartFilled,
	CertificateFilled,
	ChatHelp,
	ChatHelpFilled,
	Checkmark,
	CheckmarkFilled,
	CheckboxCheckedFilled,
	CheckboxIndeterminate,
	CheckboxUnchecked,
	CheckmarkCircle,
	CheckmarkCircleFilled,
	CheckmarkStarburst,
	CheckmarkStarburstFilled,
	ChevronDown,
	ChevronUp,
	Circle,
	Clock,
	Collections,
	CollectionsFilled,
	CollectionsAdd,
	CollectionsAddFilled,
	ContactCard,
	ContactCardFilled,
	Copy,
	Delete,
	DeleteFilled,
	Dismiss,
	DismissCircle,
	DismissCircleFilled,
	Edit,
	EditFilled,
	ErrorCircle,
	ErrorCircleFilled,
	EyeOff,
	Eye,
	Filter,
	Gavel,
	GavelFilled,
	Gift,
	GiftFilled,
	Grid,
	Heart,
	History,
	ImageEdit,
	Image,
	Info,
	Link,
	List,
	LockClosed,
	LockClosedFilled,
	LockOpen,
	Mail,
	MailFilled,
	MoreHorz,
	MoreVertical,
	Navigation,
	OpenFilled,
	Options,
	QuestionCircle,
	Person,
	PersonFilled,
	Print,
	RadioButtonFilled,
	Reorder,
	Search,
	Send,
	SendFilled,
	Settings,
	ShareIos,
	SignatureFilled,
	SignOut,
	SignOutFilled,
	Sparkle,
	Star,
	StarFilled,
	Tag,
	TagFilled,
	TagDismiss,
	TagDismissFilled,
	ThumbLikeFilled,
	TicketDiagonal,
	TicketDiagonalFilled,
	Vault,
	VaultFilled,
	VehicleTruckProfile,
	VehicleTruckProfileFilled,
	Wallet,
	Warning,
	WifiWarning,
	ZoomIn,
	ZoomOut,
} as const;

export type IconName = keyof typeof ICONS;

export const iconList = Object.keys(ICONS) as IconName[];

import React from 'react';

export const ButtonGroupContext = React.createContext({
	isGroup: false,
});

export const ButtonGroup: React.FC = ({ children }) => {
	return (
		<ButtonGroupContext.Provider value={{ isGroup: true }}>
			<div className="inline-flex h-10 p-0.5 bg-neutralbkg2 rounded-full border border-neutralstroke2 transition-all whitespace-nowrap -space-x-2">
				{children}
			</div>
		</ButtonGroupContext.Provider>
	);
};

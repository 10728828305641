const isJSON = (s: string) => {
	if (typeof s !== 'string') {
		return false;
	}

	try {
		JSON.parse(s);
	} catch (e) {
		return false;
	}

	return true;
};

export default isJSON;

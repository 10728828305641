import React from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useLotsForAuction from '../../../../../queries/useLotsForAuction';
import { deleteLot } from '../../../../../services/lotsService';
import { KernelLot } from '../../../../../services/lotsService/lotsService.model';

const BulkDeleteLotsModal = ({
	onClose,
	bulkSelectedLotIds,
	setIsBulkEditMode,
	setShouldShowBulkDeleteLotsModal,
	setBulkSelectedLotIds,
}: {
	onClose: () => void;
	bulkSelectedLotIds: string[];
	setShouldShowBulkDeleteLotsModal: (nextState: boolean) => void;
	setIsBulkEditMode: (nextState: boolean) => void;
	setBulkSelectedLotIds: (nextState: string[]) => void;
}) => {
	const history = useHistory();

	const { auctionId } = useParams<{ auctionId: string }>();
	const lotWord = bulkSelectedLotIds.length === 1 ? 'lot' : 'lots';

	const {
		data: lotsForAuction = [] as KernelLot[],
		removeLotsFromCache: removeLotsFromAuctionCache,
	} = useLotsForAuction({
		auctionId,
	});

	const deleteSelectedLots = async () =>
		Promise.all(bulkSelectedLotIds.map(async (lotId) => await deleteLot(lotId)));
	const { mutate: doDeleteSelectedLots, status: bulkDeleteLotsStatus } = useMutation({
		mutationKey: [QueryKeys.DeleteLots, ...bulkSelectedLotIds],
		mutationFn: () => deleteSelectedLots(),
	});
	const handleBulkDeleteLots = () => {
		doDeleteSelectedLots(undefined, {
			onSuccess: (deletedLotIds: string[]) => {
				showSuccessToast(`Successfully deleted ${deletedLotIds.length} ${lotWord}`);
				removeLotsFromAuctionCache(deletedLotIds);
				setIsBulkEditMode(false);
				setBulkSelectedLotIds([]);
				setShouldShowBulkDeleteLotsModal(false);

				const remainingLotsForAuction = lotsForAuction.filter(
					({ lot_id }) => !deletedLotIds.includes(lot_id)
				);
				const [nextLot = {} as KernelLot] = remainingLotsForAuction;
				const nextLocation =
					remainingLotsForAuction.length > 0
						? `/auctions/${auctionId}/inventory-manager/${nextLot.lot_id}`
						: `/auctions/${auctionId}/inventory-manager`;

				history.push(nextLocation);
			},
			onError: () => {
				showErrorToast(`Something went wrong deleting ${lotWord}. Please try again later.`);
			},
		});
	};

	return (
		<ActionModal
			title="Bulk delete lots"
			onClose={onClose}
			onConfirm={handleBulkDeleteLots}
			canConfirm={bulkDeleteLotsStatus !== 'loading'}
			isLoading={bulkDeleteLotsStatus === 'loading'}
		>
			{`You are about to permanently delete ${bulkSelectedLotIds.length} ${lotWord}. Are you sure you want to do this?`}
		</ActionModal>
	);
};

export default BulkDeleteLotsModal;

import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useUserById from '../../../../../queries/useUserById';
import { updateUser } from '../../../../../services/userService';
import { User } from '../../../../../services/userService/userService.model';
import { QueryKeys } from '../../../../../queries/queryKeys';
import Label from '../../../../../components/Label';
import { Toggle } from '../../../../../prizm-ui/Toggle';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';

const SendPaymentReminderToggle = () => {
	const { userId = '' } = useParams<{ userId: string }>();

	const { data: customer = {} as User, updateUser: updateUserInCache } = useUserById(userId);

	const { mutate: doUpdateUser, status: updateUserStatus } = useMutation({
		mutationFn: (newValue: boolean) =>
			updateUser({ userId, fieldsToUpdate: { send_payment_reminder: newValue } }),
		mutationKey: [QueryKeys.UpdateUser, userId],
	});

	const handleChange = (newValue: boolean) => {
		doUpdateUser(newValue, {
			onError: () =>
				showErrorToast(
					'Something went wrong updating payment reminder preference. If the issue persists, please notify the #mvp-cs-channel'
				),
			onSuccess: () => {
				updateUserInCache({ sendPaymentReminder: newValue });

				showSuccessToast('Successfully updated payment reminder preference.');
			},
		});
	};

	return (
		<div>
			<Label className="text-neutral2dark">Send Payment Reminder</Label>
			<div className="flex flex-row items-center">
				<Toggle
					aria-label="Send Payment Reminder Toggle"
					checked={customer.sendPaymentReminder ?? true}
					disabled={updateUserStatus === 'loading'}
					onChange={(newValue: boolean) => handleChange(newValue)}
				/>
				<Label className="ml-2">{customer.sendPaymentReminder ? 'Enabled' : 'Disabled'}</Label>
			</div>
		</div>
	);
};

export default SendPaymentReminderToggle;

import { ColumnDef } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import formatCurrency from '../../../../../../utils/formatters/formatCurrency';
import Label from '../../../../../../components/Label';
import SortableTable from '../../../../../../components/SortableTable';
import { SortableTableParams } from '../../../../../../components/SortableTable/SortableTable.model';
import { formatDate } from '../../../../../../utils/formatters';
import { LotWithBidData } from '../BidsTabView.model';
import BidAmountCell from './BidAmountCell';

const BidsTable = ({
	shouldShowAllLots,
	lotsWithBidData,
	openPlaceBidModal,
}: {
	openPlaceBidModal: (selectedLotId: string) => void;
	shouldShowAllLots: boolean;
	lotsWithBidData: LotWithBidData[];
}) => {
	const { auctionId = 'Unassigned' } = useParams<{ auctionId: string; userId: string }>();

	const columns: ColumnDef<LotWithBidData>[] = [
		{
			accessorKey: 'mostRecentBidTimestamp',
			enableGlobalFilter: false,
			header: 'Date/Time',
			cell: ({ getValue }) => (
				<div className={getValue() ? '' : 'text-center'}>
					{formatDate(getValue() as string, { invalidDateMessage: '-' })}
				</div>
			),
		},
		{
			accessorKey: 'lotNumber',
			header: 'Lot Number',
			cell: ({ getValue, row: { id } }) => (
				<div className="w-12 text-right">
					<Link
						className="link block w-full text-right"
						to={`/auctions/${auctionId}/inventory-manager/${id}`}
					>
						{getValue() as string}
					</Link>
				</div>
			),
		},
		{
			accessorKey: 'title',
			header: 'Title',
			cell: ({ getValue, row: { id } }) => (
				<Link className="link" to={`/auctions/${auctionId}/inventory-manager/${id}`}>
					{getValue() as string}
				</Link>
			),
		},
		{
			accessorKey: 'bidAmount',
			header: 'Bid Amount',
			cell: ({
				row: {
					original: { lotId, bidAmount, status, title },
				},
			}) => (
				<BidAmountCell
					bidAmount={bidAmount}
					lotId={lotId}
					lotStatus={status}
					openPlaceBidModal={openPlaceBidModal}
					title={title}
				/>
			),
		},
		{
			accessorKey: 'currentPrice',
			enableGlobalFilter: false,
			header: 'Current Price',
			cell: ({ getValue }) => (
				<div className={getValue() ? 'text-right' : 'text-center'}>
					{formatCurrency(getValue() as number)}
				</div>
			),
		},
		{
			accessorKey: 'isCurrentWinner',
			header: 'Winner',
			enableGlobalFilter: false,
			cell: ({ getValue }) => (
				<div className="text-center">
					{typeof getValue() === 'undefined' ? '-' : getValue() ? 'Yes' : ' No'}
				</div>
			),
		},
		{
			accessorKey: 'numberOfBids',
			enableGlobalFilter: false,
			header: () => <div className="w-12 text-right"># Bids</div>,
			cell: ({ getValue, row: { id } }) => (
				<Link className="link block w-full text-right" to={`/inventory/listings/${id}/bids`}>
					{getValue() as string}
				</Link>
			),
		},
	];

	const sortableTableParams: SortableTableParams<LotWithBidData> = {
		columns,
		data: lotsWithBidData ?? [],
		getRowId: (original) => original.id,
		initialState: {
			sorting: [
				{
					desc: true,
					id: 'mostRecentBidTimestamp',
				},
			],
		},
	};

	return (
		<>
			<Label>{shouldShowAllLots ? 'All Lots' : 'User Bids'}</Label>
			<SortableTable
				tableParams={sortableTableParams}
				noResultsMessage={
					shouldShowAllLots
						? 'No lots to show for this auction.'
						: 'No bids to show for this auction.'
				}
			/>
		</>
	);
};

export default BidsTable;

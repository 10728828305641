import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomerManager from './CustomerManager/CustomerManager';
import EmployeeManager from './EmployeeManager';
import Layout from '../../components/Layout';
import RequireAuth from '../../routing/RequireAuth';
import useDecision from '../../optimizely/useDecision';
import { CurrentUser, UserRoleName } from '../../services/userService/userService.model';
import ProtectedRoute from '../../routing/ProtectedRoute/ProtectedRoute';
import CustomerManagerSearch from './CustomerManagerSearch';
import useCurrentUser from '../../queries/useCurrentUser/useCurrentUser';

const UserManagementRoot = ({ match }: { match: { url: string } }) => {
	const { data: currentUser = {} as CurrentUser } = useCurrentUser();

	const { currentUserRoleName } = currentUser;

	const customerManagerAllowedRoles: UserRoleName[] = [
		'Admin',
		'AuctionManager',
		'Consignment',
		'CustomerSupport',
		'Finance',
		'Inventory',
	];

	const history = useHistory();

	useEffect(() => {
		if (!customerManagerAllowedRoles.includes(currentUserRoleName)) {
			history.push('/');
		}
	}, [currentUserRoleName]);

	const employeeManagerAllowedRoles: UserRoleName[] = ['Admin', 'CustomerSupport'];

	return (
		<RequireAuth unauthRedirectTo={`${match.url}/customer`}>
			<Layout>
				<ProtectedRoute
					isAllowedByRole={customerManagerAllowedRoles.includes(currentUserRoleName)}
					path={`${match.url}/search/customers`}
				>
					<CustomerManagerSearch />
				</ProtectedRoute>
				<ProtectedRoute
					isAllowedByRole={customerManagerAllowedRoles.includes(currentUserRoleName)}
					path={`${match.url}/customer/:userId?/:tabId?/:auctionId?`}
				>
					<CustomerManager />
				</ProtectedRoute>
				<ProtectedRoute
					component={EmployeeManager}
					isAllowedByRole={employeeManagerAllowedRoles.includes(currentUserRoleName)}
					path={`${match.url}/employee`}
				>
					<EmployeeManager />
				</ProtectedRoute>
			</Layout>
		</RequireAuth>
	);
};

export default UserManagementRoot;

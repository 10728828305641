import { capitalize } from 'lodash';
import { ChangeEventHandler } from 'react';
import { EDITOR_ROLES } from '../../../../../services/userService/userService.model';
import Input from '../../../../Input';
import Label from '../../../../Label';

const LotEditorField = ({
	className = '',
	disabled = false,
	isErrored,
	label,
	name,
	onChange,
	type = 'text',
	value,
}: {
	className?: string;
	disabled?: boolean;
	isErrored?: boolean;
	label?: string;
	name: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	type?: 'number' | 'text';
	value: string | number | undefined;
}) => {
	const fallbackLabel = name.includes('_')
		? capitalize(name.split('_').join(' '))
		: capitalize(name);

	return (
		<div className={`mr-4 ${className}`}>
			<Label htmlFor={name} isErrored={isErrored} className="capitalize">
				{label || fallbackLabel}
			</Label>
			<Input
				allowedRoles={EDITOR_ROLES}
				className="w-full"
				disabled={disabled}
				name={name}
				onChange={onChange}
				type={type}
				value={value}
			/>
		</div>
	);
};

export default LotEditorField;

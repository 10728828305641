import { HeaderBasedPagination, Pagination } from '../../types';

/** Convert HeaderBasedPagination to regular Pagination */
export const headerPaginationToPagination = (
	headerPagination: HeaderBasedPagination
): Pagination => ({
	page: headerPagination['x-pager-page'],
	pageSize: headerPagination['x-pager-size'],
	total: headerPagination['x-pager-total-items'],
	totalPages: headerPagination['x-pager-total-pages'],
});

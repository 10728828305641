import { Auth } from 'aws-amplify';
import { UserRole, UserRoleName } from './userService.model';

export const getUserRole = async (): Promise<UserRoleName> => {
	const currentSession = await Auth.currentSession();
	const userRolePayload = currentSession?.getIdToken()?.payload || {};
	const userRoleKey = userRolePayload['custom:role'] || null;

	if (userRoleKey && userRoleKey in UserRole) {
		return UserRole[userRoleKey];
	}

	return 'Unknown';
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ProtectedRoute from '../../../routing/ProtectedRoute/ProtectedRoute';
import RequireAuth from '../../../routing/RequireAuth';
import ReportPage from './ReportsPage';
import { PERMITTED_ROLES_PER_PAGE } from '../../../components/Layout/SideBar/SideBar.model';
import { UserRoleName } from '../../../services/userService/userService.model';
import useUserRole from '../../../queries/useUserRole/useUserRole';

const ReportsRoot = ({ match }: { match: { url: string } }) => {
	const { data: userRoleName = 'Unknown' as UserRoleName } = useUserRole();

	const history = useHistory();

	useEffect(() => {
		const reportsPagePermissions = [...PERMITTED_ROLES_PER_PAGE['Reports'], 'Admin'];

		if (!reportsPagePermissions.includes(userRoleName)) {
			history.push('/');
		}
	}, [userRoleName]);

	return (
		<RequireAuth>
			<ProtectedRoute exact path={`${match.url}/reports`} component={ReportPage} />
		</RequireAuth>
	);
};

export default ReportsRoot;

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const deleteAuction = async (auctionId: string) => {
	const response = await axiosAuth.delete(`${config.api.auctions}/${auctionId}`);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

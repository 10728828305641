import apiEndpoints from '../../queries/apiEndpoints';
import { postAuthed } from '../../AdminUtils/httpUtils';

export const postStripePayout = async ({
	amount,
	description = '',
	user,
}: {
	amount: number;
	description?: string;
	user: string;
}) => {
	const body = { amount, description, user };

	const response = await postAuthed(`${apiEndpoints.cash_accounts_stripe_payout}`, body);

	if (response.status >= 400) {
		throw response;
	}

	return response;
};

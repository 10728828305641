import { Modal, ModalContent, ModalHeader } from '../../../prizm-ui/Modal';
import { AuctionStatus } from '../../../services/auctionsService';
import AuctionPickerModalContent from './AuctionPickerModalContent/AuctionPickerModalContent';

const AuctionPickerModal = ({
	allowedAuctionStatuses,
	onClose,
	selectedAuctionId,
	setSelectedAuctionId,
	shouldShowUnassignedLots,
	closeAfterSelection = true,
}: {
	allowedAuctionStatuses: AuctionStatus[];
	onClose: () => void;
	selectedAuctionId: string;
	setSelectedAuctionId: (selectedAuctionId: string) => void;
	shouldShowUnassignedLots: boolean;
	closeAfterSelection?: boolean;
}) => (
	<Modal isOpen={true}>
		<ModalHeader onClose={onClose}>Select Auction</ModalHeader>
		<ModalContent>
			<AuctionPickerModalContent
				allowedAuctionStatuses={allowedAuctionStatuses}
				selectedAuctionId={selectedAuctionId}
				shouldShowUnassignedLots={shouldShowUnassignedLots}
				setSelectedAuctionId={setSelectedAuctionId}
				onClose={onClose}
				closeAfterSelection={closeAfterSelection}
			/>
		</ModalContent>
	</Modal>
);

export default AuctionPickerModal;

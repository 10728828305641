import classNames from 'classnames';
import React, { ButtonHTMLAttributes, FC } from 'react';

const PageButton: FC<{ className?: string } & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className,
	...rest
}) => {
	return (
		<button
			type="button"
			className={classNames('relative inline-flex items-center p-2', className)}
			{...rest}
		>
			{children}
		</button>
	);
};

export default PageButton;

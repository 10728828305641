import { has, pick } from 'lodash';
import { AuctionTechnicalDetails } from './auctionsService.model';
import { updateAuction } from './updateAuction';
import { updateAuctionSchedule } from './updateAuctionSchedule';

/** @description Compound network request necessary because of weird lumping of buyer_premium, extended_bidding_duration, and auction_type
 * into an endpoint named for update_schedule on the backend. "Technical details" is about as specific as I could come up
 * with to describe what these are. For the sake of keeping weirdness localized, these fields live off by themselves
 * on a separate card, and all the ugliness of making two calls for one button click is swept into this service.
 */
export const updateAuctionTechnicalDetails = async ({
	auctionId,
	deltas,
}: {
	auctionId: string;
	deltas: AuctionTechnicalDetails;
}) => {
	const fieldsThatGoToAuctionScheduleEndpointForSomeReason = pick(deltas, [
		'buyer_premium',
		'extended_bidding_duration',
		'auction_type',
	]);
	const hasAuctionScheduleFields =
		has(fieldsThatGoToAuctionScheduleEndpointForSomeReason, 'buyer_premium') ||
		has(fieldsThatGoToAuctionScheduleEndpointForSomeReason, 'extended_bidding_duration') ||
		has(fieldsThatGoToAuctionScheduleEndpointForSomeReason, 'auction_type');

	const auctionScheduleResponse = hasAuctionScheduleFields
		? await updateAuctionSchedule({
				auctionId,
				fieldsThatHaveNothingToDoWithAuctionScheduleButHitThatEndpointAnyway: deltas,
		  })
		: await Promise.resolve({ status: 200, data: {} });

	if (hasAuctionScheduleFields && auctionScheduleResponse.status >= 400) {
		throw auctionScheduleResponse;
	}

	const hasFieldsThatGoToAuctionUpdateEndpoint = Object.hasOwn(deltas, 'commission');

	const auctionUpdateResponse = hasFieldsThatGoToAuctionUpdateEndpoint
		? await updateAuction(auctionId, deltas)
		: await Promise.resolve({ status: 200, data: { statusCode: 200 } });

	if (hasFieldsThatGoToAuctionUpdateEndpoint && auctionUpdateResponse.status >= 400) {
		throw auctionUpdateResponse;
	}

	return {
		auctionScheduleResponse: auctionScheduleResponse.data,
		auctionUpdateResponse: auctionUpdateResponse.data,
	};
};

import { isEqual, noop } from 'lodash';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ActionModal from '../../../../../components/ActionModal';
import DefinitionList from '../../../../../components/DefinitionList';
import { showErrorToast, showSuccessToast } from '../../../../../components/Toast';
import { Input } from '../../../../../prizm-ui/Input';
import { Toggle } from '../../../../../prizm-ui/Toggle';
import { QueryKeys } from '../../../../../queries/queryKeys';
import useUserById from '../../../../../queries/useUserById';
import { updateUser } from '../../../../../services/userService';
import { Address, User } from '../../../../../services/userService/userService.model';
import { snakeObject } from '../../../../../utils/formatters';
import { getDeltas } from '../../../../../utils/formatters/getDeltas';
import DeactivateCustomerModal from './DeactivateCustomerModal';

const EditCustomerProfileModal = ({ onClose }: { onClose: () => void }) => {
	const { userId } = useParams<{ userId: string }>();
	const {
		data: user = {} as User,
		status: userStatus,
		updateUser: updateUserInCache,
	} = useUserById(userId);

	const [draftUser, setDraftUser] = useState(user);
	const updateDraftUser = (deltas: Partial<User>) => setDraftUser({ ...draftUser, ...deltas });

	const deltas = getDeltas<User>({ olderVersion: user, newerVersion: draftUser });

	const { mutate: doUpdateUser, status: updateUserStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateUser, deltas],
		mutationFn: () => updateUser({ userId, fieldsToUpdate: snakeObject(deltas) }),
	});

	const handleUpdateUser = () =>
		doUpdateUser(undefined, {
			onSuccess: () => {
				showSuccessToast('Successfully updated user.');
				updateUserInCache(deltas);
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong updating user. If the issue persists after refresh, please notify the #mvp-cs-channel'
				);
			},
		});

	const [shouldShowConfirmAccountStatusChangeModal, setShouldShowConfirmAccountStatusChangeModal] =
		useState(false);

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleUpdateUser}
			canConfirm={
				!isEqual(user, draftUser) &&
				draftUser.givenName.length > 0 &&
				draftUser.familyName.length > 0
			}
			title="Edit Profile"
			isLoading={userStatus === 'loading' || updateUserStatus === 'loading'}
		>
			<h2 className="text-subtitle1 mb-2">Contact Information</h2>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
				<Input
					id="givenName"
					label="First Name"
					value={draftUser.givenName}
					onChange={({ target: { value } }) => updateDraftUser({ givenName: value })}
				/>
				<Input
					id="familyName"
					label="Last Name"
					value={draftUser.familyName}
					onChange={({ target: { value } }) => updateDraftUser({ familyName: value })}
				/>
				<Input
					id="email"
					label="Email"
					value={draftUser.email}
					type="email"
					onChange={({ target: { value } }) => updateDraftUser({ email: value })}
				/>
				<Input
					id="phoneNumber"
					label="Phone Number"
					value={draftUser.phoneNumber}
					onChange={({ target: { value } }) => updateDraftUser({ phoneNumber: value })}
				/>
			</div>
			<div className="mt-8">
				<h2 className="text-subtitle1 mb-2">Shipping Address</h2>
				<div className="grid grid-cols-1 gap-4">
					<Input
						id="line1-shipping"
						label="Line 1"
						value={draftUser.shippingAddress.line1}
						onChange={({ target: { value } }) =>
							updateDraftUser({ shippingAddress: { ...draftUser.shippingAddress, line1: value } })
						}
					/>
					<Input
						id="line2-shipping"
						label="Line 2"
						value={draftUser.shippingAddress.line2}
						onChange={({ target: { value } }) =>
							updateDraftUser({ shippingAddress: { ...draftUser.shippingAddress, line2: value } })
						}
					/>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
						<Input
							id="city-shipping"
							label="City"
							value={draftUser.shippingAddress.city}
							onChange={({ target: { value } }) =>
								updateDraftUser({ shippingAddress: { ...draftUser.shippingAddress, city: value } })
							}
						/>
						<Input
							id="state-shipping"
							label="State"
							value={draftUser.shippingAddress.state}
							onChange={({ target: { value } }) =>
								updateDraftUser({ shippingAddress: { ...draftUser.shippingAddress, state: value } })
							}
						/>
						<Input
							id="postalCode-shipping"
							label="Postal Code"
							value={draftUser.shippingAddress.postalCode}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									shippingAddress: { ...draftUser.shippingAddress, postalCode: value },
								})
							}
						/>
						<Input
							id="country-shipping"
							label="Country"
							value={draftUser.shippingAddress.country}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									shippingAddress: { ...draftUser.shippingAddress, country: value },
								})
							}
						/>
					</div>
				</div>
			</div>
			<div className="mt-8">
				<h2 className="text-subtitle1 mb-2">Payout Address</h2>
				<div className="grid grid-cols-1 gap-4">
					<Input
						id="line1-payment"
						label="Line 1"
						value={draftUser?.paymentAddress?.line1}
						onChange={({ target: { value } }) =>
							updateDraftUser({
								paymentAddress: { ...draftUser?.paymentAddress, line1: value } as Address,
							})
						}
					/>
					<Input
						id="line2-payment"
						label="Line 2"
						value={draftUser?.paymentAddress?.line2}
						onChange={({ target: { value } }) =>
							updateDraftUser({
								paymentAddress: { ...draftUser?.paymentAddress, line2: value } as Address,
							})
						}
					/>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
						<Input
							id="city-payment"
							label="City"
							value={draftUser?.paymentAddress?.city}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									paymentAddress: { ...draftUser?.paymentAddress, city: value } as Address,
								})
							}
						/>
						<Input
							id="state-payment"
							label="State"
							value={draftUser?.paymentAddress?.state}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									paymentAddress: { ...draftUser?.paymentAddress, state: value } as Address,
								})
							}
						/>
						<Input
							id="postalCode-payment"
							label="Postal Code"
							value={draftUser?.paymentAddress?.postalCode}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									paymentAddress: { ...draftUser?.paymentAddress, postalCode: value } as Address,
								})
							}
						/>
						<Input
							id="country-payment"
							label="Country"
							value={draftUser?.paymentAddress?.country}
							onChange={({ target: { value } }) =>
								updateDraftUser({
									paymentAddress: { ...draftUser?.paymentAddress, country: value } as Address,
								})
							}
						/>
					</div>
				</div>
			</div>
			<div className="mt-8 border-t-light pt-4">
				<DefinitionList
					rows={[
						{
							title: 'VIP User',
							content: (
								<Toggle
									aria-label={draftUser.isVip ? 'Toggle VIP Status Off' : 'Toggle VIP Status On'}
									checked={draftUser.isVip}
									onChange={() => updateDraftUser({ isVip: !draftUser.isVip })}
								/>
							),
						},
					]}
				/>
			</div>
			<div className="mt-2 border-t-light pt-8">
				<h2 className="text-display5 text-error2">Danger Zone</h2>
				<DefinitionList
					rows={[
						{
							title: 'Account Enabled',
							content: (
								<Toggle
									aria-label={
										user.isDisabled
											? 'Notify the #mvp-cs-channel if this user should not be disabled'
											: 'Permanently disable user'
									}
									checked={!user.isDisabled}
									disabled={user.isDisabled}
									onChange={() => setShouldShowConfirmAccountStatusChangeModal(true)}
								/>
							),
						},
					]}
				/>
			</div>
			{shouldShowConfirmAccountStatusChangeModal && (
				<DeactivateCustomerModal
					onClose={() => setShouldShowConfirmAccountStatusChangeModal(false)}
					onSuccess={onClose}
				/>
			)}
		</ActionModal>
	);
};

export default EditCustomerProfileModal;

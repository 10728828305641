import React from 'react';
import { ArrowUndo20Regular, Delete20Regular, GridDotsRegular } from '@fluentui/react-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import BannerContent from '../../BannerContent';
import { Banner } from '../../../../../../services/layoutService/layoutService.model';
import { useManageBannersContext } from '../useManageBannersState';

const MoveableBannerItem = ({
	banner,
	activeBanner,
}: {
	banner: Banner;
	/** The used to identify which image is actively being dragged for reordering */
	activeBanner?: Banner | null;
}) => {
	const { manageBannersState, dispatch } = useManageBannersContext();
	const isStagedForDeletion = !!manageBannersState.bannersToDelete.find(
		({ id }) => id === banner.id
	);
	const handleDeleteIconClick = () => {
		if (isStagedForDeletion) {
			return dispatch({
				type: 'UNDELETE_BANNER',
				payload: banner,
			});
		}

		dispatch({
			type: 'DELETE_BANNER',
			payload: banner,
		});
	};

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable(banner);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<li
			className={`border-base flex flex-col rounded-lg ${
				activeBanner?.id === banner.id ? 'opacity-50 grayscale filter' : 'opacity-100'
			}`}
			id={banner.id}
			ref={setNodeRef}
			style={style}
		>
			<div className="border-b-base flex justify-between rounded-t-lg bg-neutral1 px-0 py-2">
				<button
					className="hover:bg-blue-100 ml-2 flex cursor-move items-center justify-center rounded-lg p-1"
					{...attributes}
					{...listeners}
				>
					<GridDotsRegular />
				</button>
				<button className="pr-2" onClick={handleDeleteIconClick}>
					{isStagedForDeletion ? <ArrowUndo20Regular /> : <Delete20Regular />}
				</button>
			</div>
			<div
				className={`flex flex-grow flex-col justify-between rounded-b-lg bg-neutral2 p-4 ${
					isStagedForDeletion ? 'opacity-50' : 'opacity-100'
				}`}
			>
				<BannerContent bannerId={banner.id} kind="existing" />
			</div>
		</li>
	);
};

export default MoveableBannerItem;

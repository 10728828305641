import React, { useState } from 'react';
import useEmployeeById from '../../queries/useEmployeeById';
import useUserById from '../../queries/useUserById';
import { searchBidder } from '../../services/bidderService/searchBidder';
import { getEmployeesBySearchQuery } from '../../services/userService';
import { BaseUser } from '../../services/userService/userService.model';
import Dropdown from '../Dropdown';
import Label from '../Label';
import LoadingWrapper from '../LoadingWrapper';

export type PersonOption = Omit<BaseUser, 'familyName' | 'givenName'>;

const PersonSelector = ({
	label,
	onChange,
	userType = 'bidder',
	defaultPersonOption,
	name,
	onClear,
}: {
	label: string;
	onChange: (selectedPerson: PersonOption) => void;
	userType?: 'bidder' | 'admin';
	defaultPersonOption?: PersonOption;
	name: string;
	onClear: () => void;
}) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [dropdownKey, setDropdownKey] = useState(0);

	const { data: bidderUser = {} as BaseUser, status: bidderUserStatus } = useUserById(
		defaultPersonOption?.id
	);
	const { data: adminUser = {} as BaseUser, status: adminUserStatus } = useEmployeeById(
		defaultPersonOption?.id
	);

	const defaultUserLoadStatus = userType === 'bidder' ? bidderUserStatus : adminUserStatus;
	const defaultPerson = userType === 'bidder' ? bidderUser : adminUser;

	const loadPeople = async () => {
		const searchResults =
			userType === 'bidder'
				? await searchBidder(searchQuery)
				: await getEmployeesBySearchQuery({ searchQuery });
		return searchResults.map(
			({
				id,
				givenName,
				familyName,
				email,
			}: {
				id: string;
				givenName: string;
				familyName: string;
				email: string;
			}) => ({
				value: { id, email },
				label: `${givenName} ${familyName} (${email})`,
			})
		);
	};

	const handleClear = () => {
		setSearchQuery('');
		setDropdownKey(dropdownKey + 1);
		onClear();
	};

	return (
		<LoadingWrapper queryStatuses={defaultPerson ? [defaultUserLoadStatus] : []}>
			<Label htmlFor={name}>{label}</Label>
			<div className="flex items-center">
				<div className="flex-grow">
					<Dropdown
						kind="async"
						key={dropdownKey}
						onChange={({ value: selectedPerson }) => onChange(selectedPerson as PersonOption)}
						loadOptions={() => loadPeople()}
						defaultOption={
							defaultPersonOption
								? {
										value: defaultPersonOption,
										label: `${defaultPerson.givenName || ''} ${defaultPerson.familyName || ''} (${
											defaultPersonOption.email
										})`,
								  }
								: undefined
						}
						inputValue={searchQuery}
						onInputChange={(newValue) => {
							setSearchQuery(newValue);
						}}
						placeholder="Search for Person..."
						name={name}
					/>
				</div>
				<button className="ml-2" onClick={handleClear}>
					Clear
				</button>
			</div>
		</LoadingWrapper>
	);
};

export default PersonSelector;

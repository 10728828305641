import { Tooltip } from '../../../../../../prizm-ui/Tooltip';
import { RateCardVariant } from '../../../../../../services/rateCardService/rateCardService.model';

const RateCardRate = ({
	id,
	variant,
	rate,
}: {
	variant: RateCardVariant;
	rate?: number;
	id: string;
}) =>
	typeof rate === 'undefined' ? (
		<>-</>
	) : (
		<Tooltip id={id} content={`Seller gets ${variant === 'auction' ? 100 - rate : 100 + rate}%`}>
			{rate}%
		</Tooltip>
	);

export default RateCardRate;

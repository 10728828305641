import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '../Card';
import LoadingWrapper from '../LoadingWrapper';
import ManageLotImages from './ManageLotImages';
import useIndividualLot from '../../queries/useIndividualLot';
import { CollectorsImageItem, KernelLot } from '../../services/lotsService/lotsService.model';
import AdministrativeActions from './AdministrativeActions';
import InterestedParties from './InterestedParties';
import LotEditor from './LotEditor';
import ReassignLot from './ReassignLot';
import LotAdjustments from './LotAdjustments';
import { EDITOR_ROLES, PHOTO_EDITOR_ROLES } from '../../services/userService/userService.model';
import RestrictedArea from '../RestrictedArea';
import LotMetadata from './LotMetadata';
import useUserRole from '../../queries/useUserRole';
import ReadOnlyImages from './ManageLotImages/ReadOnlyImages';
import { has } from 'lodash';
import CollectorsImages from './ManageLotImages/ExternalImages';
import useDecision from '../../optimizely/useDecision';
import { isUrl } from '../../utils/validators';
import LotHeader from './LotHeader';
import LotDetails from './LotDetails';
import LotFinancialDetails from './LotFinancialDetails';
import LotPhysicalItemDetails from './LotPhysicalItemDetails';
import RequiredFieldsAlert from './RequiredFieldsAlert';
import LotInternalNotes from './LotInternalNotes';

const ManageIndividualLot = ({ isInAuctionManager = false }: { isInAuctionManager?: boolean }) => {
	const { lotId } = useParams<{ lotId: string }>();
	const { data: currentLot = {} as KernelLot, status: currentLotStatus } = useIndividualLot(lotId);

	const { data: userRole = 'Unknown' } = useUserRole();
	const isPhotoEditor = [...PHOTO_EDITOR_ROLES, 'AuctionManager'].includes(userRole);

	const shouldShowExternalImages = useDecision('collectors-images-in-inventory-manager');
	const { external_images: externalImages = [] } = currentLot;

	if (!currentLot.images && externalImages.length === 0 && isUrl(currentLot.primary_image_name)) {
		externalImages.push({
			small: currentLot.primary_image_name,
			large: currentLot.primary_image_name,
		} as CollectorsImageItem);
	}

	const shouldShowNewLotEditor = useDecision('new-lot-editor');

	return (
		<LoadingWrapper queryStatuses={[currentLotStatus]}>
			<div className="w-full my-4">
				<RequiredFieldsAlert lot={currentLot} />
			</div>
			{shouldShowNewLotEditor && (
				<div className="mb-4">
					<LotHeader lotId={lotId} />
				</div>
			)}
			{currentLot && lotId !== '__newlyCreatedLot__' && (
				<Card className="my-4">
					<h2 className="text-display5">Manage Lot Images</h2>
					{externalImages.length > 0 && shouldShowExternalImages ? (
						<div className="my-4">
							<CollectorsImages images={externalImages} />
						</div>
					) : (
						<div>
							{isPhotoEditor ? (
								<ManageLotImages
									legacyPrimaryImageName={
										currentLot.primary_image ? currentLot.primary_image[0] : ''
									}
									imageIdentifiers={currentLot.images || []}
									lotId={lotId}
									key={lotId}
									hasPersistedImageArray={has(currentLot, 'images')}
								/>
							) : (
								<div className="w-full">
									<h2 className="display-2 mb-8">Lot Images</h2>
									<div className="mb-4">
										<ReadOnlyImages
											legacyPrimaryImageName={
												currentLot.primary_image ? currentLot.primary_image[0] : ''
											}
											imageIdentifiers={(currentLot.images || []).filter(
												(imageName) => !imageName.includes('LOA_')
											)}
											lotId={lotId}
											title="Product Images"
										/>
									</div>
									<div>
										<ReadOnlyImages
											imageIdentifiers={(currentLot.images || []).filter((imageName) =>
												imageName.includes('LOA_')
											)}
											lotId={lotId}
											title="Letters of Authentication"
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</Card>
			)}
			<div className="grid grid-cols-12 gap-4 mb-4">
				<RestrictedArea
					allowedRoles={[
						'Admin',
						'AuctionManager',
						'Consignment',
						'CustomerSupport',
						'Finance',
						'Inventory',
					]}
				>
					<div className="col-span-12 lg:col-span-6 xl:col-span-12 2xl:col-span-8">
						<AdministrativeActions />
					</div>
				</RestrictedArea>

				{!shouldShowNewLotEditor && (
					<div className="col-span-12 lg:col-span-6">
						<LotMetadata lotId={currentLot.lot_id} key={currentLot.lot_id} />
					</div>
				)}
				{!isInAuctionManager && currentLot && (
					<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager']}>
						<div className="col-span-12 lg:col-span-6 2xl:col-span-6">
							<ReassignLot initialAuctionId={currentLot.auction_id} />
						</div>
					</RestrictedArea>
				)}
				{currentLot && (
					<div className="col-span-12 lg:col-span-6">
						{currentLot && <InterestedParties key={currentLot.lot_id} />}
					</div>
				)}
			</div>
			{shouldShowNewLotEditor ? (
				<div>
					<div className="mb-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
						<div className="self-start">
							<LotDetails lotId={currentLot.lot_id} />
							<div className="mt-4">
								<LotFinancialDetails lotId={currentLot.lot_id} />
							</div>
						</div>
						<div className="self-start">
							<LotMetadata lotId={currentLot.lot_id} key={currentLot.lot_id} />
							<div className="mt-4">
								<LotPhysicalItemDetails lotId={currentLot.lot_id} />
							</div>
						</div>
					</div>
					<div className="my-4">
						<LotInternalNotes lot={currentLot} />
					</div>
				</div>
			) : (
				<LotEditor lotDisplayStatus="Active" key={currentLot.lot_id} />
			)}
			<div className="mt-8">
				<Card>
					<LotAdjustments />
				</Card>
			</div>
		</LoadingWrapper>
	);
};

export default ManageIndividualLot;

import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import ActionModal from '../../../../components/ActionModal';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast';
import { Input } from '../../../../prizm-ui/Input';
import { QueryKeys } from '../../../../queries/queryKeys';
import {
	AuctionType,
	createAuction,
	CreateAuctionParams,
} from '../../../../services/auctionsService';
import AuctionTypeSelector from '../AuctionTypeSelector';

const CreateAuctionModal = ({ onClose }: { onClose: () => void }) => {
	const [createAuctionParams, setCreateAuctionParams] = useState<Partial<CreateAuctionParams>>({});
	const updateCreateAuctionParams = (updates: Partial<CreateAuctionParams>) =>
		setCreateAuctionParams({ ...createAuctionParams, ...updates });

	const { mutate: doCreateAuction, status: createAuctionStatus } = useMutation({
		mutationKey: [
			QueryKeys.CreateAuction,
			createAuctionParams.title,
			createAuctionParams.auction_type,
		],
		mutationFn: () => createAuction(createAuctionParams as CreateAuctionParams),
	});

	const history = useHistory();
	const queryClient = useQueryClient();

	const handleCreateAuction = () =>
		doCreateAuction(undefined, {
			onSuccess: (newAuctionId: string) => {
				showSuccessToast('Successfully created new auction');
				queryClient.invalidateQueries([QueryKeys.GetAuctions]);
				history.push(`/auctions/${newAuctionId}/auction-manager`);
				onClose();
			},
			onError: () => {
				showErrorToast(
					'Something went wrong creating a new auction. If the issue persists, please notify the #mvp-cs-channel'
				);
			},
		});

	return (
		<ActionModal
			onClose={onClose}
			onConfirm={handleCreateAuction}
			title="Create Auction"
			canConfirm={!!createAuctionParams.title && !!createAuctionParams.auction_type}
			confirmButtonLabel="Submit"
			isLoading={createAuctionStatus === 'loading'}
		>
			<Input
				id="title"
				label="Title"
				value={createAuctionParams.title}
				onChange={({ target: { value } }) => updateCreateAuctionParams({ title: value })}
			/>
			<div className="my-4">
				<AuctionTypeSelector
					selectedAuctionType={createAuctionParams.auction_type}
					setSelectedAuctionType={(selectedAuctionType: AuctionType) =>
						updateCreateAuctionParams({ auction_type: selectedAuctionType })
					}
				/>
			</div>
		</ActionModal>
	);
};

export default CreateAuctionModal;

import React from 'react';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import TableHeader from '../../../../components/TableHeader';
import useSortByColumns from '../../../../hooks/useSortByColumns';
import DownloadCSVButton from '../../../../components/DownloadCSVButton';
import { invoiceDetailsColumnWidthClasses } from './InvoiceDetailsTable.model';
import InvoiceDetailsTableRow from './InvoiceDetailsTableRow';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';

const InvoiceDetailsTable = ({
	lots,
	invoiceNumber,
}: {
	lots: Partial<KernelLot>[];
	invoiceNumber: number;
}) => {
	const {
		sortedRows: sortedLots,
		getSortDirectionForColumn,
		performNextSort,
	} = useSortByColumns<Partial<KernelLot>>(lots);

	const columnNames = ['lot_number', 'serial_number', 'title', 'location', 'current_price'];

	const formattedLots = sortedLots.map((lot) => ({
		...lot,
		current_price: lot.current_price ? formatCurrency(lot.current_price) : '-',
	}));

	const downloadedCSVName = `Winner Summary, Invoice ${invoiceNumber} (${new Date().toISOString()})`;

	return (
		<div className="w-full">
			<div className="border-base grid grid-cols-12 gap-4 rounded-t-md p-2">
				<TableHeader
					label="Lot #"
					kind="sortable"
					sortDirection={getSortDirectionForColumn('lot_number')}
					onClick={() => performNextSort('lot_number')}
					className={`${invoiceDetailsColumnWidthClasses.lot_number} text-center`}
				/>
				<TableHeader
					label="Serial #"
					kind="sortable"
					sortDirection={getSortDirectionForColumn('serial_number')}
					onClick={() => performNextSort('serial_number')}
					className={`${invoiceDetailsColumnWidthClasses.serial_number}`}
				/>
				<TableHeader
					label="Title"
					kind="sortable"
					sortDirection={getSortDirectionForColumn('title')}
					onClick={() => performNextSort('title')}
					className={`${invoiceDetailsColumnWidthClasses.title}`}
				/>
				<TableHeader
					label="Location"
					kind="sortable"
					sortDirection={getSortDirectionForColumn('location')}
					onClick={() => performNextSort('location')}
					className={`${invoiceDetailsColumnWidthClasses.location}`}
				/>
				<TableHeader
					label="Amount"
					kind="sortable"
					sortDirection={getSortDirectionForColumn('current_price')}
					onClick={() => performNextSort('current_price')}
					className={`${invoiceDetailsColumnWidthClasses.current_price} text-right`}
				/>
				<div className="col-span-1" />
			</div>
			<div className="border-base w-full rounded-b-md border-t-0">
				{sortedLots.map((lot, index) => (
					<InvoiceDetailsTableRow
						lot={lot}
						index={index}
						key={lot.lot_number}
						isLastRow={index === sortedLots.length - 1}
					/>
				))}
			</div>
			<div className="mt-2">
				<DownloadCSVButton
					columnNames={columnNames}
					items={formattedLots}
					documentName={downloadedCSVName}
				/>
			</div>
		</div>
	);
};

export default InvoiceDetailsTable;

import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import Toast from '../../../Toast';
import { QueryKeys } from '../../../../queries/queryKeys';
import useIndividualLot from '../../../../queries/useIndividualLot';
import { updateLot } from '../../../../services/lotsService';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import {
	BaseUser,
	BLANK_BASE_USER,
	EDITOR_ROLES,
	UserRoleName,
} from '../../../../services/userService/userService.model';
import InterestedPartyInput from '../../../InterestedPartyInput';
import RestrictedArea from '../../../RestrictedArea';
import ConfirmationButtons from '../../../ConfirmationButtons';
import useUserRole from '../../../../queries/useUserRole';

const InterestedPartiesView = ({
	currentConsignmentManager,
	currentSeller,
}: {
	currentConsignmentManager: BaseUser;
	currentSeller: BaseUser;
}) => {
	const { lotId } = useParams<{ lotId: string }>();

	const [selectedSeller, setSelectedSeller] = useState<BaseUser>(currentSeller);
	const restoreCurrentSeller = () => {
		setSelectedSeller(currentSeller);
	};

	const [selectedConsignmentManager, setSelectedConsignmentManager] =
		useState<BaseUser>(currentConsignmentManager);
	const restoreCurrentConsignmentManager = () => {
		setSelectedConsignmentManager(currentConsignmentManager);
	};

	const { mutate: doUpdateLot, status: updateLotStatus } = useMutation({
		mutationKey: [QueryKeys.UpdateLot],
		mutationFn: (fieldsToUpdate: Partial<KernelLot>) => updateLot(fieldsToUpdate),
	});

	const { data: thisLot = {} as KernelLot, updateLot: updateLotInCache } = useIndividualLot(lotId);

	const handleSave = () => {
		const fieldsToUpdate: Partial<KernelLot> = {
			lot_id: lotId,
			seller_email: selectedSeller.email,
			seller_id: selectedSeller.id,
			consignment_manager_email: selectedConsignmentManager.email,
			consignment_manager_id: selectedConsignmentManager.id,
		};

		doUpdateLot(fieldsToUpdate, {
			onSuccess: () => {
				toast(<Toast message="Successfully updated interested parties." sentiment="success" />, {
					transition: Slide,
				});
				updateLotInCache(fieldsToUpdate);
			},
			onError: () => {
				toast(
					<Toast
						message="Error updating interested parties. Please try again later."
						sentiment="warning"
					/>,
					{
						transition: Slide,
					}
				);
			},
		});
	};

	const { data: currentUserRole = 'Unknown' as UserRoleName } = useUserRole();

	const isCompletedSold = thisLot.status === 'Completed_Sold';

	const canAdminFinanceOverride = isCompletedSold && ['Admin', 'Finance'].includes(currentUserRole);

	const canEdit = !isCompletedSold || canAdminFinanceOverride;

	const isNotSelf =
		selectedConsignmentManager.id !== currentConsignmentManager.id ||
		selectedSeller.id !== currentSeller.id;

	return (
		<>
			<div className="mb-4">
				<InterestedPartyInput
					isDisabled={!canEdit || !!thisLot.collectible_id}
					key={selectedSeller.id}
					kind="customer"
					selectedParty={selectedSeller}
					onChange={setSelectedSeller}
					onClear={() => {
						setSelectedSeller(BLANK_BASE_USER);
					}}
				/>
			</div>
			<div className="mb-4">
				<InterestedPartyInput
					isDisabled={!canEdit}
					key={selectedConsignmentManager.id}
					kind="employee"
					selectedParty={selectedConsignmentManager}
					onChange={setSelectedConsignmentManager}
					onClear={() => {
						setSelectedConsignmentManager(BLANK_BASE_USER);
					}}
				/>
			</div>
			<RestrictedArea allowedRoles={[...EDITOR_ROLES, 'AuctionManager', 'Consignment']}>
				<ConfirmationButtons
					canConfirm={isNotSelf}
					canCancel={isNotSelf}
					loading={updateLotStatus === 'loading'}
					disabled={!isNotSelf}
					onConfirm={handleSave}
					onCancel={() => {
						restoreCurrentConsignmentManager();
						restoreCurrentSeller();
					}}
				/>
			</RestrictedArea>
		</>
	);
};

export default InterestedPartiesView;

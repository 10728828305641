import React from 'react';
import { Icon, IconName, IconSize } from '../Icon';
import { mergeRefs } from '../utils';

type CheckboxSize = 'sm' | 'md';
type CheckboxVariant = 'square' | 'circle';

type CheckboxProps = {
	checked?: boolean;
	children?: React.ReactNode;
	disabled?: boolean;
	helpText?: string;
	hideLabel?: boolean;
	id: string;
	indeterminate?: boolean;
	onChange?: (value: boolean) => void;
	size?: CheckboxSize;
	variant?: CheckboxVariant;
};

const getHoverMaskClasses = ({
	size,
	variant,
}: {
	size: CheckboxSize;
	variant: CheckboxVariant;
}) => {
	const combination = `${variant}.${size}` as const;
	const classesByVariant: Record<CheckboxVariant, string[]> = {
		circle: ['rounded-full'],
		square: [],
	};
	const classesByCombination: Record<typeof combination, string[]> = {
		'circle.sm': ['h-4', 'w-4'],
		'circle.md': ['h-[18px]', 'w-[18px]'],
		'square.sm': ['h-3.5', 'w-3.5'],
		'square.md': ['h-4', 'w-4'],
	};
	return [...classesByVariant[variant], ...classesByCombination[combination]];
};

const getButtonClasses = ({
	hasLabel,
	size,
	variant,
}: {
	hasLabel: boolean;
	size: CheckboxSize;
	variant: CheckboxVariant;
}) => {
	const combination = `${variant}.${size}` as const;
	const classesByVariant: Record<CheckboxVariant, string[]> = {
		circle: ['rounded-full'],
		square: ['rounded'],
	};
	const classesByCombination: Record<typeof combination, string[]> = {
		'circle.sm': ['h-5', 'w-5'],
		'circle.md': ['h-6', 'w-6'],
		'square.sm': ['h-5', 'w-5'],
		'square.md': ['h-[22px]', 'w-[22px]'],
	};

	return [
		...(hasLabel && size === 'md' ? ['mt-0.5'] : []),
		...classesByVariant[variant],
		...classesByCombination[combination],
	];
};

const getLabelClasses = ({
	hideLabel,
	size,
}: {
	hideLabel?: boolean;
	size: CheckboxSize;
}): string[] => {
	const classes = [...(hideLabel ? ['sr-only'] : ['inline-flex', 'flex-col', 'cursor-pointer'])];

	switch (size) {
		case 'sm':
			classes.push('text-body2');
			break;
		case 'md':
			classes.push('text-body1');
			break;
	}

	return classes;
};

export const Checkbox = React.forwardRef(
	(
		{
			checked,
			children,
			disabled,
			helpText,
			hideLabel,
			id,
			indeterminate = false,
			onChange,
			size = 'md',
			variant = 'square',
		}: CheckboxProps,
		ref?: React.ForwardedRef<HTMLInputElement>
	): JSX.Element => {
		const checkboxRef = React.useRef<HTMLInputElement>(null);
		const [_checked, setChecked] = React.useState(checked);
		const isControlled = checked != null;

		React.useEffect(() => {
			setChecked(checked);
		}, [checked]);

		React.useEffect(() => {
			if (checkboxRef.current) {
				checkboxRef.current.indeterminate = indeterminate;
			}
		}, [indeterminate, checkboxRef.current]);

		const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
			if (!isControlled) {
				setChecked(e.currentTarget.checked);
			}

			onChange?.(e.currentTarget.checked);
		};

		const handleClick = (): void => {
			if (!isControlled) {
				setChecked((c) => !c);
			}

			onChange?.(!_checked);
		};

		const getIconName = (): IconName => {
			switch (variant) {
				case 'circle':
					if (indeterminate) return 'RadioButtonFilled';
					if (_checked) return 'CheckmarkCircleFilled';
					return 'Circle';
				case 'square':
					if (indeterminate) return 'CheckboxIndeterminate';
					if (_checked) return 'CheckboxCheckedFilled';
					return 'CheckboxUnchecked';
			}
		};

		const getIconSize = (): IconSize => {
			switch (size) {
				case 'sm':
					return 'md';
				case 'md':
					return 'lg';
			}
		};

		return (
			<div className="relative flex gap-2">
				<input
					ref={ref ? mergeRefs([ref, checkboxRef]) : checkboxRef}
					type="checkbox"
					id={id}
					name={id}
					disabled={disabled}
					checked={_checked}
					onChange={handleChange}
					className="absolute top-0 left-0 peer invisible h-0 w-0"
				/>
				<button
					type="button"
					disabled={disabled}
					onClick={handleClick}
					aria-hidden
					className={[
						'group',
						'relative',
						'flex',
						'items-center',
						'justify-center',
						'rounded',
						'focus:outline-none',
						'focus-visible:ring-2',
						'focus-visible:ring-primary-500',
						'peer-disabled:pointer-events-none',
						...getButtonClasses({ hasLabel: !!children, size, variant }),
					].join(' ')}
				>
					<div
						className={[
							'absolute',
							'top-1/2',
							'left-1/2',
							'-translate-x-1/2',
							'-translate-y-1/2',
							...(_checked ? [] : ['group-hover:bg-primary-100']),
							...getHoverMaskClasses({ size, variant }),
						].join(' ')}
					/>
					<div
						className={[
							'relative',
							'flex',
							'items-center',
							...(_checked || indeterminate ? ['text-primary-500'] : ['text-neutral3']),
							...(_checked ? ['group-hover:text-primary-700'] : ['group-hover:text-primary-500']),
							'group-disabled:text-disabled',
						].join(' ')}
					>
						<Icon name={getIconName()} size={getIconSize()} aria-hidden />
					</div>
				</button>
				{children && (
					<button className={getLabelClasses({ hideLabel, size }).join(' ')} onClick={handleClick}>
						<span className="text-neutral2">{children}</span>
						{size === 'md' && helpText && (
							<span className="text-neutral3 text-body2">{helpText}</span>
						)}
					</button>
				)}
			</div>
		);
	}
);

Checkbox.displayName = 'Checkbox';

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { setChallengeName, setIsAuthenticated, setUser } from '../../reducers/ThemeOptions';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import { Link } from 'react-router-dom';
import { Input } from '../../prizm-ui/Input';
import { Button } from '../../prizm-ui/Button';

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			oldpassword: '',
			newpassword: '',
			confirmpassword: '',
			complete: false,
			errors: {
				cognito: null,
				blankfield: false,
				passwordmatch: false,
			},
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	clearErrorState = () => {
		this.setState({
			errors: {
				cognito: null,
				blankfield: false,
				passwordmatch: false,
			},
		});
	};

	shouldShowOldPassword = () => {
		return this.props.challengeName === 'NEW_PASSWORD_REQUIRED' ? false : true;
	};

	inputValuesValid = () => {
		return (
			this.state.oldpassword.length > 0 &&
			this.state.newpassword.length > 0 &&
			this.state.confirmpassword.length > 0
		);
	};

	newConfirmValid = () => {
		return this.state.newpassword === this.state.confirmpassword;
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		if (!this.inputValuesValid()) {
			this.setState({
				errors: {
					...this.state.errors,
					blankfield: true,
				},
			});

			return;
		}

		if (!this.newConfirmValid()) {
			this.setState({
				errors: {
					...this.state.errors,
					passwordmatch: true,
				},
			});

			return;
		}

		// AWS Cognito integration here
		try {
			if (!this.shouldShowOldPassword()) {
				delete this.state.oldpassword;
			}

			const loggedInUser = this.props.user;

			if (this.shouldShowOldPassword()) {
				Auth.changePassword(loggedInUser, this.state.oldpassword, this.state.newpassword);
			} else {
				const newUser = await Auth.completeNewPassword(loggedInUser, this.state.newpassword);
				this.props.setUser(newUser);
			}

			this.props.setIsAuthenticated(true);

			this.setState({
				complete: true,
			});
		} catch (error) {
			let err = error?.message ? error : { message: error };

			this.setState({
				errors: { ...this.state.errors, cognito: err },
			});
		}
	};

	onInputChange = async (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	render() {
		if (this.state.complete) {
			return <Redirect to="/" />;
		}

		return (
			<Layout>
				<div className="login-form">
					<div className="grid place-content-center">
						<h1 className="legacy-h1 mb-8 text-center">Change Password</h1>

						<form onSubmit={this.handleSubmit}>
							<div className="field">
								<p className="control">
									<Input
										className="input"
										errorText="Something went wrong. Please try again."
										id="oldpassword"
										invalid={this.state.errors.blankfield || this.state.errors.cognito}
										label="Old Password"
										name="oldpassword"
										type="password"
										value={this.state.oldpassword}
										onChange={this.onInputChange}
									/>
								</p>
							</div>
							<div className="field">
								<p className="field">
									<Input
										className="input my-4"
										errorText="Something went wrong. Please try again."
										id="newpassword"
										invalid={this.state.errors.blankfield || this.state.errors.passwordmatch}
										label="New Password"
										name="newpassword"
										type="password"
										value={this.state.newpassword}
										onChange={this.onInputChange}
									/>
								</p>
							</div>
							<div className="field">
								<p className="control">
									<Input
										className="input"
										errorText="Something went wrong. Please try again."
										id="confirmpassword"
										invalid={this.state.errors.blankfield || this.state.errors.passwordmatch}
										label="Confirm Password"
										name="confirmpassword"
										onChange={this.onInputChange}
										type="password"
										value={this.state.confirmpassword}
									/>
								</p>
							</div>
							<div className="field">
								<p className="control">
									<Button
										className="mt-6 w-full"
										kind="primary"
										name="changepassword"
										onClick={this.handleSubmit}
										isLoading={this.state.isSubmitting}
									>
										Change Password
									</Button>
								</p>
							</div>
							<div className="field">
								<p className="control">
									<Link to="/forgotpassword" className="grid place-content-center text-center mt-2">
										Forgot password?
									</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
			</Layout>
		);
	}
}
const mapStateToProps = (state) => ({
	challengeName: state.ThemeOptions.challengeName,
	user: state.ThemeOptions.user,
});

const mapDispatchToProps = (dispatch) => ({
	setIsAuthenticated: (enable) => dispatch(setIsAuthenticated(enable)),
	setUser: (user) => dispatch(setUser(user)),
	setChallengeName: (challengeName) => dispatch(setChallengeName(challengeName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

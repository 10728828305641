import { createContext, useContext, useReducer } from 'react';
import { Banner } from '../../../../../../services/layoutService/layoutService.model';
import {
	emptyBanner,
	ManageBannersAction,
	manageBannersContextDefaultValue,
	manageBannersDefaultState,
	ManageBannersState,
} from './useManageBannersState.model';

const updateBannerContent = (state: ManageBannersState, updatedBanner: Banner): Banner[] => {
	const currentBannerIndex = state.sortedBanners.findIndex(({ id }) => id === updatedBanner.id);

	if (currentBannerIndex >= 0) {
		const updatedSortedBanners = [...state.sortedBanners];
		updatedSortedBanners[currentBannerIndex] = updatedBanner;
		return updatedSortedBanners;
	}

	return state.sortedBanners;
};

const useManageBannersReducer = (state: ManageBannersState, action: ManageBannersAction) => {
	switch (action.type) {
		case 'CHANGE_BANNER_ORDER':
			return { ...state, sortedBanners: action.payload };
		case 'UPDATE_BANNER_CONTENT':
			return { ...state, sortedBanners: updateBannerContent(state, action.payload) };
		case 'DELETE_BANNER':
			return { ...state, bannersToDelete: [...state.bannersToDelete, action.payload] };
		case 'COMMIT_NEW_BANNER':
			return {
				...state,
				newBanner: emptyBanner,
				sortedBanners: [...state.sortedBanners, action.payload],
				originalBanners: [...state.sortedBanners, action.payload],
			};
		case 'COMMIT_BANNER_CHANGES':
			return {
				...state,
				sortedBanners: action.payload,
				originalBanners: action.payload,
				bannersToDelete: [],
			};
		case 'UNDELETE_BANNER':
			return {
				...state,
				bannersToDelete: state.bannersToDelete.filter(({ id }) => id !== action.payload.id),
			};
		case 'UPDATE_NEW_BANNER':
			return {
				...state,
				newBanner: action.payload,
			};
		case 'DISCARD_BANNER_CHANGES':
			return {
				...state,
				sortedBanners: state.originalBanners,
				bannersToDelete: [],
			};
		case 'DISCARD_NEW_BANNER_CHANGES':
			return {
				...state,
				newBanner: emptyBanner,
			};
		default:
			return state;
	}
};

export const useManageBannersState = (originalBanners: Banner[]) =>
	useReducer(useManageBannersReducer, {
		...manageBannersDefaultState,
		sortedBanners: originalBanners,
		originalBanners,
	});

const manageBannersContext = createContext(manageBannersContextDefaultValue);
export const ManageBannersContextProvider = manageBannersContext.Provider;

export const useManageBannersContext = () => useContext(manageBannersContext);

import React from 'react';
import {
	ChevronDoubleLeft20Regular,
	ChevronDoubleRight20Regular,
	ChevronLeft20Regular,
	ChevronRight20Regular,
} from '@fluentui/react-icons';
import Dropdown from '../../Dropdown';
import { SimpleDropdownMenuItem } from '../../Dropdown/Dropdown.model';
import { useTable } from '../useTableContext';
import PageButton from './PageButton';

const Pagination = ({
	totalItems,
}: {
	totalItems?: number; // Only used for manual pagination
}) => {
	const {
		getCanNextPage,
		getCanPreviousPage,
		getPageCount,
		getPrePaginationRowModel,
		getState,
		setPageIndex,
		setPageSize,
	} = useTable();
	const { pagination } = getState();
	const { pageIndex: currentPageIndex, pageSize } = pagination;
	const { rows } = getPrePaginationRowModel();

	const pageSizeOptions: SimpleDropdownMenuItem[] = [10, 25, 50, 100, 1000, 5000].map((size) => ({
		label: `Show ${size}`,
		value: String(size),
	}));

	const pageSizeOption =
		pageSizeOptions.find((pageSizeOption) => Number(pageSizeOption.value) === pageSize) ||
		pageSizeOptions[0];

	const countOfResults = typeof totalItems !== 'undefined' ? totalItems : rows.length;

	return (
		<nav
			className="border-t-base relative z-0 inline-flex w-full items-center justify-end -space-x-px rounded-b-lg bg-neutral1 py-2"
			aria-label="Pagination"
		>
			<div className="mr-4">
				<span>{`Page ${
					currentPageIndex + 1
				} of ${getPageCount()} (${countOfResults} results)`}</span>
			</div>
			<div className="mr-4">
				<Dropdown
					options={pageSizeOptions}
					name="pageSize"
					onChange={({ value }) => setPageSize(Number(value))}
					value={pageSizeOption}
				/>
			</div>
			<div>
				<PageButton
					className="rounded-l-md"
					onClick={() => setPageIndex(0)}
					disabled={!getCanPreviousPage()}
				>
					<span className="sr-only">First</span>
					<ChevronDoubleLeft20Regular className="h-5 w-5" aria-hidden="true" />
				</PageButton>
				<PageButton
					onClick={() => setPageIndex(currentPageIndex - 1)}
					disabled={!getCanPreviousPage()}
				>
					<span className="sr-only">Previous</span>
					<ChevronLeft20Regular className="h-5 w-5" aria-hidden="true" />
				</PageButton>
				<PageButton onClick={() => setPageIndex(currentPageIndex + 1)} disabled={!getCanNextPage()}>
					<span className="sr-only">Next</span>
					<ChevronRight20Regular className="h-5 w-5" aria-hidden="true" />
				</PageButton>
				<PageButton
					className="rounded-r-md"
					onClick={() => setPageIndex(getPageCount() - 1)}
					disabled={!getCanNextPage()}
				>
					<span className="sr-only">Last</span>
					<ChevronDoubleRight20Regular className="h-5 w-5" aria-hidden="true" />
				</PageButton>
			</div>
		</nav>
	);
};

export default Pagination;

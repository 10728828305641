import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Address from '../../../../components/Address';
import Card from '../../../../components/Card';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import { Button } from '../../../../prizm-ui/Button';
import { Tag } from '../../../../prizm-ui/Tag';
import useUserById from '../../../../queries/useUserById';
import { User } from '../../../../services/userService/userService.model';
import { DATE_FORMAT, formatDate } from '../../../../utils/formatters';
import UserStatusChip from '../../CustomerManagerSearch/UserStatusChip';
import EditCustomerProfileModal from './EditCustomerProfileModal';
import RestrictedArea from '../../../../components/RestrictedArea/RestrictedArea';
import { format } from 'date-fns';
import moment from 'moment';

const CustomerHeader = () => {
	const { userId } = useParams<{ userId: string }>();
	const { data: user = {} as User, status: userStatus } = useUserById(userId);

	const [shouldShowEditProfileModal, setShouldShowEditProfileModal] = useState(false);

	return (
		<Card className="mb-4">
			<LoadingWrapper queryStatuses={[userStatus]}>
				<div className="flex justify-between">
					<div>
						<div className="w-fit flex">
							<UserStatusChip status={user.isDisabled ? 'Inactive' : 'Active'} as="Tag" />
							{user.isVip && (
								<div className="ml-2 uppercase">
									<Tag variant="primary">VIP User</Tag>
								</div>
							)}
						</div>
						<h1 className="text-display4">{`${user.givenName} ${user.familyName}`.trim()}</h1>
						<div className="text-caption1 uppercase">
							Registered on: {formatDate(user.registrationDate)}
						</div>
						{user.goldinAuthVersion !== 2 ? (
							<div className="text-caption1 uppercase">
								{user.goldinAuthVersion === 1
									? `Migrated on: ${
											user.cidMigratedOn
												? format(moment(user.cidMigratedOn).toDate(), DATE_FORMAT)
												: 'Unknown'
									  }`
									: 'Not migrated'}
							</div>
						) : undefined}

						<div className="mt-4 flex flex-col md:flex-row">
							<div>
								<div className="text-subtitle1">Contact Info</div>
								<div>Email: {user.email}</div>
								<div>Phone: {user.phoneNumber}</div>
							</div>
							<div className="mt-4 md:mt-0 md:ml-8 md:pl-8">
								<div className="text-subtitle1">Shipping Address</div>
								<Address
									firstName={user.givenName}
									lastName={user.familyName}
									line1={user?.shippingAddress?.line1}
									line2={user?.shippingAddress?.line2}
									city={user?.shippingAddress?.city}
									state={user?.shippingAddress?.state}
									postalCode={user?.shippingAddress?.postalCode}
									country={user?.shippingAddress?.country}
								/>
							</div>
							<div className="mt-4 md:mt-0 md:ml-8 md:pl-8">
								<div className="text-subtitle1">Payout Address</div>
								<Address
									firstName={user.givenName}
									lastName={user.familyName}
									line1={user?.paymentAddress?.line1}
									line2={user?.paymentAddress?.line2}
									city={user?.paymentAddress?.city}
									state={user?.paymentAddress?.state}
									postalCode={user?.paymentAddress?.postalCode}
									country={user?.paymentAddress?.country}
								/>
							</div>
						</div>
					</div>
					<RestrictedArea allowedRoles={['Admin', 'CustomerSupport', 'Finance']}>
						<Button
							size="md"
							trailingIcon="Edit"
							onClick={() => setShouldShowEditProfileModal(true)}
						>
							Edit Profile
						</Button>
						{shouldShowEditProfileModal && (
							<EditCustomerProfileModal onClose={() => setShouldShowEditProfileModal(false)} />
						)}
					</RestrictedArea>
				</div>
			</LoadingWrapper>
		</Card>
	);
};

export default CustomerHeader;

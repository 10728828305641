import { noop } from 'lodash';
import React, { ChangeEventHandler } from 'react';
import { StructuredFeeAdjustment } from '../../../../../../../services/ordersService/ordersService.model';
import { EditableLineItem, VanillaLineItem } from '../LineItem';
import { useEditSummaryContext } from '../useEditSummary';

const OrderSummaryStructuredFee = ({
	label,
	amount,
	className,
	adjustments = [],
	isEditable = true,
	name,
	onChange = noop,
	onReset = noop,
}: {
	label: string;
	amount: number;
	className?: string;
	adjustments?: StructuredFeeAdjustment[];
	isEditable?: boolean;
	name: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onReset?: () => void;
}) => {
	const totalAdjustments = adjustments.reduce(
		(accumulator, thisAdjustment) => accumulator + Number(thisAdjustment.amount),
		0
	);
	const adjustedAmount = amount + totalAdjustments;

	const shouldIgnoreAdjustments = ['shipping', 'handling', 'insurance'].includes(name);

	const { isEditing } = useEditSummaryContext();

	return (
		<div className={`my-2 flex w-full items-start ${className}`}>
			<div className="w-8" />
			<div className="ml-0.5 w-full">
				{isEditable ? (
					<EditableLineItem
						kind="primary"
						amount={shouldIgnoreAdjustments ? amount : adjustedAmount}
						name={name}
						onChange={onChange}
						onReset={onReset}
					>
						{label}
					</EditableLineItem>
				) : (
					<div className={isEditing ? 'mt-4' : ''}>
						<VanillaLineItem
							kind="primary"
							amount={shouldIgnoreAdjustments ? amount : adjustedAmount}
						>
							{label}
						</VanillaLineItem>
					</div>
				)}
			</div>
		</div>
	);
};

export default OrderSummaryStructuredFee;

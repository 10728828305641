export interface DeleteLotAdjustmentModalState {
	adjustmentId: string;
	description: string;
	isOpen: boolean;
}
export const deleteLotAdjustmentModalBaseState: DeleteLotAdjustmentModalState = {
	adjustmentId: '',
	description: '',
	isOpen: false,
};

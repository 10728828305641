import fileDownload from 'js-file-download';
import { Button } from '../../../../../prizm-ui/Button';
import useAuctionById from '../../../../../queries/useAuctionById';
import useSellerStatementDetails from '../../../../../queries/useSellerStatementDetails';
import useSellerStatementPDF from '../../../../../queries/useSellerStatementPDF';
import { Auction } from '../../../../../services/auctionsService';
import { KernelSellerStatementDetails } from '../../../../../services/sellerStatementService';

const DownloadStatementPDFButton = ({
	auctionId,
	statementId,
}: {
	auctionId: string;
	statementId: string;
}) => {
	const {
		data: statementDetails = {} as KernelSellerStatementDetails,
		status: statementDetailsStatus,
	} = useSellerStatementDetails({
		auctionId,
		statementId,
	});

	const { data: auction = {} as Auction, status: auctionStatus } = useAuctionById(auctionId);

	const { data: sellerStatementPDF = new Blob(), status: sellerStatementPDFStatus } =
		useSellerStatementPDF({
			auctionId,
			statementId,
		});

	const isLoading = [auctionStatus, statementDetailsStatus, sellerStatementPDFStatus].includes(
		'loading'
	);

	const handleDownloadClick = () =>
		fileDownload(
			new Blob([sellerStatementPDF]),
			`Seller Statement ${statementDetails.seller_statement_number} from ${auction.title}.pdf`
		);

	return (
		<Button
			variant="secondary"
			size="md"
			trailingIcon="ArrowCircleDown"
			className="w-full"
			onClick={handleDownloadClick}
			disabled={isLoading}
			loading={isLoading}
		>
			Download Statement PDF
		</Button>
	);
};

export default DownloadStatementPDFButton;

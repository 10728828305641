import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { DraftSellerStatementAdjustment } from './sellerStatementService.model';

export const createSellerStatementAdjustment = async ({
	auctionId,
	statementId,
	adjustment,
}: {
	auctionId: string;
	statementId: string;
	adjustment: DraftSellerStatementAdjustment;
}) => {
	const response = await axiosAuth.post(`${config.api.add_statement_adjustment}`, {
		auction_id: auctionId,
		seller_statement_id: statementId,
		adjustment,
	});

	if (response.status >= 400) {
		throw response;
	}

	return response.data.body;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';

export const regenerateSellerStatementByLot = async (lotId: string) => {
	const response = await axiosAuth.post(`${config.api.regenerate_seller_statement}`, {
		lot_id: lotId,
	});

	if (response.data.statusCode === 400) {
		throw response;
	}

	return response.data.body;
};

import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { KernelFacet } from './getFacets.model';

export const getFacets = async ({ auctionId }: { auctionId: string }): Promise<KernelFacet[]> => {
	const response = await axiosAuth.post(apiEndpoints.lots, {
		queryType: 'Facet',
		auction_id: [auctionId],
	});
	if (response.status >= 400) {
		throw response;
	}
	return response.data.facets;
};

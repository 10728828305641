import { reassignLotToAuction } from './reassignLotToAuction';

export const bulkReassignLotsToAuction = async ({
	lotIds,
	destinationAuctionId,
}: {
	lotIds: string[];
	destinationAuctionId: string;
}) =>
	Promise.all(
		lotIds.map(
			async (lotIdToReassign) =>
				await reassignLotToAuction({ lotId: lotIdToReassign, destinationAuctionId })
		)
	);

import { axiosAuth } from '../../AdminUtils/httpUtils';
import config from '../../config';
import { KernelLotHistory } from './lotsService.model';

export const getLotHistory = async ({ lotId }: { lotId: string }): Promise<KernelLotHistory> => {
	const response = await axiosAuth.get<KernelLotHistory>(`${config.api.lots}/${lotId}/history`);

	if (response.status >= 400) {
		throw response;
	}

	return response.data;
};

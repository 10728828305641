import { isEqual, uniq } from 'lodash';

/** @description Given two objects of the same type, return on the fields that have changed */
export const getDeltas = <T extends Partial<Record<keyof T, unknown>>>({
	olderVersion,
	newerVersion,
}: {
	olderVersion: T;
	newerVersion: Partial<T>;
}): Partial<T> => {
	const olderKeys = Object.keys(olderVersion);
	const newerKeys = Object.keys(newerVersion);
	const allKeys = uniq([...olderKeys, ...newerKeys]);

	const deltas: Partial<T> = allKeys.reduce((accumulator: Partial<T>, key: string) => {
		const typedKey = key as keyof T;
		if (!isEqual(olderVersion[typedKey], newerVersion[typedKey])) {
			return { ...accumulator, [typedKey]: newerVersion[typedKey] };
		}
		return accumulator;
	}, {} as Partial<T>);

	return deltas;
};

import { isBefore, isValid } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../../components/Button';
import EditDueDateModal from './EditDueDateModal';
import Label from '../../../../../../../components/Label';
import { CheckoutOrder } from '../../../../../../../services/ordersService/ordersService.model';
import useOrderByNumber from '../../../../../../../queries/useOrderByNumber';
import { formatDate } from '../../../../../../../utils/formatters';

const DueDateSection = () => {
	const { orderNumber } = useParams<{ orderNumber: string }>();
	const { data: order = {} as CheckoutOrder } = useOrderByNumber({
		orderNumber,
		enabled: true,
	});

	const [shouldShowEditDueDateModal, setShouldShowEditDueDateModal] = useState(false);

	const getDueDateTextClass = () => {
		const invalidClass = 'font-bold text-red';

		if (!isValid(new Date(order.dueDate))) {
			return invalidClass;
		}

		const isPastDueDate = isBefore(new Date(order.dueDate), new Date());

		return isPastDueDate ? invalidClass : '';
	};

	return (
		<section className="mt-4">
			<div className="flex justify-between">
				<Label>Due Date</Label>
				{order.invoiceStatus !== 'Paid' && (
					<Button
						className="link"
						kind="tertiary"
						isInline={true}
						onClick={() => setShouldShowEditDueDateModal(true)}
					>
						Edit Due Date
					</Button>
				)}
			</div>
			<p className={getDueDateTextClass()}>
				{formatDate(order.dueDate, { invalidDateMessage: 'Invalid due date provided' })}
			</p>
			{shouldShowEditDueDateModal && (
				<EditDueDateModal onClose={() => setShouldShowEditDueDateModal(false)} />
			)}
		</section>
	);
};

export default DueDateSection;

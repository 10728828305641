import { DropdownMenuItem } from '../../../../../components/Dropdown/Dropdown.model';

export interface CategoryAmountIsValidReturn {
	categoryAmountErrorMessage: string;
	categoryAmountIsValid: boolean;
}

export interface CategoryAuctionIsValidReturn {
	categoryAuctionErrorMessage: string;
	categoryAuctionIsValid: boolean;
}

export const CATEGORY_DROPDOWN_OPTIONS: DropdownMenuItem[] = [
	{
		label: 'Adjustment',
		value: 'adjustment',
	},
	{
		label: 'Advance',
		value: 'advance',
	},
	{
		label: 'Offset',
		value: 'offset',
	},
	{
		label: 'Proceeds',
		value: 'deposit',
	},
	{
		label: 'Payment',
		value: 'purchase',
	},
	{
		label: 'Payout',
		value: 'withdrawal',
	},
];

import React from 'react';
import { ErrorMessage, Field, Form } from 'formik';
import Button from '../../../../Button';
import BidderSelector from './BidderSelector';
import { BidderDropdownMenuItem, PlaceBidModalFormProps } from '../PlaceBidModal.model';
import MaxBidInput from './MaxBidInput';
import { QueryStatus } from 'react-query';

const PlaceBidForm = ({
	minimumViableBid,
	setSelectedBidder,
	isValid,
	dirty,
	placeMaximumBidStatus,
}: {
	minimumViableBid: number;
	setSelectedBidder: (selectedBidder: BidderDropdownMenuItem) => void;
	isValid: boolean;
	dirty: boolean;
	placeMaximumBidStatus: QueryStatus;
}) => {
	return (
		<Form>
			<div className="mb-4">
				<Field name="bidder">
					{(formikProps: PlaceBidModalFormProps) => (
						<BidderSelector
							minimumViableBid={minimumViableBid}
							setSelectedBidder={setSelectedBidder}
							{...formikProps}
						/>
					)}
				</Field>
			</div>
			<div className="mb-4">
				<Field name="maxBid">
					{(formikProps: PlaceBidModalFormProps) => (
						<MaxBidInput minimumViableBid={minimumViableBid} {...formikProps} />
					)}
				</Field>
			</div>
			<div className="flex w-full items-center justify-between">
				<div className="mr-4">
					<ErrorMessage name="bidder" component="span" className="text-red-600" />
					<ErrorMessage name="maxBid" component="span" className="text-red-600" />
				</div>
				<Button
					kind="primary"
					disabled={!(dirty && isValid)}
					type="submit"
					isLoading={placeMaximumBidStatus === 'loading'}
				>
					Submit Bid
				</Button>
			</div>
		</Form>
	);
};

export default PlaceBidForm;

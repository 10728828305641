import { useQuery } from 'react-query';
import { QueryKeys } from '../queryKeys';
import { FixedPriceOrderSearchParams } from '../../services/ordersService/ordersService.model';
import { getFixedPriceOrders } from '../../services/ordersService';

const useFixedPriceOrders = (params: FixedPriceOrderSearchParams) => {
	const isValidForSearchByQuery = params.searchType === 'query' && !!params.searchQuery;
	const isValidForSearchByDateRange =
		params.searchType === 'date-range' && (params.fromDate || params.toDate);

	return useQuery(
		[QueryKeys.GetFixedPriceOrders, ...Object.values(params)],
		() => getFixedPriceOrders(params),
		{
			enabled: !!(isValidForSearchByQuery || isValidForSearchByDateRange),
		}
	);
};

export default useFixedPriceOrders;

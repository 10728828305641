import { useQuery } from 'react-query';
import { getFacets } from '../../services/facetsService';
import { QueryKeys } from '../queryKeys';

const useFacets = ({ auctionId }: { auctionId: string }) =>
	useQuery([QueryKeys.GetFacets, auctionId], () => getFacets({ auctionId }), {
		enabled: !!auctionId,
	});

export default useFacets;

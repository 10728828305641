import { ChangeEventHandler, useState } from 'react';
import { QueryStatus } from 'react-query';
import { Button } from '../../../../prizm-ui/Button';
import Card from '../../../../components/Card';
import HtmlTextarea from '../../../../components/HtmlTextarea';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import RestrictedArea from '../../../../components/RestrictedArea';
import { KernelLot } from '../../../../services/lotsService/lotsService.model';
import { EDITOR_ROLES } from '../../../../services/userService/userService.model';

interface BaseEditGeneralLotInfoProps {
	lot: KernelLot;
	saveStatus: QueryStatus;
	onClose?: () => void;
	onCreate?: (newLot: KernelLot) => void;
	onUpdate?: (newLot: Partial<KernelLot>, onSuccess: () => void) => void;
	isNewLot: boolean;
}

type EditGeneralLotInfoProps = BaseEditGeneralLotInfoProps &
	(
		| { isNewLot: true; onUpdate?: never; onClose?: never }
		| {
				isNewLot: false;
				onCreate?: never;
				onClose?: never;
		  }
		| { isNewLot: true }
	);

const EditGeneralLotInfo = ({
	isNewLot,
	lot,
	saveStatus,
	onClose,
	onCreate,
	onUpdate,
}: EditGeneralLotInfoProps) => {
	const [title, setTitle] = useState(lot.title);
	const [hasTitleChanged, setHasTitleChanged] = useState(false);
	const handleTitleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		if (value !== title && !hasTitleChanged) {
			setHasTitleChanged(true);
		}

		if (value === title && hasTitleChanged) {
			setHasTitleChanged(false);
		}
		setTitle(value);
	};

	const [description, setDescription] = useState(lot.description);
	const [hasDescriptionChanged, setHasDescriptionChanged] = useState(false);
	const handleDescriptionChange = (value: string) => {
		setHasDescriptionChanged(true);
		setDescription(value);
	};

	const isTitleValid = !!(title && title.trim().length);
	const canTakeAction = hasTitleChanged || hasDescriptionChanged;

	const handleCancel = () => {
		setTitle(lot.title);
		setHasTitleChanged(false);

		setDescription(lot.description);
		setHasDescriptionChanged(false);

		onClose && onClose();
	};

	const lotChanges = isNewLot
		? ({ ...lot, title, description } as KernelLot)
		: ({ lot_id: lot.lot_id, title, description } as Partial<KernelLot>);

	const handleLotUpdateSuccess = () => {
		setHasDescriptionChanged(false);
		setHasTitleChanged(false);
	};
	const onSave = () => {
		if (!isTitleValid) {
			return;
		}
		isNewLot
			? onCreate && onCreate(lotChanges as KernelLot)
			: onUpdate && onUpdate(lotChanges, handleLotUpdateSuccess);
	};

	return (
		<Card className="mb-8">
			<h2 className="text-subtitle1 mb-4">General Information for {lot.title || 'Unnamed Lot'}</h2>
			<div className="mb-8">
				<div className="mb-4">
					<Label htmlFor="title">Name</Label>
					<Input
						allowedRoles={EDITOR_ROLES}
						className="w-full"
						name="title"
						onChange={handleTitleChange}
						value={title}
					/>
				</div>
				<div>
					<Label htmlFor="description">Description</Label>
					<HtmlTextarea
						allowedRoles={EDITOR_ROLES}
						initialValue={description}
						onChange={handleDescriptionChange}
						placeholder="Enter a description for the lot"
					/>
				</div>
			</div>
			<RestrictedArea allowedRoles={EDITOR_ROLES}>
				<div className="flex">
					<Button
						className="mr-2"
						disabled={!canTakeAction || !isTitleValid}
						loading={saveStatus === 'loading'}
						size="md"
						variant="brand"
						onClick={onSave}
					>
						Save Changes
					</Button>
					<Button disabled={!canTakeAction} variant="secondary" size="md" onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			</RestrictedArea>
		</Card>
	);
};

export default EditGeneralLotInfo;

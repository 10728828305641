import formatCurrency from '../../../../../../../utils/formatters/formatCurrency';
import { LotStatus } from '../../../../../../../services/lotsService/lotsService.model';
import { getTestIdentifier } from '../../../../../../../utils/test-identifiers/getTestIdentifier';

const BidAmountCell = ({
	bidAmount,
	lotId,
	lotStatus,
	openPlaceBidModal,
	title,
}: {
	bidAmount?: number;
	lotId: string;
	lotStatus: LotStatus;
	openPlaceBidModal: (selectedLotId: string) => void;
	title: string;
}) => (
	<>
		<div className="flex w-full items-center justify-between">
			<div className={`w-full`}>{formatCurrency(bidAmount)}</div>
			{lotStatus === 'Live' ? (
				<button
					className="link ml-2"
					onClick={() => openPlaceBidModal(lotId)}
					title={`Bid on ${title}`}
					data-testid={getTestIdentifier({
						componentName: 'BidAmountCell',
						descriptor: 'bid-table-bid-button',
						pagePath: 'customer',
						sectionPath: 'usermanagement',
						tabPath: 'bids',
					})}
				>
					Bid
				</button>
			) : (
				<div className="w-11" />
			)}
		</div>
	</>
);

export default BidAmountCell;

import { format, isValid } from 'date-fns';
import moment from 'moment';

interface FormatDateOptions {
	dateFormat: string; // following date-fns conventions
	invalidDateMessage: string;
	utc?: boolean;
}

export const DATE_FORMAT = 'P p';

export const formatDate = (timestamp = '', options?: Partial<FormatDateOptions>) => {
	const {
		dateFormat = DATE_FORMAT,
		invalidDateMessage = 'Invalid Date',
		utc = false,
	} = options || {};
	try {
		if (typeof timestamp !== 'string' || !isValid(new Date(timestamp))) {
			return invalidDateMessage;
		}

		if (utc) {
			return moment.utc(timestamp).local().format('YYYY-MM-DDTHH:mm');
		}

		const localizedTimestamp = timestamp.includes('Z') ? timestamp : `${timestamp}Z`;

		return format(new Date(localizedTimestamp), dateFormat);
	} catch (e) {
		return invalidDateMessage;
	}
};

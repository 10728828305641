import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AddLotsToAuction from '../AddLotsToAuction';
import GeneralLotOptions from '../GeneralLotOptions';
import LotManagerFilters from '../LotManagerFilters';
import { LotManagerFiltersType } from '../LotManagerFilters/LotManagerFilters.model';
import { getSummaryLotsFilters } from '../LotManagerFilters/LotManagerFilters.utils';
import ManageIndividualLot from '../../../../components/ManageIndividualLot';
import LotSelector from '../../../../components/LotSelector';
import useListOfLots from '../../../../queries/useListOfLots';

const LotManagerView = () => {
	const { auctionId, lotId } = useParams<{ auctionId: string; lotId: string }>();
	const { pathname } = useLocation();

	const isInAuctionManager = auctionId !== 'Unassigned' && pathname.includes('auctionmanager');

	const initialFilterSettings = {} as LotManagerFiltersType;
	const [filters, setFilters] = useState<LotManagerFiltersType>(initialFilterSettings);

	const {
		data: listOfLots = { lots: [], total: 0 },
		status: listOfLotsStatus,
		refetch: refetchLotsForAuction,
	} = useListOfLots({
		params: {
			auctionId,
			kind: 'assigned',
		},
		filters: getSummaryLotsFilters(filters),
		include: [
			'cert_number',
			'commission',
			'contract_id',
			'current_price',
			'estimated_value',
			'grading_company',
			'item_type',
			'reserve_amount',
			'seller_email',
			'year',
		],
	});

	useEffect(() => {
		refetchLotsForAuction();
	}, [auctionId]);

	return (
		<div className="pb-8">
			<GeneralLotOptions />
			{isInAuctionManager && <AddLotsToAuction />}
			<LotManagerFilters
				setFilters={setFilters}
				filters={filters}
				filteredLots={listOfLots.lots ?? []}
			/>
			<LotSelector
				lots={listOfLots.lots}
				isLoading={listOfLotsStatus === 'loading'}
				key={listOfLots.total}
			/>
			{lotId && <ManageIndividualLot isInAuctionManager={isInAuctionManager} />}
		</div>
	);
};

export default LotManagerView;

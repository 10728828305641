import { Route, Redirect } from 'react-router-dom';
import RequireAuth from '../../routing/RequireAuth';
import Layout from '../../components/Layout';
import ProtectedRoute from '../../routing/ProtectedRoute/ProtectedRoute';
import CarouselEditor from './BannerEditor';
import { UserRoleName } from '../../services/userService/userService.model';
import useUserRole from '../../queries/useUserRole/useUserRole';

const CustomizeRouter = ({ match }: { match: { url: string } }) => {
	const carouselEditorAllowedRoles = ['Admin', 'AuctionManager', 'Editorial'];

	const { data: userRole = 'Unknown' as UserRoleName } = useUserRole();

	return (
		<RequireAuth>
			<Layout>
				<ProtectedRoute
					component={CarouselEditor}
					isAllowedByRole={carouselEditorAllowedRoles.includes(userRole)}
					path={`${match.url}/carousel`}
				/>
			</Layout>
			<Route exact path={`${match.url}`} render={() => <Redirect to={`${match.url}/carousel`} />} />
		</RequireAuth>
	);
};

export default CustomizeRouter;

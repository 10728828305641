import { TypedDropdownMenuItem } from '../../../../../../components/Dropdown/Dropdown.model';
import { CheckoutOrderCollectionsStatus } from '../../../../../../services/ordersService/ordersService.model';

export const BASE_COLLECTIONS_STATUS_OPTIONS: TypedDropdownMenuItem<CheckoutOrderCollectionsStatus>[] =
	[
		{
			label: 'Active',
			value: 'active',
		},
		{
			label: 'Defaulted',
			value: 'defaulted',
		},
		{
			label: 'En route',
			value: 'en_route',
		},
		{
			label: 'VIP',
			value: 'vip',
		},
	];

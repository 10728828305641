import { useQuery, useQueryClient } from 'react-query';
import { showErrorToast } from '../../components/Toast';
import { getPluralLots } from '../../services/lotsService/getPluralLots';
import { KernelLot } from '../../services/lotsService/lotsService.model';
import { QueryKeys } from '../queryKeys';

const usePluralLots = (lotIds: string[]) => {
	const baseQuery = useQuery([QueryKeys.GetPluralLots, ...lotIds], () => getPluralLots(lotIds), {
		enabled: lotIds.length > 0,
		onError: () =>
			showErrorToast('Something went wrong while retrieving lots. Please refresh and try again.'),
	});

	const queryClient = useQueryClient();

	const updateCacheForIndividualLots = (updatedLots: Partial<KernelLot>[]) =>
		updatedLots.forEach((updatedLot) => {
			queryClient.setQueryData(
				[QueryKeys.GetIndividualLot, updatedLot.lot_id],
				(oldData: KernelLot = {} as KernelLot) => ({ ...oldData, ...updatedLot })
			);
		});

	return { ...baseQuery, updateCacheForIndividualLots };
};

export default usePluralLots;

import { AxiosResponse } from 'axios';
import { axiosAuth } from '../../AdminUtils/httpUtils';
import apiEndpoints from '../../queries/apiEndpoints';
import { Auction } from '../auctionsService/auctionsService.model';
import { WatchlistLot, KernelWatchlistLot } from './getWatchlistForUser.model';

const getWatchlistForUser = async ({
	userId,
	auctions,
}: {
	userId: string;
	auctions: Auction[];
}): Promise<WatchlistLot[]> => {
	const queryURL = `${apiEndpoints.bidder_info_actions}/${userId}/watchlist`;
	const response = await axiosAuth.post<
		AxiosResponse<{
			body: {
				lots: KernelWatchlistLot[];
				total: number;
			};
			statusCode: string;
		}>
	>(queryURL, {});

	if (response.status >= 400) {
		throw response;
	}

	const { lots = [] as KernelWatchlistLot[] } = response.data.body;

	return lots.map((item: KernelWatchlistLot) => ({
		auctionId: item.auction_id,
		auction: {
			label: auctions?.find((a) => a.id == item.auction_id)?.title,
			url: `/auctions/auctionmanager/${item.auction_id}`,
		},
		startTimestamp: item.start_timestamp,
		lotNumber: item.lot_number,
		lotId: item.lot_id,
		lot: {
			label: item.lot_number,
			url: `/auctions/${item.auction_id}/inventory-manager/${item.lot_id}`,
		},
		title: {
			label: item.title,
			url: `https://${process.env.PUBLIC_SITE_SUBDOMAIN}.goldin.co/item/${item.meta_slug}`,
		},
		description: item.description,
	}));
};

export default getWatchlistForUser;

import React from 'react';
import formatCurrency from '../../../../../../../../utils/formatters/formatCurrency';

const CurrencyValue = ({ amount, className = '' }: { amount?: number; className?: string }) => {
	return (
		<span className={`${className} ${amount !== undefined && amount < 0 ? 'text-red' : ''}`}>
			{formatCurrency(amount) !== '$0.00' ? (
				formatCurrency(amount)
			) : (
				<span className="mr-2">-</span>
			)}
		</span>
	);
};

export default CurrencyValue;

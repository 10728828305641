import { Link, useHistory } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import SortableTable from '../../../../components/SortableTable';
import { Tooltip } from '../../../../prizm-ui/Tooltip';
import { SortableTableParams } from '../../../../components/SortableTable/SortableTable.model';
import formatCurrency from '../../../../utils/formatters/formatCurrency';
import { BidActivityForUser } from '../../../../queries/useBidActivityForUser/getBidActivityForUser.model';
import useBidActivityForUser from '../../../../queries/useBidActivityForUser';
import useInvoicesByUser from '../../../../queries/useInvoicesByUser';
import Label from '../../../../components/Label';
import { formatDate } from '../../../../utils/formatters';

const ActivityTab = ({
	userId,
	onClickTabbedLink,
}: {
	userId: string;
	onClickTabbedLink: ({ label, path }: { label: string; path: string }) => void;
}) => {
	const { data: userAuctions = [], status: userAuctionsStatus } = useBidActivityForUser(userId);

	const { data: invoicesByUser = [], status: invoicesByUserStatus } = useInvoicesByUser({
		filters: {
			invoice_status: '',
			shipping_status: '',
		},
		userId,
	});

	const history = useHistory();

	const handleClick = ({ label, path, url }: { label: string; path: string; url: string }) => {
		onClickTabbedLink({ label, path });

		history.push(url);
	};

	const handleWonClick = ({
		label,
		path,
		selectedAuctionId,
	}: {
		label: string;
		path: string;
		selectedAuctionId: string;
	}) => {
		const relevantInvoices = invoicesByUser.invoices.filter(
			({ auction_id, winner_id }) => auction_id === selectedAuctionId && winner_id === userId
		);

		const [firstRelevantInvoice = {}] = relevantInvoices;

		if (firstRelevantInvoice.uses_checkout) {
			history.push(`/orders/${firstRelevantInvoice.invoice_number}`);
		} else {
			onClickTabbedLink({ label, path });

			history.push(`/usermanagement/customer/${userId}/invoices`);
		}
	};

	const columns: ColumnDef<BidActivityForUser>[] = [
		{
			accessorFn: ({ endTimestamp }) => formatDate(endTimestamp),
			header: 'End Date',
		},
		{
			accessorKey: 'title',
			cell: ({
				getValue,
				row: {
					original: { auctionId },
				},
			}) => (
				<Tooltip
					content={`View in "Create and Edit Auctions"`}
					id={getValue() as string}
					placement="top"
					trigger="hover"
				>
					<Link className="block link" to={`/auctions/auctionmanager/${auctionId}`}>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
			header: 'Auction',
		},
		{
			accessorKey: 'numberOfBids',
			cell: ({
				getValue,
				row: {
					original: { auctionId },
				},
			}) => (
				<Tooltip content={`View in Bids tab`} id={auctionId} placement="top" trigger="hover">
					<Link
						className="block link"
						to="#"
						onClick={() =>
							handleClick({
								label: 'Bids',
								path: 'bids',
								url: `/usermanagement/customer/${userId}/bids/${auctionId}`,
							})
						}
					>
						{getValue() as string}
					</Link>
				</Tooltip>
			),
			header: 'Bids',
		},
		{
			accessorKey: 'numberOfWonLots',
			header: 'Won',
		},
		{
			accessorKey: 'totalValueOfWonLots',
			cell: ({
				getValue,
				row: {
					original: { auctionId, numberOfWonLots },
				},
			}) =>
				numberOfWonLots > 0 ? (
					<div className="text-right">
						<Tooltip
							content="View invoice"
							id={`${userId}-${auctionId}`}
							placement="top"
							trigger="hover"
						>
							<Link
								className="block link"
								to="#"
								onClick={() =>
									handleWonClick({
										label: 'Invoices',
										path: 'invoices',
										selectedAuctionId: auctionId,
									})
								}
							>
								{formatCurrency(getValue() as number)}
							</Link>
						</Tooltip>
					</div>
				) : (
					<div className="text-right">{formatCurrency(getValue() as number)}</div>
				),
			header: '$ Won',
		},
	];

	const sortableTableParams: SortableTableParams<BidActivityForUser> = {
		columns,
		data: userAuctions,
		getRowId: ({ lotId }) => lotId,
	};

	return (
		<div className="px-4">
			<Label>Activity</Label>
			<div className="mt-4">
				<LoadingWrapper queryStatuses={[userAuctionsStatus, invoicesByUserStatus]}>
					<SortableTable
						globalFilterPlaceholder="Filter ..."
						key={userId}
						noResultsMessage="No activity to show"
						tableParams={sortableTableParams}
					/>
				</LoadingWrapper>
			</div>
		</div>
	);
};

export default ActivityTab;

import { useState } from 'react';
import { QueryStatus, useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AuctionPicker from '../../AuctionPicker';
import Card from '../../Card';
import Checkbox from '../../Checkbox';
import Toast from '../../Toast';
import { Button } from '../../../prizm-ui/Button';
import { toast } from 'react-toastify';
import { KernelLot } from '../../../services/lotsService/lotsService.model';
import { QueryKeys } from '../../../queries/queryKeys';
import { reassignLotToAuction } from '../../../services/lotsService';
import useIndividualLot from '../../../queries/useIndividualLot';
import useLotsForAuction from '../../../queries/useLotsForAuction';

const ReassignLot = ({ initialAuctionId }: { initialAuctionId: string }) => {
	const { lotId } = useParams<{ lotId: string; auctionId: string }>();
	const { pathname } = useLocation();

	const history = useHistory();

	const [selectedAuctionId, setSelectedAuctionId] = useState(initialAuctionId);
	const [savedAuctionId, setSavedAuctionId] = useState(initialAuctionId);

	const [artificialLoadStatus, setArtificialLoadStatus] = useState<QueryStatus>('idle');

	const [shouldKeepThisLotInFocus, setShouldKeepThisLotInFocus] = useState(false);

	const { mutate: doReassignLot, status: reassignLotStatus } = useMutation({
		mutationKey: [QueryKeys.ReassignLot, initialAuctionId, selectedAuctionId],
		mutationFn: (auctionId: string) =>
			reassignLotToAuction({
				lotId,
				destinationAuctionId: auctionId,
			}),
	});

	const { data: thisLot = {} as KernelLot } = useIndividualLot(lotId);

	const { removeLotsFromCache: removeLotsFromOldAuctionCache } = useLotsForAuction({
		auctionId: initialAuctionId || 'Unassigned',
	});
	const { addLotsToCache: addLotsToNewAuctionCache } = useLotsForAuction({
		auctionId: selectedAuctionId,
	});

	const isVaultedCollectorsItem =
		!!thisLot.collectible_id &&
		(thisLot.location === 'vault vault' || `${thisLot.bin} ${thisLot.room}` === 'vault vault');

	const canReassignVaultedCollectorsItem =
		isVaultedCollectorsItem && !['Completed_Unsold', 'Withdrawn'].includes(thisLot.status);

	const canTakeAction =
		initialAuctionId !== selectedAuctionId &&
		selectedAuctionId !== savedAuctionId &&
		reassignLotStatus !== 'loading' &&
		thisLot.status !== 'Completed_Sold';

	const handleReassignLot = () => {
		doReassignLot(selectedAuctionId, {
			onSuccess: () => {
				setSavedAuctionId(selectedAuctionId);
				addLotsToNewAuctionCache([thisLot]);
				setArtificialLoadStatus('loading');

				/** ElasticSearch asynchronously updates the results we get when fetching lots for an auction.
				 *  This means the backend will give a stale read for a short period after reassigning lots.
				 *  Sucks to add an arbitrary, artificial delay here, but this will generally prevent pushing
				 *  into the destination auction and not having the affected lot show up in the LotSelector.
				 */
				setTimeout(() => {
					toast(<Toast sentiment="success" message="Successfully reassigned lot." />);
				}, 2500);
				setTimeout(() => {
					removeLotsFromOldAuctionCache([lotId]);
					const nextPath = shouldKeepThisLotInFocus
						? `/auctions/${selectedAuctionId}/inventory-manager/${lotId}`
						: `/auctions/${initialAuctionId}/inventory-manager`;

					setShouldKeepThisLotInFocus(false);

					if (!pathname.includes('/inventory/listings')) {
						history.push(nextPath);
					}
					setArtificialLoadStatus('idle');
				}, 3000);
			},
			onError: () => {
				toast(
					<Toast
						sentiment="warning"
						message="Something went wrong reassigning this lot. Please try again later."
					/>
				);
			},
		});
	};

	const handleCancel = () => setSelectedAuctionId(initialAuctionId);

	return (
		<Card>
			<h2 className="mb-4 text-display5">Reassign Lot</h2>
			<AuctionPicker
				isDisabled={thisLot.status === 'Completed_Sold' && !canReassignVaultedCollectorsItem}
				selectedAuctionId={selectedAuctionId}
				setSelectedAuctionId={setSelectedAuctionId}
			/>
			<div className="mt-2">
				<Checkbox
					name="keep-in-focus"
					label="Keep lot in focus after reassigning"
					checked={shouldKeepThisLotInFocus}
					onChange={setShouldKeepThisLotInFocus}
				/>
			</div>
			<div className="mt-4 flex">
				<Button
					disabled={!canTakeAction && !canReassignVaultedCollectorsItem}
					loading={[reassignLotStatus, artificialLoadStatus].includes('loading')}
					size="md"
					variant="brand"
					onClick={handleReassignLot}
				>
					Reassign Lot
				</Button>
				<Button
					className="ml-2"
					disabled={!canTakeAction && !canReassignVaultedCollectorsItem}
					size="md"
					variant="secondary"
					onClick={handleCancel}
				>
					Cancel
				</Button>
			</div>
		</Card>
	);
};

export default ReassignLot;

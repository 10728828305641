import React from 'react';
import Layout from '../../components/Layout';
import ProtectedRoute from '../../routing/ProtectedRoute/ProtectedRoute';
import RequireAuth from '../../routing/RequireAuth';
import PerformanceDashboard from './Performance';
import LiveAuctionDashboard from './LiveAuction/';

const DashboardsRoot = ({ match }: { match: { url: string } }) => {
	return (
		<RequireAuth unauthRedirectTo={`${match.url}/Summary`}>
			<Layout>
				<ProtectedRoute path={`${match.url}/Summary`} component={PerformanceDashboard} />
				<ProtectedRoute exact path={`${match.url}/LiveAuction/`} component={LiveAuctionDashboard} />
				<ProtectedRoute
					exact
					path={`${match.url}/LiveAuction/:auctionId`}
					component={LiveAuctionDashboard}
				/>
			</Layout>
		</RequireAuth>
	);
};

export default DashboardsRoot;
